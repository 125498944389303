import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';

export interface BreakPoints {
  name: string;
  mediaQuery: string | string[];
}

@Injectable()
export class LayoutService {
  private breakpointsChanges: BehaviorSubject<BreakPoints> =
    new BehaviorSubject<BreakPoints>({
      name: '',
      mediaQuery: [],
    });
  public breakpointsChanges$: Observable<BreakPoints> =
    this.breakpointsChanges.asObservable();
  private breakpoints: BreakPoints[] = [
    {
      name: 'tabletMinJLG',
      mediaQuery: ['(min-width: 1201px)'],
    },
    {
      name: 'tabletMinJDC',
      mediaQuery: ['(min-width: 1201px)'],
    },
    {
      name: 'mobileJLG',
      mediaQuery: ['(min-width: 768px) and (max-width: 1201px)'],
    },
    {
      name: 'mobileJDC',
      mediaQuery: ['(min-width: 768px) and (max-width: 1201px)'],
    },
    {
      name: 'miniJLG',
      mediaQuery: ['(max-width: 767px)'],
    },
    {
      name: 'miniJDC',
      mediaQuery: ['(max-width: 767px)'],
    },
  ];

  public isMobile$: Observable<boolean> = this.breakpointsChanges$.pipe(
    startWith(null as BreakPoints),
    map(
      (breakpoints) =>
        breakpoints &&
        (breakpoints.name === 'miniJLG' || breakpoints.name === 'miniJDC')
    )
  );
  public isTabletMax$: Observable<boolean> = this.breakpointsChanges$.pipe(
    startWith(null as BreakPoints),
    map(
      (breakpoints) =>
        breakpoints &&
        (breakpoints.name === 'mobileJLG' ||
          breakpoints.name === 'mobileJDC' ||
          breakpoints.name === 'miniJLG' ||
          breakpoints.name === 'miniJDC')
    )
  );
  public isDesktop$: Observable<boolean> = this.breakpointsChanges$.pipe(
    startWith(null as BreakPoints),
    map(
      (breakpoints) =>
        breakpoints &&
        (breakpoints.name === 'tabletMinJLG' ||
          breakpoints.name === 'tabletMinJDC')
    )
  );

  // Sets whether the global search box is viewable in the header or not.
  private readonly toggleSearchEnabled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public toggleSearchEnabled$: Observable<boolean> =
    this.toggleSearchEnabled.asObservable();

  private readonly emergencyAlertEnabled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public emergencyAlertEnabled$: Observable<boolean> =
    this.emergencyAlertEnabled.asObservable();

  // Sets the hamburger and menu type.
  private navCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public navCollapsed$: Observable<boolean> = this.navCollapsed.asObservable();
  private serviceMenuCollapsed: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public serviceMenuCollapsed$: Observable<boolean> =
    this.serviceMenuCollapsed.asObservable();
  private isClearskyEnabled: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public isClearskyEnabled$: Observable<boolean> =
    this.isClearskyEnabled.asObservable();

  private mainMenuCollapsed: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public mainMenuCollapsed$: Observable<boolean> =
    this.mainMenuCollapsed.asObservable();

  private authCollapsed: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  authCollapsed$: Observable<boolean> = this.authCollapsed.asObservable();

  public hideHamburgerIcon = false;
  public cartPreviewCollapsed = true;
  public clearskyNavCollapsed = false;
  public searchFilterCollapsed = false;
  public showChatMenu = false;
  private dt = new Date();
  private etTime = 0;

  constructor(private breakpointObserver: BreakpointObserver) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    this.etTime = +dayjs.tz(this.dt, 'America/New_York').format('Hmm');

    this.breakpoints.forEach((bp) => {
      this.breakpointObserver.observe(bp.mediaQuery).subscribe((res) => {
        if (res.matches) {
          this.breakpointsChanges.next(bp);
        }
      });
    });
  }

  /**
   * Set auth collapsed for auth container in header.
   * @param isCollapsed
   */
  setAuthCollapsed(isCollapsed: boolean) {
    this.authCollapsed.next(isCollapsed);
  }

  /**
   * Set is clearsky boolean flag.
   * @param isEnabled
   */
  public setIsClearskyEnabled(isEnabled: boolean): void {
    this.isClearskyEnabled.next(isEnabled);
  }

  /**
   * Set emergency alert enabled flag.
   * @param isEnabled
   */
  public setIsEmergencyAlertEnabled(isEnabled: boolean): void {
    this.emergencyAlertEnabled.next(isEnabled);
  }

  /**
   * Is chat disabled on OLE?
   */
  isChatDisabled(): boolean {
    const dayOfWeek = this.dt.getDay();
    const holidayStart = new Date('12/24/2022').getTime();
    const holidayEnd = new Date('01/02/2023').getTime();
    return !(
      (
        this.etTime > 1800 ||
        this.etTime < 800 ||
        dayOfWeek === 0 ||
        dayOfWeek === 6 ||
        (this.dt.getTime() > holidayStart &&
          this.dt.getTime() < holidayEnd &&
          environment.production)
      ) // this condition only applies to prod
    );
  }

  /**
   * Is Service & Warrabty chat disabled on OLE?
   */
  isServiceDisabled(): boolean {
    return !(this.etTime > 1645);
  }

  /**
   * Enable toggle search mode?
   * @param enable
   */
  enableToggleSearch(enable: boolean): void {
    this.toggleSearchEnabled.next(enable);
  }

  /**
   * Menu types are, 'service', 'main', and no parameter resets the state of the menu and hamburger icon.
   * @param type string
   */
  showMenuType(type?: string) {
    switch (type) {
      case 'main':
        this.navCollapsed.next(false);
        this.serviceMenuCollapsed.next(true);
        this.mainMenuCollapsed.next(false);
        break;
      case 'service':
        this.navCollapsed.next(false);
        this.serviceMenuCollapsed.next(false);
        this.mainMenuCollapsed.next(true);
        break;
      default:
        this.navCollapsed.next(true);
        this.serviceMenuCollapsed.next(true);
        this.mainMenuCollapsed.next(true);
    }
  }
}
