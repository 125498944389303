import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface ITrustFactor {
  icon: string;
  title: string;
  subTitle: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-trust-factors',
  templateUrl: './trust-factors.component.html',
  styleUrls: ['./trust-factors.component.scss'],
})
export class TrustFactorsComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    background: string;
    trustFactors: object;
  };
  trustFactors: ITrustFactor[] = [];

  constructor() {}
  ngOnInit() {
    // data.trustFactors returns an object that has keys of "0", "1", "2", etc.
    // Instead, we are going to create an array of trust factors by executing the following.
    const trustFactorProperties: string[] = Object.keys(this.data.trustFactors);
    this.trustFactors = trustFactorProperties.map((property: string) => {
      return this.data.trustFactors[property];
    });
  }
}
