import { Component, Input } from '@angular/core';

/**
 * - The callout text component takes in a string displays the text passed in.
 * - In addition, the isProductCard variable determines what CSS class is applied. By default, isProductCard is false.
 */
@Component({
  selector: 'app-callout-text',
  templateUrl: './callout-text.component.html',
})
export class CalloutTextComponent {
  @Input() calloutText = '';
  @Input() isProductCard = false;
}
