import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-truncator',
  templateUrl: './truncator.component.html'
})
export class TruncatorComponent {

  @Input() length: number = 25;
  @Input() text: string;

  constructor() { }

  /**
   * Receives a string to truncate to max characters.
   * @param {string} stringToTruncate
   * @returns {string}
   */
  truncate(stringToTruncate: string): string {
    return stringToTruncate.substring(0, this.length) + '...';
  }

}
