import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function customValidation(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const errorObject = {};

    const currentPassword = form.get('currentPassword')?.value;
    const newPassword = form.get('password')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    const login = form.get('login')?.value.split(/[@,]+/); // Okta splits the login by punctuation into different parts

    const SMALL_LETTER_REGEXP = /^(?=.*[a-z])/;
    const UPPER_LETTER_REGEXP = /^(?=.*[A-Z])/;
    const NUMERIC_REGEXP = /^(?=.*\d)/;
    const SPECIAL_CHAR_REGEXP = /^(?=.*[!@#$%^&*])/;
    const AT_LEAST_EIGHT_REGEXP = /^(?=.{8,})/;

    if (!SMALL_LETTER_REGEXP.test(newPassword)) errorObject['lowercase'] = true;

    if (!UPPER_LETTER_REGEXP.test(newPassword)) errorObject['uppercase'] = true;

    if (!NUMERIC_REGEXP.test(newPassword)) errorObject['num'] = true;

    if (!SPECIAL_CHAR_REGEXP.test(newPassword))
      errorObject['specialChar'] = true;

    if (!AT_LEAST_EIGHT_REGEXP.test(newPassword)) errorObject['minChar'] = true;

    if (newPassword !== confirmPassword) errorObject['matchPassword'] = true;

    if (currentPassword) {
      if (currentPassword === newPassword) errorObject['repeatPassword'] = true;
    }

    // Loop through the array of the user's login that was split.
    for (let i = 0; i < login.length; i++) {
      // If the login string's length and password's length is greater than or equal to
      // 4, continue the test.
      if (login[i].length >= 4 && newPassword.length >= 4) {
        // Create a loop that is the length of the login string.
        for (let j = 0; j < login[i].length; j++) {
          // Because we are determining if any 4 character (or greater) part of the login
          // exists in the password, we are adding 4 to 'j' to get those 4 characters. If 'j' + 4
          // is greater than the length of the login string, prevent a out of bounds error by
          // breaking the loop.
          if (j + 4 > login[i].length) {
            break;
          }
          // If the password includes a 4 character substring of the login string, set the error
          // and set pass to false.
          if (newPassword.includes(login[i].substring(j, j + 4))) {
            // Okta allows any part less than 4 characters to be part of the password
            errorObject['matchLogin'] = true;
          }
        }
      }
    }
    return errorObject;
  };
}
