import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CSFieldType,
  Machine,
  MachineFieldDisplay,
} from '../../../contracts/clearsky/machine/machine.dto';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OcidItems } from '../../../contracts/ocid-items';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MachineFieldDisplayValueOcids } from '../../../contracts/clearsky/machine/machine.fields';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-table',
  templateUrl: './machine-table.component.html',
  styleUrls: ['./machine-table.component.scss'],
})
export class MachineTableComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Input() machines!: Machine[];
  @Input() columns: string[] = ['eid', 'sn', 'model'];
  @Input() maxRows?: number;
  @Output() rowClicked: EventEmitter<Machine> = new EventEmitter<Machine>();
  dataSource: MatTableDataSource<Machine> = new MatTableDataSource<Machine>([]);
  pageSize = 30;
  fieldType = CSFieldType;
  ocids: OcidItems = {};
  fieldDisplay = MachineFieldDisplay;

  constructor(private localization: LocalizationService) {}

  ngOnInit(): void {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    // Now get the OCIDs for the columns
    this.localization
      .getOCIDs([
        ...this.columns.reduce((prev, column) => {
          return prev.concat(MachineFieldDisplayValueOcids[column] || []);
        }, []),
        ...Object.values(MachineFieldDisplay),
      ])
      .subscribe(() => {
        this.setData();
      });
  }

  /**
   * On input changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxRows && !changes.maxRows.isFirstChange()) {
      this.setData();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * On row click in table.
   * @param machine
   */
  onRowClick(machine: Machine): void {
    this.rowClicked.emit(machine);
  }

  /**
   * Set datasource data.
   * @private
   */
  private setData(): void {
    this.dataSource.data = this.maxRows
      ? this.machines.slice(0, this.maxRows)
      : this.machines;
  }
}
