import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { ImageWithCTACard } from '../../../../contracts/design-components';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-three-column-card',
  styleUrls: ['./three-column-card.component.scss'],
  templateUrl: './three-column-card.component.html',
})
export class ThreeColumnCardComponent {
  @Input() slide!: ImageWithCTACard;
  @Input() activeSlide!: number;
  @Input() index!: number;
  @Input() isCarousel!: boolean;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private http: HttpClient
  ) {}

  // force pdf files as downloads instead of opening them in the browser window
  downloadFile() {
    if (isPlatformBrowser(this.platformId)) {
      const httpOptions = {
        responseType: 'blob' as 'json',
      };
      return this.http
        .get(this.slide.downloadLinkUrl, httpOptions)
        .subscribe((data) => {
          const blob = new Blob([data as BlobPart], {
            type: 'application/pdf',
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'flyer.pdf';
          link.click();
        });
    }
  }
}
