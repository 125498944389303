import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { INavMenu } from '../../../contracts/user/inav-menu';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { LayoutService } from '../../../service/layout.service';
import { first } from 'rxjs/operators';

@Component({
  selector: '[app-menu-item]',
  templateUrl: './menu-item.component.html'
})
export class MenuItemComponent implements OnInit {

  @Input() item: INavMenu;
  @Input() parent: INavMenu;
  isCollapsed: boolean = true;

  constructor(private router: Router,
              private layoutService: LayoutService,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: string,
              private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    // Upon refresh, do we need to show this menu item (only on desktop since mobile is hidden)?
    this.layoutService.isTabletMax$.pipe(
      first()
    ).subscribe(isTablet => {
      if (isPlatformBrowser(this.platformId) &&
        isTablet &&
        this.router.url.startsWith(this.item.url) && this.item.childMenus) {
        this.isCollapsed = false;
      }
    })
  }

  goTo(): void {
    if (this.item.url !== '#') {
      this.router.navigate([this.item.url]);
    } else {
      // Show snackbar that they can't navigate with that
      this.snackbar.open('This feature is not available yet.', null, {
        duration: 3000,
        verticalPosition: 'top'
      });
    }
  }

  get hasChildMenus(): boolean {
    return !!(this.item.childMenus && this.item.childMenus.length);
  }

  onToggle(e): void {
    e.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

}
