import { NativeDateAdapter } from '@angular/material/core';
import { FormatDatePipe } from '../format-date.pipe';
import { Injectable } from '@angular/core';

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: string) {
    // We will use our formatDate pipe to reuse code.
    const formatDatePipe: FormatDatePipe = new FormatDatePipe();
    // Get the day, month, and year from the provided date.
    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    const year = date.getFullYear().toString();
    // If the display format isn't the plain year, transform the date
    // into the user's default format.
    if (displayFormat !== 'YYYY') {
      const dateForPipe: string = year + month + day;
      return formatDatePipe.transform(dateForPipe, displayFormat);
    } else {
      // Otherwise only return the year.
      return year;
    }
  }
}
