import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { INavMenu } from '../../../contracts/user/inav-menu';
import { MenuService } from '../../../service/user/menu.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-sign-in-dialog',
  styleUrls: ['./sign-in-dialog.component.scss'],
  templateUrl: './sign-in-dialog.component.html',
})
export class SignInDialogComponent implements OnInit {
  signInMenu!: INavMenu;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    private menuService: MenuService,
    private localization: LocalizationService,
    public dialogRef: MatDialogRef<SignInDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: unknown
  ) {
    // Get the sign in model content from the menu service.
    // Do this in the constructor since the value needs to be set.
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('SignInModel') : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.signInMenu = menu;
        }
      });
  }

  ngOnInit() {
    // These are all the OCIDs for the sign in modal. They may not be used in this specific component,
    // but also injected components like the auth-form.
    this.localization
      .getOCIDs([
        'sign-in-modal.back-login-text',
        'sign-in-modal.check-email-message',
        'sign-in-modal.forgot-password',
        'sign-in-modal.label',
        'sign-in-modal.register-now-text',
        'sign-in-modal.reset-password-text',
      ])
      .subscribe();
  }
}
