import { HistoricSelection } from './machine/machine.dto';

export interface CsAggFleetOverviewData {
  fleetAverageAge: number;
  mxReportedLast24Hr: number;
  mxWithBatAlerts: number;
  mxWithDTCs: number;
  mxWithEngAlerts: number;
  mxWithEnvPro: number;
  mxWithLowFuel: number;
  mxWithLowSOC: number;
  mxWithSysAlerts: number;
  mxWithTeleAlerts: number;
}

export interface CsAggIgnitionStatusData {
  nv: number;
  off: number;
  on: number;
}

export interface CsAggMachineUseData {
  offPercent: number;
  onAvg: number;
  onMax: number;
  onMin: number;
  onPercent: number;
  onTimeSum: number;
}

export interface CsAggMachineCountData {
  legacy: number;
  nv: number;
  total: number;
}

export interface CsAggCountData {
  id: number;
  cnt: number;
}

export interface CsZone {
  z1: number;
  z2: number;
  z3: number;
  z4: number;
  z5: number;
}

export interface CsZoneItem {
  actCnt: CsZone;
  avg: CsZone;
  inactCnt: CsZone;
  sum: CsZone;
}

export interface CsZoneHistoricItem {
  z1d: CsZoneItem;
  z7d: CsZoneItem;
  z14d: CsZoneItem;
  z31d: CsZoneItem;
  z90d: CsZoneItem;
}

export interface CsAggRangeChartData {
  x: number[];
  y: number;
}

export interface CsAggDateLastChargeData {
  sn: string;
  ts: string;
}

export interface CsAggSiteNetworkData {
  mxsInSiteNetworks: number;
  mxsJoinedSiteNetworks24Hrs: number;
  mxsLeftSiteNetworks24Hrs: number;
  newSiteNetworks24Hrs: number;
  totalSiteNetworks: number;
}

export interface CsAggSocrChartData {
  charging: CsAggRangeChartData[];
  notCharging: CsAggRangeChartData[];
}

export interface CsAggData {
  alerts?: CsAggCountData[];
  boomsEnvPro?: CsZoneHistoricItem;
  defRmQ?: CsAggCountData[];
  dolc?: CsAggDateLastChargeData[];
  dtcs?: CsAggCountData[];
  dtcSeverity?: CsAggCountData[];
  dtcSource?: CsAggCountData[];
  fleetOverview?: CsAggFleetOverviewData;
  flvlQ?: CsAggCountData[];
  hrsFlags?: CsAggCountData[];
  ign?: CsAggIgnitionStatusData;
  machineCount?: CsAggMachineCountData;
  machineInUse?: CsAggMachineUseData[];
  mgroup?: CsAggCountData[];
  reportedInLast24Hours?: number;
  reportedOver24Hours?: number;
  scissorsEnvPro?: CsZoneHistoricItem;
  siteNetwork?: CsAggSiteNetworkData;
  socChart?: CsAggRangeChartData[];
  socrChart?: CsAggSocrChartData;
  socQ?: CsAggCountData[];
  telesEnvPro?: CsZoneHistoricItem;
  totalCustMachines?: number;
  totalCustMachinesWithDevice?: number;
}

export const CsAggEnvHist = {
  [HistoricSelection.HOURS_24]: 'z1d',
  [HistoricSelection.DAYS_7]: 'z7d',
  [HistoricSelection.DAYS_14]: 'z14d',
  [HistoricSelection.DAYS_31]: 'z31d',
  [HistoricSelection.DAYS_90]: 'z90d',
};
