import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar-with-text',
  templateUrl: './progress-bar-with-text.component.html',
  styleUrls: ['./progress-bar-with-text.component.scss'],
})
export class ProgressBarWithTextComponent {
  @Input() width = 100;
}
