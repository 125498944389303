import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IHeartbeat } from '../../contracts/heartbeat/IHeartbeat';
import { INavMenu } from '../../contracts/user/inav-menu';
import { ContentService } from '../../service/content.service';
import { HeartbeatService } from '../../service/heartbeat/heartbeat.service';
import { MenuService } from '../../service/user/menu.service';
import { LocalizationService } from '../../shared/localization/localization.service';
import { OktaAuthWrapper } from '../../service/auth/okta.auth.wrapper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LayoutService } from '../../service/layout.service';
import { environment } from '../../../environments/environment';
import { CurrentUserService } from 'app/service/user/current-user.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [
    trigger('searchState', [
      state(
        'hide',
        style({
          display: 'none',
        })
      ),
      state(
        'show',
        style({
          display: 'block',
        })
      ),
      transition('hide <=> show', animate(100)),
    ]),
  ],
})
export class FooterComponent implements OnInit {
  today: number = Date.now();
  ocids$: Observable<{}>;
  hideFooter$: Observable<boolean> = this.contentService.isMaintenance$;
  mainMenus$: Observable<INavMenu[]>;
  socialMenu$: Observable<INavMenu>;
  missionMenu$: Observable<INavMenu>;
  copyrightMenu$: Observable<INavMenu>;
  paymentMenu$: Observable<INavMenu>;
  heartbeat$: Observable<IHeartbeat> | undefined | Object;
  socialIcons = {
    phone: 'icon-phone',
    email: 'icon-mail',
    rss: 'icon-rss',
    linkedin: 'icon-linkedin',
    twitter: 'icon-twitter',
    instagram: 'icon-instagram',
    facebook: 'icon-facebook',
    youtube: 'icon-youtube',
  };
  paymentIcons = {
    visa: 'icon-cc-visa',
    americanexpress: 'icon-cc-amex',
    discover: 'icon-cc-discover',
    mastercard: 'icon-cc-mastercard',
    paypal: 'icon-cc-paypal',
  };
  isClearskyMenuEnabled = false;
  oskLogo = environment.imagePath + 'oshkosh.webp';
  isSubscribed$: Observable<boolean> = this.currentUserService.userSubject.pipe(
    map((user) => user.clearSky)
  );

  constructor(
    private localization: LocalizationService,
    private menuService: MenuService,
    private oktaAuth: OktaAuthWrapper,
    private heartbeatService: HeartbeatService,
    public layoutService: LayoutService,
    private contentService: ContentService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    // Subscribe to ocids.
    this.ocids$ = this.localization.OCIDs;

    // Subscribe to menu data. If the menu exists, get the footer menus. This prevents multiple
    // unnecessary calls to getMenu. Without this check, all of these will end up calling
    // getMenu() on page load before authentication has finished.
    this.mainMenus$ = this.menuService.menus$.pipe(
      mergeMap((menus) =>
        menus && menus.footerMenu
          ? combineLatest([
              this.menuService.getFooterMenuByUxKey('quick-links'),
              this.menuService.getFooterMenuByUxKey('newsletters'),
              this.menuService.getFooterMenuByUxKey('press-releases'),
            ])
          : of([])
      )
    );

    this.socialMenu$ = this.menuService.menus$.pipe(
      mergeMap((menus) =>
        menus && menus.footerMenu
          ? this.menuService.getFooterMenuByUxKey('social-media')
          : of(null)
      )
    );

    this.missionMenu$ = this.menuService.menus$.pipe(
      mergeMap((menus) =>
        menus && menus.footerMenu
          ? this.menuService.getFooterMenuByUxKey('mission-statement')
          : of(null)
      )
    );

    this.copyrightMenu$ = this.menuService.menus$.pipe(
      mergeMap((menus) =>
        menus && menus.footerMenu
          ? this.menuService.getFooterMenuByUxKey('copyright')
          : of(null)
      )
    );

    this.paymentMenu$ = this.menuService.menus$.pipe(
      mergeMap((menus) =>
        menus && menus.footerMenu
          ? this.menuService.getFooterMenuByUxKey('payment-options')
          : of(null)
      )
    );

    // Get information about the current server and other application info on page load
    // if the environment has been loaded.
    this.heartbeat$ = this.oktaAuth.envLoaded$.pipe(
      mergeMap((envLoaded: boolean) =>
        envLoaded ? this.heartbeatService.getHeartbeat() : of(null)
      )
    );

    this.layoutService.isClearskyEnabled$.subscribe((isEnabled: boolean) => {
      this.isClearskyMenuEnabled = isEnabled;
    });
  }

  /**
   * Get copyright text in footer.
   */
  get footerCopyright$(): Observable<string> {
    return this.ocids$.pipe(map((ocids) => ocids['footer.copyright']));
  }
}
