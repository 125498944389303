import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { IAddItemsDto } from '../../../../contracts/commerce/dto/iadd-items-dto';
import { IAddItemToGiftList } from '../../../../contracts/product/dto/iadd-item-to-giftlist';
import { IProduct } from '../../../../contracts/product/iproduct';
import { OktaAuthWrapper } from '../../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { ShoppingListComponent } from '../../../../endeca/cartridges/shopping-cart/shopping-list/shopping-list.component';
import { CartService } from '../../../../service/cart/cart.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { ProductsApiService } from '../../../../service/product/products-api.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ImportPartsComponent } from '../import-parts/import-parts.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { ConfirmationAlertService } from 'app/service/confirmation/confirmation-alert.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-order-now',
  templateUrl: './order-now.component.html',
})
export class OrderNowComponent implements OnInit {
  isLoggedIn = false;
  initialRows = 2;
  columnsPerRow = 3;
  uploadedData: {
    itemNum: string;
    quantity: number;
  }[] = [];
  orderForm: UntypedFormGroup = this.fb.group({
    items: this.fb.array([]),
  });
  ocids: OcidItems = {};
  isValid = false;
  platformBrowser = false;
  gaSource = 'Order Now';

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    public dialog: MatDialog,
    private oktaAuth: OktaAuthWrapper,
    private cartService: CartService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder,
    private ngZone: NgZone,
    private productService: ProductsApiService,
    private localization: LocalizationService,
    private ecommService: EcommerceService,
    private alertService: ConfirmationAlertService
  ) {}

  // Get all FormArray of items in template
  get itemGroups() {
    return this.orderForm.get('items') as UntypedFormArray;
  }

  /**
   * When component initializes.
   */
  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'ordernow.title',
        'ordernow.text2',
        'ordernow.import-text',
        'buy.item-number-error',
        'order-now.add-rows',
        'order-now.invalid-part',
      ])
      .subscribe();
    // Loop through default amount of items that can be entered on page load.
    const initialFields = this.initialRows * this.columnsPerRow;
    for (let i = 0; i < initialFields; i++) {
      this.addItemGroup(null, null);
    }
    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn.subscribe(
      (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    );
  }

  /**
   * Add form group to item group.
   *
   * @param {number} itemNum
   * @param {number} quantity
   * @return {FormGroup}
   */
  protected addItemGroup(itemNum: number, quantity: number) {
    const group = this.fb.group({
      itemNum: [itemNum],
      quantity: [quantity],
    });

    this.itemGroups.push(group);

    return group;
  }

  /**
   * On add more rows button click
   */
  onAddMoreRows() {
    // Add more rows
    for (let i = 0; i < this.columnsPerRow; i++) {
      this.addItemGroup(null, null);
    }
  }

  /**
   * When an input changes for an item record.
   *
   * @param {string} value
   * @param {FormGroup} itemGroup
   * @param {string} control
   */
  onItemChange(event, itemGroup: UntypedFormGroup, control: string) {
    // Add/clear validators
    const field = itemGroup.get(control);
    if (event.target.value !== '') {
      field.setValidators([Validators.required]);
      field.updateValueAndValidity();

      // validate part # only if it's not empty, must run after updateValueAndValidity otherwise errors get cleared
      if (field.value != '' && field.value != null) {
        this.validateItem(itemGroup);
      }
    } else {
      field.clearValidators();
      field.updateValueAndValidity();
    }
    this.isValid = this.orderForm.valid;
  }

  /**
   * When users enter an item, validate it
   */
  validateItem(itemGroup: UntypedFormGroup) {
    const field = itemGroup.get('itemNum');
    if (field.value != '') {
      this.productService
        .getProduct(field.value)
        .subscribe((product: IProduct) => {
          // Check if item was found
          if (product.itemNotFound) {
            field.setErrors({ itemNotFound: true });
            field.markAsTouched();
          } else {
            field.setErrors(null);
          }
          this.isValid = this.orderForm.valid;
        });
    }
  }

  /**
   * When upload excel document button is clicked.
   */
  importParts() {
    this.ngZone.run(() => {
      const importModal = this.dialog.open(ImportPartsComponent, {
        width: '450px',
      });

      // Subscribe to after close event from dialog
      importModal.afterClosed().subscribe((result) => {
        if (result) {
          // Remove all form groups in array
          this.itemGroups.controls = [];

          this.uploadedData = result;
          const numResults = result.length;

          // Loop through results
          for (let n = 0; n < numResults; n++) {
            const itemQty = result[n].quantity ? +result[n].quantity : null;
            const itemNum = result[n].itemNum ? result[n].itemNum : null;

            this.addItemGroup(itemNum, itemQty);
          }

          // Now insert nulls to fill up the rows
          // Round up the # fields so they fill the entire row
          const totalFields =
            numResults +
            (this.columnsPerRow - (numResults % this.columnsPerRow));
          const remainder = totalFields - numResults;
          for (let n = 0; n < remainder; n++) {
            this.addItemGroup(null, null);
          }
          // Mark form as dirty
          this.orderForm.markAsDirty({ onlySelf: true });
          this.orderForm.updateValueAndValidity({
            onlySelf: false,
            emitEvent: true,
          });

          // now loop through the added fields to validate the part #
          Object.keys(this.orderForm.controls).forEach((key) => {
            const formArray = <UntypedFormArray>this.orderForm.get(key);
            for (const index in formArray.controls) {
              const group: UntypedFormGroup = <UntypedFormGroup>(
                formArray.controls[index]
              );
              if (
                group.get('itemNum').value != '' &&
                group.get('itemNum').value != null
              ) {
                this.validateItem(group);
              }
            }
          });
        }
      });
    });
  }

  /**
   * When add to shopping list link is clicked.
   */
  addToList() {
    if (this.isLoggedIn) {
      // If the form is valid
      if (this.orderForm.valid) {
        this.notificationService.reset();
        // Filter the items to be added. This removes any blank form fields from being added. "map"
        // Will then manipulate then return the item numbers and quantities needed to be added.
        const items: IAddItemToGiftList[] = this.itemGroups.controls
          .filter((control) => {
            return (
              control.get('itemNum').value !== '' &&
              control.get('itemNum').value !== null
            );
          })
          .map((control) => {
            const itemNumber = control.get('itemNum').value;
            const quantity = control.get('quantity').value;
            return {
              itemNumber: itemNumber,
              quantity: quantity ? +quantity : 1,
            };
          });
        const listModal = this.dialog.open(ShoppingListComponent, {
          width: '380px',
          data: { items: items },
        });

        // Subscribe to event when dialog is closed
        listModal.afterClosed().subscribe((result) => {
          this.orderForm.reset();
          this.clearValidators();
        });
      }
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.addToList();
          }
        });
    }
  }

  /**
   * When add to cart button is clicked, upload all item entries.
   */
  addToCart() {
    if (this.orderForm.valid) {
      this.ngZone.run(() => {
        // Go through each item entered
        this.notificationService.reset();
        const itemsToAdd: IAddItemsDto = {
          items: [],
        };
        const gaItemsToAdd = [];
        for (const control of this.itemGroups.controls) {
          const itemNum = control.get('itemNum').value;
          const quantity = control.get('quantity').value
            ? control.get('quantity').value
            : 1;
          if (itemNum !== '' && itemNum !== null) {
            itemsToAdd.items.push({
              catalogRefId: itemNum,
              quantity: quantity,
              productId: itemNum,
            });
            gaItemsToAdd.push({
              item_id: itemNum,
              item_name: '',
              item_list_name: this.gaSource,
              id: itemNum,
              quantity: quantity,
            });
          }
        }
        if (itemsToAdd.items.length > 0) {
          this.cartService.addToCart(itemsToAdd).subscribe(
            () => {
              // Send Add to Cart action to GA if the platform is browser
              if (this.platformBrowser) {
                this.alertService.addToCartConfirm(
                  itemsToAdd.items.map(item=>item.productId).toString()
                );
                this.ecommService.addAllToCart(gaItemsToAdd, this.gaSource);
              }
            },
            (error) => {
              this.notificationService.addError(error.error.title);
            }
          );
        }

        // Now that all items have been reset, let's reset the form
        this.orderForm.reset();
        this.clearValidators();
      });
    }
  }

  /**
   * Clear required validators that were added via onItemChange() and set form validation status to false
   */
  clearValidators() {
    Object.keys(this.orderForm.controls).forEach((key) => {
      const formArray = <UntypedFormArray>this.orderForm.get(key);
      for (const index in formArray.controls) {
        const group: UntypedFormGroup = <UntypedFormGroup>(
          formArray.controls[index]
        );
        const field = group.get('itemNum');
        field.clearValidators();
        field.updateValueAndValidity();
      }
    });
    this.isValid = false;
  }
}
