import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { IOrderDetail } from '../../../../../contracts/orders/iorder-detail';
import { Observable, throwError } from 'rxjs';
import { IOrder } from '../../../../../contracts/commerce/iorder';
import { IOrderInquiryDto } from '../../../../../contracts/orders/dto/iorder-inquiry-dto';
import { IOrderNinetyDayHistoryDto } from '../../../../../contracts/orders/dto/iorder-ninety-day-history-dto';
import { IOrderInfoDto } from '../../../../../contracts/orders/dto/iorder-info-dto';
import { IOrderDetails } from '../../../../../contracts/orders/iorder-detail-orderDetails';
import { IOrderHistItem } from '../../../../../contracts/orders/iorder-hist-item';
import { IOrderItem } from '../../../../../contracts/orders/iorder-item';
import { DatePipe } from '@angular/common';
import { IReturnCodes, IReturnDetails } from 'app/contracts/orders/ireturn';

@Injectable()
export class OrderInquiryService {
  private orderParams: IOrderInquiryDto;
  private returnParams: IOrderInquiryDto;

  constructor(private http: HttpClient) {}

  /**
   * Searching for parts order inquiries/returns.
   *
   * @param {IOrderInquiryDto} params
   * @param {boolean} isOrder
   *
   * @returns {Observable<IOrderHistItem>}
   */
  getOrder(
    params: IOrderInquiryDto,
    isOrder: boolean
  ): Observable<IOrderHistItem> {
    isOrder ? (this.orderParams = params) : (this.returnParams = params);
    const queryString = require('query-string');
    const query = queryString.stringify(params);
    const api = isOrder ? '/orders/orderInquiry?' : '/rpa/returnOrderInquiry?';
    return this.http.get(`${environment.ATG_BASE_URL}${api}${query}`).pipe(
      map((results: IOrderHistItem) => {
        if (results.items.length > 0) {
          // Need to convert all the order dates from CST/CDT (which is stored in the backend)
          // to the browser's timezone.
          const differenceInDate: number = this.getCSTorCDTDiff();
          results.items.map((item: IOrderItem) => {
            const orderDate: string = new DatePipe('en-US').transform(
              item.orderDate.substring(0, 19).replace(/-/g, '/'),
              'yyyy/MM/dd HH:mm:ss'
            );
            item.orderDate = this.convertToBrowserDate(
              orderDate,
              differenceInDate
            );
            return item;
          });
        }
        return results;
      })
    );
  }

  /**
   *
   * @param {IOrderInfoDto} orderId
   *
   * @return {Observable<IOrderDetail>}
   */
  getOrderDetails(params: IOrderInfoDto): Observable<IOrderDetail> {
    const queryString = require('query-string');
    const query = queryString.stringify(params);
    return this.http
      .get(`${environment.ATG_BASE_URL}/orders/orderInfoWithReturn?${query}`, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        map((res: IOrderDetail) => {
          // Convert the order date from CST/CDT (which is stored in the backend)
          // to the browser's timezone only if the date hasn't already been calculated.
          // That way the date doesn't show as aN/NaN/NaNN
          if (!res.convertedOrderDate) {
            const differenceInDate: number = this.getCSTorCDTDiff();
            res.convertedOrderDate = this.convertToBrowserDate(
              res.orderDate.substring(0, 19).replace(/-/g, '/'),
              differenceInDate
            );
          }
          // Calculate tax.
          res.calculatedTax = res.freightQuote
            ? res.freightQuote.freightTaxAmount
              ? +res.freightQuote.freightTaxAmount
              : 0
            : 0;
          res.calculatedSubtotal = 0;
          res.orderDetails.forEach((orderDetail: IOrderDetails) => {
            res.calculatedTax += +orderDetail.taxAmount;
            res.calculatedSubtotal += +orderDetail.totalPrice;
          });
          // Calculate surcharge
          res.surcharge = res.specialCharges.find(
            (charge) => charge.chargeId === 'STL'
          )?.amount
            ? +res.specialCharges.find((charge) => charge.chargeId === 'STL')
                ?.amount
            : 0;
          // Get total
          res.calculatedTotal =
            res.calculatedSubtotal +
            res.coreReturnAmountTotal +
            (res.freightQuote
              ? res.freightQuote.totalQuotedFreightAmount
                ? +res.freightQuote.totalQuotedFreightAmount
                : 0
              : 0) +
            res.surcharge +
            res.calculatedTax;
          return res;
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Returns the previous request params for Parts Order/Return Inquiries.
   * @param {boolean} isOrder
   *
   * @return {IOrderInquiryDto}
   */
  getRequestParams(isOrder: boolean): IOrderInquiryDto {
    return isOrder ? this.orderParams : this.returnParams;
  }

  /**
   * Resets the request parameters.
   * @param {boolean} isOrder
   */
  resetRequestParms(isOrder: boolean): void {
    isOrder ? (this.orderParams = undefined) : (this.returnParams = undefined);
  }

  /**
   * Searching for parts order inquiries.
   *
   * @param {IOrderNinetyDayHistoryDto} params
   *
   * @return {Observable<IOrderHistItem>}
   */
  getNinetyDayOrderHistory(
    params: IOrderNinetyDayHistoryDto
  ): Observable<IOrderHistItem> {
    const queryString = require('query-string');
    const query = queryString.stringify(params);
    return this.http.get<IOrderHistItem>(
      `${environment.ATG_BASE_URL}/orders/itemsHistory?${query}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }
    );
  }

  /**
   * Get order from API.
   *
   * @param {string} id
   *
   * @returns {Observable<IOrder>}
   */
  public getOrderById(id: string): Observable<IOrder> {
    return this.http.get<IOrder>(`${environment.apiUrl}/orders/${id}`);
  }

  /**
   * Get list of return reason codes
   * @returns {Observable<IReturnCodes[]>}
   */
  public getReasonCodes(): Observable<IReturnCodes[]> {
    return this.http.get<IReturnCodes[]>(
      `${environment.apiUrl}/rpa/reasonCodes`
    );
  }

  /**
   *
   * @param {IOrderInfoDto} params
   * @return {Observable<IReturnDetails>}
   */
  public getReturnDetails(params: IOrderInfoDto): Observable<IReturnDetails> {
    const queryString = require('query-string');
    const query = queryString.stringify(params);
    return this.http
      .get(`${environment.ATG_BASE_URL}/rpa/returnOrderInfo?${query}`, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        map((res: IReturnDetails) => {
          
          // Need to convert all the order dates from CST/CDT (which is stored in the backend)
          // to the browser's timezone.
          const differenceInDate: number = this.getCSTorCDTDiff();
          const creationDate: string = new DatePipe('en-US').transform(
            res.creationDate.substring(0, 19).replace(/-/g, '/'),
            'yyyy/MM/dd HH:mm:ss'
          );
          res.creationDate = this.convertToBrowserDate(
            creationDate,
            differenceInDate
          );
          return res;
        })
      );
  }

  /**
   * Submit return order
   * @param data
   */
  public submitReturn(data): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/rpa/submitReturnOrder`, data);
  }

  /**
   * This is a reusable method that finds how many hours are between CST/CDT and UTC. Because
   * of daylight savings time, this difference could be either a difference of 5 or 6 hours. We
   * are using this logic in order to avoid hardcoding the difference.
   *
   * @returns {number}
   */
  getCSTorCDTDiff(): number {
    // Get the current date.
    const dateNow: Date = new Date();
    // Convert the date to CST/CDT.
    const CSTorCDT: Date = new Date(
      new DatePipe('en-US').transform(dateNow, 'yyyy/MM/dd HH:mm:ss', 'CST')
    );
    // Convert the date to UTC.
    const UTC: Date = new Date(
      new DatePipe('en-US').transform(dateNow, 'yyyy/MM/dd HH:mm:ss', 'UTC')
    );
    // Find the difference between the CSTorCDT and UTC dates.
    const difference: number = UTC.getHours() - CSTorCDT.getHours();
    return difference;
  }

  /**
   * Reusable method that converts a date from CST/CDT, to UTC, and then to the browser's
   * timezone.
   * @param {string} date
   * @param {number} differenceInDate
   * @returns {string}
   */
  public convertToBrowserDate(date: string, differenceInDate: number): string {
    // Convert the CST/CDT date to UTC by multiplying the current difference between CST/CDT CDT and UTC.
    const UTC_DATE: Date = new Date(
      new Date(date).getTime() + 1 * 60 * 60 * 1000 * differenceInDate
    );
    // Find the difference in hours between the browser's time and the UTC time.
    // getTimezoneOffset() returns the difference in minutes, which we convert to hours by
    // dividing by 60. Lastly, we multiple that result by -1 because if the browser time is less
    // than the UTC time the method returns it as positive and if it is greater the method returns
    // it as negative. This will help when we adjust the time to the browser's timezone.
    const differenceInBrowserTime: number =
      (new Date().getTimezoneOffset() / 60) * -1;
    // Now we will convert the time from UTC to the browser's timezone by adding the difference.
    // differenceInBrowserTime will be positive if it is ahead and negative if it is behind.
    const BROWSER_CONVERTED_DATE: Date = new Date(
      UTC_DATE.getTime() + 1 * 60 * 60 * 1000 * differenceInBrowserTime
    );
    // Now we will change the converted date to a format accepted by our date pipe which displays the
    // date in the format the user's environment is set at.
    const year: number = BROWSER_CONVERTED_DATE.getFullYear();
    const month: number = BROWSER_CONVERTED_DATE.getMonth() + 1;
    const day: number = BROWSER_CONVERTED_DATE.getDate();
    return (
      year.toString() +
      (month < 10 ? '0' + month.toString() : month.toString()) +
      (day < 10 ? '0' + day.toString() : day.toString())
    );
  }

  /**
   * Gets the difference in the date between the UTC time and the browser's time.
   * @returns {number}
   */
  getDifferenceInDate(): number {
    return new Date().getTimezoneOffset() / 60;
  }

  /**
   * Gets the date from the date in the format MM/DD/YYYY.
   * @param {Date} date
   * @returns {String}
   */
  getDateValue(date: Date): string {
    return (
      (+date.getMonth() + 1 < 10
        ? '0' + (+date.getMonth() + 1).toString()
        : +date.getMonth() + 1) +
      '/' +
      (+date.getDate() < 10
        ? '0' + date.getDate().toString()
        : date.getDate()) +
      '/' +
      date.getFullYear()
    );
  }

  /**
   * Gets the time from the date in the format HH:MM:SS.
   * @param {Date} date
   * @returns {String}
   */
  getTimeValue(date: Date): string {
    return (
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':' +
      (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    );
  }
}
