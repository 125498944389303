import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-schematics-help',
  templateUrl: './schematics-help.component.html',
})
export class SchematicsHelpComponent implements OnInit {
  ocids: {} = {};

  constructor(
    public dialogRef: MatDialogRef<SchematicsHelpComponent>,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization.getOCIDs(['3d-schematics.tip-message']).subscribe();
  }

  /**
   * When user clicks cancel button.
   */
  onClose() {
    this.dialogRef.close();
  }
}
