// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parts-cat-content {
  padding: 10px 10px 5px;
}
.parts-cat-content h3 {
  font-size: 24px;
}
.parts-cat-content a {
  border-bottom: none;
}
.parts-cat-all {
  position: absolute !important;
  bottom: 0;
  right: 0;
  font-size: 90%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1Yi1jYXRlZ29yeS1uYXZpZ2F0aW9uLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUdFO0VBQ0Usc0JBQUE7QUFGSjtBQUlJO0VBQ0UsZUFBQTtBQUZOO0FBSUk7RUFDRSxtQkFBQTtBQUZOO0FBTUU7RUFDRSw2QkFBQTtFQUNBLFNBQUE7RUFDQSxRQUFBO0VBQ0EsY0FBQTtBQUpKIiwiZmlsZSI6InN1Yi1jYXRlZ29yeS1uYXZpZ2F0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnbWl4aW5zL2JyZWFrcG9pbnRzJyBhcyAqO1xyXG5cclxuLnBhcnRzLWNhdCB7XHJcbiAgJi1jb250ZW50IHtcclxuICAgIHBhZGRpbmc6IDEwcHggMTBweCA1cHg7XHJcblxyXG4gICAgaDMge1xyXG4gICAgICBmb250LXNpemU6IDI0cHg7XHJcbiAgICB9XHJcbiAgICBhIHtcclxuICAgICAgYm9yZGVyLWJvdHRvbTogbm9uZTtcclxuICAgIH1cclxuICB9XHJcblxyXG4gICYtYWxsIHtcclxuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZSAhaW1wb3J0YW50O1xyXG4gICAgYm90dG9tOiAwO1xyXG4gICAgcmlnaHQ6IDA7XHJcbiAgICBmb250LXNpemU6IDkwJTtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/sub-category-navigation/sub-category-navigation.component.scss"],"names":[],"mappings":"AAGE;EACE,sBAAA;AAFJ;AAII;EACE,eAAA;AAFN;AAII;EACE,mBAAA;AAFN;AAME;EACE,6BAAA;EACA,SAAA;EACA,QAAA;EACA,cAAA;AAJJ;AACA,g2BAAg2B","sourcesContent":["@use 'mixins/breakpoints' as *;\r\n\r\n.parts-cat {\r\n  &-content {\r\n    padding: 10px 10px 5px;\r\n\r\n    h3 {\r\n      font-size: 24px;\r\n    }\r\n    a {\r\n      border-bottom: none;\r\n    }\r\n  }\r\n\r\n  &-all {\r\n    position: absolute !important;\r\n    bottom: 0;\r\n    right: 0;\r\n    font-size: 90%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
