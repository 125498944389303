import { Component, Input, OnInit } from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import {
  getHistoricMachineEnergyUtilizationProperty,
  getHistoricMachineUtilizationProperty,
} from '../../../../../contracts/clearsky/machine/machine.historic';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { MatDialog } from '@angular/material/dialog';
import { MachineUtilizationDialogComponent } from '../../machine-utilization-dialog/machine-utilization-dialog.component';
import { MachineDialogConfig } from '../../../../../contracts/clearsky/machine/machine.dialog.config';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { convertToLegendVal } from '../../../../shared/legend-value.pipe';
import { ClearskyService } from '../../../../clearsky.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-utilization',
  templateUrl: './asset-utilization.component.html',
  styleUrls: ['./asset-utilization.component.scss'],
})
export class AssetUtilizationComponent implements OnInit {
  @Input() machine: Machine;
  widgetName = MachineWidgets.MACHINE_UTILIZATION;
  displayName = MachineWidgetsDisplay.machineUtilization;
  historic = HistoricSelection.HOURS_24;
  modelType: string | undefined;
  historicMachineUtilization: number | undefined;
  historicMachineEnergyUtilization: number | undefined;
  ocids: OcidItems = {};
  isFuelType = false;

  constructor(
    private localization: LocalizationService,
    private clearskyService: ClearskyService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.hours-label',
        'clearsky.machine-utilization-label',
        'clearsky.liters-used-label',
        'clearsky.kwh-used-label',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.clearskyService.legendRef$.subscribe((legend) => {
      this.modelType = convertToLegendVal(this.machine.mtype, legend, 'mtype')?.toString().toLowerCase();

      const powerSource = convertToLegendVal(
        this.machine.pwrSrc,
        legend,
        'pwrSrc'
      )?.toString()
        .toLowerCase();
      this.isFuelType = powerSource.toLowerCase().includes('engine');
    });

    this.updateHistoricData();
  }

  /**
   * On historic change.
   * @param historic
   */
  onHistoricChange(historic: string): void {
    this.historic = historic;
    this.updateHistoricData();
  }

  /**
   * Open machine utilization dialog.
   */
  openMachineUtilizationDialog(): void {
    this.dialog.open(MachineUtilizationDialogComponent, {
      ...MachineDialogConfig,
      data: {
        machine: this.machine,
      },
    });
  }

  /**
   * Update machine utilization based on historic selection.
   * @private
   */
  private updateHistoricData(): void {
    this.historicMachineUtilization = getHistoricMachineUtilizationProperty(
      this.machine,
      this.historic
    );
    this.historicMachineEnergyUtilization =
      getHistoricMachineEnergyUtilizationProperty(this.machine, this.historic);
  }
}
