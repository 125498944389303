import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClearskyDashboardService } from '../../clearsky-dashboard.service';
import { ClearskyService } from '../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CsDashboard } from 'app/contracts/clearsky/dashboard/cs-dashboard.dto';
import { MatCheckbox } from '@angular/material/checkbox';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

interface DialogData {
  widgets: any[];
  filters: any[];
  dashboard?: any;
  edit: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-save-dashboard-dialog',
  templateUrl: './save-dashboard-dialog.component.html',
})
export class SaveDashboardDialogComponent implements OnInit {
  private subs: Subscription;
  @ViewChild('updateCheckbox') updateCheckbox: MatCheckbox;
  editMode = this.data?.edit;
  form: UntypedFormGroup = this.fb.group({
    name: this.fb.control(this.editMode ? this.data.dashboard.name : ''),
    shareWith: this.fb.control(''),
    default: this.fb.control(false)
  });
  reportFormKey = 'report';
  errorMessage: string | null = null;
  loadedDashboard$: Observable<CsDashboard> =
    this.dashboardService.loadedDashboard$;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<SaveDashboardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private clearskyService: ClearskyService,
    private dashboardService: ClearskyDashboardService,
    private snackbar: MatSnackBar,
    private localization: LocalizationService,
    private gAService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.save-dashboard-label',
        'clearsky.save-to-default',
        'clearsky.save-as-default',
        'clearsky.dashboard-name-label',
        'clearsky.email-filter-default',
        'clearsky.email-comma-default',
        'clearsky.email-report-default',
        'clearsky.save-label'
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * On save dashboard.
   */
  onSave(): void {
    this.errorMessage = null;
    if (this.editMode) {
      this.errorMessage = null;
      const loadDefault = this.form.value.default;
      this.gAService.eventEmitter('clearsky_click', 'dashboard', 'update', 'dashboard_id', this.data?.dashboard.id);
      this.dashboardService.update(this.data?.dashboard.id,
        {
          name: this.form.value.name,
          data: JSON.parse(this.data?.dashboard.data)
        },
        loadDefault
      ).pipe(
        catchError((error) => {
          this.errorMessage = error.error.title;
          return of(error);
        }))
        .subscribe((response) => {
          if (response?.status === 400) {
            return;
          }
          this.dialogRef.close(response);
        });
    } else {
      this.subs = combineLatest([
        this.clearskyService.currentFilters$,
        this.clearskyService.currentColumns$,
        this.clearskyService.currentWidgets$
      ])
        .pipe(
          take(1),
          mergeMap(([filters, columns, widgets]) => {
            const data = {
              name: '',
              data: {
                filters,
                widgets,
                columns,
              },
            };

            if (this.form.valid) {
              // new dashboard
              data.name = this.form.get('name').value;
              return this.dashboardService.create(data);
            }
          }),
          mergeMap((dashboard) => {
            // Do we need to share this dashboard?
            if (this.form.get('shareWith').value) {
              return this.dashboardService
                .share(dashboard.id, this.form.get('shareWith').value)
                .pipe(
                  tap(() => {
                    this.snackbar.open(
                      `${this.ocids['global.email-sent-label']}.`,
                      null,
                      {
                        duration: 3000,
                        verticalPosition: 'top',
                      }
                    );
                  })
                );
            }

            this.gAService.eventEmitter('clearsky_click', 'dashboard', 'create', 'dashboard_id', dashboard.id);
            return of(dashboard);
          }),
          catchError((error) => {
            this.errorMessage = error.error.title;
            return of(error);
          })
        )
        .subscribe((response) => {
          if (response?.status === 400) {
            return;
          }
          this.dialogRef.close(response);
        });
    }
  }
}
