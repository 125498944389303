import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../../../cartridge/cartridge.class';

@Component({
  selector: 'app-flyout-panel',
  templateUrl: './flyout-panel.component.html',
  host: {
    'class': 'auto-suggest-panel'
  }
})
export class FlyOutPanelComponent implements OnInit, CartridgeInterface {
  @Input('data') data: {
    flyOut: object[]
  };

  constructor() { }

  ngOnInit() {
  }

}
