import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClearskyService } from '../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { WidgetService } from '../../../widget.service';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { debounceTime, first } from 'rxjs/operators';
import { mergeDeep } from '../../../../shared/deep-merge';
import {
  BaseChartConfig,
  BasePlotChart,
} from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import { CsAggIgnitionStatusData } from '../../../../contracts/clearsky/agg-data';
import { OcidItems } from '../../../../contracts/ocid-items';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { combineLatest, Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-engine-status',
  templateUrl: './engine-status.component.html',
  styleUrls: ['./engine-status.component.scss'],
})
export class EngineStatusComponent implements OnInit {
  chartEl: ElementRef;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  widgetName = Widgets.IGNITION_STATUS;
  displayName = WidgetsDisplay.ignitionStatus;
  activePerc: number;
  inactivePerc: number;
  private filter: unknown[] = [];
  ocids: OcidItems = {};
  private chartData = mergeDeep(cloneDeep(BasePlotChart.pie), {
    title: {
      text: this.ocids['clearsky.ignition-on-label'],
      align: 'center',
      verticalAlign: 'middle',
      y: 70,
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        center: ['50%', '75%'],
        size: '120%',
      },
      series: {
        events: {
          click: this.onChartClick.bind(this),
        },
      },
    },
  });
  isLoading = true;
  showChart = false;
  data: {
    name: string;
    y: number;
    color: string;
    visible: boolean;
    custom: {
      value: boolean;
    };
  }[] = [];
  private aggData: CsAggIgnitionStatusData | undefined;
  private plot;
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private widgetService: WidgetService,
    private dialog: MatDialog,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.getCurrentFilterValues(CSFilter.ign.key),
      this.localization.getOCIDs([
        'clearsky.graphic-default',
        'clearsky.ignition-on-label',
        'clearsky.ignition-off-label',
        this.displayName,
      ]),
    ])
      .pipe(debounceTime(0))
      .subscribe(([page, filter, ocids]) => {
        this.ocids = ocids;
        this.aggData = page && page.aggregations && page.aggregations.ign;
        this.filter = filter;
        this.resetChartData();
        this.setChartData();
        this.isLoading = false;

        // Does the chart already exist?
        if (this.plot && this.showChart) {
          this.createChart();
        }
      });
  }

  /**
   * Set chart title.
   * @protected
   */
  protected setChartTitle(): void {
    this.plot.setTitle({
      text: `<span class="donut-center-text-main">${
        this.aggData ? this.aggData.on : 0
      }</span><br /><span class="donut-center-text-sub">${this.ocids['clearsky.ignition-on-label']}</span>`,
    });
  }

  /**
   * Set chart data.
   * @protected
   */
  private setChartData(): void {
    this.data = [];

    // Set on data
    this.data.push({
      name: this.ocids['global.on-label'],
      y: this.aggData ? this.aggData.on : 0,
      color: WidgetColors.blue,
      visible: !this.filter.length || this.filter.includes(true),
      custom: {
        value: true,
      },
    });

    // Set off data
    this.data.push({
      name: this.ocids['global.off-label'],
      y: this.aggData ? this.aggData.off : 0,
      color: WidgetColors.grey,
      visible: !this.filter.length || this.filter.includes(false),
      custom: {
        value: false,
      },
    });

    this.showChart = true;

    // Now grab filtered machines and let's set percentages
    const total = this.aggData ? this.aggData.on + this.aggData.off : 0;
    const inactive = this.aggData ? this.aggData.off : 0;
    const active = this.aggData ? this.aggData.on : 0;
    this.inactivePerc = Math.round((inactive / total) * 100);
    this.activePerc = Math.round((active / total) * 100);
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    this.chartData = mergeDeep(cloneDeep(BaseChartConfig), this.chartData);

    this.plot = Highcharts.chart(this.chartEl.nativeElement, this.chartData);
    this.setChartSeries();
  }

  /**
   * Set series for chart.
   * @protected
   */
  private setChartSeries(): void {
    // By default we do one level of series
    const series = {
      name: this.ocids[this.displayName],
      type: 'pie',
      colorByPoint: true,
      innerSize: '50%',
      data: this.data,
    };

    this.plot.addSeries(series);

    // Update chart title
    this.setChartTitle();
  }

  /**
   * On chart click.
   * @protected
   */
  private onChartClick(e: { point: Highcharts.Point }): void {
    this.clearskyService.updateFilter(CSFilter.ign.key, [
      e.point.options.custom?.value,
    ]);
  }

  /**
   * Reset chart data.
   * @protected
   */
  private resetChartData(): void {
    if (!this.plot) {
      return;
    }

    this.plot.series[0].remove();
    this.plot.redraw();
  }
}
