import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../../../../../shared/localization/localization.service';
import { IPayInvoiceDto } from '../../../../../../contracts/invoice/ipay-invoice-dto';
import { IUser } from '../../../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PaymentOptions } from '../../../../../../contracts/commerce/dto/ipayment-info';
import { OcidItems } from '../../../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-customer-receipt',
  styleUrls: ['../invoice-information.component.scss'],
  templateUrl: './customer-receipt.component.html',
})
export class CustomerReceiptComponent implements OnInit {
  @Input() user!: IUser;
  @Input() dateFormat!: string;
  @Input() invoicePayDto!: IPayInvoiceDto;
  @Output() print: EventEmitter<string> = new EventEmitter<string>();
  ocids: OcidItems = {};
  datePaid!: string;
  paymentOptions = PaymentOptions;

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Subscribe to get OCIDs.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    // Set up the invoice date . Must set it up in the format the backend sends dates for the formatDate pipe
    // to work correctly. The format is YYYYMMDD.
    const today = new Date();
    const year = today.getFullYear().toString();
    const monthTester = (today.getMonth() + 1).toString();
    const month =
      monthTester.length === 1
        ? '0' + monthTester.toString()
        : monthTester.toString();
    const day = today.getDate().toString();
    this.datePaid = year + month + day;
  }

  /**
   * Print the customer receipt.
   */
  printReceipt() {
    this.print.emit('customerReceipt');
  }
}
