import { CsFilterBuilder } from './cs-filter-builder';
import { CSFilterColumn, CSFilterType, QAPIFilter } from '../machine-filter-v2';

export class CsInCsFilterBuilder extends CsFilterBuilder {
  /**
   * QAPI filter builder.
   * @param values
   */
  buildFilter(values: unknown[]): QAPIFilter[] {
    return [
      {
        column: CSFilterColumn[this.filter.key],
        filterType: CSFilterType.IN_CS,
        values: values,
      },
    ];
  }
}
