import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
})
export class UploadFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ocids!: OcidItems;
  @Input() uploadForm!: UntypedFormGroup;
  @Input() required = false;
  @Input() type = 'attachment' || 'package-image' || 'part-image';
  uploadLabel = '';
  uploadNote = '';
  addAdditionalLabel = '';
  uploadAdded = false;
  fileTypeError = false;
  fileSizeError = false;
  fileUploadError = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.required) {
      this.uploadForm.addControl(
        'uploadInput',
        this.formBuilder.array([
          this.formBuilder.group({
            FileName: ['', Validators.required],
            FileBase64: ['', Validators.required],
          }),
        ])
      );
    } else {
      this.uploadForm.addControl('uploadInput', this.formBuilder.array([]));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.required && !changes.required.firstChange) {
      if (!this.uploadAdded) {
        if (!changes.required.currentValue) {
          this.uploadInput.removeAt(0);
        } else if (changes.required.currentValue) {
          this.uploadInput.push(
            this.formBuilder.group({
              FileName: ['', Validators.required],
              FileBase64: ['', Validators.required],
            })
          );
        }
        this.cd.detectChanges();
      }
    }
    // Check when the type of upload file has changed, or ocids, in order to display the correct labels to
    // the user.
    if (changes.type || changes.ocids) {
      // Set the upload and add additional labels based on the upload type.
      if (this.type === 'attachment') {
        this.uploadLabel = this.ocids['web2case.upload-attachment'];
        this.addAdditionalLabel =
          this.ocids['web2case.add-additional-attachment'];
      } else if (this.type === 'package-image') {
        this.uploadLabel = this.ocids['web2case.upload-packaging-image'];
        this.addAdditionalLabel = this.ocids['web2case.add-additional-image'];
        this.uploadNote = this.ocids['w2c.picture-message'];
      } else {
        this.uploadNote = this.ocids['w2c.picture-message'];
        this.uploadLabel = this.ocids['web2case.upload-part-image'];
        this.addAdditionalLabel = this.ocids['web2case.add-additional-image'];
      }
    }
  }

  /**
   * On select file.
   * @param {File} file
   */
  onSelectFile(file: File) {
    // Reset any errors.
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.fileUploadError = false;
    // Ensure that the file name is of type jpeg, png, doc, or pdf.
    if (
      !file.name.match('.{1,}.(jpg|jpeg|png|doc|pdf|JPG|JPEG|PNG|DOC|PDF)$')
    ) {
      this.fileTypeError = true;
    } else if (file.size / 1000000 > 10) {
      // Ensure that the file size is less than 10 MB.
      this.fileSizeError = true;
    } else {
      // If the previous two test cases pass, get the base64 string of the file.
      this.getBase64(file);
    }
  }

  /**
   * Gets the base64 string of the file.
   * @param {File} file
   */
  getBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Remove the file type from the base64 string so salesforce accepts the file.
      const newFile = {
        FileName: file.name,
        FileBase64: (<string>reader.result).split(',')[1],
      };
      // If we haven't yet uploaded a file and it is required, patch the first
      // form group with the current file name and base64 string.
      if (!this.uploadAdded && this.required) {
        this.uploadInput.at(0).patchValue(newFile);
      } else {
        // Otherwise, push a new form group to the form array.
        this.uploadInput.push(this.formBuilder.group(newFile));
      }
      this.cd.detectChanges();
      // Set uploaded to true.
      this.uploadAdded = true;
    };
    reader.onerror = (error) => {
      // If there was an error uploading the file, show an error.
      this.fileUploadError = true;
      console.log('Error uploading file: ', error);
    };
  }

  /**
   * Removes a parts row given its index.
   * @param {number} index
   */
  removeUpload(index: number) {
    this.uploadInput.removeAt(index);
    if (this.uploadInput.length === 0) {
      this.uploadAdded = false;
      if (this.required) {
        this.uploadInput.push(
          this.formBuilder.group({
            FileName: ['', Validators.required],
            FileBase64: ['', Validators.required],
          })
        );
      }
    }
  }

  /**
   * Gets the upload input form array.
   * @returns {FormGroup}
   */
  get uploadInput() {
    return <UntypedFormArray>this.uploadForm.get('uploadInput');
  }

  ngOnDestroy() {
    if (this.cd) {
      this.cd.detach();
    }
  }
}
