import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapInfoBoxComponent } from './map-info-box.component';
import { MapInfoBoxHeaderItemComponent } from '../map-info-box-header-item/map-info-box-header-item.component';

@NgModule({
  declarations: [MapInfoBoxComponent, MapInfoBoxHeaderItemComponent],
  exports: [MapInfoBoxComponent, MapInfoBoxHeaderItemComponent],
  imports: [CommonModule],
})
export class MapInfoBoxModule {}
