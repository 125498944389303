import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import {
  IRefinementBreadcrumb,
  DimensionName,
} from '../../../contracts/IRefinementBreadcrumb';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { OcidItems } from '../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-refinement-menu',
  styleUrls: ['./refinement-menu.component.scss'],
  templateUrl: './refinement-menu.component.html',
})
export class RefinementMenuComponent implements OnInit {
  @Input('data') menu!: {
    dimensionName: string;
    displayName: string;
    multiSelect: boolean;
    refinements: IRefinementBreadcrumb[];
  };
  items: {
    label: string;
    url: string;
    count: number;
    isChecked: boolean;
  }[] = [];
  filterText = '';
  ocids!: OcidItems;
  showList = true;
  isArticleResult = false;
  dimensionName = DimensionName;

  filteredDimension = [
    `${DimensionName.KNOWLEDGE}.models`,
    `${DimensionName.MANUALS}.modelNumber`,
    `${DimensionName.SOFTWARE}.modelNumber`,
    `${DimensionName.PRODUCT}.modelNumber`,
    `${DimensionName.TECHPUB}.modelNumber`,
    `${DimensionName.TECHPUB}.manualLanguage`,
    `${DimensionName.MANUALS}.creationYear`,
    `${DimensionName.TECHPUB}.pvc`,
  ];

  showExtraItems = false;
  platformBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private router: Router,
    private winRef: WindowRefService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    // Restructure refinements
    for (const refinement of this.menu.refinements) {
      this.pushRefinement(refinement);
    }

    // Get ocids.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));

    // Get the ocids needed for this specific component.
    this.localization.getOCIDs(['browse.category']).subscribe();

    this.isArticleResult =
      this.menu.dimensionName.indexOf(DimensionName.KNOWLEDGE) > -1 ||
      this.menu.dimensionName.indexOf(DimensionName.MANUALS) > -1 ||
      this.menu.dimensionName.indexOf(DimensionName.TECHPUB) > -1 ||
      this.menu.dimensionName.indexOf(DimensionName.SOFTWARE) > -1;
  }

  navigateTo(url: string, selectedItem: string): void {
    this.router.navigate([decodeURIComponent(url)]);
    if (this.platformBrowser && this.isArticleResult) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event:
          this.menu.dimensionName.indexOf(DimensionName.KNOWLEDGE) > -1
            ? 'articleFilter'
            : 'manualsFilter',
        filterCategory: this.menu.displayName ?? this.ocids['browse.category'],
        filterSelected: selectedItem,
      });
      this.document.body.classList.remove('tw-overflow-hidden');
    }
  }

  private pushRefinement(refinement: IRefinementBreadcrumb) {
    this.items.push({
      label: refinement.label,
      url: refinement.contentPath + refinement.navigationState,
      count: refinement.count,
      isChecked: refinement.properties.selected,
    });
  }
}
