import {
  Component,
  EventEmitter,
  Input,
  Inject,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ScannerService } from 'app/service/scanner.service';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { ICommerceItemSerialNumber } from '../../../../../contracts/commerce/icommerce-item-serial-number';
import { ICommerceItemSerialNumbers } from '../../../../../contracts/commerce/icommerce-item-serial-numbers';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { CartService } from '../../../../../service/cart/cart.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';

@Component({
  selector: 'app-psr289-serial-num-req-warning',
  templateUrl: './psr289-serial-num-req-warning.component.html',
})
export class Psr289SerialNumReqWarningComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() items: ICommerceItemWithCart[] = [];
  @Output() serialsValidated: EventEmitter<any> = new EventEmitter<any>();
  public ocids = {};
  public form: UntypedFormGroup;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private fb: UntypedFormBuilder,
    private cartService: CartService,
    private notificationService: NotificationService,
    private scanner: ScannerService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    // Set localization
    this.localization.OCIDs.pipe(takeUntil(this.unsubscribe)).subscribe(
      (ocids: {}) => {
        this.ocids = ocids;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      // Build form
      this.buildForm();
    }
  }

  /**
   * Build form for serial numbers.
   */
  protected buildForm() {
    // Build form based on number of items that require a serial num
    this.form = this.fb.group({
      items: this.fb.array(
        this.items.map((item: ICommerceItemWithCart) => {
          // Now loop through the quantity since we need serial numbers for each quantity in the cart
          const controls: UntypedFormGroup[] = [];

          for (let i = 0; i < item.quantity; i++) {
            // Prepopulate serial number if it exists in payload
            const value = item.campaignSerialNumbers[i] || null;
            controls.push(
              this.fb.group({
                serialNum: [value, [Validators.required]],
              })
            );
          }

          return this.fb.group({
            serialNums: this.fb.array(controls),
          });
        })
      ),
    });
  }

  public onAdd() {
    if (this.form.valid) {
      this.notificationService.reset();
      // Go through each commerce item to update it's serial numbers
      this.zone.runOutsideAngular(() => {
        const batch: Observable<ICommerceItemSerialNumbers>[] =
          this.formItems.controls.map(
            (itemCtrl: AbstractControl, index: number) => {
              const item: ICommerceItemWithCart = this.items[index];
              const serialNums = this.formSerialNums(index).controls.map(
                (numCtrl: AbstractControl) => numCtrl.get('serialNum').value
              );

              return this.cartService
                .addSerialNums({
                  itemNumber: item.itemNumber,
                  campaignSerialNumbers: serialNums,
                })
                .pipe(
                  takeUntil(this.unsubscribe),
                  first(),
                  catchError((err) => {
                    this.notificationService.addError(err.error?.title);

                    return of(null);
                  })
                );
            }
          );

        return forkJoin(batch)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            (res: ICommerceItemSerialNumbers[]) => {
              this.zone.run(() => {
                const isValid = this.checkSerialValidity(res);

                // If all valid, emit event to refresh cart
                if (isValid) {
                  this.serialsValidated.emit();
                }
              });
            },
            (error) => {
              this.zone.run(() => {
                this.notificationService.addError(error.error.title);
              });
            }
          );
      });
    }
  }

  /**
   * Check for valid serial numbers in item serial numbers response.
   *
   * @param {ICommerceItemSerialNumbers[]} items
   */
  protected checkSerialValidity(items: ICommerceItemSerialNumbers[]) {
    let valid = true;

    // Loop through the responses to see if all serial numbers were valid
    items.forEach(
      (response: ICommerceItemSerialNumbers, responseIndex: number) => {
        response.items.forEach(
          (serialNum: ICommerceItemSerialNumber, serialIndex: number) => {
            // Was the serial number valid?
            if (!serialNum.valid) {
              valid = false;
              this.getSerialNumControl(responseIndex, serialIndex).setErrors({
                invalid: true,
              });
            }
          }
        );
      }
    );

    return valid;
  }

  /**
   * Get serial number form control for index.
   *
   * @param {number} itemIndex
   * @param {number} serialIndex
   * @return AbstractControl|null
   */
  public getSerialNumControl(
    itemIndex: number,
    serialIndex: number
  ): AbstractControl | null {
    return this.formSerialNums(itemIndex).controls[serialIndex].get(
      'serialNum'
    );
  }

  /**
   * Get FormArray of items.
   *
   * @returns {FormArray}
   */
  get formItems() {
    return <UntypedFormArray>this.form.get('items');
  }

  /**
   * Get FormArray of serial numbers for specified item index.
   *
   * @param {number} index
   * @returns {FormArray}
   */
  public formSerialNums(index: number) {
    return <UntypedFormArray>(
      (<UntypedFormGroup>this.formItems.controls[index]).get('serialNums')
    );
  }

  /**
   * Open the barcode scanner
   */
  async openScanner(itemIndex, serialIndex) {

    (await this.scanner.scanSerialNum('Shopping Cart')).subscribe((data?) => {
      if (data) {
        this.formSerialNums(itemIndex)
          .controls[serialIndex].get('serialNum')
          .setValue(data);
      }
    });

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
