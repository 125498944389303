export const MachinePushpinColors = {
  DEFAULT: '#696557',
  INSIDE_RADIUS: '#FF5405',
  INSIDE_NETWORK: '#00c1d4',
  OUTSIDE_NETWORK: '#696557',
  HIGHLIGHTED: '#93d669',
};

export function getBingMapInstance(
  element: HTMLElement,
  options?: object
): Microsoft.Maps.Map {
  return new Microsoft.Maps.Map(element, {
    showMapTypeSelector: false,
    ...options,
  });
}
