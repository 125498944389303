import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { OcidItems } from 'app/contracts/ocid-items';
import { ScannerService } from 'app/service/scanner.service';
import { IOCIDAndValue } from '../../web2case.component';

@Component({
  selector: 'app-machine-down-form',
  templateUrl: './machine-down-form.component.html',
})
export class MachineDownFormComponent implements OnInit {
  @Input() ocids: OcidItems;
  @Input() machineDownForm: UntypedFormGroup;
  provideAssistanceOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.service-parts',
      value: 'Service/parts',
    },
    {
      ocid: 'web2case.parts-only',
      value: 'Parts only',
    },
  ];
  defaultQuantity: number = null;
  requiredToAddParts = true;
  uploadRequired = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private scanner: ScannerService
  ) {}

  ngOnInit() {
    this.machineDownForm.addControl(
      'Level',
      this.formBuilder.control('', Validators.required)
    );
  }

  /**
   * Event fired when the provide assistance dropdown select has changed. Adds various
   * form controls and groups based on its value.
   * @param {MatSelectChange} event
   */
  provideAssistanceChanged(event: MatSelectChange) {
    this.uploadRequired = false;
    const provideAssistanceValue: string = event.value;
    if (
      provideAssistanceValue === 'Service/parts' ||
      provideAssistanceValue === 'Parts only'
    ) {
      if (!this.machineDownForm.get('MachineSerial')) {
        this.machineDownForm.addControl(
          'MachineSerial',
          this.formBuilder.control('')
        );
      }
      if (!this.machineDownForm.get('FleetNumber')) {
        this.machineDownForm.addControl(
          'FleetNumber',
          this.formBuilder.control('')
        );
      }
      if (!this.machineDownForm.get('CustomerPO')) {
        this.machineDownForm.addControl(
          'CustomerPO',
          this.formBuilder.control('')
        );
      }
      if (!this.machineDownForm.get('CaseDescription')) {
        this.machineDownForm.addControl(
          'CaseDescription',
          this.formBuilder.control('', Validators.required)
        );
      }
    } else {
      this.machineDownForm.removeControl('MachineSerial');
      this.machineDownForm.removeControl('FleetNumber');
      this.machineDownForm.removeControl('CustomerPO');
      this.machineDownForm.removeControl('CaseDescription');
    }
    if (provideAssistanceValue === 'Service/parts') {
      this.uploadRequired = true;
      if (!this.machineDownForm.get('MachineFaultDescription')) {
        this.machineDownForm.addControl(
          'MachineFaultDescription',
          this.formBuilder.control('')
        );
      }
      if (!this.machineDownForm.get('EngineFaultDescription')) {
        this.machineDownForm.addControl(
          'EngineFaultDescription',
          this.formBuilder.control('')
        );
      }
      if (!this.machineDownForm.get('jobSiteInfoForm')) {
        this.machineDownForm.addControl(
          'jobSiteInfoForm',
          new UntypedFormGroup({})
        );
      }
      if (!this.machineDownForm.get('uploadForm')) {
        this.machineDownForm.addControl('uploadForm', new UntypedFormGroup({}));
      }
    } else {
      this.machineDownForm.removeControl('MachineFaultDescription');
      this.machineDownForm.removeControl('EngineFaultDescription');
      this.machineDownForm.removeControl('jobSiteInfoForm');
      this.machineDownForm.removeControl('uploadForm');
    }
    if (
      provideAssistanceValue === 'Parts only' ||
      provideAssistanceValue === 'Service/parts'
    ) {
      if (!this.machineDownForm.get('OrderNumber')) {
        this.machineDownForm.addControl(
          'OrderNumber',
          this.formBuilder.control('')
        );
      }
      if (!this.machineDownForm.get('addPartsForm')) {
        this.machineDownForm.addControl(
          'addPartsForm',
          new UntypedFormGroup({})
        );
      }
      if (provideAssistanceValue === 'Parts only') {
        this.defaultQuantity = 1;
        this.requiredToAddParts = true;
      } else {
        this.defaultQuantity = null;
        this.requiredToAddParts = false;
      }
    } else {
      this.machineDownForm.removeControl('OrderNumber');
      this.machineDownForm.removeControl('addPartsForm');
    }
  }

  /**
   * Gets the job site form.
   * @returns {FormGroup}
   */
  get jobSiteInfoForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.machineDownForm.get('jobSiteInfoForm');
  }
  /**
   * Gets the upload form.
   * @returns {FormGroup}
   */
  get uploadForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.machineDownForm.get('uploadForm');
  }
  /**
   * Gets the add parts form.
   * @returns {FormGroup}
   */
  get addPartsForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.machineDownForm.get('addPartsForm');
  }
  /**
   * Opens barcode scanner
   */
  async openScanner() {

    (await this.scanner.scanSerialNum('Web2Case - Machines Down')).subscribe((data?) => {
      if (data) {
        this.machineDownForm.get('MachineSerial').setValue(data);
      }
    });

  }
}
