import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-badge-label',
  templateUrl: './badge-label.component.html',
  styleUrls: ['./badge-label.component.scss'],
  host: {'class': 'badge-label'}
})
export class BadgeLabelComponent {

  _color: string;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(val: string) {
    this._color = val;
    // when the input is set check it and set isFoo;
    this.isPrimary = (val === 'primary');
    this.isAccent = (val === 'accent');
    this.isWarn = (val === 'warn');
  }
  @HostBinding('class.badge-label-primary') isPrimary: boolean = true;
  @HostBinding('class.badge-label-accent') isAccent: boolean = false;
  @HostBinding('class.badge-label-warn') isWarn: boolean = false;
  @HostBinding('class.badge-label-inverted') @Input() inverted: boolean = false;

  constructor() { }

}
