import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {OktaAuthWrapper} from '../../service/auth/okta.auth.wrapper';

@Component({
  selector: 'app-ending-soon',
  templateUrl: './ending-soon.component.html'
})
export class EndingSoonComponent {
  constructor(
    public dialogRef: MatDialogRef<EndingSoonComponent>,
    public oktaAuth: OktaAuthWrapper
  ) {
  }
}
