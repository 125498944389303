export const appOcids: string[] = [
  // global ocids
  'account.required-text',
  'account.user-role-select-one',
  'global.accept-text',
  'global.account-address-label',
  'global.add',
  'global.add-item',
  'global.add-part',
  'global.add-shopping-list',
  'global.add-to-cart',
  'global.address',
  'global.address-1',
  'global.address-2',
  'global.address-3',
  'global.additional-comments',
  'global.agreed',
  'global.alpha-numeric',
  'global.available-qty-label',
  'global.billing-address',
  'global.back',
  'global.branch-store-number',
  'global.buy-again',
  'global.buy-it-again-label',
  'global.cancel',
  'global.case-number-label',
  'global.cases-number-label',
  'global.category-model',
  'global.change-address',
  'global.checkout-comments-label',
  'global.checkout-edit-cart-label',
  'global.city',
  'global.clear-all',
  'global.close',
  'global.comments',
  'global.company-information',
  'global.company-name',
  'global.confirm',
  'global.confirm-password',
  'global.confirm-password-message-1',
  'global.confirmation',
  'global.contact-name',
  'global.contact-information',
  'global.cookie-acceptance-message',
  'global.copy-link',
  'global.country',
  'global.created-by',
  'global.creation-date',
  'global.credit-card-month',
  'global.credit-card-year',
  'global.customer-name',
  'global.customer-number',
  'global.customer-pickup',
  'global.declined',
  'global.delete',
  'global.description',
  'global.diesel',
  'global.dimension',
  'global.documentation',
  'global.download-file',
  'global.due-date',
  'global.edit',
  'global.email',
  'global.email-address',
  'global.email-address-error',
  'global.export',
  'global.export-excel',
  'global.fax-number',
  'global.file-size',
  'global.first-name',
  'global.gasoline',
  'global.height',
  'global.here',
  'global.imperial',
  'global.invalid-qty',
  'global.invoice',
  'global.invoice-amount',
  'global.invoice-date',
  'global.item',
  'global.item-description',
  'global.item-number',
  'global.item-total',
  'global.items-per-page',
  'global.jerrdan-account-number',
  'global.jlg-account-number',
  'global.keywords',
  'global.last-name',
  'global.length',
  'global.level-number-label',
  'global.any-level-label',
  'global.link-copied',
  'global.list-price',
  'global.login-id',
  'global.metric',
  'global.model-number',
  'global.model-series-label',
  'global.move-to-shopping-list',
  'global.my-account-label',
  'global.my-account-label-2',
  'global.name',
  'global.net',
  'global.net-item',
  'global.new-request',
  'global.next',
  'global.no',
  'global.no-results-message',
  'global.no-special-character-message',
  'global.no-thanks',
  'global.notes',
  'global.ok',
  'global.online-express',
  'global.or',
  'global.order-number-label',
  'global.order-returns-label',
  'global.order-subtotal',
  'global.order-total',
  'global.order-type',
  'global.orders',
  'global.other',
  'global.parcel',
  'global.part-component-item',
  'global.part.consult-factory',
  'global.part.core-return',
  'global.part.direct-ship',
  'global.part-hazmat',
  'global.part-phantom',
  'global.part-qty-per-component',
  'global.part-recommended-qty',
  'global.part-shipping-type',
  'global.part-unit-price',
  'global.parts',
  'global.password',
  'global.password-difference-label',
  'global.password-requirement-1',
  'global.password-requirement-2',
  'global.password-requirement-3',
  'global.password-requirement-4',
  'global.password-requirement-5',
  'global.password-requirement-6',
  'global.password-requirement-7',
  'global.payment-label',
  'global.personal-privacy',
  'global.phantom-item-label',
  'global.phone-number',
  'global.pickup-address',
  'global.po-number',
  'global.postal-code',
  'global.postal-code-hint-text',
  'global.preferred-language',
  'global.print',
  'global.print-friendly',
  'global.privacy',
  'global.private',
  'global.proceed',
  'global.public',
  'global.qty',
  'global.qty-ordered-label',
  'global.quantity',
  'global.read-privacy',
  'global.read-terms',
  'global.remove',
  'global.remove-all',
  'global.required',
  'global.return-form',
  'global.return-location-label',
  'global.return-number-label',
  'global.returns-label',
  'global.reset',
  'global.asset-id-label',
  'global.asset-label',
  'global.network-label',
  'global.generate-report-label',
  'global.alerts-label',
  'global.email-sent-label',
  'global.date-range',
  'global.new-dashboard-label',
  'global.editing-label',
  'global.get-directions-label',
  'global.close-update-label',
  'global.on-label',
  'global.quick-links',
  'global.off-label',
  'global.safety-specification-label',
  'global.save',
  'global.search',
  'global.secondary-warehouse',
  'global.security-answer',
  'global.security-question',
  'global.select-all',
  'global.selected-items',
  'global.send',
  'global.serial-number',
  'global.ship-freight-payment',
  'global.ship-to-number',
  'global.shipment-shipment',
  'global.shipped-on',
  'global.shipping',
  'global.shipping-address',
  'global.shipping-address-information',
  'global.shipping-from',
  'global.shipping-instructions',
  'global.shipping-options',
  'global.ship-via-parcel',
  'global.ship-via-truck',
  'global.show-password',
  'global.sign-in-checkout-label',
  'global.state-province',
  'global.status',
  'global.status-number-label',
  'global.store-id',
  'global.street',
  'global.subject-number-label',
  'global.submit',
  'global.submitted-by-label',
  'global.summary-label',
  'global.surcharge',
  'global.system-error',
  'global.tax',
  'global.terms-of-use',
  'global.thank-you',
  'global.total',
  'global.truck',
  'global.truck-information',
  'global.type-number-label',
  'global.unit-of-measure',
  'global.unshipped-unshipped',
  'global.update',
  'global.user-information-label',
  'global.verify',
  'global.version',
  'global.view-all',
  'global.view-less',
  'global.view-only',
  'global.warranty',
  'global.weight',
  'global.width',
  'global.yes',
  'shopping-cart.confirm-item-added-message',
  'shopping-list.confirm-item-added-message',
  'csr-proxy.using-as',
  'account.email-invalid-message',
  'account.profile-title',
  'authentication.session-expired',
  'authentication.sign-out',
  'authentication.forgot-password',
  'global.help-center',
  'global.order-now',
  'global.request-processed',
  'global.search-accounts',
  'global.sign-in',
  'header.welcome',
  'navigation.technical-tips',
  'order-now.change-account',
  'order-now.error-message-1',
  'register.account-approved',
  'register.request-submitted',
  'signin.remember-me',
  'signin.account-locked',
  'signin.account-suspended',
  'signin.forgot-password-request',
  'signin.invalid-address',
  'signin.invalid-login',
  'signin.locked-email',
  'signin.register-for-an-account',
  'sign-in-modal.logout-message-1',
  'sign-in-modal.message-1',
  'sign-in-modal.sign-in-message-1',
  'reset-password.email-accepted-message',
  // Footer ocids
  'footer.copyright',
  // Pagination ocids
  'pagination.first-page',
  'pagination.of',
  'pagination.last-page',
  'pagination.next-page',
  'pagination.previous-page',
  'global.no-data-label',
  'global.directions-label',
  'global.default-shipping-address-label',
  'global.item-label',
  'global.model-type-label',
  'global.model-group-label',
  'global.company-name',
  'global.code-category-label',
  'global.continue-label',
  'global.clearsky-smart-fleet-label',
  'globally.annually-label',
  'global.product-type-label',
  'global.phantom-message',
  'global.attachments',
  'global.show-more-label',
  'global.show-less-label',
  "global.actual-h-size-label",
  "global.product-type-label",
  "global.connection-type-label",
  "global.max-flow-label",
  "global.max-pressure-label",
  "global.actual-w-size-label",
  "global.inside-diameter-label",
  "global.outside-diameter-label",
  "global.seal-material-label",
  "global.operating-pressure-label",
  "global.actual-d-size-label",
  "global.micron-rating-label",
  "global.maximum-flow-label",
  "global.filter-type-label",
  "global.connection-size-label",
  "global.hour-rating-label",
  "global.features-features-label",
  "global.initial-resistance-label",
  "global.included-components-label",
  "global.maximum-temperature-label",
  "global.media-material-label",
  "global.pleat-type-label",
  "global.filter-design-label",
  "global.final-fpm-label",
  "global.material-material-label",
  "global.thread-size-label",
  "global.fluid-type-label",
  "global.gasket-t-material thickness-label",
  "global.upgrade-label",
  "global.purchase-label",
  "global.cart-select-all",
  "global.cart-selected-items",
  "global.checkout-back-to-shipping-address",
  "global.checkout-back-to-shipping-options",
  "global.checkout-back-to-payment",
  "global.checkout-parts-order-confirmation-number",
  "global.checkout-return-home",
  "global.checkout-equipment-order-confirmation-number",
  "global.pickup-address-label",
  'global.cross-reference',
  'global.not-available-label',
  'global.attachment-part-number-label',
  'global.machine-label',
  'global.symptoms',
  'global.celsius-label',
  'global.fahrenheit-label',
  'global.current'
];
