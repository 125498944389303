import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { OcidItems } from '../../../../contracts/ocid-items';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../shared/localization/localization.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pending-account-dialog',
  templateUrl: './pending-account-dialog.component.html',
})
export class PendingAccountDialogComponent implements OnInit {
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<PendingAccountDialogComponent>,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization
      .getOCIDs([
        'register.account-approved',
        'sign-in.pending-approval-title',
        'global.close',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
