// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-orders-start span p:last-child {
  margin-bottom: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFjY291bnQtb3JkZXJzLXN0YXJ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBQ0UsMkJBQUE7QUFETiIsImZpbGUiOiJhY2NvdW50LW9yZGVycy1zdGFydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5hY2NvdW50LW9yZGVycy1zdGFydCB7XHJcbiAgc3BhbiB7XHJcbiAgICBwOmxhc3QtY2hpbGQge1xyXG4gICAgICBtYXJnaW4tYm90dG9tOiAwICFpbXBvcnRhbnQ7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/account/account-orders/account-orders-start/account-orders-start.component.scss"],"names":[],"mappings":"AAEI;EACE,2BAAA;AADN;AACA,gcAAgc","sourcesContent":[".account-orders-start {\r\n  span {\r\n    p:last-child {\r\n      margin-bottom: 0 !important;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
