import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { LayoutService } from '../../../service/layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';
import { ImageWithCTACard } from '../../../contracts/design-components';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-three-column',
  styleUrls: ['../jump-link-carousel/jump-link-carousel.component.scss'],
  templateUrl: './three-column.component.html',
})
export class ThreeColumnComponent implements CartridgeInterface, AfterViewInit {
  @Input() data!: {
    headlineText: string;
    subHeadlineText: string;
    backgroundColor: string;
    carousel: ImageWithCTACard[];
  };
  @ViewChild('carousel') carousel!: SlickCarouselComponent;
  slideConfig = {
    infinite: false,
    dots: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  activeSlide = 0;
  isCarousel = true;

  constructor(private layoutService: LayoutService) {}

  ngAfterViewInit(): void {
    this.layoutService.isTabletMax$.subscribe((isMobile) => {
      this.isCarousel =
        (!isMobile && this.data.carousel.length > 3) || isMobile;
    });

    this.carousel.afterChange.subscribe((changes) => {
      this.activeSlide = changes.currentSlide;
    });
  }
}
