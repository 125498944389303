import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Bowser from 'bowser';

if (environment.production) {
  enableProdMode();
}

// These are the browser versions Angular supports
const browser = Bowser.getParser(window.navigator.userAgent);
const isValidBrowser = browser.satisfies({
  chrome: '>106',
  firefox: '>101',
  opera: '>90',
  safari: '>13',
  'android browser': '>106',
  'Microsoft Edge': '>106',
});

Sentry.init({
  environment: environment.production ? 'production' : 'development',
  release: 'OLE@3.32',
  dsn: 'https://b7b48e8d3fe249f2a8f930f792140252@o4503983085060096.ingest.sentry.io/4504478380261376',
  beforeSend(event) {
    // Only send PROD events from supported browsers
    if (!event.request?.url?.includes('onlineexpress.j') || !isValidBrowser) {
      return null;
    }
    return event;
  },
  integrations: [
    new BrowserTracing({
      beforeNavigate: (context) => {
        // Only enable in PROD
        if (!location.host.includes('onlineexpress.j') || !isValidBrowser) {
          return undefined;
        }
        return context;
      },
      tracingOrigins: [/onlineexpress(-[ldt])*.(jlg|jerrdan).com\//],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // Avast extension error
    '_avast_submit',
    // Listrak related errors
    'listrakbi.com',
    '_ltk',
    'Non-Error exception captured',
    '0 Unknown Error',
    'Server returned code 503',
    'Server returned code 404',
    'Http failure during parsing',
    'api/v1/app/heartbeat',
    'UET',
    '"status":403',
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /extensions\//i,
    /^chrome:\/\//i,
    // Listrak
    /recs\.listrakbi\.com/i,
  ],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
