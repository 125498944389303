import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class HeartbeatService {
  
  constructor(private http: HttpClient) { }
  
  getHeartbeat() {
		return this.http.get(`${environment.ATG_BASE_URL}/app/heartbeat`);
  }

}
