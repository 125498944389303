import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CartService } from '../../../../service/cart/cart.service';
import { IUser, UserEnvironments } from '../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { IOrder, SalesType } from '../../../../contracts/commerce/iorder';
import { ICommerceItemWithCart } from '../../../../contracts/commerce/icommerce-item-with-cart';
import { ChangeAddressComponent } from '../change-address/change-address.component';
import { MatDialog } from '@angular/material/dialog';
import { IAddress } from '../../../../contracts/user/iaddress';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ICheckoutShippingAddress } from '../../../../contracts/commerce/dto/icheckout-shipping-address';
import { ICodeAndDesc } from '../../../../contracts/commerce/icode-and-desc';
import { NotificationService } from '../../../../service/notification/notification.service';
import { UserAddressesService } from '../../../../service/user/user-addresses.service';
import { ProductTypes } from 'app/contracts/product/iproduct';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shipping-address-section',
  templateUrl: './shipping-address-section.component.html',
})
export class ShippingAddressSectionComponent implements OnInit {
  @Input() cart: IOrder;
  @Input() cartItems: ICommerceItemWithCart[];
  @Input() isActive: boolean;
  @Input() shippingAddressChanged: string;
  @Input() customerPickup = false;
  @Output() formSubmitted: EventEmitter<object> = new EventEmitter<object>();
  @Output() toggleForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() shippingAddressChangedEvent: EventEmitter<any> =
    new EventEmitter<any>();
  shippingAddressForm: UntypedFormGroup;
  formWasSubmitted = false;
  shippingAddress: IAddress;
  user: IUser;
  ocids: {} = {};
  salesTypes: ICodeAndDesc[];
  salesTypeValue: ICodeAndDesc;
  showComments = false;
  showOrderType = false;
  isChineseUser: boolean;

  constructor(
    private notificationService: NotificationService,
    private cartService: CartService,
    private userService: CurrentUserService,
    public userAddressService: UserAddressesService,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.make-default-text',
        'checkout.shipping-options',
        'checkout.shipping-order-type-info-message',
        'shipping.change-shipping-link',
        'shipping-address.request-address',
        'store-id.info-icon',
      ])
      .subscribe();

    // Setup shipping form
    this.shippingAddressForm = this.fb.group({});

    // Set user information
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        this.isChineseUser = user.erpEnvironment === UserEnvironments.CC;

        // Add fields depending on user
        if (user.appConfig.showStoreId) {
          const validators = [];
          if (this.user.storeIdReqParts) {
            validators.push(Validators.required);
          }

          this.shippingAddressForm.addControl(
            'storeID',
            this.fb.control(
              this.cart.storeId ? this.cart.storeId : null,
              validators
            )
          );
        }

        // Show shipping comments?
        if (this.user.appConfig.showShippingComments) {
          this.shippingAddressForm.addControl(
            'comment',
            this.fb.control(this.cart.comments ? this.cart.comments : null)
          );
        }

        // Sales type?
        if (user.appConfig.showSalesTypeDropdown) {
          // Check order type
          this.cartService
            .getSalesTypes()
            .subscribe((types: ICodeAndDesc[]) => {
              this.salesTypes = types;
              this.showOrderType =
                this.cartItems.filter((item) => {
                  return item.productType === ProductTypes.MACHINE;
                }).length === 0 && !!this.salesTypes;
              // If the sales type code from cart exists and is in the sales type list return true, else false.
              const existsInList = this.cart.salesTypeCode
                ? this.salesTypes.some(
                    (type: ICodeAndDesc) =>
                      this.cart.salesTypeCode === type.code
                  )
                : false;
              // If it exists in the list, set the sales type to the sales type code, else set it to the users default sales type if it exists, else set it
              // to the first index in the sales type array.
              const salesTypeCode = existsInList
                ? this.cart.salesTypeCode
                : user.defaultSalesType
                ? user.defaultSalesType
                : types[0].code;
              this.salesTypeValue = {
                code: salesTypeCode,
                description: this.salesTypes.find(
                  (type: ICodeAndDesc) => type.code === salesTypeCode
                ).description,
              };
            });
        }

        // Determine if a shipping address already exists on the cart.
        // If it does, set it as the shipping address. This could also be the customer pickup address as well.
        if (this.cart.salesTypeCode === SalesType.Pickup) {
          const item = this.cart.shippingGroups.items[0];
          this.shippingAddress = {
            ...item.shippingAddress,
            shipToNumber: item.shipToNumber,
          };
        } else {
          // If the shipping address does not exist on the cart...
          // If the user is not in proxy...
          if (!user.inProxy) {
            // If the user has a shipping address, use it as the shipping address.
            if (user.shippingAddress) {
              this.shippingAddress = user.shippingAddress;
            }
          } else if (user.orgShippingAddress) {
            // If the user is in proxy and the org shipping address exists, use the orgShippingAddress.
            this.shippingAddress = user.orgShippingAddress;
          }
        }
      }
    });

    // Check if order contains equipment
  }

  /**
   * When form is submitted.
   */
  onSubmit() {
    this.formWasSubmitted = true;

    if (this.shippingAddressForm.valid) {
      const userData = {
        customerNumber: this.user.customerNumber,
      };
      const shipTo = {
        shipTo: this.shippingAddress,
      };

      const data = <ICheckoutShippingAddress>{
        ...shipTo,
        ...this.shippingAddressForm.value,
        ...userData,
      };
      this.formSubmitted.emit(data);
    }
  }

  /**
   * Open modal to change shipping address.
   */
  onChangeAddress(): void {
    const dialogRef = this.dialog.open(ChangeAddressComponent, {
      width: '850px',
    });

    dialogRef.afterClosed().subscribe((result: IAddress) => {
      if (result) {
        this.shippingAddress = result;
        // If the user has a default shipping address on their account...
        if (this.user.shippingAddress) {
          // If the newly selected address is the user's default... set the shipping
          // address changed value to 'madeDefault' that way the checkbox is disabled
          // and already selected.
          if (this.shippingAddress.id === this.user.shippingAddress.id) {
            this.shippingAddressChangedEvent.emit({
              shipToNumber: this.shippingAddress.shipToNumber,
              changed: 'madeDefault',
            });
          } else {
            // Otherwise, give the user the option to make the selected shipping
            // address their default.
            this.shippingAddressChangedEvent.emit({
              shipToNumber: this.shippingAddress.shipToNumber,
              changed: 'changed',
            });
          }
        } else {
          // Otherwise, give the user the option to make the selected shipping
          // address their default.
          this.shippingAddressChangedEvent.emit({
            shipToNumber: this.shippingAddress.shipToNumber,
            changed: 'changed',
          });
        }
      }
    });
  }

  /**
   * Toggle form to edit mode.
   */
  onToggleForm() {
    this.toggleForm.emit();
  }

  /**
   * Set the selected address as the default address.
   */
  setDefault() {
    // Reset any errors and start the spinner.
    this.notificationService.reset();
    // Set the default address
    this.userAddressService
      .makeDefaultAddress(this.shippingAddress, false)
      .subscribe(
        () => {
          // Stop the spinner.
          // Set the status of the shipping address changed value by emitting the 'madeDefault' value.
          this.shippingAddressChangedEvent.emit({
            shipToNumber: this.shippingAddress.shipToNumber,
            changed: 'madeDefault',
          });
        },
        (error) => {
          // Set the error and stop the spinner.
          this.notificationService.addError(error.error.title);
        }
      );
  }

  // Get form sales type
  get salesType() {
    return this.shippingAddressForm.get('salesType');
  }
}
