import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table-header',
  templateUrl: './pricing-plan-table-header.component.html',
  styleUrls: ['./pricing-plan-table-header.component.scss'],
})
export class PricingPlanTableHeaderComponent {
  @HostBinding('class') classes = 'pricing-plan-table-header';
}
