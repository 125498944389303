import { Machine } from '../machine/machine.dto';
import { SiteNetwork } from '../site-network';
import { CSLegend } from '../clearsky-legend';
import { CsAggData } from '../agg-data';

export interface Customer {
  customerNumber: string;
  machines:
    | Machine[]
    | {
        machine: Machine;
      }[];
  legend: CSLegend;
  aggregations?: CsAggData;
  siteNetworkBounds?: SiteNetwork[];
}

export const ClearskyLevels = {
  ADVANCED: 'advanced',
  BASIC: 'basic',
};
