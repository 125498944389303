// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-content {
  height: 90%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hY2hpbmUtYnJlYWRjcnVtYnMtZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsV0FBQTtBQUNGIiwiZmlsZSI6Im1hY2hpbmUtYnJlYWRjcnVtYnMtZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1hdC1tZGMtZGlhbG9nLWNvbnRlbnQge1xyXG4gIGhlaWdodDogOTAlO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/machine-detail/machine-breadcrumbs-dialog/machine-breadcrumbs-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACA,oXAAoX","sourcesContent":[".mat-mdc-dialog-content {\r\n  height: 90%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
