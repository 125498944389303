import { Component, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { EndecaService } from '../../endeca.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LayoutService } from '../../../service/layout.service';

interface IEmergencyAlerts {
  alerts: {
    content: string;
    title: string;
  }[];
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-emergency-alerts',
  styleUrls: ['./emergency-alerts.component.scss'],
  templateUrl: './emergency-alerts.component.html',
})
export class EmergencyAlertsComponent implements OnInit, CartridgeInterface {
  data!: IEmergencyAlerts;

  constructor(
    private endecaService: EndecaService,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.endecaService.emergencyAlerts$.subscribe(
      (emergencyAlerts: IEmergencyAlerts) => {
        this.data = emergencyAlerts;
        this.layoutService.setIsEmergencyAlertEnabled(!!this.data?.alerts);
      }
    );
  }

  ngOnDestroy(): void {
    this.layoutService.setIsEmergencyAlertEnabled(false);
  }
}
