import { Component, Input, OnInit } from '@angular/core';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import * as dayjs from 'dayjs';
import { BingMapsService } from '../../../service/bing-maps.service';
import { Observable, first } from 'rxjs';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';

@Component({
  selector: 'app-network-general-info',
  templateUrl: './network-general-info.component.html',
})
export class NetworkGeneralInfoComponent implements OnInit {
  @Input() network!: SiteNetwork;
  machinesAddedRemoved = 0;
  numberOfAlerts = 0;
  address$!: Observable<Microsoft.Maps.IAddress>;
  ocids: OcidItems = {};

  constructor(private bingMaps: BingMapsService, private localization: LocalizationService) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.machine-onsite-label',
        'clearsky.added-removed-label',
        'clearsky.created-label',
        'clearsky.of-label',
      ])
      .pipe(first())
      .subscribe();

    this.address$ = this.bingMaps.getAddressByGeo(
      this.network.centerPoint.latitude,
      this.network.centerPoint.longitude
    );

    // Figure out how many machines were added/removed in last 24 hours
    this.machinesAddedRemoved = Object.entries(this.network.machines).reduce(
      (prev, [serialNumber, data]) => {
        const now = dayjs();
        // Check to see if machine entered or exited in last 24 hours
        const lessThan24Hours =
          now.diff(dayjs(data.dateEntered), 'hours') <= 24 ||
          now.diff(dayjs(data.dateExited), 'hours') <= 24;
        return (prev += +lessThan24Hours);
      },
      0
    );

    // Now check to see what machines have alerts that is attached to this network
    this.numberOfAlerts = this.network.machinesData.reduce(
      (prev, machine) => (prev += machine.alerts ? machine.alerts.length : 0),
      0
    );
  }
}
