import { Component, OnInit } from '@angular/core';
import { ClearskyService } from '../../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { CSFilter } from '../../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsAggCountData } from '../../../../../contracts/clearsky/agg-data';
import { MatDialogRef } from '@angular/material/dialog';
import { CsRequestKeys } from '../../../../../contracts/clearsky/cs-machines-request';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dtc-widget-dialog',
  templateUrl: './dtc-widget-dialog.component.html',
  styleUrls: ['./dtc-widget-dialog.component.scss'],
})
export class DtcWidgetDialogComponent implements OnInit {
  dtcs: CsAggCountData[];

  constructor(
    public dialogRef: MatDialogRef<DtcWidgetDialogComponent>,
    private clearskyService: ClearskyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .subscribe(
        (page) =>
          (this.dtcs =
            (page && page.aggregations && page.aggregations.dtcs) || [])
      );
  }

  /**
   * Filter to dtc code.
   * @param dtc
   */
  filterToDtc(dtc: number): void {
    this.clearskyService.updateFilter(CSFilter.dtcId.key, [dtc]);

    // Now reroute the user
    this.dialogRef.close();
    this.router.navigate(['/clearsky/list']);
  }
}
