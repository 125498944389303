import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { IOCIDAndValue } from '../../../web2case.component';
import { Web2CaseService } from '../../../web2case.service';
import { PART_OPTION_VALUES } from '../../../add-part-options.const';

@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html'
})
export class ComplaintFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() complaintForm: UntypedFormGroup;
  complaintCategory: string;
  partsCatOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.incorrect-part',
      value: PART_OPTION_VALUES.INCORRECT_PART
    },
    {
      ocid: 'web2case.quantity-discrepancy',
      value: PART_OPTION_VALUES.QTY_DISCREPANCY
    },
    {
      ocid: 'web2case.quality-of-parts',
      value: PART_OPTION_VALUES.QUALITY_OF_PART
    },
    {
      ocid: 'w2c-late-delievery-label',
      value: PART_OPTION_VALUES.LATE_DELIVERY
    },
    {
      ocid: 'w2c-wrong-packaging',
      value: PART_OPTION_VALUES.WRONG_PACKAGING
    },
    {
      ocid: 'w2c-damaged-packaging',
      value: PART_OPTION_VALUES.DAMAGED_PACKAGING
    }
  ];
  uploadType: ('package-image' | 'part-image' | 'attachment') = 'package-image';
  uploadRequired: boolean = true;
  quantityOCID: string = '';

  constructor(private formBuilder: UntypedFormBuilder, private web2caseService: Web2CaseService) { }

  ngOnInit() {
    this.complaintForm.addControl('Level2', this.formBuilder.control('', Validators.required));
  }

  /**
   * Event fired when the parts category options dropdown select has changed. Adds various
   * form controls and groups based on its value.
   * @param {MatSelectChange} event
   */
  partsCatOptChanged(event: MatSelectChange) {
    const partsCatOptValue: string = event.value;
    this.complaintCategory = event.value;
    this.web2caseService.complaintType = event.value;

    if (
      partsCatOptValue === PART_OPTION_VALUES.INCORRECT_PART ||
      partsCatOptValue === PART_OPTION_VALUES.QTY_DISCREPANCY ||
      partsCatOptValue === PART_OPTION_VALUES.QUALITY_OF_PART ||
      partsCatOptValue === PART_OPTION_VALUES.LATE_DELIVERY ||
      partsCatOptValue === PART_OPTION_VALUES.WRONG_PACKAGING ||
      partsCatOptValue === PART_OPTION_VALUES.DAMAGED_PACKAGING
    ) {
      if (!this.complaintForm.get('OrderNumber')) {
        this.complaintForm.addControl('OrderNumber', this.formBuilder.control('', Validators.required));
      }
      if (!this.complaintForm.get('CaseDescription')) {
        this.complaintForm.addControl('CaseDescription', this.formBuilder.control('', Validators.required));
      }
      if (!this.complaintForm.get('uploadForm')) {
        this.complaintForm.addControl('uploadForm', new UntypedFormGroup({}));
      }
    } else {
      this.complaintForm.removeControl('OrderNumber');
      this.complaintForm.removeControl('CaseDescription');
      this.complaintForm.removeControl('uploadForm');
    }
    if (
      partsCatOptValue === PART_OPTION_VALUES.INCORRECT_PART ||
      partsCatOptValue === PART_OPTION_VALUES.QUALITY_OF_PART ||
      partsCatOptValue === PART_OPTION_VALUES.QTY_DISCREPANCY
    ) {

      this.uploadRequired = !(partsCatOptValue === PART_OPTION_VALUES.QTY_DISCREPANCY);
      if (!this.complaintForm.get('addPartsForm')) {
        this.complaintForm.addControl('addPartsForm', new UntypedFormGroup({}));
      }
    } else {
      this.complaintForm.removeControl('addPartsForm');
    }
    if (
      partsCatOptValue === PART_OPTION_VALUES.INCORRECT_PART ||
      partsCatOptValue === PART_OPTION_VALUES.QUALITY_OF_PART
    ) {
      this.uploadType = 'part-image';
      this.quantityOCID = this.ocids['global.quantity'];
    }
    if (
      partsCatOptValue === PART_OPTION_VALUES.QTY_DISCREPANCY
    ) {
      this.uploadType = 'part-image';
      this.quantityOCID = this.ocids['web2case.quantity-received'];
    }
  }

  /**
   * Gets the add parts form.
   * @returns {FormGroup}
   */
  get addPartsForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.complaintForm.get('addPartsForm');
  }
  /**
   * Gets the upload form.
   * @returns {FormGroup}
   */
  get uploadForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.complaintForm.get('uploadForm');
  }

}
