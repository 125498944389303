import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table-features-item-icon',
  templateUrl: './pricing-plan-table-features-item-icon.component.html',
  styleUrls: ['./pricing-plan-table-features-item-icon.component.scss'],
})
export class PricingPlanTableFeaturesItemIconComponent {
  @HostBinding('class.pricing-plan-table-features-item-icon') isIcon = true;
  private _isSelected: boolean;
  @Input()
  public get selected(): boolean {
    return this._isSelected;
  }
  public set selected(val: boolean) {
    this._isSelected = val;
    this.isSelected = val;
  }

  @HostBinding('class.pricing-plan-table-features-item-icon-selected')
  public isSelected = false;
}
