import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { HPL60FormComponent } from './hpl60-form/hpl60-form.component';
import { PlacardComponent } from './placard.component';
import { PlacardService } from './placard.service';
import { RequestTypeFormComponent } from './request-type-form/request-type-form.component';
import { WreckerFormComponent } from './wrecker-form/wrecker-form.component';
import { TruckFormComponent } from './truck-form/truck-form.component';
import { CarrierFormComponent } from './carrier-form/carrier-form.component';
import { ReplacementFormComponent } from './replacement-form/replacement-form.component';
import { VinFormComponent } from './vin-form/vin-form.component';

@NgModule({
  declarations: [
    PlacardComponent,
    RequestTypeFormComponent,
    HPL60FormComponent,
    WreckerFormComponent,
    TruckFormComponent,
    CarrierFormComponent,
    ReplacementFormComponent,
    VinFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    PlacardService
  ]
})
export class PlacardModule {}
