import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

interface DialogData {
  defaultOptions: string[];
  selectedOptions: string[];
  displayOptions: object;
}

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
})
export class SettingsDialogComponent implements OnInit {
  ocids: OcidItems = {};
  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'browse.filter-title',
        'clearsky.settings-label'
      ])
      .pipe(first())
      .subscribe();
  }

  /**
   * Toggle filter from selected filters.
   * @param filter
   */
  toggleFilter(filter: string): void {
    const index = this.data.selectedOptions.indexOf(filter);

    if (index === -1) {
      this.data.selectedOptions.push(filter);
    } else {
      this.data.selectedOptions.splice(index, 1);
    }
  }
}
