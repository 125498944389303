import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-control-result-list-item]',
  templateUrl: './control-result-list-item.component.html',
})
export class ControlResultListItemComponent implements OnInit {
  @Input() item!: {
    records: object[];
    numRecords: number;
    detailsAction: {
      contentPath: string;
      recordState: string;
    };
    attributes: {
      'controlSoftware.description': string[];
      'controlSoftware.modelNumber': string[];
      'controlSoftware.modelSeries': string[];
      'controlSoftware.modelType': string[];
      'controlSoftware.moduleName': string[];
      'controlSoftware.modulePartNumber': string[];
      'controlSoftware.notes': string[];
      'controlSoftware.softwarePartNumber': string[];
      'controlSoftware.softwareVersion': string[];
      'record.id': string[];
    };
  };
  ocids: OcidItems = {};
  linkText = '';

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['control-software.software-part-number-label'])
      .pipe(first())
      .subscribe();
    this.linkText = this.item.attributes['controlSoftware.description']
      ? this.item.attributes['controlSoftware.modulePartNumber'].join(', ') +
        ' - ' +
        this.item.attributes['controlSoftware.description'][0]
      : this.item.attributes['controlSoftware.modulePartNumber'].join(', ');
  }

  get seoUrl() {
    // tbd
    return (
      this.item.detailsAction.contentPath + this.item.detailsAction.recordState
    );
  }
}
