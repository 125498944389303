import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClearskyService } from '../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, of, Subscription } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

interface DialogData {
  widgets: any[];
  filters: any[];
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-generate-report-dialog',
  templateUrl: './generate-report-dialog.component.html',
})
export class GenerateReportDialogComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({});
  reportFormKey: string = 'report';
  private subs: Subscription;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<GenerateReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * On save report settings.
   */
  onSave(): void {
    this.subs = combineLatest([
      this.clearskyService.currentFilters$,
      this.clearskyService.currentColumns$,
      this.clearskyService.currentWidgets$,
    ])
      .pipe(
        take(1),
        mergeMap(([filters, columns, widgets]) => {
          const data = {
            name: '',
            data: {
              filters,
              widgets,
              columns,
            },
          };

          const reportSettings = this.form.value;

          // TODO: call API to generate/email report
          return of(null);
        })
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
