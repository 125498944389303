// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center !important;
}

.table-container:not(._nodata) {
  height: 500px;
  font-size: 14px;
  overflow-y: auto;
}

.sticky-header {
  z-index: 4;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFjY2Vzcy1saXN0LXdpZGdldC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDZCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxhQUFBO0VBQ0EsZUFBQTtFQUNBLGdCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxVQUFBO0FBQ0YiLCJmaWxlIjoiYWNjZXNzLWxpc3Qtd2lkZ2V0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsidGgsIHRkIHtcclxuICB0ZXh0LWFsaWduOiBjZW50ZXIgIWltcG9ydGFudDtcclxufVxyXG5cclxuLnRhYmxlLWNvbnRhaW5lcjpub3QoLl9ub2RhdGEpIHtcclxuICBoZWlnaHQ6IDUwMHB4O1xyXG4gIGZvbnQtc2l6ZTogMTRweDtcclxuICBvdmVyZmxvdy15OiBhdXRvO1xyXG59XHJcblxyXG4uc3RpY2t5LWhlYWRlciB7XHJcbiAgei1pbmRleDogNDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/access-control/widgets/access-list-widget/access-list-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,UAAA;AACF;AACA,ooBAAooB","sourcesContent":["th, td {\r\n  text-align: center !important;\r\n}\r\n\r\n.table-container:not(._nodata) {\r\n  height: 500px;\r\n  font-size: 14px;\r\n  overflow-y: auto;\r\n}\r\n\r\n.sticky-header {\r\n  z-index: 4;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
