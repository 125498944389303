import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyButtonComponent } from './copy-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [CopyButtonComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, ClipboardModule],
  exports: [CopyButtonComponent],
})
export class CopyButtonModule {}
