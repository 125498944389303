import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IOCIDAndValue } from '../../web2case.component';

@Component({
selector: 'app-training-form',
templateUrl: './training-form.component.html'
})
export class TrainingFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() trainingForm: UntypedFormGroup;
  trainingOpts: IOCIDAndValue[] = [
    {
      ocid: 'global.online-express',
      value: 'OLE training'
    },
    {
      ocid: 'web2case.service-bench',
      value: 'SB training'
    },
    {
      ocid: 'web2case.product-usage-training',
      value: 'Product training'
    },
    {
      ocid: 'web2case.service-tech-training',
      value: 'Service training'
    }
  ];

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.trainingForm.addControl('Level', this.formBuilder.control('', Validators.required))
    this.trainingForm.addControl('CaseDescription', this.formBuilder.control('', Validators.required))
  }

}