import {
  Component,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WindowRefService } from '../../../../service/window-ref/window-ref.service';
import { environment } from '../../../../../environments/environment';
interface MediaInfo {
  videoUrl?: string;
  imageSrcUrl?: string;
  imageTitle?: string;
  imageAlt?: string;
}
interface MainContent {
  campaignDescrition?: string;
  campaignTitle?: string;
  jumpLinks?: IJumpLink[];
  ctaUrl?: string;
  ctaText?: string;
  ctaTarget?: string;
  viewAllUrl?: string;
  viewAllText?: string;
  viewAllTarget?: string;
}

interface IJumpLink {
  text: string;
  url: string;
}

@Component({
  selector: 'app-jump-link',
  styleUrls: ['./jump-link.component.scss'],
  templateUrl: './jump-link.component.html',
})
export class JumpLinkComponent implements OnInit, AfterViewInit {
  @Input() slide;
  @Input() activeSlide!: number;
  @Input() index!: number;
  imgRight = false;
  media: MediaInfo = {};
  content: MainContent = {};
  videoUrlSanitized!: SafeResourceUrl;
  dataLayer = (this.winRef.nativeWindow.dataLayer =
    this.winRef.nativeWindow.dataLayer || []);
    placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private sanitizer: DomSanitizer,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // determine which column - left or right - the image is on and set the media and content objects accordingly
    if (
      !!this.slide.JumpLinkRightContent[0].videoUrl ||
      !!this.slide.JumpLinkRightContent[0].imageSrcUrl
    ) {
      this.media = this.slide.JumpLinkRightContent[0];
      this.content = this.slide.JumpLinkLeftContent[0];
      this.imgRight = true;
    } else {
      this.media = this.slide.JumpLinkLeftContent[0];
      this.content = this.slide.JumpLinkRightContent[0];
    }

    // If there's a video, set the sanitized video url based on whether it's a DAM url or a Youtube video ID
    this.videoUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.media.videoUrl?.startsWith('https')
        ? this.media.videoUrl
        : 'https://www.youtube.com/embed/' + this.media.videoUrl
    );
  }

  ngAfterViewInit() {
    // send GTM event to track video plays *after* the video has loaded
    this.dataLayer.push({
      event: 'hasVideo',
    });
  }

  onCtaClick(): void {
    // open the link in a new or same tab, and send an event to GTM so we can track the click
    if (isPlatformBrowser(this.platformId)) {
      if (this.content.ctaTarget === '_self') {
        document.location.href = this.content.ctaUrl;
      } else {
        window.open(this.content.ctaUrl);
      }
      this.dataLayer.push({
        event: 'jumpLinkCtaClick',
      });
    }
  }
}
