import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { OcidItems } from '../../../../contracts/ocid-items';
import { debounceTime } from 'rxjs/operators';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { combineLatest, Subscription } from 'rxjs';
import { mergeDeep } from '../../../../shared/deep-merge';
import {
  BasePlotChart,
  getDtcSrcColor,
  getDtcSvtyColor,
} from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as Highcharts from 'highcharts';
import { ClearskyService } from '../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as cloneDeep from 'lodash.clonedeep';
import {
  CSFilter,
  CSFilterValueOcids,
  toggleCsFilterVal,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsAggData } from '../../../../contracts/clearsky/agg-data';
import {
  CSLegend,
  CSRefBasic,
} from '../../../../contracts/clearsky/clearsky-legend';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-dtc-donut-chart',
  templateUrl: './fleet-dtc-donut-chart.component.html',
})
export class FleetDtcDonutChartComponent implements AfterViewInit {
  @ViewChild('chartEl') chartEl: ElementRef;
  isLoading = true;
  showChart = false;
  availableOptions: unknown[] = [];
  availableSecondaryOptions: unknown[] = [];
  ocids: OcidItems = {};
  severities: Highcharts.PlotPieOptions[] = [];
  sources: Highcharts.PlotPieOptions[] = [];
  private plot;
  private aggData: CsAggData;
  private legend: CSLegend;
  private filter: unknown[] = [];
  private secondaryFilter: unknown[] = [];
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngAfterViewInit(): void {
    this.createChart();
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.legendRef$,
      this.localization.getOCIDs([
        ...CSFilterValueOcids,
        'clearsky.source-label',
        'browse.category',
      ]),
      this.clearskyService.getCurrentFilterValues(CSFilter.dtcSvty.key),
      this.clearskyService.getCurrentFilterValues(CSFilter.fsrc.key),
      this.clearskyService.getValuesByFilter(CSFilter.dtcSvty.key),
      this.clearskyService.getValuesByFilter(CSFilter.fsrc.key),
    ])
      .pipe(debounceTime(0))
      .subscribe(
        ([
          page,
          legend,
          ocids,
          filter,
          secondaryFilter,
          options,
          secondaryOptions,
        ]) => {
          this.ocids = ocids;
          this.aggData = (page && page.aggregations) || {};
          this.legend = legend;
          this.filter = filter;
          this.secondaryFilter = secondaryFilter;
          this.availableOptions = options;
          this.availableSecondaryOptions = secondaryOptions;
          this.showChart = true;
          this.isLoading = false;

          // Does the chart already exist?
          if (this.plot && this.showChart) {
            this.setChartData();
          }
        }
      );
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    const chartData = mergeDeep(cloneDeep(BasePlotChart.pie), {
      chart: {
        height: 250,
      },
      plotOptions: {
        pie: {
          point: {
            events: {
              click: this.onChartClick.bind(this),
            },
          },
        },
      },
      series: [
        {
          colorByPoint: true,
          innerSize: '50%',
        },
      ],
    });

    this.plot = Highcharts.chart(this.chartEl.nativeElement, chartData);
  }

  /**
   * Toggle filter value (legend).
   * @param primary
   * @param key
   */
  toggleFilter(primary: boolean, key: CSRefBasic): void {
    if (primary) {
      this.clearskyService.updateFilter(
        CSFilter.dtcSvty.key,
        toggleCsFilterVal(key.id, this.filter, this.availableOptions)
      );
    } else {
      this.clearskyService.updateFilter(
        CSFilter.fsrc.key,
        toggleCsFilterVal(
          key.id,
          this.secondaryFilter,
          this.availableSecondaryOptions
        )
      );
    }
  }

  /**
   * On chart click.
   * @protected
   */
  private onChartClick(e: { point: { custom: { key: number } } }): void {
    this.clearskyService.updateFilter(CSFilter.dtcSvty.key, [
      e.point.custom.key,
    ]);
  }

  /**
   * Update series for chart.
   * @protected
   */
  private updateSeriesData(): void {
    // Update severities statistics
    this.updateSeverityData();

    // Update sources
    this.updateSourceData();
  }

  /**
   * Update severity data grouping.
   * @private
   */
  private updateSeverityData(): void {
    // Get severity filter values that are currently set
    this.severities = (this.aggData.dtcSeverity || []).map((s) => {
      const label = (this.legend.svtyCategory || []).find(
        (src) => src.id === s.id
      );

      return {
        name: label?.desc,
        y: s.cnt,
        color: getDtcSvtyColor(s.id),
        visible: this.filter.length ? this.filter.includes(s.id) : true,
        custom: {
          key: s.id,
          count: s.cnt,
          label: label?.desc,
          value: s,
        },
      };
    });
  }

  /**
   * Update source data grouping.
   * @private
   */
  private updateSourceData(): void {
    // Get source filter values that are currently set
    this.sources = (this.aggData.dtcSource || []).map((s) => {
      const label = (this.legend.fsrc || []).find((src) => src.id === s.id);

      return {
        name: label?.desc,
        y: s.cnt,
        color: getDtcSrcColor(s.id),
        visible: this.secondaryFilter.length
          ? this.secondaryFilter.includes(s.id)
          : true,
        custom: {
          key: s.id,
          count: s.cnt,
          label: label?.desc,
          value: s,
        },
      };
    });
  }

  /**
   * Set data for chart.
   * @protected
   */
  private setChartData(): void {
    // Redraw data points
    this.updateSeriesData();
    this.plot.series[0].setData(this.severities);
    this.setChartTitle();
  }

  /**
   * Set chart title.
   * @protected
   */
  private setChartTitle(): void {
    const total = this.severities.reduce(
      (prev, d) => (prev += (d as { y: number }).y),
      0
    );
    this.plot.setTitle({
      text: `<span class="donut-center-text-main">${total}</span><br /><span class="donut-center-text-sub">${this.ocids['global.total']}</span>`,
    });
  }
}
