import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table-features-item',
  templateUrl: './pricing-plan-table-features-item.component.html',
  styleUrls: ['./pricing-plan-table-features-item.component.scss'],
})
export class PricingPlanTableFeaturesItemComponent {
  @HostBinding('class') hostClass = 'pricing-plan-table-features-item';
}
