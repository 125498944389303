import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PieChartComponent } from './pie-chart.component';
import { ChartLegendModule } from '../chart-legend/chart-legend.module';

@NgModule({
  declarations: [PieChartComponent],
  exports: [PieChartComponent],
  imports: [CommonModule, ChartLegendModule],
})
export class PieChartModule {}
