import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-marketing-box-cart',
  styleUrls: ['./marketing-box-cart.component.scss'],
  templateUrl: './marketing-box-cart.component.html',
})
export class MarketingBoxCartComponent {
  @Input() data!: {
    heading: string;
    description: string;
    teaserText: string;
    buttonText: string;
    buttonStyle: string;
    buttonUrl: string;
    isButtonUrlDocument: boolean;
    backgroundImage: string;
    backgroundStyle: string;
  };

  constructor() {}

  /**
   * Gets the image style depending on whether the box has a header, description,
   * or button text and the background style.
   * @param {string} backgroundStyle
   * @returns {string}
   */
  getImageStyle(backgroundStyle: string) {
    switch (backgroundStyle) {
      case 'black':
        return `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${this.data.backgroundImage}')`;
      case 'orange':
        return `linear-gradient(rgba(255, 84, 5, 0.85), rgba(255, 84, 5, 0.85)), url('${this.data.backgroundImage}')`;
      case 'image':
        return `url('${this.data.backgroundImage}')`;
      case 'solid':
        return `none`;
      default:
        return `url('${this.data.backgroundImage}')`;
    }
  }
}
