import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HistoricSelection } from '../../../../../contracts/clearsky/machine/machine.dto';
import { getZoneScale } from '../../../../../contracts/clearsky/machine/machine.chart.config';
import { EnvelopeProfileTelehandlerChart } from '../../../charts/envelope-profile-use/envelope-profile-telehandler-chart';
import {
  CsAggEnvHist,
  CsZoneHistoricItem,
  CsZoneItem,
} from '../../../../../contracts/clearsky/agg-data';
import { MxMGroupImg } from '../../../../../contracts/clearsky/machine/machine.images';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';

@Component({
  selector: 'app-envelope-profile-telehandler-fleet',
  templateUrl: './envelope-profile-telehandler-fleet.component.html',
})
export class EnvelopeProfileTelehandlerFleetComponent
  implements AfterViewInit, OnChanges
{
  @Input() data: CsZoneHistoricItem;
  @Input() historic = HistoricSelection.HOURS_24;
  @Input() showPercentage: boolean;
  @ViewChild('chart') chartEl: ElementRef;
  image = MxMGroupImg.telehandlers;
  historicData: CsZoneItem;
  private plot;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}
  ngAfterViewInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.average-label'])
      .pipe(first())
      .subscribe();
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.historic && !changes.historic.firstChange || changes.data && !changes.data.firstChange)) {
      this.setChartData();
    }
  }

  /**
   * Create chart for scissor lifts.
   * @private
   */
  private createChart(): void {
    this.plot = EnvelopeProfileTelehandlerChart.createInstance().createChart(
      this.chartEl,
      this.getZoneData.bind(this),
      {
        typeDescription: 'Telehandlers',
        tooltip: (zone: number, self: { options: { value: number } }) =>
          `On average your fleet has spent ${self.options.value} hours in this zone.`,
      }
    );
  }

  /**
   * Set the chart data.
   */
  setChartData(): void {
    this.plot.series[0].setData(this.getZoneData());
  }

  /**
   * Get zone data.
   * @private
   */
  private getZoneData(): any[] {
    // Set data based on historic selection
    this.historicData = this.data[CsAggEnvHist[this.historic]];
    const colorScale = getZoneScale(Object.values(this.historicData.avg));

    return Object.entries(this.historicData.avg).map(([zone, num]) => ({
      name: zone,
      y: 1,
      value: num,
      color: colorScale[num],
      borderColor: '#000',
    }));
  }
}
