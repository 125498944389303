import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridBlocksComponent } from './grid-blocks.component';
import { GridBlocksItemComponent } from './grid-blocks-item/grid-blocks-item.component';
import { GridBlocksItemTitleComponent } from './grid-blocks-item-title/grid-blocks-item-title.component';
import { GridBlocksItemHeaderComponent } from './grid-blocks-item-header/grid-blocks-item-header.component';
import { GridBlocksItemSubComponent } from './grid-blocks-item-sub/grid-blocks-item-sub.component';
import { GridBlocksItemIconComponent } from './grid-blocks-item-icon/grid-blocks-item-icon.component';
import { GridBlocksSpotlightTextComponent } from './grid-blocks-spotlight-text/grid-blocks-spotlight-text.component';

@NgModule({
  declarations: [
    GridBlocksComponent,
    GridBlocksItemComponent,
    GridBlocksItemTitleComponent,
    GridBlocksItemHeaderComponent,
    GridBlocksItemSubComponent,
    GridBlocksItemIconComponent,
    GridBlocksSpotlightTextComponent,
  ],
  exports: [
    GridBlocksComponent,
    GridBlocksItemComponent,
    GridBlocksItemTitleComponent,
    GridBlocksItemHeaderComponent,
    GridBlocksItemSubComponent,
    GridBlocksItemIconComponent,
    GridBlocksSpotlightTextComponent,
  ],
  imports: [CommonModule],
})
export class GridBlocksModule {}
