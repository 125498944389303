import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../service/notification/notification.service';
import { LayoutService } from '../service/layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EndecaService } from '../endeca/endeca.service';
import { CurrentUserService } from '../service/user/current-user.service';
import { ClearskyService } from './clearsky.service';
import { NavigationEnd, Router } from '@angular/router';
import { ClearskyDashboardService } from './clearsky-dashboard.service';
import { mergeMap, Observable } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-clearsky',
  templateUrl: './clearsky.component.html',
  styleUrls: ['./clearsky.component.scss'],
  providers: [NotificationService],
})
export class ClearskyComponent implements OnInit, OnDestroy {
  isProxied$: Observable<boolean> = this.currentUserService.userSubject.pipe(
    map((user) => user.inProxy)
  );
  isSubscribed$: Observable<boolean> = this.currentUserService.userSubject.pipe(
    map((user) => user.clearSky)
  );
  isEmergencyAlertEnabled$: Observable<boolean> =
    this.layoutService.emergencyAlertEnabled$;

  constructor(
    public layoutService: LayoutService,
    private endecaService: EndecaService,
    private clearskyService: ClearskyService,
    private currentUserService: CurrentUserService,
    private dashboardService: ClearskyDashboardService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Get mandatory data
    this.dashboardService.all().subscribe();

    // Check to see if we need to collapse navigation on route change
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        mergeMap(() => this.layoutService.isMobile$)
      )
      .subscribe((isMobile) => {
        // Make sure it's boolean since it starts with null
        if (isMobile === true) {
          this.layoutService.clearskyNavCollapsed = true;
        }
      });

    // Skip initial emission and only force reload on change
    this.currentUserService.userSubject.pipe(skip(1)).subscribe((user) => {
      // Every time the user changes, force reload of machines
      if (user && user.clearSky) {
        this.clearskyService.forceMachinesReload();
      }
    });

    this.layoutService.isDesktop$.subscribe(
      (isTablet) => (this.layoutService.clearskyNavCollapsed = !isTablet)
    );
    this.layoutService.setIsClearskyEnabled(true);
    // Suppress padding on content area for every page in here
    this.endecaService.suppressFooterPadding(true);
    this.endecaService.suppressHeaderPadding(true);

    // Make ClearSky full width
    this.endecaService.setToFullWidth(true);

    // Always show OLE search but in toggle form
    this.endecaService.enableOleSearch(true);
    this.layoutService.enableToggleSearch(true);
  }

  ngOnDestroy(): void {
    this.endecaService.enableOleSearch(false);
    this.layoutService.enableToggleSearch(false);
  }
}
