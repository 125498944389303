import { Pipe, PipeTransform } from '@angular/core';
import { TemperatureUnitType } from '../contracts/clearsky/machine/machine.dto';

@Pipe({
  name: 'temperatureConverter'
})
export class TemperatureConverterPipe implements PipeTransform {
  transform(value: number, inputUnit: TemperatureUnitType, outputUnit: TemperatureUnitType): string {
    let temperature = value;
    if (inputUnit === TemperatureUnitType.FAHRENHEIT && outputUnit === TemperatureUnitType.CELSIUS) {
      temperature = (value - 32) * 5 / 9;
    } else if (inputUnit === TemperatureUnitType.CELSIUS && outputUnit === TemperatureUnitType.FAHRENHEIT) {
      temperature = (value * 9 / 5) + 32;
    }
    return temperature?.toFixed(1);
  }
}
