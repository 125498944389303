import {
  Component,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ProductsApiService } from '../../../../service/product/products-api.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
})
export class ProductMediaComponent implements OnInit, OnDestroy {
  @Input() imgUrl = '';
  @Input() videoUrl = '';
  @Input() altText = '';
  @Input() placeholderImg = '';
  @Input() index = '';
  @Input() uploadDate = '';
  @Input() title = '';
  videoUrlSanitized!: SafeResourceUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private sanitizer: DomSanitizer,
    private productService: ProductsApiService
  ) {}

  ngOnInit() {
    this.videoUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.videoUrl
    );
    if (this.videoUrl && isPlatformBrowser(this.platformId)) {
      // inject video schema if it's a video
      this.productService.insertSchema(
        {
          '@context': 'https://schema.org/',
          '@type': 'VideoObject',
          name: this.title,
          thumbnailUrl: this.placeholderImg,
          uploadDate: this.uploadDate,
          contentUrl: this.videoUrl,
        },
        'video' + this.index
      );
    }
  }

  ngOnDestroy(): void {
    // remove video schema if it exists
    if (this.videoUrl && isPlatformBrowser(this.platformId)) {
      this.productService.removeSchema('video' + this.index);
    }
  }
}
