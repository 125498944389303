import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-map-toggles',
  templateUrl: './map-toggles.component.html',
  styleUrls: ['./map-toggles.component.scss'],
})
export class MapTogglesComponent {
  @HostBinding('class.mat-elevation-z2') isElevated = true;
}
