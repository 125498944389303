import { Pipe, PipeTransform } from '@angular/core';
import { MachineFieldDisplay, DTCFieldDisplay } from '../contracts/clearsky/machine/machine.dto';
import { camelToWords } from '../shared/camel-to-words.pipe';
import { CSFieldType } from '../contracts/clearsky/machine/machine.dto';

/**
 * Returns the display text for a CS field based on the field name and type.
 */
export const getFieldDisplay = (fieldName: string, fieldType: CSFieldType) => {
  if (typeof fieldName !== "string") {
    return fieldName;
  }

  switch (fieldType) {
    case CSFieldType.MACHINE:
      return MachineFieldDisplay[fieldName] || camelToWords(fieldName);
    case CSFieldType.DTC:
      return DTCFieldDisplay[fieldName] || camelToWords(fieldName);
  }
}

@Pipe({name: 'fieldDisplay' })
export class FieldDisplayPipe implements PipeTransform {
  transform(fieldType: CSFieldType, value: any): string {
    return getFieldDisplay(fieldType, value);
  }
}
