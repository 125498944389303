import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-grid-blocks',
  templateUrl: './grid-blocks.component.html',
  styleUrls: ['./grid-blocks.component.scss'],
})
export class GridBlocksComponent implements OnInit {
  @Input() col = 3;
  @Input() row?: number;
  @HostBinding('class.grid-blocks') isBlocks = true;

  constructor(private renderer2: Renderer2, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.renderer2.addClass(
      this.elementRef.nativeElement,
      `grid-blocks-col-${this.col}`
    );
    this.renderer2.addClass(
      this.elementRef.nativeElement,
      this.row ? `grid-blocks-row-${this.row}` : 'grid-blocks-row-auto'
    );
  }
}
