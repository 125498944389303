import { CSFilterData, CSFilterType, QAPIFilter } from '../machine-filter-v2';

export abstract class CsFilterBuilder {
  filter: CSFilterData;
  custom?: object;
  valueColumnMapper: { [source: string]: string } = {};

  constructor(filter: CSFilterData, custom?: object) {
    this.filter = filter;
    this.custom = custom;
  }

  /**
   * QAPI filter builder.
   * @param values
   */
  abstract buildFilter(values: unknown[]): QAPIFilter[];

  /**
   * Build QAPI filters to convert values to simple boolean constraints.
   * @param values
   */
  buildValueToBooleanFilter(values: unknown[]): QAPIFilter[] {
    const valuesLength = values.length - 1;
    return values.map((val, index) => {
      const filter: QAPIFilter = {
        column: this.valueColumnMapper[val as string],
        filterType: CSFilterType.EQUALS,
        values: [true],
      };

      if (index < valuesLength) {
        filter.operator = 'OR';
      }

      return filter;
    });
  }
}
