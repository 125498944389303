import { Pipe, PipeTransform } from '@angular/core';

export const camelToWords = (value: string): string => {
  if (typeof value !== "string") {
    return value;
  }

  return value
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());
}

@Pipe({name: 'camelToWords' })
export class CamelToWordsPipe implements PipeTransform {
  transform(value): string {
    return camelToWords(value);
  }
}
