import { Component, HostBinding } from '@angular/core';
import { LayoutService } from '../../service/layout.service';
import { CurrentUserService } from 'app/service/user/current-user.service';

@Component({
  selector: 'app-clearsky-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
    '../cs-layout/cs-layout-header.component.scss',
  ],
})
export class HeaderComponent {
  @HostBinding('class.is-nav-min') get minimizedClass() {
    return this.layoutService.clearskyNavCollapsed;
  }
  @HostBinding('class.!tw-w-full') get hiddenClass() {
    return !this.currentUserService.user.clearSky;
  }

  constructor(private layoutService: LayoutService, private currentUserService: CurrentUserService) { }
}
