import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-tab-search-count',
  templateUrl: './tab-search-count.component.html',
  styleUrls: ['./tab-search-count.component.scss'],
})
export class TabSearchCountComponent implements CartridgeInterface {
  @Input() data!: {
    selectedTab: string;
    tabOneCount: number;
    tabOneHeading: string;
    tabOneUrl: string;
    tabParams: string;
    tabTwoCount: number;
    tabTwoHeading: string;
    tabTwoUrl: string;
  };
}
