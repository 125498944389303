import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { INavMenu } from '../../../../contracts/user/inav-menu';
import { MenuService } from '../../../../service/user/menu.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-navigation',
  styleUrls: ['./account-navigation.component.scss'],
  templateUrl: './account-navigation.component.html',
})
export class AccountNavigationComponent implements OnInit {
  ocids: OcidItems = {};
  shoppingListActive = false;
  ordersActive = false;
  dashboardOptions: INavMenu;
  isCollapsed = true;

  constructor(
    private route: ActivatedRoute,
    private menuService: MenuService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    // Get OCIDs
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    // Subscribe to menu data
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? this.menuService.getMenuByUxKey('my-account-dashboard')
            : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.dashboardOptions = menu;
        }
      });
    // Subscribe to route changes. This allows us to dynamically set the activated page on the left since shopping lists
    // and orders all have different sub pages.
    this.route.url
      .pipe(
        filter((url: UrlSegment[]) => url.length >= 2),
        map((url: UrlSegment[]) => url[1].path)
      )
      .subscribe((segment: string) => {
        // If the url contains 'shopping-list' set it as active.
        this.shoppingListActive = segment.includes('shopping-list');

        // If the url contains 'orders' set it as active.
        this.ordersActive = segment.includes('orders');
      });
  }
}
