import {
  ComponentFactoryResolver,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { CartridgeInterface } from './cartridge/cartridge.class';
import { TemplateInterface } from './template/template.class';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { JsonPayload } from '../contracts/IEndecaPayloadResponse';

@Injectable()
export class EndecaService {
  // Sets whether the global search box is viewable in the header or not.
  private readonly globalSearchBoxViewable: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public globalSearchBoxViewable$: Observable<boolean> =
    this.globalSearchBoxViewable.asObservable();
  // Sets whether there is padding between the header and main content.
  private readonly mainContentPaddedTop: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public mainContentPaddedTop$: Observable<boolean> =
    this.mainContentPaddedTop.asObservable();
  // Sets whether there is padding between the main content and footer.
  private readonly mainContentPaddedBottom: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public mainContentPaddedBottom$: Observable<boolean> =
    this.mainContentPaddedBottom.asObservable();
  // Sets whether there is padding between the content and container.
  private readonly suppressContentPadding: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public suppressContentPadding$: Observable<boolean> =
    this.suppressContentPadding.asObservable();
  // Sets whether there is a background color for the main content area.
  private readonly mainContentBgColor: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public mainContentBgColor$: Observable<string> =
    this.mainContentBgColor.asObservable();
  // Adds a container class if not full width
  private readonly mainContentFullWidth: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public mainContentFullWidth$: Observable<boolean> =
    this.mainContentFullWidth.asObservable();
  // Holds any emergency alerts.
  private readonly emergencyAlerts: BehaviorSubject<object> =
    new BehaviorSubject<object>({});
  public emergencyAlerts$: Observable<object> =
    this.emergencyAlerts.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private resolver: ComponentFactoryResolver
  ) {}

  /**
   * Retrieve payload from ExM
   * @param {string} url
   * @param {object} query
   * @param {boolean} clearPayloadCache
   * @returns {Observable<object>}
   */
  getPayload(
    url: string,
    query: object = {},
    clearPayloadCache = false
  ): Observable<JsonPayload> {
    return this.http.get<JsonPayload>(
      environment.payloadUrl + this.generateUrl(url, query),
      this.generateHeaders(clearPayloadCache)
    );
  }

  /**
   * Generate valid url for endeca.
   * @param url
   * @param query
   * @protected
   */
  protected generateUrl(url: string, query: object = {}): string {
    if (Object.keys(query).length) {
      // Adjust url with query string
      const queryString = Object.keys(query)
        .map((key) => key + '=' + query[key])
        .join('&');
      url += '?' + queryString;
    }

    // Want to remove this in the future
    if (!url.startsWith('/')) {
      url = '/' + url;
    }

    if (url === '/') {
      url = '/home';
    }

    return url;
  }

  /**
   * Generate endeca headers.
   * @param clearPayloadCache
   * @protected
   */
  protected generateHeaders(clearPayloadCache = false): object {
    // Create request headers.
    const headers = {
      'Content-Type': 'application/json',
    };

    // If we need to clear the payload cache for the request, add that header to the request.
    if (clearPayloadCache) {
      headers['clear-payload-cache'] = 'true';
    }

    return {
      headers: new HttpHeaders(headers),
      withCredentials: true,
    };
  }

  /**
   * Inject cartridge dynamically into a ViewContainerRef
   *
   * @param {ViewContainerRef} container
   * @param {object} data
   * @param {object} cartridgeTypes
   */
  injectCartridge(
    container: ViewContainerRef,
    data: object,
    cartridgeTypes: object
  ) {
    const cartridgeType = data['@type'];
    // Don't serve the emergency alert component. We are directly injecting this into app.component.html.
    if (cartridgeType !== 'EmergencyAlerts') {
      if (!cartridgeTypes.hasOwnProperty(cartridgeType)) {
        // Cartridge does not exist, throw error
        throw new Error(
          'Cartridge ' +
            cartridgeType +
            ' does not have a component reference! Please add this in the CartridgeComponent!'
        );
      }

      // Lazy load the component
      const factory = this.resolver.resolveComponentFactory(
        cartridgeTypes[cartridgeType]
      );
      const componentRef = container.createComponent(factory);
      (<CartridgeInterface>componentRef.instance).data = data;
    }
  }

  /**
   * Inject template dynamically into a ViewContainerRef
   *
   * @param {ViewContainerRef} container
   * @param {object} data
   * @param {string} url
   * @param {object} templateTypes
   */
  injectTemplate(
    container: ViewContainerRef,
    data: object,
    url: string,
    templateTypes: object
  ) {
    const template = data['@type'];

    // If the data is not at the page slot level (ex. OneColumnPage or TwoColumnPage) then perform the logic on
    // whether to suppress the global search box or not, whether to show padding between the header and main content,
    // and whether to show padding between the main content and footer.
    if (template !== 'PageSlot') {
      const suppressGlobalSearchBox: boolean = data['suppressGlobalSearchBox'];
      this.globalSearchBoxViewable.next(
        suppressGlobalSearchBox ? !suppressGlobalSearchBox : true
      );
      const mainContentPaddedTop: boolean = data['suppressHeaderPadding'];
      this.mainContentPaddedTop.next(
        mainContentPaddedTop ? !mainContentPaddedTop : true
      );
      const mainContentPaddedBottom: boolean = data['suppressFooterPadding'];
      this.mainContentPaddedBottom.next(
        mainContentPaddedBottom ? !mainContentPaddedBottom : true
      );
      const suppressContentPadding: boolean = data['suppressContentPadding'];
      this.suppressContentPadding.next(suppressContentPadding);
      const mainContentBgColor: string = data['backgroundColor'];
      this.mainContentBgColor.next(
        mainContentBgColor ? mainContentBgColor : ''
      );
      const mainFullWidth: boolean = data['isFullWidth'];
      this.mainContentFullWidth.next(mainFullWidth ? mainFullWidth : false);
    }

    if (!templateTypes.hasOwnProperty(template)) {
      // Template does not exist, throw error
      throw new Error(
        'Template ' +
          template +
          ' does not have a component reference! Please add this in the TemplateComponent!'
      );
    }

    // Filter out emergency alerts from header content
    const headerContent = data['headerContent'];
    if (headerContent) {
      const emergencyAlerts = headerContent.filter((content: object) => {
        return content['@type'] === 'EmergencyAlerts';
      });
      this.emergencyAlerts.next(
        emergencyAlerts.length > 0 ? emergencyAlerts[0] : null
      );
    }
    container.clear(); // clear ViewContainerRef first, otherwise dynamic components will keep getting appended
    const factory = this.resolver.resolveComponentFactory(
      templateTypes[template]
    );
    const componentRef = container.createComponent(factory);
    (<TemplateInterface>componentRef.instance).data = data;
    (<TemplateInterface>componentRef.instance).payloadUrl = url;
  }

  /**
   * Inject template manually into ViewContainerRef
   *
   * @param {ViewContainerRef} container
   * @param {TemplateInterface} component
   * @param {object} data
   * @param {string} url
   */
  injectTemplateType(
    container: ViewContainerRef,
    component: Type<{}>,
    data: object,
    url: string
  ) {
    // Clear previous data
    container.clear();

    // Resolve template dynamically
    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef = container.createComponent(factory);
    (<TemplateInterface>componentRef.instance).data = data;
    (<TemplateInterface>componentRef.instance).payloadUrl = url;
  }

  /**
   * Suppress the header padding before the content?
   * @param suppress
   */
  suppressHeaderPadding(suppress: boolean): void {
    this.mainContentPaddedTop.next(!suppress);
  }

  /**
   * Suppress the footer padding before the content?
   * @param suppress
   */
  suppressFooterPadding(suppress: boolean): void {
    this.mainContentPaddedBottom.next(!suppress);
  }

  /**
   * Should we enable OLE search?
   * @param enable
   */
  enableOleSearch(enable: boolean): void {
    this.globalSearchBoxViewable.next(enable);
  }

  /**
   * Set OLE page to full width?
   * @param fullWidth
   */
  setToFullWidth(fullWidth: boolean): void {
    this.mainContentFullWidth.next(fullWidth);
  }
}
