// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  flex-grow: 1;
  width: 50%;
  padding: 0.75rem 0.5rem;
  border-right: 1px solid var(--cs-border-color);
  text-align: center;
}
:host:last-child {
  border-right: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hcC1pbmZvLWJveC1oZWFkZXItaXRlbS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxVQUFBO0VBQ0EsdUJBQUE7RUFDQSw4Q0FBQTtFQUNBLGtCQUFBO0FBQ0Y7QUFDRTtFQUNFLGVBQUE7QUFDSiIsImZpbGUiOiJtYXAtaW5mby1ib3gtaGVhZGVyLWl0ZW0uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGZsZXgtZ3JvdzogMTtcbiAgd2lkdGg6IDUwJTtcbiAgcGFkZGluZzogLjc1cmVtIC41cmVtO1xuICBib3JkZXItcmlnaHQ6IDFweCBzb2xpZCB2YXIoLS1jcy1ib3JkZXItY29sb3IpO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG5cbiAgJjpsYXN0LWNoaWxkIHtcbiAgICBib3JkZXItcmlnaHQ6IDA7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/map/map-info-box-header-item/map-info-box-header-item.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,UAAA;EACA,uBAAA;EACA,8CAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;AACJ;AACA,4nBAA4nB","sourcesContent":[":host {\n  flex-grow: 1;\n  width: 50%;\n  padding: .75rem .5rem;\n  border-right: 1px solid var(--cs-border-color);\n  text-align: center;\n\n  &:last-child {\n    border-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
