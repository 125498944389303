import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IOrder } from '../../contracts/commerce/iorder';
import { IUser } from '../../contracts/user/iuser';
import { WindowRefService } from '../window-ref/window-ref.service';
import { IProduct } from '../../contracts/product/iproduct';
import { ICommerceItemWithCart } from '../../contracts/commerce/icommerce-item-with-cart';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { IProductPricing } from '../../contracts/product/iproduct-pricing';
import { CurrentUserService } from '../user/current-user.service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ListrakService {
  private dataLayer;

  constructor(
    private winRef: WindowRefService,
    @Inject(PLATFORM_ID) private platformId: string,
    private userService: CurrentUserService,
    private http: HttpClient
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataLayer = (window as any).dataLayer || [];
    }
  }

  /**
   * Conversion tracking for place order.
   * @param user
   * @param order
   */
  placeOrder(user: IUser, order: IOrder): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        'listrak.value': null,
      });
      this.dataLayer.push({
        event: 'listrak.purchase',
        listrak: {
          contents: {
            emailAddress: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            orderNumber: order.comOrderNumber,
            itemTotal: order.priceInfo.amount,
            shippingTotal: order.priceInfo.shipping,
            taxTotal: order.priceInfo.tax,
            handlingTotal: order.priceInfo.amount,
            orderTotal: order.priceInfo.total,
          },
          value: order.commerceItems.items.map((item) => {
            return {
              sku: item.itemNumber,
              quantity: item.quantity,
              price: item.priceInfo.listPrice,
            };
          }),
        },
      });
    });
  }

  /**
   * Browse product abandonment.
   * @param product
   * @param pricing
   */
  browseProduct(product: IProduct, pricing: IProductPricing): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        event: 'listrak.productbrowse',
        listrak: {
          contents: {
            sku: product.itemNumber,
            title: product.itemDescription,
            price: parseFloat(pricing.listPrice).toFixed(2),
            productUrl: `${location.hostname}/parts/p/${product.itemNumber}`,
            imageUrl: `${
              environment.webAppUrl
            }/wholegoods/api/v1/partImage?partNumber=${
              product.itemNumber
            }&brand=${environment.jlgStyling ? 'JLG' : 'JD'}`,
            region: this.userService.user.erpEnvironment,
          },
        },
      });
    });
  }

  /**
   * Add to cart event.
   * @param items
   * @param user
   */
  updateCartContents(items: ICommerceItemWithCart[], user: IUser): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        'listrak.value': null,
      });
      this.dataLayer.push({
        event: 'listrak.updatecart',
        listrak: {
          contents: {
            region: user.erpEnvironment,
          },
          value: items.map((item) => {
            return {
              sku: item.itemNumber,
              quantity: item.quantity,
              price: item.priceInfo.listPrice,
              productTitle: item.itemDescription,
              productUrl: `${location.hostname}/parts/p/${item.itemNumber}`,
              imageUrl: `${
                environment.webAppUrl
              }/wholegoods/api/v1/partImage?partNumber=${
                item.itemNumber
              }&brand=${environment.jlgStyling ? 'JLG' : 'JD'}`,
            };
          }),
        },
      });
    });
  }

  /**
   * Clear cart event.
   */
  clearCart(user: IUser): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        'listrak.value': null,
      });
      this.dataLayer.push({
        event: 'listrak.clearcart',
        listrak: {
          contents: {
            region: user.erpEnvironment,
          },
        },
      });
    });
  }

  /**
   * show Listrak popup
   * @param id
   */
  showPopup(id: string): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        event: 'listrak.popup',
        popupId: id,
      });
    });
  }

  captureRegistration(user: IUser): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        event: 'listrak.registration',
        listrak: {
          contents: {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            homeAddress: user.homeAddress,
            locale: user.locale,
            marketingUserRole: user.marketingUserRole,
            accountType: user.accountType,
          },
        },
      });
    });
  }

  /**
   * get list of recommended parts
   * @param blockId
   * @param globalId
   */
  getRecommendedParts(blockId: string, globalId: string) {
    return this.http.get(
      `https://recs.listrakbi.com/json/${blockId}?globalSessionUID=${globalId}`
    );
  }

  /**
   * Only perform if browser.
   * @param callback
   * @protected
   */
  protected pushLayer(callback: Function): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    return callback();
  }
}
