import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { LocalizationService } from '../localization/localization.service';
import { OcidItems } from '../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
})
export class CopyButtonComponent implements OnInit {
  @Input() color: ThemePalette = 'primary';
  @Input() text?: string;
  @Input() copiedText?: string;
  @Input() copy!: string;
  isCopied = false;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit(): void {
    this.localization
      .getOCIDs(['global.copy-link', 'global.link-copied'])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
