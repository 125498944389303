import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import { distinctUntilChanged } from 'rxjs/operators';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';
import { ListrakService } from '../../../service/gtm/listrak.service';
import { IRecommender } from '../../../contracts/listrak/irecommender';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-listrak-recommender',
  styleUrls: ['./listrak-recommender.component.scss'],
  templateUrl: './listrak-recommender.component.html',
})
export class ListrakRecommenderComponent
  implements AfterViewInit, CartridgeInterface
{
  @Input() data!: {
    title: string;
    blockCode: string;
  };
  @ViewChild('carousel')
  carousel!: SlickCarouselComponent;
  platformBrowser = false;
  slides = [];
  source = 'Listrak Recommender';
  slideConfig = {
    infinite: false,
    slidesToShow: 4,
    dots: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private listrakService: ListrakService
  ) {}

  ngAfterViewInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    if (this.platformBrowser) {
      // Need to get the _ltk.Session.GlobalID from GTM and this is the only way :(
      setTimeout(() => {
        this.listrakService
          .getRecommendedParts(
            this.data.blockCode,
            window['_ltk']?.Session.GlobalID
          )
          .pipe(distinctUntilChanged())
          .subscribe((partsData: []) => {
            // this.slides = partsData;
            this.slides = partsData.map((v: IRecommender) => ({
              attributes: {
                'product.displayName': [v.Title],
                productId: [v.Sku],
                productMedia: [{ fullImageUrl: v.ImageUrl }],
              },
              linkUrl: v.LinkUrl,
            }));
          });
      }, 1200);
    }
  }
}
