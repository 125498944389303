import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../contracts/ocid-items';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-product-detail-related-items',
  templateUrl: './product-detail-related-items.component.html',
  styleUrls: ['./product-detail-related-items.component.scss'],
})
export class ProductDetailRelatedItemsComponent
  implements OnInit, CartridgeInterface
{
  @Input() data: {
    seeAllLinkImageUrl: string;
    relatedItemsHeading: string;
    records: object[];
    horizPosition?: string;
    featuredPartsHeading?: string;
  };
  ocids: OcidItems = {};
  slideConfig = {
    slidesToShow: 4,
    dots: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  source: string;
  isPlatformBrowser = false;
  reRenderCarouselFlag = false;

  constructor(
    private localization: LocalizationService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit() {
    this.source = this.data.featuredPartsHeading
      ? 'Featured Parts'
      : 'Related Parts';
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
      // Re-render to clone items again once OCIDs are loaded, because the cloned items are not working within the Angular zone for lazy-loaded images and OCIDs.
      this.reRenderCarouselFlag = false;
      setTimeout(() => {
        this.reRenderCarouselFlag = true;
      }, 0);
    });

    this.isPlatformBrowser = isPlatformBrowser(this.platformId);
  }
}
