import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuService } from '../../../service/user/menu.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-apple-store-badge',
  templateUrl: './apple-store-badge.component.html',
})
export class AppleStoreBadgeComponent {
  badge$ = this.menu.getAppStoreBadgeMenu();

  constructor(private menu: MenuService) {}
}
