import { Component } from '@angular/core';
import { LayoutService } from '../../../service/layout.service';

@Component({
  selector: 'app-nav-toggle',
  templateUrl: './nav-toggle.component.html',
  styleUrls: ['./nav-toggle.component.scss'],
})
export class NavToggleComponent {
  constructor(public layoutService: LayoutService) {}

  /**
   * Toggle nav.
   */
  toggleNav(): void {
    this.layoutService.clearskyNavCollapsed =
      !this.layoutService.clearskyNavCollapsed;
  }
}
