import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-confirm-address',
  templateUrl: './confirm-address.component.html',
})
export class ConfirmAddressComponent implements OnInit {
  ocids: {} = {};
  hasAddress = false;
  hasDupe = false;
  exactDupe = false;
  selectedAddress;

  constructor(
    public dialogRef: MatDialogRef<ConfirmAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'address-validation.message-1',
        'address-validation.message-2',
        'address-validation.message-3',
        'address-validation.message-4',
        'address-validation.message-5',
        'address-validation.message-6',
        'address-validation.message-7',
        'address-validation.original-address',
        'address-validation.preferred-address',
        'address-validation.suggested-addresses',
      ])
      .subscribe();

    this.selectedAddress = this.data.originalAddress;
    this.hasAddress = this.data.alternates.length > 0 ? true : false;
    this.hasDupe = this.data.duplicates.length > 0 ? true : false;

    const duplicates = this.data.duplicates;
    if (this.hasDupe) {
      for (let i = 0; i < duplicates.length; i++) {
        if (
          this.data.originalAddress.addressLine.toLowerCase() ==
            duplicates[i].addressLine.toLowerCase() &&
          this.data.originalAddress.address2.toLowerCase() ==
            duplicates[i].address2.toLowerCase() &&
          this.data.originalAddress.city.toLowerCase() ==
            duplicates[i].city.toLowerCase() &&
          this.data.originalAddress.state == duplicates[i].state &&
          this.data.originalAddress.addressLine.zip ==
            duplicates[i].addressLine.zip
        ) {
          this.exactDupe = true;
          break;
        }
      }
    }
  }

  /**
   * Event when dialog is closed.
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
