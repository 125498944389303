import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { SearchService } from '../search/search.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-search-box-banner',
  styleUrls: ['./search-box-banner.component.scss'],
  templateUrl: './search-box-banner.component.html',
})
export class SearchBoxBannerComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    imageUrl: string;
    imageShading: string;
    position: string;
    searchBoxBannerHeading: string;
    searchBoxHelp: string;
    landingPageUrl: string;
    isLandingPageUrlExternal: boolean;
  };
  ocids = {};
  public isFocused = false;
  public searchType = 'default';

  constructor(
    private localization: LocalizationService,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    // WILL BE REMOVED ONCE WE HAVE ACTUAL DATA.
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.searchService.isSearchFocused.next(false);
    this.searchService.isSearchFocused.subscribe((isActive) => {
      this.isFocused = isActive;
    });
  }
}
