import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OcidItems } from '../../../../contracts/ocid-items';
import { ClearskyService } from '../../../clearsky.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDialogComponent } from '../widget-dialog/widget-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { MachineWidgetDialogComponent } from '../../machine-detail/widgets/machine-widget-dialog/machine-widget-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Machine } from 'app/contracts/clearsky/machine/machine.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss'],
})
export class WidgetHeaderComponent implements OnInit {
  @Output() editSettings: EventEmitter<void> = new EventEmitter<void>();
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Input() disableEdit = true;
  @Input() disableRemove = false;
  @Input() machine: Machine;
  @Input() disableMenu = false;
  @Input() widget: string | undefined;
  ocids: OcidItems = {};
  isCollapsed = true;

  constructor(
    protected clearskyService: ClearskyService,
    protected localization: LocalizationService,
    protected confirmation: ConfirmationDialogsService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.remove-label',
        'clearsky.are-you-remove-label',
        'clearsky.edit-label',
        'clearsky.add-widget-label',
      ])
      .pipe(first())
      .subscribe();
  }

  /**
   * On remove filter.
   */
  onRemove(): void {
    this.confirmation
      .confirm(
        this.ocids['clearsky.remove-label'],
        this.ocids['clearsky.are-you-remove-label'],
        this.ocids['global.yes']
      )
      .subscribe((confirm) => {
        if (confirm && this.widget) {
          this.clearskyService.removeWidget(this.widget);
        }
      });
  }

  /**
   * Show widget dialog.
   */
  onAddWidget(): void {
    if (this.machine) {
      this.dialog
        .open(MachineWidgetDialogComponent, {
          panelClass: ['clearsky-dialog'],
          data: {
            machine: this.machine,
          },
        })
        .afterClosed()
        .subscribe((data) => {
          if (data) {
            this.clearskyService.updateMachineWidgets(data);
          }
        });
      return;
    }
    this.dialog.open(WidgetDialogComponent, {
      panelClass: ['clearsky-dialog'],
    });
  }
}
