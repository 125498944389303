import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../../../shared/canonical.service';
import { TemplateInterface } from '../../template/template.class';

@Component({
  selector: 'app-two-column-page',
  templateUrl: './two-column-page.component.html',
})
export class TwoColumnPageComponent
  extends TemplateInterface
  implements OnInit
{
  @Input()
  data!: {
    headerContent: object[];
    leftContent: object[];
    mainContent: object[];
    baseBreadcrumbs?: object[];
    leftContentMarginRight: number;
    leftContentWidth: number;
    mainContentWidth: number;
    topMargin: number;
    footerContent: object[];
  };
  @Input('url') payloadUrl!: string;
  mainContentWidth = 'w-';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    protected titleService: Title,
    protected metaService: Meta,
    protected canonicalService: CanonicalService
  ) {
    super();
  }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    const platformBrowser = isPlatformBrowser(this.platformId);
    // Set metadata title & description
    this._setMetaData(platformBrowser);
    // Set the main content width class.
    this.mainContentWidth += this.data.mainContentWidth
      ? this.data.mainContentWidth
      : '75';
  }
}
