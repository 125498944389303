import { Component, OnInit } from '@angular/core';
import { OcidItems } from '../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-access-site-network-map-widget',
  templateUrl: './access-site-network-map-widget.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'widget' },
})
export class AccessSiteNetworkMapWidgetComponent implements OnInit {
  displayName = 'global.map-label';
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit(): void {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization.getOCIDs([this.displayName]).pipe(first()).subscribe();
  }
}
