import {
  Component,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IUser } from '../../../../contracts/user/iuser';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { IBillingHistory } from 'app/contracts/user/subscription/subscription.interface';
import { ClearskySubscriptionService } from 'app/clearsky/subscription/clearsky-subscription.service';
import { ClearskySubscriptionPlan } from 'app/contracts/clearsky/subscription/clearsky-subscription';
import { SUBSCRIPTION } from 'app/clearsky/subscription/subscription.constants';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-subscription-management',
  templateUrl: './account-subscription-management.component.html',
})
export class AccountSubscriptionManagementComponent implements OnInit {
  billinghistory: IBillingHistory[] = []
  passwordHide = true;
  ocids: {} = {};
  dataSource = new MatTableDataSource<IBillingHistory>([]);
  currentUser: IUser;
  subscriptions: ClearskySubscriptionPlan[];
  subscriptionConst = SUBSCRIPTION
  addOns: ClearskySubscriptionPlan[];
  apisAddOns: ClearskySubscriptionPlan[];
  showUpgrade = false;
  constructor(
    private localization: LocalizationService,
    private clearskySubscription: ClearskySubscriptionService,
    private currentUserService: CurrentUserService,
  ) { }

  ngOnInit() {
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.clearskySubscription.getPlans().subscribe((subscriptions: ClearskySubscriptionPlan[]) => {
      this.subscriptions = subscriptions.map(item => ({ ...item, discount: this.formatNumber(((item.listPrice - item.salePrice) / item.listPrice) * 100) }));
      let allOptions: ClearskySubscriptionPlan[] = this.subscriptions.filter((pkg) => pkg.subscribe).reduce((prev, curr) => [...prev, ...curr.addOns], []);
      allOptions = allOptions.map(item => ({ ...item, discount: this.formatNumber(((item.listPrice - item.salePrice) / item.listPrice) * 100) }));
      this.addOns = this.getUniqueAddOns(allOptions, true);
      this.apisAddOns = this.getUniqueAddOns(allOptions, false);
      this.showUpgrade = subscriptions.length > 1 && subscriptions.some(item => item.subscribe);
    })
    this.clearskySubscription.getBillingHistory().subscribe((billingHistory: IBillingHistory[]) => {
      this.billinghistory = billingHistory;
      this.dataSource.data = this.billinghistory;
    })
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'subscription-management.add-ons-label',
        'subscription-management.package-label',
        'subscription-management.api-label',
        'subscription-management.h1',
        'subscription-management.add-feature-label',
        'subscription-management.subscribed-status',
        'subscription-management.billing-story-label',
        'subscription-management.purchase-date-label',
        'subscription-management.amount-label',
        'subscription-management.renew-date-label',
        'subscription-management.next-charge-date-label',
        'subscription-management.learn-more-label',
        'subscription-management.trial-access-label',
        'subscription-management.how-to-access',
        'subscription-management.client-id-label',
        'subscription-management.client-secret-label',
        'subscription-management.view-password-label',
        'orderdetails.ordered-by',
        'clearsky.per-unit-annually-label'
      ])
      .subscribe();
  }

  getUniqueAddOns(allOptions, filterAddOn) {
    const uniqueAddOns = [];
    const itemNumberSet = new Set();
    allOptions.forEach(item => {
      if ((filterAddOn ? item.subscriptionType && item.subscriptionType === SUBSCRIPTION.ADD_ON : item.subscriptionType && item.subscriptionType !== SUBSCRIPTION.ADD_ON) && !itemNumberSet.has(item.itemNumber)) {
        itemNumberSet.add(item.itemNumber);
        uniqueAddOns.push(item);
      }
    });
    return uniqueAddOns;
  }

  formatNumber(number) {
    return number.toFixed(number % 1 !== 0 ? 2 : 0);
  }
  /**
  * Transforms the date returned from lastActivity to a format our custom date pipe can read.
  * @param {string} dateAsString
  * @returns {string}
  */
  transformDate(dateAsString: string) {
    const date = new Date(dateAsString);
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    return (
      date.getFullYear().toString() +
      (month.length === 1 ? '0' + month : month) +
      (day.length === 1 ? '0' + day : day)
    );
  }
}
