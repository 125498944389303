import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  HistoricSelection,
  Machine
} from '../../../../contracts/clearsky/machine/machine.dto';
import * as Highcharts from 'highcharts';
import { mergeDeep } from '../../../../shared/deep-merge';
import * as cloneDeep from 'lodash.clonedeep';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';
import { first } from 'rxjs/operators';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-battery-monitoring',
  templateUrl: './asset-battery-monitoring.component.html',
})
export class AssetBatteryMonitoringComponent implements OnInit, OnChanges {
  private chartEl: ElementRef;
  ocids: OcidItems = {};
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chartEl = content;
      this.createChart();
    }
  }
  @Input() machine: Machine;
  @Input() legend: CSLegend;
  @Input() historic: string = HistoricSelection.HOURS_24;
  plot;
  showChart = false;
  private chartDataMap = {
    [HistoricSelection.HOURS_24]: this.set24HourChartData.bind(this),
    [HistoricSelection.DAYS_7]: this.setHourDayChartData.bind(this),
    [HistoricSelection.DAYS_14]: this.setDayChartData.bind(this),
    [HistoricSelection.DAYS_31]: this.setDayChartData.bind(this),
    [HistoricSelection.DAYS_90]: this.setDayChartData.bind(this),
  };
  private historicChartConfig = {
    [HistoricSelection.HOURS_24]: this.get24HourConfig.bind(this),
    [HistoricSelection.DAYS_7]: this.getXAxisHourDayConfig.bind(this),
    [HistoricSelection.DAYS_14]: this.getXAxisHourDayConfig.bind(this),
    [HistoricSelection.DAYS_31]: this.getXAxisDayConfig.bind(this),
    [HistoricSelection.DAYS_90]: this.getXAxisDayConfig.bind(this),
  };
  private chartData = () =>
    mergeDeep(cloneDeep(BaseChartConfig), {
      chart: {
        height: 300,
      },
      yAxis: {
        title: {
          text: 'State of Charge',
        },
      },
      xAxis: {
        type: 'category',
      },
      legend: {
        enabled: false,
      },
      series: [],
    });
  constructor(private localization: LocalizationService) { }
  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.graphic-default',
      ])
      .pipe(first())
      .subscribe();
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    this.showChart = !!this.historicData.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.historic && !changes.historic.firstChange) {
      this.showChart = !!this.historicData.length;

      if (this.showChart) {
        this.drawChart();
      }
    }
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    this.plot = Highcharts.chart(this.chartEl.nativeElement, this.chartData());
    this.setChartData();
  }

  /**
   * Draw the chart or update it.
   */
  private drawChart(): void {
    // Does the chart already exist?
    if (this.plot && this.showChart) {
      this.setChartData();
    } else if (!this.showChart && this.plot) {
      this.plot.destroy();
    }
  }

  /**
   * Get chart data based on historic data.
   * @private
   */
  private setChartData(): void {
    // Remove old series first
    this.resetChartData();

    // Adjust config based on historic
    this.plot.update(
      mergeDeep(this.chartData(), this.historicChartConfig[this.historic]())
    );

    // Add series
    this.chartDataMap[this.historic]();
  }

  /**
   * Get global x axis config.
   * @private
   */
  private xAxisConfig(): object {
    const xAxis = this.historicData.map((point) => point.x);

    return {
      categories: xAxis.map((point) => dayjs(point)),
    };
  }

  /**
   * Chart configuration for 24 Hour.
   * @private
   */
  private set24HourChartData(): void {
    this.plot.addSeries({
      data: this.historicData.map((point) => {
        return [dayjs(point.x).format('hA'), point.y];
      }),
    });
  }

  /**
   * Get chart config for weekly charts (7 and 14 day).
   * @private
   */
  private get24HourConfig() {
    return {
      xAxis: {
        ...this.xAxisConfig(),
        labels: {
          formatter: function () {
            return dayjs(this.value).format('hA');
          },
        },
      },
    };
  }

  /**
   * Chart configuration for x axis charts with hour and m/dd.
   * @private
   */
  private setHourDayChartData(): void {
    this.plot.addSeries({
      data: this.historicData.map((point) => {
        return [dayjs(point.x).format('hA (M/DD)'), point.y];
      }),
    });
  }

  /**
   * Get chart config for data set.
   * @private
   */
  private getXAxisHourDayConfig() {
    return {
      xAxis: {
        ...this.xAxisConfig(),
        labels: {
          formatter: function () {
            return dayjs(this.value).format('hA (M/DD)');
          },
        },
      },
    };
  }

  /**
   * Chart configuration for day charts (31 and 90 days).
   * @private
   */
  private setDayChartData(): void {
    this.plot.addSeries({
      data: this.historicData.map((point) => {
        return [dayjs(point.x).format('M/DD'), point.y];
      }),
    });
  }

  /**
   * Get chart config for day x axis.
   * @private
   */
  private getXAxisDayConfig() {
    return {
      xAxis: {
        ...this.xAxisConfig(),
        labels: {
          formatter: function () {
            return dayjs(this.value).format('M/DD');
          },
        },
      },
    };
  }

  /**
   * Remove series from existing chart.
   * @private
   */
  private resetChartData(): void {
    if (this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Get 24 hour axis array.
   */
  get axis24Hour(): number[] {
    return [...Array(25).keys()];
  }

  get historicData(): { x: string; y: number }[] {
    switch (this.historic) {
      case HistoricSelection.HOURS_24:
        return (this.machine.socT7d ?? []).slice(0, 24).map((y, index) => ({
          x: (this.legend.d14h ?? [])[index],
          y,
        })).reverse();
      case HistoricSelection.DAYS_7:
        return (this.machine.socT7d ?? [])
          .slice(0, 7 * 24)
          .map((y, index) => ({
            x: (this.legend.d14h ?? [])[index],
            y,
          })).reverse();
      case HistoricSelection.DAYS_14:
        return (this.machine.socT90d ?? []).slice(0, 14).map((y, index) => ({
          x: (this.legend.d90d ?? [])[index],
          y,
        })).reverse();
      case HistoricSelection.DAYS_31:
        return (this.machine.socT90d ?? []).slice(0, 30).map((y, index) => ({
          x: (this.legend.d90d ?? [])[index],
          y,
        })).reverse();
      case HistoricSelection.DAYS_90:
        return (this.machine.socT90d ?? []).map((y, index) => ({
          x: (this.legend.d90d ?? [])[index],
          y,
        })).reverse();
      default:
        return [];
    }
  }
}
