// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hour-label {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-left: -10px;
}

.chart {
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVudmVsb3BlLXByb2ZpbGUtYm9vbS1saWZ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usa0JBQUE7RUFDQSxXQUFBO0VBQ0Esa0JBQUE7RUFDQSxrQkFBQTtBQUNGOztBQUVBO0VBQ0Usa0JBQUE7RUFDQSxXQUFBO0VBQ0EseUJBQUE7RUFDQSxnQkFBQTtBQUNGIiwiZmlsZSI6ImVudmVsb3BlLXByb2ZpbGUtYm9vbS1saWZ0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmhvdXItbGFiZWwge1xyXG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcclxuICB3aWR0aDogMTAwJTtcclxuICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgbWFyZ2luLWxlZnQ6IC0xMHB4O1xyXG59XHJcblxyXG4uY2hhcnQge1xyXG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcclxuICB3aWR0aDogMTAwJTtcclxuICBoZWlnaHQ6IGNhbGMoMTAwJSAtIDIwcHgpO1xyXG4gIG1hcmdpbi10b3A6IDIwcHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/charts/envelope-profile-use/envelope-profile-boom-lift/envelope-profile-boom-lift.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACA,wtBAAwtB","sourcesContent":[".hour-label {\r\n  position: absolute;\r\n  width: 100%;\r\n  text-align: center;\r\n  margin-left: -10px;\r\n}\r\n\r\n.chart {\r\n  position: absolute;\r\n  width: 100%;\r\n  height: calc(100% - 20px);\r\n  margin-top: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
