import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  KnowledgeArticle,
  KnowledgeArticleExistence,
} from '../contracts/atg/knowledge-article.dto';
import { environment } from '../../environments/environment';
import { catchError, map, take } from 'rxjs/operators';

@Injectable()
export class KnowledgeArticleService {
  constructor(private http: HttpClient) {}

  /**
   * Get knowledge articles for dtc codes.
   * @param codes
   */
  getArticlesByDtcCodes(
    codes: string[] | number[]
  ): Observable<KnowledgeArticleExistence[]> {
    return this.http
      .post<{ faultCodes: KnowledgeArticleExistence[] }>(
        `${environment.apiUrl}/knowledgeArticle/knowledgeArticlesForFaultCodes`,
        {
          faultCodes: codes.map((c) => c.toString()),
        }
      )
      .pipe(
        map((existence) => {
          return existence.faultCodes;
        }),
        catchError(() => of([]))
      );
  }

  /**
   * See if knowledge articles exists on an array of dtc (diagnostic trouble code)/fault codes.
   * @param codes
   */
  getArticleExistsByDtcCodes(codes: string[]): Observable<string[]> {
    return this.getArticlesByDtcCodes(codes).pipe(
      take(1),
      map((faultCodes) => {
        return faultCodes.reduce((prev, code) => {
          if (code.knowledgeArticleExists) {
            prev.push(code.code);
          }

          return prev;
        }, []);
      })
    );
  }

  /**
   * Get dtc to article mapping.
   * @param codes
   */
  getDtcsArticleLink(
    codes: number[] | string[]
  ): Observable<{ [dtc: string]: string }> {
    return this.getArticlesByDtcCodes(codes).pipe(
      take(1),
      map((faultCodes) => {
        return faultCodes.reduce((prev, code) => {
          if (code.knowledgeArticleExists) {
            prev[code.code] = code.knowledgeArticleUrl;
          }

          return prev;
        }, {} as { [dtc: string]: string });
      })
    );
  }

  /**
   * Get dtc to articles mapping.
   * @param codes
   */
  getDtcArticleLinks(
    codes: string[]
  ): Observable<{ [dtc: string]: KnowledgeArticle[] }> {
    return this.getArticlesByDtcCodes(codes).pipe(
      take(1),
      map((faultCodes) => {
        return faultCodes.reduce((prev, code) => {
          if (code.knowledgeArticleUrls.length) {
            prev[code.code] = code.knowledgeArticleUrls;
          }

          return prev;
        }, {} as { [dtc: string]: KnowledgeArticle[] });
      })
    );
  }

  /**
   * Submit the feedback for knowledge article
   * @param feedback
   */
  submitArticleFeedback(feedback: object) {
    return this.http
      .post(`${environment.apiUrl}/knowledgeArticle/feedback`, feedback)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Get knowledge articles associated with a dtc (diagnostic trouble code)/fault code.
   * @param code
   */
  getKnowledgeArticles(code: string): Observable<KnowledgeArticle[]> {
    return this.http
      .get<{ knowledgeArticleList: KnowledgeArticle[] }>(
        `${environment.apiUrl}/knowledgeArticle/knowledgeArticleByFaultCode/${code}`
      )
      .pipe(
        map((list) => list.knowledgeArticleList),
        catchError((err) => (err.status === 400 ? of([]) : throwError(err)))
      );
  }
}
