import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
selector: 'app-hpl60-form',
templateUrl: './hpl60-form.component.html'
})
export class HPL60FormComponent implements OnInit {
  @Input() ocids: {};
  @Input() hpl60Form: UntypedFormGroup;
  bodyOpts = ['60AL','60STL','84STL'];
  gridOpts = ['3500 lb L ARMS','5000 lb L ARMS','T-37 L ARMS'];
  wreckerBoomOpts = ['0808D','0808S','1210D','1210S','1212D','1212S','8DMWR','H408S'];
  tunnelToolBoxOpts = ['24 ALUM DOMESTIC','24 ALUM FOREIGN', '48 ALUM DOUBLE', '24 STL DOMESTIC', '2-24 STL DOMESTIC', '24 STL FOREIGN', '2-24 STL FOREIGN'];
  towSlingOpts = ['JD Tow Sling','None'];
  dollyOpts = ['Standard','None'];
  pushBumperOpts = ['STL','None'];

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.hpl60Form.addControl('body', this.formBuilder.control('', Validators.required));
    this.hpl60Form.addControl('grid', this.formBuilder.control('', Validators.required));
    this.hpl60Form.addControl('towSling', this.formBuilder.control('', Validators.required));
    this.hpl60Form.addControl('dolly', this.formBuilder.control('', Validators.required));
    this.hpl60Form.addControl('wreckerBoom', this.formBuilder.control('', Validators.required));
    this.hpl60Form.addControl('tunnelToolBox', this.formBuilder.control('', Validators.required));
    this.hpl60Form.addControl('pushBumper', this.formBuilder.control('', Validators.required));
  }

}