import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ShoppingListsService } from '../account-shopping-lists/shopping-lists.service';
import { ListItem } from '../../../../shared/list-item.model';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { ShareListDialogComponent } from '../account-shopping-lists/share-list/share-list-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { ItemDialogComponent } from './item/item-dialog.component';
import { CartService } from '../../../../service/cart/cart.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { IGiftList } from '../../../../contracts/commerce/igift-list';
import { IGiftListItem } from '../../../../contracts/commerce/igift-list-item';
import { NotificationService } from '../../../../service/notification/notification.service';
import { ConfirmationAlertService } from '../../../../service/confirmation/confirmation-alert.service';
import { mergeMap } from 'rxjs/operators';
import { IAddItemsDto } from '../../../../contracts/commerce/dto/iadd-items-dto';
import { ExcelService } from '../../../../service/excel/excel.service';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { IUser } from '../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IGiftListRemove } from '../../../../contracts/commerce/params/igiftlist-params';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

export interface ISelectedItem {
  item: IGiftListItem;
  indexInList: number;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-shopping-list',
  styleUrls: [
    '../account-shopping-lists/account-shopping-lists.component.scss',
  ],
  templateUrl: './account-shopping-list.component.html',
})
export class AccountShoppingListComponent implements OnInit {
  // Shopping list form which holds list info and also a form array of formgroups
  // that hold each item's select and quantity.
  shoppingListForm: UntypedFormGroup = this.formBuilder.group({
    items: this.formBuilder.array([]),
  });
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  totalItemsSelected = 0;
  list!: IGiftList;
  allSelected = false;
  ocids = {};
  addingItem = false;
  isEditingName = false;
  canEdit = true;
  platformBrowser = false;
  subTotal = 0;
  taxTotal = 0;
  orderTotal = 0;
  showItemTax = true;
  user!: IUser;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private alertService: ConfirmationAlertService,
    private cartService: CartService,
    private shoppingListsService: ShoppingListsService,
    private currentUserService: CurrentUserService,
    private excelService: ExcelService,
    private confirmationService: ConfirmationDialogsService,
    public dialog: MatDialog,
    private localization: LocalizationService,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Get OCIDS
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.list-name-label',
        'buy.in-stock',
        'shopping-list.delete-item',
        'shopping-list.delete-multiple-items',
        'shopping-list.added-multiple-items-cart',
        'shopping-list.item-added',
        'shopping-list.empty-message',
        'shopping-list.confirm-added-cart',
        'shopping-list.entire-list-added-cart',
        'shopping-list.privacy-info-icon-private',
        'shopping-list.privacy-info-icon-public',
        'shopping-list.remove-item',
        'shopping-list.saved-confirmation-message',
        'shopping-list.disclaimer',
      ])
      .subscribe();
    // Add the form controls listName and notes.
    this.shoppingListForm.addControl(
      'listName',
      this.formBuilder.control('', Validators.required)
    );
    this.shoppingListForm.addControl('public', this.formBuilder.control(''));
    this.shoppingListForm.addControl('notes', this.formBuilder.control(''));
    // Get list.
    this.notificationService.reset();
    this.shoppingListsService
      .getList({
        giftlistId: this.route.snapshot.url[2].toString(),
      })
      .pipe(
        mergeMap((list: IGiftList) => {
          this.list = list;

          // Sort the data so its in the correct order.
          this.sortData({
            active: 'itemNumber',
            direction: 'asc',
          });
          // Calculate the totals
          this.calculateTotal();
          // set form values
          this.shoppingListForm.controls.listName.setValue(this.list.eventName);
          this.shoppingListForm.controls.public.setValue(this.list.public);
          this.shoppingListForm.controls.notes.setValue(
            this.canEdit === false
              ? { value: this.list.description, disabled: true }
              : this.list.description
          );
          // Add a form group for each item on page load only (itemGroups.length == 0). Each form group contains a select and quantity.
          if (this.itemGroups.length === 0) {
            this.list.giftlistItems.forEach((item) => {
              this.addGroup(item.quantityDesired);
            });
          }
          return this.currentUserService.userSubject;
        })
      )
      .subscribe({
        next: (user: IUser) => {
          if (user) {
            this.canEdit = user.login === this.list.owner.login;
            this.showItemTax = user.appConfig.showItemTax;
            this.user = user;
          }
        },
        error: (error) => {
          this.notificationService.addError(error.error.title);
        },
      });
  }

  /**
   * Return the shopping list form group items as a FormArray.
   */
  get itemGroups() {
    return this.shoppingListForm.get('items') as UntypedFormArray;
  }

  /**
   * Accepts id which is used to make the formControlName and the quantity of the item
   * and adds it to the items group in shoppingListForm.
   *
   * @param {number} quantity
   */
  addGroup(quantity: number) {
    const newGroup = this.formBuilder.group({
      select: [false],
      quantity:
        this.canEdit === false
          ? new UntypedFormControl({ value: quantity, disabled: true })
          : [quantity, [Validators.min(1), Validators.required]],
    });
    this.itemGroups.push(newGroup);
  }

  /**
   * Remove a form control group at a given index from the items group in the shoppingListForm.
   *
   * @param {number} index
   */
  removeFormControl(index: number) {
    this.itemGroups.removeAt(index);
  }

  /**
   * Export the items of a shopping list for item upload on the Order Now page.
   */
  exportToExcel() {
    this.excelService.exportShoppingList(
      this.list.giftlistItems,
      this.list.eventName
    );
  }

  /**
   * Share a list.
   */
  shareList() {
    const dialogRef = this.dialog.open(ShareListDialogComponent, {
      width: '720px',
      data: this.list,
    });
  }

  /**
   * Print the page.
   */
  print() {
    // Only print the shopping list page if the platform is browser.
    if (this.platformBrowser) {
      window.print();
    }
  }

  /**
   * Toggle allSelected boolean and total items selected. This removed code bloat so
   * the browser isn't doing constant iterations over an array of form controls to count
   * how many items are selected.
   *
   * @param {any} event
   */
  checkboxChanged(event) {
    if (event.checked) {
      this.totalItemsSelected = this.totalItemsSelected + 1;
    } else {
      this.totalItemsSelected = this.totalItemsSelected - 1;
    }
    if (this.allSelected) {
      this.allSelected = false;
    }
  }

  /**
   * Select/deselect every item's select form control.
   */
  selectAll() {
    if (this.allSelected) {
      this.totalItemsSelected = 0;
      this.allSelected = false;
    } else {
      this.totalItemsSelected = this.list.giftlistItems.length;
      this.allSelected = true;
    }
    // Iterate through each giftlistItem in order to get the index and item id.
    this.list.giftlistItems.forEach((item: IGiftListItem, index: number) => {
      // Set a constant quantity which holds the real time quantity of the item (could be
      // the same as what was given in the payload or updated by user).
      // const quantity = this.itemGroups.at(index).value.quantity;
      // Patch the form control's value with the allSelected boolean variable.
      this.itemGroups.at(index).patchValue({
        select: this.allSelected,
      });
    });
  }

  /**
   * Gets all selected items along with their index. It also
   * filters any null values out of the array and returns the
   * new array of only selected items.
   * @returns {ISelectedItem[]}
   */
  getSelectedItems() {
    const selectedItems = this.list.giftlistItems.map(
      (item: IGiftListItem, index: number) => {
        if (this.itemGroups.at(index).value.select) {
          return {
            item: item,
            indexInList: index,
          };
        }
        return null;
      }
    );
    return selectedItems.filter((item) => item !== null);
  }

  /**
   * Adds an item to the list.
   */
  addItem() {
    const dialogRef = this.dialog
      .open(ItemDialogComponent, {
        width: '720px',
        data: {
          type: 'addItem',
          listId: this.list.id,
          item: new ListItem(-1, '', '', 1),
        },
      })
      .afterClosed()
      .subscribe(
        (data: {
          addedItem: boolean;
          itemNumber?: string;
          quantity?: number;
          allListItems?: IGiftListItem[];
        }) => {
          // If data is not undefined (say a user clicks outside the dialog box.)
          if (data !== undefined) {
            // and if data.addeditem is true.
            if (data.addedItem) {
              this.addingItem = true;
              // Get the most recent added item
              const itemExists: boolean = this.list.giftlistItems.some(
                (giftListItem: IGiftListItem) => {
                  return giftListItem.itemNumber === data.itemNumber;
                }
              );
              if (!itemExists) {
                this.addGroup(data.quantity);
              }
              this.list.giftlistItems = data.allListItems;
              this.sortData(this.sort);
              // Recalculate the totals
              this.calculateTotal();
              this.addingItem = false;
            }
          }
        }
      );
  }

  /**
   * Deletes an individual item from the list.
   *
   * @param {IGiftListItem} item
   * @param {number} index
   */
  removeSingleItem(item: IGiftListItem, index: number) {
    this.confirmationService
      .confirm(
        `${this.ocids['shopping-list.delete-item']} ${
          item.displayName ? item.displayName : item.itemNumber
        }`,
        '',
        this.ocids['global.delete'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.removeItem(item, index);
        }
      });
  }

  /**
   * Removes selected items from the list.
   */
  removeSelectedItems() {
    this.confirmationService
      .confirm(
        this.ocids['shopping-list.delete-multiple-items'],
        '',
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          // If all were selected, deselect all so that if a user adds another item after deleting them all,
          // select all remains unchecked.
          if (this.allSelected) {
            this.allSelected = !this.allSelected;
          }
          // Reverse the array so that the indexes that are deleted aren't out of bounds.
          const selectedItems = this.getSelectedItems().reverse();
          const indexes: number[] = [];
          const itemsToRemove: IGiftListRemove[] = [];
          selectedItems.forEach((selectedItem: ISelectedItem) => {
            indexes.push(selectedItem.indexInList);
            itemsToRemove.push({ giftlistItemId: selectedItem.item.id });
          });
          this.removeItems(itemsToRemove, indexes);
        }
      });
  }

  /**
   * Consolidate calls to remove item to reduce code bloat. Removes
   * an item from the list.
   *
   * @param {IGiftListItem} item
   * @param {number} index
   */
  removeItem(item: IGiftListItem, index: number) {
    this.notificationService.reset();
    this.shoppingListsService
      .deleteItem({
        giftlistId: this.list.id,
        giftlistItemId: item.id,
      })
      .subscribe(
        (items: IGiftListItem[]) => {
          if (this.itemGroups.at(index).value.select) {
            this.totalItemsSelected = this.totalItemsSelected - 1;
          }
          this.list.giftlistItems = items;
          this.removeFormControl(index);
          this.sortData(this.sort);
          // Recalculate the totals
          this.calculateTotal();
        },
        (error) => {
          console.log(error);
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   * Removes multiple items from the list.
   *
   * @param {<IGiftListRemove>[]} items
   * @param {<number>[]} indexes
   */
  removeItems(items: IGiftListRemove[], indexes: number[]) {
    this.notificationService.reset();
    this.shoppingListsService.deleteItems(this.list.id, items).subscribe(
      (res: IGiftListItem[]) => {
        this.list.giftlistItems = res;
        for (const index of indexes) {
          this.removeFormControl(index);
          if (this.itemGroups.at(index)?.value.select) {
            this.totalItemsSelected = this.totalItemsSelected - 1;
          }
        }
        this.sortData(this.sort);
        // Recalculate the totals
        this.calculateTotal();
      },
      (error) => {
        console.log(error);
        this.notificationService.addError(error.error.title);
      }
    );
  }

  /**
   * Add selected items to the cart.
   */
  addSelectedToCart() {
    if (this.shoppingListForm.status === 'VALID') {
      this.confirmationService
        .confirm(
          this.ocids['shopping-list.added-multiple-items-cart'],
          '',
          this.ocids['global.add-to-cart'],
          this.ocids['global.cancel']
        )
        .subscribe((result) => {
          if (result) {
            const selectedItems = this.getSelectedItems();
            const itemsToAdd: IAddItemsDto = { items: [] };
            const gaItemsToAdd = [];
            selectedItems.forEach((selectedItem: ISelectedItem, index) => {
              const quantity: number = this.canEdit
                ? this.itemGroups.at(selectedItem.indexInList).value.quantity
                : this.list.giftlistItems[index].quantityDesired;
              const itemNumber: string = selectedItem.item.itemNumber;
              itemsToAdd.items.push({
                catalogRefId: itemNumber,
                quantity: quantity,
                productId: itemNumber,
              });
              gaItemsToAdd.push({
                item_id: itemNumber,
                item_name: selectedItem.item.displayName,
                item_list_name: 'Shopping List',
                id: itemNumber,
                quantity: quantity,
              });
            });
            this.addItemsToCart(
              itemsToAdd,
              gaItemsToAdd,
              this.ocids['shopping-list.item-added']
            );
          }
        });
    }
  }

  /**
   * Determines if any items have been selected in the list. If items have been selected,
   * add those to the cart. If no items are selected, add all the items to the cart.
   */
  addAllOrSelectedToCart() {
    this.notificationService.reset();
    if (this.shoppingListForm.status === 'VALID') {
      if (this.totalItemsSelected > 0) {
        this.addSelectedToCart();
      } else {
        this.confirmationService
          .confirm(
            this.ocids['shopping-list.confirm-added-cart'],
            '',
            this.ocids['global.add-to-cart'],
            this.ocids['global.cancel']
          )
          .subscribe((result) => {
            if (result) {
              const itemsToAdd: IAddItemsDto = { items: [] };
              const gaItemsToAdd: object[] = [];
              this.list.giftlistItems.forEach(
                (item: IGiftListItem, index: number) => {
                  const quantity: number = this.canEdit
                    ? this.itemGroups.at(index).value.quantity
                    : item.quantityDesired;
                  const itemNumber: string = item.itemNumber;
                  itemsToAdd.items.push({
                    catalogRefId: itemNumber,
                    quantity: quantity,
                    productId: itemNumber,
                  });
                  gaItemsToAdd.push({
                    id: itemNumber,
                    name: item.displayName ? item.displayName : '',
                    quantity: quantity,
                  });
                }
              );
              this.addItemsToCart(
                itemsToAdd,
                gaItemsToAdd,
                this.ocids['shopping-list.entire-list-added-cart']
              );
            }
          });
      }
    }
  }

  /**
   * Method to consolidate the call to add potentially multiple items to the cart.
   *
   * @param {IAddItemsDto} itemsToAdd
   * @param {object[]} gaItemsToAdd
   * @param {string} message
   */
  addItemsToCart(
    itemsToAdd: IAddItemsDto,
    gaItemsToAdd: object[],
    message: string
  ) {
    this.cartService.addToCart(itemsToAdd).subscribe(
      () => {
        this.alertService.alertUser(message);
        // Send Add to Cart action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.addAllToCart(gaItemsToAdd, 'Shopping List');
        }
      },
      (error) => {
        this.notificationService.addError(error.error.title);
      }
    );
  }

  /**
   * Save the list. Update name and description (labeled notes in FE).
   */
  saveList() {
    this.notificationService.reset();
    // If either the listName or notes formControl has changed, update the list.
    if (
      this.shoppingListForm.controls.listName.value !== this.list.eventName ||
      this.shoppingListForm.controls.notes.value !== this.list.description ||
      this.shoppingListForm.controls.public.value !== this.list.public
    ) {
      this.shoppingListsService
        .updateList(
          {
            giftlistId: this.list.id,
          },
          {
            eventName: this.shoppingListForm.controls.listName.value,
            description: this.shoppingListForm.controls.notes.value,
            public: this.shoppingListForm.controls.public.value,
          }
        )
        .subscribe(
          (list: IGiftList) => {
            this.list = list;
            if (this.list.giftlistItems.length > 0) {
              // Recalculate the totals
              this.calculateTotal();
              // Re-sort the list once it is retrieved in case it has changed.
              this.sortData(this.sort);
            }
            // Alert the user that their profile was updated.
            this.alertService.alertUser(
              this.ocids['shopping-list.saved-confirmation-message']
            );
            // Set editing name to false in case it is open.
            this.isEditingName = false;
          },
          (error) => {
            console.log(error);
            this.notificationService.addError(error.error.title);
          }
        );
    } else {
      this.isEditingName = false;
    }
  }

  /**
   * Update the quantity of an item in the list.
   *
   * NOTE: There is no true update item of list endpoint. Use
   * add item. This endpoint is able to receive negative numbers so that
   * it can subtract items if the user wishes to lower an item amount
   * in their shopping list.
   *
   * @param {number} index
   */
  updateItemQuantity(index: number) {
    const newQuantity = this.itemGroups.at(index).value.quantity;
    // Only update the quantity if it has changed.
    if (this.list.giftlistItems[index].quantityDesired !== newQuantity) {
      this.notificationService.reset();
      this.shoppingListsService
        .addItem(
          {
            giftlistId: this.list.id,
          },
          {
            productId: this.list.giftlistItems[index].itemNumber,
            catalogRefIds: this.list.giftlistItems[index].itemNumber,
            quantity:
              newQuantity - this.list.giftlistItems[index].quantityDesired,
          }
        )
        .subscribe(
          (listItems: IGiftListItem[]) => {
            this.list.giftlistItems = listItems;
            // Recalculate the totals
            this.calculateTotal();
            // Re-sort the list once it is retrieved.
            this.sortData(this.sort);
            // Alert the user that their list has been updated.
            this.alertService.alertUser(
              this.ocids['shopping-list.saved-confirmation-message']
            );
          },
          (error) => {
            this.notificationService.addError(error.error.title);
          }
        );
    }
  }

  /**
   * Event emitted when sorting.
   *
   * @param {Sort} sort
   */
  sortData(sort: Sort) {
    // If sorting is inactive or not present, just return.
    if (sort) {
      if (!sort.active || sort.direction === '') {
        return;
      }
      const data = this.list.giftlistItems;
      this.list.giftlistItems = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        return compare(a.itemNumber, b.itemNumber, isAsc);
      });
    }
  }
  /**
   * Calculate totals
   */
  calculateTotal() {
    this.subTotal = 0;
    this.taxTotal = 0;
    for (let i = 0; i < this.list.giftlistItems.length; i++) {
      this.subTotal +=
        +this.list.giftlistItems[i].listPrice *
        this.list.giftlistItems[i].quantityDesired;
      if (this.list.giftlistItems[i].taxPrice) {
        this.taxTotal += +this.list.giftlistItems[i].taxPrice;
      }
    }
    this.orderTotal = this.subTotal + this.taxTotal;
  }
}

function compare(a: string, b: string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
