import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { OktaAuthWrapper } from '../../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { CartService } from '../../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../../service/confirmation/confirmation-alert.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ShoppingListComponent } from '../../shopping-cart/shopping-list/shopping-list.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { Observable } from 'rxjs';
import { IUser, UserEnvironments } from 'app/contracts/user/iuser';
import { environment } from 'environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-results-list-item]',
  styleUrls: ['./result-list-item.component.scss'],
  templateUrl: './results-list-item.component.html',
})
export class ResultsListItemComponent implements OnInit {
  @Input('product') item: {
    records: object[];
    numRecords: number;
    linkUrl?: string;
    detailsAction: {
      contentPath: string;
      recordState: string;
    };
    attributes: {
      machinePlatformHeight: string[];
      machineWidth: string[];
      machineWorkingHeight: string[];
      'product.repositoryId': string[];
      productId: string[];
      productBadge?: string[];
      'product.displayName': string[];
      'product.category': string[];
      productType: string[];
      productMedia: {
        imageAltText: string;
        thumbnailImageUrl: string;
        fullImageUrl: string;
      }[];
    };
  };
  @Input() source!: string;
  isLoggedIn = false;
  orderForm!: UntypedFormGroup;
  ocids = {};
  platformBrowser = false;
  user$: Observable<IUser> = this.currentUserService.userSubject;
  userEnvironments = UserEnvironments;
  isJlg = environment.jlgStyling;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public dialog: MatDialog,
    private oktaAuth: OktaAuthWrapper,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private localization: LocalizationService,
    private fb: UntypedFormBuilder,
    private ecommService: EcommerceService,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    // Setup localization
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });

    // Setup form
    this.orderForm = this.fb.group({
      quantity: this.fb.control(1, [Validators.required, Validators.min(1)]),
    });

    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn
      .pipe(distinctUntilChanged())
      .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn));
  }

  /**
   * Event emitted when adding results item to shopping cart.
   */
  onAddToShoppingList() {
    if (this.isLoggedIn) {
      // If there is a valid quantity, add it to the shopping list.
      if (this.orderForm.valid) {
        // Pop open dialog
        const shoppingListModal = this.dialog.open(ShoppingListComponent, {
          width: '380px',
          data: {
            items: [
              {
                itemNumber: this.item.attributes.productId[0],
                quantity: +this.quantity.value,
              },
            ],
          },
        });

        shoppingListModal.afterClosed().subscribe((result) => {
          if (result) {
            this.alertService.addToShoppingList(
              this.item.attributes['product.displayName'][0]
                ? this.item.attributes['product.displayName'][0]
                : this.item.attributes.productId[0]
            );
            if (this.platformBrowser) {
              this.ecommService.addToWishlist(
                this.item.attributes.productId[0],
                +this.quantity.value,
                this.source
              );
            }
          }
        });
      }
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.onAddToShoppingList();
          }
        });
    }
  }

  /**
   * Event emitted when adding to cart on result item.
   */
  onAddToCart() {
    // If there is a valid quantity, add it to the cart.
    if (this.orderForm.valid) {
      this.cartService
        .addOneToCart(
          this.item.attributes['productId'][0],
          +this.quantity.value
        )
        .subscribe(() => {
          this.cartService.refreshCart();
          this.quantity.setValue(1);
          this.quantity.markAsPristine();
          this.quantity.markAsUntouched();
          this.alertService.addToCartConfirm(
            this.item.attributes['product.displayName'][0]
          );
          // Send Add to Cart action to GA if the platform is browser
          if (this.platformBrowser) {
            this.ecommService.addToCart(
              this.item.attributes['productId'][0],
              this.item.attributes['product.category']?.[0] ?? '',
              this.item.attributes['product.displayName'][0],
              +this.quantity.value,
              this.source
            );
          }
        });
    }
  }

  // Send the click action to GA for Product List Tracking
  productClick() {
    if (this.platformBrowser) {
      this.ecommService.selectItem(
        this.item.attributes['productId'][0],
        this.item.attributes['product.displayName'][0],
        this.source
      );
    }
  }

  get seoUrl() {
    return this.item.linkUrl
      ? this.item.linkUrl.split('jlg.com')[1]
      : this.item.detailsAction.contentPath +
          this.item.detailsAction.recordState;
  }

  get quantity() {
    return this.orderForm.get('quantity');
  }
}
