import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BasePlotChart } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import { combineLatest, mergeMap, Subscription } from 'rxjs';
import { Machine } from '../../../../contracts/clearsky/machine/machine.dto';
import { MachineDetailsDialogComponent } from '../../machine-details-dialog/machine-details-dialog.component';
import { MachineDialogConfig } from '../../../../contracts/clearsky/machine/machine.dialog.config';
import { ClearskyService } from '../../../clearsky.service';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

interface ChartDataItem {
  custom: unknown;
  x: number;
  y: number;
  name: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-age-scatter',
  templateUrl: './fleet-age-scatter.component.html',
})
export class FleetAgeScatterComponent implements AfterViewInit {
  @ViewChild('chartEl') chartEl: ElementRef;
  private plot;
  private ageChart = mergeDeep(cloneDeep(BasePlotChart.scatter), {
    chart: {
      events: {
        selection: this.onChartSelection.bind(this),
      },
    },
    plotOptions: {
      scatter: {
        events: {
          click: (event: { point: { options: { custom: Machine } } }) => {
            this.gAService.eventEmitter(
              'clearsky_click',
              'fleet_age_scatter',
              'click',
              'serial_number',
              event.point.options.custom.sn
            );
            this.dialog.open(MachineDetailsDialogComponent, {
              ...MachineDialogConfig,
              data: { sn: event.point.options.custom.sn },
            });
          },
        },
        turboThreshold: 0,
      },
    },
    xAxis: {
      minTickInterval: 1,
      title: {
        text: 'Years',
      },
    },
    yAxis: {
      title: {
        text: 'Operating Hours',
      },
    },
  });
  private ageChartData: ChartDataItem[] = [];
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private dialog: MatDialog,
    private gAService: GoogleAnalyticsService
  ) {}

  ngAfterViewInit(): void {
    this.createChart();

    this.subs = this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.fleetAge)
      .pipe(
        mergeMap((res) => {
          this.ageChartData = (
            (res && (res.machines as Machine[])) ||
            []
          ).reduce((prev, machine) => {
            if (machine.mxAge && machine.copHrs) {
              prev.push({
                x: machine.mxAge,
                y: machine.copHrs,
                name: machine.sn ?? '',
                custom: machine,
              });
            }

            return prev;
          }, [] as ChartDataItem[]);

          this.setChartData();

          return combineLatest([
            this.clearskyService.getCurrentFilterValues(CSFilter.mxAge.key),
            this.clearskyService.getCurrentFilterValues(CSFilter.copHrs.key),
          ]);
        })
      )
      .subscribe(([ageFilter, copFilter]) => {
        if (ageFilter.length) {
          // 'zoom out for x-axis here'
          this.plot.xAxis[0].setExtremes(undefined, undefined);
        }

        if (copFilter.length) {
          // zoom out for y-axis here
          this.plot.yAxis[0].setExtremes(undefined, undefined);
        }
      });
  }

  /**
   * Set the chart data.
   * @private
   */
  private setChartData(): void {
    const seriesCount = this.plot.series.length;
    for (let i = 0; i < seriesCount; i++) {
      this.plot.series[i].remove(true);
    }

    /**
     * Set chart data here.
     */
    this.plot.addSeries({
      showInLegend: false,
      data: this.ageChartData,
      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          return `Machine Id: ${this.name}`;
        },
      },
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    this.plot = Highcharts.chart(this.chartEl.nativeElement, this.ageChart);
  }

  /**
   * On chart selection event (drag and drop with a range).
   * @param e
   * @private
   */
  private onChartSelection(e: { xAxis: { max: number; min: number }[] }): void {
    const min = e.xAxis?.[0]?.min as number;
    const max = e.xAxis?.[0]?.max as number;

    this.clearskyService.updateFilters([
      {
        key: CSFilter.mxAge.key,
        values: [min.toFixed(2), max.toFixed(2)],
      },
      {
        key: CSFilter.copHrs.key,
        values: [Math.ceil(min), Math.ceil(max)],
      },
    ]);
  }
}
