import { Component, Inject, OnInit } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-auth-form',
  styleUrls: ['./auth-form.component.scss'],
  templateUrl: './auth-form.component.html',
})
export class AuthFormComponent implements OnInit {
  ocids: OcidItems = {};
  isJlg: boolean = environment.jlgStyling;

  constructor(
    private localization: LocalizationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.localization
      .getOCIDs([
        'global.create-an-account',
        'global.sign-in',
        'global.register',
        'sign-in.subtext',
        'create-account.subtext',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Redirect user to digital ID SSO login.
   */
  login(): void {
    this.document.location.href = `${environment.digitalIdUrl}/?hostUrl=${this.document.location.href}`;
  }

  register(): void {
    this.document.location.href = `${environment.digitalIdUrl}/registration/?hostUrl=${this.document.location.href}`;
  }
}
