export enum ClearskySubscriptionPlanName {
  basic = 'CSC',
  pro = 'CSAC',
}

export interface ClearskySubscriptionPlan {
  amount: number;
  description: string;
  itemNumber: string;
  salePrice: number;
  addOns: ClearskySubscriptionPlan[];
  name: string;
  id: ClearskySubscriptionPlanName;
  listPrice: number;
  subscribe?: boolean;
}

export interface ClearskyCart {
  itemNumber?: string;
  amount: number;
  salePrice: number;
  addOns: ClearskySubscriptionPlan[];
  name?: string;
  itemTax: number;
  id?: string;
  listPrice: number;
  addonsTotal: number;
  packageTotal: number;
  taxTotal: number;
}

export interface ClearskySubscriptionCheckout {
  poNumber?: string;
}
