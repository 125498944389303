import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Machine } from '../../../contracts/clearsky/machine/machine.dto';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { ClearskyService } from '../../clearsky.service';
import { ClearskyMapService } from '../clearsky-map.service';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-site-network-assets-table',
  templateUrl: './site-network-assets-table.component.html',
  styleUrls: ['./site-network-assets-table.component.scss'],
})
export class SiteNetworkAssetsTableComponent implements OnInit {
  ocids: OcidItems = {};
  @Input() set visibleMachines(machines: Machine[]) {
    this.machines = machines;
    this.dataSource = new MatTableDataSource(this.machines);
  }
  @Input() availableNetworks: SiteNetwork[] = [];
  @Output() onAssetClick: EventEmitter<Machine> = new EventEmitter<Machine>();
  displayedColumns: string[] = ['sn', 'eid', 'model', 'alerts', 'networks'];
  machines: Machine[];
  dataSource: MatTableDataSource<Machine>;
  changedSerialNumberValues: { [serialNumber: string]: string } = {};
  highlightedSerialNumber: string | undefined;

  constructor(
    private clearskyService: ClearskyService,
    private clearskyMapService: ClearskyMapService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {

    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.model-label',
        'clearsky.of-label',
        'clearsky.change-network-label',
        'clearsky.no-assets-label',
      ])
      .pipe(first())
      .subscribe();
    // Check if site networks have changes on refresh
    this.clearskyService.loggedNetworkChanges$.subscribe((changes) => {
      this.changedSerialNumberValues = {};

      // Now log change
      changes.forEach((change) => {
        // Now log network value for this machine
        this.changedSerialNumberValues[change.sn] = change.snID;
      });
    });

    // Every time this changes, highlight machine if it exists in machine list
    this.clearskyMapService.machinePinClicked$.subscribe((machine) => {
      this.highlightedSerialNumber = machine && machine.sn;
    });
  }

  /**
   * On selection change.
   * @param machine
   * @param change
   */
  changeNetwork(machine: Machine, change: MatSelectChange): void {
    if (change.value) {
      this.clearskyService
        .logSiteNetworkChange(
          machine.sn,
          change.value, // site network id
          change.value !== machine.snID // addTo = true if not the same network, otherwise remove
        )
        .pipe(first())
        .subscribe();
    } else {
      this.clearskyService
        .removeSiteNetworkChange(machine.sn)
        .pipe(first())
        .subscribe();
    }
  }
}
