import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table',
  templateUrl: './pricing-plan-table.component.html',
  styleUrls: ['./pricing-plan-table.component.scss'],
})
export class PricingPlanTableComponent {
  @HostBinding('class') hostClass = 'pricing-plan-table';
}
