// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-content {
  min-height: 450px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hY2hpbmUtaGlzdG9yaWMtZHRjLWNoYXJ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsaUJBQUE7QUFERiIsImZpbGUiOiJtYWNoaW5lLWhpc3RvcmljLWR0Yy1jaGFydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgXCJtaXhpbnMvYnJlYWtwb2ludHNcIiBhcyAqO1xuXG4uY2hhcnQtY29udGVudCB7XG4gIG1pbi1oZWlnaHQ6IDQ1MHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/machine-detail/machine-historic-dtc-chart/machine-historic-dtc-chart.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;AACA,wZAAwZ","sourcesContent":["@use \"mixins/breakpoints\" as *;\n\n.chart-content {\n  min-height: 450px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
