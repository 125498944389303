import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { CartService } from '../../../../service/cart/cart.service';
import { ShoppingListsService } from './shopping-lists.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { IGiftList } from '../../../../contracts/commerce/igift-list';
import { ShoppingListDialogComponent } from './shopping-list-dialog/shopping-list-dialog.component';
import { ConfirmationAlertService } from '../../../../service/confirmation/confirmation-alert.service';
import { ShareListDialogComponent } from './share-list/share-list-dialog.component';
import { merge, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  onErrorResumeNext,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { NotificationService } from '../../../../service/notification/notification.service';
import { IGiftListDto } from '../../../../contracts/commerce/dto/igift-list-dto';
import { IGiftListResponse } from '../../../../contracts/commerce/igift-list-response';
import { IAddItemsDto } from '../../../../contracts/commerce/dto/iadd-items-dto';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { IUser } from '../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-shopping-lists',
  styleUrls: ['./account-shopping-lists.component.scss'],
  templateUrl: './account-shopping-lists.component.html',
})
export class AccountShoppingListsComponent implements OnInit, OnDestroy {
  @ViewChild('confirmDialog', { read: ViewContainerRef })
  confirmDialog: ViewContainerRef;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  search = '';
  searchFilterLists: EventEmitter<any> = new EventEmitter<any>();
  sortedData: IGiftList[];
  dataSource: MatTableDataSource<IGiftList> = new MatTableDataSource<IGiftList>(
    []
  );
  dataLength: number;
  filterableLoginIds: string[] = [];
  filterByLoginId: string = null;
  listFormGroup: UntypedFormGroup;
  privatePublicFormArray: UntypedFormArray;
  user: IUser;
  ocids = {};
  platformBrowser = false;
  submitted = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationDialogsService,
    private shoppingListsService: ShoppingListsService,
    private currentUserService: CurrentUserService,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private localization: LocalizationService,
    private ecommService: EcommerceService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Subscribe to the user.
    this.currentUserService.userSubject
      .pipe(
        mergeMap((user: IUser) => {
          if (user) {
            this.user = user;
            // Get all logins that have public shopping lists under
            return this.shoppingListsService.getPublicListUsers();
          }
          return of({
            result: { filterableLoginIds: [] },
          });
        })
      )
      .subscribe(
        (result: { filterableLoginIds: string[] }) => {
          this.filterableLoginIds = result.filterableLoginIds;
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
    // Get OCIDs
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.date-created-title',
        'account.list-name-label',
        'account.new-list-link',
        'shoppinglist.delete-message',
        'shopping-list.add-list-shopping-cart-message',
        'shopping-list.entire-list-added-cart',
        'shopping-list.privacy-info-icon-private',
        'shopping-list.privacy-info-icon-public',
        'shopping-list.remove-item',
        'shopping-lists.search',
      ])
      .subscribe();
    // Merge any search, sort, or pagination events.
    merge(this.searchFilterLists, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.notificationService.reset();
          // Create the parameters for how to page and sort the data.
          const params: IGiftListDto = {
            limit: this.paginator.pageSize
              ? this.paginator.pageSize
              : this.pageSize,
            offset: this.paginator.pageIndex + 1,
            sortBy: this.sort.active ? this.sort.active : 'eventName',
          };
          // If a search term exists, add it to the parameters.
          if (this.search !== '') {
            params.eventName = this.search;
          }
          // If the sort direction is descending, add it to the parameters. Default
          // sort is ascending.
          if (this.sort.direction === 'desc') {
            params.sortDir = 'desc';
          }
          // If the user has elected to filter by a login id, add it to the paramseters.
          if (this.filterByLoginId) {
            params.login = this.filterByLoginId;
          }
          // Get the credit cards.
          return this.shoppingListsService.getLists(params).pipe(
            catchError((error) => {
              // Show the error to the user.
              this.notificationService.addError(error.error.title);
              // Return null.
              return null;
            }),
            onErrorResumeNext()
          );
        }),
        map((lists: IGiftListResponse) => {
          return lists;
        }),
        catchError((err) => {
          if (err) {
            // If not cancelled
            this.notificationService.addError(err.error?.title);
          }
          return of([]);
        })
      )
      .subscribe((lists: IGiftListResponse) => {
        this.privatePublicFormArray = this.formBuilder.array(
          lists.items.map((list: IGiftList) => {
            return this.formBuilder.control(
              list.owner.login === this.user.login
                ? list.public
                : { value: false, disabled: true }
            );
          })
        );
        this.listFormGroup = this.formBuilder.group({
          privatePublicFormArray: this.privatePublicFormArray,
        });
        this.dataSource.data = lists.items;
        this.dataLength = lists.totalResults;
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Emit search list event when search is submitted.
   */
  submitSearchFilterEvent() {
    this.searchFilterLists.emit();
    this.submitted = true;
  }

  /**
   * Clear the search input and emit search list event to refresh the table.
   */
  resetSearch() {
    if (this.search !== '') {
      this.search = '';
      this.searchFilterLists.emit();
      this.submitted = false;
    }
  }

  /**
   * Event emitted when clicking 'new list' link.
   */
  onAddList() {
    this.dialog
      .open(ShoppingListDialogComponent, {
        width: '720px',
      })
      .afterClosed()
      .pipe(
        mergeMap((result: IGiftList) => {
          if (result) {
            // Emit a "search" event that will trigger refresh of table.
            this.submitSearchFilterEvent();
            this.notificationService.reset();
            return this.shoppingListsService.getPublicListUsers();
          }
          return of(null);
        })
      )
      .subscribe(
        (result: { filterableLoginIds: string[] }) => {
          if (result) {
            // Handle the new filterable login ids.
            this.handleNewFilterableLoginIds(result.filterableLoginIds);
            // Refresh the table.
            this.submitSearchFilterEvent();
          }
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   * Update whether a list is private or public.
   * @param {MatSlideToggleChange} event
   * @param {number} listIndex
   */
  publicPrivateChange(event: MatSlideToggleChange, listIndex: number) {
    this.notificationService.reset();
    // Get the list to update.
    const listToUpdate: IGiftList = this.dataSource.data[listIndex];
    // Update the list with the new public value.
    this.shoppingListsService
      .updateList(
        {
          giftlistId: listToUpdate.id,
        },
        {
          eventName: listToUpdate.eventName,
          description: listToUpdate.description,
          public: event.checked,
        }
      )
      .subscribe(
        (updatedList: IGiftList) => { },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   *  Handles any new filterable login ids.
   */
  handleNewFilterableLoginIds(filterableLoginIds: string[]) {
    // If the user can no longer filter by their ID (they have no lists) and they currently have their
    // filter set to their own login id, then we need to clear the filter, retrieve the list
    if (
      !filterableLoginIds.includes(this.user.login) &&
      this.filterByLoginId === this.user.login
    ) {
      this.filterByLoginId = null;
    }
    this.filterableLoginIds = filterableLoginIds;
  }

  shareList(list: IGiftList) {
    const dialogRef = this.dialog.open(ShareListDialogComponent, {
      width: '720px',
      data: list,
    });
  }

  /**
   * Event emitted when deleting list.
   *
   * @param {IGiftList} list
   */
  onDeleteList(list: IGiftList) {
    if (list.owner.login === this.user.login) {
      this.confirmationService
        .confirm(
          this.ocids['shoppinglist.delete-message'] + ' ' + list.eventName,
          '',
          this.ocids['global.delete'],
          this.ocids['global.cancel']
        )
        .subscribe((result) => {
          if (result) {
            this.notificationService.reset();
            this.shoppingListsService
              .deleteList({
                giftlistId: list.id,
              })
              .pipe(
                mergeMap(() => {
                  return this.shoppingListsService.getPublicListUsers();
                })
              )
              .subscribe(
                (result: { filterableLoginIds: string[] }) => {
                  // Handle the new filterable login ids.
                  this.handleNewFilterableLoginIds(result.filterableLoginIds);
                  // Refresh the table.
                  this.submitSearchFilterEvent();
                },
                (error) => {
                  this.notificationService.addError(error.error.title);
                }
              );
          }
        });
    }
  }

  addToCart(list: IGiftList) {
    this.confirmationService
      .confirm(
        `${this.ocids['shopping-list.add-list-shopping-cart-message']} ${list.eventName}`,
        '',
        this.ocids['global.add'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.notificationService.reset();
          const itemsToAdd: IAddItemsDto = { items: [] };
          const gaItemsToAdd = [];
          list.giftlistItems.forEach((item) => {
            itemsToAdd.items.push({
              catalogRefId: item.productId,
              quantity: +item.quantityDesired,
              productId: item.productId,
            });
            gaItemsToAdd.push({
              item_id: item.productId,
              item_name: item.displayName ? item.displayName : '',
              item_list_name: 'Shopping List',
              id: item.productId,
              name: item.displayName ? item.displayName : '',
              quantity: +item.quantityDesired,
            });
          });
          this.cartService.addToCart(itemsToAdd).subscribe(
            () => {
              this.alertService.alertUser(
                this.ocids['shopping-list.entire-list-added-cart']
              );
              // Send Add to Cart action to GA if the platform is browser
              if (this.platformBrowser) {
                this.ecommService.addAllToCart(gaItemsToAdd, 'Shopping List');
              }
            },
            (error) => {
              this.notificationService.addError(error.error.title);
            }
          );
        }
      });
  }

  get isDirty() {
    return this.search && this.submitted;
  }

  /**
   * Complete subscriptions from ngOnInit.
   */
  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
