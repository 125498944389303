// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-legend {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.chart-legend.vertical {
  flex-direction: column;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXJ0LWxlZ2VuZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSw4QkFBQTtFQUNBLFlBQUE7QUFDRjtBQUNFO0VBQ0Usc0JBQUE7QUFDSiIsImZpbGUiOiJjaGFydC1sZWdlbmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2hhcnQtbGVnZW5kIHtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcclxuICBoZWlnaHQ6IDEwMCU7XHJcblxyXG4gICYudmVydGljYWwge1xyXG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/chart/chart-legend/chart-legend.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,YAAA;AACF;AACE;EACE,sBAAA;AACJ;AACA,ohBAAohB","sourcesContent":[".chart-legend {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  height: 100%;\r\n\r\n  &.vertical {\r\n    flex-direction: column;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
