// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cs-main {
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--cs-body-bg);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNzLWxheW91dC1tYWluLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLGlCQUFBO0VBQ0EsbUNBQUE7QUFDRiIsImZpbGUiOiJjcy1sYXlvdXQtbWFpbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jcy1tYWluIHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC13cmFwOiBub3dyYXA7XG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWNzLWJvZHktYmcpO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/cs-layout/cs-layout-main/cs-layout-main.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,mCAAA;AACF;AACA,waAAwa","sourcesContent":[".cs-main {\n  display: flex;\n  flex-wrap: nowrap;\n  background-color: var(--cs-body-bg);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
