import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as cloneDeep from 'lodash.clonedeep';
import {
  BasicWeather,
  CSFieldType,
  Machine,
  MachineFieldDisplay,
  UnitType,
} from '../contracts/clearsky/machine/machine.dto';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  merge,
  Observable,
  of,
  Subject,
  throwError,
  timer,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CSFilter,
  CSFilterValsByLegend,
  MachineFilterSelection,
} from '../contracts/clearsky/machine/machine-filter-v2';
import {
  catchError,
  filter,
  first,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { ConfirmationAlertService } from '../service/confirmation/confirmation-alert.service';
import { CurrentUserService } from '../service/user/current-user.service';
import { BingMapsService } from '../service/bing-maps.service';
import {
  AvailableWidgets,
  CsDashboard,
  CsDashboardData,
  DefaultColumns,
  DefaultWidgets,
} from '../contracts/clearsky/dashboard/cs-dashboard.dto';
import { WidgetService } from './widget.service';
import { ExcelService } from '../service/excel/excel.service';
import { saveAs } from 'file-saver-es';
import XLSX from 'xlsx-populate/browser/xlsx-populate.min';
import { getFieldDisplay } from './field-display.pipe';
import {
  LoggedSiteNetworkChange,
  SiteNetwork,
  SiteNetworkUpdate,
} from '../contracts/clearsky/site-network';
import { JlgLocation } from '../contracts/clearsky/jlg-location';
import { LocalizationService } from '../shared/localization/localization.service';
import {
  getMachineFieldOcidTranslation,
  MachineFieldDisplayValueOcids,
  reverseMappingColumns,
} from '../contracts/clearsky/machine/machine.fields';
import {
  MachineModelNumberImages,
  MxMGroupImg,
} from '../contracts/clearsky/machine/machine.images';
import {
  AvailableMachineWidgets,
  DefaultMachineWidgets,
  MachineWidgetsKey,
} from '../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { CSLegend } from '../contracts/clearsky/clearsky-legend';
import {
  CSFilterKeys,
  CSFilterVals,
  filtersAreEqual,
  transformToQAPIFilters,
} from '../contracts/clearsky/machine/machine-filter-v2';
import {
  CsMachineRequest,
  CsMxRequestBody,
  CsReqKeyImgReq,
  CsRequestExpect,
  CsRequestKeys,
  CsRequestKeysExpect,
  CsRequestKeysToColumns,
  CsRequestKeyStorageItem,
} from '../contracts/clearsky/cs-machines-request';
import { convertToLegendVal } from './shared/legend-value.pipe';
import { MxPendUpd } from '../contracts/clearsky/machine/machine.pending.update';
import { CsListColumns } from '../contracts/clearsky/dashboard/cs-list-view';

const CACHE_SIZE = 1;
const LANDMARK_CACHE_TIME = 600000;

@Injectable({
  providedIn: 'root',
})
export class ClearskyService {
  private cachedLandmarks$: Observable<JlgLocation[]>;
  private reloadMachines$: Subject<void> = new Subject<void>(); // This triggers retrieval of new machine data under allMachines$
  private forceReload$: Subject<void> = new Subject<void>(); // This is to tell getCachedMachines$ to force a reload
  private _forceMachineReload = new Subject<void>();
  forceMachineReload$: Observable<void> =
    this._forceMachineReload.asObservable();
  cachedLegend$: Observable<CSLegend>;
  private _keyRequests: CsRequestKeyStorageItem[] = [];

  private filterCookieName = 'csSelectedFilters';
  private columnCookieName = 'csDisplayedColumns';
  private widgetCookieName = 'csDisplayedWidgets';
  private networkChangeCookieName = 'csSiteNetworkChanges';
  private machineWidgetCookieName = 'csDisplayedMachineWidgets';
  private mxUpdCookieName = 'csMxPendUpds';

  private _pendMxUpds: BehaviorSubject<MxPendUpd[]> = new BehaviorSubject<
    MxPendUpd[]
  >(this.initCookie(this.mxUpdCookieName, []));
  pendMxUpds$: Observable<MxPendUpd[]> = this._pendMxUpds.asObservable().pipe(
    tap((upds) => this.updateCookie(this.mxUpdCookieName, upds)),
    shareReplay(1)
  );
  private _currentFilters: BehaviorSubject<MachineFilterSelection[]> =
    new BehaviorSubject<MachineFilterSelection[]>(
      this.initCookie(this.filterCookieName, [])
    );
  currentFilters$: Observable<MachineFilterSelection[]> = this._currentFilters
    .asObservable()
    .pipe(
      map((filters) => {
        // Wipe out any filter that doesn't exist anymore
        return filters.filter((filter) =>
          Object.values(CSFilterKeys).includes(filter.key)
        );
      }),
      tap((filters) => this.updateCookie(this.filterCookieName, filters)),
      shareReplay(1)
    );
  private _currentColumns: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >(this.initCookie(this.columnCookieName, DefaultColumns));
  currentColumns$: Observable<string[]> = this._currentColumns
    .asObservable()
    .pipe(
      map((columns) => (columns.length ? columns : DefaultColumns)),
      map((columns) =>
        columns.map((c) => (reverseMappingColumns[c] as string) || c)
      ), // Use new name
      map((columns) => columns.filter((c) => CsListColumns.includes(c))), // Filter to allowed columns
      tap((columns) => this.updateCookie(this.columnCookieName, columns)),
      shareReplay(1)
    );
  private _currentWidgets: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >(this.initCookie(this.widgetCookieName, DefaultWidgets));
  currentWidgets$: Observable<string[]> = this._currentWidgets
    .asObservable()
    .pipe(
      map((widgets) =>
        widgets.length
          ? widgets.filter((widget) => AvailableWidgets.includes(widget))
          : AvailableWidgets
      ),
      tap((widgets) => {
        this.updateCookie(this.widgetCookieName, widgets);
      }),
      shareReplay(1)
    );
  private _loggedNetworkChanges: BehaviorSubject<LoggedSiteNetworkChange[]> =
    new BehaviorSubject<LoggedSiteNetworkChange[]>(
      this.initCookie(this.networkChangeCookieName, [])
    );
  loggedNetworkChanges$: Observable<LoggedSiteNetworkChange[]> =
    this._loggedNetworkChanges.asObservable().pipe(
      tap((changes) =>
        this.updateCookie(this.networkChangeCookieName, changes)
      ),
      shareReplay(1)
    );

  private _currentMachineWidgets: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>(
      this.initCookie(this.machineWidgetCookieName, DefaultMachineWidgets)
    );
  currentMachineWidgets$: Observable<string[]> = this._currentMachineWidgets
    .asObservable()
    .pipe(
      map((widgets) => {
        // Wipe out any widget that doesn't exist anymore
        return widgets.filter((widget) =>
          AvailableMachineWidgets.includes(widget)
        );
      }),
      tap((changes) =>
        this.updateCookie(this.machineWidgetCookieName, changes)
      ),
      shareReplay(1)
    );

  constructor(
    protected http: HttpClient,
    protected widgetService: WidgetService,
    protected alertService: ConfirmationAlertService,
    protected userService: CurrentUserService,
    protected bingMaps: BingMapsService,
    protected excelService: ExcelService,
    protected localization: LocalizationService,
    @Inject(PLATFORM_ID) protected platformId: string
  ) {}

  /**
   * Return cached landmarks.
   */
  get getCachedLandmarks$(): Observable<JlgLocation[]> {
    if (!this.cachedLandmarks$) {
      const timer$ = timer(0, LANDMARK_CACHE_TIME); // every 10 min

      this.cachedLandmarks$ = timer$.pipe(
        switchMap((_) => this.getJlgLocations()),
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cachedLandmarks$;
  }

  /**
   * Clearsky legend reference (makes request if it wasn't already made)
   */
  get legendRef$(): Observable<CSLegend> {
    const getDataOnce = () => {
      return this.requestLegend().pipe(take(1));
    };

    const request$ = getDataOnce();
    const reload$ = this.forceReload$.pipe(switchMap(() => getDataOnce()));

    return merge(request$, reload$);
  }

  /**
   * Get clearsky legend.
   */
  requestLegend(): Observable<CSLegend> {
    this.cachedLegend$ =
      this.cachedLegend$ ||
      this.http
        .get<CSLegend>(`${environment.apiUrl}/currentUser/getLegend`)
        .pipe(takeUntil(this.reloadMachines$), shareReplay(CACHE_SIZE));

    return this.cachedLegend$;
  }

  /**
   * Get filter selection.
   * @param key
   * @protected
   */
  getCurrentFilter(key: string): Observable<MachineFilterSelection> {
    return this.currentFilters$.pipe(
      map((filters) => filters.find((f) => f.key === key) || null)
    );
  }

  /**
   * Get current filter set values.
   * @param key
   */
  getCurrentFilterValues(key: string): Observable<unknown[]> {
    return this.getCurrentFilter(key).pipe(
      map((filter) => {
        return filter ? (filter.values as unknown[]) : [];
      })
    );
  }

  /**
   * Get JLG locations for landmarks.
   */
  getJlgLocations(): Observable<JlgLocation[]> {
    return this.http
      .get<{ locations: JlgLocation[] }>(
        `${environment.apiUrl}/currentUser/getJLGLocations`
      )
      .pipe(
        map((res) => (res ? res.locations : [])),
        catchError(() => [])
      );
  }

  /**
   * Force retrieval of machines and clear cached items.
   */
  forceMachinesReload(): void {
    // Clear cache
    this._keyRequests = [];
    this.cachedLegend$ = null;

    // Calling next will complete the current cache instance
    this.reloadMachines$.next();

    // Now force the reload with getCachedMachines subscribers
    this.forceReload$.next();
  }

  /**
   * Force machine detail reload from various components.
   */
  forceMachineReload(): void {
    this._forceMachineReload.next();
  }

  /**
   * Get available options by filter key.
   * @param key
   */
  getValuesByFilter(key: string): Observable<unknown[]> {
    // Get values from legend?
    if (CSFilter[key].disFromLeg) {
      return this.legendRef$.pipe(
        map((legend) => {
          // Return values based on legend
          return legend ? CSFilterValsByLegend(key, legend) : [];
        })
      );
    }

    // Get values from payload?
    if (CSFilter[key].disFromJson) {
      return this.getDataByWidgetKey(CsRequestKeys.filterVals).pipe(
        map((page) => {
          return page && page.machines
            ? page.machines.map((m) => m[CSFilter[key].jsonProp])
            : [];
        })
      );
    }

    // Otherwise return all other values
    return of(CSFilterVals(key));
  }

  /**
   * Update current selected filters.
   * @param key
   * @param selectedValues
   */
  updateFilter(key: string, selectedValues: unknown[]): void {
    if (!selectedValues.length) {
      // Just remove it since they don't want to add specific constraints
      return this.removeFilter(key);
    } else {
      const filters = cloneDeep(this._currentFilters.getValue());
      const i = filters.findIndex((f) => f.key === key);

      // New or update filter?
      if (i > -1) {
        filters[i].values = [...selectedValues];
      } else {
        filters.push({
          key,
          values: [...selectedValues],
        });
      }

      this.updateCurrentFilters([...filters]);
    }
  }

  /**
   * Update current filters.
   * @param filters
   */
  private updateCurrentFilters(filters: MachineFilterSelection[]): void {
    this._currentFilters.next(filters);
  }

  /**
   * Update multiple filters so current filters isn't updated multiple times.
   * @param filters
   */
  updateFilters(filters: MachineFilterSelection[]): void {
    const setFilters: MachineFilterSelection[] =
      this._currentFilters.getValue();

    filters.forEach((filter) => {
      if (!filter.values.length) {
        // Remove filter
        setFilters.filter((f) => f.key !== filter.key);
      } else {
        const i = setFilters.findIndex((f) => f.key === filter.key);

        // New or update filter?
        if (i > -1) {
          setFilters[i].values = [...filter.values];
        } else {
          setFilters.push(filter);
        }
      }
    });

    // Now emit
    this.updateCurrentFilters([...setFilters]);
  }

  /**
   * Update displayed columns so cookie gets cached.
   * @param values
   */
  updateColumns(values: string[]): void {
    this._currentColumns.next(values);
  }

  /**
   * Remove filter by key.
   * @param key
   */
  removeFilter(key: string): void {
    const filters = this._currentFilters
      .getValue()
      .filter((filter) => key !== filter.key);

    this._currentFilters.next([...filters]);
  }

  /**
   * Reset all filters.
   */
  resetFilters(): void {
    this._currentFilters.next([]);
  }

  /**
   * Update selected widgets.
   * @param widgets
   */
  updateWidgets(widgets: string[]): void {
    this._currentWidgets.next([...widgets]);
  }

  /**
   * Add widget to dashboard.
   * @param key
   */
  addWidget(key: string): void {
    this.addWidgets([key]);
  }

  /**
   * Add widgets to dashboard.
   * @param keys
   */
  addWidgets(keys: string[]): void {
    const widgets = this._currentWidgets.getValue();

    keys.forEach((key) => {
      if (widgets.includes(key)) {
        return;
      }
      widgets.push(key);
    });

    this._currentWidgets.next(widgets);
  }

  /**
   * Remove widget from dashboard.
   * @param key
   */
  removeWidget(key: string): void {
    const widgets = this._currentWidgets
      .getValue()
      .filter((widget) => widget !== key);

    this._currentWidgets.next(widgets);
  }

  /**
   * Update selected machine widgets. (in the machine/asset view)
   * @param keys
   * @param onlyNew
   */
  updateMachineWidgets(keys: string[], onlyNew = false): void {
    const widgets = this._currentMachineWidgets.getValue();
    const newWidgets = keys.reduce(
      (prev, key) => (prev.includes(key) ? prev : prev.concat(key)),
      [...widgets]
    );
    const added = newWidgets.filter((key) => !widgets.includes(key));

    this._currentMachineWidgets.next([...(onlyNew ? newWidgets : keys)]);

    // Does the newly added widget depend on backend data?
    if (added.length) {
      if (Object.keys(MachineWidgetsKey).some((i) => added.includes(i))) {
        this.forceMachineReload();
      }
    }
  }

  /**
   * Add machine widget to asset view.
   * @param key
   */
  addMachineWidget(key: string): void {
    this.addMachineWidgets([key]);
  }

  /**
   * Add machine widgets to asset view.
   * @param keys
   */
  addMachineWidgets(keys: string[]): void {
    this.updateMachineWidgets(keys, true);
  }

  /**
   * Remove machine widget from asset view.
   * @param key
   */
  removeMachineWidget(key: string): void {
    const widgets = this._currentMachineWidgets
      .getValue()
      .filter((widget) => widget !== key);

    this._currentMachineWidgets.next(widgets);
  }

  /**
   * Reset to default machine widgets.
   */
  resetMachineWidgets(): void {
    this._currentMachineWidgets.next(DefaultMachineWidgets);
  }

  /**
   * Reset to default widgets.
   */
  resetWidgets(): void {
    this._currentWidgets.next(DefaultWidgets);
  }

  /**
   * Reset to default columns.
   */
  resetColumns(): void {
    this._currentColumns.next(DefaultColumns);
  }

  /**
   * Activate a specific dashboard instance.
   * @param dashboard
   */
  activateDashboard(dashboard: CsDashboard): void {
    // For legacy dashboards
    if (!dashboard.data) {
      this._currentWidgets.next([]);
      this._currentFilters.next([]);
      this._currentColumns.next([]);
      return;
    }

    this._currentWidgets.next(
      dashboard.data.widgets ? [...dashboard.data.widgets] : []
    );
    this._currentFilters.next(
      dashboard.data.filters ? this.convertDashboardFilters(dashboard.data) : []
    );
    this._currentColumns.next(
      dashboard.data.columns ? [...dashboard.data.columns] : []
    );
  }

  /**
   * Convert filters coming from the dashboard into the correct format.
   * @param data
   */
  convertDashboardFilters(data: CsDashboardData): MachineFilterSelection[] {
    if (!(data && data.filters)) {
      return [];
    } // Integrated for legacy dashboards
    return data.filters.map((filter) => {
      const values =
        filter.values instanceof Array ? filter.values.sort() : filter.values;

      return {
        key: filter.fieldName,
        values,
      } as MachineFilterSelection;
    });
  }

  /**
   * Download list view as xlsx
   * @param machines
   * @param columns
   * @param dashboard
   */
  downloadXLSX(
    machines: Machine[],
    columns: string[],
    dashboard?: CsDashboard
  ): Observable<void> {
    if (isPlatformBrowser(this.platformId)) {
      return combineLatest([
        this.localization.getOCIDs([
          ...columns.reduce((prev, column) => {
            return prev.concat(MachineFieldDisplayValueOcids[column] || []);
          }, []),
          ...Object.values(MachineFieldDisplay),
        ]),
        this.legendRef$,
      ]).pipe(
        map(([ocids, legend]) => {
          // Prep the data with columns being the first row
          const headers: string[][] = [
            [...columns].map(
              (c) =>
                ocids[MachineFieldDisplay[c]] ||
                getFieldDisplay(c, CSFieldType.MACHINE)
            ),
          ];

          // Now append each machine as a row based on filtered columns
          const rows: string[][] = machines.map((machine) => {
            return columns.map((c) => {
              machine[c] = convertToLegendVal(machine[c], legend, c) as never;
              return getMachineFieldOcidTranslation(
                c,
                machine[c] as never,
                ocids,
                machine
              ) as string;
            });
          });

          XLSX.fromBlankAsync().then((workbook) => {
            // Modify the workbook.
            const sheet = workbook
              .sheet(0)
              .name(dashboard ? dashboard.name : 'Assets');
            sheet.row(1).cell(1).value(headers);
            if (rows?.length) {
              sheet.row(2).cell(1).value(rows);
            }

            // Write to file.
            workbook.outputAsync('blob').then((blob) => {
              saveAs(blob, `${dashboard ? dashboard.name : 'Assets'}.xlsx`);
            });
          });
        })
      );
    }
  }

  /**
   * Remove site network change from log.
   * @param serialNumber
   */
  removeSiteNetworkChange(
    serialNumber: string
  ): Observable<LoggedSiteNetworkChange[]> {
    const changes = this._loggedNetworkChanges.getValue();
    const foundChangeIndex = changes.findIndex(
      (change) => change.sn === serialNumber
    );

    // Does a change for this serial number exist?
    if (foundChangeIndex !== -1) {
      changes.splice(foundChangeIndex, 1);
      this._loggedNetworkChanges.next(changes);
    }

    return this.loggedNetworkChanges$;
  }

  /**
   * Log site network change.
   * @param serialNumber
   * @param networkId
   * @param addTo
   */
  logSiteNetworkChange(
    serialNumber: string,
    networkId: string,
    addTo = true
  ): Observable<LoggedSiteNetworkChange[]> {
    // Remove log if it exists first
    this.removeSiteNetworkChange(serialNumber);

    // Now get current changes and push this change to it
    const changes = this._loggedNetworkChanges.getValue();
    changes.push({
      snID: networkId,
      sn: serialNumber,
      addToNetwork: addTo,
    });

    this._loggedNetworkChanges.next(changes);
    return this.loggedNetworkChanges$;
  }

  /**
   * Update site network.
   */
  updateSiteNetwork(
    network: SiteNetwork,
    data: SiteNetworkUpdate
  ): Observable<SiteNetwork> {
    return this.http
      .post<{ renameStatus: boolean }>(
        `${environment.apiUrl}/currentUser/renameMachineSiteNetwork`,
        {
          siteName: data.siteNetworkName,
          siteNetworkId: network.siteNetworkID,
        }
      )
      .pipe(
        map((res) => {
          return res.renameStatus
            ? {
                ...network,
                ...data,
              }
            : network;
        })
      );
  }

  /**
   * Save site network changes.
   */
  saveSiteNetworkChanges(): Observable<number> {
    const changes = this._loggedNetworkChanges.getValue();

    const batch = changes.map((change) => {
      return this.http
        .post<{ connected: boolean }>(
          `${environment.apiUrl}/currentUser/updateMachineSiteNetwork`,
          change
        )
        .pipe(
          map((res) => res.connected),
          catchError(() => of(null))
        );
    });

    return forkJoin(batch).pipe(
      map((res) => {
        // Reset changes
        this._loggedNetworkChanges.next([]);
        // Force machine reload to redraw networks

        this.forceMachinesReload();

        // Return how many machines were successfully moved
        return res.reduce((prev, curr) => (prev += curr ? 1 : 0), 0);
      })
    );
  }

  /**
   * Retrieves the current weather status for the machine's last known location.
   * @param machine
   * @param unit
   */
  getLocalWeather(
    machine: Machine,
    unit: UnitType
  ): Observable<BasicWeather | null> {
    if (!machine.loc) {
      return of(null);
    }
    const currentConditionsUrl =
      'https://atlas.azure.us/weather/currentConditions/json';
    return this.http
      .get<unknown>(currentConditionsUrl, {
        params: {
          'api-version': '1.0',
          'subscription-key': environment.azureMapsKey,
          query: [machine.loc?.lat, machine.loc?.lng].join(),
          unit,
        },
      })
      .pipe(
        map((weather) => {
          const res = weather['results'].shift();

          if (!res) {
            return null;
          }

          return <BasicWeather>{
            iconCode: res.iconCode,
            temperature: res.temperature.value,
            feelLike: res.realFeelTemperature.value,
            humidity: res.relativeHumidity,
            windSpeed: res.wind.speed.value,
          };
        }),
        catchError(() => {
          return of(null);
        })
      );
  }

  /**
   * Check if property on machine is pending an update.
   * @param mx
   * @param prop
   */
  isPropUpdPend(mx: Machine, prop: string): Observable<boolean> {
    return this._pendMxUpds.pipe(
      map((upds) => {
        const mxUpd = upds.find((updmx) => updmx.sn === mx.sn);

        if (mxUpd) {
          const propUpd = mxUpd.upds.find((upd) => upd.prop === prop);
          return propUpd ? propUpd.val !== mx[prop] : false;
        }

        return false;
      })
    );
  }

  /**
   * Update machine's properties.
   * @param serialNumber
   * @param properties
   */
  updateMachine(
    serialNumber: string,
    properties: { [key: string]: unknown }
  ): Observable<void | never> {
    const obs = Object.keys(properties).map((property) => {
      return this.http
        .post<void>(`${environment.apiUrl}/currentUser/updateMachineAsset`, {
          serialNumber,
          propertyName: property,
          newValue: properties[property],
        })
        .pipe(
          mergeMap(() => {
            return this.pendMxUpds$.pipe(first());
          }),
          map((pendUpds) => {
            // Store updates
            const newUpds = [...pendUpds];
            const mxUpdIndex = newUpds.findIndex(
              (mx) => mx.sn === serialNumber
            );
            if (mxUpdIndex === -1) {
              newUpds.push({
                sn: serialNumber,
                upds: [{ prop: property, val: properties[property] }],
              });
            } else {
              // Check if prop already exists
              const propIndex = newUpds[mxUpdIndex].upds.findIndex(
                (up) => up.prop === property
              );

              propIndex === -1
                ? newUpds[mxUpdIndex].upds.push({
                    prop: property,
                    val: properties[property],
                  })
                : (newUpds[mxUpdIndex].upds[propIndex].val =
                    properties[property]);
            }
            this._pendMxUpds.next(newUpds);

            return {
              property,
              success: true,
            };
          }),
          catchError(() => {
            return of({
              property,
              success: false,
            });
          })
        );
    });

    // Now join all endpoints since we don't have an endpoint to update multiple properties
    return forkJoin(obs).pipe(
      first(),
      mergeMap((res) => {
        // Could some properties not be updated?
        const fails = res.reduce(
          (prev, r) => (!r.success ? prev.concat(r.property) : prev),
          []
        );

        if (fails.length) {
          return throwError(
            () =>
              new Error(
                `The following properties could not be updated: ${fails.join(
                  ','
                )}`
              )
          );
        }

        return of(void 0);
      }),
      tap(() => {
        if (!properties['eid']) {
          this.forceMachinesReload();
          this.forceMachineReload();
        }
      })
    );
  }

  /**
   * Get payload data by widget key name.
   * @param key
   * @param body
   * @param shouldMerge
   */
  getDataByWidgetKey(
    key: string,
    body: CsMxRequestBody = {},
    shouldMerge = true
  ): Observable<CsMachineRequest | undefined> {
    let fetchFilters: MachineFilterSelection[] = [];

    return this.currentFilters$.pipe(
      switchMap((filters) => {
        fetchFilters = body.filters
          ? shouldMerge
            ? [...filters, ...body.filters]
            : body.filters
          : filters;

        // Don't cache paginated requests (maybe in the future if requests show to be slow, but they shouldn't since they are chunked)
        if (!body.paginate) {
          // Check filters and key and see if there is already a match in the storage
          const item = this._keyRequests.find(
            (r) => r.key === key && filtersAreEqual(r.filters, fetchFilters)
          );
          if (item) {
            return item.pending.pipe(
              filter((pending) => !pending),
              map(() => {
                return item.data;
              })
            );
          }

          // It's not so mark it as pending
          this._keyRequests.push({
            key,
            filters: fetchFilters,
            pending: new BehaviorSubject<boolean>(true),
          });
        }

        // Form the final body of the request with everything passed by the user and overwrite filters to QAPI format
        const finalBody = {
          ...body,
          filters: transformToQAPIFilters(fetchFilters),
        };

        const getDataOnce = () => {
          return this.http
            .post<CsMachineRequest>(
              `${environment.apiUrl}/currentUser/v2/searchMachinesByCustomerNumbers`,
              {
                columns: CsRequestKeysToColumns[key] || [],
                expect: CsRequestKeysExpect[CsRequestExpect.AGG_DATA].includes(
                  key
                )
                  ? CsRequestExpect.AGG_DATA
                  : CsRequestExpect.MX_DATA,
                ...finalBody,
              }
            )
            .pipe(
              catchError(() => {
                return of({
                  machines: [],
                });
              }),
              mergeMap((res) => {
                // Do we need to request machine images?
                // return of(res);
                if (
                  CsReqKeyImgReq.includes(key) &&
                  res.machines &&
                  res.machines.length
                ) {
                  return this.http
                    .post<{ images: MachineModelNumberImages[] }>(
                      `${environment.apiUrl}/currentUser/machineImages`,
                      {
                        // Remove falsey values
                        modelNumbers: res.machines
                          .map((m) => m.model)
                          .filter((item) => item),
                      }
                    )
                    .pipe(
                      map((r) => {
                        const imageMapping = r.images.reduce(
                          (prev, modelImage) => {
                            prev[modelImage.modelNumber] = modelImage.imageUrl;
                            return prev;
                          },
                          {} as { [model: string]: string }
                        );

                        res.machines = (res.machines ?? []).map((m) => ({
                          ...m,
                          modelImage:
                            imageMapping[m.model] === '404'
                              ? MxMGroupImg[m.mgroup]
                              : imageMapping[m.model],
                        }));

                        return res;
                      }),
                      catchError(() => {
                        return of(res);
                      })
                    );
                }

                return of(res);
              }),
              take(1),
              tap((res) => {
                const found = this._keyRequests.find(
                  (r) =>
                    r.key === key && filtersAreEqual(r.filters, fetchFilters)
                );

                if (found) {
                  found.data = res;
                  found.pending.next(false);
                }
              })
            );
        };

        const request$ = getDataOnce();
        const reload$ = this.forceReload$.pipe(switchMap(() => getDataOnce()));
        return merge(request$, reload$);
      })
    );
  }

  /**
   * Initialize selected filters on load.
   * @private
   */
  private initCookie(key: string, defaultValue: unknown): any {
    // Check local storage
    if (isPlatformBrowser(this.platformId)) {
      const cookie = localStorage.getItem(key);

      if (cookie) {
        return JSON.parse(cookie);
      }
    }

    return defaultValue;
  }

  /**
   * Update filter selection cookie.
   * @param key
   * @param payload
   * @private
   */
  private updateCookie(key: string, payload: any): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(payload));
    }
  }
}
