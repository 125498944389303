import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateComponent } from '../endeca/template/template.component';
import { EndecaService } from '../endeca/endeca.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dynamic-route-component',
  templateUrl: './dynamic-route.component.html',
})
export class DynamicRouteComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true }) container;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private endecaService: EndecaService
  ) {}

  ngOnInit() {
    // Gather dynamic routing information
    this.route.data.subscribe((data: { response }) => {
      // data.response is an array holding two objects. [0] - IUser and [1] - IEndecaPayloadResponse.
      // Manually resolve app template and inject data to re-render from previous endeca-driven pages
      this.endecaService.injectTemplateType(
        this.container,
        TemplateComponent,
        data.response[1].payload,
        data.response[1].url
      );
    });
  }
}
