export interface IOrderSpecialCharges {
  amount: string;
  chargeId?: string;
  chargeIdDesc?: string;
  cost?: string;
  itemReference: string;
  sequence?: string;
  taxAmount?: number;
  taxRate?: number;
}

export const ChargeIds = {
  CORS: 'COR',
  CoreCharge: 'Core Charge',
};
