import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ClearskyService } from '../../../clearsky.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  CSFilter,
  CSFilterData,
  CSFilterLabel,
  CSFilterLabelOcids,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { Observable, map } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-new-filter',
  templateUrl: './new-filter.component.html',
  styleUrls: ['./new-filter.component.scss'],
})
export class NewFilterComponent implements OnInit {
  @ViewChild('filterMenuTrigger') set filterMenuTrigger(
    element: MatMenuTrigger
  ) {
    if (this.openMenu && element) {
      element.openMenu();
      this.cdRef.detectChanges();
    }
  }
  @Input() openMenu: boolean;
  availableFilters: CSFilterData[] = [];
  availableFilterLabels = CSFilterLabel;
  ocids: OcidItems = {};
  filterKey: string | undefined; // Current edited filter

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private cdRef: ChangeDetectorRef
  ) { }

  get filteredFilters$(): Observable<{ key: string }[]> {
    return this.clearskyService.legendRef$.pipe(
      map(legend =>
        this.availableFilters.filter(filter =>
          filter.key !== CSFilter.cn.key || (legend?.cn?.length > 0)
        )
      )
    );
  }
  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs(CSFilterLabelOcids)
      .subscribe((ocids) => (this.ocids = ocids));

    this.availableFilters = Object.values(CSFilter).filter(
      (filter) => !filter.isNonEdit
    );
  }

  /**
   * Open filter menu and set available options.
   * @param filter
   */
  openFilterMenu(filter: CSFilterData): void {
    this.filterKey = filter.key;
  }
}
