import { Component, Input } from '@angular/core';
import { NotificationService } from '../../../service/notification/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent {
  @Input('asAlert') displayAsAlert = true;
  @Input() asError = true;
  @Input() displayAsLink = false;
  @Input() isLink = false;
  @Input() linkType = 'email';
  @Input() internal = true;
  @Input() linkValue = '';
  notifications$: Observable<string[]> = this.notificationService.errors$;

  constructor(private notificationService: NotificationService) {}

  resetNotifications(): void {
    this.notificationService.reset();
  }
}
