import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appStripApostrophe]'
})
export class StripApostropheDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onEvent($event) {
    this.control.control.setValue(this.el.nativeElement.value.replace(/'/g, ''));
  }

}
