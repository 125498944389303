import {Pipe, PipeTransform} from '@angular/core';

/**
 * Shade RGB darker or lighter by a float number.
 * @param color
 * @param percent
 */
export const shadeRGBColor = (color: string, percent: number): string => {
  const f = color.split(','),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = parseInt(f[0].slice(4)),
    G = parseInt(f[1]),
    B = parseInt(f[2]);

  return `rgb(${Math.round((t - R) * p) + R},${Math.round((t - G) * p) + G},${
    Math.round((t - B) * p) + B
  })`;
};

/**
 * Convert a hex string to an rgb object.
 * @param hex
 */
export const hexToRgb = (
  hex: string
): {r: number; g: number; b: number} | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * Convert a hex string to an rgb string.
 * @param hex
 */
export const hexToRgbString = (hex: string): string | null => {
  const rgb = hexToRgb(hex);

  return rgb ? `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` : null;
};

@Pipe({name: 'shadeRgb'})
export class ShadeRgbPipe implements PipeTransform {
  transform(value: string, percent: number): string {
    return shadeRGBColor(value, percent);
  }
}
