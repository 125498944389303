import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { KnowledgeArticleService } from '../../../service/knowledge-article.service';
import { ContentService } from '../../../service/content.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { filter, take } from 'rxjs/operators';
import { OcidItems } from '../../../contracts/ocid-items';

interface ArticleMenu {
  label: string;
  anchor: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-article-detail',
  styleUrls: [
    './article-detail.component.scss',
    '../article-result-list/article-result-list-item/article-result-list-item.component.scss',
  ],
  templateUrl: './article-detail.component.html',
})
export class ArticleDetailComponent implements OnInit, OnDestroy {
  @Input() data!: {
    articleId: string;
    articleInfo: {
      sfKnowledgeArticleId: string;
      articleType: string[];
      beginningSN: string;
      endingSN: string;
      machineFaultCode: string;
      engineFaultCode: string;
      models: string[];
      notes: string;
      possibleCauses: string;
      probDesc: string;
      productType: string;
      section: string;
      symptoms: string;
      title: string;
      tools: string[];
      troubleshootGuide: string;
      usefulDocs: string;
    };
  };
  @ViewChild('comments') private comments!: ElementRef;
  @ViewChild('survey', { read: ElementRef }) survey!: ElementRef;
  ocids: OcidItems = {};
  formattedTroubleshootGuide!: string;
  formattedPossibleCauses!: string;
  source!: string;
  feedbackSelected = '';
  feedbackHeader!: string;
  feedbackLabel!: string;
  feedbackSubmitted = true;
  showThankYouMsg = false;
  feedback = {
    yes: 'Yes',
    no: 'No',
  };
  articleTypes = {
    machine: 'Machine',
    engine: 'Engine',
  };
  articleMenu: ArticleMenu[] = [];
  dataLayer = (this.winRef.nativeWindow.dataLayer =
    this.winRef.nativeWindow.dataLayer || []);
  platformBrowser = false;
  locationLink = location.href;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private viewportScroller: ViewportScroller,
    private articleService: KnowledgeArticleService,
    private contentService: ContentService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'ka.troubleshooting-steps',
        'ka.suggested-tools',
        'ka.related-documents',
        'ka.yes-feedback',
        'ka.feedback-comment-label',
        'ka.how-improve-label',
        'ka.related-models-label',
        'ka.feedback-no-message',
        'control-software.serial-num-range',
        'ka.thank-you-message',
        'ka.helpful-message',
        'ka.possible-cause',
        'ka.article-helpful-text',
        'global.yes',
        'global.no',
      ])
      .subscribe(() => {
        // Setup floating menu
        this.data.articleInfo.symptoms &&
          this.articleMenu.push({
            label: this.ocids['global.symptoms'],
            anchor: 'symptoms',
          });
        this.data.articleInfo.possibleCauses &&
          this.articleMenu.push({
            label: this.ocids['ka.possible-cause'],
            anchor: 'causes',
          });
        this.data.articleInfo.troubleshootGuide &&
          this.articleMenu.push({
            label: this.ocids['ka.troubleshooting-steps'],
            anchor: 'troubleshooting',
          });
        this.data.articleInfo.tools &&
          this.articleMenu.push({
            label: this.ocids['ka.suggested-tools'],
            anchor: 'tools',
          });
        this.data.articleInfo.usefulDocs &&
          this.articleMenu.push({
            label: this.ocids['ka.related-documents'],
            anchor: 'docs',
          });
        if (this.platformBrowser) {
          // Hide feedback form if it's already been submitted
          this.feedbackSubmitted = this.contentService.getCookie(
            'hideFeedback_' + this.data.articleInfo.sfKnowledgeArticleId
          );
        }

        if (!this.feedbackSubmitted) {
          this.articleMenu.push({
            label: this.ocids['ka.article-helpful-text'],
            anchor: 'survey',
          });
        }
        if (
          !this.feedbackSubmitted &&
          this.data.articleInfo.sfKnowledgeArticleId
        ) {
          this.contentService
            .checkIntersection(this.survey)
            .pipe(
              filter((isVisible: boolean) => isVisible),
              take(1)
            )
            .subscribe((isVisible) => {
              this.survey?.nativeElement.classList.add('_slideIn');
            });
        } else {
          this.survey?.nativeElement.classList.add('_hide');
        }
      });

    this.formattedPossibleCauses = this.data.articleInfo.possibleCauses
      ? this.data.articleInfo.possibleCauses.replace(/\n/g, '<br>')
      : '';
    this.formattedTroubleshootGuide = this.data.articleInfo.troubleshootGuide
      ? this.data.articleInfo.troubleshootGuide.replace(/\n/g, '<br>')
      : '';
  }

  selectFeedback(isHelpful: boolean): void {
    this.feedbackSelected = isHelpful ? this.feedback.yes : this.feedback.no;
    this.feedbackHeader = isHelpful
      ? this.ocids['ka.yes-feedback']
      : this.ocids['ka.feedback-no-message'];
    this.feedbackLabel = isHelpful
      ? this.ocids['ka.feedback-comment-label']
      : this.ocids['ka.how-improve-label'];

    // Send event to GA
    this.dataLayer.push({
      event: 'articleFeedback',
      feedbackAction: 'Feedback Selected',
      feedbackSelected: this.feedbackSelected,
      articleTitle: this.data.articleInfo.title,
    });
  }

  submitFeedback() {
    this.articleService
      .submitArticleFeedback({
        id: this.data.articleInfo.sfKnowledgeArticleId,
        helpful: this.feedbackSelected,
        comments: this.comments.nativeElement.value,
      })
      .subscribe({
        next: () => {
          // Create a cookie so we don't show the feedback form again
          const d = new Date();
          d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
          this.contentService.createCookie(
            'hideFeedback_' + this.data.articleInfo.sfKnowledgeArticleId,
            'true',
            d.toUTCString()
          );
          this.feedbackSubmitted = true;
          this.showThankYouMsg = true;
          // Send event to GA
          this.dataLayer.push({
            event: 'articleFeedback',
            feedbackAction: 'Feedback Submitted',
            feedbackSelected: this.feedbackSelected,
            articleTitle: this.data.articleInfo.title,
          });
        },
        error: () => {},
      });
  }

  scrollTo(elementId: string): void {
    // Setting x cordinate to 800 otherwise the layout shifts on scrolling to anchor
    this.viewportScroller.setOffset([800, 70]);
    this.viewportScroller.scrollToAnchor(elementId);
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    if (!this.feedbackSubmitted) {
      this.dataLayer.push({
        event: 'articleFeedback',
        feedbackAction: 'Abandoned',
        articleTitle: this.data.articleInfo.title,
      });
    }
  }
}
