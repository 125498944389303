import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-format-url-link',
  templateUrl: './format-url-link.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'formatted-link',
  },
})
export class FormatUrlLinkComponent implements OnInit {
  @Input('url') apiUrl: string | undefined;
  @Input('text') linkText: string | undefined;
  @Input() count: number | undefined;
  @Input() category: string | undefined;
  validUrl: {
    url: string;
    query: object;
  } = {
    url: '',
    query: {},
  };

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const queryString = require('query-string');
    // https://www.npmjs.com/package/query-string

    // Format url into a valid url for routerLink
    const validUrl = queryString.parseUrl(this.apiUrl);
    this.validUrl.url = validUrl.url;
    this.validUrl.query = Object.keys(validUrl.query).length
      ? validUrl.query
      : null;
  }
}
