import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-category-navigation',
  styleUrls: ['./category-navigation.component.scss'],
  templateUrl: './category-navigation.component.html',
})
export class CategoryNavigationComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    numSubCategories: number;
    showViewAllCategoriesLink: boolean;
    categoryViewAllLinkText: string;
    categoryNavigationHeading: string;
    title: string;
    displayImage: boolean;
    numCategories: number;
    viewAllCategoriesLinkText: string;
    showCategoryViewAllLink: boolean;
    categories: object[];
  };
  showAllCats!: boolean;

  constructor() {}

  ngOnInit() {
    this.showAllCats = this.data.showViewAllCategoriesLink;
  }
}
