import { Component, Input } from '@angular/core';
import {
  IOrderDetail,
  ShippingMethod,
} from '../../../../../../contracts/orders/iorder-detail';
import { IOrderDetailShipments } from '../../../../../../contracts/orders/iorder-detail-shipments';
import { IOrderDetailShipmentDetails } from '../../../../../../contracts/orders/iorder-detail-shipment-details';
import { IUser } from '../../../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-order-print',
  styleUrls: ['./order-print.component.scss'],
  templateUrl: './order-print.component.html',
})
export class OrderPrintComponent {
  @Input() order!: IOrderDetail;
  @Input() user!: IUser;
  @Input() customerPickup = false;
  @Input() ocids!: OcidItems;
  @Input() isCoreReturn: boolean;
  shippingMethod = ShippingMethod;

  /**
   * Filters for the quantity shipped given an item number.
   *
   * @param {string} itemNumber
   */
  getShippedQuantity(itemNumber: string) {
    let totalShipped = 0;
    this.order.shipments.forEach((shipment: IOrderDetailShipments) => {
      shipment.details
        .filter((detail: IOrderDetailShipmentDetails) => {
          return detail.itemNumber === itemNumber;
        })
        .forEach((detail: IOrderDetailShipmentDetails) => {
          totalShipped += +detail.quantity;
        });
    });
    return totalShipped;
  }
}
