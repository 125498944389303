import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-article-result-list-item]',
  styleUrls: ['article-result-list-item.component.scss'],
  templateUrl: './article-result-list-item.component.html',
})
export class ArticleResultListItemComponent implements OnInit {
  @Input('dateFormat') dateFormat: string;
  @Input('article') item: {
    records: object[];
    numRecords: number;
    detailsAction: {
      contentPath: string;
      recordState: string;
    };
    attributes: {
      'knowledgeArticle.articleType': string[];
      'knowledgeArticle.machineFaultCode': string[];
      'knowledgeArticle.engineFaultCode': string[];
      'knowledgeArticle.probDesc': string[];
      'knowledgeArticle.productType': string[];
      'knowledgeArticle.section': string[];
      'knowledgeArticle.symptoms': string[];
      'knowledgeArticle.title': string[];
      'knowledgeArticle.beginningSN': string[];
      'knowledgeArticle.endingSN': string[];
      'record.id': string[];
      'knowledgeArticle.lastModifiedDate': string[],
      productMedia: {
        imageAltText: string;
        thumbnailImageUrl: string;
        fullImageUrl: string;
      }[];
    };
  };
  ocids = {};
  articleTypes = {
    machine: 'Machine',
    engine: 'Engine',
  };
  dateValue = '';
  baseUrl = location.origin;
  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    // Remove slashes from date so we can format it using our standard format date pipe
    this.dateValue = this.item.attributes['knowledgeArticle.lastModifiedDate']?.[0]?.replace(/\//g,'');
  }

  get seoUrl() {
    // tbd
    return (
      this.item.detailsAction.contentPath + this.item.detailsAction.recordState
    );
  }
}
