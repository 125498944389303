// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.clipboard-btn mat-icon {
  font-size: 15px;
  margin-top: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hY2hpbmUtZGV0YWlsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksZUFBQTtFQUNBLGVBQUE7QUFBUiIsImZpbGUiOiJtYWNoaW5lLWRldGFpbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImJ1dHRvbi5jbGlwYm9hcmQtYnRuIHtcclxuICAgIG1hdC1pY29uIHtcclxuICAgICAgICBmb250LXNpemU6IDE1cHg7XHJcbiAgICAgICAgbWFyZ2luLXRvcDogNXB4O1xyXG4gICAgfVxyXG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/machine-detail/machine-detail.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,eAAA;AAAR;AACA,4aAA4a","sourcesContent":["button.clipboard-btn {\r\n    mat-icon {\r\n        font-size: 15px;\r\n        margin-top: 5px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
