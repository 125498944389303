import { Component, Input, OnInit } from '@angular/core';
import {
  ClearskyCart,
  ClearskySubscriptionPlan,
} from '../../../../../../contracts/clearsky/subscription/clearsky-subscription';

@Component({
  selector: 'app-subscription-addon-card-price-savings-badge',
  templateUrl: './subscription-addon-card-price-savings-badge.component.html',
})
export class SubscriptionAddonCardPriceSavingsBadgeComponent implements OnInit {
  @Input() plan!: ClearskySubscriptionPlan | ClearskyCart;
  savings = 0;

  ngOnInit(): void {
    this.savings =
      this.formatNumber((100 * (this.plan.listPrice - this.plan.salePrice)) / this.plan.listPrice);
  }

  formatNumber(number) {
    return number.toFixed(number % 1 !== 0 ? 2 : 0);
  }
}
