import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AuthFormComponent } from '../core/header/auth-container/auth-form/auth-form.component';
import { DynamicRouteComponent } from '../dynamic-route/dynamic-route.component';
import { CreditCardService } from '../endeca/cartridges/account/account-payment-preferences/credit-card.service';
import { ShoppingListsService } from '../endeca/cartridges/account/account-shopping-lists/shopping-lists.service';
import { ShippingAddressFormComponent } from '../endeca/cartridges/account/shipping-address-form/shipping-address-form.component';
import { SearchService } from '../endeca/cartridges/search/search.service';
import { ShoppingCartItemWarningComponent } from '../endeca/cartridges/shopping-cart/shopping-cart-item/shopping-cart-item-warning/shopping-cart-item-warning.component';
import { UserAddressesService } from '../service/user/user-addresses.service';
import { BVLoader } from '../service/product/bv-loader.service';
import { CanonicalService } from './canonical.service';
import { ErrorsModule } from './errors/errors.module';
import { FilterPipe } from './filter.pipe';
import { IsMachineLastReportedIn24HoursPipe } from './machine-reported-in-last-24h.pipe';
import { FormatCurrencyPipe } from './format-currency.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FormatUrlLinkComponent } from './format-url-link/format-url-link.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LocalizedDataPipe } from './localization/localized-data.pipe';
import { MaterialModule } from './material.module';
import { PasswordPipe } from './password.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ScriptsService } from './scripts.service';
import { YesNoPipe } from './yes-no.pipe';
import { StripApostropheDirective } from './strip-apostrophe.directive';
import { CollapseModule } from './collapse/collapse.module';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { MenuItemComponent } from './navigation/menu-item/menu-item.component';
import { CamelToWordsPipe } from './camel-to-words.pipe';
import { CartridgesComponent } from '../endeca/cartridges/cartridges.component';
import { ShadeRgbPipe } from './shade-rgb.pipe';
import { CastValuePipe } from './cast-value.pipe';
import { TruncatePipe } from './truncate.pipe';
import { PayPalLoader } from '../service/product/paypal-loader.service';
import { ParamRemovalPipe } from './param-removal.pipe';
import { ShippingTranslationPipe } from './shipping-translation.pipe';

@NgModule({
  imports: [
    CommonModule,
    ErrorsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LazyLoadImageModule,
    CollapseModule,
    MaterialModule,
    NgClickOutsideModule,
  ],
  declarations: [
    AuthFormComponent,
    CartridgesComponent,
    DynamicRouteComponent,
    FilterPipe,
    IsMachineLastReportedIn24HoursPipe,
    FormatCurrencyPipe,
    FormatDatePipe,
    FormatUrlLinkComponent,
    LoadingSpinnerComponent,
    LocalizedDataPipe,
    ParamRemovalPipe,
    PasswordPipe,
    SafeHtmlPipe,
    ShippingAddressFormComponent,
    ShoppingCartItemWarningComponent,
    YesNoPipe,
    StripApostropheDirective,
    MenuItemComponent,
    CamelToWordsPipe,
    ShadeRgbPipe,
    CastValuePipe,
    TruncatePipe,
    ShippingTranslationPipe
  ],
  exports: [
    AuthFormComponent,
    CartridgesComponent,
    DynamicRouteComponent,
    ErrorsModule,
    FilterPipe,
    IsMachineLastReportedIn24HoursPipe,
    FormatCurrencyPipe,
    FormatDatePipe,
    FormatUrlLinkComponent,
    FormsModule,
    LoadingSpinnerComponent,
    LocalizedDataPipe,
    ParamRemovalPipe,
    PasswordPipe,
    ReactiveFormsModule,
    RouterModule,
    SafeHtmlPipe,
    ShippingAddressFormComponent,
    ShoppingCartItemWarningComponent,
    YesNoPipe,
    StripApostropheDirective,
    CollapseModule,
    NgClickOutsideModule,
    MenuItemComponent,
    CamelToWordsPipe,
    ShadeRgbPipe,
    MaterialModule,
    LazyLoadImageModule,
    CastValuePipe,
    TruncatePipe,
    ShippingTranslationPipe
  ],
  providers: [
    BVLoader,
    CanonicalService,
    CreditCardService,
    DecimalPipe,
    PayPalLoader,
    SearchService,
    ShoppingListsService,
    UserAddressesService,
    ScriptsService,
  ],
})
export class SharedModule {}
