import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingPlanTableComponent } from './pricing-plan-table.component';
import { PricingPlanTableHeaderComponent } from './pricing-plan-table-header/pricing-plan-table-header.component';
import { PricingPlanTableHeaderItemComponent } from './pricing-plan-table-header/pricing-plan-table-header-item/pricing-plan-table-header-item.component';
import { PricingPlanTableFeaturesComponent } from './pricing-plan-table-features/pricing-plan-table-features.component';
import { PricingPlanTableFeaturesItemComponent } from './pricing-plan-table-features/pricing-plan-table-features-item/pricing-plan-table-features-item.component';
import { PricingPlanTableFeaturesItemHeaderComponent } from './pricing-plan-table-features/pricing-plan-table-features-item/pricing-plan-table-features-item-header/pricing-plan-table-features-item-header.component';
import { PricingPlanTableFeaturesItemIconComponent } from './pricing-plan-table-features/pricing-plan-table-features-item/pricing-plan-table-features-item-icon/pricing-plan-table-features-item-icon.component';
import { PricingPlanTableFooterComponent } from './pricing-plan-table-footer/pricing-plan-table-footer.component';
import { PricingPlanTableFooterItemComponent } from './pricing-plan-table-footer/pricing-plan-table-footer-item/pricing-plan-table-footer-item.component';

@NgModule({
  declarations: [
    PricingPlanTableComponent,
    PricingPlanTableHeaderComponent,
    PricingPlanTableHeaderItemComponent,
    PricingPlanTableFeaturesComponent,
    PricingPlanTableFeaturesItemComponent,
    PricingPlanTableFeaturesItemHeaderComponent,
    PricingPlanTableFeaturesItemIconComponent,
    PricingPlanTableFooterComponent,
    PricingPlanTableFooterItemComponent,
  ],
  exports: [
    PricingPlanTableComponent,
    PricingPlanTableHeaderComponent,
    PricingPlanTableHeaderItemComponent,
    PricingPlanTableFeaturesComponent,
    PricingPlanTableFeaturesItemComponent,
    PricingPlanTableFeaturesItemHeaderComponent,
    PricingPlanTableFeaturesItemIconComponent,
    PricingPlanTableFooterComponent,
    PricingPlanTableFooterItemComponent,
  ],
  imports: [CommonModule],
})
export class PricingPlanTableModule {}
