import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ICommerceItemWithCart } from '../../contracts/commerce/icommerce-item-with-cart';
import { isPlatformBrowser } from '@angular/common';
import { IOrder } from 'app/contracts/commerce/iorder';
import { IUser } from 'app/contracts/user/iuser';

@Injectable()
export class EcommerceService {
  private dataLayer;
  private gtag;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.dataLayer = (window as any).dataLayer || [];
      this.gtag = (window as any).gtag;
    }
  }

  /**
   * Track when and where a single item is added to the cart - GA & FB
   * @param itemNumber
   * @param itemName
   * @param qty
   * @param source
   */
  addToCart(
    itemNumber: string,
    categoryName: string,
    itemName: string,
    qty: number,
    source: string
  ): void {
    return this.pushLayer(() => {
      this.gtag('event', 'add_to_cart', {
        items: [
          {
            item_id: itemNumber,
            item_name: itemName,
            item_category: categoryName,
            item_list_name: source,
            quantity: qty,
          },
        ],
      });
      this.dataLayer.push({
        event: 'fb.addToCart',
        fb: {
          content_name: source,
          contents: [
            {
              id: itemNumber,
              quantity: qty,
            },
          ],
        },
      });
    });
  }

  /**
   * Track when and where multiple items are added to the cart - GA & FB
   * @param itemNumber
   * @param source
   */
  addAllToCart(items, source: string): void {
    return this.pushLayer(() => {
      this.gtag('event', 'add_to_cart', {
        items: items,
      });
      this.dataLayer.push({
        event: 'fb.addToCart',
        fb: {
          content_name: source,
          contents: items,
        },
      });
    });
  }

  /**
   * Track when and where a single item is added to the shopping list - FB & GA
   * @param itemNumber
   * @param qty
   * @param source
   */
  addToWishlist(itemNumber: string, qty: number, source: string): void {
    return this.pushLayer(() => {
      this.gtag('event', 'add_to_wishlist', {
        items: [
          {
            item_id: itemNumber,
            item_list_name: source,
            quantity: qty,
          },
        ],
      });
      this.dataLayer.push({
        event: 'fb.addToWishlist',
        fb: {
          content_name: source,
          contents: [
            {
              id: itemNumber,
              quantity: qty,
            },
          ],
        },
      });
    });
  }

  /**
   * Track when and where multiple items added to the shopping list - FB & GA
   * @param itemNumber
   * @param qty
   * @param source
   */
  addAllToWishlist(items, source: string): void {
    return this.pushLayer(() => {
      this.gtag('event', 'add_to_wishlist', {
        items: items,
      });
      this.dataLayer.push({
        event: 'fb.addToWishlist',
        fb: {
          content_name: source,
          contents: items,
        },
      });
    });
  }

  /**
   * Track when the user selects payment options in checkout - GA
   * @param value
   * @param currency
   * @param paymentType
   * @param items
   */
  addPaymentInfo(
    value: number,
    currency: string,
    paymentType: string,
    items
  ): void {
    return this.pushLayer(() => {
      this.gtag('event', 'add_payment_info', {
        value: value,
        currency: currency,
        payment_type: paymentType,
        items: items,
      });
    });
  }

  /**
   * Track when the user selects shipping options in checkout - GA
   * @param value
   * @param currency
   * @param shippingOpt
   * @param items
   */
  addShippingInfo(
    value: number,
    currency: string,
    shippingOpt: string,
    items
  ): void {
    return this.pushLayer(() => {
      this.gtag('event', 'add_shipping_info', {
        value: value,
        currency: currency,
        shipping_tier: shippingOpt,
        items: items,
      });
    });
  }

  /**
   * Track when the checkout process starts - GA
   * @param value
   * @param currency
   * @param items
   */
  checkout(value: number, currency: string, items): void {
    return this.pushLayer(() => {
      this.gtag('event', 'begin_checkout', {
        value: value,
        currency: currency,
        items: items,
      });
    });
  }

  /**
   * Track when a purchase is made - FB & GA
   * @param order
   * @param items
   * @param user
   */
  purchase(order: IOrder, items, user: IUser): void {
    return this.pushLayer(() => {
      this.gtag('event', 'purchase', {
        transaction_id: order.comOrderNumber,
        value: order.priceInfo.total,
        tax: order.priceInfo.tax,
        shipping: order.priceInfo.shipping,
        currency: order.priceInfo.currencyCode,
        items: items,
        account_type: user.accountType,
        customer_number: user.customerNumber,
      });
      this.dataLayer.push({
        event: 'fb.purchase',
        fb: {
          contents: items,
          currency: order.priceInfo.currencyCode,
          value: order.priceInfo.total,
        },
      });
      // all prices sent to BV must have 2 decimal places
      this.dataLayer.push({
        event: 'bvCheckout',
        bvData: {
          orderId: order.comOrderNumber,
          currency: order.priceInfo.currencyCode,
          total: order.priceInfo.total.toFixed(2), // subtotal without tax, shipping, discounts
          tax: order.priceInfo.tax.toFixed(2),
          shipping: order.priceInfo.shipping.toFixed(2),
          discount: order.priceInfo.discountAmount.toFixed(2), // discounts applied to total cart
          items: items,
          email: user.email,
          locale: user.locale,
          nickname: user.firstName, // used for email personalization
        },
      });
    });
  }

  /**
   * Track when a user signs up - FB & GA
   * @param accountType
   * @param customerNumber
   */
  register(accountType: string, customerNumber: string): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        event: 'sign_up',
        method: accountType,
        NCM: customerNumber,
      });
      this.dataLayer.push({
        event: 'fb.completeRegistration',
      });
    });
  }

  /**
   * Conversion tracking for removing a single item - GA.
   * @param item
   * @param source
   * @param qty
   * @param customerNum
   * @param inStock
   * @param shipDate
   */
  remove(
    item: ICommerceItemWithCart,
    source: string,
    qty: number,
    customerNum = '',
    companyName: string,
    inStock = '',
    shipDate = 'n/a'
  ): void {
    return this.pushLayer(() => {
      this.gtag('event', 'remove_from_cart', {
        currency: item.priceInfo.currencyCode,
        items: [
          {
            item_id: item.itemNumber,
            item_name: item.productDisplayName,
            item_list_name: source,
            price: item.priceInfo.listPrice,
            quantity: qty,
            company_name: companyName,
            consult_factory: item.consultFactoryAction,
            phantom_item: item.phantomItem,
            core_return_amount: item.coreReturnAmount
          },
        ],
        customer_number: customerNum,
        estimated_ship_date: shipDate,
        in_stock: inStock,
      });
    });
  }

  /**
   * Conversion tracking for removing multiple items - GA.
   * @param items
   * @param source
   */
  removeMultiple(items: ICommerceItemWithCart[], source: string, companyName: string): void {
    return this.pushLayer(() => {
      const itemsToRemove = items.map((item: ICommerceItemWithCart) => {
        return {
          item_id: item.itemNumber,
          item_name: item.productDisplayName,
          item_list_name: source,
          price: item.priceInfo.listPrice,
          quantity: item.quantity,
          company_name: companyName,
          consult_factory: item.consultFactoryAction,
          phantom_item: item.phantomItem,
          core_return_amount: item.coreReturnAmount
        };
      });
      this.gtag('event', 'remove_from_cart', {
        items: itemsToRemove,
      });
    });
  }

  /**
   * Track when and where a single cateogry is selected - GA
   * @param catType
   * @param catName
   */
  selectCategory(catType: string, catName: string): void {
    return this.pushLayer(() => {
      this.gtag('event', 'select_content', {
        content_type: catType,
        item_id: catName,
      });
    });
  }

  /**
   * Track when and where a single item is selected from a list - GA
   * @param itemNumber
   * @param itemName
   * @param source
   */
  selectItem(itemNumber: string, itemName: string, source: string): void {
    return this.pushLayer(() => {
      this.gtag('event', 'select_item', {
        item_list_name: source,
        items: [
          {
            item_id: itemNumber,
            item_name: itemName,
          },
        ],
      });
    });
  }

  /**
   * Track when the cart is viewed - GA
   * @param cart
   */
  viewCart(cart: IOrder): void {
    if (cart.commerceItems.items) {
      return this.pushLayer(() => {
        const cartItems = cart.commerceItems.items.map(
          (item: ICommerceItemWithCart) => {
            return {
              item_id: item.itemNumber,
              item_name: item.productDisplayName,
              price: item.priceInfo.listPrice,
              item_category: item.categoryName,
              quantity: item.quantity,
            };
          }
        );
        this.gtag('event', 'view_cart', {
          currency: cart.priceInfo.currencyCode,
          value: cart.priceInfo.total,
          items: cartItems,
        });
      });
    }
  }

  /**
   * Track when and where a single item is viewed - GA
   * @param itemNumber
   * @param itemName
   * @param source
   */
  viewItem(itemNumber: string, productType: string, itemName: string, source: string): void {
    return this.pushLayer(() => {
      this.gtag('event', 'view_item', {
        items: [
          {
            item_id: itemNumber,
            item_category: productType,
            item_name: itemName,
            item_list_name: source,
          },
        ],
      });
    });
  }

  /**
   * Event tracking for paying with PayPal
   * @param source
   */
  usePayPal(source: string): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        event: 'usePayPal',
        source: source,
      });
    });
  }

  /**
   * Track when a product is viewed so we can trigger the BV catalog collection
   * @param id
   * @param name
   * @param image
   * @param url
   */
  addToBV(id: string, name: string, image: string, url: string): void {
    return this.pushLayer(() => {
      this.dataLayer.push({
        event: 'addToBV',
        bvProductId: id,
        bvProductName: name,
        bvProductImage: image,
        bvProductPage: url,
      });
    });
  }

  /**
   * Only perform if browser.
   * @param callback
   * @protected
   */
  protected pushLayer(callback: Function): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    return callback();
  }
}
