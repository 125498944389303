// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  transition: width 0.2s ease-in-out;
  width: 100%;
  height: auto;
}
.menu-main {
  margin-bottom: 2rem;
  height: auto;
}
.menu-item {
  font-weight: bold;
}
.menu.minimized {
  width: auto;
  padding: 0;
}
.menu.minimized-main {
  width: auto;
  padding: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNzLWxheW91dC1uYXYuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQ0FBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0FBQ0Y7QUFDRTtFQUNFLG1CQUFBO0VBQ0EsWUFBQTtBQUNKO0FBRUU7RUFDRSxpQkFBQTtBQUFKO0FBR0U7RUFDRSxXQUFBO0VBQ0EsVUFBQTtBQURKO0FBR0k7RUFDRSxXQUFBO0VBQ0EsVUFBQTtBQUROIiwiZmlsZSI6ImNzLWxheW91dC1uYXYuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWVudSB7XG4gIHRyYW5zaXRpb246IHdpZHRoIC4ycyBlYXNlLWluLW91dDtcbiAgd2lkdGg6IDEwMCU7XG4gIGhlaWdodDogYXV0bztcblxuICAmLW1haW4ge1xuICAgIG1hcmdpbi1ib3R0b206IDJyZW07XG4gICAgaGVpZ2h0OiBhdXRvO1xuICB9XG5cbiAgJi1pdGVtIHtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgfVxuXG4gICYubWluaW1pemVkIHtcbiAgICB3aWR0aDogYXV0bztcbiAgICBwYWRkaW5nOiAwO1xuXG4gICAgJi1tYWluIHtcbiAgICAgIHdpZHRoOiBhdXRvO1xuICAgICAgcGFkZGluZzogMDtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/cs-layout/cs-layout-nav/cs-layout-nav.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,YAAA;AACJ;AAEE;EACE,iBAAA;AAAJ;AAGE;EACE,WAAA;EACA,UAAA;AADJ;AAGI;EACE,WAAA;EACA,UAAA;AADN;AACA,41BAA41B","sourcesContent":[".menu {\n  transition: width .2s ease-in-out;\n  width: 100%;\n  height: auto;\n\n  &-main {\n    margin-bottom: 2rem;\n    height: auto;\n  }\n\n  &-item {\n    font-weight: bold;\n  }\n\n  &.minimized {\n    width: auto;\n    padding: 0;\n\n    &-main {\n      width: auto;\n      padding: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
