import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table-features',
  templateUrl: './pricing-plan-table-features.component.html',
  styleUrls: ['./pricing-plan-table-features.component.scss'],
})
export class PricingPlanTableFeaturesComponent {
  @HostBinding('class') hostClass = 'pricing-plan-table-features';
}
