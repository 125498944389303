import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { first } from 'rxjs/operators';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { IUser } from '../../../../contracts/user/iuser';
import { of } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-jlg-manual-result-list-item]',
  styleUrls: [
    '../manual-result-list-item/manual-result-list-item.component.scss',
  ],
  templateUrl: './jlg-manual-result-list-item.component.html',
})
export class JLGManualResultListItemComponent implements OnInit {
  dateFormat = 'MM/dd/YYYY';
  @Input() item!: {
    records: object[];
    numRecords: number;
    detailsAction: {
      contentPath: string;
      recordState: string;
    };
    attributes: {
      'jlgManual.creationDate': string[];
      'jlgManual.damFileSize': string[];
      'jlgManual.damUrl': string[];
      'jlgManual.description': string[];
      'jlgManual.descriptionSuffix': string[];
      'jlgManual.excludedSerialNumbers': string[];
      'jlgManual.keywords': string[];
      'jlgManual.manualLanguage': string[];
      'jlgManual.manualType': string[];
      'jlgManual.marketingBrand': string[];
      'jlgManual.modelNumber': string[];
      'jlgManual.modelSeries': string[];
      'jlgManual.modelType': string[];
      'jlgManual.pvc': string[];
      'jlgManual.safetySpecification': string[];
      'jlgManual.schematicType': string[];
      'jlgManual.snRange': string[];
      'jlgManual.techPubPartNumber': string[];
      'jlgManual.title': string[];
      'record.id': string[];
    };
  };
  ocids: OcidItems = {};
  manualTitle = '';
  excludedSns = '';

  constructor(
    private localization: LocalizationService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'control-software.serial-num-range',
        'jlg-manuals.publication-part-number',
        'control-software.serial-num-range',
        'jlg-manuals.release-date',
        'jlg-manuals.language',
        'tp.exclude-serial-number-range-label',
      ])
      .pipe(first())
      .subscribe();

    this.manualTitle += this.item.attributes['jlgManual.marketingBrand']
      ? `${this.item.attributes['jlgManual.marketingBrand'].join(', ')} - `
      : '';
    this.manualTitle += this.item.attributes['jlgManual.modelType']
      ? this.item.attributes['jlgManual.modelType'][0]
      : '';
    this.manualTitle += this.item.attributes['jlgManual.modelNumber']
      ? ` : ${this.item.attributes['jlgManual.modelNumber'].join(', ')}`
      : '';
    this.manualTitle += this.item.attributes['jlgManual.manualType']
      ? ` - ${this.item.attributes['jlgManual.manualType'][0]}`
      : '';
    this.manualTitle += this.item.attributes['jlgManual.schematicType']
      ? ` - ${this.item.attributes['jlgManual.schematicType'][0]}`
      : '';
    this.manualTitle += this.item.attributes['jlgManual.descriptionSuffix']
      ? ` - ${this.item.attributes['jlgManual.descriptionSuffix'][0]}`
      : '';
    // Add space between commas of excluded serial numbers
    this.excludedSns =
      this.item.attributes['jlgManual.excludedSerialNumbers'] &&
      this.item.attributes['jlgManual.excludedSerialNumbers'][0].replace(
        /,/g,
        ', '
      );
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        // Set the user's default date format.
        this.dateFormat = user.appConfig.dateFormat;
      }
      // If the user is not an enterprise user or user is empty, return null.
      return of(null);
    });
  }
}
