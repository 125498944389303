import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-parts-slider-cart',
  styleUrls: ['./parts-slider-cart.component.scss'],
  templateUrl: './parts-slider-cart.component.html',
})
export class PartsSliderCartComponent implements CartridgeInterface {
  @Input() data!: {
    title: string;
    records: object[];
  };
}
