// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-menu-panel.filter-menu {
  min-width: 200px;
  max-height: 300px;
  overflow-y: scroll !important;
}
::ng-deep .mat-mdc-menu-panel.filter-options-menu {
  min-width: 200px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5ldy1maWx0ZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxnQkFBQTtFQUNBLGlCQUFBO0VBQ0EsNkJBQUE7QUFBSjtBQUVFO0VBQ0UsZ0JBQUE7QUFBSiIsImZpbGUiOiJuZXctZmlsdGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOjpuZy1kZWVwIC5tYXQtbWRjLW1lbnUtcGFuZWwge1xyXG4gICYuZmlsdGVyLW1lbnUge1xyXG4gICAgbWluLXdpZHRoOiAyMDBweDtcclxuICAgIG1heC1oZWlnaHQ6IDMwMHB4O1xyXG4gICAgb3ZlcmZsb3cteTogc2Nyb2xsICFpbXBvcnRhbnQ7XHJcbiAgfVxyXG4gICYuZmlsdGVyLW9wdGlvbnMtbWVudSB7XHJcbiAgICBtaW4td2lkdGg6IDIwMHB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/header/fleet-header/new-filter/new-filter.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,iBAAA;EACA,6BAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AACA,gmBAAgmB","sourcesContent":["::ng-deep .mat-mdc-menu-panel {\r\n  &.filter-menu {\r\n    min-width: 200px;\r\n    max-height: 300px;\r\n    overflow-y: scroll !important;\r\n  }\r\n  &.filter-options-menu {\r\n    min-width: 200px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
