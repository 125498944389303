import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TemplateService {
  templateData: BehaviorSubject<object> = new BehaviorSubject({});

  injectTemplateData(data: object) {
    this.templateData.next(data);
  }
}
