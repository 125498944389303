import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { LocalizationService } from '../../shared/localization/localization.service';

@Injectable()
export class ConfirmationAlertService {

  constructor(private snackBar: MatSnackBar,
              private localization: LocalizationService) {
  }

  /**
   * Add item to cart confirmation alert.
   *
   * @param {string} productName
   */
  public addToCartConfirm(productName: string) {
    const ocids = this.localization.OCIDs.getValue();
    if (productName === '') {
      productName = 'The product';
    }
    this.snackBar.open(productName + ` ${ocids['shopping-cart.confirm-item-added-message']}`, '✖', {
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'product-confirm'
    });
  }

  /**
   * Add item to shopping list alert.
   *
   * @param {string} productName
   */
  public addToShoppingList(productName: string) {
    const ocids = this.localization.OCIDs.getValue();
    if (productName === '') {
      productName = 'The product';
    }
    this.snackBar.open(productName + ` ${ocids['shopping-list.confirm-item-added-message']}`, null, {
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'product-confirm'
    });
  }

  /**
   * Alert user with given message.
   *
   * @param {string} message
   */
  public alertUser(message: string) {
    this.snackBar.open(message, null, {
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'product-confirm'
    });
  }
}
