import { IWarehouseInfo } from './iwarehouse-info';

export interface ICommerceItemAvailability {
  backOrderMessage: string;
  backOrderMessageExtention?: string;
  backOrderable: boolean;
  directShipItem?: boolean;
  estimatedShipDate: string;
  displayEstimatedShipDate: boolean;
  itemNumber: string;
  leadTime: string;
  primaryWarehouse: string;
  shippable: string;
  splitWarehouse: string;
  splitWhsMessage: string;
  totalAvailability: number;
  hazmatWarningMessage: string;
  displayTotalAvailability: boolean;
  warehouseInfo: IWarehouseInfo[];
  warehouseVisible: boolean;
}

export interface IItemAvailabilityCheckout {
  [name: string]: ICommerceItemAvailability;
}

export const WarehouseType = {
  Primary: 'primary',
};

export const SplitWarehouse = {
  TRUE: 'Y',
  FALSE: 'N',
};
