import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { CartService } from '../../../../../service/cart/cart.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { ConfirmationDialogsService } from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UserEnvironments } from '../../../../../contracts/user/iuser';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pricing-not-setup-warning',
  templateUrl: './pricing-not-setup-warning.component.html',
})
export class PricingNotSetupWarningComponent implements OnInit {
  @Input() items: ICommerceItemWithCart[] = [];
  @Input() env!: string;
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  ocids: OcidItems = {};
  platformBrowser = false;
  environments = UserEnvironments;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private cartService: CartService,
    private zone: NgZone,
    private confirmationService: ConfirmationDialogsService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  /**
   * Event listener on request price click
   */
  onRequestPricing() {
    if (this.platformBrowser) {
      const itemList = this.items.map(item => item.itemNumber).join(',');
      document.cookie =
        'partNumForPrice=' + itemList + ';path=/;domain=jlg.com';
      window.open('/request', '_blank');
    }
  }

  /**
   * Event listener on remove click.
   */
  onRemove() {
    // Show dialog first
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.notificationService.reset();
          this.zone.runOutsideAngular(() => {
            return this.cartService
              .removeAll(this.items, 'Pricing Not Setup')
              .subscribe({
                next: () => {
                  this.zone.run(() => {
                    this.removed.emit();
                  });
                },
                error: (err) => {
                  this.zone.run(() => {
                    console.log(err);
                    this.notificationService.addError(err.error?.title);
                  });
                },
              });
          });
        }
      });
  }
}
