import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-paypal-redirect',
  templateUrl: './paypal-redirect.component.html',
})
export class PayPalRedirectComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  ngOnInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   window.opener.postMessage('paypalVerified', '*');
    //   window.close();
    // }
  }
}
