import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, merge, of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  onErrorResumeNext,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { IAllRoles } from '../../../../contracts/user/iall-roles';
import { IManagedUser } from '../../../../contracts/user/imanaged-user';
import { IManagedUserPagination } from '../../../../contracts/user/imanaged-user-pagination';
import { IManagedUsersPayload } from '../../../../contracts/user/imanaged-users-payload';
import { IMenuListItem } from '../../../../contracts/user/imenu-list-item';
import { INavMenu } from '../../../../contracts/user/inav-menu';
import { ISubusers } from '../../../../contracts/user/isubuser';
import { IUser } from '../../../../contracts/user/iuser';
import { ExcelService } from '../../../../service/excel/excel.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { MenuService } from '../../../../service/user/menu.service';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { EditPermissionComponent } from './edit-permission/edit-permission.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { ManageUsersService } from './manage-users.service';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface IUserRolesAndStatuses {
  usersListOfRoles: {};
  userStatuses: {};
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-manage-users',
  styleUrls: ['./account-manage-users.component.scss'],
  templateUrl: './account-manage-users.component.html',
})
export class AccountManageUsersComponent implements OnInit, OnDestroy {
  ocids: {} = {};
  currentUser: IUser;
  customer: ISubusers[] = [];
  searchUsers = '';
  filterRoleMenu: INavMenu = {};
  roleFilter;
  filterStatusMenu: INavMenu = {};
  statusFilter = '';
  dataLength = 0;
  submitted = false;
  pageSizeOptions: number[] = [10, 20, 50];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  searchFilterEmitter: EventEmitter<any> = new EventEmitter<any>();
  dataSource = new MatTableDataSource<IManagedUser>([]);
  userRolesAndStatuses: IUserRolesAndStatuses = {
    usersListOfRoles: {},
    userStatuses: {},
  };
  filteredAccountPermissoins: INavMenu;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private confirmationService: ConfirmationDialogsService,
    private notificationService: NotificationService,
    private menuService: MenuService,
    private localization: LocalizationService,
    private manageUsersService: ManageUsersService,
    private currentUserService: CurrentUserService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    // Get the user's customer number and if they are an enterprise user, their
    // child company numbers as well.
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.currentUser = user;
        // Minimally, push the user's customer number.
        this.customer.push({
          customerNumber: user.customerNumber,
          name: user.companyName ? user.companyName : '',
        });
        // If the user has any roles set.
        if (user.allRoles) {
          // Iterate through those roles looking for EnterpriseUserProxy.
          user.allRoles.forEach((role) => {
            // If any of the roles are EnterpriseUserProxy.
            if (role.name === 'EnterpriseUserProxy') {
              // Get the child company list which will container customer number and name.
              this.currentUserService.getChildCompanyList().subscribe(
                (data: ISubusers[]) => {
                  // If the user contains subusers.
                  if (data) {
                    // Iterate throught the subusers and add each customer number to the array of customer numbers.
                    data.forEach((subUser) => {
                      this.customer.push(subUser);
                    });
                  }
                },
                (error) => {
                  console.log(error);
                  this.notificationService.addError(error.error.title);
                }
              );
            }
          });
        }
      }
    });
    // Get OCIDS

    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });

    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? combineLatest(
              this.menuService.getMenuByUxKey('roles'),
              this.menuService.getMenuByUxKey('userStatus'),
              this.menuService.getMenuByUxKey('my-account.manage-user-permissions')
            )
            : of(null)
        )
      )
      .subscribe((menus: INavMenu[]) => {
        if (menus) {
          this.filterRoleMenu = menus[0];
          this.filterStatusMenu = menus[1];
          this.filteredAccountPermissoins = menus[2];
          const filteredAccountPermissoinsOcids = this.filteredAccountPermissoins.childMenus.map(child => child.label);
          this.localization
            .getOCIDs([
              'account.manage-users.intro',
              'account.manage-users-roles',
              'account.manage-users-user',
              'account.manage-users-invite-link',
              'account.manage-users-title',
              'account.search-users-remove',
              'account.edit-user-delete-message',
              'account.manage-users-search',
              'manage-users.activate',
              'manage-users.activation-confirmation-message',
              'manage-users.activation-confirmation-title',
              'manage-users.deactivate',
              'manage-users.deactivate-message',
              'manage-users.deactivate-title',
              'manage-users.edit-user-permissions',
              'manage-users.invite-confirmation',
              'manage-users.invite-user-message',
              'manage-users.last-login-date',
              'manage-users.user-permissions-label',
              ...filteredAccountPermissoinsOcids
            ])
            .subscribe();
        }
      });
    // Merge requests to search/filter, sort, and paginate.
    merge(this.searchFilterEmitter, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.notificationService.reset();
          const params: IManagedUserPagination = this.getQueryParams(false);
          return this.manageUsersService.getManagedUsers(params).pipe(
            catchError((error) => {
              this.notificationService.addError(error.error.title);
              return null;
            }),
            onErrorResumeNext()
          );
        }),
        map((userPayload: IManagedUsersPayload) => {
          // minus 1 because we're manually removing the current user from the list
          this.dataLength = userPayload.totalResults;
          return userPayload.items;
        }),
        catchError((error) => {
          this.notificationService.addError(error.error.title);
          return of([]);
        })
      )
      .subscribe((users: IManagedUser[]) => {
        if (users.length > 0) {
          this.userRolesAndStatuses = this.filterRolesAndMapStatuses(users);
        }
        // this.dataSource.data = this.filterOutSelf(users);
        this.dataSource.data = users;
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Invite user.
   */
  inviteUser() {
    this.dialog.open(InviteUserComponent, {
      width: '350px',
      data: this.customer,
    });
  }

  /**
   * Edit user permissions.
   *
   * @param user
   */
  editPermission(event, user: IManagedUser) {
    const dialogRef = this.dialog.open(EditPermissionComponent, {
      width: '350px',
      data: {
        user: user,
        userRoles: this.filterRoleMenu,
      },
    });
    dialogRef.afterClosed().subscribe((updatedUserPermissions) => {
      // If permissions were updated, refresh the table.
      if (updatedUserPermissions) {
        this.search();
      }
    });
  }

  /**
   * Activate user from STAGED and SUSPENDED statuses.
   *
   * @param user
   */
  activateUser(user: IManagedUser) {
    this.confirmationService
      .confirm(
        this.ocids['manage-users.activation-confirmation-title'],
        this.ocids['manage-users.activation-confirmation-message'],
        this.ocids['global.confirm'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.updateUserStatus(user.login, 'ACTIVE');
        }
      });
  }

  /**
   * Deactive user from ACTIVE status.
   *
   * @param user
   */
  deactivateUser(user: IManagedUser) {
    this.confirmationService
      .confirm(
        this.ocids['manage-users.deactivate-title'],
        this.ocids['manage-users.deactivate-message'],
        this.ocids['global.confirm'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.updateUserStatus(user.login, 'SUSPEND');
        }
      });
  }

  /**
   * Delete user: special status change to DELETE.
   *
   * @param user
   */
  deleteUser(user: IManagedUser) {
    this.confirmationService
      .confirm(
        this.ocids['account.search-users-remove'],
        this.ocids['account.edit-user-delete-message'],
        this.ocids['global.confirm'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.updateUserStatus(user.login, 'DELETE');
        }
      });
  }

  /**
   * Method used to consolidate code. Takes login and status as parameters and updates the user's status.
   */
  updateUserStatus(login: string, status: string) {
    this.notificationService.reset();
    this.manageUsersService
      .updateUserStatus({
        login: login,
        status: status,
      })
      .subscribe(
        (data) => {
          this.search();
        },
        (error) => {
          console.log(error);
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   * Reset filter.
   */
  resetFilter() {
    this.paginator.pageIndex = 0;
    this.searchUsers = '';
    this.dataSource.filter = '';
  }

  /**
   * Export users to Excel.
   */
  exportUsers() {
    this.notificationService.reset();
    this.manageUsersService
      .getManagedUsers(this.getQueryParams(true))
      .subscribe(
        (payload: IManagedUsersPayload) => {
          const rolesAndStatuses: IUserRolesAndStatuses =
            this.filterRolesAndMapStatuses(payload.items);

          this.excelService.exportManageUsers(
            [
              this.ocids['global.first-name'],
              this.ocids['global.last-name'],
              this.ocids['global.email-address'],
              this.ocids['global.jlg-account-number'],
              this.ocids['manage-users.last-login-date'],
              this.ocids['manage-users.user-permissions-label'],
              this.ocids['global.status'],
            ],
            [
              'firstName',
              'lastName',
              'login',
              'customerNumber',
              'lastActivity',
              'roles',
              'oleUserState',
            ],
            this.ocids['account.manage-users-title'],
            payload.items,
            rolesAndStatuses.usersListOfRoles,
            rolesAndStatuses.userStatuses
          );
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   * Reusable method to get query params used to retrieve managed users.
   * @param {boolean} exportingToExcel
   * @returns {IManagedUserPagination}
   */
  getQueryParams(exportingToExcel: boolean) {
    const params: IManagedUserPagination = exportingToExcel
      ? {
        limit: this.dataLength,
      }
      : {
        limit: this.paginator.pageSize ? this.paginator.pageSize : 10,
        offset: this.paginator.pageIndex + 1,
        sortBy: this.sort.active ? this.sort.active : 'firstName',
      };
    // ADD SEARCH
    if (this.sort.direction === 'desc') {
      params.sortDir = 'desc';
    }
    // If the user wants to search, add the searchTerm param.
    if (this.searchUsers) {
      params.searchTerm = this.searchUsers;
    }
    // If any roles are being filtered, add them to the filter parameters.
    if (this.roleFilter) {
      // Add role to query parameters. Currently not allowed.
      params.roles = this.roleFilter;
    }
    // If a status is being filtered, add it to the filter parameters.
    // If the user wants to view all users regardless of status, then don't add any status params to the request. Otherwise,
    // add the status param to the request.
    if (this.statusFilter && this.statusFilter !== 'ALL') {
      params.status = this.statusFilter;
    }
    return params;
  }

  /**
   * Maps the user's roles as keys to their corresponing label from the menu service.
   *
   * @param {string} menu
   * @param {string} value
   * @returns Observable<IMenuListItem[]|any[]>
   */
  mapToMenu(menu: string, value: string) {
    return this.menuService.mapMenu(menu, [value]);
  }

  /**
   * Remove csr role from the list of roles since it is only manageable in BCC.
   *
   * @param {IAllRoles[]} roles
   * @returns {IAllRoles[]}
   */
  filterOutCSR(roles: IAllRoles[]) {
    return roles.filter((role: IAllRoles) => role.name !== 'CSRProxy');
  }

  /**
   * Transforms the date returned from lastActivity to a format our custom date pipe can read.
   * @param {string} dateAsString
   * @returns {string}
   */
  transformDate(dateAsString: string) {
    const date = new Date(dateAsString);
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    return (
      date.getFullYear().toString() +
      (month.length === 1 ? '0' + month : month) +
      (day.length === 1 ? '0' + day : day)
    );
  }

  /**
   * Filter out CSR roles if returned and map the user's status.
   * @param {IManagedUser[]} users
   */
  filterRolesAndMapStatuses(users: IManagedUser[]) {
    const rolesAndStatuses: IUserRolesAndStatuses = {
      usersListOfRoles: {},
      userStatuses: {},
    };
    users.forEach((user: IManagedUser) => {
      const filteredRoles = this.filterOutCSR(user.allRoles);
      filteredRoles.forEach((role: IAllRoles, roleIndex: number) => {
        this.mapToMenu('roles', role.name).subscribe(
          (userRole: IMenuListItem[]) => {
            if (userRole.length) {
              const userListOfRoles =
                rolesAndStatuses.usersListOfRoles[user.login];
              rolesAndStatuses.usersListOfRoles[user.login] =
                (userListOfRoles !== undefined ? userListOfRoles : '') +
                userRole[0].label +
                (roleIndex + 1 !== filteredRoles.length ? ', ' : '');
            }
          }
        );
      });
      // Map the status to the value we show in the front end.
      this.menuService
        .mapMenu('userStatus', [user.oleUserState])
        .subscribe((oleUserState) => {
          rolesAndStatuses.userStatuses[user.login] = oleUserState[0]
            ? oleUserState[0].label.toUpperCase()
            : '';
        });
    });
    return rolesAndStatuses;
  }

  /**
   * Checks to see if this user is the current logged in user.
   * @param user
   */
  isCurrentUser(user: IManagedUser): boolean {
    return user.login === this.currentUser.login;
  }

  resetForm(event) {
    event.preventDefault();
    this.searchUsers = '';
    this.roleFilter = undefined;
    this.statusFilter = undefined;
    this.searchFilterEmitter.emit();
    this.submitted = false;
  }

  search() {
    this.submitted = true;
    this.searchFilterEmitter.emit();
  }

  get isDirty() {
    return this.submitted && (this.searchUsers || this.roleFilter || this.statusFilter);
  }

  /**
   * Destroys any unresolved subscriptions.
   */
  ngOnDestroy(): void {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
