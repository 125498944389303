// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.connectivity-status-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.connectivity-status-icon {
  width: 35px !important;
  height: 35px !important;
  font-size: 35px !important;
  margin-right: 0.5rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2V0LW1hY2hpbmUtc3RhdHVzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7QUFDRjs7QUFFQTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLGtCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxzQkFBQTtFQUNBLHVCQUFBO0VBQ0EsMEJBQUE7RUFDQSxvQkFBQTtBQUNGIiwiZmlsZSI6ImFzc2V0LW1hY2hpbmUtc3RhdHVzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xyXG4gIGRpc3BsYXk6IGZsZXg7XHJcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcclxuICBoZWlnaHQ6IDEwMCU7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbn1cclxuXHJcbi5jb25uZWN0aXZpdHktc3RhdHVzLWl0ZW0ge1xyXG4gIGRpc3BsYXk6IGZsZXg7XHJcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcclxuICBtYXJnaW4tdG9wOiAwLjVyZW07XHJcbn1cclxuXHJcbi5jb25uZWN0aXZpdHktc3RhdHVzLWljb24ge1xyXG4gIHdpZHRoOiAzNXB4ICFpbXBvcnRhbnQ7XHJcbiAgaGVpZ2h0OiAzNXB4ICFpbXBvcnRhbnQ7XHJcbiAgZm9udC1zaXplOiAzNXB4ICFpbXBvcnRhbnQ7XHJcbiAgbWFyZ2luLXJpZ2h0OiAwLjVyZW07XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/machine-detail/widgets/asset-machine-status/asset-machine-status.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,sBAAA;EACA,uBAAA;EACA,0BAAA;EACA,oBAAA;AACF;AACA,w7BAAw7B","sourcesContent":[":host {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.connectivity-status-item {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-top: 0.5rem;\r\n}\r\n\r\n.connectivity-status-icon {\r\n  width: 35px !important;\r\n  height: 35px !important;\r\n  font-size: 35px !important;\r\n  margin-right: 0.5rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
