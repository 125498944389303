// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  padding: var(--cs-widget-padding-y) var(--cs-widget-padding-x);
  overflow: hidden;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndpZGdldC1jb250ZW50LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLGtCQUFBO0VBQ0Esc0JBQUE7RUFDQSxZQUFBO0VBQ0EsMkJBQUE7RUFDQSw4REFBQTtFQUNBLGdCQUFBO0FBQ0YiLCJmaWxlIjoid2lkZ2V0LWNvbnRlbnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgZmxleC1ncm93OiAxO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gIHBhZGRpbmc6IHZhcigtLWNzLXdpZGdldC1wYWRkaW5nLXkpIHZhcigtLWNzLXdpZGdldC1wYWRkaW5nLXgpO1xuICBvdmVyZmxvdzogaGlkZGVuO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/widgets/widget-content/widget-content.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,YAAA;EACA,2BAAA;EACA,8DAAA;EACA,gBAAA;AACF;AACA,ooBAAooB","sourcesContent":[":host {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  flex-grow: 1;\n  justify-content: flex-start;\n  padding: var(--cs-widget-padding-y) var(--cs-widget-padding-x);\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
