import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import XLSX from 'xlsx-populate/browser/xlsx-populate.min';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-import-parts',
  templateUrl: './import-parts.component.html',
})
export class ImportPartsComponent implements OnInit {
  selectedFile = null;
  selectedFileName = null;
  ocids = {};
  fileUploadError = false;

  constructor(
    public dialogRef: MatDialogRef<ImportPartsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: unknown,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'browse.import-part',
        'ordernow.import-step1',
        'ordernow.import-step1.template',
        'ordernow.import-upload-file',
        'global.select-one',
        'order-now.import-parts-1',
        'order-now.import-parts-2',
        'order-now.import-parts-3',
        'order-now.import-parts-4',
        'order-now.import-parts-5',
        'ordernow.import-please-upload',
        'import-parts.download-template',
        'web2case.browse',
        'web2case.no-file-selected',
      ])
      .subscribe();
  }

  /**
   * On select file.
   *
   * @param file
   */
  onSelectFile(file) {
    // Determine whether the file is a .xlsx file first. We use XLSX to parse the file into JSON, and then JSON to CSV.
    // Changing the file to CSV makes it easier to read the file, otherwise we would have to code complicated logic to parse the
    // JSON. Instead, we can reuse the same method for .csv files.
    const fileName: string = file.name;
    if (fileName.match('.{1,}.(xlsx)$')) {
      this.fileUploadError = false;

      XLSX.fromDataAsync(file).then(
        (workbook) => {
          this.selectedFileName = file.name;
          this.selectedFile = file;

          // Modify the workbook.
          const sheet = workbook.activeSheet();
          const usedRange = sheet.usedRange();
          const dataRange = sheet.range(
            1,
            1,
            usedRange._maxRowNumber,
            usedRange._maxColumnNumber
          );
          this.arrayToRecords(dataRange.value());
        },
        () => {
          this.errorUploadingFile();
        }
      );
    } else if (fileName.match('.{1,}.(csv)$')) {
      this.fileUploadError = false;
      const myReader: FileReader = new FileReader();
      myReader.readAsText(file);
      myReader.onloadend = (e) => {
        this.selectedFileName = file.name;
        this.csvToRecords(myReader.result);
      };
      myReader.onerror = (error) => {
        this.errorUploadingFile();
      };
    } else {
      this.errorUploadingFile();
    }
  }

  /**
   * This method is used to reuse the parsing of a csv file.
   * @param csvFile
   */
  csvToRecords(csvFile) {
    const extractValidData = csvFile.split(/\n/);

    // Extract data into array of item nums (in string format) and quantity pairs
    const records = extractValidData.reduce((prev, curr, i) => {
      // Skip headers
      if (i === 0) {
        return prev;
      }

      const row = curr.includes(',') ? curr.split(',') : curr.split(';');

      if (row[0]) {
        prev.push({
          itemNum: row[0].trim().toString(),
          quantity: row[1].trim()
            ? row[1].length <= 4
              ? row[1].trim()
              : 999
            : 1,
        });
      }

      return prev;
    }, []);

    this.selectedFile = csvFile;
    this.data = records;
  }

  /**
   * Convert array to records.
   * @param data
   */
  arrayToRecords(data) {
    const records = data.reduce((prev, curr, i) => {
      // Skip headers
      if (i === 0) {
        return prev;
      }

      const itemNumber = curr.shift();
      const quantity = curr.pop();

      prev.push({
        itemNum: itemNumber.toString(),
        quantity: quantity ? (quantity <= 999 ? quantity : 999) : 1,
      });

      return prev;
    }, []);

    this.selectedFile = data;
    this.data = records;
  }

  /**
   * Making the error uploading file code reusable.
   */
  errorUploadingFile() {
    this.fileUploadError = true;
    this.selectedFileName = this.ocids['ordernow.import-please-upload'];
  }

  /**
   * Event when dialog is closed.
   */
  closeDialog() {
    this.dialogRef.close(this.data);
  }
}
