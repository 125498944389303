import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../contracts/ocid-items';
import { LocalizationService } from '../localization/localization.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss']
})
export class AlertBannerComponent implements OnInit {
  @Input() alertType: 'WARN' | 'ERROR' | 'INFO';
  @Input() header: string;
  @Input() content: string;
  @Output() onClick = new EventEmitter();

  ocids: OcidItems = {};
  color = {
    WARN: '#f0ce12',
    ERROR: '#ff0000',
    INFO: '#000000'
  }
  constructor(private localization: LocalizationService) { }

  ngOnInit() {
    this.localization.getOCIDs([this.header, this.content]).subscribe((ocids) => this.ocids = ocids);
  }

  closeAlert() {
    this.onClick.emit();
  }
}