import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFreightTypeItems } from '../../../../../contracts/commerce/ifreight-type-items';
import { IOrder } from '../../../../../contracts/commerce/iorder';
import { IUser } from '../../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { FreightShippingMethods } from '../../../../../contracts/commerce/ishipping-info-freight-type';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuService } from 'app/service/user/menu.service';
import { Subscription, mergeMap, of } from 'rxjs';
import { INavMenu } from 'app/contracts/user/inav-menu';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-shipping-form-review]',
  templateUrl: './shipping-form-review.component.html',
})
export class ShippingFormReviewComponent implements OnInit {
  @Input() cart: IOrder;
  @Input() items: IFreightTypeItems[] = [];
  @Input() itemsAvailability: {} = {};
  @Input() customerPickup = false;
  @Input() isFreightQuote = false;
  @Output() toggleForm: EventEmitter<any> = new EventEmitter<any>();
  ocids: {} = {};
  user: IUser;
  showShippingInstructions = false;
  showPrimary = false;
  showSecondary = false;
  showEquipment = false;
  primaryShippingTitle: string;
  primaryShippingIcon: string;
  subscription: Subscription;
  shippingInstructionMenu: INavMenu;

  constructor(
    private localization: LocalizationService,
    private currentUserService: CurrentUserService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    this.subscription = this.menuService.menus$
      .pipe(
        mergeMap((menus) => menus ? this.menuService.getMenuByUxKey('user-defaults-shipping-instructions') : of(null))
      )
      .subscribe((menus: INavMenu) => {
        if (menus) {
          this.shippingInstructionMenu = menus;
        }
      });
    this.showPrimary = !!this.items.filter(
      (iFreightItems) =>
        iFreightItems.type === FreightShippingMethods.Parcel ||
        iFreightItems.type === FreightShippingMethods.Other
    )[0]?.items.length;
    this.showSecondary = !!this.items.filter(
      (iFreightItems) => iFreightItems.type === FreightShippingMethods.Truck
    )[0]?.items.length;
    this.showEquipment = !!this.items.filter(
      (iFreightItems) => iFreightItems.type === FreightShippingMethods.Machine
    )[0]?.items.length;

    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;

      const isParcel = !!this.items.filter(
        (iFreightItems) => iFreightItems.type === FreightShippingMethods.Parcel
      )[0]?.items.length;
      this.primaryShippingTitle = isParcel
        ? this.ocids['buy.shipping-options-primary']
        : this.ocids['checkout.shipping-method.label'];
      this.primaryShippingIcon = isParcel ? 'card_giftcard' : '';
    });
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        this.showShippingInstructions =
          this.cart.shippingInstruction &&
          user.appConfig.showShippingInstructions &&
          (this.showPrimary || this.showSecondary);
      }
    });
  }

  /**
   * Toggle form to edit mode.
   */
  onToggleForm() {
    this.toggleForm.emit();
  }

  /**
   * Get primary shipping group.
   *
   * @returns {IShippingGroup}
   */
  get primaryFreight() {
    return this.cart.shippingGroups.items[0];
  }

  /**
   * Get secondary shipping group.
   *
   * @returns {IShippingGroup}
   */
  get secondaryFreight() {
    return this.cart.shippingGroups.items[1];
  }

  /**
   * Get tertiary/equipment shipping group.
   *
   * @returns {IShippingGroup}
   */
  get equipFreight() {
    return this.cart.shippingGroups.items[
      this.cart.shippingGroups.items.length - 1
    ];
  }
}
