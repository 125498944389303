import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { AccountNavigationComponent } from './account-navigation/account-navigation.component';
import { AccountStartComponent } from './account-start/account-start.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { AccountOrdersStartComponent } from './account-orders/account-orders-start/account-orders-start.component';
import { OrderIFrameComponent } from './account-orders/order-iframe/order-iframe.component';
import { AccountShoppingListsComponent } from './account-shopping-lists/account-shopping-lists.component';
import { AccountPaymentComponent } from './account-payment-preferences/account-payment-preferences.component';
import { AccountManageUsersComponent } from './account-manage-users/account-manage-users.component';
import { AccountSubscriptionManagementComponent } from './account-subscription-management/account-subscription-management.component';
import { AccountAddressBookComponent } from './account-address-book/account-address-book.component';
import { ShippingAddressDialogComponent } from './account-address-book/shipping-address-dialog/shipping-address-dialog.component';
import { DuplicateShippingAddressDialogComponent } from './account-address-book/duplicate-shipping-address-dialog/duplicate-shipping-address-dialog.component';
import { ShoppingListDialogComponent } from './account-shopping-lists/shopping-list-dialog/shopping-list-dialog.component';
import { ShareListDialogComponent } from './account-shopping-lists/share-list/share-list-dialog.component';
import { AccountShoppingListComponent } from './account-shopping-list/account-shopping-list.component';
import { PartsOrderInquiryComponent } from './account-orders/parts-order-inquiry/parts-order-inquiry.component';
import { PartsPurchaseHistoryComponent } from './account-orders/parts-purchase-history/parts-purchase-history.component';
import { InvoiceInformationComponent } from './account-orders/invoice-information/invoice-information.component';
import { AccountW2CComponent } from './account-w2c/account-w2c.component';

import { ArrayLengthPipe } from '../../../shared/array-length.pipe';

import { ItemDialogComponent } from './account-shopping-list/item/item-dialog.component';
import { InviteUserComponent } from './account-manage-users/invite-user/invite-user.component';
import { OrderDetailsComponent } from './account-orders/order-details/order-details.component';
import { ShipmentHistoryComponent } from './account-orders/order-details/shipment-history/shipment-history.component';
import { OrderPrintComponent } from './account-orders/order-details/order-print/order-print.component';
import { ExcelService } from '../../../service/excel/excel.service';
import { SharedModule } from '../../../shared/shared.module';
import { InvoiceInformationService } from './account-orders/invoice-information/invoice-information.service';
import { EditPermissionComponent } from './account-manage-users/edit-permission/edit-permission.component';
import { ManageUsersService } from './account-manage-users/manage-users.service';
import { IFrameComponent } from '../i-frame/i-frame.component';
import { CustomerReceiptComponent } from './account-orders/invoice-information/customer-receipt/customer-receipt.component';
import { W2CCasesComponent } from './account-w2c/w2c-cases/w2c-cases.component';
import { W2CWorkOrdersComponent } from './account-w2c/w2c-work-orders/w2c-work-orders.component';
import { RpaFormComponent } from './account-orders/order-details/rpa-form/rpa-form.component';
import { RpaDetailsComponent } from './account-orders/order-details/rpa-details/rpa-details.component';
import { PartsOrdersComponent } from './account-orders/parts-orders/parts-orders.component';
import { ReturnsTableComponent } from './account-orders/parts-orders/returns-table/returns-table.component';
import { OrdersTableComponent } from './account-orders/parts-orders/orders-table/orders-table.component';

@NgModule({
  declarations: [
    AccountNavigationComponent,
    AccountStartComponent,
    AccountProfileComponent,
    AccountOrdersStartComponent,
    AccountShoppingListsComponent,
    AccountPaymentComponent,
    AccountManageUsersComponent,
    AccountSubscriptionManagementComponent,
    AccountAddressBookComponent,
    ShippingAddressDialogComponent,
    DuplicateShippingAddressDialogComponent,
    ShoppingListDialogComponent,
    ShareListDialogComponent,
    AccountShoppingListComponent,
    ItemDialogComponent,
    ArrayLengthPipe,
    InviteUserComponent,
    PartsOrderInquiryComponent,
    PartsPurchaseHistoryComponent,
    PartsOrdersComponent,
    ReturnsTableComponent,
    OrdersTableComponent,
    OrderDetailsComponent,
    OrderIFrameComponent,
    RpaDetailsComponent,
    RpaFormComponent,
    ShipmentHistoryComponent,
    OrderPrintComponent,
    InvoiceInformationComponent,
    EditPermissionComponent,
    IFrameComponent,
    CustomerReceiptComponent,
    AccountW2CComponent,
    W2CCasesComponent,
    W2CWorkOrdersComponent,
  ],
  imports: [CommonModule, SharedModule],
  providers: [
    DatePipe,
    InvoiceInformationService,
    ManageUsersService,
    ExcelService,
  ],
})
export class AccountModule { }
