import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit {
  imageUrl = '/assets/img/maintenance.jpg';
  userLang: string;
  private isJLG = environment.jlgStyling;
  ocids = {
    en: {
      title: 'JLG Will Be Back Soon',
      body:
        'JLG is currently unavailable while we make upgrades to improve our service to you.<br>We apologize for the inconvenience and appreciate your patience.',
      footer: 'Thank you for using JLG',
    },
  };
  titleText: string;
  bodyText: string;
  footerText: string;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.userLang = navigator.language;
    }

    switch (this.userLang) {
      case 'de-DE':
        this.titleText = 'JLG wird bald wieder verfügbar sein';
        this.bodyText =
          'JLG ist derzeit nicht verfügbar, da wie gerade Upgrades durchführen, um unseren Service für Sie zu verbessern.<br>Wir entschuldigen uns für die Unannehmlichkeiten und bedanken uns für Ihre Geduld.';
        this.footerText = 'Vielen Dank, dass Sie JLG verwenden';
        break;
      case 'en-AU':
        this.titleText = 'JLG Will Be Back Soon';
        this.bodyText =
          'JLG is currently unavailable while we make upgrades to improve our service to you.<br> We apologize for the inconvenience and appreciate your patience.';
        this.footerText = 'Thank you for using JLG';
        break;
      case 'en-NZ':
        this.titleText = 'JLG Will Be Back Soon';
        this.bodyText =
          'JLG is currently unavailable while we make upgrades to improve our service to you.<br> We apologize for the inconvenience and appreciate your patience.';
        this.footerText = 'Thank you for using JLG';
        break;
      case 'es-ES':
        this.titleText = 'JLG estará de vuelta en breve';
        this.bodyText =
          'JLG no está disponible en este momento, ya que estamos llevando a cabo actualizaciones para mejorar el servicio que le ofrecemos.<br> Rogamos que nos disculpe por cualquier molestia que podamos ocasionarle y le agradecemos su paciencia.';
        this.footerText = 'Gracias por usar JLG';
        break;
      case 'es-MX':
        this.titleText = 'JLG estará de vuelta en breve';
        this.bodyText =
          'JLG no está disponible en este momento, ya que estamos llevando a cabo actualizaciones para mejorar el servicio que le ofrecemos.<br> Rogamos que nos disculpe por cualquier molestia que podamos ocasionarle y le agradecemos su paciencia.';
        this.footerText = 'Gracias por usar JLG';
        break;
      case 'es':
        this.titleText = 'JLG estará de vuelta en breve';
        this.bodyText =
          'JLG no está disponible en este momento, ya que estamos llevando a cabo actualizaciones para mejorar el servicio que le ofrecemos.<br> Rogamos que nos disculpe por cualquier molestia que podamos ocasionarle y le agradecemos su paciencia.';
        this.footerText = 'Gracias por usar JLG';
        break;
      case 'fr-CA':
        this.titleText = 'JLG sera bientôt à nouveau accessible';
        this.bodyText =
          'JLG est actuellement indisponible. Nous effectuons des mises à niveau pour améliorer le service qui vous est proposé.<br>Nous vous prions de nous excuser pour la gêne occasionnée et vous remercions de votre patience.';
        this.footerText = "Merci d'utiliser JLG";
        break;
      case 'fr-FR':
        this.titleText = 'JLG sera bientôt à nouveau accessible';
        this.bodyText =
          'JLG est actuellement indisponible. Nous effectuons des mises à niveau pour améliorer le service qui vous est proposé.<br>Nous vous prions de nous excuser pour la gêne occasionnée et vous remercions de votre patience.';
        this.footerText = "Merci d'utiliser JLG";
        break;
      case 'fr':
        this.titleText = 'JLG sera bientôt à nouveau accessible';
        this.bodyText =
          'JLG est actuellement indisponible. Nous effectuons des mises à niveau pour améliorer le service qui vous est proposé.<br>Nous vous prions de nous excuser pour la gêne occasionnée et vous remercions de votre patience.';
        this.footerText = "Merci d'utiliser JLG";
        break;
      case 'it-IT':
        this.titleText = 'JLG tornerà presto disponibile';
        this.bodyText =
          'JLG non è al momento disponibile: stiamo apportando delle modifiche per migliorare il servizio offerto.<br>Ci scusiamo per il disagio e vi ringraziamo per la pazienza.';
        this.footerText = 'Grazie per aver scelto JLG';
        break;
      case 'nl-NL':
        this.titleText = 'JLG is binnenkort weer terug';
        this.bodyText =
          'JLG is op dit moment niet bereikbaar. We zijn bezig met upgrades om onze service te verbeteren.<br>Onze excuses voor het ongemak en bedankt voor uw geduld.';
        this.footerText = 'Bedankt voor het gebruiken van JLG';
        break;
      case 'nl':
        this.titleText = 'JLG is binnenkort weer terug';
        this.bodyText =
          'JLG is op dit moment niet bereikbaar. We zijn bezig met upgrades om onze service te verbeteren.<br>Onze excuses voor het ongemak en bedankt voor uw geduld.';
        this.footerText = 'Bedankt voor het gebruiken van JLG';
        break;
      case 'pl-PL':
        this.titleText = 'JLG niedługo będzie znowu dostępne';
        this.bodyText =
          'Chwilowy brak dostępu do JLG. Przeprowadzamy aktualizacje, aby polepszyć nasze usługi.<br>Przepraszamy za niedogodności i dziękujemy za cierpliwość.';
        this.footerText = 'Dziękujemy za korzystanie z usług JLG';
        break;
      case 'pl':
        this.titleText = 'JLG niedługo będzie znowu dostępne';
        this.bodyText =
          'Chwilowy brak dostępu do JLG. Przeprowadzamy aktualizacje, aby polepszyć nasze usługi.<br>Przepraszamy za niedogodności i dziękujemy za cierpliwość.';
        this.footerText = 'Dziękujemy za korzystanie z usług JLG';
        break;
      case 'pt-BR':
        this.titleText = 'A JLG retornará em breve';
        this.bodyText =
          'A JLG está indisponível no momento enquanto fazemos atualizações para melhorar nosso serviço para você.<br>Pedimos desculpas pelo inconveniente e agradecemos sua paciência.';
        this.footerText = 'Obrigado por utilizar a JLG';
        break;
      case 'pt-PT':
        this.titleText = 'JLG Irá Estar de Volta em Breve';
        this.bodyText =
          'A JLG está temporariamente inacessível durante o período de atualizações para melhoria do nosso serviço ao cliente.<br>Pedimos desculpa pelo inconveniente e agradecemos desde já a sua compreensão.';
        this.footerText =
          'Agradecidos pela preferência com que distingue a JLG';
        break;
      case 'pt':
        this.titleText = 'JLG Irá Estar de Volta em Breve';
        this.bodyText =
          'A JLG está temporariamente inacessível durante o período de atualizações para melhoria do nosso serviço ao cliente.<br>Pedimos desculpa pelo inconveniente e agradecemos desde já a sua compreensão.';
        this.footerText =
          'Agradecidos pela preferência com que distingue a JLG';
        break;
      case 'ru-RU':
        this.titleText = 'JLG скоро будет с Вами';
        this.bodyText =
          'В настоящее время веб-сайт JLG недоступен, так как мы работаем над улучшением его работы и наших сервисов. <br>Мы приносим извинения за неудобства и благодарим Вас за терпение.';
        this.footerText = 'Благодарим Вас за обращение в компанию JLG';
        break;
      case 'ru':
        this.titleText = 'JLG скоро будет с Вами';
        this.bodyText =
          'В настоящее время веб-сайт JLG недоступен, так как мы работаем над улучшением его работы и наших сервисов. <br>Мы приносим извинения за неудобства и благодарим Вас за терпение.';
        this.footerText = 'Благодарим Вас за обращение в компанию JLG';
        break;
      case 'sv-SE':
        this.titleText = 'JLG kommer snart tillbaka';
        this.bodyText =
          'För närvarande kan du inte använda JLG eftersom vi uppgraderar för att ge dig bättre service.<br>Vi ber om ursäkt för besväret.';
        this.footerText = 'Tack att du använder JLG';
        break;
      case 'de':
        this.titleText = 'JLG wird bald wieder verfügbar sein';
        this.bodyText =
          'JLG ist derzeit nicht verfügbar, da wie gerade Upgrades durchführen, um unseren Service für Sie zu verbessern.<br>Wir entschuldigen uns für die Unannehmlichkeiten und bedanken uns für Ihre Geduld.';
        this.footerText = 'Vielen Dank, dass Sie JLG verwenden';
        break;
      default:
        this.titleText = this.ocids.en.title;
        this.bodyText = this.ocids.en.body;
        this.footerText = this.ocids.en.footer;
    }
    // ssr apis failing so checking on browser url for jerrdan.
    if (!this.isJLG || location.href.includes('jerrdan')) {
      this.titleText = this.titleText.replace('JLG', 'Jerr-Dan');
      this.bodyText = this.bodyText.replace('JLG', 'Jerr-Dan');
      this.footerText = this.footerText.replace('JLG', 'Jerr-Dan');
      this.imageUrl = '/assets/img/maintenance-jerr.png';
    }
  }
}
