import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Inject,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of } from 'rxjs';
import {
  catchError,
  map,
  onErrorResumeNext,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { IAppConfig } from '../../../../contracts/user/config/iapp-config';
import { IAddressPaginationDto } from '../../../../contracts/user/dto/iaddress-pagination-dto';
import { IAddress } from '../../../../contracts/user/iaddress';
import { IAddressPagination } from '../../../../contracts/user/iaddress-pagination';
import { IUser } from '../../../../contracts/user/iuser';
import { ShippingAddressFormComponent } from '../../../../endeca/cartridges/account/shipping-address-form/shipping-address-form.component';
import { NotificationService } from '../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { UserAddressesService } from '../../../../service/user/user-addresses.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
})
export class ChangeAddressComponent implements OnInit, OnDestroy {
  addressForm: UntypedFormGroup;
  appConfig: IAppConfig;
  isSearch = true;
  selection = new SelectionModel<IAddress>(true, []);
  dataSource: MatTableDataSource<IAddress> = new MatTableDataSource<IAddress>(
    []
  );
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50];
  resultsLength = 0;
  ocids: {} = {};
  title = '';
  search = '';
  isEmeaUser = false;
  emeaRequestSubmitted = false;
  readonly searchEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('addAddressForm') addAddressForm: ShippingAddressFormComponent;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ChangeAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private addressesService: UserAddressesService,
    private currentUserService: CurrentUserService,
    private localization: LocalizationService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    // Build address form
    this.addressForm = this.fb.group({});

    // Get all user addresses
    this.paginateAddresses();

    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.contact-number-label',
        'account.search-address-text',
        'address-book.add-address',
        'address-m2-create-ship-to-label',
        'address.create-ship-to-request',
        'shipping.add-new-address',
        'shipping.change-shipping-link',
        'shipping.shipping-id',
      ])
      .subscribe(() => {
        this.title = this.ocids['shipping.change-shipping-link'];
      });

    // Get the user for the user's app config.
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.appConfig = user.appConfig;
        this.isEmeaUser =
          !user.appConfig.createShiptoAddressAllowed &&
          user.appConfig.hasOwnProperty('createShiptoAddressRequestLink');
      }
    });
  }

  ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  /**
   * Reset filter.
   */
  onReset() {
    this.search = '';
    this.onSearch();
  }

  /**
   * Emit event of search.
   */
  onSearch() {
    this.paginator.firstPage(); // Go to first page before searching!
    this.searchEvent.emit(this.search);
  }

  /**
   * Paginate for addresses.
   */
  paginateAddresses() {
    this.notificationService.reset();
    // Merge all search, sort, and pagination events to get the addresses.
    merge(this.searchEvent, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          const params: IAddressPaginationDto = {
            addressType: 'secondaryAddresses',
            pageIndex: this.paginator.pageSize
              ? this.paginator.pageSize
              : this.pageSize,
            page: this.paginator.pageIndex + 1,
            sortBy: this.sort.direction ? this.sort.active : 'customerName',
          };
          if (this.search !== '') {
            params.searchTerm = this.search;
          }
          if (this.sort.direction === 'desc') {
            params.sortDir = 'desc';
          }
          return this.addressesService.getAddressPagination(params).pipe(
            catchError((error) => {
              // Show the error to the user.
              this.notificationService.addError(error.error.title);
              // Return null.
              return null;
            }),
            onErrorResumeNext()
          );
        }),
        map((addresses: IAddressPagination) => {
          this.resultsLength = addresses.totalResults;

          return addresses.items;
        }),
        catchError((err) => {
          if (err) {
            // If not cancelled
            console.log(err);
            // If the app is in DEV mode, show the developer error. If the app is not,
            // show the user friendly global error.
            this.notificationService.addError(
              isDevMode() ? err.error?.title : this.ocids['global.system-error']
            );
          }

          return of([]);
        })
      )
      .subscribe((data) => {
        this.dataSource.data = data;
      });
  }

  /**
   * Event listener for when a user selects a table row.
   */
  selectAddress(event, row) {
    // Check if this was selected previously
    if (this.selection.isSelected(row)) {
      // It was, so simply uncheck it
      this.selection.deselect(row);
    } else {
      this.selection.clear();
      this.selection.select(row);
    }
  }

  /**
   * Close out of add address and show addresses.
   *
   * @param {any} event
   */
  closeAddAddress(event) {
    this.isSearch = true;
    this.emeaRequestSubmitted = false;
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog(event) {
    this.dialogRef.close();
  }

  /**
   * Even emitted when an address has been added. Close the module and show the added address as the new
   * selected address in checkout.
   * @param {IAddress} address
   */
  addedAddress(address) {
    this.dialogRef.close(address);
  }

  /**
   * Receives the event the ship to request was submitted from the child component.
   * @param {boolean} submitted
   */
  createShiptoSubmitted(submitted: boolean) {
    this.emeaRequestSubmitted = submitted;
  }

  /**
   * Event emitted when switching to adding a new address form.
   */
  showAddAddress() {
    this.title = this.ocids['address-book.add-address'];
    this.isSearch = false;
  }

  /**
   * Click event to add a new address
   */
  onAddAddress(e) {
    this.addAddressForm.onAddAddress(e);
  }

  /**
   * Close dialog.
   */
  onAddressChange() {
    this.dialogRef.close(this.selection.selected[0]);
  }
}
