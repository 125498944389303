// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th, td {
  text-align: center !important;
}

.table-container:not(._nodata) {
  height: 400px;
  overflow: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hY2hpbmUtaGlzdG9yaWMtZHRjcy10YWJsZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDZCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxhQUFBO0VBQ0EsY0FBQTtBQUNGIiwiZmlsZSI6Im1hY2hpbmUtaGlzdG9yaWMtZHRjcy10YWJsZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbInRoLCB0ZCB7XHJcbiAgdGV4dC1hbGlnbjogY2VudGVyICFpbXBvcnRhbnQ7XHJcbn1cclxuXHJcbi50YWJsZS1jb250YWluZXI6bm90KC5fbm9kYXRhKSB7XHJcbiAgaGVpZ2h0OiA0MDBweDtcclxuICBvdmVyZmxvdzogYXV0bztcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/machine-detail/widgets/machine-historic-dtcs/machine-historic-dtcs-table/machine-historic-dtcs-table.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;AACF;AACA,ohBAAohB","sourcesContent":["th, td {\r\n  text-align: center !important;\r\n}\r\n\r\n.table-container:not(._nodata) {\r\n  height: 400px;\r\n  overflow: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
