import { Component, Input, OnInit } from '@angular/core';
import {
  CSFilterLabelOcids,
  MachineFilterSelection,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { ClearskyService } from '../../../clearsky.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  CSFilter,
  CSFilterData,
  CSFilterKeys,
  CSFilterLabel,
  CSFilterNonEdit,
  CSFilterTransValsByLeg,
  CSFilterTransValsByOcids,
  CSFilterValueOcids,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CSLegend } from '../../../../contracts/clearsky/clearsky-legend';
import { combineLatestWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-filter-chip',
  templateUrl: './filter-chip.component.html',
})
export class FilterChipComponent implements OnInit {
  @Input() filter: MachineFilterSelection;
  nonEditableFilters: string[] = CSFilterNonEdit;
  isEditable = false;
  filterDisplayLabels: { [key: string]: string } = CSFilterLabel;
  filterKeys = CSFilterKeys;
  filterDisplayValues: unknown[] = [];
  filterDisplayValuesPreview: string;
  ocids: OcidItems = {};
  editKey: string | undefined; // Current edited filter
  selectedValues: unknown[] = [];
  private legend: CSLegend = {};
  private filterData: CSFilterData | undefined;
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Is this filter editable
    this.isEditable = !this.nonEditableFilters.includes(this.filter.key);
    this.filterData = Object.values(CSFilter).find(
      (f) => f.key === this.filter.key
    );

    this.subs = this.clearskyService
      .getCurrentFilterValues(this.filter.key)
      .pipe(
        combineLatestWith(
          this.localization.getOCIDs([
            ...CSFilterValueOcids,
            ...CSFilterLabelOcids,
            'clearsky.choose-dashboard-label',
            'clearsky.override-dashboard-label',
            'clearsky.continue-label',
            'clearsky.delete-dashboard-label',
            'clearsky.clear-dashboard-label',
          ]),
          this.clearskyService.legendRef$
        )
      )
      .subscribe(([filter, ocids, legend]) => {
        this.ocids = ocids;
        this.legend = legend;

        // If this filter doesn't have values
        if (!filter) {
          return;
        }

        this.updateDefaultValues(filter);
      });
  }

  /**
   * Update chip values for all filters.
   *
   * @param values
   * @private
   */
  private updateDefaultValues(values: unknown[]): void {
    // Default display values
    this.filterDisplayValues = this.filterData
      ? this.filterData.disFromLeg
        ? CSFilterTransValsByLeg(this.filter.key, values, this.legend)
        : CSFilterTransValsByOcids(this.filter.key, values, this.ocids)
      : values;

    this.filterDisplayValuesPreview =
      this.filterDisplayValues.length > 2
        ? `${[...this.filterDisplayValues].splice(0, 2).join(',')},...`
        : this.filterDisplayValues.join(',');
  }

  /**
   * Open filter menu and set available options.
   * @param filter
   */
  openFilterMenu(filter: MachineFilterSelection): void {
    if (!this.isEditable) {
      return;
    }

    this.editKey = filter.key;
    this.selectedValues = filter.values;
  }

  /**
   * On remove filter.
   * @param key
   */
  onRemoveFilter(key: string): void {
    this.clearskyService.removeFilter(key);
  }
}
