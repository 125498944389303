import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from "../../cartridge/cartridge.class";

@Component({
  selector: 'app-horizontal-record-spotlight',
  templateUrl: './horizontal-record-spotlight.component.html'
})
export class HorizontalRecordSpotlightComponent implements OnInit, CartridgeInterface {
  @Input('data') data: {
    recordSpotlightHeading: string,
    records: object[]
  };

  constructor() { }

  ngOnInit() {
  }

}
