import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { IProduct } from '../../../contracts/product/iproduct';
import { IProductPricing } from '../../../contracts/product/iproduct-pricing';
import { IUser, UserEnvironments } from '../../../contracts/user/iuser';
import { OktaAuthWrapper } from '../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { CartService } from '../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../service/confirmation/confirmation-alert.service';
import { ProductsApiService } from '../../../service/product/products-api.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { ShoppingListComponent } from '../shopping-cart/shopping-list/shopping-list.component';
import { VerifyBySerialComponent } from './verify-by-serial/verify-by-serial.component';
import { environment } from '../../../../environments/environment';
import { ListrakService } from '../../../service/gtm/listrak.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { concatMap, distinctUntilChanged, map } from 'rxjs/operators';
import { OcidItems } from '../../../contracts/ocid-items';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { ICommerceItemAvailability } from 'app/contracts/commerce/icommerce-item-availability';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-endeca-product-detail',
  styleUrls: ['./product-detail.component.scss'],
  templateUrl: './product-detail.component.html',
})
export class ProductDetailComponent implements OnInit, CartridgeInterface, OnDestroy {
  @Input() data!: {
    productMedia: {
      title: string;
      imageAltText: string;
      thumbnailImageUrl: string;
      fullImageUrl: string;
      damLastModifiedDate: string;
    }[];
    metadata: {
      keywords: string;
      description: string;
      title: string;
    };
    '@type': string;
    productId: string;
  };
  isLoggedIn = false;
  user!: IUser;
  platformBrowser = false;
  @ViewChild('carousel') carousel!: SlickCarouselComponent;

  id = 0;
  product!: IProduct;
  pricing!: IProductPricing;
  showExtraItems: boolean;
  cartForm!: UntypedFormGroup;
  phantomForm!: UntypedFormGroup;
  href = '';
  imageHref = '';
  marketingTitle = '';
  isJlg = environment.jlgStyling;
  isReady = false;
  ocids: OcidItems = {};
  environments = UserEnvironments;
  source = {
    PDP: 'ProductDetail',
    MACHINE: 'EquipmentDetail',
  };
  isPDP = true;
  gaSource = '';
  schema;
  placeholderImg = environment.imagePath + environment.placeholderImg;
  attributesOcidsMapping = {
    "global.product-type-label": "productSubType",
    "navigation.refinement.usedOnBrand": "usedOnBrand",
    "global.model-number": "modelNumber",
    "global.filter-design-label": "filterDesign",
    "global.length": "prdLength",
    "global.height": "prdHeight",
    "global.micron-rating-label": "micronRating",
    "global.fluid-type-label": "fluidType",
    "global.media-material-label": "mediaMaterial",
    "global.features-features-label": "feature",
    "global.included-components-label": "includedComponent",
    "global.gasket-t-material thickness-label": "gasketMaterialThickness",
    "global.actual-w-size-label": "actualWidth",
    "global.actual-h-size-label": "actualHeight",
    "global.inside-diameter-label": "insideDiameter",
    "global.outside-diameter-label": "outsideDiameter",
    "global.thread-size-label": "threadSize",
    "global.maximum-temperature-label": "maximumTemperature",
    "global.actual-d-size-label": "actualDepth",
    "global.initial-resistance-label": "initialResistanceAtFPM",
    "global.final-fpm-label": "finalResistanceAtFPM",
    "global.maximum-flow-label": "maximumAirFlow",
    "global.pleat-type-label": "pleatType",
    "global.connection-type-label": "connectionType",
    "global.material-material-label": "material",
    "global.max-pressure-label": "maximumPressure",
    "global.max-flow-label": "maximumFlow",
    "global.seal-material-label": "sealMaterial",
    "global.operating-pressure-label": "operatingPressure",
    "global.connection-size-label": "connectionSize",
    "global.hour-rating-label": "hourRating",
    "global.filter-type-label": "filterFuelType",
  }
  attributes: { key: string; value: string; }[];
  totalAvailability: number;
  itemAvailability: ICommerceItemAvailability;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public dialog: MatDialog,
    private oktaAuth: OktaAuthWrapper,
    private router: Router,
    private currentUserService: CurrentUserService,
    private productService: ProductsApiService,
    private meta: Meta,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private localization: LocalizationService,
    private fb: UntypedFormBuilder,
    private titleService: Title,
    private listrakService: ListrakService,
    private ecommService: EcommerceService
  ) { }

  /**
   * Create an href tag for a superseded item number, suitable to be injected into a tokenized OCID translation
   *
   * @returns {string}
   */
  get supersedeItemLink() {
    return (
      '<a class="text-decoration-underline" routerLink=' +
      this.product.supersedeItemUrl +
      '>' +
      this.product.supersedeItemNumber +
      '</a>'
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(VerifyBySerialComponent, {
      width: '350px',
      data: { part: this.product.itemNumber },
    });

    dialogRef.afterClosed().subscribe();
  }

  /**
   * Move to certain slide in carousel.
   * @param index
   */
  moveToSlide(index: number) {
    this.carousel.slickGoTo(index);
  }

  /**
   * Event emitted when adding product to cart.
   */
  onAddToCart() {
    if (this.cartForm.valid) {
      this.addToCart(
        this.product.itemNumber,
        +this.quantity.value,
        this.product.itemDescription
      );
    }
  }

  /**
   * Event emitted when adding phantom item to cart.
   *
   * @param {number} index
   */
  onAddPhantomToCart(index: number) {
    const product = this.product.phantomItems[index];
    const quantity = (<UntypedFormGroup>(
      (<UntypedFormArray>this.phantomForm.get('items')).controls[index]
    )).get('quantity').value;

    if (quantity) {
      this.addToCart(
        product.componentItemNumber,
        +quantity,
        product.description
      );
    }
  }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    // Setup localization
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'browse.pdp.verify-serial-number',
        'pdp.part-superseded',
        'buy.phantom-message-1',
        'browse.pdp-verify.serial.num.info.icon',
        'browse-pdp.pricing-not-setup',
        'navigation.refinement.machineWidth',
        'navigation.refinement.machinePlatformHeight',
        'navigation.refinement.machineWorkingHeight',
        'clearsky.attributes-label',
        'navigation.refinement.usedOnBrand',
        'parts.mto-message',
        'buy.in-stock',
        'shopping-cart.availability-error',
        'pdp.itemavailability-direct-ship-message'
      ])
      .subscribe();
    // Get user app config
    this.currentUserService.userSubject
      .pipe(distinctUntilChanged())
      .subscribe((user: IUser) => {
        if (user) {
          this.user = user;
        }
      });

    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn
      .pipe(distinctUntilChanged())
      .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn));

    // Determine if it's PDP or Equipment Details Page
    this.isPDP = this.data['@type'] === this.source.PDP;
    this.gaSource = this.isPDP
      ? 'Product Detail Page'
      : 'Equipment Detail Page';

    // Gather product information
    this.productService.getProduct(this.data.productId).pipe(
      concatMap(product =>
        this.productService.getAvailability(product.itemNumber).pipe(
          map(availability => ({ product, availability }))
        )
      )
    ).subscribe({
      next: ({ product, availability }) => {
        this.itemAvailability = availability.find(item => item.itemNumber === product.itemNumber);
        // Check if item was found
        if (product.itemNotFound) {
          return this.router.navigate([
            '/search/zero-results?Ntt=' + product.itemNumber,
          ]);
        }

        this.product = product;
        this.attributes = Object.entries(this.attributesOcidsMapping).reduce((acc, [label, value]) => {
          const trimmedValue = (this.product[value] || '').toString().trim();
          if (trimmedValue) {
            acc.push({ key: label, value: trimmedValue.split(',').join(', ') });
          }
          return acc;
        }, []);

        this.marketingTitle = this.product.marketingTitle
          ? `${this.product.marketingTitle} - ${this.product.itemNumber}`
          : `${this.product.itemDescription} - ${this.product.itemNumber}`;

        // Set or update page title.
        if (this.product.metatagPageTitle) {
          this.titleService.setTitle(
            `${environment.baseMetaTitle} | ${this.marketingTitle}`
          );
        }

        // Set or update meta description.
        if (
          this.product.metatagDescription ||
          this.data.metadata?.description
        ) {
          this.meta.updateTag({
            name: 'description',
            content:
              this.product.metatagDescription ??
              this.data.metadata?.description,
          });
        }

        // Don't add keywords if they don't exist.
        if (this.data.metadata?.keywords.length > 0) {
          this.meta.updateTag({
            name: 'keywords',
            content: this.data.metadata?.keywords,
          });
        }

        // Get page & img url for product structured data
        this.href = environment.referer + this.router.url;
        this.imageHref = this.data.productMedia[0].fullImageUrl ?? '';

        // Open Graph MetaData
        this.meta.updateTag({ property: 'og:type', content: 'website' });
        this.meta.updateTag({
          property: 'og:url',
          content: window.location.href,
        });
        this.meta.updateTag({
          property: 'og:title',
          content: this.titleService.getTitle(),
        });
        this.meta.updateTag({
          property: 'og:description',
          content:
            this.product.metatagDescription ?? this.data.metadata?.description,
        });
        this.meta.updateTag({
          property: 'og:image',
          content: this.data.productMedia[0].fullImageUrl,
        });

        // Twitter Meta Cards
        this.meta.updateTag({
          property: 'twitter:card',
          content: 'summary_large_image',
        });
        this.meta.updateTag({
          property: 'twitter:url',
          content: window.location.href,
        });
        this.meta.updateTag({
          property: 'twitter:title',
          content: this.titleService.getTitle(),
        });
        this.meta.updateTag({
          property: 'twitter:description',
          content:
            this.product.metatagDescription ?? this.data.metadata?.description,
        });
        this.meta.updateTag({
          property: 'twitter:image',
          content: this.data.productMedia[0].fullImageUrl,
        });

        // Get pricing
        this.productService.getPrices(product.itemNumber).subscribe({
          next: (pricing: IProductPricing) => {
            this.pricing = pricing;
            this.schema = {
              '@context': 'https://schema.org/',
              '@type': 'Product',
              '@id': this.href,
              name: this.product.marketingTitle || this.product.itemDescription,
              image: this.imageHref,
              description:
                this.product.metatagDescription ?? this.product.itemDescription,
              sku: this.product.itemNumber,
              offers: {
                '@type': 'Offer',
                url: this.href,
                priceCurrency: this.user.currencyCode,
                price: this.pricing.listPrice ?? '',
              },
            };

            if (this.platformBrowser) {
              // Listrak browse product
              this.listrakService.browseProduct(product, pricing);
              // Insert product schema
              this.productService.insertSchema(this.schema, 'product' + this.product.itemNumber);
            }

            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0);
            }
          },
          error: () => {
            // ERROR ON PRICING

            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0);
            }
          },
          complete: () => {
            this.isReady = true;
          },
        });

        // Make phantom form if has phantom items
        if (product.phantomItem) {
          this.phantomForm = this.fb.group({
            items: this.fb.array(
              this.product.phantomItems.map(() => {
                return this.fb.group({
                  quantity: [1, [Validators.required, Validators.min(1)]],
                });
              })
            ),
          });
        }

        // Send Product Detail View action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.viewItem(
            this.product.itemNumber,
            this.product.productType,
            this.product.itemDescription,
            this.gaSource
          );
          this.ecommService.addToBV(
            this.product.itemNumber,
            this.product.itemDescription,
            this.imageHref,
            this.href
          );
        }
      },
      error: () => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      },
    });

    this.cartForm = this.fb.group({
      quantity: this.fb.control(1, [Validators.required, Validators.min(1)]),
    });
  }

  /**
   * Add product to shopping list.
   */
  onAddToShoppingList() {
    if (this.cartForm.valid) {
      this.addToShoppingList(
        this.product.itemNumber,
        +this.quantity.value,
        this.product.itemDescription
      );
    }
  }

  /**
   * Add phantom item to shopping list.
   *
   * @param {number} index
   */
  onAddPhantomToShoppingList(index: number) {
    const product = this.product.phantomItems[index];
    const quantity = (<UntypedFormGroup>(
      (<UntypedFormArray>this.phantomForm.get('items')).controls[index]
    )).get('quantity').value;

    if (quantity) {
      this.addToShoppingList(
        product.componentItemNumber,
        +quantity,
        product.description
      );
    }
  }

  /**
   * Add product to shopping list.
   *
   * @param {string} itemNumber
   * @param {number} quantity
   * @param {string} description
   */
  addToShoppingList(
    itemNumber: string,
    quantity: number,
    description?: string
  ) {
    if (this.isLoggedIn) {
      // Pop open dialog
      const shoppingListModal = this.dialog.open(ShoppingListComponent, {
        width: '380px',
        data: {
          items: [
            {
              itemNumber: itemNumber,
              quantity: quantity,
            },
          ],
        },
      });

      shoppingListModal.afterClosed().subscribe((result) => {
        if (result) {
          this.alertService.addToShoppingList(
            description ? description : itemNumber
          );
          // push FB Pixel AddToWishlist Conversion Event
          if (this.platformBrowser) {
            this.ecommService.addToWishlist(
              itemNumber,
              quantity,
              this.gaSource
            );
          }
        }
      });
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.addToShoppingList(itemNumber, quantity, description);
          }
        });
    }
  }

  /**
   * Does this product have dimensions?
   *
   * @returns {number}
   */
  get hasDimensions() {
    if (this.isPDP) {
      return (
        this.product.itemLength ||
        this.product.width ||
        this.product.height ||
        this.product.weight
      );
    } else {
      return (
        this.product.machineWidth ||
        this.product.machinePlatformHeight ||
        this.product.machineWorkingHeight
      );
    }
  }

  /**
   * See if user can purchase item.
   *
   * @returns {boolean | undefined}
   */
  get canBuy() {
    return (
      !this.product.phantomItem &&
      !this.product.supersedeItem &&
      this.product.orderable &&
      this.pricing &&
      this.pricing?.listPrice
    );
  }

  /**
   * See if product has message at top.
   *
   * @returns {boolean | undefined | string}
   */
  get hasImportantMessage() {
    return this.product.phantomItem || this.product.supersedeItem;
  }

  get quantity() {
    return this.cartForm.get('quantity');
  }

  /**
   * See if product has multiple media images.
   *
   * @returns {boolean}
   */
  get hasMultipleImages() {
    return this.data.productMedia.length > 1;
  }

  /**
   * Add product to cart.
   *
   * @param {string} itemNumber
   * @param {number} quantity
   * @param {string} description
   */
  protected addToCart(
    itemNumber: string,
    quantity: number,
    description?: string
  ) {
    this.cartService.addOneToCart(itemNumber, quantity).subscribe({
      next: (item) => {
        this.quantity.setValue(1);
        this.alertService.addToCartConfirm(
          description ? description : itemNumber
        );

        // Send Add to Cart action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.addToCart(
            itemNumber,
            item.categoryName,
            description,
            quantity,
            this.gaSource
          );
        }
      },
    });
  }

  /**
   * Redirects user to the W2C form
   */
  onRequestPricing() {
    if (this.platformBrowser) {
      document.cookie =
        'partNumForPrice=' + this.product.itemNumber + ';path=/;domain=jlg.com';
      this.router.navigate(['/request']);
    }
  }

  /**
   * Binds to a click event of an element injected as HTML. Used to determine if a link was clicked. This is required
   * because routerLink has to be compiled at run time, and because this is an injected link, it cannot be compiled at runtime.
   *
   * @param {any} event
   */
  reroute(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      const link = event.target.getAttribute('routerLink');
      this.router.navigate([link]);
    }
  }

  ngOnDestroy(): void {
    // remove added product schema
    if (this.platformBrowser) {
      this.productService.removeSchema('product' + this.product.itemNumber);
    }
  }

}
