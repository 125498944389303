import { Component } from '@angular/core';

@Component({
  selector: 'app-widget-drag-handler',
  templateUrl: './widget-drag-handler.component.html',
  styleUrls: ['./widget-drag-handler.component.scss']
})
export class WidgetDragHandlerComponent {

}
