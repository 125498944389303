import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-content-slot-main',
  templateUrl: './content-slot-main.component.html'
})
export class ContentSlotMainComponent implements CartridgeInterface {
  @Input('data') data: {
    contents: object[]
  };
}
