import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import { KnowledgeArticleService } from '../../../../../service/knowledge-article.service';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-historic-dtcs',
  templateUrl: './machine-historic-dtcs.component.html',
  styleUrls: ['./machine-historic-dtcs.component.scss'],
})
export class MachineHistoricDtcsComponent implements OnInit {
  @Input() machine: Machine;
  historic = HistoricSelection.DAYS_90; // Binding from output of historic dropdown (will change)
  historicSelection = HistoricSelection.DAYS_90; // Default historic selection (wont change.. default)
  widgetName = MachineWidgets.HISTORIC_DTCS;
  displayName = MachineWidgetsDisplay.assetHistoricDtcs;
  ocids: OcidItems = {};

  constructor(
    private localization: LocalizationService,
    private kaService: KnowledgeArticleService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components
    this.localization
      .getOCIDs([this.displayName])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
