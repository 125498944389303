import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ClearskySubscriptionPlan } from '../../../contracts/clearsky/subscription/clearsky-subscription';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-addons-selection',
  templateUrl: './subscription-addons-selection.component.html',
  styleUrls: ['./subscription-addons-selection.component.scss'],
})
export class SubscriptionAddonsSelectionComponent implements OnChanges, AfterViewInit {
  @Input() selectedPlan!: ClearskySubscriptionPlan;
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  selectedAddonIds: string[] = [];
  ocids: OcidItems = {};
  addOns = [];
  disableBackButton: boolean;

  constructor(private localization: LocalizationService, private activatedRoute: ActivatedRoute) {
    this.translations();
  }

  ngAfterViewInit() {
    if (this.selectedPlan.subscribe) {
      this.disableBackButton = true;
    }
    const params = this.activatedRoute.snapshot.queryParamMap;
    if (params.has('selectedItem') && params.get('selectedItem')) {
      this.toggleAddon(params.get('selectedItem'));
    }
  }
  /**
   * On changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Reset selection if plan changed
    if (changes.selectedPlan) {
      this.selectedAddonIds = [];
    }
  }

  /**
   * Toggle addons selection.
   * @param id
   */
  toggleAddon(id: string): void {
    this.selectedAddonIds.includes(id)
      ? this.selectedAddonIds.splice(this.selectedAddonIds.indexOf(id), 1)
      : this.selectedAddonIds.push(id);

    // Emit new addon selection to parent
    this.selectionChanged.emit(this.selectedAddonIds);
  }

  translations() {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.datafeed-checkbox-label',
        'clearsky.continue-label',
        'clearsky.add-ons-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
