import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from './localization/localization.service';
import { map, take } from 'rxjs/operators';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
  
  /**
   * Receives a boolean value and returns yes: true and no: false.
   * 
   * @param {boolean} value 
   */
  transform(value: boolean, ocids: object) {
    return value ? ocids['global.yes'] : ocids['global.no'];
  }
}
