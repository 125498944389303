import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-widget-title',
  templateUrl: './widget-title.component.html',
  styleUrls: ['./widget-title.component.scss'],
})
export class WidgetTitleComponent {
  @Output() onClick?: EventEmitter<void> = new EventEmitter<void>();
  @Input() hasClickHandler = false;
}
