export enum RequestTypeMode {
    MACHINE = 'Machine',
    ATTACHMENT = 'Attachment',
}

export interface IEquipmentModel {
    serial: string;
    model: string;
    type: string;
    desc: string;
}

export const ReplacementOptions = [
    {
        ocid: 'placard-request.reason1',
        value: 'Lost (original Serial Number Plate has been lost and cannot be found)'
    }, {
        ocid: 'placard-request.reason2',
        value: 'Illegible or damaged beyond use'
    }, {
        ocid: 'global.other',
        value: 'Other'
    }
];