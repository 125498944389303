import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CartridgeInterface } from '../../../cartridge/cartridge.class';
import { EndecaService } from '../../../endeca.service';
import { SearchService } from '../../search/search.service';
import { WindowRefService } from '../../../../service/window-ref/window-ref.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

interface IKeywordSearchAutoSuggestion {
  totalNumResults?: number;
  heading?: string;
  typeAheadMatches: {
    url: string;
    suggestion: string;
  }[];
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-keyword-search-auto-suggest',
  styleUrls: ['./keyword-search-auto-suggest.component.scss'],
  templateUrl: './keyword-search-auto-suggest.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KeywordSearchAutoSuggestComponent
  implements OnInit, CartridgeInterface
{
  @Input() data!: IKeywordSearchAutoSuggestion[];
  @Input() isArticle!: boolean;
  flyoutContent: object = null;
  activeFlyout: string = null;
  isGlobalSearch = false;
  platformBrowser = false;
  showFlyout = false;
  currentSearchTerm: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public searchService: SearchService,
    private endecaService: EndecaService,
    public router: Router,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    if (!Array.isArray(this.data)) {
      this.data = [this.data];
    }
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Get current search term to send to GA
    this.currentSearchTerm = this.searchService.currentSearchTerm.getValue().trim();
    // Determine if there are search results.
    let resultsExist = false;
    const searchResults: object[] = [];
    this.data.forEach((suggestion: IKeywordSearchAutoSuggestion) => {
      if (suggestion.totalNumResults) {
        if (suggestion.totalNumResults > 0) {
          resultsExist = true;
          searchResults.push(suggestion.typeAheadMatches);
        }
      } else {
        if (suggestion.typeAheadMatches.length > 0) {
          resultsExist = true;
          searchResults.push(suggestion.typeAheadMatches);
        }
      }
    });
    // Set the search results.
    if (!resultsExist && !this.isArticle) {
      this.searchService.setSearchResults([]);
    } else if ((this.isArticle && searchResults.length > 0) || resultsExist) {
      this.searchService.setSearchResults(searchResults);
    }

    // Get which search is being used
    this.searchService.isGlobalSearchActive
      .pipe(distinctUntilChanged())
      .subscribe((active: boolean) => (this.isGlobalSearch = active));

    // Check whether or not to show the fly out panel
    this.searchService.showFlyOut
      .pipe(distinctUntilChanged())
      .subscribe((active: boolean) => (this.showFlyout = active));
  }

  /**
   * Event when user clicks on a match suggestion.
   *
   * @param match
   * @param category
   * @returns {Promise<void>}
   */
  onMatchClick(match, category: string) {
    // Record search item in history
    this.searchService.recordSearch(
      match.suggestion,
      <string>encodeURI(match.url)
    );
    // Set the current search term
    this.searchService.setCurrentSearchTerm(match.suggestion);
    this.searchService.currentSearchInput.next(match.suggestion);

    // Send search event to GA if the platform is browser
    if (this.platformBrowser) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: this.isGlobalSearch ? 'globalSearch' : 'searchBoxBannerSearch',
        searchTerm: match.suggestion,
        enteredTerm: this.currentSearchTerm,
        searchType: 'Type ahead ' + category,
      });
    }

    // Now navigate the user
    this.searchService.termIsClicked.next(true);
    this.router
      .navigateByUrl(encodeURI(match.url))
      .then(() => {
        this.searchService.termIsClicked.next(false);
      })
      .catch((error) => error);
  }

  /**
   * In each search result, add bold text around the substring that equals the search term and
   * change the color to black.
   * @param {string} suggestion
   */
  formatSuggestion(suggestion: string) {
    return suggestion.includes(this.currentSearchTerm)
      ? suggestion.replace(
          this.currentSearchTerm,
          `<span style="color: black">${this.currentSearchTerm}</span>`
        )
      : suggestion;
  }

  /**
   * Gets the flyout content if the match has a flyout url.
   * @param {object} match
   */
  getFlyout(match: object) {
    // Get the flyout url for the search result.
    const flyoutURL: string = match['flyoutPath'];
    if (flyoutURL) {
      // Reset the search key up/down index when mouse over.
      this.searchService.currentSectionIndex.next(0);
      this.searchService.linkIndex.next(-1);

      this.searchService.showFlyOut.next(true);
      // Set the active flyout to the currently hovered item. This allows the shaded and font-color: black
      // style to persist even if the user moves their mouse off the result.
      this.activeFlyout = match['suggestion'];
      this.endecaService
        .getPayload(decodeURI(flyoutURL))
        .pipe(distinctUntilChanged())
        .subscribe(
          (flyoutContent: object) => {
            this.flyoutContent = flyoutContent;
            this.searchService.isResultsListSearch.next(true);
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.searchService.showFlyOut.next(false);
      this.flyoutContent = null;
      this.activeFlyout = null;
      this.searchService.isResultsListSearch.next(false);
    }
  }

  /**
   * This is what highlights the current search term on keyboard navigation.
   * Only call this function if the link text is not null.
   * @param i #mainIndex.tabIndex
   * @param j #linkText.tabIndex
   * @param linkText
   */
  highlightSearchTerm(i: number, j: number, linkText: string): boolean {
    if (
      linkText != '' &&
      this.searchService.currentSectionIndex.getValue() === i &&
      this.searchService.linkIndex.getValue() === j
    ) {
      this.searchService.setCurrentSearchTerm(linkText);
      return true;
    }
    return false;
  }
}
