import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

interface ISubCat {
  displayName: string;
  navigationState: string;
  subCategories: ISubCat[];
}

@Component({
  selector: 'app-sub-category-navigation',
  styleUrls: [
    './sub-category-navigation.component.scss',
    '../category-navigation/category-navigation.component.scss',
  ],
  templateUrl: './sub-category-navigation.component.html',
})
export class SubCategoryNavigationComponent
  implements OnInit, CartridgeInterface
{
  @Input() data!: {
    categoryNavigationHeading: string;
    numSubCategories: number;
    displayImage: boolean;
    categoryViewAllLinkText: string;
    showCategoryViewAllLink: boolean;
    numCategories: number;
    categories: ISubCat[];
  };
  showAllCats: boolean;

  constructor() {}

  ngOnInit() {
    this.showAllCats = this.data.showCategoryViewAllLink;
  }

  onNavigateCategory(category: ISubCat) {}
}
