import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';

interface Data {
  url: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-see-on-site-dialog',
  templateUrl: './see-on-site-dialog.component.html',
})
export class SeeOnSiteDialogComponent implements OnInit {
  myAngularxQrCode: string;
  ocids: OcidItems = {};

  constructor(
    private dialogRef: MatDialogRef<SeeOnSiteDialogComponent>,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {}

  ngOnInit(): void {
    this.myAngularxQrCode = this.data.url;

    this.localization
      .getOCIDs([
        'arapp.see-jobsite-label',
        'arapp.agumented-reality-preview',
        'arapp.qr-code-label',
        'arapp.device-restrictions-label',
        'global.done-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
