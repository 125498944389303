import {
  Component,
  HostBinding,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { LayoutService } from '../../../service/layout.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ClearskyService } from '../../clearsky.service';
import { isPlatformBrowser } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { CSFilter } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { ScannerService } from '../../../service/scanner.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-cs-layout-sidebar',
  templateUrl: './cs-layout-sidebar.component.html',
  styleUrls: ['./cs-layout-sidebar.component.scss'],
})
export class CsLayoutSidebarComponent implements OnInit {
  @HostBinding('class.minimized') get minimizedClass() {
    return this.layoutService.clearskyNavCollapsed;
  }
  navBarEnabled$: Observable<boolean>;
  serialNumbers: UntypedFormControl = new UntypedFormControl(null);
  ocids: OcidItems = {};

  constructor(
    public layoutService: LayoutService,
    private router: Router,
    private clearskyService: ClearskyService,
    @Inject(PLATFORM_ID) private platformId: string,
    private confirmationService: ConfirmationDialogsService,
    private winRef: WindowRefService,
    private scanner: ScannerService,
    private currentUserService: CurrentUserService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    this.navBarEnabled$ = this.currentUserService.getUser().pipe(
      distinctUntilChanged(),
      map((user) => user.clearSky)
    );

    this.localization
      .getOCIDs([
        'clearsky.machine-filter-remove-label',
        'global.proceed',
        'global.cancel',
        'clearsky.equipment-serial-number-label',
      ])
      .subscribe((ocids) => {
        this.ocids = ocids;
      });
  }

  /**
   * Get smart fleet image based on minimized state of nav.
   */
  get csvSmartFleetImg(): string {
    return !this.layoutService.clearskyNavCollapsed ? '.jpg' : '_small.jpg';
  }

  /**
   * On search.
   */
  onSearch(): void {
    const serialNumbers = this.serialNumbers.value
      .replace(/\s/g, '')
      .split(',');
    if (isPlatformBrowser(this.platformId)) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: 'CSV Search',
        serialNumbers,
      });
    }
    if (serialNumbers.length > 1) {
      this.confirmationService
        .confirm(
          '',
          this.ocids['clearsky.machine-filter-remove-label'],
          this.ocids['global.proceed'],
          this.ocids['global.cancel']
        )
        .subscribe((result) => {
          if (result) {
            // Machines filter and redirect to list view
            this.router.navigate(['/clearsky/list']).then(() => {
              this.clearskyService.resetFilters();
              this.clearskyService.updateFilter(CSFilter.sn.key, serialNumbers);
              this.serialNumbers.reset();
            });
            return;
          }
        });
    } else {
      // Redirect to asset view
      this.router
        .navigate(['/clearsky/machine', serialNumbers.pop()])
        .then(() => {
          this.serialNumbers.reset();
        });
    }
  }

  /**
   * Open the barcode scanner
   */
  async openScanner() {
    (await this.scanner.scanSerialNum('ClearSky')).subscribe((data?) => {
      if (data) {
        this.serialNumbers.setValue(data);
        this.onSearch();
      }
    });
  }
}
