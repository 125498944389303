import { Component, ElementRef, Inject, ViewChild, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import Sortable from 'sortablejs';

interface WidgetData {
  key: string;
  label: string;
  active: boolean;
  row?: number;
  col?: number;
}

interface DialogData {
  widgets: WidgetData[];
}

@Component({
  selector: 'app-widget-grid-dialog',
  templateUrl: './widget-grid-dialog.component.html',
  styleUrls: ['./widget-grid-dialog.component.scss'],
})
export class WidgetGridDialogComponent implements OnInit {
  grid: ElementRef | undefined;
  ocids: OcidItems = {};
  @ViewChild('grid', { read: ElementRef }) set gridContent(
    content: ElementRef
  ) {
    if (content) {
      this.grid = content;
      this.initDrag();
    }
  }
  grid2: ElementRef | undefined;
  @ViewChild('grid2', { read: ElementRef }) set gridContent2(
    content: ElementRef
  ) {
    if (content) {
      this.grid2 = content;
      this.initDrag2();
    }
  }
  private gridInstance: Sortable;

  constructor(
    public dialogRef: MatDialogRef<WidgetGridDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.customize-label'
      ])
      .pipe(first())
      .subscribe();
  }

  /**
   * Track widget identify.
   * @param index
   * @param widget
   */
  trackWidget(index: number, widget: WidgetData): string {
    return widget.key;
  }

  /**
   * On save event listener.
   */
  onSave(): void {
    this.dialogRef.close(this.gridInstance.toArray());
  }

  /**
   * Init drag and drop functionality.
   * @private
   */
  private initDrag(): void {
    if (!this.grid) {
      return;
    }
    this.gridInstance = Sortable.create(this.grid.nativeElement, {
      forceFallback: true,
      group: {
        name: 'widgets',
      },
    });
  }

  /**
   * Init drag and drop functionality for inactive widgets.
   * @private
   */
  private initDrag2(): void {
    if (!this.grid2) {
      return;
    }
    Sortable.create(this.grid2.nativeElement, {
      forceFallback: true,
      group: {
        name: 'widgets',
      },
    });
  }
}
