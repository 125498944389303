import { IAddress } from '../../user/iaddress';
import { ICreditCard } from '../../user/icredit-card';

export interface IPaymentInfo {
  billTo: IAddress;
  poNum: string;
  paymentOption: string;
  creditCard?: ICreditCard;
}

export const PaymentOptions = {
  MyAccount: 'myAccount',
  CreditCard: 'creditCard',
  PayPal: 'payPal',
};
