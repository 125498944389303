import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-knowledge-article-auto-suggest',
  templateUrl: './knowledge-article-auto-suggest.component.html',
})
export class KnowledgeArticleAutoSuggestComponent
  implements CartridgeInterface
{
  @Input() data!: {
    flyOutDisplayOrientation: string;
    matchSections: {
      heading: string;
      typeAheadMatches: {
        flyoutPath: string;
        suggestion: string;
        url: string;
      }[];
    }[];
  };

  constructor() {}
}
