import { Component, Input, OnInit } from '@angular/core';
import { IRefinementBreadcrumb } from '../../../../contracts/IRefinementBreadcrumb';

@Component({
  selector: 'app-refinement-crumbs',
  styleUrls: [
    '../../../endeca-payload/base-breadcrumbs/base-breadcrumbs.component.scss',
  ],
  templateUrl: './refinement-crumbs.component.html',
})
export class RefinementCrumbsComponent implements OnInit {
  @Input() data!: {
    label: string;
    ancestors: IRefinementBreadcrumb[];
    removeAction: {
      contentPath: string;
      navigationState: string;
    };
  }[];
  breadcrumbs: {
    label: string;
    url: string;
  }[] = [];

  constructor() {}

  ngOnInit() {
    for (const crumb of this.data) {
      // Reverse the ancestors
      for (const ancestor of crumb.ancestors) {
        this.breadcrumbs.push({
          label: ancestor.label,
          url: ancestor.contentPath + ancestor.navigationState,
        });
      }

      // Now add the root label
      this.breadcrumbs.push({
        label: crumb.label,
        url:
          crumb.removeAction.contentPath + crumb.removeAction.navigationState,
      });
    }
  }
}
