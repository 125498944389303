import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { OcidItems } from '../../../../contracts/ocid-items';
import { first } from 'rxjs/operators';
import { ClearskyService } from '../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  CSFilter,
  CSFilterExistsDisabled,
  CSFilterLabelOcids,
  CSFilterSort,
  CSFilterTransValByLeg,
  CSFilterTransValByOcids,
  CSFilterType,
  CSFilterValueOcids,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CSLegend } from '../../../../contracts/clearsky/clearsky-legend';
import { LocalizationService } from '../../../../shared/localization/localization.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
})
export class FilterMenuComponent implements OnInit, OnChanges {
  @Input() filterKey: string;
  @Input() selectedValues: unknown[] = [];
  filterForm: UntypedFormGroup = new UntypedFormGroup({
    values: new UntypedFormArray([]),
  });
  filterOptions: { key: unknown; value: unknown }[] = [];
  ocids: OcidItems = {};
  private legend: CSLegend = {};

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );

    this.localization
      .getOCIDs([...CSFilterValueOcids, ...CSFilterLabelOcids])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterKey && changes.filterKey.currentValue) {
      this.updateFilterOptions();
    }
  }

  updateFilterOptions(): void {
    // Reset form array
    const filterData = Object.values(CSFilter).find(
      (f) => f.key === this.filterKey
    );
    this.valuesFormArray.clear();
    this.filterOptions = [];

    // Let's set the available options
    this.clearskyService
      .getValuesByFilter(this.filterKey)
      .pipe(first())
      .subscribe((filters) => {
        this.filterOptions = filters
          .map((v) => {
            return {
              key: v,
              value: filterData
                ? filterData.disFromLeg
                  ? CSFilterTransValByLeg(this.filterKey, v, this.legend)
                  : CSFilterTransValByOcids(this.filterKey, v, this.ocids)
                : v,
            };
          })
          .sort((a, b) => CSFilterSort(this.filterKey, a.value, b.value));

        this.filterOptions.forEach((opt) => {
          this.valuesFormArray.push(
            new UntypedFormControl(this.selectedValues.includes(opt.key))
          );
        });

        // Does this filter have the capability of checking if it exists
        if (!CSFilterExistsDisabled.includes(this.filterKey)) {
          this.filterOptions.push({
            key: CSFilterType.NULL,
            value: 'N/A',
          });
          this.valuesFormArray.push(
            new UntypedFormControl(
              this.selectedValues.includes(CSFilterType.NULL)
            )
          );
        }
      });
  }

  /**
   * Update current selected filter values.
   */
  onUpdateFilterValues(): void {
    // Did they change any values?
    if (!this.filterKey || !this.valuesFormArray.dirty) {
      return;
    }

    // Get an array of values based on which ones are checked or not
    const newValues = this.valuesFormArray.controls.reduce(
      (prev, control, index) => {
        if (control.value) {
          prev.push(this.filterOptions[index].key);
        }

        return prev;
      },
      [] as unknown[]
    );

    // Fire off value changes
    this.clearskyService.updateFilter(this.filterKey, newValues);
  }

  /**
   * Get the form array for the values that are selected.
   */
  get valuesFormArray(): UntypedFormArray {
    return this.filterForm.controls.values as UntypedFormArray;
  }
}
