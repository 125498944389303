import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { JlgLocation } from '../../../contracts/clearsky/jlg-location';
import {
  Machine,
  MachineAccessRestrictionIcons,
} from '../../../contracts/clearsky/machine/machine.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, merge, Subscription } from 'rxjs';
import { debounceTime, first, mergeMap, tap } from 'rxjs/operators';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import { OcidItems } from '../../../contracts/ocid-items';
import { MachineFilterSelection } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { ClearskyService } from '../../clearsky.service';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from '../../../service/layout.service';
import { BingMapsService } from '../../../service/bing-maps.service';
import { ClearskyMapService } from '../clearsky-map.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { Widgets } from '../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { isPlatformBrowser } from '@angular/common';
import { MachineDetailsDialogComponent } from '../../machines/machine-details-dialog/machine-details-dialog.component';
import { MachineDialogConfig } from '../../../contracts/clearsky/machine/machine.dialog.config';
import { DistanceTypes } from '../../../shared/bing-maps';
import {
  clusterPinOuterRadiusWidth,
  clusterPinRadius,
  getClusterPin,
  getPushpinByModelGroup,
} from '../../../contracts/clearsky/map-pins';
import * as cloneDeep from 'lodash.clonedeep';
import 'bingmaps';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';
import { CSFilter } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../contracts/clearsky/cs-machines-request';
import { MxPlaceholderImg } from '../../../contracts/clearsky/machine/machine.images';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

export interface IMapPinClickEvent {
  target: {
    getLocation: () => Microsoft.Maps.Location;
    metadata: {
      network?: SiteNetwork;
      landmark?: JlgLocation;
      machine?: Machine;
    };
  };
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-map-content',
  templateUrl: './map-content.component.html',
  styleUrls: ['./map-content.component.scss'],
})
export class MapContentComponent implements OnInit, AfterViewInit {
  @ViewChild('map') mapEl: ElementRef;
  isMapView = true; // Default to true until site networks comes back
  showMap = false;
  showSiteMap = false;
  visibleMachines: Machine[] = [];
  isPinView = false;
  bingMap: Microsoft.Maps.Map;
  selectedMachine: Machine | undefined;
  selectedLandmark: JlgLocation | undefined;
  selectedTargetLocation: Microsoft.Maps.Location | undefined;
  isLandmarksToggled = false;
  ocids: OcidItems = {};
  accessRestrictionIcons = MachineAccessRestrictionIcons;
  collapsed = true;
  placeholderImg = MxPlaceholderImg;
  private machines: Machine[] = [];
  private clusterPinLayer: Microsoft.Maps.ClusterLayer;
  private pinLayer: Microsoft.Maps.Layer;
  private landmarkLayer: Microsoft.Maps.Layer;
  private filters: MachineFilterSelection[];
  private distanceType: string;
  private landmarks: JlgLocation[] = [];
  private highlightedPin: Microsoft.Maps.Pushpin | undefined;
  legend: CSLegend = {};
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private bingMaps: BingMapsService,
    private clearskyMapService: ClearskyMapService,
    private localization: LocalizationService,
    private gAService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: string
  ) { }

  ngOnInit(): void {
    this.subs = this.localization
      .getOCIDs([
        'clearsky.locate-this-machine-label',
        'clearsky.collapse-label',
        'clearsk.expand-label',
        'clearsky.machine-onsite-label',
        'clearsky.local-machine-connection-label',
        'clearsky.unassociated-label',
        'clearsky.network-detail-label',
        'clearsky.details-label',
        'header.chat-link',
        'clearsky.filter-machines-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    // Make sure site networks is in their widget list (along with alerts since it's dependent)
    this.clearskyService.addWidgets([Widgets.ALERTS]);
  }

  ngAfterViewInit(): void {
    const loadMachines = (page: number) => {
      // Now start chunking requests
      return this.clearskyService
        .getDataByWidgetKey(CsRequestKeys.mapView, {
          paginate: true,
          pageSize: 2000,
          pageNum: page,
        })
        .pipe(first());
    };

    // See which view we should load
    /**
    this.subs.add(
      this.route.url.pipe(first()).subscribe((url) => {
        const urlEnd = url.pop();
        this.onToggleMapView(
          (urlEnd && urlEnd.path === 'map') ?? this.isMapView
        );
      })
    );
     **/

    this.subs.add(
      combineLatest([
        this.bingMaps.initialize().pipe(first()),
        this.clearskyService.currentFilters$,
      ])
        .pipe(
          debounceTime(0),
          mergeMap(() => {
            return combineLatest([
              this.clearskyService.getCachedLandmarks$.pipe(first()),
              this.clearskyService.legendRef$.pipe(first()),
              loadMachines(0).pipe(first()),
            ]);
          })
        )
        .subscribe(([landmarks, legend, page]) => {
          this.landmarks = landmarks;
          this.legend = legend || {};

          if (isPlatformBrowser(this.platformId)) {
            this.bingMap || this.initMap();

            // Determine how many requests we need to chunk together
            this.clearMap();

            const obs = [
              ...[...new Array(page ? page.totalPages : 0)].keys(),
            ].map((num) => {
              return loadMachines(num + 1).pipe(
                tap((machines) => {
                  this.machines = [
                    ...this.machines,
                    ...((machines && (machines.machines as Machine[])) || []),
                  ];
                })
              );
            });

            merge(...obs).subscribe((res) => {
              this.loadMainMap((res && (res.machines as Machine[])) || []);
              this.zoomToBounds(
                this.clusterPinLayer
                  .getPushpins()
                  .map((pin) => pin.getLocation())
              );

              this.determineVisibleMachines();
            });
          }
        })
    );
  }

  /**
   * Filter to visible machines on map.
   */
  filterToVisibleMachines(): void {
    this.gAService.eventEmitter('clearsky_click', 'map', 'filterToVisibleMachines');
    this.clearskyService.updateFilter(
      CSFilter.sn.key,
      this.visibleMachines.map((machine) => machine.sn)
    );
  }

  /**
   * Open more details slide-in for this machine.
   * @param machine
   * @protected
   */
  openMoreDetails(machine: Machine): void {
    this.gAService.eventEmitter('clearsky_click', 'map', 'open_more_details', 'serial_number', machine.sn);
    this.dialog.open(MachineDetailsDialogComponent, {
      ...MachineDialogConfig,
      data: {
        sn: machine.sn,
      },
    });
  }

  /**
   * Open chat.
   * @protected
   */
  openChat(): void {
    this.layoutService.showChatMenu = true;
  }

  /**
   * Get directions to machine.
   * @param machine
   * @protected
   */
  getDirections(machine: Machine): void {
    this.bingMaps.getDirections(machine.loc?.lat, machine.loc?.lng);
  }

  /**
   * On toggle map view.
   */
  onToggleMapView(isMapView: boolean): void {
    // Only update shows if false so it doesn't have to recreate map instances
    if (!this.showMap && isMapView) {
      this.showMap = true;
    }

    if (!this.showSiteMap && !isMapView) {
      this.showSiteMap = true;
    }

    // Now update the toggle no matter what
    this.isMapView = isMapView;

    if (isPlatformBrowser(this.platformId) && this.bingMap) {
      this.loadMap();
    }
  }

  /**
   * Zoom to specific asset on map.
   * @param machine
   */
  zoomToAsset(machine: Machine): void {
    this.gAService.eventEmitter('clearsky_click', 'map', 'zoomToAsset', 'serial_number', machine.sn);
    this.clearskyMapService.machinePinClicked(machine);

    this.zoomToBounds([
      new Microsoft.Maps.Location(machine.loc.lat, machine.loc.lng),
    ]);

    // Highlight pin instance on map
    if (!this.isMapView) {
      // They are on site network map view
      const prims = this.pinLayer.getPrimitives();
      this.highlightedPin = prims.find((prim, index) => {
        const pin = prims[index] as Microsoft.Maps.Pushpin;
        const loc = pin.getLocation();
        return (
          loc.latitude === machine.loc?.lat &&
          loc.longitude === machine.loc?.lng
        );
      }) as Microsoft.Maps.Pushpin;
    } else {
      // They are on main map view
      this.highlightedPin = this.clusterPinLayer.getPushpins().find((pin) => {
        const loc = pin.getLocation();
        return (
          loc.latitude === machine.loc?.lat &&
          loc.longitude === machine.loc?.lng
        );
      });
    }

    // Did we find a pin?
    if (this.highlightedPin) {
      // Also open up infobox for it
      this.selectedMachine = machine;
      this.selectedTargetLocation = this.highlightedPin.getLocation();
    }
  }

  /**
   * Reset info box state.
   */
  onInfoBoxClosed(): void {
    this.selectedMachine = undefined;
    this.selectedLandmark = undefined;
    this.selectedTargetLocation = undefined;
  }

  /**
   * Toggle landmark pins.
   */
  onLandmarkToggle(): void {
    this.isLandmarksToggled = !this.isLandmarksToggled;

    if (this.isLandmarksToggled) {
      this.addLandmarkPins();
    } else {
      this.bingMap.layers.remove(this.landmarkLayer);
    }
  }

  private initMap(): void {
    this.bingMap = new Microsoft.Maps.Map(this.mapEl.nativeElement, {
      showMapTypeSelector: false,
      liteMode: true,
      center: new Microsoft.Maps.Location(39.393486, -98.100769),
      zoom: 3,
    });

    // Todo: Will need to read user's default unit (metric vs imperial) Set to miles by default for now
    this.distanceType = DistanceTypes.MILES;

    // Load the Spatial Math modules.
    Microsoft.Maps.loadModule('Microsoft.Maps.SpatialMath');
    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering');

    // Add zoom handler
    Microsoft.Maps.Events.addHandler(
      this.bingMap,
      'viewchangeend',
      this.zoomChanged.bind(this)
    );
  }

  /**
   * Load site networks map.
   * @protected
   */
  private loadMap(): void {
    // this.isMapView ? this.loadMainMap() : this.loadNetworkMap();
  }

  private clearMap(): void {
    this.machines = [];
    this.bingMap.layers.clear();

    // Create the cluster layer and set it to an empty set
    Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      this.clusterPinLayer = new Microsoft.Maps.ClusterLayer([], {
        clusteredPinCallback: this.createCustomClusteredPin.bind(this),
        gridSize: 100,
      });

      // Add landmarks
      this.addLandmarkPins();

      // Now insert the layer on the map
      this.bingMap.layers.insert(this.clusterPinLayer);
    });
  }

  /**
   * Load layers for the main map.
   * @private
   */
  private loadMainMap(machines: Machine[]): void {
    const pins = machines.reduce((prev, machine) => {
      if (!machine.loc) {
        return prev;
      }

      const loc = new Microsoft.Maps.Location(machine.loc.lat, machine.loc.lng);
      const pushpin = new Microsoft.Maps.Pushpin(loc, {
        icon: getPushpinByModelGroup(machine.mgroup),
        anchor: new Microsoft.Maps.Point(10, 10),
      });

      // Store some metadata with the pushpin so we can show info box
      pushpin.metadata = {
        machine: machine,
      };

      // Add a click event handler to the pushpin.
      Microsoft.Maps.Events.addHandler(
        pushpin,
        'click',
        this.machinePushpinClicked.bind(this)
      );

      // Now store it
      prev.push(pushpin);
      return prev;
    }, [] as Microsoft.Maps.Pushpin[]);

    const current = this.clusterPinLayer.getPushpins();
    this.clusterPinLayer.setPushpins([...current, ...pins]);
  }

  /**
   * Zoom to a set of bounds.
   *
   * @param bounds
   * @param padding
   * @private
   */
  private zoomToBounds(bounds: Microsoft.Maps.Location[], padding = 50): void {
    // Set default view to machines
    this.bingMap.setView({
      bounds: Microsoft.Maps.LocationRect.fromLocations(
        bounds.length
          ? bounds
          : [
            new Microsoft.Maps.Location(90, 180),
            new Microsoft.Maps.Location(-90, -180),
          ]
      ),
      padding,
    });
  }

  /**
   * Add landmark pushpins to map.
   * @protected
   */
  private addLandmarkPins(): void {
    if (!this.isLandmarksToggled) {
      return;
    }

    this.landmarkLayer = new Microsoft.Maps.Layer();

    // Add all landmarks
    this.landmarks.forEach((landmark) => {
      const loc = new Microsoft.Maps.Location(
        landmark.latitude,
        landmark.longitude
      );

      const pin = new Microsoft.Maps.Pushpin(loc, {
        icon: '/assets/img/clearsky/PushpinJLG.svg',
        anchor: new Microsoft.Maps.Point(10, 10),
      });

      pin.metadata = { landmark };

      // Add a click event handler to the pushpin.
      Microsoft.Maps.Events.addHandler(
        pin,
        'click',
        this.landmarkClicked.bind(this)
      );

      this.landmarkLayer.add(pin);
    });

    this.bingMap.layers.insert(this.landmarkLayer);
  }

  /**
   * On zoom change.
   * @protected
   */
  private zoomChanged(): void {
    this.determineVisibleMachines();
    this.setGridSize();
  }

  /**
   * Sets grid size according to zoom level.
   */
  setGridSize() {
    const zoomLevel = this.bingMap.getZoom();
    const gridSize = zoomLevel > 10 ? 10 : 100;
    this.clusterPinLayer?.setOptions({ gridSize })
  }

  /**
   * Determine visible machines for right rail.
   * @private
   */
  private determineVisibleMachines(): void {
    // Get the bounding box of the current map view.
    const bounds = this.bingMap.getBounds();

    // Now loop through the pins
    this.visibleMachines = this.machines
      .filter((machine) => {
        if (!machine.loc) {
          return;
        }
        // Check if machine is in bounds
        return bounds.contains(
          new Microsoft.Maps.Location(machine.loc.lat, machine.loc.lng)
        );
      })
      .sort();
  }

  /**
   * Open info box on map with machine data.
   * @param e
   * @protected
   */
  private machinePushpinClicked(e: IMapPinClickEvent): void {
    this.pushpinClicked(e, true);

    // If on site network map, highlight it under appropriate network
    if (!this.isMapView && e.target.metadata.machine) {
      this.clearskyMapService.machinePinClicked(e.target.metadata.machine);
    }
  }

  /**
   * Open info box on map with machine data.
   * @param e
   * @protected
  */
  private landmarkClicked(e: IMapPinClickEvent): void {
    this.pushpinClicked(e, false);
  }

  /**
   * Pushpin click event.
   * @param e
   * @private
  */
  private pushpinClicked(e: IMapPinClickEvent, isMachine: boolean): void {
    this.selectedLandmark = e.target.metadata.landmark
      ? cloneDeep(e.target.metadata.landmark)
      : undefined;
    this.selectedMachine = e.target.metadata.machine
      ? cloneDeep(e.target.metadata.machine)
      : undefined;
    this.selectedTargetLocation = e.target.getLocation();

    this.gAService.eventEmitter(
      'clearsky_click',
      'map',
      'pushpin_click' + (isMachine ? ' machine' : ' landmark'),
      (isMachine ? 'serial_number' : null),
      (isMachine ? e.target.metadata.machine.sn : null)
    );
  }

  zoomToCluster(cluster) {
    const locations = cluster.containedPushpins.map((pin) => {
      return pin.getLocation();
    });

    var bounds = Microsoft.Maps.LocationRect.fromLocations(locations);
    this.bingMap.setView({ bounds: bounds });
  }

  /**
   * Create machine cluster view.
   * @param cluster
   * @private
   */
  private createCustomClusteredPin(cluster: Microsoft.Maps.ClusterPushpin) {
    const radius = clusterPinRadius;
    // prettier-ignore
    const textOffset = (radius / 2) + clusterPinOuterRadiusWidth;
    // Customize the clustered pushpin using the generated SVG and anchor on its center.
    cluster.setOptions({
      icon: getClusterPin(),
      anchor: new Microsoft.Maps.Point(radius, radius),
      textOffset: new Microsoft.Maps.Point(0, radius - textOffset),
    });
    Microsoft.Maps.Events.addHandler(cluster, 'click', () => this.zoomToCluster(cluster));
  }
}
