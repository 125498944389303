import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ScannerService {
  constructor(public dialog: MatDialog) {}

  /**
   * Scan serial number using barcode scanner
   * @param {string} scanSource
   */
  scanSerialNum(scanSource: string) {
   return import('../core/scanner/scanner.component').then((m) => {
    const scannnerModal = this.dialog.open(m.ScannerComponent, {
      width: '450px',
      data: {
        source: scanSource,
      },
    });
    return scannnerModal.afterClosed();
   });
  }
}
