import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  HistoricSelection,
  Machine,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

interface DialogData {
  machine: Machine;
}

@Component({
  selector: 'app-machine-utilization-dialog',
  templateUrl: './machine-utilization-dialog.component.html',
})
export class MachineUtilizationDialogComponent implements OnInit {
  historic90Days = HistoricSelection.DAYS_90;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<MachineUtilizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.day-utilization-label',
        'clearsky.machine-utilization-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
