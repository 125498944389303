import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { UntilDestroy } from '@ngneat/until-destroy';

enum RequestTypes {
  STRUCTURAL = 'Structural',
  STAR = 'S.T.A.R.',
  SERIAL = 'Serial (All Other Placards)',
  OTHER = 'Other'
}

enum RequestMode {
  NEW = 'New',
  REPLACEMENT = 'Replacement',
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-request-type-form',
  templateUrl: './request-type-form.component.html'
})
export class RequestTypeFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() requestTypeForm: UntypedFormGroup;
  @Output() requestTypeFormSubmittedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestTypeChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  requestTypeFormValid: boolean = false;
  maxDate: Date = new Date();
  requestMode = RequestMode;

  private requestTypeOptOriginal = [RequestTypes.STRUCTURAL, RequestTypes.STAR, RequestTypes.SERIAL, RequestTypes.OTHER];
  requestTypeOpt: string[];
  manufacturedDateConst = +new Date('2023/12/31');
  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.requestTypeForm.addControl('neworReplacementForm', this.formBuilder.control('', Validators.required));
  }

  /**
   * Event fired when the request type button toggle changes. Adds various
   * form controls and groups based on its value.
   * @param {MatButtonToggleChange} event
   */
  requestTypeChanged(event: MatButtonToggleChange) {
    this.requestTypeChangedEvent.emit(event.value);
    if (!this.requestTypeForm.get('otherType')) {
      this.requestTypeForm.addControl('otherType', this.formBuilder.control('', Validators.required));
      this.requestTypeForm.addControl('manufactureDate', this.formBuilder.control('', Validators.required));
    }
    if (event.value === RequestMode.NEW) {
      this.requestTypeOpt = this.requestTypeOptOriginal.slice(0, 2);
    } else if (event.value === RequestMode.REPLACEMENT) {
      this.requestTypeOpt = this.requestTypeOptOriginal;
    }
  }
  /**
   * Event fired when the provide assistance dropdown select has changed. Adds various
   * form controls and groups based on its value.
   * @param {MatSelectChange} event
   */
  placardTypeChanged(event: MatSelectChange) {
    if (event.value === 'Other' && !this.requestTypeForm.get('explain')) {
      this.requestTypeForm.addControl('explain', this.formBuilder.control('', Validators.required));
    } else {
      this.requestTypeForm.removeControl('explain');
    }
  }
  /**
   * Emits the FormGroupDirective if the form is valid.
   */
  submitrequestTypeForm() {
    if (this.requestTypeForm.valid) {
      this.requestTypeFormSubmittedEvent.emit();
    }
  }

  getErrorMessage(): string {
    if (this.requestTypeForm.get('neworReplacementForm').value === RequestMode.NEW &&
      this.requestTypeForm.get('otherType').value === RequestTypes.STAR &&
      this.requestTypeForm.get('manufactureDate').value &&
      +this.requestTypeForm.get('manufactureDate').value > this.manufacturedDateConst) {
      return this.ocids["placard-request.structural-placard-error-message"];
    }
    return '';
  }
}
