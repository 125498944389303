import {Directive, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChanges,} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {CollapseStack} from './collapse-stack';

/**
 * A directive to provide a simple way of hiding and showing elements on the page.
 */
@Directive({selector: '[collapse]', exportAs: 'collapse'})
export class Collapse implements OnInit, OnChanges {

  /**
   * If `true`, will collapse the element or show it otherwise.
   */
  @Input('collapse') collapsed = false;

  /**
   * Whether or not to show the black overlay.
   */
  @Input() showOverlay = false;

  @Output() collapseChange = new EventEmitter<boolean>();

  /**
   * An event emitted when the collapse element is shown, after the transition.
   */
  @Output() shown = new EventEmitter<void>();

  /**
   * An event emitted when the collapse element is hidden, after the transition.
   */
  @Output() hidden = new EventEmitter<void>();

  constructor(private _element: ElementRef,
              private _collapseStack: CollapseStack,
              @Inject(PLATFORM_ID) private platformId: string,
              @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.runOnBrowser(() => {
      this._element.nativeElement.classList.add('collapse');
      this._runTransition(this.collapsed, false);
    });
  }

  ngOnChanges({collapsed}: SimpleChanges): void {
    if (!collapsed.firstChange) {
      this._runTransition(this.collapsed);
    }
  }

  /**
   * Triggers collapsing programmatically.
   *
   * If there is a collapsing transition running already, it will be reversed.
   * If the animations are turned off this happens synchronously.
   */
  toggle(open: boolean = this.collapsed): void {
    this.collapsed = !open;
    this.collapseChange.next(this.collapsed);
    this._runTransition(this.collapsed);
  }

  /**
   * Triggers transitioning of collapsable element.
   *
   * @param collapsed
   * @param emitEvent
   * @private
   */
  private _runTransition(collapsed: boolean, emitEvent = true): void {
    this.runOnBrowser(() => {
      if (collapsed) {
        this._element.nativeElement.classList.remove('show');
        if (this.showOverlay) {
          this._collapseStack.remove(this);
        }

        if (emitEvent) {
          this.hidden.emit();
        }
      } else {
        this._element.nativeElement.classList.add('show');
        // If it has an overlay, add it to the stack so we can dismiss all and hide overlay
        if (this.showOverlay) {
          this._collapseStack.add(this);
        }

        if (emitEvent) {
          this.shown.emit();
        }
      }
    });
  }

  /**
   * Only execute code on browser.
   * @param callback
   */
  runOnBrowser(callback: Function): void {
    if (isPlatformBrowser(this.platformId)) {
      callback();
    }
  }
}
