import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HistoricSelection } from '../../../../../contracts/clearsky/machine/machine.dto';
import { EnvelopeProfileBoomChart } from '../../../charts/envelope-profile-use/envelope-profile-boom-chart';
import { MxMGroupImg } from '../../../../../contracts/clearsky/machine/machine.images';
import {
  CsAggEnvHist,
  CsZoneHistoricItem,
  CsZoneItem,
} from '../../../../../contracts/clearsky/agg-data';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-envelope-profile-boom-lift-fleet',
  templateUrl: './envelope-profile-boom-lift-fleet.component.html',
})
export class EnvelopeProfileBoomLiftFleetComponent
  implements AfterViewInit, OnChanges {
  @Input() data: CsZoneHistoricItem;
  @Input() historic = HistoricSelection.HOURS_24;
  @Input() showPercentage: boolean;
  @ViewChild('chart') chartEl: ElementRef;
  image = MxMGroupImg.boomLifts;
  historicData: CsZoneItem;
  private plot;
  ocids: OcidItems = {};
  constructor(private localization: LocalizationService) { }
  ngAfterViewInit(): void {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.average-label',
      ])
      .pipe(first())
      .subscribe();
    // Set zone data for active bars
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.historic && !changes.historic.firstChange || changes.data && !changes.data.firstChange)) {
      this.plot.redraw();
    }
  }

  /**
   * Create chart for scissor lifts.
   * @private
   */
  private createChart(): void {
    this.plot = EnvelopeProfileBoomChart.createInstance().createChart(
      this.chartEl,
      this.getZoneData.bind(this),
      {
        typeDescription: 'Boom Lifts',
        tooltip: (zone: number) =>
          `On average your fleet has spent ${this.historicData.avg['z' + zone]
          } hours in this zone.`,
      }
    );
  }

  /**
   * Get zone data.
   * @private
   */
  private getZoneData(): number[] {
    // Set data based on historic selection
    this.historicData = this.data[CsAggEnvHist[this.historic]];
    return Object.values(this.historicData.avg).splice(0, 2);
  }
}
