import { HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Injectable,
  NgModule,
  APP_INITIALIZER,
  PLATFORM_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { isPlatformBrowser } from '@angular/common';
// Webpack will lazily load this import so it does not break the server side rendering process.
// Also, HammerModule does not automatically import this script, which is why we have to do it manually here.
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { HeartbeatService } from './service/heartbeat/heartbeat.service';
import { NotificationService } from './service/notification/notification.service';
import { PartsService } from './service/product/parts.service';
import { LocalizationService } from './shared/localization/localization.service';
import { SharedModule } from './shared/shared.module';
import { BrowserStateInterceptor } from './shared/universal/browser-state.interceptor';
import { BingMapsService } from './service/bing-maps.service';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { PayPalRedirectComponent } from './paypal-redirect/paypal-redirect.component';
import { IconModule } from './icon/icon.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ChatComponent } from './core/header/chat/chat.component';
import { PwaService } from './service/pwa.service';
import { ScannerService } from './service/scanner.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
class OLEErrorHandler implements ErrorHandler {
  handleError(error: Error | HttpErrorResponse) {
    console.error('Global error handler: ', error);
  }
}
@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    PayPalRedirectComponent,
    ChatComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ole-app' }),
    // BrowserTransferStateModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    IconModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    HeartbeatService,
    LocalizationService,
    NotificationService,
    PartsService,
    ScannerService,
    BingMapsService,
    CookieService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: (platformId: Object) => {
        // Check if the application is running in a browser environment
        if (isPlatformBrowser(platformId)) {
          return Sentry.createErrorHandler({
            showDialog: false,
          });
        } else {
          // Return a noop ErrorHandler for SSR
          return new ErrorHandler();
        }
      },
      deps: [PLATFORM_ID],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      deps: [PwaService],
      useFactory: (pwaService: PwaService) => () => {
        return new Promise<void>((resolve, reject) => {
          pwaService.generateManifest();
          resolve();
        });
      },
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // https://docs.sentry.io/platforms/javascript/guides/angular/
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(trace: Sentry.TraceService) {}
}
