import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-slot',
  templateUrl: './page-slot.component.html'
})
export class PageSlotComponent implements OnInit {
  @Input('data') data: {
    contents: object[];
  };
  @Input('url') payloadUrl: string;

  constructor() {}

  ngOnInit() {

  }
}
