import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-bulletin-board-messages',
  templateUrl: './bulletin-board-messages.component.html',
})
export class BulletinBoardMessagesComponent
  implements OnInit, CartridgeInterface
{
  @Input() data: {
    messages: object[];
  };
  ocids: {};

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'home.message-board-title',
        'home.message-board-read-more',
        'home.message-board-read-less',
      ])
      .subscribe();
    // Subscribe to any ocid changes.
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }
}
