import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IRequestTokenDto } from '../../../contracts/recovery/dto/irequest-token-dto';
import { IVerifyTokenDto } from '../../../contracts/recovery/dto/iverify-token-dto';
import { IVerifyTokenUpdatePassDto } from '../../../contracts/recovery/dto/iverify-token-update-pass-dto';
import { IVerifyTokenAnswerDto } from '../../../contracts/recovery/dto/iverify-token-answer-dto';

@Injectable()
export class ForgotUnlockWrapperService {
  public isForgotPassword: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  /**
   * Request password recovery email with token.
   *
   * @param {IRequestTokenDto} requestTokenDto
   */
  getRecoveryToken(requestTokenDto: IRequestTokenDto) {
    return this.http.post(
      environment.ATG_BASE_URL + '/recovery/sendPasswordRecoveryEmail',
      requestTokenDto
    );
  }

  /**
   * Verify the token sent for unlock or forgot password.
   *
   * @param {IVerifyTokenDto} recoveryTokenDto
   * @returns {Observable<IVerifyTokenResponse>}
   */
  verifyRecoveryToken(recoveryTokenDto: IVerifyTokenDto) {
    return this.http.post(
      environment.ATG_BASE_URL + '/recovery/verifyRecoveryToken',
      recoveryTokenDto
    );
  }

  /**
   * Verify security token and answer on unlock or forgot password.
   *
   * @param {IVerifyTokenAnswerDto} tokenAnswerDto
   * @returns {Observable<IVerifyTokenAnswerResponse>}
   */
  verifySecurityTokenAndAnswer(tokenAnswerDto: IVerifyTokenAnswerDto) {
    return this.http.post(
      environment.ATG_BASE_URL +
        '/recovery/verifyRecoveryTokenAndSecurityAnswer',
      tokenAnswerDto
    );
  }

  /**
   * Verify recovery token and updated password on forgot password.
   *
   * @param {IVerifyTokenUpdatePassDto} tokenPassDto
   */
  verifySecurityTokenAndUpdatePassword(
    tokenPassDto: IVerifyTokenUpdatePassDto
  ) {
    return this.http.post(
      environment.ATG_BASE_URL +
        '/recovery/verifyRecoveryTokenAndUpdatePassword',
      tokenPassDto
    );
  }
}
