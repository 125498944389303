import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-content-slot-footer',
  templateUrl: './content-slot-footer.component.html'
})
export class ContentSlotFooterComponent implements OnInit, CartridgeInterface {
  @Input('data')
  data: {
    contents: object[];
  };

  constructor() {}

  ngOnInit() {
    //
  }

}
