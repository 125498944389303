import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMachineLastReportedIn24Hours'
})
export class IsMachineLastReportedIn24HoursPipe implements PipeTransform {
  transform(machineLRT: Date): boolean {
    const lastReportedIn = (+new Date() - +new Date(machineLRT)) / 3600000;
    return lastReportedIn > 24;
  }
}