import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-cs-nav-toggle',
  templateUrl: './cs-nav-toggle.component.html',
  styleUrls: ['./cs-nav-toggle.component.scss'],
})
export class CsNavToggleComponent {
  @HostBinding('class.collapsed') @Input() collapsed: boolean = false;
}
