import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {INavMenu} from '../../../contracts/user/inav-menu';
import {MenuService} from '../../../service/user/menu.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {LayoutService} from '../../../service/layout.service';
import {isPlatformBrowser} from '@angular/common';

@UntilDestroy({checkProperties: true})
@Component({
  selector: '[app-service]',
  templateUrl: './service.component.html'
})
export class ServiceComponent implements OnInit {
  salesAndServiceOptions: INavMenu;
  public platformBrowser: boolean = isPlatformBrowser(this.platformId);
  public serviceMenuCollapsed = true;

  @ViewChild('collapse') set collapse(serviceMenu) {
    // Reset the nav and hamburger state if the directive has collapsed.
    if (serviceMenu) {
      serviceMenu.collapseChange.subscribe((hasChanged) => {
        if (hasChanged) {
          this.serviceMenuCollapsed = true;
          this.layoutService.showMenuType();
        }
      });
    }
  }

  constructor(private menuService: MenuService,
              public layoutService: LayoutService,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit() {
    this.menuService.menus$.pipe(
      mergeMap((menus) => menus ? this.menuService.getMenuByUxKey('sales-and-service-menu') : of(null))
    ).subscribe((menu: INavMenu) => {
      if (menu) {
        this.salesAndServiceOptions = menu;
      }
    });

    this.layoutService.serviceMenuCollapsed$.subscribe((collapsed) => {
      if (collapsed) {
        this.serviceMenuCollapsed = true;
      }
    });
  }

  openServiceMenu() {
    this.layoutService.showMenuType('service');
  }
}
