import { Injectable } from '@angular/core';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
} from 'rxjs/operators';
import { IUser, PardotUser } from '../../contracts/user/iuser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { ICustomerSwitch } from '../../contracts/user/icustomer-switch';

@Injectable()
export class CurrentUserService {
  user: IUser;
  _stayLoggedInDialgState: Subject<boolean> = new Subject<boolean>();
  _userSubject: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  userSubject: Observable<IUser> = this._userSubject.pipe(
    distinctUntilChanged((prev, curr) => {
      if (prev === null) {
        return true;
      }

      return (
        prev.email === curr.email &&
        prev.customerNumber === curr.customerNumber &&
        prev.locale === curr.locale
      );
    })
  );
  proxyRoles: string[] = ['CSRProxy', 'EnterpriseUserProxy'];
  proxyUser$: Observable<ICustomerSwitch> = this.userSubject.pipe(
    map((user) => {
      if (user) {
        // Check if they are proxying
        if (user.inProxy) {
          return {
            ...user,
            status: 'success',
            companyName: user.companyName,
            companyNumber: user.customerNumber,
          };
        }
      }

      return null;
    })
  );

  constructor(private http: HttpClient) {}

  /**
   * Get current user.
   *
   * @param {boolean} [clearPayloadCache=false]
   * @returns {Observable<IUser>}
   */
  getUser(clearPayloadCache: boolean = false): Observable<IUser> {
    // Create request headers.
    const headers = {};
    // If we need to clear the payload cache for the request, add that header to the request.
    if (clearPayloadCache) {
      headers['clear-payload-cache'] = 'true';
    }
    const httpOptions = {
      headers: new HttpHeaders(headers),
    };

    // Make API request.
    return this.http.get(`${environment.apiUrl}/currentUser`, httpOptions).pipe(
      map((res) => {
        const response = <IUser>res;

        this.user = response;
        this._userSubject.next(this.user);
        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  /**
   * Patch user profile.
   * @param {object} user
   * @returns {Observable<IUser>}
   */
  updateUserProfile(user: object) {
    return this.http
      .patch(environment.ATG_BASE_URL + '/currentUser', user, {
        headers: new HttpHeaders()
          .set('Accept', 'application/json')
          .set('Content-Type', 'application/json'),
      })
      .pipe(
        map((res: IUser) => {
          const _user = Object.assign({ ...this._userSubject.getValue() }, res);
          this._userSubject.next(_user);
          return _user;
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Patch user profile for new users.
   * @param {object} address
   * @returns {Observable<IUser>}
   */
  updateNewUserProfile(address: object) {
    return this.http
      .post(
        environment.ATG_BASE_URL + '/currentUser/shipToOrBillToCreate',
        { ...address, clientId: environment.CLIENT_ID },
        {
          headers: new HttpHeaders()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json'),
        }
      )
      .pipe(
        map((res: ICustomerSwitch) => {
          const _user = Object.assign({ ...this._userSubject.getValue() }, res);
          this._userSubject.next(_user);
          return _user;
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Get user status.
   *
   * @param {string} email
   * @returns {Observable<any>}
   */
  getUserStatus(email: string) {
    return this.http
      .get(environment.ATG_BASE_URL + '/recovery/' + email + '/userStatus')
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /**
   * Get "subuser"
   *
   * @returns { name: string, companyNumber: string }[]
   */
  getChildCompanyList() {
    return this.http
      .get(`${environment.ATG_BASE_URL}/currentUser/getChildCompanyList`)
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Set language value in cookie.
   *
   * @param {string} lang
   */
  setLanguage(lang) {
    return this.http
      .post(environment.ATG_BASE_URL + '/app/locale', { locale: lang })
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Validate captcha token.
   *
   * @param {string} token
   * @returns {Observable<BillingAddressModel|ShippingAddressModel>}
   */
  validateCaptcha(token: string) {
    return this.http
      .post(environment.ATG_BASE_URL + '/app/recaptcha/verify', {
        remoteip: '',
        response: token,
      })
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Register user.
   *
   * @param {object} user
   * @returns {Observable<BillingAddressModel|ShippingAddressModel>}
   */
  registerUser(user: object) {
    return this.http
      .post(environment.ATG_BASE_URL + '/currentUser/register', user, {
        headers: new HttpHeaders()
          .set('accept', 'application/json')
          .set('Content-Type', 'application/json'),
      })
      .pipe(
        map((data) => data),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Switch to customer.
   *
   * @param {number} customerNum
   */
  switchToCustomer(customerNum: number): Observable<IUser> {
    // Make header form since this is required by API!
    const httpOptions = {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      ),
    };

    const body = new HttpParams().set('customerNumber', customerNum.toString());

    return this.http
      .put<IUser>(
        `${environment.apiUrl}/currentUser/switchToCustomer`,
        body,
        httpOptions
      )
      .pipe(mergeMap(() => this.getUser()));
  }

  /**
   * End switch to customer.
   *
   * @returns {Observable<Object>}
   */
  endSwitchToCustomer() {
    return this.http
      .get(`${environment.apiUrl}/currentUser/endSwitchToCustomer`)
      .pipe(mergeMap(() => this.getUser()));
  }

  /**
   * Invite user.
   *
   * @param {object} with parameters {"firstName": "string","lastName": "string","contactEmail": "string","role": "string"}
   * @returns {Observable<{"status": "string", "token": "string"}>}
   */
  inviteUser(params) {
    return this.http
      .post(`${environment.apiUrl}/currentUser/inviteUser`, params, {
        headers: new HttpHeaders()
          .set('accept', 'application/json')
          .set('Content-Type', 'application/json'),
      })
      .pipe(
        map((res) => res),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Submits Info directly to Pardot
   *
   * @param {[key: string]: unknown} formData
   * @param {string} formUrl
   */
  submitToPardot(formData: { [key: string]: unknown }, formUrl) {
    const queryString = require('query-string');
    const query = queryString.stringify(formData);

    return this.http.get(`${formUrl}?${query}`, {
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*'),
    });
  }

  /**
   * Update User Info in Pardot
   * Submits Info directly to Pardot
   *
   * @param {PardotUser} userData
   * @returns {Observable<{"state": "string"}>}
   */
  updateInPardot(userData: PardotUser) {
    return this.http.post<{ state: 'string' }>(
      `${environment.apiUrl}/users/updateUserInPardot`,
      userData
    );
  }

  /** set Stay Logged In Dialg  dialog to false if its closed */
  setStayLoggedInDialgState(val: boolean) {
    this._stayLoggedInDialgState.next(val);
  }
}
