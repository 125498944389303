import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ShoppingListsService } from '../../account-shopping-lists/shopping-lists.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IGiftListItem } from '../../../../../contracts/commerce/igift-list-item';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-item-dialog',
  templateUrl: './item-dialog.component.html',
  providers: [NotificationService],
})
export class ItemDialogComponent implements OnInit {
  addItemForm: UntypedFormGroup;
  ocids = {};

  constructor(
    public dialogRef: MatDialogRef<ItemDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private shoppingListService: ShoppingListsService,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    // Get OCIDS
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    // Build the form.
    this.addItemForm = this._formBuilder.group({
      itemNumber: ['', Validators.required],
      quantity: ['', [Validators.required, Validators.min(1)]],
    });
  }

  /**
   * Verify the list form and if it is valid add it to the list.
   *
   * @param form FormGroup
   */
  verifyListForm(form: UntypedFormGroup) {
    if (form.status === 'VALID') {
      this.notificationService.reset();
      this.shoppingListService
        .addItem(
          {
            giftlistId: this.data.listId,
          },
          {
            quantity: +form.controls.quantity.value,
            productId: form.controls.itemNumber.value.toString().trim(),
            catalogRefIds: form.controls.itemNumber.value,
          }
        )
        .subscribe(
          (data: IGiftListItem[]) => {
            this.changeDetectorRef.detectChanges();
            this.dialogRef.close({
              addedItem: true,
              itemNumber: form.controls.itemNumber.value.toString().trim(),
              quantity: form.controls.quantity.value,
              allListItems: data,
            });
          },
          (error) => {
            this.notificationService.addError(error.error.title);

            this.changeDetectorRef.detectChanges();
          }
        );
    } else {
      console.log('List Form is INVALID.');
    }
  }

  /**
   * Close the dialog box and throw the flag that no item was added.
   */
  closeDialog() {
    this.dialogRef.close({ addedItem: false });
  }
}
