import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  HistoricSelection,
  Machine,
  MachineLocation,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

interface DialogData {
  machine: Machine;
  historicData: MachineLocation[];
  historic: string;
}

@Component({
  selector: 'app-machine-breadcrumbs-dialog',
  templateUrl: './machine-breadcrumbs-dialog.component.html',
  styleUrls: ['./machine-breadcrumbs-dialog.component.scss'],
})
export class MachineBreadcrumbsDialogComponent implements OnInit {
  mostRecent = HistoricSelection.MOST_RECENT;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<MachineBreadcrumbsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.breadcrumb-label'])
      .pipe(first())
      .subscribe();
  }
}
