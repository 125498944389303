import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { INavMenu } from '../../../contracts/user/inav-menu';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-footer-links',
  styleUrls: ['./footer-links.component.scss'],
  templateUrl: './footer-links.component.html',
})
export class FooterLinksComponent {
  @Input() links: INavMenu;
  isCollapsed = true;

  constructor() {}
}
