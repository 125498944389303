import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { LayoutService } from '../../../service/layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';

export interface ITab {
  label: string;
  rightTabContent: {
    imageSrcUrl: string;
    imageTitle: string;
    imageAlt: string;
  };
  leftTabContent: {
    content: string;
  };
}

export interface ITabBody {
  index: number;
  pos: number;
  width: number;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent
  implements OnInit, AfterViewInit, CartridgeInterface
{
  @Input()
  data: {
    tabs: ITab[];
    background: string;
  };
  @ViewChild('tabBody', { read: ElementRef }) tabBodyContainer!: ElementRef;
  private platformBrowser = false;
  currentTab = 0;
  tabs: ITabBody[] = [];
  totalTabs!: number;
  tabBodyWidth!: number;
  isMobile = false;
  tabPosition = 'none';
  defaultTouch = { x: 0, y: 0, time: 0 };
  placeholderImg = environment.imagePath + environment.placeholderImg;

  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  handleTouch(event) {
    const touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      const deltaX = touch.pageX - this.defaultTouch.x;
      const deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 500) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 60) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.tabNext(false);
          } else {
            this.tabNext(true);
          }
        }
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    // initialize data for the tabs
    this.totalTabs = this.data.tabs.length;
    for (let i = 0; i < this.totalTabs; i++) {
      this.tabs.push({
        index: i,
        pos: 0,
        width: null,
      });
    }
  }

  ngAfterViewInit() {
    // Subscribe to screen resolution so we can update the tabs display for mobile
    // Needs to happen here otherwise tabBodyContainer will return undefined
    if (this.platformBrowser) {
      this.layoutService.breakpointsChanges$.subscribe((breakpoints) => {
        if (breakpoints.name === 'miniJLG' || breakpoints.name === 'miniJDC') {
          this.isMobile = true;
        }
        if (
          breakpoints.name === 'mobileJLG' ||
          breakpoints.name === 'mobileJDC'
        ) {
          this.isMobile = false;
        }
        this.tabSetWidth();
      });
    }
  }

  // Sets current tab to selected tab, used in the tab header
  tabToggle(index: number) {
    this.currentTab = index;
    if (this.isMobile) {
      this.tabPosition =
        'translate3d(-' + this.tabs[index].pos + 'px, 0px, 0px)';
    }
  }

  // Navigates to the next tab, used both when users swipe or click the next arrow in the footer.
  tabNext(next: boolean) {
    if (next) {
      if (this.currentTab < this.totalTabs - 1) {
        this.currentTab++;
        if (this.isMobile) {
          this.tabPosition =
            'translate3d(-' + this.tabs[this.currentTab].pos + 'px, 0px, 0px)';
        }
      }
    } else {
      if (this.currentTab > 0) {
        this.currentTab--;
        if (this.isMobile) {
          this.tabPosition =
            'translate3d(-' + this.tabs[this.currentTab].pos + 'px, 0px, 0px)';
        }
      }
    }
  }

  // Add swipe support for tabs in mobile view.
  tabSwipe(event) {
    const direction =
      Math.abs(event.deltaX) > 40 ? (event.deltaX > 0 ? 'right' : 'left') : '';
    if (direction == 'left') {
      this.tabNext(true);
    } else {
      this.tabNext(false);
    }
  }

  // Sets/resets inline styles for the tabs depending on mobile/desktop view.
  tabSetWidth() {
    if (this.isMobile) {
      let pos = 0;
      const width = this.tabBodyContainer.nativeElement.offsetWidth;
      this.tabBodyWidth = width * this.totalTabs;

      this.tabs.forEach((tab) => {
        tab.pos = pos;
        tab.width = width - 25;
        pos = pos + (width - 25) + 15;
      });
    } else {
      this.tabBodyWidth = null;
      this.tabs.forEach((tab) => {
        tab.pos = 0;
        tab.width = null;
      });
    }
  }

  /**
   * Receives the component background color and returns its corresponding class.
   * @param {string} bg
   * @returns {string}
   */
  getBackground(color: string) {
    switch (color) {
      case '#010000;': {
        return '_black';
      }
      case '#F37120;': {
        return '_orange';
      }
      case '#FFE4B8;': {
        return '_cream';
      }
      case '#E4E4E4;': {
        return '_lightgray';
      }
      case '#B3B5B8;': {
        return '_gray';
      }
      case '#76787B;': {
        return '_gray2';
      }
      case '#333333;': {
        return '_darkgray';
      }

      default: {
        return '';
      }
    }
  }
}
