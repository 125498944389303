import { ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';

export class EnvelopeProfileTelehandlerChart {
  static createInstance(): EnvelopeProfileTelehandlerChart {
    return new this();
  }

  /**
   * Create scissor lift chart.
   * @param chartEl
   * @param getZoneData
   * @param options
   * @param config
   */
  createChart(
    chartEl: ElementRef,
    getZoneData: Function,
    options: { typeDescription?: string; tooltip?: Function } = {},
    config: object = {}
  ): Highcharts.Chart {
    // Chart configuration
    const chartConfig = mergeDeep(
      {
        chart: {
          type: 'pie',
          height: '100%',
          animation: false,
          backgroundColor: 'transparent',
          margin: [0, 0, 0, 0],
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              formatter: function () {
                return this.point.value;
              },
              style: {
                color: '#000',
              },
            },
            startAngle: 0,
            endAngle: 90,
            center: ['0%', '100%'],
            size: '200%',
          },
          series: {},
        },
        series: [
          {
            type: 'pie',
            innerSize: '40%',
            data: getZoneData(),
          },
        ],
      },
      config
    );
    // Do we need to add tooltip/states configuration?
    if (options.tooltip) {
      chartConfig['tooltip'] = {
        headerFormat: '',
        pointFormatter: function () {
          return options && options.tooltip
            ? options.tooltip.length === 2
              ? options.tooltip(this['zoneNumber'], this)
              : options.tooltip(this['zoneNumber'])
            : null;
        },
        backgroundColor: 'white',
        borderRadius: 5,
        borderColor: 'black',
      };

      chartConfig.plotOptions.series['states'] = {
        hover: {
          animation: {
            duration: 0,
          },
          brightness: 0,
          halo: {
            size: 6,
            opacity: 1,
            attributes: {
              stroke: '#b5b5b5',
              'stroke-width': 6,
            },
          },
        },
        inactive: {
          enabled: false,
        },
      };
    } else {
      chartConfig['tooltip'] = {
        enabled: false,
      };

      chartConfig.plotOptions['series'] = {
        enableMouseTracking: false,
      };
    }

    // Create chart
    return Highcharts.chart(
      chartEl.nativeElement,
      mergeDeep(cloneDeep(BaseChartConfig), chartConfig)
    );
  }
}
