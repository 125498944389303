import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  EnvelopeHistoricMapping,
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { getZoneTooltip } from '../../../../../contracts/clearsky/machine/machine.chart.config';
import { EnvelopeProfileBoomChart } from '../envelope-profile-boom-chart';
import { MxMGroupImg } from '../../../../../contracts/clearsky/machine/machine.images';
import { ClearskyService } from '../../../../clearsky.service';
import { CSLegend } from '../../../../../contracts/clearsky/clearsky-legend';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-envelope-profile-boom-lift',
  templateUrl: './envelope-profile-boom-lift.component.html',
  styleUrls: ['./envelope-profile-boom-lift.component.scss'],
})
export class EnvelopeProfileBoomLiftComponent implements OnInit, OnChanges {
  @Input() machine: Machine;
  @Input() historic: string = HistoricSelection.HOURS_24;
  private chartEl: ElementRef;
  ocids: OcidItems = {};
  @ViewChild('chart', { read: ElementRef }) set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  showChart = false;
  image: string | undefined;
  private typeDescription = 'Boom Lifts';
  private plot: Highcharts.Chart | undefined;
  private zoneStart: { [zone: string]: number } = {
    z1: 0,
    z2: 0,
    z3: 0,
  };
  private zones = this.zoneStart;
  private legend!: CSLegend;

  constructor(private clearskyService: ClearskyService, private localization: LocalizationService) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.hours-label',
        'clearsky.graphic-default',
      ])
      .pipe(first())
      .subscribe();
    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.showChart = !!this.historicData;
      this.image = this.machine.mgroup ? MxMGroupImg[this.machine.mgroup] : '';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.historic && !changes.historic.firstChange) {
      this.showChart = !!this.historicData;

      // Do we need to redraw or start the create chart process?
      if (this.plot && this.showChart) {
        this.plot.redraw();
      }
    }
  }

  /**
   * Create chart for scissor lifts.
   * @private
   */
  private createChart(): void {
    this.plot = EnvelopeProfileBoomChart.createInstance().createChart(
      this.chartEl,
      this.getZoneData.bind(this),
      {
        typeDescription: this.typeDescription,
        tooltip: (zone: number) =>
          getZoneTooltip(zone, this.historicData, this.machine.ezTL),
      }
    );
  }

  /**
   * Get zone data.
   * @private
   */
  private getZoneData(): number[] {
    this.zones = this.zoneStart;

    // Now add numbers to the zones
    Object.keys(this.zones).forEach((zone) => {
      this.zones[zone] = this.historicData[zone];
    });

    return Object.values(this.zones).splice(0, 2);
  }

  /**
   * Get current historic data selection from machine data.
   */
  get historicData(): { [zone: string]: number } {
    return this.machine[EnvelopeHistoricMapping[this.historic]] || null;
  }
}
