import { Component, Input, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IOCIDAndValue } from '../../web2case.component';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { ScannerService } from 'app/service/scanner.service';
import { Web2CaseService } from '../../web2case.service';
import { PART_OPTION_VALUES } from '../../add-part-options.const';
import { ContentService } from 'app/service/content.service';

export const PartsOptions = {
  COMPLAINT: 'Complaint',
  PLATE: 'Order ID plate',
  AVAILABILITY: 'Availability',
  ACTIVATE: 'Activate Part',
  ID: 'Parts identification',
  QUOTE: 'Quotation request',
  RETURNS: 'Returns – RPA',
  OTHERS: 'Others',
  INVOICE: 'Invoice',
  PROGRAMMED_PARTS: 'PROGRAMMED PARTS',
  REMAN_CRAWLER_REMOTE_CONTROL: 'REMAN CRAWLER REMOTE CONTROL',
  REMAN_POWER_MODULE: 'REMAN POWER MODULE'
};

@Component({
  selector: 'app-parts-form',
  templateUrl: './parts-form.component.html',
})
export class PartsFormComponent implements OnInit {
  @Input() ocids!: OcidItems;
  @Input() partsForm!: UntypedFormGroup;
  @Input() itemNumber!: string;
  partsOptions = PartsOptions;
  partsQuestionValueSelected;
  uploadRequired = false;
  policyLink = "/resources/documents";

  partsQuestionOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.complaint',
      value: PartsOptions.COMPLAINT,
    },
    {
      ocid: 'web2case.order-id-plate',
      value: PartsOptions.PLATE,
    },
    {
      ocid: 'web2case.availability',
      value: PartsOptions.AVAILABILITY,
    },
    {
      ocid: 'w2c.activate-part',
      value: PartsOptions.ACTIVATE,
    },
    {
      ocid: 'web2case.part-num-identification',
      value: PartsOptions.ID,
    },
    {
      ocid: 'web2case.quote',
      value: PartsOptions.QUOTE,
    },
    {
      ocid: 'web2case.return',
      value: PartsOptions.RETURNS,
    },
    {
      ocid: 'web2case.others',
      value: PartsOptions.OTHERS,
    },
    {
      ocid: 'web2case.programmed-parts',
      value: PartsOptions.PROGRAMMED_PARTS,
    },
    {
      ocid: 'web2case.reman-crawler-remote-control',
      value: PartsOptions.REMAN_CRAWLER_REMOTE_CONTROL,
    },
    {
      ocid: 'web2case.reman-power-module',
      value: PartsOptions.REMAN_POWER_MODULE,
    },
  ];

  SelectItemOpts: String[] = [
    '26608200L',
    '26907000L',
    '27179900L',
    '27180000L',
    '26608300L',
    '27133100L',
    '16773800L',
    '27480300L',
    '26608500L',
  ]
  reasonForReturnOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.duplicate-order-entry',
      value: '1. Duplicate order entry',
    },
    {
      ocid: 'web2case.damaged-part',
      value: '2. Damaged part (for damages on sight, or part is incomplete)',
    },
    {
      ocid: 'web2case.part-not-required',
      value: '4. Part not required by customer',
    },
    {
      ocid: 'web2case.incorrect-part-ordered',
      value: '5. Incorrect part ordered',
    },
    {
      ocid: 'web2case.incorrect-ident-of-part',
      value: '6. Incorrect identification of part (manual, etc)',
    },
    {
      ocid: 'web2case.other-please-explain',
      value: 'Other: please explain',
    },
  ];
  maxDate: Date = new Date();
  noSuffixOrdered: boolean;
  requiredToAddParts: boolean;
  public platformBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private scanner: ScannerService,
    private web2caseService: Web2CaseService,
    private contentService: ContentService
  ) { }
  ngOnInit() {
    this.partsForm.addControl(
      'Level',
      this.formBuilder.control('', Validators.required)
    );
    if (this.platformBrowser) {
      if (this.contentService.getCookie('level2Selection')) {
        const level2Selection = this.contentService.getCookieValue('level2Selection');
        this.partsForm.get('Level')?.setValue(level2Selection);
        this.partsQuestionsChanged(level2Selection);
      } else if (this.itemNumber) {
        this.partsForm.get('Level')?.setValue(PartsOptions.ACTIVATE);
        this.partsQuestionsChanged(PartsOptions.ACTIVATE);
      }
    }
  }

  /**
   * Event fired when the parts questions dropdown select has changed. Adds various
   * form controls and groups based on its value.
   * @param {string} partsQuestionValue
   */
  partsQuestionsChanged(partsQuestionValue: string) {
    this.uploadRequired = false;
    this.partsQuestionValueSelected = PART_OPTION_VALUES.OTHERS === partsQuestionValue ? partsQuestionValue : undefined;
    this.noSuffixOrdered = PART_OPTION_VALUES.ACTIVATE === partsQuestionValue || PART_OPTION_VALUES.OTHERS === partsQuestionValue;
    this.requiredToAddParts = PART_OPTION_VALUES.OTHERS !== partsQuestionValue;
    this.web2caseService.complaintType = this.partsQuestionValueSelected;
    if (partsQuestionValue === PartsOptions.COMPLAINT) {
      if (!this.partsForm.get('')) {
        this.partsForm.addControl('complaintForm', new UntypedFormGroup({}));
      }
    } else {
      this.partsForm.removeControl('complaintForm');
    }
    if (
      partsQuestionValue === PartsOptions.PLATE ||
      partsQuestionValue === PartsOptions.QUOTE ||
      partsQuestionValue === PartsOptions.ACTIVATE ||
      partsQuestionValue === PartsOptions.AVAILABILITY ||
      partsQuestionValue === PartsOptions.ID ||
      partsQuestionValue === PartsOptions.OTHERS ||
      partsQuestionValue === PartsOptions.REMAN_CRAWLER_REMOTE_CONTROL ||
      partsQuestionValue === PartsOptions.REMAN_POWER_MODULE ||
      partsQuestionValue === PartsOptions.RETURNS
    ) {
      if (!this.partsForm.get('CaseDescription')) {
        this.partsForm.addControl(
          'CaseDescription',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('CaseDescription');
    }
    if (partsQuestionValue === PartsOptions.RETURNS || partsQuestionValue === PartsOptions.AVAILABILITY) {
      if (!this.partsForm.get('OrderNumber')) {
        this.partsForm.addControl('OrderNumber', this.formBuilder.control(''));
      }
    } else {
      this.partsForm.removeControl('OrderNumber');
    }
    if (partsQuestionValue === PartsOptions.RETURNS) {
      if (!this.partsForm.get('InvoiceNumber')) {
        this.partsForm.addControl(
          'InvoiceNumber',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('InvoiceNumber');
    }
    if (
      partsQuestionValue === PartsOptions.PLATE ||
      partsQuestionValue === PartsOptions.PROGRAMMED_PARTS ||
      partsQuestionValue === PartsOptions.QUOTE ||
      partsQuestionValue === PartsOptions.ACTIVATE ||
      partsQuestionValue === PartsOptions.OTHERS ||
      partsQuestionValue === PartsOptions.ID
    ) {
      if (!this.partsForm.get('MachineSerial')) {
        this.partsForm.addControl(
          'MachineSerial',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('MachineSerial');
    }
    if (partsQuestionValue === PartsOptions.PROGRAMMED_PARTS) {
      if (!this.partsForm.get('MachineModelNumber')) {
        this.partsForm.addControl(
          'MachineModelNumber',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('MachineModelNumber');
    }
    if (partsQuestionValue === PartsOptions.PROGRAMMED_PARTS) {
      if (!this.partsForm.get('SelectedItem')) {
        this.partsForm.addControl(
          'SelectedItem',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('SelectedItem');
    }
    if (partsQuestionValue === PartsOptions.REMAN_CRAWLER_REMOTE_CONTROL || partsQuestionValue === PartsOptions.REMAN_POWER_MODULE) {
      if (!this.partsForm.get('SerialNumberFailingModule')) {
        this.partsForm.addControl(
          'SerialNumberFailingModule',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('SerialNumberFailingModule');
    }
    if (partsQuestionValue === PartsOptions.REMAN_CRAWLER_REMOTE_CONTROL || partsQuestionValue === PartsOptions.REMAN_POWER_MODULE) {
      if (!this.partsForm.get('COReplacementModule')) {
        this.partsForm.addControl(
          'COReplacementModule',
          this.formBuilder.control('')
        );
      }
    } else {
      this.partsForm.removeControl('COReplacementModule');
    }
    if (
      partsQuestionValue === PartsOptions.PLATE ||
      partsQuestionValue === PartsOptions.PROGRAMMED_PARTS ||
      partsQuestionValue === PartsOptions.REMAN_CRAWLER_REMOTE_CONTROL ||
      partsQuestionValue === PartsOptions.REMAN_POWER_MODULE
    ) {
      if (partsQuestionValue === PartsOptions.PLATE) {
        if (!this.partsForm.get('CustomerPO')) {
          this.partsForm.addControl(
            'CustomerPO',
            this.formBuilder.control('', Validators.required)
          );
        }
      } else {
        this.partsForm.removeControl('CustomerPO');
      }
      if (!this.partsForm.get('shippingAddressForm')) {
        this.partsForm.addControl(
          'shippingAddressForm',
          new UntypedFormGroup({})
        );
      }
    } else {
      this.partsForm.removeControl('CustomerPO');
      this.partsForm.removeControl('shippingAddressForm');
    }

    if (
      partsQuestionValue === PartsOptions.QUOTE ||
      partsQuestionValue === PartsOptions.ID ||
      partsQuestionValue === PartsOptions.OTHERS
    ) {
      if (!this.partsForm.get('FleetNumber')) {
        this.partsForm.addControl('FleetNumber', this.formBuilder.control(''));
      }
    } else {
      this.partsForm.removeControl('FleetNumber');
    }
    if (
      partsQuestionValue === PartsOptions.ACTIVATE ||
      partsQuestionValue === PartsOptions.AVAILABILITY ||
      partsQuestionValue === PartsOptions.QUOTE ||
      partsQuestionValue === PartsOptions.RETURNS ||
      partsQuestionValue === PartsOptions.OTHERS
    ) {
      if (!this.partsForm.get('addPartsForm')) {
        this.partsForm.addControl('addPartsForm', new UntypedFormGroup({}));
      }
    } else {
      this.partsForm.removeControl('addPartsForm');
    }
    if (
      partsQuestionValue === PartsOptions.OTHERS ||
      partsQuestionValue === PartsOptions.ID ||
      partsQuestionValue === PartsOptions.RETURNS ||
      partsQuestionValue === PartsOptions.PLATE
    ) {
      this.uploadRequired = partsQuestionValue !== PartsOptions.OTHERS;
      if (!this.partsForm.get('uploadForm')) {
        this.partsForm.addControl('uploadForm', new UntypedFormGroup({}));
      }
    } else {
      this.partsForm.removeControl('uploadForm');
    }
    if (partsQuestionValue === PartsOptions.RETURNS || partsQuestionValue === PartsOptions.PROGRAMMED_PARTS) {
      if (!this.partsForm.get('PONumber')) {
        this.partsForm.addControl('PONumber', this.formBuilder.control(''));
      }
      if (partsQuestionValue !== PartsOptions.PROGRAMMED_PARTS) {
        if (!this.partsForm.get('ReasonForReturn')) {
          this.partsForm.addControl(
            'ReasonForReturn',
            this.formBuilder.control('', Validators.required)
          );
        }
        if (!this.partsForm.get('JLGInvoiceDate')) {
          this.partsForm.addControl(
            'JLGInvoiceDate',
            this.formBuilder.control('')
          );
        }
      }
    } else {
      this.partsForm.removeControl('PONumber');
      this.partsForm.removeControl('ReasonForReturn');
      this.partsForm.removeControl('JLGInvoiceDate');
    }
  }

  /**
   * Gets the complaint form.
   * @returns {FormGroup}
   */
  get complaintForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.partsForm.get('complaintForm');
  }
  /**
   * Gets the price form.
   * @returns {FormGroup}
   */
  get priceForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.partsForm.get('priceForm');
  }
  /**
   * Gets the shipping address form.
   * @returns {FormGroup}
   */
  get shippingAddressForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.partsForm.get('shippingAddressForm');
  }
  /**
   * Gets the add parts form.
   * @returns {FormGroup}
   */
  get addPartsForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.partsForm.get('addPartsForm');
  }
  /**
   * Gets the upload form.
   * @returns {FormGroup}
   */
  get uploadForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.partsForm.get('uploadForm');
  }

  /**
   * Helps determine if the machine serial number form control field should be required
   * or not.
   * @returns {boolean}
   */
  machineSerialNumberReq() {
    return (
      this.partsForm.get('Level').value === PartsOptions.PLATE ||
      this.partsForm.get('Level').value === PartsOptions.ID ||
      this.partsForm.get('Level').value === PartsOptions.ACTIVATE ||
      this.partsForm.get('Level').value === PartsOptions.PROGRAMMED_PARTS
    );
  }

  /**
   * Helps determine if the order number form control field should be required
   * or not.
   * @returns {boolean}
   */
  orderNumberReq() {
    return this.partsForm.get('Level').value === PartsOptions.RETURNS;
  }

  /**
   * Helps determine if the CaseDescription form control field should be required
   * or not.
   * @returns {boolean}
   */
  descriptionRequired() {
    return (
      this.partsForm.get('Level').value === PartsOptions.INVOICE ||
      this.partsForm.get('Level').value === PartsOptions.QUOTE ||
      this.partsForm.get('Level').value === PartsOptions.OTHERS
    );
  }

  /**
   * Opens barcode scanner
   */
  async openScanner() {

    (await this.scanner.scanSerialNum('Web2Case - Parts')).subscribe((data?) => {
      if (data) {
        this.partsForm.get('MachineSerial').setValue(data);
      }
    });

  }
}
