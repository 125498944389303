import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionComponent } from './subscription.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { ClearskySharedModule } from '../clearsky-shared/clearsky-shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { SubscriptionTotalSidebarComponent } from './subscription-total-sidebar/subscription-total-sidebar.component';
import { SubscriptionPackageSelectionComponent } from './subscription-package-selection/subscription-package-selection.component';
import { PricingPlanTableModule } from '../../shared/pricing-plan-table/pricing-plan-table.module';
import { SubscriptionAddonsSelectionComponent } from './subscription-addons-selection/subscription-addons-selection.component';
import { CardLabelMarkerModule } from '../../shared/card-label-marker/card-label-marker.module';
import { SubscriptionAddonsCardComponent } from './subscription-addons-selection/subscription-addons-card/subscription-addons-card.component';
import { SubscriptionAddonCardComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card.component';
import { SubscriptionAddonCardIconComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-icon/subscription-addon-card-icon.component';
import { SubscriptionAddonCardContentComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-content/subscription-addon-card-content.component';
import { SubscriptionAddonCardTitleComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-title/subscription-addon-card-title.component';
import { SubscriptionAddonCardPriceComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-price/subscription-addon-card-price.component';
import { SubscriptionAddonCardHeaderComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-header/subscription-addon-card-header.component';
import { SubscriptionAddonCardDescriptionComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-description/subscription-addon-card-description.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SubscriptionTosComponent } from './subscription-tos/subscription-tos.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionConfirmPurchaseComponent } from './subscription-confirm-purchase/subscription-confirm-purchase.component';
import { MatInputModule } from '@angular/material/input';
import { SubscriptionOrderConfirmationComponent } from './subscription-order-confirmation/subscription-order-confirmation.component';
import { SubscriptionStepperComponent } from './subscription-stepper/subscription-stepper.component';
import { ClearskyModule } from '../clearsky.module';
import { RouterModule } from '@angular/router';
import { SocialModule } from '../../shared/social/social.module';
import { SharedModule } from '../../shared/shared.module';
import { BadgeLabelModule } from '../../shared/badge-label/badge-label.module';
import { SubscriptionAddonCardPriceSavingsBadgeComponent } from './subscription-addons-selection/subscription-addon-card/subscription-addon-card-price/subscription-addon-card-price-savings-badge/subscription-addon-card-price-savings-badge.component';
import { SubscriptionAlertDialogComponent } from './subscription-alert-dialog/subscription-alert-dialog.component';

@NgModule({
  declarations: [
    SubscriptionComponent,
    SubscriptionTotalSidebarComponent,
    SubscriptionPackageSelectionComponent,
    SubscriptionAddonsSelectionComponent,
    SubscriptionAddonsCardComponent,
    SubscriptionAddonCardComponent,
    SubscriptionAddonCardIconComponent,
    SubscriptionAddonCardContentComponent,
    SubscriptionAddonCardTitleComponent,
    SubscriptionAddonCardPriceComponent,
    SubscriptionAddonCardHeaderComponent,
    SubscriptionAddonCardDescriptionComponent,
    SubscriptionTosComponent,
    SubscriptionConfirmPurchaseComponent,
    SubscriptionOrderConfirmationComponent,
    SubscriptionStepperComponent,
    SubscriptionAddonCardPriceSavingsBadgeComponent,
    SubscriptionAlertDialogComponent
  ],
  exports: [
    SubscriptionStepperComponent,
    SubscriptionOrderConfirmationComponent,
  ],
  imports: [
    CommonModule,
    ClearskySharedModule,
    PricingPlanTableModule,
    CardLabelMarkerModule,
    MatIconModule,
    MatCardModule,
    MatStepperModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatInputModule,
    ClearskyModule,
    RouterModule,
    SocialModule,
    SharedModule,
    BadgeLabelModule,
  ],
})
export class SubscriptionModule {}
