import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Web2CaseService } from '../../web2case/web2case.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  CaseStatus,
  IWeb2Case,
  IWorkOrder,
} from '../../../../contracts/web2case/iweb2case';
import { W2CCasesComponent } from './w2c-cases/w2c-cases.component';
import { W2CWorkOrdersComponent } from './w2c-work-orders/w2c-work-orders.component';
import { IUser } from '../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-w2c',
  styleUrls: ['./account-w2c.component.scss'],
  templateUrl: './account-w2c.component.html',
})
export class AccountW2CComponent implements OnInit {
  @ViewChild('cases') cases!: W2CCasesComponent;
  @ViewChild('workOrders') workOrders!: W2CWorkOrdersComponent;
  caseData: unknown[] = [];
  workOrderData: unknown[] = [];
  showCases = true;
  ocids = {};
  statusFilterMenu: object[] = [];
  user!: IUser;
  constructor(
    private localization: LocalizationService,
    private web2case: Web2CaseService,
    private notificationService: NotificationService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    // Get OCIDS

    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'w2c.created-by-number-label',
        'w2c.created-date-number-label',
        'w2c.last-updated-number-label',
        'w2c.search-message',
        'w2c.closed-cases-number-label',
        'w2c.any-types-number-label',
        'w2c.created-by-anyone-label',
        'w2c.work-orders-number-label',
        'w2c.case-update-number-label',
        'w2c.request-update-number-label',
        'w2c.request-sent-number-label',
        'w2c.request-update-message',
        'w2c.work-order-number-label',
        'w2c.job-type-number-label',
        'w2c.repair-type-number-label',
        'w2c.suggested-maintenance-date-label',
        'w2c.open-work-orders-label',
        'w2c.any-job-type-label',
        'w2c.any-repair-type-label',
        'w2c.search-order-number-label',
        'web2case.machine-serial-number',
        'w2c.search-order-number-label',
      ])
      .subscribe();

    this.statusFilterMenu = [
      {
        label: 'Any',
        value: '',
      },
      {
        label: CaseStatus.NEW,
        value: CaseStatus.NEW,
      },
      {
        label: CaseStatus.ACTIVE,
        value: CaseStatus.ACTIVE,
      },
      {
        label: CaseStatus.CLOSED,
        value: CaseStatus.CLOSED,
      },
    ];

    this.currentUserService.userSubject
      .pipe(distinctUntilChanged())
      .subscribe((user: IUser | undefined) => {
        if (user) {
          this.user = user;
        }
      });

    combineLatest([
      this.web2case.getCaseList(),
      this.web2case.getWorkOrders(),
    ]).subscribe({
      next: ([cases, workOrders]) => {
        this.caseData = this.showPastYear(cases);
        this.workOrderData = this.showPastWorkOrdersYear(workOrders);
      },
      error: (err) => {
        console.log(err);
        this.notificationService.addError(err.error?.title);
      },
    });
  }

  export(cases: boolean) {
    if (cases) {
      this.cases.export();
    } else {
      this.workOrders.export();
    }
  }

  showPastYear(items: Array<IWeb2Case>) {
    const lastYear = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    );
    return items.filter((a: IWeb2Case) => {
      const createdDate = new Date(a.CreatedDate);
      return createdDate >= lastYear;
    });
  }

  showPastWorkOrdersYear(items: Array<IWorkOrder>) {
    const lastYear = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    );
    return items.filter((a) => {
      const createdDate = new Date(a.createdDate);
      return createdDate >= lastYear;
    });
  }
}
