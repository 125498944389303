import { CsFilterBuilder } from './cs-filter-builder';
import {
  CSFilterColumn,
  CSFilterRange,
  CSFilterType,
  QAPIFilter,
} from '../machine-filter-v2';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { MxDolcStartOfTime } from '../machine.dto';

export class CsDateRangeIntBuilder extends CsFilterBuilder {
  custom?: {
    unit: dayjs.ManipulateType;
  } = {
    unit: 'days',
  };

  /**
   * QAPI filter builder.
   * @param values
   */
  buildFilter(values: CSFilterRange[]): QAPIFilter[] {
    dayjs.extend(utc);
    const range = values[0];

    return [
      {
        column: CSFilterColumn[this.filter.key],
        filterType: CSFilterType.RANGE,
        values: range
          ? [
              range.max
                ? this.subtractDays(range.max)
                : dayjs(MxDolcStartOfTime).utc().format(),
              range.min ? this.subtractDays(range.min) : dayjs().utc().format(),
            ]
          : [],
      },
    ];
  }

  /**
   * Subtract number of days to get timestamp needed for filter value.
   * @param num
   * @private
   */
  private subtractDays(num: number): string {
    return dayjs()
      .subtract(num, this.custom ? this.custom.unit : 'days')
      .utc()
      .format();
  }
}
