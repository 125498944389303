import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppleStoreBadgeComponent } from './apple-store-badge.component';

@NgModule({
  declarations: [AppleStoreBadgeComponent],
  exports: [AppleStoreBadgeComponent],
  imports: [CommonModule],
})
export class AppleStoreBadgeModule {}
