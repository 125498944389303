import { Component } from '@angular/core';

@Component({
  selector: 'app-map-info-box',
  templateUrl: './map-info-box.component.html',
  styleUrls: ['./map-info-box.component.scss']
})
export class MapInfoBoxComponent {

}
