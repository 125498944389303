import { WidgetColors } from '../dashboard/cs-colors.dto';

/**
 * Machine charge status legend id value.
 */
export enum MachineChargeStatus {
  IDLE = 0,
  CHRGING = 1,
  STANDBY = 2,
  CHRGING_DERATE = 3,
  HIGH_V = 4,
  LOW_V = 5,
  CHRG_TO = 6,
  BATT_FAIL = 13,
  CHRG_FAIL = 14,
}

export enum MxPwrSrc {
  ENGINE = 1,
  BATTERY = 2,
  HYBRID = 3,
}

export const MxDolcStartOfTime = '0000-01-01';

export enum MxAsrc {
  HAS_BA = 1,
  HAS_EA,
  HAS_SA,
  HAS_TA,
}

export enum MxAlert {
  BAT003 = 1, // Batteries being maintained for more than 30 days at a low state of charge
  BAT006 = 2, // SOC Low
  ENG001 = 3, // ALTERNATOR VOLTAGE OUT OF RANGE
  ENG003 = 4, // Fuel Level Low
  ENG004 = 5, // DEF Level Low
  SYS001 = 6, // Battery Voltage Low
  TELM002 = 7, // GPS Accuracy Reading Poor
  TELM003 = 8, // GSM Strength Reading Poor
  TELM004 = 9, // GSM Strength default value
  TELM005 = 10, // No GPS or Location data returned
  TELM006 = 11, // Device not reporting
  TELM008 = 12, // Device has not reported
}

/**
 * Access restriction legend value id to material icon.
 */
export const MachineAccessRestrictionIcons: { [key: number]: string | null } = {
  0: null,
  1: 'clearsky:access_control_unlocked',
  2: 'clearsky:access_control_creep',
  3: 'clearsky:access_control_creep_transport',
  4: 'clearsky:access_control_locked',
};

export interface MachineLocation {
  elev: number;
  lat: number;
  lng: number;
  ct: string;
}

export interface MachineDTCHistoric {
  id: number;
  stat: boolean;
  st: string;
}

export interface MachineDTC {
  desc?: string;
  id: number;
  st?: string;
  stat?: boolean;
  src?: number;
  svty?: number;
  cnt?: number;
}

interface ScatterPlotData {
  x: number;
  y: number;
}

export interface ScatterPlot3dData extends ScatterPlotData {
  z: number;
  time: number;
}

export interface MachineZone {
  maxAngle?: number;
  maxHeight?: number;
  maxOutreach?: number;
  minAngle?: number;
  minHeight?: number;
  minOutreach?: number;
}

export interface MachineZoneDetail {
  units: string;
  z1?: MachineZone;
  z2?: MachineZone;
  z3?: MachineZone;
  z4?: MachineZone;
  z5?: MachineZone;
}

export interface MachinePersonality {
  category: string;
  children: {
    name: string;
    value: string;
  }[];
}

interface MachinePersonalities {
  array: MachinePersonality[];
  time: number;
}

export interface MachineSetupItem {
  name: string;
  value: string;
}

export interface MachineSetupItems {
  array: MachineSetupItem[];
  time: number;
}

export interface MachineAlertSource {
  sys: number;
  eng: number;
  bat: number;
  tel: number;
  ac: number;
  sn: number;
}

export interface Machine {
  afCnt?: number;
  asCnt?: MachineAlertSource;
  dtcT: MachineDTCHistoric[];
  alerts?: number[];
  ar?: number;
  arH?: { t: string; v: number }[];
  ascnt?: { [key: string]: number };
  asrc: number[];
  batC?: number;
  batH2OP?: string;
  batH2OPQ?: number;
  batR?: number;
  batT?: number;
  batV?: number;
  batVQ?: number;
  lwr?: number;
  socT7d?: number[];
  socT90d?: number[];
  batVAC?: number;
  chrgT?: number;
  cmmn?: string;
  cmpn?: string;
  cmsn?: string;
  cn?: number;
  copHrs?: number;
  csd?: string;
  csDev?: number;
  cstat?: number;
  defRm?: number;
  defRmQ?: number;
  dtc?: string[];
  ect?: number;
  efcT?: ScatterPlot3dData[];
  efpT?: ScatterPlot3dData[];
  eid?: string;
  emodel?: string;
  esn?: string;
  estat?: number;
  ezT?: { [zone: string]: number };
  ezT7d?: { [zone: string]: number };
  ezT14d?: { [zone: string]: number };
  ezT31d?: { [zone: string]: number };
  ezT90d?: { [zone: string]: number };
  ezTL?: { [zone: string]: string };
  flvl?: number;
  flvlQ?: number;
  fuelS?: number;
  fuelS7d?: number;
  fuelS14d?: number;
  fuelS31d?: number;
  fuelS90d?: number;
  gsm?: { s: number; t: string };
  gstat?: number;
  hib?: boolean;
  hrs?: number;
  ign?: boolean;
  loc?: MachineLocation;
  locT90d?: MachineLocation[];
  lrt?: string;
  mgroup?: number;
  mpers?: MachinePersonalities[];
  miuS?: number;
  miuS7d?: number;
  miuS14d?: number;
  miuS31d?: number;
  miuS90d?: number;
  miuH14d?: number[];
  miuH90d?: number[];
  miuOnT?: { ts: string; ton: { s: string; e: string }[] }[];
  model?: string;
  modelImage?: string;
  msetup?: MachineSetupItems[];
  msm?: string;
  mtype?: number;
  oemName?: string;
  brand?: number;
  pwrSrc?: number;
  pvc?: number;
  sn?: string;
  spn?: string;
  soc?: number;
  socQ?: number;
  socr?: number;
  sofStat?: boolean;
  sofVer?: string;
  uidt?: string;
  rep?: boolean;
  snID?: string;
  sstat?: boolean;
  mxAge?: number;
  yb?: number;
  connStat?: boolean;
  dolc?: Date;
}

export function getQuantifiedClass(quantified: string | number): string {
  switch (quantified) {
    case 1:
      return '_empty';
    case 2:
      return '_medium';
    case 3:
      return '_full';
    default: {
      return '_empty';
    }
  }
}

export function getQuantifiedColor(quantified: string | number): string {
  switch (quantified) {
    case 1:
      return WidgetColors.red;
    case 2:
      return WidgetColors.yellow;
    case 3:
    case 4:
      return WidgetColors.green;
    default: {
      return WidgetColors.grey2;
    }
  }
}

export const MachineFieldDisplay: { [key: string]: string } = {
  brand: 'clearsky.brand-label',
  dtc: 'clearsky.number-dtcs-label',
  flvlQ: 'web2case.level2.fuel-level',
  defRmQ: 'clearsky.def-remaining-label',
  copHrs: 'clearsky.operating-label',
  tid: 'clearsky.terminal-id-label',
  csDev: 'clearsky.clearsky-device-label',
  dolc: 'clearsky.date-time-last-charge-label',
  eid: 'global.asset-id-label',
  ign: 'clearsky.machine-status-label',
  hasTA: 'clearsky.telematics-alert-label',
  hasBA: 'clearsky.battery-alert-label',
  hasEA: 'clearsky.engine-alert-label',
  hasLFSOC: 'clearsky.low-fuel-charge-label',
  hasSA: 'clearsky.system-alert-label',
  ar: 'clearsky.current-machine-label',
  alerts: 'clearsky.of-label',
  sn: 'global.serial-number',
  cn: 'global.customer-number',
  model: 'clearsky.model-label',
  mtype: 'global.model-type-label',
  mgroup: 'global.model-group-label',
  lrt: 'clearsky.last-reported-label',
  pwrSrc: 'clearsky.power-source-label',
  hrs: 'clearsky.reporting-status-label',
  rep: 'clearsky.reported-today-label',
  mxAge: 'clearsky.machine-age-label',
  uidt: 'clearsky.year-label',
  sstat: 'clearsky.charging-status-label',
  socQ: 'clearsky.state-of-charge-label',
  cname: 'global.company-name',
  yb: 'clearsky.year-label',
  sofVer: 'clearsky.software-version-label',
  sofStat: 'clearsky.software-status-label',
  hib: 'clearsky.device-mode-label',
  pvc: 'clearsky.pvc-label'
};

export const DTCFieldDisplay: { [key: string]: string } = {
  svty: 'Category',
  id: 'Identifier',
  stat: 'Status',
  st: 'Time',
  desc: 'Description',
  src: 'Source',
};

export const HistoricSelection = {
  MOST_RECENT: 'recent',
  HOURS_24: '24hours',
  DAYS_7: '7days',
  DAYS_14: '14days',
  DAYS_31: '31days',
  DAYS_90: '90days',
};

export enum UnitType {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

export enum TemperatureUnitType {
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
}

export const UnitTypeConst = {
  IMPERIAL: 'imperial',
  METRIC: 'metric',
};

export const UnitConst = {
  IMPERIAL: {
    temperature: 'F',
    speed: 'MPH',
  },
  METRIC: {
    temperature: 'C',
    speed: 'KPH',
  },
};

export interface BasicWeather {
  iconCode: string;
  unit: UnitType;
  temperature: number;
  feelLike: number;
  humidity: number;
  windSpeed: number;
}

export const EnvelopeHistoricMapping = {
  [HistoricSelection.HOURS_24]: 'ezT',
  [HistoricSelection.DAYS_7]: 'ezT7d',
  [HistoricSelection.DAYS_14]: 'ezT14d',
  [HistoricSelection.DAYS_31]: 'ezT31d',
  [HistoricSelection.DAYS_90]: 'ezT90d',
};

export enum CSFieldType {
  MACHINE = 'MACHINE',
  DTC = 'DTC',
}

export const SoftwareVersionURL = "https://cdn-jlg.scdn5.secure.raxcdn.com/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/131636-source";
export const MxConnDevCertUrl =
  'https://cdn-jlg.scdn5.secure.raxcdn.com/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/125481-source';
