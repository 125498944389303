export const localHostUrls = ['onlineexpress-l', 'onlineexpress-l-beta'];
export const devHostUrls = ['onlineexpress-d', 'onlineexpress-d-beta'];
export const testHostUrls = ['onlineexpress-t', 'onlineexpress-t-beta'];
export const prodHostUrls = [
  'onlineexpress-beta.jlg',
  'onlineexpress-beta.jerrdan',
  'onlineexpress.jlg',
  'onlineexpress.jerrdan',
];

export const isLocal = (host: string): boolean =>
  localHostUrls.some((url) => host.includes(url));

export const isTest = (host: string): boolean =>
  testHostUrls.some((url) => host.includes(url));

export const isProd = (host: string): boolean =>
  prodHostUrls.some((h) => host.includes(h));
