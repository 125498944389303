import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ICommerceItemWithCart } from '../../../../contracts/commerce/icommerce-item-with-cart';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { EESurchargeCountries, IUser, UserEnvironments } from '../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { IItemAvailabilityCheckout } from '../../../../contracts/commerce/icommerce-item-availability';
import { IOrder } from 'app/contracts/commerce/iorder';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-checkout-review',
  templateUrl: './checkout-review.component.html',
})
export class CheckoutReviewComponent implements OnInit, OnChanges {
  @Input() cartItems!: ICommerceItemWithCart[];
  @Input() showTax!: boolean;
  @Input() itemsAvailability: IItemAvailabilityCheckout = {};
  @Input() user!: IUser;
  @Input() set cart(cart: IOrder) {
    const address = cart.shippingGroups.items[0].shippingAddress;
    this.surchargeNotification = this.countries.includes(address.country);
    this.EEsurchargeNotification = this.user.erpEnvironment == UserEnvironments.EE && this.EEcountries.includes(address.country);
  }
  @Input() isPickup = false;
  @Input() checkoutError!: string;
  @Input() platformBrowser!: boolean;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  ocids: OcidItems = {};
  EEsurchargeNotification = false;
  surchargeNotification = false;
  countries = ['UK', 'GB', 'XI'];
  EEcountries = EESurchargeCountries;
  constructor(private localization: LocalizationService) { }
  ngOnInit() {
    this.localization
      .getOCIDs(['email-notification.ee-export-message', 'order-confirmation.ee-shipping-export-message'])
      .subscribe((ocids) => this.ocids = ocids);
  }
  ngOnChanges() {
    // we need to reset the warehouse display on order review
    if (this.cartItems.length && Object.keys(this.itemsAvailability).length) {
      this.cartItems.forEach((item) => {
        this.itemsAvailability[item.itemNumber].warehouseVisible = false;
      });
    }
  }

  /**
   * Prints the page on Print Friendly click.
   */
  printPage() {
    // Only print the checkout review page if the platform is browser.
    if (this.platformBrowser) {
      window.print();
    }
  }

  /**
   * Emits the submitted event to trigger order submit.
   */
  onSubmit() {
    this.submitted.emit();
  }
}
