import { IAddress } from './iaddress';

export interface ICreditCard {
  id: string;
  creditCardNumber: string;
  creditCardType: string;
  expirationDayOfMonth: string;
  expirationMonth: string;
  expirationYear: string;
  creditCardNickname: string;
  setAsDefault?: boolean;
  billingAddress: IAddress;
}

export const CCIcons = {
  VI: 'icon-cc-visa',
  AM: 'icon-cc-amex',
  DI: 'icon-cc-discover',
  MC: 'icon-cc-mastercard',
};
