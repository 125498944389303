import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  getQuantifiedColor,
  Machine,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseSolidGaugeConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as Highcharts from 'highcharts';
import * as cloneDeep from 'lodash.clonedeep';
import More from 'highcharts/highcharts-more';
import HCSoldGauge from 'highcharts/modules/solid-gauge';

// Solid gauge usage
More(Highcharts);
HCSoldGauge(Highcharts);

@Component({
  selector: 'app-asset-fuel-level-gauge',
  templateUrl: './asset-fuel-level-gauge.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'tw-relative tw-w-full tw-h-full' },
})
export class AssetFuelLevelGaugeComponent implements OnChanges {
  @Input() machine: Machine;
  private chart: ElementRef;
  @ViewChild('chart') set content(content: ElementRef) {
    if (content) {
      this.chart = content;
      this.createChart();
    }
  }
  private plot;

  ngOnChanges(changes: SimpleChanges): void {
    // If changing machines, update chart
    if (changes.machine && !changes.machine.firstChange) {
      this.updateData();
    }
  }

  /**
   * Create gauge chart.
   * @private
   */
  private createChart(): void {
    const data = mergeDeep(cloneDeep(BaseSolidGaugeConfig), {
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'Fuel Level',
          data: [
            {
              y: this.machine.flvl,
              color: getQuantifiedColor(this.machine.flvlQ),
            },
          ],
          dataLabels: {
            format: '{y}%',
            style: {
              fontSize: '11px',
            },
          },
        },
      ],
    });

    this.plot = Highcharts.chart(this.chart.nativeElement, data);
  }

  /**
   * Update gauge number.
   * @private
   */
  private updateData(): void {
    const point = this.plot.series[0].points[0];

    point.update({
      y: this.machine.flvl,
      color: getQuantifiedColor(this.machine.flvlQ),
    });
  }
}
