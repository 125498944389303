import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IOCIDAndValue } from '../../../../contracts/placard/iplacard-dto';
import { ReplacementOptions } from '../models/request-mode.enum';

@Component({
  selector: 'app-plate-replacement-form',
  templateUrl: './replacement-form.component.html'
})
export class ReplacementFormComponent implements OnInit, OnChanges {
  @Input() ocids: {};
  @Input() replacementForm: UntypedFormGroup;
  reasons: IOCIDAndValue[] = ReplacementOptions;
  replacementReason: string;
  uploadAdded: boolean = false;
  fileTypeError: boolean = false;
  fileSizeError: boolean = false;
  fileUploadError: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.replacementForm.addControl('reasonforReplacement', this.formBuilder.control('', Validators.required));
    this.replacementForm.addControl('uploadInput', this.formBuilder.array([
      this.formBuilder.group({
        FileName: ['', Validators.required],
        FileBase64: ['', Validators.required]
      })]));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.required && !changes.required.firstChange) {
      if (!this.uploadAdded) {
        if (!changes.required.currentValue) {
          this.uploadInput.removeAt(0);
        } else if (changes.required.currentValue) {
          this.uploadInput.push(
            this.formBuilder.group({
              FileName: ['', Validators.required],
              FileBase64: ['', Validators.required]
            }));
        }
        this.cd.detectChanges();
      }
    }
  }

  onReasonChange(event) {
    this.replacementReason = event.value;
    this.replacementForm.removeControl('explainOtherReasonforReplacement');
    const fileNameControl = this.uploadInput.controls[0].get('FileName');
    const fileBase64Control = this.uploadInput.controls[0].get('FileBase64');

    fileNameControl.clearValidators();
    fileBase64Control.clearValidators();
    // Update the validity of the controls
    fileNameControl.updateValueAndValidity();
    fileBase64Control.updateValueAndValidity();
    this.cd.detectChanges();
    if (this.replacementReason == this.reasons[2].value) {
      this.replacementForm.addControl('explainOtherReasonforReplacement', this.formBuilder.control('', Validators.required));
    }
  }

  /**
 * On select file.
 * @param {File} file
 */
  onSelectFile(file: File) {
    // Reset any errors.
    this.fileTypeError = false;
    this.fileSizeError = false;
    this.fileUploadError = false;
    // Ensure that the file name is of type jpeg, png, doc, or pdf.
    if (!file.name.match('.{1,}.(jpg|jpeg|png|doc|pdf)$')) {
      this.fileTypeError = true;
    } else if ((file.size / 1000000) > 1) {
      // Ensure that the file size is less than 1 MB.
      this.fileSizeError = true;
    } else {
      // If the previous two test cases pass, get the base64 string of the file.
      this.getBase64(file);
    }
  }

  /**
 * Gets the base64 string of the file.
 * @param {File} file
 */
  getBase64(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Remove the file type from the base64 string so salesforce accepts the file.
      const newFile = {
        FileName: file.name,
        FileBase64: (<string>reader.result).split(',')[1]
      };
      // If we haven't yet uploaded a file and it is required, patch the first
      // form group with the current file name and base64 string.
      if (!this.uploadAdded) {
        this.uploadInput.at(0).patchValue(newFile);
      } else {
        // Otherwise, push a new form group to the form array.
        this.uploadInput.push(
          this.formBuilder.group(newFile)
        );
      }
      this.cd.detectChanges();
      // Set uploaded to true.
      this.uploadAdded = true;
    };
    reader.onerror = (error) => {
      // If there was an error uploading the file, show an error.
      this.fileUploadError = true;
      console.log('Error uploading file: ', error);
    };
  }

  /**
 * Removes a parts row given its index.
 * @param {number} index
 */
  removeUpload(index: number) {
    this.uploadInput.removeAt(index);
    if (this.uploadInput.length === 0) {
      this.uploadAdded = false;
      const validator = [];
      this.uploadInput.push(
        this.formBuilder.group({
          FileName: ['', validator],
          FileBase64: ['', validator]
        })
      );
    }
  }

  /**
   * Gets the upload input form array.
   * @returns {FormGroup}
   */
  get uploadInput() {
    return <UntypedFormArray>this.replacementForm.get('uploadInput')
  }
}
