import { IAddress } from './iaddress';
import { IUserRole } from './iuser-role';
import { IAppConfig } from './config/iapp-config';
import { TemperatureUnitType } from '../clearsky/machine/machine.dto';

export interface IUser {
  oleUserState: string;
  oktaGroups: {
    environment: string;
    siteId: string;
    groupId: string;
  };
  clearSky: boolean;
  clearSkyAccessLevel: string;
  inProxy: boolean;
  accountType: string;
  login: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  receiveEmail: string;
  customerNumber: string;
  recoveryQuestion: string;
  recoveryAnswer: string;
  csrNotes: string;
  cultureCode: string;
  internalAccount: boolean;
  defaultCustomer: boolean;
  erpEnvironment: string;
  companyNumber: string;
  companyName?: string;
  marketingUserRole?: string;
  dealerName: string;
  enterpriseCode: string;
  languageCode: string;
  defaultWarehouse: string;
  sellingWarehouse: string;
  defaultShippingInstructions: string;
  defaultFreight: string;
  defaultFreight2: string;
  defaultShipVia: string;
  defaultShipVia2: string;
  defaultBroker1: string;
  defaultBroker2: string;
  defaultStoreId: string;
  environmentCurrency: string;
  placeOrderAllowed: boolean;
  changeShiptoAddressAllowed: boolean;
  userMapicsEnvironment: string;
  storeIdReqParts: boolean;
  taxSuffix: string;
  suspended: boolean;
  freightQuoteUser: boolean;
  priceBook: string;
  specialNotes: string;
  custClass: string;
  currencyCode: string;
  currencyCode2: string;
  dateFormat: string;
  numberFormat: string;
  labor: number;
  travel: number;
  partTerms: string;
  mileage: number;
  machineTerms: string;
  classCode: string;
  surchargeRate: number;
  mktAgreement: string;
  storeIdReqEquip: boolean;
  discountStructure: string;
  freightTerm: string;
  parentCustomer: string;
  viewCredits: boolean;
  saveCreditCardAllowed: boolean;
  defaultCurrency: string;
  defaultWeightMeasure: string;
  acceptedLanguages: string;
  locale: string;
  homeAddress?: IAddress;
  defaultSalesType: string;
  appConfig: IAppConfig;
  shippingAddress: IAddress;
  billingAddress: IAddress;
  orgShippingAddress: IAddress;
  allRoles: IUserRole[];
  addresses: IAddress;
  unitOfMeasure: string;
  unitOfTemperature: TemperatureUnitType;
  siteId: string;
}

export interface PardotUser {
  visitorId: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
}

export const UserRoles = {
  CLEARSKY_ADMIN: 'ClearSkyAdmin',
};

export const UserEnvironments = {
  EE: 'EE',
  AA: 'AA',
  BP: 'BP',
  NZ: 'NZ',
  JB: 'JB',
  CC: 'CC'
};

export const EESurchargeCountries = [
  'AR',
  'AE',
  'AU',
  'AZ',
  'BH',
  'CH',
  'DZ',
  'EG',
  'ET',
  'GF',
  'GI',
  'GP',
  'GY',
  'IC',
  'IL',
  'IN',
  'IQ',
  'IS',
  'JE',
  'JO',
  'KW',
  'KZ',
  'LB',
  'LK',
  'MA',
  'MQ',
  'MR',
  'NA',
  'NC',
  'NG',
  'NO',
  'NP',
  'NZ',
  'OM',
  'PK',
  'QA',
  'RE',
  'RS',
  'SA',
  'SG',
  'TN',
  'TR',
  'UA',
  'US',
  'YT',
  'ZA'
];

export const SiteID = {
  JLG: 'jlgSite',
  JDC: 'jerrdanSite',
};

export const AccountType = {
  RETAIL: 'Retail',
  TRADITIONAL: 'NRC/IRC',
};
