import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { EndecaService } from '../../../endeca.service';
import { SearchService } from '../search.service';
import { Subscription } from 'rxjs';
import { SearchTypes } from '../../../../contracts/ISearchHistoryItem';

@Component({
  selector: 'app-search-typeahead',
  templateUrl: './search-typeahead.component.html',
})
export class SearchTypeaheadComponent implements OnChanges, OnDestroy {
  @Input() searchTerm = '';
  @Input() searchType = 'default';
  isSearching = false;
  searchResults: object = null;
  previousSearches: {} = {};
  searchSubscription: Subscription = new Subscription();
  searchCount = 0;

  constructor(
    private endecaService: EndecaService,
    private searchService: SearchService
  ) {}

  /**
   * Record previous search in log.
   *
   * @param {string} term
   * @param {object} results
   */
  recordSearch(term: string, results: object) {
    this.previousSearches[term] = results;
  }

  /**
   * Get previous search results by term.
   *
   * @param {string} term
   * @returns {any}
   */
  getPreviousSearch(term: string) {
    return this.previousSearches[term];
  }

  /**
   * Check if term exists in previous search results log.
   *
   * @param {string} term
   * @returns {boolean}
   */
  previousSearchExists(term: string) {
    return term in this.previousSearches;
  }

  /**
   * Set search results in log.
   *
   * @param {object} results
   */
  setSearchResults(results: object) {
    this.searchResults = results;
    this.searchService.setCurrentSearchTerm(this.searchTerm);
    this.isSearching = false;
  }

  /**
   * On component data changes.
   *
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchTerm']) {
      this.updateSearchResults();
    }
  }

  /**
   * On component destroy
   */
  ngOnDestroy() {
    // Unsubscribe to all search observables
    this.searchSubscription.unsubscribe();
  }

  /**
   * Update search results.
   */
  protected updateSearchResults() {
    const currentTerm = this.searchTerm;

    // Don't perform search when it's undefined.
    if (currentTerm.length < this.searchService.minCharacters) {
      return;
    }

    // Check if search was already previously fetched.
    if (this.previousSearchExists(currentTerm)) {
      // Set results list search to false.
      this.searchService.isResultsListSearch.next(false);
      const results = this.getPreviousSearch(currentTerm);
      this.setSearchResults(results);
      return;
    }

    // Only show loading indicator after 5s if results haven't finished!
    setTimeout(() => {
      // Only show spinner after 5s if server response still hasn't completed.
      if (!this.searchResults) {
        this.isSearching = true;
      }
    }, 5000);

    // encode search term and grab typeahead results from server
    const subCount = (this.searchCount += 1);
    let url: string;
    switch (this.searchType) {
      case SearchTypes.ARTICLES:
      case SearchTypes.HEADER:
        url = '/knowledge/typeahead';
        break;
      case SearchTypes.SOFTWARE:
        url = '/service/machine-control-software/typeahead';
        break;
      case SearchTypes.MANUALS:
        url = '/technical-publications/typeahead';
        break;
        case SearchTypes.TECHPUB:
          url = '/technical-publications/typeahead';
          break;
      default:
        url = 'typeahead';
    }
    const sub = this.endecaService
      .getPayload(url, {
        Ntt: encodeURIComponent(currentTerm),
      })
      .subscribe((data: object) => {
        // Set results list search to false.
        this.searchService.isResultsListSearch.next(false);
        if (this.searchCount > subCount) {
          // Another search is being made so cancel functionality and wait for other one to complete
          return;
        }

        // Upon success
        this.setSearchResults(data);

        // Record search
        this.recordSearch(this.searchTerm, data);
      });

    this.searchSubscription.add(sub);
  }
}
