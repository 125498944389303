import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-zero-results-messages',
  styleUrls: ['./zero-results-messages.component.scss'],
  templateUrl: './zero-results-messages.component.html',
})
export class ZeroResultsMessagesComponent implements CartridgeInterface {
  @Input() data!: {
    listOfSearchTips: string;
    searchTipsHeading: string;
    couldNotFindResultsFor: string;
    sectionHeading: string;
    collapsible: boolean;
  };
  hideMsg = false;

  constructor() {}
}
