// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hour-label {
  width: 100%;
  text-align: center;
  margin-left: -10px;
}

.chart {
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVudmVsb3BlLXByb2ZpbGUtc2Npc3Nvci1saWZ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsV0FBQTtFQUNBLGtCQUFBO0VBQ0Esa0JBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0VBQ0EsV0FBQTtFQUNBLHlCQUFBO0FBQ0YiLCJmaWxlIjoiZW52ZWxvcGUtcHJvZmlsZS1zY2lzc29yLWxpZnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaG91ci1sYWJlbCB7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xyXG4gIG1hcmdpbi1sZWZ0OiAtMTBweDtcclxufVxyXG5cclxuLmNoYXJ0IHtcclxuICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbiAgaGVpZ2h0OiBjYWxjKDEwMCUgLSAyMHB4KTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/charts/envelope-profile-use/envelope-profile-scissor-lift/envelope-profile-scissor-lift.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,yBAAA;AACF;AACA,ooBAAooB","sourcesContent":[".hour-label {\r\n  width: 100%;\r\n  text-align: center;\r\n  margin-left: -10px;\r\n}\r\n\r\n.chart {\r\n  position: absolute;\r\n  width: 100%;\r\n  height: calc(100% - 20px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
