import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
/**
 * Function that truncates the string value given to the specified character length
 * @param {string} value - the value being modified.
 * @param {number} charLength character length to truncate the value to
 */
export class TruncatePipe implements PipeTransform {
  transform(value: string, charLength: number) {
    return value ? value.substring(0, charLength) + ' ...' : undefined;
  }
}
