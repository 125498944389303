import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-tab-search-results',
  templateUrl: './tab-search-results.component.html',
  styleUrls: ['./tab-search-results.component.scss'],
})
export class TabSearchResultsComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    TabOne: object[];
    TabTwo: object[];
    TabThree: object[];
  };
  activeTab = '1';

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.activeTab = params.tab ?? '1';
    });
  }
}
