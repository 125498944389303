import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { IUser } from '../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { IOrder, SalesType } from '../../../../contracts/commerce/iorder';
import { MatRadioChange } from '@angular/material/radio';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { IShippingInfo } from '../../../../contracts/commerce/ishipping-info';
import {
  FreightShippingMethods,
  IShippingInfoFreightType,
} from '../../../../contracts/commerce/ishipping-info-freight-type';
import { ICheckoutShipping } from '../../../../contracts/commerce/dto/icheckout-shipping';
import { IShippingInfoFreightTermDto } from '../../../../contracts/commerce/dto/ishipping-info-freight-term.dto';
import { IFreightTypeItems } from '../../../../contracts/commerce/ifreight-type-items';
import { IApplyFreightTypeDto } from '../../../../contracts/commerce/dto/iapply-freight-type.dto';
import { MenuService } from '../../../../service/user/menu.service';
import { IMenuListItem } from '../../../../contracts/user/imenu-list-item';
import { IUpdateInstructionDto } from '../../../../contracts/commerce/dto/iupdate-instruction.dto';
import { MatCheckbox } from '@angular/material/checkbox';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IItemAvailabilityCheckout } from '../../../../contracts/commerce/icommerce-item-availability';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shipping-form',
  styleUrls: ['./shipping-form.component.scss'],
  templateUrl: './shipping-form.component.html',
})
export class ShippingFormComponent implements OnInit, OnChanges {
  @Input() cart!: IOrder;
  @Input() freightItems: IFreightTypeItems[] = [];
  @Input() itemsAvailability!: IItemAvailabilityCheckout;
  @Input() isActive!: boolean;
  @Input() customerPickup = false;
  @Input() shippingInfo!: IShippingInfo;
  @Input() defaultInstructions!: string;
  @Output() formSubmitted: EventEmitter<ICheckoutShipping> =
    new EventEmitter<ICheckoutShipping>();
  @Output() toggleForm: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() instructionsChanged: EventEmitter<IUpdateInstructionDto> =
    new EventEmitter<IUpdateInstructionDto>();
  @Output() freightChanged: EventEmitter<IShippingInfoFreightTermDto> =
    new EventEmitter<IShippingInfoFreightTermDto>();
  @ViewChild('liftGateCharge') liftGateCharge!: MatCheckbox;
  @ViewChild('govCharge') govCharge!: MatCheckbox;
  shippingForm: UntypedFormGroup = new UntypedFormGroup({});
  oldShippingForm = {};
  formWasSubmitted = false;
  user!: IUser;
  isShippingViaParc = false;
  ocids = {};
  primaryOpts!: IShippingInfoFreightType;
  showSecondary!: boolean;
  secondaryOpts!: IShippingInfoFreightType;
  equipOpts!: IShippingInfoFreightType;
  primaryShippingTitle!: string;
  secondaryShippingTitle!: string;
  equipShippingTitle!: string;
  isReady = false;
  public shippingInstructions: IMenuListItem[] = [];
  instructionDesc = {};
  instructionsHelpText = '';
  parcelItems!: IFreightTypeItems[];
  truckItems!: IFreightTypeItems[];
  equipItems!: IFreightTypeItems[];
  equipmentOnly = false;
  equipmentIndex = 0;
  isPickup = false;

  constructor(
    private userService: CurrentUserService,
    private fb: UntypedFormBuilder,
    private localization: LocalizationService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'buy.shipping-options-primary',
        'buy.shipping-options-secondary',
        'checkout.customerpickup-shipping-warning',
        'checkout.shipping-primary-info-icon-message',
        'checkout.shipping-secondary-info-icon-message',
        'shipping.comments-label-info-icon',
        'shipping-instructions.complete-info',
        'shipping-instructions.back-order-info',
        'shipping-instructions.partial-complete-info',
        'shipping.shipping-option-error',
        'shipping.freight-billing-message',
        'shipping.freight-billing-error',
        'shipping.ship-via-label',
        'shipping.ship-via-error',
        'shipping.carrier-label',
        'shipping.carrier-error',
        'shipping.service-level-label',
        'shipping.service-level-error',
        'shipping.collect-label',
        'shipping.incoterms',
        'shipping.incoterm-error',
        'shipping.named-place-label',
        'shipping.named-place-error',
        'shipping.broker-label',
        'shipping.comments-label',
        'checkout.payment',
        // ShippingFreightTypeFormComponent OCIDs
        'broker.hint-text',
        'shippinginfo.tpb-missing-carrier-message',
        'shipping-address.collect-carrier-error',
        'shipping-options.incoterms-info-line1',
        'shipping-options.incoterms-info-line2',
        'shipping-options.named-place-info',
        'checkout.shipping-option-label',
        'checkout.delivery-method-label',
        'checkout.shipping-method.label',
        'checkout.equipment-charges-liftgate-checkbox',
        'checkout.equipment-charges-liftgate',
        'checkout.equipment-charges-gov-checkbox',
        'checkout.equipment-charges-gov',
      ])
      .subscribe(() => {
        this.instructionDesc = {
          'PARTIAL & B/O': this.ocids['shipping-instructions.back-order-info'],
          COMPLETE: this.ocids['shipping-instructions.complete-info'],
          'PARTIAL & COMPLETE':
            this.ocids['shipping-instructions.partial-complete-info'],
        };
      });
    // Get user
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        // Start form
        this.shippingForm = this.fb.group({
          freightTypes: this.fb.array([]),
        });

        // Show shipping instructions?
        if (this.user.appConfig.showShippingInstructions) {
          this.shippingForm.addControl(
            'shippingInstruction',
            this.fb.control(
              this.cart.shippingInstruction ?? this.defaultInstructions
            )
          );
        }

        // Show shipping comments?
        if (this.user.appConfig.showShippingComments) {
          this.shippingForm.addControl(
            'comment',
            this.fb.control(this.cart.comments ? this.cart.comments : null)
          );
        }

        // Make form ready
        this.isReady = true;
      }
    });
    this.isPickup = this.cart.salesTypeCode === SalesType.Pickup;
  }

  ngOnChanges(changes: SimpleChanges) {
    // Map freight types
    if (changes.shippingInfo && changes.shippingInfo.currentValue) {
      // Log old values if dirty
      if (this.shippingForm.dirty) {
        this.oldShippingForm = this.shippingForm.value;
      }

      // Map shipping instructions code to OCID translation
      this.menuService
        .mapMenu(
          'user-defaults-shipping-instructions',
          this.shippingInfo.shippingInstructions,
          'code'
        )
        .subscribe(
          (value: IMenuListItem[]) => (this.shippingInstructions = value)
        );

      if (this.instructionsHelpText === '') {
        this.instructionsHelpText = '';
        this.shippingInstructions.forEach(
          (instruction: IMenuListItem, index: number) => {
            this.instructionsHelpText +=
              instruction.label +
              ' - ' +
              this.instructionDesc[instruction.value] +
              (index !== this.shippingInstructions.length ? '\n\n' : '');
          }
        );
      }

      this.rebuildForm();
    }
    this.parcelItems = this.freightItems.filter((obj) => {
      return (
        obj.type === FreightShippingMethods.Parcel ||
        obj.type === FreightShippingMethods.Other
      );
    });
    this.truckItems = this.freightItems.filter((obj) => {
      return obj.type === FreightShippingMethods.Truck;
    });
    this.equipItems = this.freightItems.filter((obj) => {
      return obj.type === FreightShippingMethods.Machine;
    });

    this.equipmentOnly =
      !this.parcelItems[0]?.items.length && !this.truckItems[0]?.items.length;
    this.equipmentIndex = this.equipmentOnly ? 0 : this.freightItems.length - 1;
  }

  protected rebuildForm() {
    // Reset values
    this.isShippingViaParc = false;
    this.primaryShippingTitle = '';
    this.showSecondary = false;

    // Reset freight types
    this.shippingForm.setControl('freightTypes', this.fb.array([]));
    // Set freight types
    this.shippingInfo.freightTypes.forEach(
      (freightType: IShippingInfoFreightType) => {
        // Push freight type into form array
        (<UntypedFormArray>this.shippingForm.get('freightTypes')).push(
          this.fb.group({})
        );
        // Parcel freight
        switch (freightType.freightShippingMethod) {
          case FreightShippingMethods.Parcel:
            this.isShippingViaParc = true;
            this.primaryShippingTitle =
              this.ocids['buy.shipping-options-primary'];
            this.primaryOpts = freightType;

            break;
          case FreightShippingMethods.Truck:
            this.secondaryShippingTitle =
              this.ocids['buy.shipping-options-secondary'];
            this.secondaryOpts = freightType;
            this.showSecondary = true;
            break;
          case FreightShippingMethods.Machine:
            this.equipShippingTitle = this.ocids['global.shipping-options']; // TODO: ocids for this label
            this.equipOpts = freightType;
            break;
          default:
            this.primaryShippingTitle =
              this.ocids['checkout.delivery-method-label'];
            this.primaryOpts = freightType;
        }
      }
    );
  }

  /**
   * Event listener for freight term changed.
   *
   */
  onFreightChanged() {
    const body: IShippingInfoFreightTermDto = this.getShippingOptionsBody();
    this.freightChanged.emit(body);
  }

  /**
   * When form is submitted.
   */
  onSubmit() {
    this.formWasSubmitted = true;
    // Is form valid or the sales type customer pickup?
    if (
      this.shippingForm.valid ||
      this.cart.salesTypeCode === SalesType.Pickup
    ) {
      const response = this.shippingForm.value;
      // Add additional properties to freight types payload
      const freightTypes: IApplyFreightTypeDto[] = response['freightTypes'];
      freightTypes.forEach((value: IApplyFreightTypeDto, index: number) => {
        let options: IShippingInfoFreightType;
        if (index === 0 && !this.equipmentOnly && this.parcelItems) {
          options = this.primaryOpts;
        } else if (
          this.truckItems &&
          (!Object.keys(this.equipItems).length ||
            index !== freightTypes.length - 1)
        ) {
          options = this.secondaryOpts;
        } else {
          options = this.equipOpts;
        }
        value.freightQuoted = options.freightQuoted;
        value.freightType = options.freightType;
        value.itemDetails = options.itemDetails;
        if (options.freightShippingMethod === FreightShippingMethods.Machine) {
          value.liftIncluded = this.liftGateCharge?.checked;
          value.splOrder = this.govCharge?.checked;
        }
      });
      this.formSubmitted.emit(<ICheckoutShipping>response);
    } else {
      console.log('The shipping form is not valid.');
      console.log(this.shippingForm);
    }
  }

  /**
   * Event listener on shippingInstructions change.
   *
   * @param {MatRadioChange} change
   */
  onShippingInstructionsChange(change: MatRadioChange) {
    const body: IUpdateInstructionDto = {
      shipInfoFreightTerms: this.getShippingOptionsBody(),
      shippingInstruction: change.value,
    };
    // Update value manually
    this.shippingInstruction.setValue(change.value);
    this.instructionsChanged.emit(body);
  }

  /**
   * Get shipping instructions form control.
   */
  get shippingInstruction() {
    return this.shippingForm.get('shippingInstruction');
  }

  /**
   * Get primary shipping group from cart response.
   *
   * @returns {IShippingGroup}
   */
  get primaryShippingGroup() {
    if (this.cart.shippingGroups.items) {
      return this.cart.shippingGroups.items[0];
    }
  }

  /**
   * Get secondary shipping group from cart response.
   *
   * @returns {IShippingGroup}
   */
  get secondaryShippingGroup() {
    if (this.cart.shippingGroups.items) {
      return this.cart.shippingGroups.items[1];
    }
  }

  /**
   * Get equipment shipping group from cart response.
   *
   * @returns {IShippingGroup}
   */
  get equipShippingGroup() {
    if (this.cart.shippingGroups.items.length) {
      return this.cart.shippingGroups.items[
        this.cart.shippingGroups.items.length - 1
      ];
    }
  }

  /**
   * Gets the body of IShippingInfoFreightTermDto for getShippingInfo.
   *
   * @returns {IShippingInfoFreightTermDto} body
   */
  getShippingOptionsBody() {
    const body: IShippingInfoFreightTermDto = {};
    (<UntypedFormGroup[]>this.shippingForm.get('freightTypes').value).forEach(
      (form: UntypedFormGroup, index: number) => {
        body['freightTerm' + (+index + 1)] = (<UntypedFormArray>(
          this.shippingForm.get('freightTypes')
        ))
          .at(index)
          .get('freightTerm').value;
        body['shipviaCode' + (+index + 1)] = (<UntypedFormArray>(
          this.shippingForm.get('freightTypes')
        ))
          .at(index)
          .get('shipViaCode').value;
      }
    );
    return body;
  }
}
