import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SearchService } from './search.service';
import { NavigationStart, Router } from '@angular/router';
import {
  ISearchHistoryItem,
  SearchTypes,
} from '../../../contracts/ISearchHistoryItem';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { LayoutService } from '../../../service/layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScannerService } from '../../../service/scanner.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-search',
  styleUrls: ['./search.component.scss'],
  templateUrl: './search.component.html',
  animations: [
    trigger('searchState', [
      state(
        'hide',
        style({
          display: 'none',
        })
      ),
      state(
        'show',
        style({
          display: 'block',
        })
      ),
      transition('hide <=> show', animate(100)),
    ]),
  ],
})
export class SearchComponent
  implements OnInit, AfterContentChecked, AfterViewInit, OnChanges
{
  @Input() isGlobalSearch = false;
  @Input() isHeaderBanner = false;
  @Input() searchType = 'default';
  @Input() placeholderText = '';
  @Input() showTabletSearch = false;
  @Input() isFocused = false;
  @ViewChild('searchInput', { static: true }) private searchInput:
    | ElementRef
    | undefined;
  isResultsListSearch = true;
  searchState = false;
  // Search term is the value that is in the input of the text box.
  searchTerm = '';
  isSearching = false;
  isNavigating = false;
  historyItems: Array<ISearchHistoryItem> = [];
  // Number of history items to show
  historyNum = 20;
  searchResultsCount = 0;
  // Current term is the value that is selected.
  currentTerm = '';
  platformBrowser = false;
  searchMinCharacters: number = this.searchService.minCharacters;
  delayCodeTimer = null;
  ocids = {};
  hasFilters = false;
  linkIndex = -1;
  currentSectionIndex = 0;
  searchTypeOpts = SearchTypes;
  searchCat: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public searchService: SearchService,
    private layoutService: LayoutService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private localization: LocalizationService,
    private winRef: WindowRefService,
    private scanner: ScannerService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });

    this.localization
      .getOCIDs([
        'searchbox.hint-text',
        'searchbox.recent-search',
        'searchbox.clear-search',
        'technical-publications.default-search-text',
        // ResultsListFlyoutItemComponent OCIDs
        'searchbox.view-all',
        'browse.top-result',
      ])
      .subscribe();

    // Set searchCat so it can be used for regular search and barcode scanner GA4 events
    switch (this.searchType) {
      case SearchTypes.SOFTWARE:
        this.searchCat = 'Control Software Search';
        break;
      case SearchTypes.MANUALS:
        this.searchCat = 'JDC Manual Search';
        break;
      case SearchTypes.TECHPUB:
        this.searchCat = 'JLG Tech Pub Search';
        break;
      case SearchTypes.ARTICLES:
      case SearchTypes.HEADER:
        this.searchCat = 'Knowledge Article Search';
        break;
      default:
        this.searchCat = 'Billboard Search';
        break;
    }

    // Get search history items upon update
    this.searchService.historyItemsSubject
      .pipe(distinctUntilChanged())
      .subscribe((items: Array<ISearchHistoryItem>) => {
        this.historyItems = items.slice(0, this.historyNum);
      });

    this.searchService.currentSearchTerm$
      .pipe(distinctUntilChanged())
      .subscribe((term) => {
        this.currentTerm = term;
      });

    this.searchService.currentSearchInput$
      .pipe(distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
      });

    // Get whether the user clicked on item or not
    this.searchService.termIsClicked
      .pipe(distinctUntilChanged())
      .subscribe((clicked: boolean) => (this.isNavigating = clicked));

    // Observe search service to see the results from cartridge
    this.searchService.searchResultsSubject
      .pipe(distinctUntilChanged())
      .subscribe((results: object[]) => {
        this.searchResultsCount = results.length;
      });

    this.searchService.currentSectionIndex$
      .pipe(distinctUntilChanged())
      .subscribe((index) => {
        this.currentSectionIndex = index;
      });

    this.searchService.linkIndex$
      .pipe(distinctUntilChanged())
      .subscribe((index) => {
        this.linkIndex = index;
      });

    // Observe whether the current query is a category search or a part search with flyout.
    this.searchService.isResultsListSearch
      .pipe(distinctUntilChanged())
      .subscribe(
        (isResultsListSearch: boolean) =>
          (this.isResultsListSearch = isResultsListSearch)
      );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          const queryParams = event.url.indexOf('&')
            ? event.url.split('Ntt=')[1]?.split('&')[0]
            : event.url.split('Ntt=')[1];

          // If no query params exist (not on a search page) set the current search term to null.
          if (!queryParams) {
            this.searchService.currentSearchInput.next('');
            this.searchService.setCurrentSearchTerm('');
          }
        }
      });

    // Every time toggle mode is enabled, collapse the search if it is open
    this.layoutService.toggleSearchEnabled$
      .pipe(distinctUntilChanged())
      .subscribe((isEnabled) => (this.showTabletSearch = !isEnabled));

    // Check whether to show filter icon
    this.searchService.hasFilters
      .pipe(distinctUntilChanged())
      .subscribe((result) => (this.hasFilters = result));

    fromEvent(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        filter(
          (e: KeyboardEvent) =>
            e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key != 'Enter'
        ),
        distinctUntilChanged(),
        tap(() => {
          this.onSearchKeyup(this.searchInput?.nativeElement.value);
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    // https://jlgaccessit.atlassian.net/browse/O20R-5555
    this.searchInput.nativeElement.value = decodeURIComponent(this.currentTerm);
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnChanges() {
    if (this.isFocused) {
      setTimeout(() => this.searchInput?.nativeElement.focus(), 0);
    }
  }

  /**
   * Focus the search and update the search term.
   */
  searchClicked(isNavigating: boolean) {
    // Only focus the element if clicked but not when navigating.
    if (!isNavigating) {
      this.isFocused = true;
      this.searchInput?.nativeElement.focus();
    }

    // when the term is clicked change the navigating state.
    this.searchService.termIsClicked.next(true);
  }

  /**
   * Event when user is typing in search bar.
   *
   * @param value
   */
  onSearchKeyup(value: string) {
    if (value === '') {
      // clear the current search term if the value is empty.
      this.searchService.setCurrentSearchTerm('');
    }
    this.resetSearchKeyNavigationIndex();
    // this updates the searchTerm variable via observable.
    this.searchService.currentSearchInput.next(value);
    this.isSearching = value.length !== 0;
    this.searchService.showFlyOut.next(false);
    this.cd.detectChanges();
  }

  /**
   * When the user focused on the search bar, show results if
   * there is a search term instead of history.
   *
   * @param value
   */
  onSearchFocus(value: string) {
    this.isSearching = value !== '' && value.length >= this.searchMinCharacters;
    this.cd.detectChanges();
    // track which search is being used, required for GA tracking
    if (this.isGlobalSearch) {
      this.searchService.isGlobalSearchActive.next(true);
    } else {
      this.searchService.isGlobalSearchActive.next(false);
    }
  }

  /**
   * When the user clicks outside the search bar, make the search unfocused.
   * In addition, reset the search highlight index.
   */
  blur() {
    this.resetSearchKeyNavigationIndex();
    this.searchService.isSearchFocused.next(false);
    this.isFocused = false;
  }

  /**
   * Set search term when users paste value into search input
   * @param {ClipboardEvent} event
   */
  onPaste(event: ClipboardEvent) {
    this.searchTerm = event.clipboardData.getData('text');
  }

  /**
   * Shows the search filters in mobile
   */
  showFilters() {
    this.layoutService.searchFilterCollapsed =
      !this.layoutService.searchFilterCollapsed;
    if (!this.layoutService.searchFilterCollapsed) {
      this.document.body.classList.add('tw-overflow-hidden');
    } else {
      this.document.body.classList.remove('tw-overflow-hidden');
    }
  }

  /**
   * Event emitted when the search icon is clicked.
   */
  onSearchIconClick() {
    this.searchItems();
  }

  /**
   * Searches items based on the input.
   */
  searchItems() {
    // Prevent extra function call if empty and set the current term.
    // If the key nav is used don't set the current term. The index will reset once a user types
    // or a blur event happens.
    if (this.searchTerm !== '' && this.linkIndex === -1) {
      this.searchService.setCurrentSearchTerm(this.searchTerm);
    }

    // If no search is present do not allow the user to search O20R-4979.
    if (this.currentTerm === '') {
      return;
    }

    this.searchClicked(true);

    // https://www.npmjs.com/package/query-string
    // Ignore this since there is no other way to import this.
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const queryString = require('query-string');

    // Record search item in history
    let url: string;
    switch (this.searchType) {
      case SearchTypes.ARTICLES:
      case SearchTypes.HEADER:
        url = '/knowledge/search?';
        break;
      case SearchTypes.SOFTWARE:
        url = '/service/machine-control-software/search?';
        break;
      case SearchTypes.MANUALS:
        url = '/technical-publications/search?';
        break;
      case SearchTypes.TECHPUB:
          url = '/technical-publications/search?';
          break;
      default:
        url = '/search?';
    }
    const query = queryString.stringify({
      Ntt: encodeURIComponent(this.currentTerm), // Encode params
    });

    url += query;
    this.searchService.recordSearch(this.currentTerm, url);

    // Send search event to GA if the platform is browser
    if (this.platformBrowser) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);

      dataLayer.push({
        event: this.isGlobalSearch ? 'globalSearch' : 'searchBoxBannerSearch',
        searchCat: this.searchCat,
        searchTerm: this.currentTerm,
        enteredTerm: this.currentTerm,
        searchType: 'Keyword',
      });

      // only track the keyboard nav event if they used the keyboard.
      if (this.linkIndex > -1) {
        // Update the text input if the keyboard nav is used.
        this.searchInput.nativeElement.value = this.currentTerm;
        dataLayer.push({
          event: 'keyboardNavSearch',
          searchTerm: this.currentTerm,
          searchLocation: this.isGlobalSearch
            ? 'Global Search'
            : 'Search Box Banner',
        });
      }
    }

    // Now navigate the user
    return this.router.navigateByUrl(url).then(() => {
      this.searchInput?.nativeElement.blur();
      this.searchService.termIsClicked.next(false);
    });
  }

  /**
   * Event when user clicks on search history item.
   *
   * @param {Event} e
   * @param {ISearchHistoryItem} item
   * @returns {Promise<void>}
   */
  onHistoryItemClick(e: MouseEvent, item: ISearchHistoryItem) {
    // Record search item in history
    if (item.term != '') {
      this.searchService.recordSearch(item.term, item.url);
      this.searchService.setCurrentSearchTerm(item.term);

      // Send search event to GA if the platform is browser
      if (this.platformBrowser) {
        const dataLayer = (this.winRef.nativeWindow.dataLayer =
          this.winRef.nativeWindow.dataLayer || []);
        dataLayer.push({
          event: this.isGlobalSearch ? 'globalSearch' : 'searchBoxBannerSearch',
          searchTerm: item.term,
          enteredTerm: item.term,
          searchType: 'Search history',
        });
      }

      // Now navigate the user
      this.searchService.termIsClicked.next(true);
      return this.router.navigateByUrl(item.url).then(() => {
        this.searchService.termIsClicked.next(false);
        this.searchService.setCurrentSearchTerm(item.term);
        this.searchInput.nativeElement.value = item.term;
      });
    }
  }

  /**
   * Event when user clicks on clear search history link.
   */
  onClearSearchHistory() {
    this.searchService.clearHistory();
  }

  /**
   * - Resets the searchService.currentSectionIndex to 0.
   * - Resets the searchService.linkIndex to -1.
   */
  resetSearchKeyNavigationIndex(): void {
    this.searchService.currentSectionIndex.next(0);
    this.searchService.linkIndex.next(-1);
  }

  /**
   * This allows a user to navigate by Key Up/ Key Down in the search bar.
   * When they hit enter it sets the current search term.
   * @param event: KeyboardEvent
   */
  navigateUsingKey(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        // When the up key hits the last item it will reset the index. This will refocus the cursor in the search box.
        if (
          this.searchService.currentSectionIndex.getValue() === 0 &&
          this.linkIndex <= 0
        ) {
          this.resetSearchKeyNavigationIndex();
          this.searchService.setCurrentSearchTerm(this.searchTerm);
          return;
        }

        this.searchService.linkIndex.next(
          this.searchService.linkIndex.getValue() - 1
        );

        if (this.linkIndex === -1) {
          this.searchService.currentSectionIndex.next(
            this.currentSectionIndex - 1
          );

          if (this.currentSectionIndex === -1) {
            this.searchService.currentSectionIndex.next(0);
            this.searchService.linkIndex.next(0);
            return;
          }

          this.linkIndex =
            this.searchService.searchResults[
              this.searchService.currentSectionIndex.getValue()
            ]?.length - 1;
        }
        break;

      case 'ArrowDown':
        // This is needed in order up/down arrows to work. In addition to being able to type.
        event.preventDefault();

        this.searchService.linkIndex.next(
          this.searchService.linkIndex.getValue() + 1
        );

        if (
          this.linkIndex >
          this.searchService.searchResults[
            this.searchService.currentSectionIndex.getValue()
          ]?.length -
            1
        ) {
          this.searchService.currentSectionIndex.next(
            this.searchService.currentSectionIndex.getValue() + 1
          );
          this.searchService.linkIndex.next(0);
          if (
            this.searchService.currentSectionIndex.getValue() ===
            this.searchResultsCount
          ) {
            // When the search reaches the bottom it will reset the index to the text input.
            this.resetSearchKeyNavigationIndex();
            this.searchService.setCurrentSearchTerm(this.searchTerm);
          }
        }
        break;
    }
  }
  /**
   * Open the barcode scanner
   */
  async openScanner() {

      (
        await this.scanner.scanSerialNum(
          this.isGlobalSearch ? 'Global Search' : this.searchCat
        )
      ).subscribe((data?) => {
        if (data) {
          this.searchInput.nativeElement.value = data;
          this.searchTerm = data;
          this.searchItems();
        }
      });

  }
}
