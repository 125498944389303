import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BingMapsService } from '../../../service/bing-maps.service';
import * as dayjs from 'dayjs';
import { Machine } from '../../../contracts/clearsky/machine/machine.dto';
import { ClearskyMapService } from '../clearsky-map.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from 'app/clearsky/clearsky.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { OcidItems } from '../../../contracts/ocid-items';
import { LocalizationService } from '../../../shared/localization/localization.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-site-network-map-list-item',
  templateUrl: './site-network-map-list-item.component.html',
  styleUrls: ['./site-network-map-list-item.component.scss'],
})
export class SiteNetworkMapListItemComponent implements OnInit, OnChanges {
  @Input() network: SiteNetwork;
  @Input() availableNetworks: SiteNetwork[] = [];
  @Output() onAssetClick: EventEmitter<Machine> = new EventEmitter<Machine>();
  @Output() onNetworkClick: EventEmitter<SiteNetwork> = new EventEmitter<
    SiteNetwork | undefined
  >();
  _networkData: BehaviorSubject<SiteNetwork> = new BehaviorSubject<
    SiteNetwork | undefined
  >(undefined);
  networkData$: Observable<SiteNetwork> = this._networkData.asObservable();
  inEditMode: boolean;
  isExpanded: boolean;
  nameControl: UntypedFormControl = new UntypedFormControl(
    '',
    Validators.required
  );
  location: Microsoft.Maps.IAddress;
  machinesInLast24Hours = 0;
  numberOfAlerts = 0;
  ocids: OcidItems = {};
  private dataFetched: boolean;

  constructor(
    private bingMaps: BingMapsService,
    private clearskyMapService: ClearskyMapService,
    private clearskyService: ClearskyService,
    private snackbar: MatSnackBar,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    this.localization
      .getOCIDs([
        'clearsky.machine-onsite-label',
        'clearsky.created-label',
        'clearsky.added-removed-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.updateNetwork();

    this.clearskyMapService.machinePinClicked$.subscribe((machine) => {
      // If machine is in network, expand, otherwise collapse
      this.isExpanded = machine && machine.snID === this.network.snID;
    });

    this.clearskyMapService.siteNetworkPinClicked$.subscribe((siteNetwork) => {
      this.isExpanded =
        siteNetwork && siteNetwork.siteNetworkID === this.network.siteNetworkID;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.network) {
      this.updateNetwork();
    }
  }

  /**
   * On expand network information.
   */
  onExpand(): void {
    if (!this.dataFetched) {
      // Get address for network
      this.determineLocation();

      // Get how many machines were added in last 24 hours
      this.determineMachinesLast24Hours();

      // Determine how many alerts this network has
      this.determineNumberOfAlerts();
    }

    this.isExpanded = !this.isExpanded;
  }

  /**
   * On save listener.
   */
  onSave(): void {
    this.inEditMode = false;
    this.clearskyService
      .updateSiteNetwork(this.network, {
        siteNetworkName: this.nameControl.value,
      })
      .subscribe({
        next: (res) => {
          this._networkData.next(res);
          this.snackbar.open(`Site Network name updated.`, undefined, {
            duration: 3000,
            verticalPosition: 'top',
          });
        },
        error: () => {
          this.snackbar.open(`unable to update Site Network name.`, undefined, {
            duration: 3000,
            verticalPosition: 'top',
          });
        },
      });
  }

  /**
   * Update network info.
   * @private
   */
  private updateNetwork(): void {
    this.nameControl.setValue(this.network.siteNetworkName);
    this._networkData.next(this.network);
  }

  /**
   * Update location for network.
   * @private
   */
  private determineLocation(): void {
    this.bingMaps
      .getAddressByGeo(
        this.network.centerPoint.latitude,
        this.network.centerPoint.longitude
      )
      .subscribe((location) => {
        this.location = location ? location : null;
      });
  }

  /**
   * Determine how many machines were inserted into network in last 24 hours.
   * @private
   */
  private determineMachinesLast24Hours(): void {
    this.machinesInLast24Hours = Object.values(this.network.machines).reduce(
      (prev, machine) => {
        if (
          machine.networkStatus &&
          dayjs().diff(dayjs(machine.dateEntered), 'hours') <= 24
        ) {
          prev += 1;
        }

        return prev;
      },
      0
    );
  }

  /**
   * Determine how many alerts this network has.
   * @private
   */
  private determineNumberOfAlerts(): void {
    this.numberOfAlerts = this.network.machinesData.reduce((prev, machine) => {
      if (machine.alerts) {
        prev += machine.alerts.length;
      }

      return prev;
    }, 0);
  }
}
