import { Pipe, PipeTransform } from '@angular/core';

function getBoolean(value: unknown): boolean {
  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}

export const castValue = (value: unknown, cast?: string): unknown => {
  switch (cast) {
    case 'boolean':
      return getBoolean(value);
    case 'number':
      // @ts-ignore
      return +value;
    default:
      return value;
  }
};

@Pipe({ name: 'castValue' })
export class CastValuePipe implements PipeTransform {
  transform(value: unknown, cast?: string): unknown {
    return castValue(value, cast);
  }
}
