import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { INavMenu } from '../../contracts/user/inav-menu';
import { MenuService } from '../../service/user/menu.service';
import { OktaAuthWrapper } from '../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../header/sign-in-dialog/sign-in-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-nav-menu-item]',
  templateUrl: './nav-menu-item.component.html',
})
export class NavMenuItemComponent implements OnInit {
  @Input() item: INavMenu;
  isLoggedIn = false;
  private privateRoutes: string[] = [];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private oktaAuth: OktaAuthWrapper,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    this.oktaAuth.isLoggedIn.subscribe(
      (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    );
    // Get which routes are private and add their urls to the privateRoutes array.
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('SignInModel') : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.privateRoutes = menu.childMenus.map(
            (childMenu: INavMenu) => childMenu.url
          );
        }
      });
  }

  /**
   * Determines if the url is either interactive manuals or pdf manuals.
   * @param {string} url
   * @returns {boolean}
   */
  privateRoute(url: string) {
    return this.privateRoutes.includes(url);
  }

  /**
   * Opens the sign in model if the user is not logged in and the url is a my account url.
   * @param {any} event
   */
  openSignInModal(event) {
    const signInDialog = this.dialog.open(SignInDialogComponent, {
      panelClass: ['sign-in-dialog'],
      width: '720px',
    });
    // Send them to the page if the user logs in from the sign in modal.
    signInDialog.afterClosed().subscribe((loggedIn) => {
      if (loggedIn) {
        this.router.navigate([this.item.url]);
      }
    });
  }
}
