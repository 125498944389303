import { AfterViewInit, Component, HostBinding } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { EndecaService } from '../../../endeca/endeca.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LayoutService } from '../../../service/layout.service';
import { CurrentUserService } from 'app/service/user/current-user.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-cs-layout-content',
  templateUrl: './cs-layout-content.component.html',
  styleUrls: ['./cs-layout-content.component.scss'],
})
export class CsLayoutContentComponent implements AfterViewInit {
  @HostBinding('class.!tw-px-0') suppressContentPadding = false;
  @HostBinding('class.is-nav-min') get minimizedClass() {
    return this.layoutService.clearskyNavCollapsed;
  }
  @HostBinding('class.!tw-ml-0') get hiddenClass() {
    return !this.currentUserService.user.clearSky;
  }

  constructor(
    private endecaService: EndecaService,
    private layoutService: LayoutService,
    private currentUserService: CurrentUserService
  ) {}

  ngAfterViewInit() {
    this.endecaService.suppressContentPadding$
      .pipe(distinctUntilChanged())
      .subscribe((suppressContentPadding: boolean) => {
        this.suppressContentPadding = suppressContentPadding;
      });
  }
}
