import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { CurrentUserService } from '../../service/user/current-user.service';
import { MenuService } from '../../service/user/menu.service';
import { IUser } from '../../contracts/user/iuser';
import { INavMenu } from '../../contracts/user/inav-menu';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-lang]',
  templateUrl: './lang.component.html',
})
export class LangComponent implements OnInit {
  @Input() isLoggedIn: boolean;
  langDisplay: string;
  user: IUser;
  languageOptions;

  constructor(
    public dialog: MatDialog,
    private currentUserService: CurrentUserService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    // Get the language menu and user so we can display the proper language at the top
    combineLatest([
      this.currentUserService._userSubject,
      this.menuService.menus$,
    ])
      .pipe(
        mergeMap((result: [IUser, INavMenu]) => {
          const user: IUser = result[0];
          const menus: INavMenu = result[1];
          if (user) {
            this.user = user;
            if (menus) {
              return this.menuService.getMenuByUxKey('account-languages');
            }
          }
          return of(null);
        })
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.languageOptions = menu.childMenus;
          this.langDisplay = this.languageOptions.find(
            (a) => a.uxKey === this.user.locale
          )?.label;
        }
      });
  }

  /**
   * Opens dialog to display language selector
   */
  selectLang() {
    const importModal = this.dialog
      .open(LangSelectorComponent, {
        width: '750px',
        panelClass: 'lang',
        data: {
          languageOptions: this.languageOptions,
        },
      })
      .afterClosed()
      .subscribe((data?) => {
        if (data) {
          this.updateLang(data);
        }
      });
  }

  /**
   * Update the user's locale based on the language selected
   */
  updateLang(lang: string) {
    // Call the locale endpoint to first update the cookie value
    this.currentUserService
      .setLanguage(lang)
      .pipe(
        mergeMap(() => {
          // If user is logged in we should update their profile as well
          if (this.isLoggedIn) {
            return this.currentUserService.updateUserProfile({
              locale: lang,
            });
          } else {
            // Update the menu and OCID for anonymous users when they change the locale
            const newUser = Object.assign(
              { ...this.currentUserService._userSubject.getValue() },
              {
                locale: lang,
              }
            );
            this.currentUserService._userSubject.next(newUser);
          }
          return of([]);
        })
      )
      .subscribe();
  }
}
