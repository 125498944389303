import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CartridgeComponent } from '../cartridge/cartridge.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-cartridges',
  templateUrl: './cartridges.component.html',
  host: {
    class: 'cartridges-container',
  },
})
export class CartridgesComponent implements OnInit, OnChanges {
  @Input('cartridges') data;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container;
  private notFoundCartridge = 'NotFoundResponse';
  private unauthorizedCartridge = 'UnauthorizedResponse';

  constructor(
    private viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional()
    @Inject('response')
    private response: { status: (status: number) => void }
  ) {}

  ngOnInit() {
    this.injectCartridges();
  }

  // When data changes, rebuild component
  ngOnChanges(changes: SimpleChanges) {
    // Only update if it's actually changed, not on load
    if (!changes.data.firstChange) {
      // Clear previous container
      this.container.clear();

      this.injectCartridges();
    }
  }

  protected injectCartridges() {
    for (const contentCartridge of this.data || []) {
      // Load in the cartridge!
      const cartridgeType = contentCartridge['@type'];

      // Load in the cartridge if not a 404/401
      if (
        cartridgeType !== this.notFoundCartridge
      ) {
        const componentRef = this.container.createComponent(CartridgeComponent);
        (<{ data: unknown; type: string }>componentRef.instance).type =
          cartridgeType;
        (<{ data: unknown; type: string }>componentRef.instance).data =
          contentCartridge;
      }

      // Is it a 401?
      if (
        cartridgeType === this.unauthorizedCartridge &&
        !isPlatformBrowser(this.platformId)
      ) {
        this.response.status(401);
      }

      // 404?
      if (
        cartridgeType === this.notFoundCartridge &&
        !isPlatformBrowser(this.platformId)
      ) {
        this.response.status(404);
      }
    }
  }
}
