import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-blocks-item-title',
  templateUrl: './grid-blocks-item-title.component.html',
  styleUrls: ['./grid-blocks-item-title.component.scss'],
  host: {'class': 'grid-blocks-item-title'}
})
export class GridBlocksItemTitleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
