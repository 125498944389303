let stateKey,
  eventKey,
  keys = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  };
let activeListener;

function getVisKey() {
  let eventKey;

  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }

  return eventKey;
}

export const vis = (function () {
  eventKey = getVisKey();

  return function (c?) {
    if (c) {
      document.addEventListener(eventKey, c);
      activeListener = c;
      return c(!document[stateKey]);
    }
    return !document[stateKey];
  };
})();

export const unregisterVis = () => {
  eventKey = getVisKey();

  if (activeListener) {
    document.removeEventListener(eventKey, activeListener);
  }
};

/**
 * Returns hidden prop on document if it exists.
 */
export const getDocHiddenProp = (): string | null => {
  const prefixes = ['webkit', 'moz', 'ms', 'o'];

  // Test for native support
  if ('hidden' in document) return 'hidden';

  // Find prefixes
  for (let i = 0; i < prefixes.length; i++) {
    if (prefixes[i] + 'Hidden' in document) return prefixes[i] + 'Hidden';
  }

  // Otherwise it's not supported
  console.log('Page Visibility API not supported');
  return null;
};

/**
 * Get event listener name for visibility if hidden exists.
 * @param visProp
 */
const getHiddenEvtName = (visProp: string | null): string | null => {
  return visProp
    ? visProp.replace(/[H|h]idden/, '') + 'visibilitychange'
    : null;
};

/**
 * Event handler for visibilitychange event.
 * @param visProp
 * @param callback
 */
const visibilityEventHandler = (
  visProp: string | null,
  callback: (visible: boolean) => void
): (() => void) => {
  return () => {
    callback(!document[visProp]);
  };
};

/**
 * Adds event listener to 'hidden' prop on document to determine if tab is active or not.
 * @param callback
 */
export const addVisibilityEventListener = (
  callback: (visible: boolean) => void
): boolean => {
  const visProp = getDocHiddenProp();
  const evtName = getHiddenEvtName(visProp);

  if (evtName) {
    // Call the callback before adding listener so it fires initially
    callback(!document[visProp]);
    document.addEventListener(
      evtName,
      visibilityEventHandler(visProp, callback),
      false
    );
    return true;
  }

  return false;
};

/**
 * Adds event listener to 'hidden' prop on document to determine if tab is active or not.
 * @param callback
 */
export const removeVisibilityEventListener = (
  callback: (visible: boolean) => void
): boolean => {
  const visProp = getDocHiddenProp();
  const evtName = getHiddenEvtName(visProp);

  if (evtName) {
    document.removeEventListener(
      evtName,
      visibilityEventHandler(visProp, callback),
      false
    );
    return true;
  }

  return false;
};
