import { Component, Input, OnInit } from '@angular/core';
import { Machine } from '../../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-quick-links',
  templateUrl: './asset-quick-links.component.html',
  styleUrls: ['./asset-quick-links.component.scss'],
})
export class AssetQuickLinksComponent implements OnInit {
  @Input() machine: Machine;
  widgetName = MachineWidgets.QUICK_LINKS;
  displayName = MachineWidgetsDisplay.quickLinks;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([this.displayName])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
