import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';

@Component({
  selector: 'app-chart-legend-option',
  templateUrl: './chart-legend-option.component.html',
  styleUrls: ['./chart-legend-option.component.scss'],
})
export class ChartLegendOptionComponent implements OnInit, OnChanges {
  @Input() legacy = false;
  @Input() orientation = 'horizontal';
  @Input() disableLabelColor?: boolean = false;
  @Input() hideDot?: boolean = false;
  @Input() hidden?: boolean = false;
  @Input() value: string | number = '';
  @Input() data?: number | string;
  @Input() label?: string;
  @Input() color?: string = WidgetColors.grey2;
  @Input() borderColor?: string = WidgetColors.grey2;
  @Input() enableClick? = true;
  @Output() onClick?: EventEmitter<string | number> = new EventEmitter<
    string | number
  >();
  @HostBinding('class') classes = '';
  @HostBinding('class.border-bottom')
  public get isVertical(): boolean {
    return this.orientation === 'vertical';
  }
  @HostBinding('class.border-end')
  public get isHorizontal(): boolean {
    return this.orientation === 'horizontal';
  }
  isActive = true;

  ngOnInit(): void {
    this.isActive = !this.hidden;
    this.classes = this.orientation;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hidden && !changes.hidden.isFirstChange()) {
      this.isActive = !this.hidden;
    }
  }

  /**
   * On toggle of legend option.
   */
  onToggle(): void {
    this.isActive = !this.isActive;

    if (!this.onClick) {
      return;
    }
    this.onClick.emit(this.value);
  }
}
