import { Component, Input } from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../contracts/clearsky/machine/machine.dto';

@Component({
  selector: 'app-engine-fuel-consumption',
  templateUrl: './engine-fuel-consumption.component.html',
})
export class EngineFuelConsumptionComponent {
  @Input() machine: Machine;
  historic: string = HistoricSelection.HOURS_24;
}
