import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLabelMarkerComponent } from './card-label-marker.component';

@NgModule({
  declarations: [CardLabelMarkerComponent],
  exports: [CardLabelMarkerComponent],
  imports: [CommonModule],
})
export class CardLabelMarkerModule {}
