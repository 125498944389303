import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IAddress } from '../../../../../contracts/user/iaddress';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAddressesService } from 'app/service/user/user-addresses.service';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-duplicate-shipping-address-dialog',
  templateUrl: './duplicate-shipping-address-dialog.html',
})
export class DuplicateShippingAddressDialogComponent implements OnInit {
  ocids: {} = {};
  formGroup: FormGroup;
  makeDefault: boolean;
  constructor(
    public dialogRef: MatDialogRef<DuplicateShippingAddressDialogComponent>,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private localization: LocalizationService,
    private addressService: UserAddressesService,
    @Inject(MAT_DIALOG_DATA)
    public data: { addresses: IAddress[], newAddress: IAddress, makeDefault: boolean }
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      address: [this.data.addresses[0], Validators.required]
    })

    this.makeDefault = this.data.makeDefault;
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'new-address.duplicate-warning-message',
        'new-address.duplicate-use-existing-cta',
        'new-address.duplicate-create-new-cta'
      ])
      .subscribe();
  }

  /**
   * Event listener for adding address.
   *
   * @param address: IAddress
   */
  onAddressAdded() {
    this.ngZone.run(() => {
      // send true to make existng address default.
      if (this.makeDefault) {
        this.addressService.makeDefaultAddress(this.formGroup.value.address).subscribe(() => {
          this.dialogRef.close(this.formGroup.value.address);
        });
      } else {
        this.dialogRef.close(this.formGroup.value.address);
      }
    });
  }


  onCancel() {
    const address: IAddress = { ...this.data.newAddress, addAsNewAddress: true, makeDefault: this.makeDefault };
    (this.addressService
      .addValidAddress(
        address
      ) as Observable<{ address: IAddress }>).subscribe((res: { address: IAddress, duplicateAddress: boolean }) => {
        this.ngZone.run(() => {
          this.dialogRef.close(res.address);
        })
      });
  }
}
