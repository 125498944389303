export class ShippingAddressModel {
  public id: string;
  public companyName: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public city: string;
  public state: string;
  public postalCode: string;
  public country: string;
  public phoneNumber: string;
  public contactName: string;
  public isDefault: boolean;
  public shipToNumber: string;

  // Sets shipToNumber to a default empty string for when passing new
  // shipping addresses that don't yet have a ship to number.
  constructor(
      id: string,
      companyName: string,
      address1: string,
      address2: string,
      address3: string,
      city: string,
      state: string,
      postalCode: string,
      country: string,
      phoneNumber: string,
      contactName: string,
      isDefault: boolean,
      shipToNumber: string = ''
  ) {
    this.id = id;
    this.companyName = companyName;
    this.address1 = address1;
    this.address2 = address2;
    this.address3 = address3;
    this.city = city;
    this.state = state;
    this.postalCode = postalCode;
    this.country = country;
    this.phoneNumber = phoneNumber;
    this.contactName = contactName;
    this.isDefault = isDefault;
    this.shipToNumber = shipToNumber;
  }
}
