import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Machine,
  MachineSetupItem,
} from '../../../../contracts/clearsky/machine/machine.dto';
import XLSX from 'xlsx-populate/browser/xlsx-populate.min';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver-es';
import { isPlatformBrowser } from '@angular/common';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs/operators';

interface DialogData {
  machine: Machine;
}

@Component({
  selector: 'app-machine-setup-dialog',
  templateUrl: './machine-setup-dialog.component.html',
})
export class MachineSetupDialogComponent implements OnInit {
  setups: MachineSetupItem[] = [];
  lastModified: string;
  lastReported: string;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<MachineSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.personalities-details-label',
        'clearsky.last-reported-label',
        'clearsky.last-modified-label',
        'clearsky.download-historical-report-label',
      ])
      .pipe(first())
      .subscribe();
    dayjs.extend(utc);

    // Only display latest in dialog
    const personalities = [...(this.data.machine.msetup || [])].pop();
    if (personalities) {
      this.setups = personalities.array;
      this.lastModified = dayjs
        .utc(personalities.time)
        .format('MMMM D, YYYY h:mm A');
      this.lastReported = dayjs
        .utc(this.data.machine.lrt)
        .format('MMMM D, YYYY h:mm A');
    }
  }

  onDownload(): void {
    // Write all personalities to csv
    if (isPlatformBrowser(this.platformId)) {
      const historicalCount = (this.data.machine.msetup || []).length;
      const blankColumnsForTimestamps = new Array(historicalCount);

      // Get rows
      const categoryNames: { [category: string]: string[] } = (
        this.data.machine.msetup || []
      ).reduce((categories, setup) => {
        setup.array.reduce((prev, entity) => {
          // Does this title even exist?
          if (!categories[entity.name]) {
            categories[entity.name] = [];
          }

          categories[entity.name].push(entity.value);

          return prev;
        }, {});

        return categories;
      }, {});

      // Header information
      const rows: string[][] = [
        [
          `Serial Number: ${this.data.machine.sn}`,
          ...blankColumnsForTimestamps,
        ],
        [`Asset ID: ${this.data.machine.eid}`, ...blankColumnsForTimestamps],
        [
          `Last Reported: ${dayjs
            .utc(this.data.machine.lrt)
            .format('MMMM D, YYYY H:mm')}`,
          ...blankColumnsForTimestamps,
        ],
        ['Setup', ...blankColumnsForTimestamps],
        ['', ...blankColumnsForTimestamps],
        [
          '',
          ...(this.data.machine.msetup || []).map((i) =>
            dayjs.utc(i.time).format('M/D/YYYY H:mm')
          ),
        ],
        ...Object.keys(categoryNames).map((key) => [
          key,
          ...categoryNames[key],
        ]),
      ];

      const name = 'Machine Setup';
      XLSX.fromBlankAsync().then((workbook) => {
        // Modify the workbook.
        const sheet = workbook.sheet(0).name(name);
        sheet.row(1).cell(1).value(rows);

        // Write to file.
        workbook.outputAsync('blob').then((blob) => {
          saveAs(blob, `${name}.xlsx`);
        });
      });
    }
  }
}
