import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-widget-footer',
  templateUrl: './widget-footer.component.html',
  styleUrls: ['./widget-footer.component.scss'],
})
export class WidgetFooterComponent {
  @Input() enableHistory = false;
  @Input() enableMostRecent = false;
  @Input() historicSelection?: string;
  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();
}
