import { Component, OnInit } from '@angular/core';
import { WidgetHeaderComponent } from '../../../widgets/widget-header/widget-header.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-widget-header',
  templateUrl: './../../../widgets/widget-header/widget-header.component.html',
  styleUrls: ['./../../../widgets/widget-header/widget-header.component.scss'],
})
export class MachineWidgetHeaderComponent
  extends WidgetHeaderComponent
  implements OnInit
{
  /**
   * Remove widget.
   */
  onRemove(): void {
    this.confirmation
      .confirm(
        this.ocids['clearsky.remove-label'],
        this.ocids['clearsky.are-you-remove-label'],
        this.ocids['global.yes']
      )
      .subscribe((confirm) => {
        if (confirm) {
          this.clearskyService.removeMachineWidget(this.widget);
        }
      });
  }
}
