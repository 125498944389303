import { Component, Input } from '@angular/core';
import { ILatestNewsItem } from '../../latest-news.component';

@Component({
  selector: 'app-latest-news-header',
  styleUrls: ['./latest-news-header.component.scss'],
  templateUrl: './latest-news-header.component.html',
})
export class LatestNewsHeaderComponent {
  @Input() news!: ILatestNewsItem;

  constructor() {}

  /**
   * Receives the backend color from endeca and returns the corresponding class name.
   * @param {string} backgroundColor
   * @returns {string}
   */
  getBGStyle(backgroundColor: string) {
    switch (backgroundColor) {
      case 'jlg-orange': {
        return 'site';
      }
      case 'jerrdan-red': {
        return 'site';
      }
      case 'gray': {
        return 'gray';
      }
      case 'black': {
        return 'black';
      }
      case 'white': {
        return 'white';
      }
      default: {
        return 'site';
      }
    }
  }
}
