import { AfterViewInit, Component, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyService } from '../../../clearsky.service';
import { combineLatest, Subscription } from 'rxjs';
import { MxMGroupImg } from '../../../../contracts/clearsky/machine/machine.images';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';
import { LayoutService } from 'app/service/layout.service';
import { WidgetService } from 'app/clearsky/widget.service';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-model-type',
  templateUrl: './model-type.component.html',
  styleUrls: ['./model-type.component.scss'],
})
export class ModelTypeComponent implements AfterViewInit {
  displayName = WidgetsDisplay.modelType;
  widgetName = Widgets.MODEL_TYPE;
  isMobile$ = this.layoutService.isMobile$;
  isDownloading$ = this.widgetService.downloadPdfStarted$;
  modelGroups: {
    id: number;
    name: string;
    cnt: number;
    ratio: number;
    hidden: boolean;
    image?: string;
  }[] = [];
  slideConfig = {
    infinite: false,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 2600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  isLoading = true;
  private subs: Subscription;
  ocids: OcidItems = {};

  constructor(
    private localization: LocalizationService,
    private widgetService: WidgetService,
    private layoutService: LayoutService,
    private clearskyService: ClearskyService,
    private changeDetectRef: ChangeDetectorRef,
    private gAService: GoogleAnalyticsService
  ) {
    this.localization
      .getOCIDs([this.displayName])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  ngAfterViewInit(): void {
    // Subscribe to machine and filter updates
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.getCurrentFilterValues(CSFilter.mgroup.key),
      this.clearskyService.legendRef$,
    ]).subscribe(([page, values, legend]) => {
      if (!page) {
        return;
      }

      const mgroups = legend.mgroup || [];
      const total =
        page.aggregations && page.aggregations.machineCount
          ? page.aggregations.machineCount.total
          : 0;
      this.modelGroups = ((page.aggregations && page.aggregations.mgroup) || [])
        .map((group) => {
          const modelGroup = mgroups.find((g) => g.id === group.id);

          return {
            ...group,
            name: modelGroup ? modelGroup.desc : '',
            ratio: group.cnt / total,
            image: modelGroup && MxMGroupImg[modelGroup.id],
            hidden: values.length && !values.includes(group.id),
          };
        })
        .sort((a, b) => {
          if (a.name === 'Other') return 1;
          if (b.name === 'Other') return -1;
          return 0;
        });

      this.isLoading = false;
      this.changeDetectRef.detectChanges()
    });
  }

  /**
   * On legend click event listener.
   * @param mgroup
   */
  onModelClick(mgroup: number): void {
    this.gAService.eventEmitter('clearsky_click', 'model_type_composition', 'filter', CSFilter.mgroup.key, mgroup);
    const mgroupIds = this.modelGroups.filter(item => !item.hidden).map(item => item.id);
    let filteredMgroupIds;
    if (this.modelGroups.length === mgroupIds.length) {
      filteredMgroupIds = [mgroup]
    } else {
      const index = mgroupIds.indexOf(mgroup);
      filteredMgroupIds = index > -1
        ? [...mgroupIds.slice(0, index), ...mgroupIds.slice(index + 1)]
        : [...mgroupIds, mgroup];
    }
    this.clearskyService.updateFilter(CSFilter.mgroup.key, filteredMgroupIds);
  }
}
