import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IGiftList } from '../../../../../contracts/commerce/igift-list';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { ShoppingListsService } from '../shopping-lists.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shopping-list-dialog',
  templateUrl: './shopping-list-dialog.component.html',
})
export class ShoppingListDialogComponent implements OnInit {
  listForm: UntypedFormGroup;
  ocids = {};

  constructor(
    public dialogRef: MatDialogRef<ShoppingListDialogComponent>,
    private shoppingListService: ShoppingListsService,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    // Setup form
    this.listForm = this.fb.group({
      eventName: ['', Validators.required],
      description: [''],
      public: [false, Validators.required],
    });

    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  /**
   * Event emitted on form submit.
   */
  onFormSubmit() {
    if (this.listForm.status === 'VALID') {
      this.notificationService.reset();
      // Add data as a new list resource
      this.shoppingListService.addList(this.listForm.value).subscribe(
        (list: IGiftList) => {
          this.dialogRef.close(list);
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
    }
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
