import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-blocks-spotlight-text',
  templateUrl: './grid-blocks-spotlight-text.component.html',
  styleUrls: ['./grid-blocks-spotlight-text.component.scss']
})
export class GridBlocksSpotlightTextComponent {

}
