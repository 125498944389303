import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SeeOnSiteDialogComponent } from '../see-on-site-dialog/see-on-site-dialog.component';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { isMobileOrTabletDevice } from '../../../../contracts/helpers/is-mobile-tablet';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-see-on-site-button',
  templateUrl: './see-on-site-button.component.html',
})
export class SeeOnSiteButtonComponent implements OnInit {
  @Input() url: string;
  ocids: OcidItems = {};

  constructor(
    private dialog: MatDialog,
    private localization: LocalizationService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.localization
      .getOCIDs(['arapp.see-jobsite-label'])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  onClick(): void {
    // If mobile just redirect the user to the link
    if (isPlatformBrowser(this.platformId) && isMobileOrTabletDevice()) {
      this.document.location.href = this.url;
      return;
    }

    // Otherwise open QR code dialog
    this.dialog.open(SeeOnSiteDialogComponent, {
      maxWidth: '500px',
      data: {
        url: this.url,
      },
    });
  }
}
