import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IManagedUserPagination } from '../../../../contracts/user/imanaged-user-pagination';
import { iInviteUserDto } from '../../../../contracts/user/dto/iInvite-user-dto';
import { iUpdateUserStatusDto } from '../../../../contracts/user/dto/iupdate-user-status-dto';
import { iUpdateUserRoleDto } from '../../../../contracts/user/dto/iupdate-user-role-dto';

@Injectable()
export class ManageUsersService {

    constructor(private http: HttpClient) { }

    /**
     * @params {IManagedUserPagination} params
     *
     * @returns {Observable<IManagedUsersPayload>}
     */
    getManagedUsers(params: IManagedUserPagination) {
        const queryString = require('query-string');
        let query = queryString.stringify(params);
        return this.http.get(`${environment.ATG_BASE_URL}/currentUser/managedUsers?${query}`)
    }

    /**
     *
     * @param {inviteUserDto} iInviteUserDto
     *
     * @returns {Observable<{status: string, token: string>}
     */
    inviteUser(inviteUserDto: iInviteUserDto) {
        return this.http.post(`${environment.ATG_BASE_URL}/currentUser/inviteUser`, inviteUserDto);
    }

    /**
     *
     * @param {login} string
     * @param {roles} string
     *
     * @returns {Observable<null>}
     */
    updateUserRoles(updateUserRoleDto: iUpdateUserRoleDto) {
        return this.http.post(`${environment.ATG_BASE_URL}/users/updateUserRoles`, updateUserRoleDto);
    }

    /**
     *
     * @param {login} string
     * @param {status} string
     *
     * @returns {Observable<null>}
     */
    updateUserStatus(updateUserStatusDto: iUpdateUserStatusDto) {
        return this.http.patch(`${environment.ATG_BASE_URL}/users/updateUserStatus`, updateUserStatusDto);
    }
}
