import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { IAddItemToGiftList } from '../../../../../contracts/product/dto/iadd-item-to-giftlist';
import { OktaAuthWrapper } from '../../../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { CartService } from '../../../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../../../service/confirmation/confirmation-alert.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { ConfirmationDialogsService } from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { ShoppingListComponent } from '../../shopping-list/shopping-list.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-psr289-serial-num-opt-warning',
  templateUrl: './psr289-serial-num-opt-warning.component.html',
})
export class Psr289SerialNumOptWarningComponent implements OnInit, OnChanges {
  @Input() items: ICommerceItemWithCart[] = [];
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeAllExcept: EventEmitter<string> = new EventEmitter<string>();
  isLoggedIn = false;
  public ocids = {};
  public form: UntypedFormGroup;
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    public dialog: MatDialog,
    private router: Router,
    private oktaAuth: OktaAuthWrapper,
    private localization: LocalizationService,
    private fb: UntypedFormBuilder,
    private cartService: CartService,
    private confirmationService: ConfirmationDialogsService,
    private alertService: ConfirmationAlertService,
    private notificationService: NotificationService,
    private zone: NgZone,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn.subscribe(
      (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    );
  }

  // On changes, rebuild the form.
  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      // Build form
      this.buildForm();
    }
  }

  /**
   * Build the form.
   */
  protected buildForm() {
    // Build the form based on the number of non-required serial number parts.
    if (this.items.length > 1) {
      // If the number of items is greater than one, build the form with a radio button per item.
      this.form = this.fb.group({
        serialNumOpts: [this.items[0].itemNumber, Validators.required],
      });
    } else {
      // If the number of items is 1, then set a blank form. Per requirements, we do not need a radio button
      // for only one item.
      this.form = this.fb.group({});
    }
  }

  /**
   * Remove a single item from the cart.
   *
   * @param {ICommerceItemWithCart} item
   */
  removeItem(item: ICommerceItemWithCart) {
    // Show dialog asking for permission first.
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        // If they wish to delete the item, proceed.
        if (result) {
          this.notificationService.reset();
          // Remove the item from the cart.
          this.cartService.removeFromCart(item, 'PSR289').subscribe(() => {
            // Emit the removed event in order to refresh the cart.
            this.removed.emit();
          });
        }
      });
  }

  /**
   * Remove all the non-required serial number items from the cart.
   */
  removeAllItems() {
    // Show dialog asking for permission first.
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.notificationService.reset();
          // Remove added items ( ugly for now since only one item can be removed at a time, future enhancement? )
          this.zone.runOutsideAngular(() => {
            return this.removeBatchOfItems(
              this.cartService.removeAll(this.items, 'PSR289')
            );
          });
        }
      });
  }

  /**
   * Remove items after having added them to the cart.
   *
   * @param {IAddItemToGiftList[]} items
   */
  removeAfterAddingToList(items: IAddItemToGiftList[]) {
    this.notificationService.reset();
    // Remove added items ( ugly for now since only one item can be removed at a time :( Future enhancement? )
    this.zone.runOutsideAngular(() => {
      const itemsNeedRemoved: ICommerceItemWithCart[] = [];
      items.forEach((removableItem: IAddItemToGiftList) => {
        itemsNeedRemoved.push(
          this.items.find(
            (item: ICommerceItemWithCart) =>
              removableItem.itemNumber === item.itemNumber
          )
        );
      });
      return this.removeBatchOfItems(
        this.cartService.removeAll(
          itemsNeedRemoved,
          'PSR289 - Add to Shopping List'
        )
      );
    });
  }

  /**
   * Method constucted in order to reuse the removal of a batch of items.
   *
   * @param {} removeBatch
   */
  removeBatchOfItems(removeBatch) {
    return combineLatest(removeBatch).subscribe(
      () => {
        this.zone.run(
          () => {
            this.removed.emit();
          },
          (error) => {
            this.notificationService.addError(error.error.title);
          }
        );
      },
      (error) => {
        this.notificationService.addError(error.error.title);
      }
    );
  }

  /**
   * Removes ALL ITEMS from the ENTIRE cart except the one that was selected.
   *
   * @param {string} itemNumber
   */
  removeAllOtherItems(itemNumber: string) {
    this.removeAllExcept.emit(itemNumber);
  }

  /**
   * Adds a single item to a shopping list.
   *
   * @param {ICommerceItemWithCart} selectedItem
   */
  onAddItemToShoppingList(selectedItem: ICommerceItemWithCart) {
    const addingItem: ICommerceItemWithCart = this.items.find(
      (item: ICommerceItemWithCart) => {
        return item.itemNumber === selectedItem.itemNumber;
      }
    );
    this.addToShoppingList([
      {
        itemNumber: addingItem.itemNumber,
        quantity: +addingItem.quantity,
      },
    ]);
  }

  /**
   * Moves all non-required serial number items to the shopping list.
   *
   * @param {any} event
   */
  moveAllToList() {
    const addingItems: IAddItemToGiftList[] = this.items.map(
      (items: ICommerceItemWithCart) => {
        return { itemNumber: items.itemNumber, quantity: +items.quantity };
      }
    );
    this.addToShoppingList(addingItems);
  }

  /**
   * Reusable method to add items to the shopping list.
   *
   * @param {IAddItemToGiftList[]} items
   */
  addToShoppingList(items: IAddItemToGiftList[]) {
    if (this.isLoggedIn) {
      // Pop open dialog
      this.dialog
        .open(ShoppingListComponent, {
          width: '380px',
          data: {
            items: items,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            // Emit the event to remove the item from the shopping cart.
            this.removeAfterAddingToList(items);
            // Alert the user that items have been added to their shopping list.
            if (items.length === 1) {
              const item = this.items.find(
                (item: ICommerceItemWithCart) =>
                  item.itemNumber === items[0].itemNumber
              );
              this.alertService.addToShoppingList(
                item.productDisplayName
                  ? item.productDisplayName
                  : item.itemNumber
              );
              // Push FB Pixel AddToWishlist Conversion Event
              if (this.platformBrowser) {
                this.ecommService.addToWishlist(
                  item.itemNumber,
                  item.quantity,
                  'PSR289'
                );
              }
            }
          }
        });
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.router.navigate(['/cart']);
          }
        });
    }
  }
}
