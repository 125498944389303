import { IShippingInfoFreightTypeItem } from './ishipping-info-freight-type-item';
import { IShippingInfoShippingOption } from './ishipping-info-shipping-option';
import { ICodeAndDesc } from './icode-and-desc';

export interface IShippingInfoFreightType {
  brokerRequired: boolean;
  brokers: {
    address1: string;
    address2: string;
    address3: string;
    brokerCode: string;
    city: string;
    country: string;
    name: string;
    state: string;
    zipCode: string;
  }[];
  collectAccountRequired: boolean;
  freightTerms: ICodeAndDesc[];
  freightTerm: string;
  freightShippingMethod: string;
  freightType: string;
  incoTermRequired: boolean;
  incoTerms: ICodeAndDesc[];
  itemDetails?: IShippingInfoFreightTypeItem[];
  namedPlace: string;
  shipmentNumber: string;
  shippingOptions: IShippingInfoShippingOption[];
  freightQuoteAmount: number;
  freightQuoted?: boolean;
  nonConvertedFreightAmount?: number;
}

export const FreightShippingMethods = {
  Parcel: 'Parcel',
  Truck: 'Truck',
  Machine: 'Equipment',
  Other: 'Other'
};
