import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of } from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  onErrorResumeNext,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { IInvoice } from '../../../../../contracts/invoice/iInvoice';
import { IInvoiceDto } from '../../../../../contracts/invoice/iInvoice-dto';
import { IInvoicePayloadResponse } from '../../../../../contracts/invoice/iinvoice-payload-response';
import {
  IInvoicePaymentOption,
  IPayInvoiceDto,
} from '../../../../../contracts/invoice/ipay-invoice-dto';
import {
  CCIcons,
  ICreditCard,
} from '../../../../../contracts/user/icredit-card';
import { INavMenu } from '../../../../../contracts/user/inav-menu';
import { IUser, UserEnvironments } from '../../../../../contracts/user/iuser';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { MenuService } from '../../../../../service/user/menu.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { CreditCardDialogComponent } from '../../account-payment-preferences/credit-card-dialog/credit-card-dialog.component';
import { CreditCardService } from '../../account-payment-preferences/credit-card.service';
import { InvoiceInformationService } from './invoice-information.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import { PaymentOptions } from '../../../../../contracts/commerce/dto/ipayment-info';
import { CartService } from '../../../../../service/cart/cart.service';
import { HttpResponse } from '@angular/common/http';
import { ScannerService } from 'app/service/scanner.service';
import { PayPalLoader } from '../../../../../service/product/paypal-loader.service';

@Component({
  selector: 'app-invoice-information',
  styleUrls: ['./invoice-information.component.scss'],
  templateUrl: './invoice-information.component.html',
})
@UntilDestroy({ checkProperties: true })
export class InvoiceInformationComponent implements OnInit, OnDestroy {
  ocids: OcidItems = {};
  user!: IUser;
  searchByOptions: INavMenu = {};
  invoiceInquiryForm!: UntypedFormGroup;
  paymentForm!: UntypedFormGroup;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50];
  dataLength!: number;
  invoices: IInvoice[] = [];
  selectedInvoices: IInvoice[] = [];
  searched = false;
  unpaidSearched = false;
  totalPayment = 0;
  invoicePaid = false;
  invoicePayDto!: IPayInvoiceDto;
  customerNumber = '';
  dateFormat = '';
  activePrint = '';
  showPaypal = false;
  paypalSelected = false;
  paypalVerified = false;
  ccSelected = true;
  ccIcons = CCIcons;
  searchOptionVal = {
    OPEN: 'openInvoice',
    SERIALNUM: 'serialNumber',
  };
  monthlyPayments = false;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  searchEvent: EventEmitter<unknown> = new EventEmitter<unknown>();
  dataSource = new MatTableDataSource<IInvoice>(this.invoices);
  creditCards!: ICreditCard[];
  selectedCC!: ICreditCard;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private ngZone: NgZone,
    private currentUserService: CurrentUserService,
    private notificationService: NotificationService,
    private menuService: MenuService,
    private invoiceInfoService: InvoiceInformationService,
    private _formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private ccService: CreditCardService,
    private cartService: CartService,
    private scanner: ScannerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    // Get ocids.
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.invoice-information',
        'order-history.open-amount',
        'orderdetails.invoice-number',
        'orderdetails.order-number',
        'orderdetails.three-year-limit',
        'parts-order-inquiry.please-select-message',
        'payment.credit-card-error',
        'payment.select-card-error',
        'payment.add-credit-card',
        'payment.credit-card-billing',
        'payment.credit-card-label',
        'global.no-results-message',
        'payment.credit-card-billing',
        'global.credit-card-types',
        'global.type',
        'invoice-information.date-label',
        'invoice-information.negative-error',
        'invoice-information.payment-method-label',
        'invoice-information.receipt-label',
        'invoice-inquiry.total-payment',
        'invoice-inquiry.message-1',
        'invoice-inquiry.search-by',
        'invoice-inquiry.unpaid-invoices',
        'invoice-inquiry.thank-you',
        'invoice-inquiry.search-value',
        'invoice-inquiry.unpaid-invoices-invoice-sequence',
      ])
      .pipe(first())
      .subscribe();
    // Subscribe to menu data.
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? this.menuService.getMenuByUxKey('invoice-inquiry-search-options')
            : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.searchByOptions = menu;
        }
      });
    // Subscribe to user changes to get customer number.
    this.currentUserService.userSubject.subscribe((user) => {
      if (user) {
        this.user = user;
        this.customerNumber = user.customerNumber;
        this.dateFormat = user.appConfig.dateFormat;
      }
    });
    // Build invoice inquiry form and payment forms.
    this.invoiceInquiryForm = this._formBuilder.group({
      searchBy: ['', Validators.required],
      inputNum: [''],
    });
    this.paymentForm = this._formBuilder.group({
      paymentOption: ['creditCard', Validators.required],
      creditCard: ['', Validators.required],
    });
    // Get available payment options
    this.invoiceInfoService
      .getInvoicePaymentOptions()
      .subscribe((options: IInvoicePaymentOption[]) => {
        // show Paypal only if it's listed as a payment option
        this.showPaypal = !!options.find((opt: IInvoicePaymentOption) => {
          return opt.code === PaymentOptions.PayPal;
        });
        if (
          this.showPaypal &&
          this.user.erpEnvironment === UserEnvironments.AA
        ) {
          // load the paypal script if user has paypal option and is AA
          PayPalLoader.load();
          this.monthlyPayments = true;
        }
      });
    // Merge search, sort, and page events into one request. Skip the first request
    // on page load.
    merge(this.searchEvent, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        skip(1),
        switchMap(() => {
          this.notificationService.reset();
          // Build the minimum requirement for request parameters.
          const params: IInvoiceDto = {
            inquiryType: this.invoiceInquiryForm.controls.searchBy.value,
            customerNumber: this.customerNumber,
            limit: this.paginator.pageSize,
            offset: this.paginator.pageIndex + 1,
            sortBy: this.sort.active ? this.sort.active : 'invoiceNumber',
          };
          // If the request is not unpaid invoices, add the value of the form
          // and the sort by and sort direction attributes.
          if (
            this.invoiceInquiryForm.controls.searchBy.value !==
            this.searchOptionVal.OPEN
          ) {
            params.value = this.invoiceInquiryForm.controls.inputNum.value;
            // Since the user is not searching by unpaid invoices, set unpaidSearch to false.
            this.unpaidSearched = false;
          } else {
            // Since the user is searching by unpaid invoices, set unpaidSearch to true.
            this.unpaidSearched = true;
          }
          // If the sort direction is descending, add it to the dto params. Otherwise, default
          // sort is ascending.
          if (this.sort.direction === 'desc') {
            params.sortDir = 'desc';
          }

          // Get the invoices.
          return this.invoiceInfoService.getInvoices(params).pipe(
            catchError((error) => {
              // Show the error to the user.
              this.notificationService.addError(error.error.title);
              // Return null.
              return null;
            }),
            onErrorResumeNext()
          );
        }),
        map((invoices: IInvoicePayloadResponse) => {
          return invoices;
        })
      )
      .subscribe({
        next: (invoices: IInvoicePayloadResponse) => {
          this.invoices = invoices.items;
          // Set all of the data source values and the data length for the paginator.
          this.dataSource.data = this.invoices;
          this.dataLength = invoices.totalResults;
          // Set searched to true to update the UI.
          this.searched = true;
          this.changeDetector.detectChanges();
        },
        error: (error) => {
          this.unpaidSearched = false;
          this.searched = false;
          this.notificationService.addError(error.error.title);
        },
      });
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.data === 'paypalVerified' &&
            event.origin.indexOf('jlg.com') !== -1
          ) {
            return this.cartService.verifyPayPal('Invoice Payment').subscribe({
              next: (resp: HttpResponse<unknown>) => {
                this.paypalVerified = true;
              },
              error: (error) => {
                this.notificationService.addError(error.error.title);
              },
            });
          }
        },
        false
      );
    }
  }

  /**
   * Get the image for the selected invoice.
   *
   * @param {IInvoice} invoice
   */
  getInvoiceImage(invoice: IInvoice) {
    // Only get the invoice image if the platform is browser.
    if (isPlatformBrowser(this.platformId)) {
      // When an invoice is clicked, get the image.

      this.invoiceInfoService
        .getInvoiceImage(
          `/api/v1/invoices/${invoice.invoiceNumber}/${invoice.invoiceSequence}`
        )
        .subscribe({
          next: (indInvoice: string) => {
            if (window.navigator) {
              // If the browser is Chrome or Firefox, open the invoice in a new tab for the user to view.
              // Removed IE support when upgrading to Angular 13.
              const newTab = window.open();
              newTab.document.body.innerHTML = `<iframe src='data:application/pdf;base64,${indInvoice}' width='100%' height='100%'></iframe>`;
            }
          },
          error: (error) => {
            this.notificationService.addError(error.error.title);
          },
        });
    }
  }

  /**
   * Print the invoice table.
   * @param {string} type
   */
  print(type: string) {
    this.activePrint = type;
    // Adding miniscule timeout, otherwise UI won't update when changing active print value.
    setTimeout(() => window.print(), 1);
  }

  /**
   * Clear input.
   */
  clearInput() {
    if (
      this.invoiceInquiryForm.controls.searchBy.value ===
      this.searchOptionVal.OPEN
    ) {
      this.invoiceInquiryForm.controls.inputNum.setValue('');
      this.changeDetector.detectChanges();
    }
  }

  /**
   * Add payment or subtract payment amount.
   *
   * @param {MatCheckboxChange} event
   * @param {IInvoice} invoice
   *
   */
  togglePayment(event: MatCheckboxChange, invoice: IInvoice) {
    // Get current user credit cards if the user is selecting the first invoice.
    if (this.selectedInvoices.length === 0 && event.checked) {
      this.getCreditCards();
    }
    // Set to false in case user has just submitted a payment and wants
    // to pay other invoices.
    this.invoicePaid = false;
    if (event.checked) {
      this.selectedInvoices.push(invoice);
      this.totalPayment = this.totalPayment + +invoice.openAmount;
    } else {
      this.totalPayment = this.totalPayment - +invoice.openAmount;
      this.selectedInvoices.forEach((selectedInvoice: IInvoice, index) => {
        if (selectedInvoice.invoiceNumber === invoice.invoiceNumber) {
          this.selectedInvoices.splice(index, 1);
        }
      });
    }
    this.changeDetector.detectChanges();
  }

  /**
   * When payment option is selected.
   */
  onSelectPaymentOption() {
    const value = this.paymentOption.value;
    this.ccSelected = value === PaymentOptions.CreditCard;
    this.paypalSelected = value === PaymentOptions.PayPal;
    if (this.ccSelected) {
      // Add credit card fields to form
      this.paymentForm.addControl(
        'creditCard',
        this._formBuilder.control(this.selectedCC ? this.selectedCC.id : null, [
          Validators.required,
        ])
      );
      this.getCreditCards();
    } else {
      // Remove credit card fields from form
      this.selectedCC = null;
      this.paymentForm.removeControl('creditCard');
      return this.cartService
        .initPayPal(false, this.totalPayment)
        .pipe(first())
        .subscribe({
          error: (error) => {
            this.notificationService.addError(error.error.title);
          },
        });
    }
  }

  /**
   * Set the selected card.
   *
   * @param {MatRadioChange} event
   *
   */
  onSelectCreditCard(event: MatRadioChange) {
    this.creditCards.forEach((card: ICreditCard) => {
      if (card.id === event.value) {
        this.selectedCC = card;
      }
    });
  }

  /**
   * Add credit card dialog.
   */
  addCreditCard() {
    this.ngZone.run(() => {
      this.dialog
        .open(CreditCardDialogComponent, {
          data: { isAddCreditCard: true },
          width: '720px',
        })
        .afterClosed()
        .subscribe((data: ICreditCard) => {
          if (data) {
            this.getCreditCards();
            this.selectedCC = data;
            this.paymentForm.patchValue({
              creditCard: this.selectedCC.id,
            });
          }
        });
    });
  }

  /**
   * Method to consolidate the retrieval of credit cards.
   */
  getCreditCards() {
    this.notificationService.reset();
    this.ccService.getCreditCards().subscribe({
      next: (cards: ICreditCard[]) => {
        this.creditCards = cards ? cards : [];
      },
      error: (error) => {
        this.notificationService.addError(error.error.title);
        this.changeDetector.detectChanges();
      },
    });
  }

  /**
   * Submit payment of invoices.
   */
  onSubmitPayment() {
    // If the payment form is valid then pay the invoices.
    this.notificationService.reset();
    if (this.paymentForm.valid) {
      this.invoicePayDto = {
        totalAmount: this.totalPayment.toString(),
        creditCardId: this.paypalVerified
          ? PaymentOptions.PayPal
          : this.selectedCC.id,
        invoices: this.selectedInvoices.map((invoice: IInvoice) => {
          return {
            invoiceNumber: invoice.invoiceNumber,
            invoiceSequence: invoice.invoiceSequence,
            invoiceAmount: invoice.openAmount,
            customerNumber: invoice.customerNumber,
            orderType: invoice.orderType,
            comOrderNumber: invoice.comOrderNumber,
          };
        }),
      };
      // Pay the invoices.
      this.invoiceInfoService.payInvoices(this.invoicePayDto).subscribe({
        next: () => {
          this.invoicePaid = true;
          this.paypalVerified = false;
          this.paypalSelected = false;
          this.selectedInvoices = [];
          this.totalPayment = 0.0;
          this.paymentForm.patchValue({
            creditCard: '',
            paymentOption: '',
          });

          this.searchEvent.emit();
        },
        error: (error) => {
          this.invoicePaid = false;
          this.notificationService.addError(error.error.title);
          this.changeDetector.detectChanges();
        },
      });
    }
  }

  /**
   * Method that invokes the search event emitter.
   */
  searchInvoices() {
    if (this.invoiceInquiryForm.valid) {
      this.invoicePaid = false;
      this.selectedInvoices = [];
      this.totalPayment = 0.0;
      this.invoicePayDto = null;
      this.searchEvent.emit();
    }
  }

  /**
   * Determines if the checkbox is checked.
   * @param {IInvoice} selectedInvoice
   * @returns {boolean}
   */
  isChecked(selectedInvoice: IInvoice) {
    const isSelected: boolean = this.selectedInvoices.some(
      (invoice: IInvoice) =>
        invoice.invoiceNumber === selectedInvoice.invoiceNumber
    );
    return isSelected;
  }

  // Get payment option form value
  get paymentOption() {
    return this.paymentForm.get('paymentOption');
  }

  /**
   * Open the barcode scanner
   */
  async openScanner() {
    (await this.scanner.scanSerialNum('Invoice Information')).subscribe(
      (data?) => {
        if (data) {
          this.invoiceInquiryForm.controls.inputNum.setValue(data);
          this.searchInvoices();
        }
      }
    );
  }

  /**
   * Unsubscribe from detector changes.
   */
  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
