// Asset view widgets displayed in the machine details component
import { environment } from '../../../../environments/environment';
import { WidgetSize } from './cs-widget-sizing';
import { Machine } from '../machine/machine.dto';

export const MachineWidgets = {
  GENERAL_MACHINE_INFO: 'machineInfo',
  MACHINE_LOCATION: 'machineLocation',
  ENGINE: 'engine',
  QUICK_LINKS: 'quickLinks',
  MACHINE_STATUS: 'assetMachineStatus',
  MACHINE_UTILIZATION: 'machineUtilization',
  LOCAL_WEATHER: 'machineLocalWeather',
  ASSET_MACHINE_IN_USE: 'assetMachineInUse',
  HISTORIC_DTCS: 'assetHistoricDtcs',
  BATTERY_MONITORING: 'assetBatteryMonitoring',
  BATTERY_MONITORING_GENERAL: 'assetBatteryMonitoringGeneral',
  ACCESS_CONTROL: 'accessControl',
  CONN_DEV: 'connDev',
};

// Asset view widgets enabled in PROD
export const DefaultProdMachineWidgets = [
  MachineWidgets.GENERAL_MACHINE_INFO,
  MachineWidgets.MACHINE_LOCATION,
  MachineWidgets.ENGINE,
  MachineWidgets.QUICK_LINKS,
  MachineWidgets.CONN_DEV,
  MachineWidgets.MACHINE_STATUS,
  MachineWidgets.MACHINE_UTILIZATION,
  MachineWidgets.LOCAL_WEATHER,
  MachineWidgets.BATTERY_MONITORING_GENERAL,
];

// Asset view widgets enabled in DEV/TEST
export const DefaultTestMachineWidgets = [
  ...DefaultProdMachineWidgets,
  MachineWidgets.ACCESS_CONTROL,
];

// Default asset view widgets based on environment
export const DefaultMachineWidgets = environment.production
  ? DefaultProdMachineWidgets
  : DefaultTestMachineWidgets;

// Available prod asset view widgets
export const ProdMachineWidgets = [
  ...DefaultProdMachineWidgets,
  MachineWidgets.HISTORIC_DTCS,
  MachineWidgets.BATTERY_MONITORING,
  MachineWidgets.ASSET_MACHINE_IN_USE,
];

// Available test asset view widgets
export const TestMachineWidgets = [
  ...DefaultTestMachineWidgets,
  MachineWidgets.HISTORIC_DTCS,
  MachineWidgets.BATTERY_MONITORING,
  MachineWidgets.ASSET_MACHINE_IN_USE,
];

// Available asset view widgets based on env
export const AvailableMachineWidgets = environment.production
  ? ProdMachineWidgets
  : TestMachineWidgets;

export const MachineWidgetsDisplay: { [key: string]: string } = {
  machineInfo: 'clearsky.machine-info-label',
  machineLocation: 'clearsky.location-label',
  engine: 'Engine Information',
  assetMachineStatus: 'Machine Status',
  machineUtilization: 'clearsky.utilizations-label',
  machineLocalWeather: 'clearsky.weather-label',
  machineInUse: 'Machine Use Time',
  assetMachineInUse: 'Machine In Use',
  assetHistoricDtcs: 'clearsky.historic-codes-label',
  quickLinks: 'global.quick-links',
  envelopeProfile: 'clearsky.envelope-profile-label',
  assetSetup: 'Machine Setup',
  assetPersonalities: 'clearsky.personalities-label',
  assetBatteryMonitoring: 'Battery Monitoring Detail',
  assetBatteryMonitoringGeneral: 'clearsky.battery-monitoring-label',
  accessControl: 'clearsky.access-default',
  connDev: 'clearsky.connectivity-device-label',
};

export const MachineWidgetsSizing: {
  [widget: string]: WidgetSize;
} = {
  [MachineWidgets.ASSET_MACHINE_IN_USE]: { col: 4, row: 2 },
  [MachineWidgets.HISTORIC_DTCS]: { col: 2, row: 1 },
  [MachineWidgets.MACHINE_LOCATION]: { col: 2, row: 1 },
  [MachineWidgets.HISTORIC_DTCS]: { col: 4, row: 1.5 },
  [MachineWidgets.BATTERY_MONITORING]: { col: 4, row: 1.5 },
  [MachineWidgets.MACHINE_STATUS]: { col: 2, row: 1 },
  [MachineWidgets.MACHINE_UTILIZATION]: { col: 2, row: 1 },
};

export const MachineWidgetsKey = {
  machineInfo: [
    'generalAsset',
    'machineSetupPersonalitiesAsset',
    'machineSetupPersonalitiesAsset',
  ],
  machineLocation: ['assetMap'],
  engine: ['engineInformation'],
  assetMachineStatus: ['machineStatus'],
  machineUtilization: [
    'utilizationAsset',
    'machineUtilization',
    'machineInUseTimeHeatMap',
  ],
  assetMachineInUse: ['machineInUseTimeHeatMap'],
  assetHistoricDtcs: ['assetLevelHistoricalDTCs'],
  envelopeProfile: ['envelopeProfile'],
  assetBatteryMonitoring: ['batteryMonitoringAsset'],
  assetBatteryMonitoringGeneral: ['batteryMonitoringGeneral'],
  accessControl: ['accessControl'],
};

export const hideWidgetFromDashboardMapping: {
  [widget: string]: { property: string; values: unknown[] }[];
} = {
  [MachineWidgets.ENGINE]: [{ property: 'dengInf', values: [true] }],
  [MachineWidgets.BATTERY_MONITORING]: [
    {
      property: 'dbatMon',
      values: [true],
    },
  ],
  [MachineWidgets.BATTERY_MONITORING_GENERAL]: [
    {
      property: 'dbatMon',
      values: [true],
    },
  ],
  [MachineWidgets.ACCESS_CONTROL]: [
    {
      property: 'csDev',
      values: [1],
    },
  ],
  [MachineWidgets.CONN_DEV]: [{ property: 'csDev', values: [1] }],
};

/**
 * Checks to see if widget can show based on machine payload.
 * @param machine
 * @param widget
 */
export const canMachineWidgetShow = (
  machine: Machine,
  widget: string
): boolean => {
  if (hideWidgetFromDashboardMapping[widget]) {
    return hideWidgetFromDashboardMapping[widget].every((key) => {
      // @ts-ignore
      return key.values.some((val) => machine[key.property] === val);
    });
  }

  return true;
};

/**
 * Filter widgets array based on ones that can show based on machine payload.
 * @param machine
 * @param widgets
 */
export const filterShowableMachineWidgets = (
  machine: Machine,
  widgets: string[]
) => {
  return widgets.filter((widget) => canMachineWidgetShow(machine, widget));
};
