import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { ShoppingCartComponent } from './shopping-cart.component';
import { ConsultFactoryComponent } from './consult-factory/consult-factory.component';
import { ShoppingCartItemComponent } from './shopping-cart-item/shopping-cart-item.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';
import { WarningComponent } from './warning/warning.component';
import { CoreReturnWarningComponent } from './warning/core-return-warning/core-return-warning.component';
import { SupersededWarningComponent } from './warning/superseded-warning/superseded-warning.component';
import { PhantomWarningComponent } from './warning/phantom-warning/phantom-warning.component';
import { ConsultFactoryWarningComponent } from './warning/consult-factory-warning/consult-factory-warning.component';
import { PricingNotSetupWarningComponent } from './warning/pricing-not-setup-warning/pricing-not-setup-warning.component';
import { InvalidPartWarningComponent } from './warning/invalid-part-warning/invalid-part-warning.component';
import { Psr289SerialNumReqWarningComponent } from './warning/psr289-serial-num-req-warning/psr289-serial-num-req-warning.component';
import { Psr289SerialNumOptWarningComponent } from './warning/psr289-serial-num-opt-warning/psr289-serial-num-opt-warning.component';
import { MachineOOSWarningComponent } from './warning/machine-oos-warning/machine-oos-warning.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    ShoppingCartComponent,
    ConsultFactoryComponent,
    ShoppingCartItemComponent,
    ShoppingListComponent,
    WarningComponent,
    CoreReturnWarningComponent,
    SupersededWarningComponent,
    PhantomWarningComponent,
    ConsultFactoryWarningComponent,
    PricingNotSetupWarningComponent,
    InvalidPartWarningComponent,
    Psr289SerialNumReqWarningComponent,
    Psr289SerialNumOptWarningComponent,
    MachineOOSWarningComponent,
  ],
  exports: [ConsultFactoryWarningComponent],
})
export class ShoppingCartModule {}
