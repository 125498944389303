import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrder, OrderItemType } from '../../../../contracts/commerce/iorder';
import { CartService } from '../../../../service/cart/cart.service';
import { OrderInquiryService } from '../../account/account-orders/parts-order-inquiry/order-inquiry.service';
import { environment } from '../../../../../environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
})
export class OrderConfirmationComponent implements OnInit {
  ocids: OcidItems = {};
  order!: IOrder;
  insertLocalizedDataAsHTML!: string;
  brand!: string;
  orderTypes = OrderItemType;
  private isJlg = environment.jlgStyling;

  constructor(
    private localization: LocalizationService,
    private cartService: CartService,
    private orderService: OrderInquiryService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => (this.ocids = ocids));

    this.localization
      .getOCIDs([
        'checkout.order-confirmation',
        'buy.order-received-1',
        'buy.order-received-2',
        'buy.order-received-3',
        'buy.order-received-4',
        'email-notification.order-confirmation-line1',
        'email-notification.order-confirmation-reference',
        'buy.order-confirmation-order-num',
        'buy.order-confirmation-order-email',
        'order.confirmation-order-failed-label',
        'order.confirmation-order-failed',
      ])
      .subscribe();

    this.brand = this.isJlg ? 'JLG' : 'Jerr-Dan';
    this.cartService.cartPreviewSubject.next({});
    this.orderService
      .getOrderById(this.route.snapshot.url[2].toString())
      .subscribe((order: IOrder) => {
        this.order = order;
        if (this.order.comOrderNumber) {
          this.insertLocalizedDataAsHTML = `<a routerLink="/my-account/orders/order-details/${this.order.id}">${this.order.comOrderNumber}</a>`;
        }
      });
  }
}
