import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  makeStateKey,
  StateKey,
  TransferState,
} from '@angular/platform-browser';
import { finalize, Observable, of, throwError } from 'rxjs';
import { ContentService } from '../../service/content.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {
  totalRequests = 0;
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private contentService: ContentService,
    private transferState: TransferState
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Only perform the following if the platform is browser. This saves server errors when
    // using URL.

    if (isPlatformBrowser(this.platformId)) {
      // Don't show spinner for flyout requests but still count the request! - O20R-6089
      this.totalRequests++;
      if (!request.url.includes('flyout')) {
        this.contentService.perform();
      }

      // Only save GET requests, not POST requests.
      if (request.method !== 'GET') {
        return this.handleConditions(next.handle(request));
      }

      // Use URL to get the pathname of the request.
      const url: URL = new URL(request.url);
      const path: string = url.pathname;
      // Create the key using the pathname to retrieve the stored response.
      const key: StateKey<string> = makeStateKey(path);
      const storedResponse: string | null = this.transferState.get(key, null);

      // If the user is ssoing, we need to re-retrieve the page payload.
      if (request.headers.has('clear-payload-cache')) {
        // Remove the temporary clear-payload-cache header.
        request = request.clone({
          withCredentials: true,
          headers: request.headers.delete('clear-payload-cache'),
        });

        // Remove the stored response and handle the request as normal.
        this.transferState.remove(key);
        return this.handleConditions(next.handle(request));
      }

      // If the stored response exists, delete the stored response and return the
      // response as an observable.
      if (storedResponse) {
        this.transferState.remove(key);
        const response = new HttpResponse({
          body: storedResponse,
          status: 200,
        });
        return this.handleConditions(of(response));
      }
    }

    // If the platform is not browser OR the stored response does not exist, make
    // the request as normal.
    return this.handleConditions(next.handle(request));
  }

  /**
   * Handle http request.
   */
  handleConditions(
    obs: Observable<HttpEvent<unknown>>
  ): Observable<HttpEvent<unknown>> {
    return obs.pipe(
      catchError((err) => {
        return throwError(() => err);
      }),
      finalize(() => {
        this.totalRequests--;
        // Remove spinner only if all the requests have completed
        if (!this.totalRequests) {
          this.contentService.finish();
        }
      })
    );
  }
}
