import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SignInDialogComponent } from '../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from '../../../service/layout.service';
import { CollapseStack } from '../../../shared/collapse/collapse-stack';
import { distinctUntilChanged } from 'rxjs';
import { OktaAuthWrapper } from '../../../service/auth/okta.auth.wrapper';
  
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-unauthorized-response',
  templateUrl: './unauthorized-response.component.html',
})

export class UnauthorizedResponseComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object, 
    public dialog: MatDialog,
    private oktaAuth: OktaAuthWrapper,
    public layoutService: LayoutService,
    private collapseStack: CollapseStack
  ) {}
  
  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      // Determine if the user is logged in or not.
      this.oktaAuth.isLoggedIn
      .pipe(distinctUntilChanged())
      .subscribe((isLoggedIn: boolean) => {
        if(!isLoggedIn){
          // hide the login dropdown
          this.layoutService.setAuthCollapsed(true);
          this.collapseStack.dismissAll();
          //open the login modal
          this.dialog.open(SignInDialogComponent, {
            panelClass: ['sign-in-dialog'],
            width: '720px',
          })
        }
      });
    }
  }
} 