import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, concatMap, map, mergeMap } from "rxjs/operators";
import { IAddress } from "../../contracts/user/iaddress";
import { CurrentUserService } from "./current-user.service";
import { IAddressPagination } from "../../contracts/user/iaddress-pagination";
import { IAddressPaginationDto } from "../../contracts/user/dto/iaddress-pagination-dto";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmAddressComponent } from '../../shared/confirm-address/confirm-address.component';

@Injectable()
export class UserAddressesService {
  public addressesPayloadSubject: BehaviorSubject<{totalResults, items: IAddress[]}> = new BehaviorSubject<
    {totalResults, items: IAddress[]}
  >({ totalResults: 0, items: [] });
  private addressesPayload: {totalResults, items: IAddress[]} = { totalResults: 0, items: [] }

  constructor(private http: HttpClient,
              private currentUserService: CurrentUserService,
              private dialog: MatDialog) { }

  /**
   * Get addresses in paginated format.
   *
   * @param {IAddressPaginationDto} params
   * @param {string} defaultShippingAddress
   * @returns {Observable<IAddressPagination>}
   */
  getAddressPagination(params: IAddressPaginationDto, defaultShippingAddress?: string) {
    return this.http.post<IAddressPagination>(`${environment.apiUrl}/currentUser/addresses/addressesPagination`,
    params,
    {
      headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
    })
    .pipe(map((res: IAddressPagination) => {
      if (res.totalResults > 0) {
        if (defaultShippingAddress) {
          res.items.forEach((address) => {
            address['isDefault'] = address.id === defaultShippingAddress;
          })
        }
      }
      this.addressesPayloadSubject.next(res);
      this.addressesPayload = res;
      return res;
    }, (error) => {
      return throwError(error);
    }));
  }

  /**
   * Get user addresses.
   *
   * @param {string} type
   * @returns {Observable<IAddress[]>}
   */
  public getAddressesByType(type?: string): Observable<IAddress[]> {
    let params;
    const fullType = this.getAddressTypeName(type);

    if (fullType !== null) {
      params = `?type=${fullType}`;
      return this.getAddresses(params);
    }

    return throwError('You did not set a type!');
  }

  /**
   * Get full address type name.
   *
   * @param {string} type
   * @returns string
   */
  protected getAddressTypeName(type?: string): string {
    switch (type) {
      case 'shipping':
        return 'secondaryAddresses';
      case 'billing':
        return 'billingAddress';
      case 'home':
        return 'homeAddress';
      case 'secondary':
        return 'secondaryAddress';
      default:
        return null;
    }
  }

  /**
   * Get addresses.
   *
   * @param {string} extras
   * @returns {Observable<IAddress[]>}
   */
  public getAddresses(extras?: string): Observable<IAddress[]> {
    const params = extras ? extras : '';

    return this.http.get(`${environment.apiUrl}/currentUser/addresses${params}`)
      .pipe(
        map((res: { items: object[] }) => {
          return <IAddress[]>res.items;
        })
      );
  }

  /**
   * Get user address by ID.
   *
   * @param {string} type
   * @param {string} id
   * @returns {Observable<IAddress>}
   */
  getAddress(type: string, id: string): Observable<IAddress> {
    return this.http.get<IAddress>(`${environment.apiUrl}/currentUser/addresses/${id}`);
  }

  /**
   * Check if address is valid.
   *
   * @param {IAddress} address
   * @returns {Observable<IAddress | boolean | string>}
   */
  public isAddressValid(address: IAddress): Observable<IAddress | boolean | string> {
    return this.http.post(`${environment.utilsBaseUrl}/validateAddress`, {
      sourceSystem: 'OLE',
      address: {
        consigneeName: '',
        buildingName: '',
        addressLine: address.address1,
        city: address.city,
        state: address.state,
        zip: address.postalCode,
        country: address.country
      }
    },
    {
      headers: new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
    }).pipe(
      map((data: any) => {
        if(address.country === 'US'){
          if (data.addressList.length > 0) {
          let validAddress = data.addressList[0];

            if (data.addressList.length === 1 &&
              validAddress.addressLine.toLowerCase() === address.address1.toLowerCase() &&
              validAddress.city.toLowerCase() === address.city.toLowerCase() &&
              validAddress.state === address.state &&
              validAddress.zip.substring(0, 5) === address.postalCode
              ) {
              return 'valid';
            } else {
              return data.addressList;
            }
          }
        } else {
          // for other countries the service will return an error if the address in invalid
          return 'valid';
        }
        return false;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

public confirmAddress(
    returnedAddress: any,
    originalAddress: IAddress):  Observable<any | boolean> {
      const addressModal = this.dialog.open(ConfirmAddressComponent, {
      data: {
        alternates: returnedAddress,
        duplicates: [], //placeholder for duplicate addresses found
        originalAddress: {
            addressLine: originalAddress.address1,
            address2:  originalAddress.address2,
            address3:  originalAddress.address3,
            city: originalAddress.city,
            state: originalAddress.state,
            zip: originalAddress.postalCode,
          }
      }
      });

      return addressModal.afterClosed();
}

  /**
   * Add new address.
   *
   * @param {IAddress} address
   * @param {boolean} isDefault
   * @returns {Observable<IAddress | boolean>}
   */
  public addAddress(address: IAddress, isDefault?: boolean): Observable<IAddress | boolean> {
    address.makeDefault = isDefault;
    // Validate if address is US
    if (address.country === 'US') {
      return this.isAddressValid(address).pipe(
        concatMap((value: IAddress | boolean) => {
          if (! value) {
            return throwError('Address is invalid.');
          }

          // Valid address
          return this.addValidAddress(address) as Observable<IAddress>;
        })
      )
    } else {
      return this.addValidAddress(address) as Observable<IAddress>;
    }
  }

  /**
   * Add new valid address.
   *
   * @param {IAddress} address
   * @param {boolean} isDefault
   * @returns {Observable<IAddress>}
   */
  public addValidAddress(address: IAddress): Observable<IAddress> | Observable<{ address: IAddress }> {
    // If you don't remove makeDefault (since it is added from the form) it will throw
    // an error when adding the address.
    const isDefault = address.makeDefault;
    delete address.makeDefault;
    let req = this.http.post<{address: IAddress, duplicateAddress: boolean}>(`${environment.apiUrl}/currentUser/addresses/addAddress`, address);

    if (isDefault) {
      return req.pipe(
        mergeMap((value: {address: IAddress, duplicateAddress: boolean}) => {
          if (value.duplicateAddress && !address.addAsNewAddress) {
            return req;
          } else {
            return this.makeDefaultAddress(value.address).pipe(
              map(any => value)
            )
          }
        })
      );
    } else {
      return req;
    }
  }

  /**
   * Delete address
   *
   * @param {string} shipToNumber
   * @returns {Observable<any>}
   */
   public deleteAddress(shipToNumber: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/currentUser/addresses/shipToDeactivate`, {
      "shipToNumber": shipToNumber
    }).pipe(map(
      (res: any) => {
        return res;
      }
    ), catchError(error => throwError(error)));
   }

  /**
   * Make address user's default address.
   *
   * @param {IAddress} address
   * @param {boolean} returnAddressPayload
   * @returns {Observable<any>}
   */
  public makeDefaultAddress(address: IAddress, returnAddressPayload: boolean = true): Observable<any> {
    const defaultId = address.id;
    return this.http.post(`${environment.apiUrl}/currentUser/addresses/defaultAddress`, {
      "addressId": defaultId.toString(),
      "defaultType": "shippingAddress"
    },
    {
      headers: new HttpHeaders()
      .set('accept', 'application/json')
      .set('Content-Type', 'application/json')
    }).pipe(map(
      (res: any) => {
        if (returnAddressPayload) {
          const newAddressPayload = this.addressesPayloadSubject.getValue();
          // Set the new default shipping address and deselect the old default shipping address
          newAddressPayload.items.forEach(address => {
            if (address.id === defaultId) {
              address['isDefault'] = true;
            } else {
              address['isDefault'] = false;
            }
          });
          this.addressesPayloadSubject.next(newAddressPayload);
        }
        // If a default shipping address already exists for the user, change it
        if (this.currentUserService._userSubject.getValue().shippingAddress) {
          const defaultShippingAddress = Object.assign(
            this.currentUserService._userSubject.getValue(), {
              shippingAddress: address
            });
          this.currentUserService._userSubject.next(defaultShippingAddress);
        } else {
          // If a default shipping address does not already exist for the user, add it
          const currentUser = this.currentUserService._userSubject.getValue();
          currentUser['shippingAddress'] = address;
          this.currentUserService._userSubject.next(currentUser);
        }
        return res;
      }
    ), catchError(error => throwError(error)));
  }
}
