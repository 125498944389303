import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { CartridgeInterface } from '../../endeca/cartridge/cartridge.class';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from '../../service/user/current-user.service';
import { ContentService } from '../../service/content.service';
import { NotificationService } from '../../service/notification/notification.service';
import { WindowRefService } from '../../service/window-ref/window-ref.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-requestform',
  templateUrl: './request-form.component.html',
})
export class RequestFormComponent implements OnInit, CartridgeInterface {
  platformBrowser = false;
  ocids: OcidItems;
  requestDemoForm: UntypedFormGroup;
  formSubmitted = false;
  @Input() data: {
    title: string;
    text: string;
    ctaLabel: string;
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private formBuilder: UntypedFormBuilder,
    private userService: CurrentUserService,
    private contentService: ContentService,
    private notificationService: NotificationService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.requestDemoForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      companyName: [''],
      email: [''],
      phoneNumber: [''],
    });
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.required-text',
        'clearsky.subscription-contact-us-popup',
      ])
      .subscribe();
  }
  submitForm(): void {
    this.notificationService.reset();
    if (this.requestDemoForm.valid) {
      const formData = {
        ...this.requestDemoForm.value,
        visitorId: this.contentService.getCookieValue('visitor_id471491'),
      };

      this.userService.updateInPardot(formData).subscribe({
        next: () => {
          // Send form success event to GA
          if (this.platformBrowser) {
            const dataLayer = (this.winRef.nativeWindow.dataLayer =
              this.winRef.nativeWindow.dataLayer || []);
            dataLayer.push({
              event: 'formEvent',
              formAction: 'Success',
              formName: 'Clearsky Demo Form',
            });
          }
          this.formSubmitted = true;
        },
        error: (error) => {
          this.notificationService.addError(error.error.title);
        },
      });
    }
  }
}
