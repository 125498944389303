import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ISearchHistoryItem} from '../../../contracts/ISearchHistoryItem';

@Injectable()
export class SearchService {
  termKey = 'currentSearchTerm';
  isSearchFocused: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  currentSearchInput: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentSearchInput$: Observable<string> =
    this.currentSearchInput.asObservable();
  currentSearchTerm: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.getCurrentSearchTerm()
  );
  currentSearchTerm$: Observable<string> =
    this.currentSearchTerm.asObservable();
  searchResults = [];
  searchResultsSubject: BehaviorSubject<object[]> = new BehaviorSubject<
    object[]
  >([]);
  isResultsListSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  historyKey = 'searchHistoryItems';
  historyItemsSubject: BehaviorSubject<Array<ISearchHistoryItem>> =
    new BehaviorSubject<Array<ISearchHistoryItem>>(this.getHistory());
  termIsClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isGlobalSearchActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  hasFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showFlyOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  minCharacters = 3;
  // For tracking up/down arrow keys in the search.
  linkIndex: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  linkIndex$: Observable<number> = this.linkIndex.asObservable();
  currentSectionIndex: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentSectionIndex$: Observable<number> = this.linkIndex.asObservable();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  /**
   * Get current search term that user is typing in search bar.
   *
   * @returns {string | null}
   */
  getCurrentSearchTerm() {
    return isPlatformBrowser(this.platformId)
      ? localStorage.getItem(this.termKey) || ''
      : '';
  }

  /**
   * - Set current search term that user is typing in search bar.
   * - Also sets the currentSearchTerm behavior subject in the search service.
   * @param {string} term
   */
  setCurrentSearchTerm(term: string) {
    const _term = decodeURIComponent(term);
    localStorage.setItem(this.termKey, _term);
    this.currentSearchTerm.next(_term);
  }

  /**
   * Record search item.
   *
   * @param {string} term
   * @param {string} url
   */
  recordSearch(term: string, url: string) {
    // Check if term already exists. If it does, remove it.
    if (term != '' && url != '') {
      this.removeRecordInHistory(term);
      const items: Array<ISearchHistoryItem> = this.getHistory();
      // Record at top of history items
      items.unshift(<ISearchHistoryItem>{
        term: term,
        url: url,
      });
      this.setHistory(items);
    }
  }

  /**
   * Remove record item in history list.
   *
   * @param {string} term
   */
  removeRecordInHistory(term: string) {
    const history = this.getHistory();

    // Find index of matching term
    const index = history.findIndex((element: ISearchHistoryItem) => {
      return element.term === term;
    });

    // Remove it if found
    if (index >= 0) {
      history.splice(index, 1);
    }

    this.setHistory(history);
  }

  /**
   * Get search history.
   *
   * @returns {any[]}
   */
  getHistory() {
    const items = isPlatformBrowser(this.platformId)
      ? localStorage.getItem(this.historyKey)
      : null;
    return JSON.parse(items) || [];
  }

  /**
   * Set history.
   *
   * @param {Array<ISearchHistoryItem>} items
   */
  protected setHistory(items: Array<ISearchHistoryItem>) {
    localStorage.setItem(this.historyKey, JSON.stringify(items));
    this.historyItemsSubject.next(items);
  }

  /**
   * Clear search history in local storage.
   */
  clearHistory() {
    this.setHistory([]);
  }

  /**
   * Set search results from cartridge.
   *
   * @param {object[]} results
   */
  setSearchResults(results: object[]) {
    this.searchResults = results;
    this.searchResultsSubject.next(results);
  }
}
