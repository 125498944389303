import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class IconModule {
  private path = '/assets/icon';
  private customIcons: { name: string; path: string; namespace: string }[] = [
    {
      namespace: 'clearsky',
      name: 'access_control_creep',
      path: '/clearsky/access-control-creep.svg',
    },
    {
      namespace: 'clearsky',
      name: 'access_control_creep_transport',
      path: '/clearsky/access-control-creep-restricted-to-transport.svg',
    },
    {
      namespace: 'clearsky',
      name: 'access_control_locked',
      path: '/clearsky/access-control-locked.svg',
    },
    {
      namespace: 'clearsky',
      name: 'access_control_unlocked',
      path: '/clearsky/access-control-unlocked.svg',
    },
  ];

  constructor(
    private domSanitizer: DomSanitizer,
    public matIconRegistry: MatIconRegistry,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.customIcons.forEach((icon) => {
        this.matIconRegistry.addSvgIconInNamespace(
          icon.namespace,
          icon.name,
          this.setPath(`${window.location.origin}${this.path}${icon.path}`)
        );
      });
    }
  }

  private setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
