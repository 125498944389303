import { Component, OnInit } from '@angular/core';
import { ClearskyService } from '../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-percentage-bar',
  templateUrl: './fleet-percentage-bar.component.html',
  styleUrls: ['./fleet-percentage-bar.component.scss'],
})
export class FleetPercentageBarComponent implements OnInit {
  unFilteredPerc = 0;
  unFilteredCount = 0;
  ocids: OcidItems = {};

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.assets-label', 'clearsky.of-fleet-label'])
      .pipe(first())
      .subscribe();

    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .subscribe((res) => {
        if (res && res.aggregations) {
          this.unFilteredCount = res.aggregations.machineCount
            ? res.aggregations.machineCount.total
            : 0;

          const totalCustMachines =
            res && res.aggregations
              ? res.aggregations.totalCustMachinesWithDevice
              : 0;
          if (this.unFilteredCount === 0 && totalCustMachines === 0) {
            this.unFilteredPerc = 0;
          } else {
            this.unFilteredPerc = Math.round(
              (this.unFilteredCount / totalCustMachines) * 100
            );
          }
        }
      });
  }
}
