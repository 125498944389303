import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-grid-blocks-item',
  templateUrl: './grid-blocks-item.component.html',
  styleUrls: ['./grid-blocks-item.component.scss'],
})
export class GridBlocksItemComponent implements OnInit {
  @Input() col = 1;
  @Input() row = 1;
  @HostBinding('class.grid-blocks-item') isGridItem = true;

  constructor(private renderer2: Renderer2, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.renderer2.addClass(
      this.elementRef.nativeElement,
      `grid-blocks-item-col-${this.col.toString().replace('.', '-')}`
    );
    this.renderer2.addClass(
      this.elementRef.nativeElement,
      `grid-blocks-item-row-${this.row.toString().replace('.', '-')}`
    );
  }
}
