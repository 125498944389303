import {
    Component,
    Input,
  } from '@angular/core';
  import { UntilDestroy } from '@ngneat/until-destroy';
import { IOrderInquiryDto } from '../../../../../../contracts/orders/dto/iorder-inquiry-dto';
import { OcidItems } from '../../../../../../contracts/ocid-items';
import { MatTableDataSource } from '@angular/material/table';
import { IOrderItem } from '../../../../../../contracts/orders/iorder-item';
  
  @UntilDestroy({ checkProperties: true })
  @Component({
    selector: '[app-orders-table]',
    templateUrl: './orders-table.component.html',
  })
  export class OrdersTableComponent {
    @Input('previousParams') previousParams: IOrderInquiryDto;
    @Input('ocids') ocids: OcidItems;
    @Input('dateFormat') dateFormat: string;
    @Input('dataSource') dataSource: MatTableDataSource<any>;
    
    constructor() {}

    identify(index: number, item: IOrderItem): string {
        return item.comOrderNumber;
      }
  }