import { Component, OnInit } from '@angular/core';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-customize-button',
  templateUrl: './customize-button.component.html',
})
export class CustomizeButtonComponent implements OnInit {
  ocids: OcidItems = {};
  constructor(private localization: LocalizationService) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.customize-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
