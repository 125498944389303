import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-grid-blocks-item-icon',
  templateUrl: './grid-blocks-item-icon.component.html',
  styleUrls: ['./grid-blocks-item-icon.component.scss'],
  host: {'class': 'grid-blocks-item-icon'}
})
export class GridBlocksItemIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
