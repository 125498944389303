import { Component, OnInit } from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { ClearskyService } from '../../../clearsky.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { combineLatestWith } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PieChartData } from '../../../../shared/chart/pie-chart/pie-chart-data';
import { OcidItems } from '../../../../contracts/ocid-items';
import { CsAggCountData } from '../../../../contracts/clearsky/agg-data';
import { CSRefBasic } from '../../../../contracts/clearsky/clearsky-legend';
import {
  CSFilter,
  toggleCsFilterVal,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-state-of-charge-widget',
  templateUrl: './state-of-charge-widget.component.html',
  styleUrls: ['./state-of-charge-widget.component.scss'],
})
export class StateOfChargeWidgetComponent implements OnInit {
  displayName = WidgetsDisplay.stateOfCharge;
  widgetName = Widgets.STATE_OF_CHARGE;
  data: PieChartData[] = [];
  ocids: OcidItems = {};
  isLoading = true;
  showChart = false;
  private filter: unknown[];
  private colors = {
    1: WidgetColors.red,
    2: WidgetColors.orange,
    3: WidgetColors.green,
    4: WidgetColors.blue,
  };
  private aggData: CsAggCountData[];
  private legend: CSRefBasic[];
  private options: unknown[];
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.subs = this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .pipe(
        combineLatestWith(
          this.localization.getOCIDs([
            'clearsky.graphic-default',
            this.displayName,
          ]),
          this.clearskyService.getCurrentFilterValues(CSFilter.socQ.key),
          this.clearskyService.getValuesByFilter(CSFilter.socQ.key),
          this.clearskyService.legendRef$
        )
      )
      .subscribe(([page, ocids, filter, options, legend]) => {
        this.ocids = ocids;
        this.aggData =
          (page && page.aggregations && page.aggregations.socQ) || [];
        this.legend = legend.socQ || [];
        this.filter = filter;
        this.options = options;
        this.setChartData();
        this.isLoading = false;
      });
  }

  /**
   * Toggle filter value (legend).
   * @param key
   */
  toggleFilter(key: unknown): void {
    this.clearskyService.updateFilter(
      CSFilter.socQ.key,
      toggleCsFilterVal(key, this.filter, this.options)
    );
  }

  /**
   * Update filter to exact value (chart click).
   * @param e
   */
  updateFilter(e: unknown): void {
    const event = e as { point: { key: number } };
    this.clearskyService.updateFilter(CSFilter.socQ.key, [event.point.key]);
  }

  /**
   * Set chart data.
   * @protected
   */
  private setChartData(): void {
    this.data = [];

    // Now loop through each available option and gather its statistics
    this.legend.forEach((opt) => {
      const d = this.aggData.find((r) => r.id === opt.id);
      const data = {
        key: opt.id,
        name: opt.desc,
        y: d ? d.cnt : 0,
        color: this.colors[opt.id] || '#eee',
        visible: this.filter.length ? this.filter.includes(opt.id) : true,
      };

      this.data.push(data);
    });

    this.showChart = true;
  }
}
