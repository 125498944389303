import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ICustomerResult } from '../../../contracts/user/icustomer-result';
import { INavMenu, IUserNavMenus } from '../../../contracts/user/inav-menu';
import { IUser, SiteID } from '../../../contracts/user/iuser';
import { CartService } from '../../../service/cart/cart.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { MenuService } from '../../../service/user/menu.service';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { CSRDialogComponent } from './csr-dialog/csr-dialog.component';
import { OktaAuthWrapper } from '../../../service/auth/okta.auth.wrapper';
import { CollapseStack } from '../../../shared/collapse/collapse-stack';
import { LayoutService } from '../../../service/layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../contracts/ocid-items';
import * as Sentry from '@sentry/angular-ivy';
import { ClearskyService } from 'app/clearsky/clearsky.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-auth-container]',
  styleUrls: ['./auth-container.component.scss'],
  templateUrl: './auth-container.component.html',
})
export class AuthContainerComponent implements OnInit {
  csrForm!: UntypedFormGroup;
  isActive = false;
  givenName!: string;
  ocids: OcidItems = {};
  welcomeMenuOptions!: INavMenu;
  canProxy = false;
  private currentUrl!: string;
  platformBrowser = false;
  enableClickOutside$: Observable<boolean> = this.layoutService.isDesktop$;
  authCollapsed!: boolean;
  site!: string;
  customerNumber!: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private oktaAuth: OktaAuthWrapper,
    private ngZone: NgZone,
    private confirmationService: ConfirmationDialogsService,
    private currentUserService: CurrentUserService,
    private cartService: CartService,
    private dialog: MatDialog,
    private localization: LocalizationService,
    private fb: UntypedFormBuilder,
    private menuService: MenuService,
    private changeDetector: ChangeDetectorRef,
    public layoutService: LayoutService,
    private collapseStack: CollapseStack,
    private clearskyService: ClearskyService
  ) {}

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
      }
    });

    this.layoutService.authCollapsed$.subscribe(
      (collapsed) => (this.authCollapsed = collapsed)
    );

    // Subscribe to ocids. If ocids have been fetched (by app component), get the ocids for
    // this component. Without this check, this call will end up calling
    // getOCIDs() on page load before authentication has finished.
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });

    this.csrForm = this.fb.group({
      customerNumber: [
        null,
        [Validators.required, Validators.pattern('[0-9]*')],
      ],
    });

    // Subscribe to menu data. If the menu exists, get the welcome menu. This prevents multiple
    // unnecessary calls to getMenu. Without this check, all of these will end up calling
    // getMenu().
    this.menuService.menus$
      .pipe(
        mergeMap((menus: IUserNavMenus) => {
          if (menus) {
            // Get the welcome menu and secure buttons menu.
            return combineLatest([
              this.menuService.getMenuByUxKey('welcome-menu'),
              this.menuService.getMenuByUxKey('secure-buttons'),
            ]);
          }
          return of([]);
        })
      )
      .subscribe((menus) => {
        if (menus) {
          this.welcomeMenuOptions = menus[0];
          // If the menu exists...
          if (menus[1]) {
            // ... determine if the user is able to proxy or not.
            this.canProxy =
              menus[1].childMenus.filter(
                (subMenu: INavMenu) => subMenu.uxKey === 'account-proxy-button'
              ).length > 0;
          }
        }
      });

    // Set given name
    this.currentUserService.userSubject.subscribe((user: IUser | undefined) => {
      if (!user) {
        this.givenName = '';
        return;
      }
      this.layoutService.setAuthCollapsed(true); // Show auth container if not logged in by default
      this.givenName = user.firstName;
      this.site = user.siteId === SiteID.JLG ? 'JLG' : 'Jerr-Dan';
      this.customerNumber = user.customerNumber;
      // passing customer data to Sentry
      Sentry.setUser({
        email: user.email,
        id: user.customerNumber,
      });
      if (user.inProxy) {
        this.csrForm.get('customerNumber').patchValue(user.customerNumber);
      }
    });

    // Every time the user changes from mobile to desktop, show auth menu on tablet
    combineLatest([
      this.layoutService.isDesktop$,
      this.oktaAuth.isLoggedIn,
    ]).subscribe(([isTablet, isLoggedIn]) => {
      this.toggle((isTablet && isLoggedIn) || !isTablet, undefined);
    });
  }

  /**
   * Event emitted when logging out.
   *
   * @param clearShoppingCart
   */
  logout(clearShoppingCart: boolean) {
    this.oktaAuth.logOutAndResetOle(clearShoppingCart).subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  /**
   * Event emitted when opening modal for searching customers.
   */
  searchCSRaccount() {
    this.dialog
      .open(CSRDialogComponent, {
        width: '80%',
        maxWidth: '820px',
      })
      .afterClosed()
      .subscribe((data?: ICustomerResult) => {
        if (data) {
          this.csrForm.get('customerNumber').patchValue(data.customerNumber);

          // Toggle menu
          this.isActive = true;
        }
      });
  }

  /**
   * Event emitted when changing account.
   *
   * @param {FormGroup} form
   */
  changeAccount(form: UntypedFormGroup) {
    if (form.valid) {
      // Prevent change detection issues by running ngZone.
      this.ngZone.run(() => {
        // Close the dropdown.
        this.isActive = false;
        // proxy account and get customer data

        this.currentUserService
          .switchToCustomer(<number>form.get('customerNumber').value)
          .pipe(
            mergeMap(() => {
              return forkJoin([
                this.menuService.getMenus(),
                this.currentUrl === '/cart'
                  ? of(null)
                  : this.cartService.getCart(),
                this.localization.refreshOCIDs(),
              ]);
            })
          )
          .subscribe(
            () => {
              this.changeDetector.detectChanges();
              // Navigate to the same url in order to refresh the page.
              this.clearskyService.forceMachinesReload();
              this.router.navigateByUrl(
                this.currentUrl.startsWith('/clearsky')
                  ? '/clearsky'
                  : this.currentUrl
              );
            },
            (error) => {
              // Show the user the proxy error.
              this.confirmationService
                .confirm(error.error.title, '', this.ocids['global.close'], '')
                .subscribe(() => {
                  // On close, open up the dropdown.
                  this.isActive = true;
                });
            }
          );
      });
    }
  }

  /**
   * Reset customer selection.
   *
   * @param {FormGroup} form
   */
  resetProxy(form: UntypedFormGroup) {
    // Prevent change detection issues by running ngZone.
    this.ngZone.run(() => {
      this.currentUserService
        .endSwitchToCustomer()
        .pipe(
          mergeMap(() => {
            return forkJoin(
              this.menuService.getMenus(),
              this.currentUrl === '/cart'
                ? of(null)
                : this.cartService.getCart(),
              this.localization.refreshOCIDs()
            );
          })
        )
        .subscribe(
          () => {
            form.reset();
            this.changeDetector.detectChanges();
            // Navigate to the same url in order to refresh the page.
            this.router.navigateByUrl(
              this.currentUrl.startsWith('/clearsky')
                ? '/clearsky'
                : this.currentUrl
            );
          },
          (error) => {
            console.log(error);
          }
        );
    });
  }

  /**
   * Check the focus or blur events from our form inputs.
   * @param event
   */
  checkFocus(event: Event) {
    const vm = this;
    setTimeout(function () {
      vm.isActive = event.type === 'focus';
    }, 50);
  }

  /**
   * Set auth collapsed.
   * @param collapsed
   */
  setAuthCollapsed(collapsed: boolean): void {
    this.layoutService.setAuthCollapsed(collapsed);
  }

  /**
   * If closing the auth is true, remove overlays.
   *
   * @param close
   * @param e
   * @private
   */
  toggle(close: boolean, e?: Event) {
    if (e) {
      e.stopPropagation();
    }

    this.layoutService.setAuthCollapsed(close);
    if (close) {
      this.collapseStack.dismissAll();
    }
  }
}
