import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyDashboardService } from 'app/clearsky/clearsky-dashboard.service';
import { CsDashboard } from 'app/contracts/clearsky/dashboard/cs-dashboard.dto';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-email-dashboard-dialog',
  templateUrl: './email-dashboard-dialog.component.html'
})
export class EmailDashboardDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EmailDashboardDialogComponent>,
    private fb: UntypedFormBuilder,
    private localization: LocalizationService,
    private dashboardService: ClearskyDashboardService,
    private snackbar: MatSnackBar,
  ) { }
  errorMessage: string | null = null;
  ocids: OcidItems;
  form: UntypedFormGroup = this.fb.group({
    name: this.fb.control(''),
    shareWith: this.fb.control(''),
  });
  loadedDashboard$: Observable<CsDashboard> =
    this.dashboardService.loadedDashboard$;

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.email-comma-default',
        'clearsky.email-filter-default',
        'clearsky.email-dashboard-dashboardname',
        'clearsky.email-dashboard'
      ])
      .pipe(first())
      .subscribe();
  }

  /**
  * On save dashboard.
  */
  onSave(): void {
    this.errorMessage = null;
    this.loadedDashboard$
      .pipe(
        take(1),
        switchMap((dashboard: CsDashboard) => {
          // Do we need to share this dashboard?
          return this.dashboardService
            .share(dashboard.id, this.form.get('shareWith')?.value)
            .pipe(
              tap(() => {
                this.snackbar.open(
                  `${this.ocids['global.email-sent-label']}.`,
                  undefined,
                  {
                    duration: 3000,
                    verticalPosition: 'top',
                  }
                );
              })
            );
        }),
        catchError((error) => {
          this.errorMessage = error.error.title;
          return of(error);
        })
      )
      .subscribe((response) => {
        if (response?.status === 400) {
          return;
        }
        this.dialogRef.close(response);
      });
  }
}
