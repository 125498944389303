import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  FreightShippingMethods,
  IShippingInfoFreightType,
} from '../../../../../contracts/commerce/ishipping-info-freight-type';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { IUser } from '../../../../../contracts/user/iuser';
import { IShippingInfoShippingOption } from '../../../../../contracts/commerce/ishipping-info-shipping-option';
import { IShippingInfo } from '../../../../../contracts/commerce/ishipping-info';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IShippingGroup } from '../../../../../contracts/commerce/ishipping-group';
import { ConfirmationDialogsService } from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shipping-freight-type-form',
  templateUrl: './shipping-freight-type-form.component.html',
})
export class ShippingFreightTypeFormComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() shippingInfo: IShippingInfo;
  @Input() freightInfo: IShippingInfoFreightType;
  @Input() formSubmitted: boolean;
  @Output() freightChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() cartShippingGroup?: IShippingGroup;
  protected isPrimary: boolean;
  public isEquipment: boolean;
  public secondaryFieldsOptional: boolean;
  public filteredTypeOptions: IShippingInfoShippingOption[] = [];
  public user: IUser;
  public carrierMethods: IShippingInfoShippingOption[] = [];
  public ocids: {} = {};
  incoTermHelpText = '';
  namedPlaceHelpText = '';
  collectAccountExists = false;
  changedToCollect = false;
  changedToTPB = false;
  fieldDisabled = false;

  constructor(
    private userService: CurrentUserService,
    private fb: UntypedFormBuilder,
    private localization: LocalizationService,
    private confirmationService: ConfirmationDialogsService
  ) { }

  ngOnInit() {
    this.localization
      .getOCIDs([
        'global.no-special-character-message',
      ])
      .pipe(first())
      .subscribe();
    // Setup localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
      if (this.freightInfo.incoTermRequired) {
        this.incoTermHelpText =
          this.ocids['shipping-options.incoterms-info-line1'] +
          '\n\n' +
          this.ocids['shipping-options.incoterms-info-line2'];
        this.namedPlaceHelpText =
          this.ocids['shipping-options.named-place-info'];
        this.form.addControl(
          'incoTermCode',
          this.fb.control(
            this.cartShippingGroup ? this.cartShippingGroup.incoterm : null
          )
        );
        this.form.addControl(
          'namedPlace',
          this.fb.control(
            this.cartShippingGroup ? this.cartShippingGroup.namedPlace : null
          )
        );
      }
    });

    // Get user information
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;

        // Build form
        this.buildForm();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // checking this because we're hiding truck shipping options if there are no truck items,
    // so those fields need to be optional as well
    this.secondaryFieldsOptional =
      !this.freightInfo.itemDetails?.length &&
      this.freightInfo.freightShippingMethod === FreightShippingMethods.Truck;
    this.isPrimary =
      this.freightInfo.freightShippingMethod === FreightShippingMethods.Parcel;
    this.isEquipment =
      this.freightInfo.freightShippingMethod === FreightShippingMethods.Machine;

    // if there are no parcel items, disable all the primary shipping option fields
    this.fieldDisabled = !this.freightInfo.itemDetails?.length;
    setTimeout(() => {
      if (this.fieldDisabled) {
        this.form.controls.namedPlace?.disable();
      }
    }, 0);

    if (changes['freightInfo'] && !changes['freightInfo'].isFirstChange()) {
      this.buildForm();
    }
    // If the user has changed the freight billing method (primary or secondary) to Collect,
    // but the user doesn't have any Collect carriers on their account and the backend changes
    // the value to Prepaid Freight, show the user a message as to why.
    if (
      this.changedToCollect &&
      changes['freightInfo'] &&
      changes['freightInfo']?.currentValue['freightTerm'] !== 'C'
    ) {
      setTimeout(() =>
        this.confirmationService.confirm(
          null,
          this.ocids['shipping-address.collect-carrier-error'],
          this.ocids['global.close'],
          null
        )
      );
      this.changedToCollect = false;
    }
    // If the user has changed the freight billing method (primary or secondary) to TPB,
    // but the user doesn't have any TPB ship via's on their account and the backend changes
    // the value to Prepaid Freight, show the user a message as to why.
    if (
      this.changedToTPB &&
      changes['freightInfo'] &&
      changes['freightInfo']?.currentValue['freightTerm'] !== '3'
    ) {
      setTimeout(() =>
        this.confirmationService.confirm(
          null,
          this.ocids['shippinginfo.tpb-missing-carrier-message'],
          this.ocids['global.close'],
          null
        )
      );
      this.changedToTPB = false;
    }
    // If the changes were on the formSubmitted input.
    if (changes.formSubmitted) {
      // If the form has been submitted.
      if (changes.formSubmitted.currentValue) {
        // And if the form is not valid, loop through the form's controls and set them as touched so their errors will show.
        if (!this.form.valid) {
          Object.keys(this.form.controls).forEach((key: string) => {
            this.form.get(key).markAsTouched();
          });
        }
      }
    }
  }

  /**
   * Build form.
   *
   * @return void
   */
  protected buildForm() {
    this.form.addControl(
      'freightTerm',
      this.fb.control(this.freightInfo.freightTerm, [Validators.required])
    );

    // Show service level?
    if (this.user.appConfig.showShippingServiceLevel) {
      this.addShipVia();
      if (!this.isEquipment) {
        this.addServiceLevel();
      }
    }

    // Show carrier?
    if (this.showCarrier) {
      this.carrierMethods = this.getCarrierOptions();
      // This will set to default carrier in cart shipping group, but backend integration is not yet hooked up
      const option = this.freightInfo.shippingOptions
        ? this.getDefaultShipVia()
        : null;
      this.form.addControl(
        'carrier',
        this.fb.control(
          option ? (option.carrier ? option.carrier : null) : null,
          [Validators.required]
        )
      );
      this.setServiceLevelOptions(option.carrier);
    }

    // Rest of the fields
    if (this.freightInfo.collectAccountRequired) {
      const option = this.freightInfo.shippingOptions
        ? this.getDefaultShipVia()
        : null;
      this.form.addControl(
        'collectAccountNumber',
        this.fb.control(
          option
            ? option.collectAccountNumber
              ? option.collectAccountNumber
              : null
            : null
        )
      );
      this.collectAccountExists = !!option.collectAccountNumber;
    }

    if (this.freightInfo.incoTermRequired) {
      this.form.addControl(
        'incoTermCode',
        this.fb.control(
          this.cartShippingGroup ? this.cartShippingGroup.incoterm : null
        )
      );
      this.form.addControl(
        'namedPlace',
        this.fb.control(
          this.cartShippingGroup ? this.cartShippingGroup.namedPlace : null
        )
      );
    }

    if (this.freightInfo.brokerRequired) {
      this.form.addControl(
        'brokerCode',
        this.fb.control(this.getDefaultBrokerValue())
      );
    }

    // FQ user, equipment orders must always show FQ
    if (this.shippingInfo.freightQuotedOrder || this.isEquipment) {
      this.addFreightQuoteAmount();
      this.addShipVia();
      if (!this.isEquipment) {
        this.addServiceLevel();
      }
      return;
    }

    // Show description/ship via
    if (this.user.appConfig.showShippingDescription) {
      this.addShipVia();
      return;
    }
  }

  /**
   * Add freight quote amount field (hidden).
   */
  protected addFreightQuoteAmount() {
    const option = this.freightInfo.shippingOptions
      ? this.getDefaultShipVia()
      : null;
    this.form.addControl(
      'freightQuoteAmount',
      this.fb.control(option ? option.freightQuoteAmount : 0)
    ); // Hidden field
    this.form.addControl(
      'nonConvertedFreightAmount',
      this.fb.control(option ? option.nonConvertedFreightAmount : 0)
    ); // Hidden field
  }

  /**
   * Add ship via/description form control.
   */
  protected addShipVia() {
    const option = this.freightInfo.shippingOptions
      ? this.getDefaultShipVia()
      : null;
    this.form.addControl(
      'shipViaCode',
      this.fb.control(option ? option.shipViaCode : null, [Validators.required])
    );
  }

  /**
   * Add service level form control.
   */
  protected addServiceLevel() {
    const value = this.freightInfo.shippingOptions
      ? this.getDefaultShipVia()
      : null;
    if (!this.showCarrier) {
      this.setServiceLevelOptions(null);
    }
    this.form.addControl(
      'serviceLevel',
      this.fb.control(value ? value.serviceLevel : null, [Validators.required])
    );
  }

  /**
   * On Ship Via Code change.
   *
   * @param {string} value
   */
  public onShipViaCodeChange(value: string) {
    if (this.shippingInfo.freightQuotedOrder) {
      const option: IShippingInfoShippingOption =
        this.freightInfo.shippingOptions.find(
          (o: IShippingInfoShippingOption) => {
            return o.shipViaCode === value;
          }
        );
      // Preselect carrier.
      if (this.form.contains('carrier')) {
        this.form.get('carrier').setValue(option.carrier);
      }
      // Preselect service level.
      if (this.form.contains('serviceLevel')) {
        this.form.get('serviceLevel').setValue(option.serviceLevel);
      }
      // Prepopulate collect account number.
      if (this.form.contains('collectAccountNumber')) {
        this.setCollectAccountNumber(option);
      }
      // Set freight quote amount.
      this.form.get('freightQuoteAmount').setValue(option.freightQuoteAmount);
    }
  }

  /**
   * Event listener for when carrier is selected.
   *
   * @param {string} value
   */
  public onCarrierChange(value: string) {
    const option: IShippingInfoShippingOption =
      this.freightInfo.shippingOptions.find(
        (o: IShippingInfoShippingOption) => {
          return o.carrier === value;
        }
      );
    // Reset the ship via code & service level fields.
    this.form.get('shipViaCode').reset();
    this.form.get('serviceLevel').reset();

    // Set the service level dropdown options.
    this.setServiceLevelOptions(value);
    // Set the collect account number.
    if (this.form.contains('collectAccountNumber')) {
      this.setCollectAccountNumber(option);
    }
  }

  /**
   * On Service Level dropdown change.
   *
   * @param {string} value
   */
  public onServiceLevelChange(value: string) {
    if (this.user.appConfig.showShippingServiceLevel) {
      const option: IShippingInfoShippingOption = this.filteredTypeOptions.find(
        (o: IShippingInfoShippingOption) => {
          return o.serviceLevel === value;
        }
      );

      // Preselect ship via.
      this.form.get('shipViaCode').setValue(option.shipViaCode);
      // Set the collect account number.
      if (this.form.contains('collectAccountNumber')) {
        this.setCollectAccountNumber(option);
      }
    }
  }

  /**
   * Event listener on shippingOptions.freightTerms change.
   *
   * @param {any} event
   */
  onFreightTermChange(event) {
    // We use the following boolean variables to help show any errors if the user tries to select
    // Collect or TPB for primary or secondary, but the backend does not let them.
    this.changedToCollect = event === 'C';
    this.changedToTPB = event === '3';
    this.freightChanged.emit();
  }

  get showCarrier(): boolean {
    return (
      !this.shippingInfo.freightQuotedOrder && this.user.appConfig.showCarrier
    );
  }

  /**
   * Get the default ship via, if no default set return the first item in the shipping options array.
   *
   * @returns {IShippingInfoShippingOption or null}
   */
  getDefaultShipVia() {
    const shipVia = this.freightInfo.shippingOptions.find(
      (option: IShippingInfoShippingOption) => {
        return option.default === true;
      }
    );
    return shipVia ? shipVia : this.freightInfo.shippingOptions[0];
  }

  /**
   * Get carrier options and sort them alphabetically.
   *
   * @returns {IShippingInfoShippingOption[]}
   */
  getCarrierOptions() {
    let carrierOptions = this.freightInfo.shippingOptions.filter(
      (option: IShippingInfoShippingOption, index: number, self) => {
        return (
          self.findIndex(
            (v: IShippingInfoShippingOption) => v.carrier === option.carrier
          ) === index
        );
      }
    );
    carrierOptions = carrierOptions.sort((a, b) => {
      return compare(a.carrier, b.carrier, 'asc');
    });
    return carrierOptions;
  }

  /**
   * Set the service level options based on the carrier value. The carrier value is what is passed into this
   * method. If there is no carrier, just return the shipping options.
   *
   * @param {string} value
   */
  setServiceLevelOptions(value: string) {
    let filteredTypeOptions: IShippingInfoShippingOption[];
    if (value) {
      filteredTypeOptions = this.freightInfo.shippingOptions.filter(
        (option: IShippingInfoShippingOption) => {
          return option.carrier === value;
        }
      );
    } else {
      filteredTypeOptions = this.freightInfo.shippingOptions;
    }
    // Sort the service level options based the sort order given by the shipping info.
    this.filteredTypeOptions = filteredTypeOptions.sort((a, b) => {
      return compare(a.sortOrder, b.sortOrder, 'asc');
    });
  }

  /**
   * Method to set the collect account number control.
   *
   * @param {IShippingInfoShippingOption} option
   */
  setCollectAccountNumber(option: IShippingInfoShippingOption) {
    this.form
      .get('collectAccountNumber')
      .setValue(
        option.collectAccountNumber ? option.collectAccountNumber : null
      );
    this.collectAccountExists = !!option.collectAccountNumber;
  }

  /**
   * Gets the default broker value for the form.
   */
  getDefaultBrokerValue() {
    if (this.cartShippingGroup) {
      if (this.cartShippingGroup.broker) {
        return this.cartShippingGroup.broker;
      }
    }
    if (this.isPrimary) {
      if (this.user.defaultBroker1) {
        return this.user.defaultBroker1;
      }
    } else {
      if (this.user.defaultBroker2) {
        return this.user.defaultBroker2;
      }
    }
    return null;
  }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
