// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h3 {
  margin-bottom: 0;
}

table._responsive th {
  padding: 10px;
}

td {
  border-bottom: none;
}

tbody tr:last-child td {
  padding-bottom: 16px;
}

tfoot tr:first-child td {
  padding-top: 16px;
  border-top: 1px solid var(--gray25);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJwYS1kZXRhaWxzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQUE7QUFDRjs7QUFDQTtFQUNFLGFBQUE7QUFFRjs7QUFBQTtFQUNFLG1CQUFBO0FBR0Y7O0FBREE7RUFDRSxvQkFBQTtBQUlGOztBQUZBO0VBQ0UsaUJBQUE7RUFDQSxtQ0FBQTtBQUtGIiwiZmlsZSI6InJwYS1kZXRhaWxzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmgzIHtcclxuICBtYXJnaW4tYm90dG9tOiAwO1xyXG59XHJcbnRhYmxlLl9yZXNwb25zaXZlIHRoIHtcclxuICBwYWRkaW5nOiAxMHB4O1xyXG59XHJcbnRkIHtcclxuICBib3JkZXItYm90dG9tOiBub25lO1xyXG59XHJcbnRib2R5IHRyOmxhc3QtY2hpbGQgdGQge1xyXG4gIHBhZGRpbmctYm90dG9tOiAxNnB4O1xyXG59XHJcbnRmb290IHRyOmZpcnN0LWNoaWxkIHRkIHtcclxuICBwYWRkaW5nLXRvcDogMTZweDtcclxuICBib3JkZXItdG9wOiAxcHggc29saWQgdmFyKC0tZ3JheTI1KTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/account/account-orders/order-details/rpa-details/rpa-details.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,mBAAA;AAGF;;AADA;EACE,oBAAA;AAIF;;AAFA;EACE,iBAAA;EACA,mCAAA;AAKF;AACA,wwBAAwwB","sourcesContent":[".h3 {\r\n  margin-bottom: 0;\r\n}\r\ntable._responsive th {\r\n  padding: 10px;\r\n}\r\ntd {\r\n  border-bottom: none;\r\n}\r\ntbody tr:last-child td {\r\n  padding-bottom: 16px;\r\n}\r\ntfoot tr:first-child td {\r\n  padding-top: 16px;\r\n  border-top: 1px solid var(--gray25);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
