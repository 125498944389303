// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-container {
  margin: 0 24px;
  background-color: var(--orange200);
}
.progress-bar-container .progress {
  background-color: var(--orange);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpbGUtY29tcGxldGlvbi1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0VBQ0Esa0NBQUE7QUFDRjtBQUNFO0VBQ0UsK0JBQUE7QUFDSiIsImZpbGUiOiJwcm9maWxlLWNvbXBsZXRpb24tZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnByb2dyZXNzLWJhci1jb250YWluZXIge1xuICBtYXJnaW46IDAgMjRweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tb3JhbmdlMjAwKTtcblxuICAucHJvZ3Jlc3Mge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLW9yYW5nZSk7XG4gIH1cbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/core/header/auth-container/profile-completion-dialog/profile-completion-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kCAAA;AACF;AACE;EACE,+BAAA;AACJ;AACA,whBAAwhB","sourcesContent":[".progress-bar-container {\n  margin: 0 24px;\n  background-color: var(--orange200);\n\n  .progress {\n    background-color: var(--orange);\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
