import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IAddPaymentGroupDto } from "../../contracts/commerce/dto/iadd-payment-group.dto";
import { IOrder } from "../../contracts/commerce/iorder";
import { IPaymentGroup } from "../../contracts/commerce/ipayment-group";
import { Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class PaymentGroupsService {

  constructor(private http: HttpClient) { }

  /**
   * Add payment to order.
   *
   * @param {IAddPaymentGroupDto} payment
   * @returns {Observable<IAddPaymentGroupDto>}
   */
  addToOrder(payment: IAddPaymentGroupDto) {
    return this.http.post<IAddPaymentGroupDto>(`${environment.apiUrl}/cart/paymentGroups`, payment);
  }

  /**
   * Remove all paymentGroups from an order.
   *
   * @param {IOrder} cart
   * @returns {Observable<IOrder | undefined>}
   */
  removeAllFromOrder(cart: IOrder): Observable<IOrder | undefined> {
    // Typically don't have more than one payment group but it IS an array
    if (! cart.paymentGroups.items) {
      return of(undefined);
    }

    let batch = cart.paymentGroups.items.map((value: IPaymentGroup) => this.removeFromOrder(cart, value.id));

    return forkJoin(batch).pipe(
      map((orders: IOrder[]) => {
        return orders.pop();
      })
    )
  }

  /**
   * Remove paymentGroup from order.
   *
   * @param {IOrder} cart
   * @param {string} groupId
   * @returns {Observable<IOrder>}
   */
  removeFromOrder(cart: IOrder, groupId: string): Observable<IOrder> {
    return this.http.delete<IOrder>(`${environment.apiUrl}/cart/paymentGroups/${groupId}`);
  }

}
