import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ShoppingListsService } from '../shopping-lists.service';
import { DatePipe } from '@angular/common';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IGiftList } from '../../../../../contracts/commerce/igift-list';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { ConfirmationAlertService } from '../../../../../service/confirmation/confirmation-alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-share-list-dialog',
  templateUrl: './share-list-dialog.component.html',
})
export class ShareListDialogComponent implements OnInit, OnDestroy {
  shareListForm: UntypedFormGroup;
  ocids: {} = {};
  private unsubscribe: Subject<void> = new Subject<void>();
  @Input() newListName = '';

  constructor(
    public dialogRef: MatDialogRef<ShareListDialogComponent>,
    private notificationService: NotificationService,
    private alertService: ConfirmationAlertService,
    private shoppingListService: ShoppingListsService,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IGiftList,
    public datePipe: DatePipe,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.pipe(takeUntil(this.unsubscribe)).subscribe(
      (ocids: {}) => {
        this.ocids = ocids;
      }
    );
    this.localization
      .getOCIDs([
        'shopping-list.email-share-success',
        'shoppinglist.share-text',
        'shopping-list.share-price',
      ])
      .subscribe();
    this.shareListForm = this.formBuilder.group({
      emails: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,\s?)*([^\s,]+@[^\s,]+\.[^\s,]+)$/
          ),
        ],
      ],
      includePrice: false,
    });
  }

  /**
   * If the form is valid, share the list.
   */
  verifyShareForm() {
    this.notificationService.reset();
    if (this.shareListForm.status === 'VALID') {
      this.shoppingListService
        .shareList(
          {
            giftlistId: this.data.id,
          },
          {
            toEmails: this.shareListForm.controls.emails.value,
            includePrice: this.shareListForm.controls.includePrice.value,
          }
        )
        .subscribe(
          (data) => {
            this.alertService.alertUser(
              this.ocids['shopping-list.email-share-success']
            );

            this.closeDialog();
          },
          (error) => {
            console.log(error);
            this.notificationService.addError(error.error.title);
          }
        );
    }
  }

  /**
   * Close the dialog box.
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Complete subscriptions from ngOnInit.
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
