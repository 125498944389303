import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UserEnvironments } from '../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { WindowRefService } from '../../../../service/window-ref/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import {
  ICommerceItemAvailability,
  SplitWarehouse,
  WarehouseType,
} from '../../../../contracts/commerce/icommerce-item-availability';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
})
export class WarehouseListComponent implements OnInit {
  @Input() itemAvailability!: ICommerceItemAvailability;
  @Input() env!: string;
  @Input() isPickup = false;
  @Input() itemDesc!: string;
  public ocids: OcidItems = {};
  platformBrowser = isPlatformBrowser(this.platformId);
  envOpts = UserEnvironments;
  warehouseTypes = WarehouseType;
  splitWarehouse = SplitWarehouse;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['orderdetails.primary-warehouse'])
      .pipe(first())
      .subscribe();
  }

  wareHouseViewClick(): void {
    this.itemAvailability.warehouseVisible =
      !this.itemAvailability.warehouseVisible;

    if (this.platformBrowser && this.itemAvailability?.warehouseVisible) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: 'viewWarehouseInfo',
        itemName: this.itemDesc,
        toggleLocation: 'Checkout - Shipping Options',
      });
    }
  }
}
