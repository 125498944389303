import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { EngineComponentWidget } from '../engine-component-widget';

@Component({
  selector: 'app-fuel-pressure',
  templateUrl: './fuel-pressure.component.html',
})
export class FuelPressureComponent
  extends EngineComponentWidget
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() machine: Machine;
  @Input() historic: string = HistoricSelection.HOURS_24;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chart = content;
    }
  }
  protected historicMapping = {
    [HistoricSelection.HOURS_24]: 'engRpmTrqFuelPress',
    [HistoricSelection.DAYS_7]: 'engRpmTrqFuelPress7days',
    [HistoricSelection.DAYS_14]: 'engRpmTrqFuelPress14days',
    [HistoricSelection.DAYS_31]: 'engRpmTrqFuelPress31days',
    [HistoricSelection.DAYS_90]: 'engRpmTrqFuelPress90days',
  };
  protected chartTitle = 'Fuel Delivery Pressure (kPa)';
  protected yAxisTitle = 'Torque (%)';
  protected xAxisTitle = 'Engine Speed (RPM)';
}
