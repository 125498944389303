import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from "rxjs/operators";

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {

  constructor(private _router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        tap(
          ok => {
            // return event instanceof HttpResponse ? 'succeeded' : '';
          }, error => {
            return 'failed';
          }
        ),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            // Http Error
            if (error !== null) {
              // If the server is down, redirect to the maintenance page.
              if (error.status === 503) {
                this._router.navigate(['/maintenance']);
              }
            } else {
              //this._notificationService.notify(`${error.error.title}`);
            }
          } else {
            // Client Error Happened
            this._router.navigate(['/error'], { queryParams: {error: error} });
          }

          return throwError(error);
        })
      );

  }
}
