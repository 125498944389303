import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { environment } from '../../../../../environments/environment';
import { INavMenu } from '../../../../contracts/user/inav-menu';

export interface Schematics {
  equipmentType: string;
  img: string;
  imgActive: string;
  models: Models[];
}
export interface Models {
  label: string;
  uxKey: string;
}

@Component({
  selector: 'app-schematics-selector',
  styleUrls: ['./schematics-selector.component.scss'],
  templateUrl: './schematics-selector.component.html',
})
export class SchematicsSelectorComponent implements OnInit {
  public selectedType!: number;
  public schematics: Schematics[] = [];
  variationMenu: Array<object> = [];
  damPath = environment.production
    ? 'https://www.jlg.com'
    : 'https://staging.jlg.com';
  modelType: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  variation!: UntypedFormControl;
  availableTypes = ['booms', 'scissors', 'telehandlers'];
  selectedVariation!: string;
  constructor(
    public dialogRef: MatDialogRef<SchematicsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.data.types.forEach((type: INavMenu) => {
      let imgActive = '';
      let img = '';
      switch (type.uxKey) {
        case 'telehandlers':
          imgActive =
            this.damPath +
            '/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/44912-source';
          img =
            this.damPath +
            '/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/44908-source';
          break;
        case 'scissorLifts':
          imgActive =
            this.damPath +
            '/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/44911-source';
          img =
            this.damPath +
            '/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/44913-source';
          break;
        case 'boomLifts':
          imgActive =
            this.damPath +
            '/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/44909-source';
          img =
            this.damPath +
            '/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/44906-source';
          break;
      }
      this.schematics.push({
        equipmentType: type.label,
        img: img,
        imgActive: imgActive,
        models: this.data.models[type.uxKey],
      });
    });
  }

  /**
   * When user selects a type in step 1
   * Determines which values to show in the models dropdown.
   * Also resets previously selected models and variations
   */
  selectType(index: number) {
    this.selectedType = index;
    this.modelType.setValue('');
    this.variation?.setValue('');
    this.variationMenu = [];
  }

  /**
   * When user selects a model from the dropdown in step 2
   * Determines which values to show in the variations dropdown
   */
  selectModel(event: MatSelectChange) {
    const selectedModel: any = this.schematics[this.selectedType].models.find(
      (x) => x.label === event.value
    );
    this.variationMenu = selectedModel.childMenus;
    this.variation =
      this.variationMenu.length > 0
        ? new UntypedFormControl('', [Validators.required])
        : null;
  }

  /**
   * When user selects a variation from the dropdown in step 3.
   * We want to capture the display text, not the actual field value.
   */
  setVariation(event: MatSelectChange) {
    this.selectedVariation = (event.source.selected as MatOption).viewValue;
  }

  /**
   * When user clicks confirm button.
   */
  viewSchematics() {
    this.dialogRef.close({
      name: this.modelType.value + ' - ' + this.selectedVariation,
      type: this.availableTypes[this.selectedType],
      model: this.modelType.value,
      sn: this.selectedVariation,
      path: `${this.availableTypes[this.selectedType]}/${this.variation.value}`,
    });
  }

  /**
   * When user clicks cancel button.
   */
  onClose() {
    this.dialogRef.close();
  }
}
