import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetListComponent implements OnInit {
  @Input() cols = 4;
  @Input() autoRows = 'minmax(min-content, max-content)';
  @HostBinding('class')
  public get cssCols(): string {
    return `cols-${this.cols}`;
  }
  @HostBinding('style.grid-auto-rows')
  public get autoRowsStyle(): string {
    return this.autoRows;
  }

  ngOnInit(): void {
    //
  }
}
