import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ClearskyService } from '../../../clearsky.service';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HistoricSelection } from '../../../../contracts/clearsky/machine/machine.dto';
import { CsAggData } from '../../../../contracts/clearsky/agg-data';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-envelope-profile-use',
  templateUrl: './envelope-profile-use.component.html',
  styleUrls: ['./envelope-profile-use.component.scss'],
})
export class EnvelopeProfileUseComponent implements OnInit {
  displayName = WidgetsDisplay.envelopeProfileUse;
  widgetName = Widgets.ENVELOPE_PROFILE_USE;
  isPercentage = false;
  applicableMachines: number;
  percentage: number;
  historic = HistoricSelection.DAYS_14;
  historicSelection = HistoricSelection.DAYS_14;
  aggData: CsAggData = {};
  ocids: OcidItems = {};

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private gAService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.graphic-default', 'clearsky.profile-zone-label', this.displayName])
      .pipe(first())
      .subscribe();
    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .subscribe((agg) => {
        this.aggData = (agg && agg.aggregations) || {};
        // TODO - remove below code once ADX returns only 3 zones for boomsEnvPro.
        const boomsEnvPro = this.aggData.boomsEnvPro;
        for (const zoneDays in this.aggData.boomsEnvPro) {
          const zones = boomsEnvPro[zoneDays];
          for (const aggregation in zones) {
            delete boomsEnvPro[zoneDays][aggregation].z4;
            delete boomsEnvPro[zoneDays][aggregation].z5;
          }
        }
        this.applicableMachines = this.aggData.fleetOverview
          ? this.aggData.fleetOverview.mxWithEnvPro
          : 0;
        this.percentage =
          this.applicableMachines /
          (this.aggData.machineCount
            ? this.aggData.machineCount.total
            : 1 ?? 1);
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * On historic dropdown change.
   * @param historic
   */
  onHistoricChange(historic: string): void {
    this.gAService.eventEmitter('clearsky_click', 'envelop_profile_use', 'change', 'historic', historic);
    this.historic = historic;
  }

  /**
   * Toggle data type.
   */
  onToggle(): void {
    this.isPercentage = !this.isPercentage;
  }
}
