import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from '../../../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../../../service/confirmation/confirmation-alert.service';
import { ExcelService } from '../../../../../service/excel/excel.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { ISubusers } from '../../../../../contracts/user/isubuser';
import { IUser } from '../../../../../contracts/user/iuser';
import { OrderInquiryService } from '../parts-order-inquiry/order-inquiry.service';
import { IOrderHistItem } from '../../../../../contracts/orders/iorder-hist-item';
import { IOrderItem } from '../../../../../contracts/orders/iorder-item';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { IOrderNinetyDayHistoryDto } from '../../../../../contracts/orders/dto/iorder-ninety-day-history-dto';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-parts-purchase-history',
  templateUrl: './parts-purchase-history.component.html',
})
export class PartsPurchaseHistoryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  customerNum = '';
  companyName = '';
  isEnterpriseUser = false;
  numberOptions: ISubusers[] = [];
  purchaseHistoryFormGroup: UntypedFormGroup;
  searched = false;
  results: IOrderItem[] = [];
  pageSize = 10;
  dataLength = 0;
  pageSizeOptions: number[] = [10, 20, 50];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource = new MatTableDataSource<any>(this.results);
  startDate = new Date();
  endDate = new Date();
  ocids: OcidItems = {};
  platformBrowser = false;
  user: IUser;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private _formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private currentUserService: CurrentUserService,
    private orderService: OrderInquiryService,
    private excelService: ExcelService,
    private localization: LocalizationService,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'order.90-day-parts-history',
        'global.date-range',
        'order.total-qty-ordered',
        'order.number-transactions',
        'order.average-price-paid',
        'order.30-day-supply',
        'global.no-results-message',
      ])
      .subscribe();
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        // Set user's company name. Company name is the same as customer name.
        this.companyName = user.companyName;
        if (user.allRoles) {
          user.allRoles.forEach((role) => {
            if (role.name === 'EnterpriseUserProxy') {
              this.isEnterpriseUser = true;
            }
          });
          // If the user is an enterprise user, add their company number to
          // the options list.
          if (this.isEnterpriseUser) {
            this.numberOptions.push({
              customerNumber: user.customerNumber,
              name: user.companyName,
            });
            // Reset any existing errors and get the child companys' company
            // numbers to the otpions list.
            this.notificationService.reset();
            this.currentUserService.getChildCompanyList().subscribe(
              (data: ISubusers[]) => {
                data.forEach((subUser) => {
                  this.numberOptions.push(subUser);
                });
                this.customerNum = user.customerNumber;
                this.setPurchaseHistoryFormGroup();
              },
              (error) => {
                this.notificationService.addError(error.error.title);
                console.log(
                  'There has been an error retrieving child company list: ' +
                    error.message
                );
              }
            );
          } else {
            this.customerNum = user.customerNumber;
            this.setPurchaseHistoryFormGroup();
          }
        } else {
          this.customerNum = user.customerNumber;
          this.setPurchaseHistoryFormGroup();
        }
      }
    });
    this.startDate.setDate(this.startDate.getDate() - 90);
  }

  ngAfterViewInit() {
    this.refresh();
  }

  /**
   * Set the purchase history form group.
   */
  setPurchaseHistoryFormGroup() {
    this.purchaseHistoryFormGroup = this._formBuilder.group({
      customerNum: [this.customerNum, Validators.required],
      companyName: [this.companyName, Validators.required],
      shipToNumber: [''],
    });
  }

  /**
   * Get the purchase history for the query parameters the user has provided.
   */
  getPurchaseHistory() {
    // If the form group is valid, get the purchase history.
    if (this.purchaseHistoryFormGroup.valid) {
      // Start the spinner.

      // Build the base of the data transfer object with the selected
      // customer numbers, limit, and offset for pagination.
      const params: IOrderNinetyDayHistoryDto = {
        customerNumber: this.customerNum,
        limit: this.paginator.pageSize,
        offset: this.paginator.pageIndex + 1,
      };
      // If the ship to number has been provided, add it to the dto.
      if (this.purchaseHistoryFormGroup.controls.shipToNumber.value) {
        params.shipToNumber =
          this.purchaseHistoryFormGroup.controls.shipToNumber.value;
      }
      // Add sortBy (active) and sortDir (direction) to the parameters for filtering by column.
      if (this.dataSource.sort.direction) {
        params.sortBy = this.dataSource.sort.active;
        params.sortDir = this.dataSource.sort.direction;
      }
      // Get the purchase history.
      this.notificationService.reset();
      this.orderService.getNinetyDayOrderHistory(params).subscribe(
        (response: IOrderHistItem) => {
          this.dataLength = response.totalResults;
          this.paginator.length = this.dataLength;
          this.searched = true;
          this.results = response.items;
          this.refresh();
        },
        (error) => {
          this.notificationService.addError(error.error.title);
          this.searched = true;
          this.results = [];
          this.refresh();
          console.log('Error getting 90 Day Order History: ' + error.message);
        }
      );
    }
  }

  /**
   * Method that listens for change in customer number.
   */
  changeCustNum() {
    const changedOption =
      this.purchaseHistoryFormGroup.controls.customerNum.value;
    this.numberOptions.forEach((option) => {
      if (option.customerNumber === changedOption) {
        this.companyName = option.name;
      }
    });
  }

  /**
   * Refresh the data source values.
   */
  refresh() {
    this.dataSource.data = this.results;
    this.dataSource.sort = this.sort;
    this.changeDetector.detectChanges();
  }

  /**
   * Add the item to the cart.
   * @param {number} itemNum
   */
  addToCart(itemNum) {
    this.notificationService.reset();
    this.cartService.addOneToCart(itemNum, +1).subscribe({
      next:(response: ICommerceItemWithCart) => {
        this.alertService.addToCartConfirm(response.productDisplayName);
        // Send Add to Cart action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.addToCart(
            itemNum,
            response.categoryName,
            response.productDisplayName,
            1,
            'Parts Purchase History'
          );
        }
      },
      error:(error) => {
        this.notificationService.addError(error.error.title);
        console.log(error);
      }
  });
  }

  /**
   * Export the data list to excel.
   */
  exportToExcel() {
    // If data is available for export, export to excel.
    if (this.dataLength > 0) {
      // Because this page uses paginated data, we have to get all records in order
      // to export to excel. We use the total records returned from the initial
      // request to set the limit, otherwise the limit will be set to default 50.
      const params: IOrderNinetyDayHistoryDto = {
        customerNumber: this.customerNum,
        limit: this.dataLength,
      };
      // If the ship to number has been provided, add it to the dto.
      if (this.purchaseHistoryFormGroup.controls.shipToNumber.value) {
        params.shipToNumber =
          this.purchaseHistoryFormGroup.controls.shipToNumber.value;
      }
      // Reset the notifications and start the spinner.
      this.notificationService.reset();
      // Get the ninety day order history based on the params.
      this.orderService.getNinetyDayOrderHistory(params).subscribe(
        (response: IOrderHistItem) => {
          const replacements = {
            productId: this.ocids['global.item-number'],
            itemDescription: this.ocids['global.item-description'],
            itemCount: this.ocids['order.total-qty-ordered'],
            orderCount: this.ocids['order.number-transactions'],
            avgPrice: this.ocids['order.average-price-paid'],
            quantity30Days: this.ocids['order.30-day-supply'],
          };
          // Export the data to excel.
          const replaceKeyInObjectArray = (a, r) =>
            a.map((o) =>
              Object.keys(o)
                .map((key) => ({ [r[key] || key]: o[key] }))
                .reduce((a, b) => Object.assign({}, a, b))
            );
          const dateRange =
            this.startDate.toISOString().slice(0, 10) +
            ' to ' +
            this.endDate.toISOString().slice(0, 10);
          this.excelService.exportPurchaseHistory(
            replaceKeyInObjectArray(response.items, replacements),
            '90DayPurchaseHistory',
            dateRange,
            +this.customerNum,
            this.companyName
          );
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
    }
  }

  /**
   * Print the page.
   */
  print() {
    // Only print the parts purchase history page if the platform is browser.
    if (this.platformBrowser) {
      window.print();
    }
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
