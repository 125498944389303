import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ClearskyService } from '../../../clearsky.service';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import * as Highcharts from 'highcharts';
import HighchartsTreemap from 'highcharts/modules/treemap';
import { combineLatest, Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import { OcidItems } from '../../../../contracts/ocid-items';
import { CsAggCountData } from '../../../../contracts/clearsky/agg-data';
import {
  CSRefAlert,
  CSRefBasic,
} from '../../../../contracts/clearsky/clearsky-legend';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';

// Add treemap module
HighchartsTreemap(Highcharts);

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-alerts-widget',
  templateUrl: './alerts-widget.component.html',
  styleUrls: ['./alerts-widget.component.scss'],
})
export class AlertsWidgetComponent implements OnInit {
  protected chartEl: ElementRef;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  displayName = WidgetsDisplay.alerts;
  widgetName = Widgets.ALERTS;
  ocids: OcidItems = {};
  isLoading = true;
  showChart = false;
  private aggData: CsAggCountData[] = [];
  private parentSrcs: CSRefBasic[] = [];
  private alerts: CSRefAlert[] = [];
  private svty: CSRefBasic[] = [];
  private plot;
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) { }

  ngOnInit() {
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.localization.getOCIDs([
        'clearsky.active-alerts-label',
        'clearsky.alert-label',
        'clearsky.details-label',
        'clearsky.rank-label',
        'clearsky.alert-source-label',
        'clearsky.graphic-default',
        this.displayName,
      ]),
      this.clearskyService.legendRef$,
    ]).subscribe(([page, ocids, legend]) => {
      if (!page) {
        return;
      }

      this.resetChartData();
      this.parentSrcs = legend.asrc || [];
      this.alerts = legend.alerts || [];
      this.aggData = (page.aggregations && page.aggregations.alerts) || [];
      this.svty = legend.svtyLevel || [];
      this.ocids = ocids;
      this.isLoading = false;
      this.showChart = !!this.parentSrcs.length;

      // Does the chart already exist?
      if (this.plot && this.showChart) {
        this.setChartData();
      }
    });
  }

  /**
   * Reset chart data.
   * @protected
   */
  protected resetChartData(): void {
    if (this.plot && this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Set data for chart.
   * @protected
   */
  private setChartData(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const codeSourcesMap = this.parentSrcs.map((i, index) => {
      return {
        id: 'p_' + i.id,
        name: i.desc,
        color: BaseChartConfig.colors[index],
        isParent: true,
        custom: i,
        events: {
          click: function (e: { point: { custom: { id: number } } }) {
            self.clearskyService.updateFilter(CSFilter.asrc.key, [
              e.point.custom.id,
            ]);
          },
        },
      };
    });

    this.plot.addSeries({
      type: 'treemap',
      layoutAlgorithm: this.aggData.length > 30 ? 'squarified' : 'sliceAndDice',
      allowDrillToNode: true,
      alternateStartingDirection: true,
      levels: [
        {
          level: 1,
          borderWidth: 5,
          dataLabels: {
            enabled: true,
            borderWidth: 2,
            align: 'left',
            backgroundColor: 'white',
            padding: 7,
            verticalAlign: 'top',
            y: 5,
            x: 5,
            formatter: function (this, options) {
              options.borderColor = this.color;
              return `<div style="borderColor:${this.color}">${this.key}</div>`;
            },
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
      ],
      tooltip: {
        pointFormatter: function () {
          let text = '';
          // Is this the parent group tooltip?
          if (this.isParent) {
            text += `<b>${this.name.toUpperCase()}</b><br />`;
            text += `${self.ocids['clearsky.active-alerts-label']}: ${this.value}`;
          } else {
            const lItem = self.parentSrcs.find(
              (a) => a.id === this.custom?.asrc
            );
            const lSvty = self.svty.find((s) => s.id === this.custom?.svty);
            text = `${self.ocids['clearsky.alert-label']}: ${this.custom?.desc}<br />`;
            text += `${self.ocids['clearsky.details-label']}: ${this.custom?.dtls}<br />`;
            text += `${self.ocids['clearsky.rank-label']}: ${lSvty?.desc}<br />`;
            text += `${self.ocids['clearsky.alert-source-label']}: ${lItem?.desc}<br />`;
            text += `${self.ocids['clearsky.active-alerts-label']}: ${this.value} Asset`;
          }

          return text;
        },
      },
      dataLabels: {
        style: {
          color: '#000',
          fontSize: '13px',
        },
      },
      cursor: 'pointer',
      data: [
        // Merge parent code sources
        ...codeSourcesMap,

        // Now merge dtcs and add their parent
        ...this.aggData.map((i) => {
          const lItem = this.alerts.find((a) => a.id === i.id);
          const parent = codeSourcesMap.find(
            (item) => item.custom.id === lItem?.asrc
          );
          return {
            id: 'c_' + i.id,
            name: lItem?.desc,
            parent: parent?.id,
            value: i.cnt,
            custom: lItem,
            // color,
            borderWidth: 2,
            // borderColor: parent.color,
            events: {
              click: function (e: { point: { custom: { id: number } } }) {
                self.clearskyService.updateFilter(CSFilter.alerts.key, [
                  e.point.custom.id,
                ]);
              },
            },
          };
        }),
      ],
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    const chartData = mergeDeep(cloneDeep(BaseChartConfig), {
      chart: {
        type: 'treemap',
      },
      title: {
        text: undefined,
      },
      series: [],
    });

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      chartData as unknown
    );
    this.setChartData();
  }
}
