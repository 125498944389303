import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IWeb2Case,
  CaseStatus,
} from '../../../../../contracts/web2case/iweb2case';
import { ExcelService } from '../../../../../service/excel/excel.service';
import { DatePipe } from '@angular/common';
import { ConfirmationDialogsService } from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { Web2CaseService } from '../../../web2case/web2case.service';
import { ContentService } from '../../../../../service/content.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IUser } from '../../../../../contracts/user/iuser';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IFileList } from 'app/contracts/web2case/iweb2case-dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-w2c-cases',
  styleUrls: ['./w2c-cases.component.scss'],
  templateUrl: './w2c-cases.component.html',
})
export class W2CCasesComponent implements OnChanges {
  @Input() cases!: IWeb2Case[];
  @Input() ocids!: OcidItems;
  @Input() user!: IUser;
  @Input() statusMenu!: [];
  @ViewChild(MatPaginator) casePaginator!: MatPaginator;
  @ViewChild(MatSort) caseSort!: MatSort;
  caseSource = new MatTableDataSource<IWeb2Case>([]);
  isFocused = false;
  searchCases = '';
  statusFilter = '';
  typeFilter = '';
  levelFilter = '';
  createFilter = '';
  caseLength = 0;
  openCase = 0;
  requestedCases = [];
  createFilterMenu!: unknown[];
  typeFilterMenu = [];
  levelFilterMenu = [];
  caseStatus = CaseStatus;
  pageSizeOptions: number[] = [10, 20, 50];
  submitted = false;
  attachmentList: IFileList[] = [];

  constructor(
    private excelService: ExcelService,
    private date: DatePipe,
    private confirmation: ConfirmationDialogsService,
    private web2case: Web2CaseService,
    private contentService: ContentService,
    private sanitizer: DomSanitizer
  ) { }

  addLine(field) {
    return field ? field + '<br />' : '';
  }

  ngOnChanges() {
    this.cases = this.cases.map(item => {
      const Job_Site_Address__c = `
        ${this.addLine(item.Job_Site_Name__c)}
        ${this.addLine(item.Job_Site_Contact_Street__c)}
        ${this.addLine(item.Job_Site_Contact_Postal_Code__c)}
        ${this.addLine(item.Job_Site_Contact_City__c)}
        ${this.addLine(item.Job_Site_Contact_Country__c)}
        ${this.addLine(item.Job_Site_Contact_Name__c)}
        ${this.addLine(item.Job_Site_Contact_Phone__c)}
      `.trim();
      if (Job_Site_Address__c.length) {
        item.Job_Site_Address__c = Job_Site_Address__c;
      }
      return item;
    });
    this.caseLength = this.cases.length;
    this.caseSource = new MatTableDataSource<IWeb2Case>(this.cases);
    this.createFilterMenu = [
      { label: this.ocids['w2c.created-by-anyone-label'], value: '' },
      ...[...new Map(this.cases.map((v) => [v.OLE_CreatedBy__c, v])).values()].map(
        (item) => ({ label: item.OLE_CreatedBy__c, value: item.OLE_CreatedBy__c })
      ),
    ];
    this.typeFilterMenu = [
      { label: this.ocids['w2c.any-types-number-label'], value: '' },
      ...[...new Map(this.cases.map((v) => [v.Type, v])).values()].map(
        (item) => ({ label: item.Type, value: item.Type })
      ),
    ];
    this.levelFilterMenu = [
      { label: this.ocids['global.any-level-label'], value: '' },
      ...[...new Map(this.cases.map((v) => [v.Level__c, v])).values()].map(
        (item) => ({ label: item.Level__c, value: item.Level__c })
      ),
    ];
    this.caseSource.paginator = this.casePaginator;
  }

  caseFiltering() {
    const filteredData = this.cases.filter((item: IWeb2Case) => {
      return (
        (this.statusFilter ? item.OLE_Status__c === this.statusFilter : true) &&
        (this.typeFilter ? item.Type === this.typeFilter : true) &&
        (this.levelFilter ? item.Level__c === this.levelFilter : true) &&
        (this.createFilter ? item.OLE_CreatedBy__c === this.createFilter : true) &&
        (this.searchCases
          ? item.CaseNumber === this.searchCases ||
          item.Order_Number__c === this.searchCases
          : true)
      );
    });
    this.isFocused = false;
    this.caseSource = new MatTableDataSource<IWeb2Case>(filteredData);
    this.caseSource.paginator = this.casePaginator;
    this.caseLength = filteredData.length;
    this.submitted = true;
  }

  sortCases(sort: Sort) {
    const data = this.caseSource.data;
    if (!sort.active || sort.direction === '') {
      return;
    }
    const sortedData = data.sort((a: IWeb2Case, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'CaseNumber':
          return this.contentService.compare(a.CaseNumber, b.CaseNumber, isAsc);
        case 'Status':
          return this.contentService.compare(a.OLE_Status__c, b.OLE_Status__c, isAsc);
        case 'Type':
          return this.contentService.compare(a.Type, b.Type, isAsc);
        case 'Level':
          return this.contentService.compare(a.Level__c, b.Level__c, isAsc);
        case 'CreatedBy':
          return this.contentService.compare(a.OLE_CreatedBy__c, b.OLE_CreatedBy__c, isAsc);
        case 'CreatedDate':
          return this.contentService.compare(
            a.CreatedDate,
            b.CreatedDate,
            isAsc
          );
        case 'OrderNumber':
          return this.contentService.compare(
            a.Order_Number__c,
            b.Order_Number__c,
            isAsc
          );
        case 'LastUpdate':
          return this.contentService.compare(a.OLE_case_update_date__c, b.OLE_case_update_date__c, isAsc);
        default:
          return 0;
      }
    });
    this.caseSource = new MatTableDataSource<any>(sortedData);
  }

  getObjectKeys(item: IWeb2Case) {
    const newItem = Object.assign({}, item);
    delete newItem.attributes;
    delete newItem.Order_Number__c;
    delete newItem.Subject;
    delete newItem.Description;
    delete newItem.Job_Site_Address__c;
    delete newItem.OLE_case_update__c;
    delete newItem.Job_Site_Contact_Name__c;
    delete newItem.Job_Site_Name__c;
    delete newItem.Job_Site_Contact_Street__c;
    delete newItem.Job_Site_Contact_Postal_Code__c;
    delete newItem.Job_Site_Contact_City__c;
    delete newItem.Job_Site_Contact_Country__c;
    delete newItem.Job_Site_Contact_Phone__c;
    delete newItem.Type;
    delete newItem.CaseNumber;
    delete newItem.OLE_Status__c;
    delete newItem.Id;
    delete newItem.Origin;
    delete newItem.OLE_CreatedBy__c;
    delete newItem.CreatedDate;
    delete newItem.OLE_request_update__c;
    delete newItem.OLE_case_update_date__c;
    delete newItem.SuppliedEmail;
    delete newItem.SuppliedName;
    delete newItem.SuppliedPhone;
    delete newItem.CurrencyIsoCode;
    delete newItem.Case_Region__c;
    return Object.keys(newItem);
  }

  export() {
    if (this.caseLength) {
      const headers = [
        'CaseNumber',
        'Status',
        'Type',
        'CreatedBy',
        'CreatedDate',
        'OrderNumber',
        'LastUpdate',
        'Subject',
        'CaseOrigin',
        'Description',
        'Model',
        'SerialNumber',
        'Level',
        'CaseUpdate',
      ];

      const json = this.caseSource.data.map((data: IWeb2Case) => {
        return {
          CaseNumber: data.CaseNumber,
          Status: data.OLE_Status__c,
          Type: data.Type,
          CreatedBy: data.OLE_CreatedBy__c,
          CreatedDate: this.date.transform(data.CreatedDate, 'dd/MM/yyyy'),
          OrderNumber: data.Order_Number__c,
          LastUpdate: this.date.transform(data.OLE_case_update_date__c, 'dd/MM/yyyy'),
          Subject: data.Subject,
          CaseOrigin: data.Case_Region__c,
          Description: data.Description,
          SerialNumber: data.Serial_No__c,
          Level: data.Level__c,
          CaseUpdate: data.OLE_request_update__c ? 'true' : 'false',
        };;
      });

      this.excelService.exportData(headers, json, 'Cases');
    }
  }

  toggleCaseDetails({ CaseNumber, Id }) {
    this.attachmentList = [];
    this.openCase = this.openCase === CaseNumber ? 0 : CaseNumber;
    this.web2case.getCaseAttachmentList(Id).subscribe((res) => {
      this.attachmentList = res;
    })
  }

  downloadAttachment(attachment) {
    const byteCharacters = atob(attachment.FileBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = attachment.FileName;
    link.click();
  }

  requestUpdate(caseNumber) {
    this.confirmation
      .confirm(
        this.ocids['w2c.request-update-number-label'],
        this.ocids['w2c.request-update-message'],
        this.ocids['global.submit'],
        this.ocids['global.cancel']
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          const request = {
            id: this.cases.find((x) => x.CaseNumber === caseNumber).Id,
            email: this.user.email,
            oleReqUpdate: true,
          };
          this.web2case.requestUpdate(request).subscribe({
            next: () => {
              this.requestedCases.push(caseNumber);
            },
          });
        }
      });
  }

  resetForm(event) {
    event.preventDefault();
    this.searchCases = '';
    this.statusFilter = '';
    this.typeFilter = '';
    this.levelFilter = '';
    this.createFilter = '';
    this.caseFiltering();
    this.submitted = false;
  }

  get isDirty() {
    return this.submitted && (this.searchCases || this.statusFilter || this.typeFilter || this.levelFilter || this.createFilter);
  }

  identify(index, item): string {
    return item.caseNumber;
  }

  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
