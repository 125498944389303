import { Component, Input, OnInit } from '@angular/core';
import {
  Machine,
  MxConnDevCertUrl,
  SoftwareVersionURL,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-conn-dev',
  templateUrl: './asset-conn-dev.component.html',
})
export class AssetConnDevComponent implements OnInit {
  @Input() machine: Machine;
  widgetName = MachineWidgets.CONN_DEV;
  displayName = MachineWidgetsDisplay.connDev;
  ocids: OcidItems = {};
  devCertUrl = MxConnDevCertUrl;
  softwareVersionURL = SoftwareVersionURL;

  constructor(private localization: LocalizationService) { }

  ngOnInit(): void {
    this.localization
      .getOCIDs([
        this.displayName,
        'global.model-number',
        'global.serial-number',
        'clearsky.part-number-label',
        'clearsky.hibernate-mode-label',
        'clearsky.software-status-label',
        'clearsky.software-updates-label',
        'clearsky.hibernation-start-label',
        'web2case.level2.software-version',
        'clearsky.update-required',
        'global.current',
        'global.device-certifications-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
