// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-icon:not(.grid-blocks-spotlight-icon) {
  font-size: 40px;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
:host ::ng-deep .grid-blocks-spotlight-icon {
  font-size: 80px;
  width: 80px;
  height: 80px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImdyaWQtYmxvY2tzLWl0ZW0taWNvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGVBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUNBLG1CQUFBO0FBQUo7QUFHRTtFQUNFLGVBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQURKIiwiZmlsZSI6ImdyaWQtYmxvY2tzLWl0ZW0taWNvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IDo6bmctZGVlcCB7XHJcbiAgLm1hdC1pY29uOm5vdCguZ3JpZC1ibG9ja3Mtc3BvdGxpZ2h0LWljb24pIHtcclxuICAgIGZvbnQtc2l6ZTogNDBweDtcclxuICAgIHdpZHRoOiA0MHB4O1xyXG4gICAgaGVpZ2h0OiA0MHB4O1xyXG4gICAgbWFyZ2luLWJvdHRvbTogMTBweDtcclxuICB9XHJcblxyXG4gIC5ncmlkLWJsb2Nrcy1zcG90bGlnaHQtaWNvbiB7XHJcbiAgICBmb250LXNpemU6IDgwcHg7XHJcbiAgICB3aWR0aDogODBweDtcclxuICAgIGhlaWdodDogODBweDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/grid-blocks/grid-blocks-item-icon/grid-blocks-item-icon.component.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,WAAA;EACA,YAAA;AADJ;AACA,4vBAA4vB","sourcesContent":[":host ::ng-deep {\r\n  .mat-icon:not(.grid-blocks-spotlight-icon) {\r\n    font-size: 40px;\r\n    width: 40px;\r\n    height: 40px;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .grid-blocks-spotlight-icon {\r\n    font-size: 80px;\r\n    width: 80px;\r\n    height: 80px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
