import { UntypedFormGroup, ValidationErrors } from "@angular/forms";

export function OneRequiredValidator(controlName1: string, controlName2: string) {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const control1 = formGroup.get(controlName1);
    const control2 = formGroup.get(controlName2);
    const level = formGroup.get('Level');

    if (level && level.value !== 'Invoice') {
      return;
    }
    if (control1 && control2) {
      if ((control1.errors && !control1.errors.oneRequired) || (control2.errors && !control2.errors.oneRequired)) {
        return;
      }
  
      // If neither form control has a value
      if (!control1.value && !control2.value) {
        return {
          oneRequired: true
        };
      }
    } else {
      return;
    }
  }
}