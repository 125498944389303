import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { IManagedUser } from 'app/contracts/user/imanaged-user';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private gtag: Function;
  private user: IManagedUser = { customerNumber: null, allRoles: [] };
  private subs: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: string, private currentUserService: CurrentUserService) {
    if (isPlatformBrowser(this.platformId)) {
      this.gtag = (window as any).gtag;
      this.subs = this.currentUserService.getUser().subscribe((user) => {
        this.user = user || this.user;
      });
    }
  }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = null,
    eventValue: string | number | null = null
  ): void {
    this.gtag('event', eventName, {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
      event_value: eventValue,
      customer_number: this.user.customerNumber,
      user_roles: this.user.allRoles.map(role => role.name)?.toString()
    });
  }
}