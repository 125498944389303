import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../../cartridge/cartridge.class';
import { IMarketingBox } from '../marketing-box-banner.component';

@Component({
  selector: '[app-marketing-box]',
  styleUrls: ['./marketing-box.component.scss'],
  templateUrl: './marketing-box.component.html',
})
export class MarketingBoxComponent implements CartridgeInterface {
  @Input() data: IMarketingBox;

  constructor() {}

  /**
   * Gets the button style class name.
   * @param {string} buttonStyle
   */
  getButtonStyle(buttonStyle: string) {
    switch (buttonStyle) {
      case 'jlg-orange': {
        return 'marketing-button-style-jlg';
      }
      case 'jerrdan-red': {
        return 'marketing-button-style-jerrdan';
      }
      case 'gray': {
        return 'marketing-button-style-gray';
      }
      case 'white': {
        return 'marketing-button-style-white';
      }
      case 'black': {
        return 'marketing-button-style-black';
      }
      case 'shaded': {
        return 'marketing-button-style-shaded';
      }
      case 'transparent': {
        return 'marketing-button-style-transparent';
      }
      default: {
        return 'marketing-button-style-jlg';
      }
    }
  }
}
