import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from '../../clearsky.service';
import {
  CSFieldType,
  Machine,
  MachineFieldDisplay,
} from '../../../contracts/clearsky/machine/machine.dto';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MachineDetailsDialogComponent } from '../machine-details-dialog/machine-details-dialog.component';
import { MachineFilterSelection } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { combineLatest, merge, Subscription } from 'rxjs';
import {
  debounceTime,
  first,
  mergeMap,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { MachineDialogConfig } from '../../../contracts/clearsky/machine/machine.dialog.config';
import { MachineFieldDisplayValueOcids } from '../../../contracts/clearsky/machine/machine.fields';
import { CSFilter } from 'app/contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../contracts/clearsky/cs-machines-request';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';
import { CsListColumns } from '../../../contracts/clearsky/dashboard/cs-list-view';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [];
  legend: unknown;
  dataSource: MatTableDataSource<Machine> = new MatTableDataSource<Machine>();
  columnOptions = CsListColumns;
  unsortableColumns = [
    'loc',
    'pwrSrc',
    'csDev',
    'mtype',
    'ar',
    'mgroup',
    'hrs',
    'hasLFSOC',
  ];
  selectAll = false;
  isAddingColumn = false;
  isSorterCollapsed = true;
  selection = new SelectionModel<string>(true, []);
  fieldType = CSFieldType;
  ocids: OcidItems = {};
  moreOptions = [
    {
      text: 'Download XLSX',
      click: this.download.bind(this),
    },
  ];
  fieldDisplayMapping = MachineFieldDisplay;
  resultsLength = 0;
  private selectedFilters: MachineFilterSelection[] = [];
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationDialogsService,
    private localization: LocalizationService,
    private gAService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.subs = this.localization
      .getOCIDs([
        ...Object.values(MachineFieldDisplayValueOcids).reduce((prev, i) =>
          prev.concat(i)
        ),
        ...Object.values(MachineFieldDisplay),
        'clearsky.deselect-name',
        'clearsky.select-label',
        'clearsky.filter-selected-machines-label',
        'clearsky.machine-filter-remove-label',
        'global.proceed',
        'global.cancel',
        'clearsky.uh-oh-label',
        'clearsky.you-this-you-filtering-label',
        'clearsky.list-view-configure-columns',
        'clearsky.reported-tech-tip-message',
        'clearsky.software-status-label',
        'clearsky.device-mode-label',
        'clearsky.software-version-label',
        'clearsky.device-mode-hibernating',
        'clearsky.device-mode-not-hibernating',
        'clearsky.update-required'
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    // If the user changes the sort order, reset back to the first page.
    this.subs.add(
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))
    );

    this.subs.add(
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.clearskyService.getDataByWidgetKey(
              CsRequestKeys.listView,
              {
                paginate: true,
                pageNum: this.paginator.pageIndex + 1,
                pageSize: this.paginator.pageSize || 25,
                sorting: {
                  column: this.sort.active,
                  direction: this.sort.direction.toUpperCase(),
                },
              }
            );
          })
        )
        .subscribe((page) => {
          this.dataSource.data = (page && (page.machines as Machine[])) || [];
          this.resultsLength = (page && page.totalMachines) || 0;
        })
    );

    this.subs.add(
      combineLatest([
        this.clearskyService.currentFilters$.pipe(
          tap(() => this.paginator.firstPage())
        ),
        this.clearskyService.currentColumns$,
        this.clearskyService.legendRef$,
      ])
        .pipe(debounceTime(200))
        .subscribe(([filters, displayedColumns, legend]) => {
          // take legends
          this.legend = legend;

          // Clear selection
          this.selection.clear();
          // Now update column information
          this.selectedFilters = filters;
          this.displayedColumns = displayedColumns;
          this.selectAll =
            this.displayedColumns.length >= this.columnOptions.length;
        })
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      if (sortHeaderId == 'dtc') return data.dtc?.length;

      return data[sortHeaderId as keyof typeof data];
    };
  }

  /**
   * On row click in table.
   * @param machine
   */
  onRowClick(machine: Machine): void {
    this.dialog.open(MachineDetailsDialogComponent, {
      ...MachineDialogConfig,
      data: { sn: machine.sn },
    });
  }

  /**
   * Remove column from table.
   * @param option
   */
  removeColumn(option: string): void {
    // Check to see if column isn't filtered on
    if (
      this.selectedFilters.findIndex(
        (f) => f.key.split('.').pop() === option
      ) === -1
    ) {
      const columnIndex = this.displayedColumns.findIndex((c) => c === option);
      const columns = [...this.displayedColumns];
      columns.splice(columnIndex, 1);
      this.clearskyService.updateColumns(columns);
    } else {
      // Show dialog that they can't remove this column
      this.confirmationService.confirm(
        this.ocids['clearsky.uh-oh-label'],
        this.ocids['clearsky.you-this-you-filtering-label'],
        this.ocids['global.ok'],
        ''
      );
    }
    this.gAService.eventEmitter(
      'clearsky_click',
      'Customize List Columns',
      'click',
      'column removed',
      option
    );
  }

  /**
   * Add column to table.
   * @param option
   */
  addColumn(option: string): void {
    const columns = [...this.displayedColumns];
    columns.push(option);
    this.clearskyService.updateColumns(columns);
    this.isAddingColumn = false;
    this.gAService.eventEmitter(
      'clearsky_click',
      'Customize List Columns',
      'click',
      'column added',
      option
    );
  }

  /**
   * Drag and drop displayed column ordering.
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    const columns = [...this.displayedColumns];
    moveItemInArray(columns, event.previousIndex, event.currentIndex);
    this.clearskyService.updateColumns(columns);
  }

  /**
   * Toggle sorter in table.
   * @param e
   */
  toggleSorter(e): void {
    e.stopPropagation();

    this.isSorterCollapsed = !this.isSorterCollapsed;
  }

  /**
   * Download list view.
   */
  download(): void {
    this.gAService.eventEmitter('clearsky_click', 'list', 'download_xlsx');
    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.listView)
      .pipe(
        mergeMap((page) => {
          // Now sort the machines based on the sorting info from the sorter
          const machines =
            page && page.machines ? (page.machines as Machine[]) : [];
          const dataSrc = new MatTableDataSource(machines);
          return this.clearskyService.downloadXLSX(
            dataSrc.sortData(dataSrc.filteredData, this.dataSource.sort),
            this.displayedColumns
          );
        })
      )
      .pipe(first())
      .subscribe();
  }

  /**
   * Only if they click select/deselect all, set the options
   * @param val
   */
  toggleColumns(val: MatCheckboxChange): void {
    this.gAService.eventEmitter(
      'clearsky_click',
      'list',
      'update',
      'columns',
      this.selection.selected.toString()
    );
    this.clearskyService.updateColumns(val.checked ? this.columnOptions : []);
    this.gAService.eventEmitter(
      'clearsky_click',
      'Customize List Columns',
      'click',
      'Select All',
      val.checked.toString()
    );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  get isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.gAService.eventEmitter(
      'clearsky_click',
      'list',
      'update',
      'columns',
      'all_selected'
    );

    this.isAllSelected
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row.sn));
  }

  /**
   * Update machine selection filter.
   */
  updateMachineSelection(): void {
    this.gAService.eventEmitter(
      'clearsky_click',
      'list',
      'filter_selected_machines',
      'serial_number',
      this.selection.selected.toString()
    );
    this.confirmationService
      .confirm(
        '',
        this.ocids['clearsky.machine-filter-remove-label'],
        this.ocids['global.proceed'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.clearskyService.resetFilters();
          this.clearskyService.updateFilter(
            CSFilter.sn.key,
            this.selection.selected
          );
        }
      });
  }
}
