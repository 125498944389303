import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import {
  ClearskyCart,
  ClearskySubscriptionCheckout,
  ClearskySubscriptionPlan,
  ClearskySubscriptionPlanName,
} from '../../../contracts/clearsky/subscription/clearsky-subscription';
import { ClearskySubscriptionService } from '../clearsky-subscription.service';
import { CdkStep, StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, map, tap } from 'rxjs/operators';
import { IAddItemDto } from '../../../contracts/commerce/dto/iadd-item-dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SUBSCRIPTION } from '../subscription.constants';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionAlertDialogComponent } from '../subscription-alert-dialog/subscription-alert-dialog.component';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { combineLatest, Subscription } from 'rxjs';
import { UserEnvironments } from 'app/contracts/user/iuser';
import { isPlatformBrowser } from '@angular/common';
import { IManagedUser } from 'app/contracts/user/imanaged-user';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-stepper',
  templateUrl: './subscription-stepper.component.html',
  styleUrls: ['./subscription-stepper.component.scss'],
})
export class SubscriptionStepperComponent implements OnInit {
  private gtag: Function;
  private user: IManagedUser = { customerNumber: null, allRoles: [], erpEnvironment: null, companyName: null };
  private subs: Subscription;
  @ViewChild(MatStepper) stepper!: MatStepper;
  @Input() data: { '@type': string; tosContent: object[] };
  totalSidebarCollapsed = true;
  activePlan: ClearskySubscriptionPlan | undefined;
  selectedAddons: string[] = [];
  currentStep = 0;
  existingPlan = false;
  startFromAddOn = false;
  itemsAddToCart: IAddItemDto[] = [];
  plans: ClearskySubscriptionPlan[];
  count = 0;
  count$ = combineLatest([this.subscription.getMachineCount(), this.currentUserService.getUser()]).pipe(
    tap(([res, user]) => {
      this.count = res.clearSky;
      if (!this.count && (user.erpEnvironment === UserEnvironments.BP || user.erpEnvironment === UserEnvironments.NZ)) {
        this.dialog
          .open(SubscriptionAlertDialogComponent, {
            width: '80%',
            maxWidth: '550px',
          })
          .afterClosed()
          .subscribe();
      }
    }),
    map(([res]) => res.clearSky)
  );
  cart!: ClearskyCart;
  showUpgrade: boolean;

  constructor(
    private dialog: MatDialog,
    private subscription: ClearskySubscriptionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currentUserService: CurrentUserService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.gtag = (window as any).gtag;
      this.subs = this.currentUserService.getUser().subscribe((user) => {
        this.user = user || this.user;
      });
    }
  }

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParamMap;
    if (params.has('step') && params.get('step') === SUBSCRIPTION.ADD_ON) {
      this.startFromAddOn = true;
    }
    this.subscription.getCart().subscribe((cart) => (this.cart = cart));
    this.subscription.getPlans().subscribe((plans) => {
      this.plans = plans;
      this.showUpgrade = plans.length > 1 && plans.some(item => item.subscribe);
      this.checkPlan();
    })
  }

  checkPlan() {
    const noPackage = this.plans.every(plan => plan.subscribe === false);
    if (noPackage) {
      this.startFromAddOn = false;
      return;
    }
    const proPackage = this.plans.find(plan => plan.itemNumber === ClearskySubscriptionPlanName.pro && plan.subscribe === true)
    if (this.startFromAddOn) {
      if (proPackage) {
        this.activePlan = proPackage;
      } else {
        const plan = this.plans.find(plan => plan.subscribe);
        this.activePlan = plan;
      }
    }
  }

  /**
   * Select and activate plan.
   * @param plan
   */
  selectPlan(plan: ClearskySubscriptionPlan): void {
    this.activePlan = plan;
    this.advanceStep(this.stepper.selected);
  }

  /**
   * Select and activate addons.
   * @param addons
   */
  selectAddons(addons: string[]): void {
    this.selectedAddons = addons;
  }

  /**
   * On step change event listener.
   * @param event
   */
  onStepChange(event: StepperSelectionEvent): void {
    // Now set current step
    this.currentStep = event.selectedIndex;

    // Grab anything after and mark it incomplete
    this.stepper.steps.forEach(
      (step, index) => (step.completed = event.selectedIndex > index)
    );
  }

  /**
   * Advance stepper.
   * @param step
   * @private
   */
  advanceStep(step: CdkStep | undefined): void {
    if (step) {
      step.completed = true;
    }
    this.stepper.next();
  }

  /**
   * Go to previous step.
   */
  previousStep(): void {
    this.stepper.previous();
  }

  /**
   * Add items to cart and move to next step.
   */
  addToCart(): void {
    if (!this.activePlan) {
      return;
    }

    const addItems = () => {
      this.itemsAddToCart = [];
      if (!this.activePlan.subscribe) {
        this.itemsAddToCart.push({
          catalogRefId: this.activePlan.itemNumber,
          quantity: this.count,
          productId: this.activePlan.itemNumber,
        });
      }

      // Now push addons selected into items to add to cart
      this.activePlan.addOns.forEach((addon) => {
        if (!addon.subscribe && this.selectedAddons.includes(addon.itemNumber)) {
          this.itemsAddToCart.push({
            catalogRefId: addon.itemNumber,
            quantity: this.count,
            productId: addon.itemNumber,
          });
        }
      });

      // Add to cart
      return this.subscription
        .addToCart(this.itemsAddToCart)
        .pipe(concatMap(() => this.subscription.getCart()));
    };

    // If they had a previous cart instance, remove it
    if (this.cart.itemNumber) {
      this.subscription
        .removeFromCart(this.cart.itemNumber)
        .pipe(concatMap(() => addItems()))
        .subscribe((cart) => {
          this.cart = cart;
          this.advanceStep(this.stepper.selected);
        });
    } else {
      addItems().subscribe((cart) => {
        this.cart = cart;
        this.advanceStep(this.stepper.selected);
      });
    }
  }

  /**
   * Confirm checkout purchase.
   */
  checkout(data: ClearskySubscriptionCheckout): void {
    this.gtag('event', 'csv_subscription_checkout', {
      item: this.cart.itemNumber,
      cart: this.cart,
      customer_number: this.user.customerNumber,
      company_name: this.user.companyName,
      erpEnvironment: this.user.erpEnvironment,
      user_roles: this.user.allRoles.map(role => role.name)?.toString()
    });
    // Do processing at endpoint
    this.subscription.checkout(data).subscribe((order) => {
      this.router.navigate([
        '/clearsky/subscription/order-confirmation',
        order.id,
      ]);
    });
  }
}
