import { Component, Input, OnInit } from '@angular/core';
import {
  getQuantifiedColor,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import * as dayjs from 'dayjs';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { OcidItems } from '../../../../../contracts/ocid-items';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { MatDialog } from '@angular/material/dialog';
import { MachineBatteryMonitoringDialogComponent } from '../../machine-battery-monitoring-dialog/machine-battery-monitoring-dialog.component';
import { MachineDialogConfig } from '../../../../../contracts/clearsky/machine/machine.dialog.config';
import { ClearskyService } from '../../../../clearsky.service';
import { CSLegend } from '../../../../../contracts/clearsky/clearsky-legend';
import { WidgetColors } from 'app/contracts/clearsky/dashboard/cs-colors.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-battery-monitoring-general',
  templateUrl: './asset-battery-monitoring-general.component.html',
  styleUrls: ['./asset-battery-monitoring-general.component.scss'],
})
export class AssetBatteryMonitoringGeneralComponent implements OnInit {
  @Input() machine: Machine;
  widgetName = MachineWidgets.BATTERY_MONITORING_GENERAL;
  displayName = MachineWidgetsDisplay.assetBatteryMonitoringGeneral;
  hasClickHandler: boolean;
  stateOfChargeIconColor: string;
  batteryVoltageIconColor: string;
  waterIconColor: string;
  lastRefillDate: string;
  ocids: OcidItems;
  legend: CSLegend = {};

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.make-label',
        'clearsky.state-of-charge-label',
        'clearsky.water-label',
        'clearsky.last-refill-label',
        'clearsky.model-label',
        'clearsky.sn-label',
        'clearsky.charge-status-label',
        'clearsky.elapsed-charging-time-label',
        'clearsky.water-level-label',
        'clearsky.size-label',
        'clearsky.voltage-label',
        'clearsky.charger-type-label',
        'clearsky.n-a-label',
        'clearsky.date-time-last-charge-label',
        'clearsky.not-label',
        this.displayName,
      ])
      .pipe(first())
      .subscribe();

    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );

    // Basically only scissor lifts
    this.hasClickHandler = !!(this.machine.soc || this.machine.cstat);
    this.stateOfChargeIconColor = getQuantifiedColor(this.machine.socQ);
    this.batteryVoltageIconColor = this.machine.connStat ? WidgetColors.green : WidgetColors.grey2;
    this.waterIconColor = getQuantifiedColor(this.machine.batH2OPQ);
    this.lastRefillDate = dayjs(this.machine.lwr).format('MM/DD/YYYY');
  }

  /**
   * Activate detailed dialog.
   */
  onActivateDetailDialog(): void {
    this.dialog.open(MachineBatteryMonitoringDialogComponent, {
      ...MachineDialogConfig,
      data: {
        machine: this.machine,
      },
    });
  }
}
