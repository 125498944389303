import { isPlatformBrowser, Location, PopStateEvent } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  Event as NavigationEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
} from 'rxjs/operators';
import { combineLatest, from, interval, Observable, Subscription } from 'rxjs';
import { IUser, UserEnvironments } from './contracts/user/iuser';
import { OktaAuthWrapper } from './service/auth/okta.auth.wrapper';
import { EndecaService } from './endeca/endeca.service';
import { CartService } from './service/cart/cart.service';
import { ContentService } from './service/content.service';
import { CurrentUserService } from './service/user/current-user.service';
import { MenuService } from './service/user/menu.service';
import { WindowRefService } from './service/window-ref/window-ref.service';
import { LocalizationService } from './shared/localization/localization.service';
import { CollapseStack } from './shared/collapse/collapse-stack';
import { LayoutService } from './service/layout.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EndingSoonComponent } from './core/ending-soon/ending-soon.component';
import { appOcids } from './app-ocids';
import { ListrakService } from './service/gtm/listrak.service';
import { BVLoader } from './service/product/bv-loader.service';
import {
  addVisibilityEventListener,
  removeVisibilityEventListener,
} from './shared/visible-state';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { environment } from '../environments/environment';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  user: IUser | null = null;
  isJlg = environment.jlgStyling;
  isUserLoggedIn = false;
  private lastPoppedUrl!: string;
  private yScrollStack: number[] = [];
  private locale = null;
  private isLoggedInSub!: Subscription;
  private localeSub!: Subscription;
  private navSubscription!: Subscription;
  private isDataInit = false;
  public mainContentPaddedTop = true;
  public platformBrowser: boolean = isPlatformBrowser(this.platformId);
  public mainContentPaddedBottom = true;
  public mainContentBgColor = '';
  public isFullWidth = false;
  public showSpinner = true;
  private checkEverySecondIfExpiringSoonOrExpired!: Subscription;
  private visibilityEventListener = false;
  private endingSoonDialogRef: MatDialogRef<EndingSoonComponent> | undefined;
  isMaintenance$: Observable<boolean> = this.contentService.isMaintenance$.pipe(
    shareReplay()
  );
  @ViewChild('navigationSpinner') navSpinner!: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private winRef: WindowRefService,
    private location: Location,
    private oktaAuth: OktaAuthWrapper,
    private localization: LocalizationService,
    private endecaService: EndecaService,
    private cartService: CartService,
    private currentUserService: CurrentUserService,
    private contentService: ContentService,
    private menuService: MenuService,
    private collapseStack: CollapseStack,
    public layoutService: LayoutService,
    private dialog: MatDialog,
    private listrakService: ListrakService,
    private renderer2: Renderer2
  ) {
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);

    // Subscribe to navigation events
    router.events
      .pipe(
        filter((event: NavigationEvent) => {
          // check if it's running in NgZone
          if (!NgZone.isInAngularZone()) {
            this.ngZone.run(() => {
              this._navigationInterceptor(event);
            });
          } else {
            this._navigationInterceptor(event);
          }
          return event instanceof NavigationEnd;
        }),
        map((event: NavigationEnd) => {
          // Map response to activated route
          return this.activatedRoute;
        }),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          // Get the most nested firstChild route inside the activated route
          return route;
        }),
        // Only return the route if it's outlet is primary
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        // Return data observable of activated route
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((data: object) => {
        // log app data in here.
      });
  }

  ngOnInit() {
    /**
    this.currentUserService._stayLoggedInDialgState.subscribe(
      (dialogOpened: boolean) => {
        this.endingSoonDialogRef = dialogOpened
          ? this.endingSoonDialogRef
          : undefined;
      }
    );
     **/

    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      this.renderer2.addClass(
        document.body,
        location.href.includes('jlg.com') ? 'jlg-theme' : 'jdc-theme'
      );
      if (location.pathname.startsWith('/clearsky')) {
        this.renderer2.addClass(document.body, 'clearsky-theme');
      }
      this.location.subscribe((ev: PopStateEvent) => {
        this.lastPoppedUrl = ev.url;
      });
      // check for Okta-token-storage changes so we SSO users into Jerrdan/JLG across tabs
      const that = this;
      window.addEventListener(
        'storage',
        function (ev: StorageEvent) {
          if (ev.key === 'okta-token-storage') {
            // 2 because the value is a string, and {} indicates the user is logged out
            if (ev.newValue?.length > 2) {
              that._reloadComponent(true);
            } else {
              that._reloadComponent(false);
            }
          }
        },
        false
      );
    }

    // Show nav spinner when content is loading
    this.contentService.actionPerforming$.subscribe((isPerforming: boolean) => {
      isPerforming ? this._showNavSpinner() : this._hideNavSpinner();
    });

    // Subscribe to any changes to whether there should be padding between the header and main content
    // and the main content and footer.
    this.endecaService.mainContentPaddedTop$
      .pipe(distinctUntilChanged())
      .subscribe((mainContentPaddedTop: boolean) => {
        this.mainContentPaddedTop = mainContentPaddedTop;
        this.changeDetector.detectChanges();
      });
    this.endecaService.mainContentPaddedBottom$
      .pipe(distinctUntilChanged())
      .subscribe((mainContentPaddedBottom: boolean) => {
        this.mainContentPaddedBottom = mainContentPaddedBottom;
        this.changeDetector.detectChanges();
      });
    this.endecaService.mainContentBgColor$
      .pipe(distinctUntilChanged())
      .subscribe((mainContentBgColor: string) => {
        this.mainContentBgColor = mainContentBgColor;
        this.changeDetector.detectChanges();
      });
    this.endecaService.mainContentFullWidth$
      .pipe(distinctUntilChanged())
      .subscribe((isFullWidth: boolean) => {
        this.isFullWidth = isFullWidth;
        this.changeDetector.detectChanges();
      });
  }

  private _navigationInterceptor(event: NavigationEvent) {
    // NAVIGATION START. Only execute this logic if the platform is browser.
    if (event instanceof NavigationStart) {
      if (isPlatformBrowser(this.platformId)) {
        // Close all collapsable items
        this.collapseStack.dismissAll();

        // Hide cart preview manually since it's not an overlay item
        this.layoutService.cartPreviewCollapsed = true;
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      }
      // Show spinner
      this._showNavSpinner();
    }

    // NAVIGATION END
    if (event instanceof NavigationEnd) {
      // Set the url state to be used globally.
      this.contentService.setUrlState(event.url);
      // Only execute the following if the platform is browser.
      if (this.platformBrowser) {
        // Check if we're on the PayPal redirect page
        if (this.router.url.includes('paypal-redirect')) {
          window.opener.postMessage('paypalVerified', '*');
          window.close();
        }
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          // Check if we're passing a scrollTop option
          const prevScrollPos =
            this.router.getCurrentNavigation()?.extras?.state?.scrollTop ?? 0;
          try {
            setTimeout(() => {
              window.scrollTo({
                left: 0,
                top: prevScrollPos,
                behavior: 'smooth',
              });
            }, 0);
          } catch (e) {
            window.scrollTo(0, prevScrollPos);
          }
        }
      }
      // Hide spinner only if other component didn't trigger spinner to perform
      if (!this.contentService.isPerforming()) {
        this._hideNavSpinner();
      }

      this._initData();
    }

    // NAVIGATION CANCEL // NAVIGATION ERROR
    if (event instanceof NavigationCancel || event instanceof NavigationError) {
      this._hideNavSpinner();
      this._initData();
    }
  }

  /**
   * Show the nav spinner between nav states.
   *
   * @private
   */
  private _showNavSpinner() {
    this.showSpinner = true;
    this.changeDetector.detectChanges();
  }

  /**
   * Hide the nav spinner when nav finishes.
   *
   * @private
   */
  private _hideNavSpinner() {
    this.showSpinner = false;
    this.changeDetector.detectChanges();
  }

  private _reloadComponent(isLogin: boolean) {
    /**
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if (isLogin) {
      // refresh current page if logging in
      this.router.navigateByUrl(this.router.url);
    } else {
      // redirect to homepage if logging out
      this.router.navigate(['/']);
    }
     **/
  }

  ngOnDestroy() {
    // Only unsubscribe if the subscriptions have been subscribed to.
    if (this.isLoggedInSub) {
      this.isLoggedInSub.unsubscribe();
    }
    if (this.locale) {
      this.localeSub.unsubscribe();
    }
    if (this.navSubscription) {
      this.navSubscription.unsubscribe();
    }
    if (this.checkEverySecondIfExpiringSoonOrExpired) {
      this.checkEverySecondIfExpiringSoonOrExpired.unsubscribe();
    }
  }

  /**
   * Helper function to check if one minute is remaining or the session has expired.
   * If any of those conditions are met it will show the appropriate modal.
   */
  checkIfExpiringSoonOrExpired(): void {
    /**
    const expiredAtDate = dayjs(localStorage.getItem('session_expires_at'));

    // Give the user at least 5min to refresh their session or logout.
    if (
      dayjs().isBetween(expiredAtDate.subtract(5, 'minutes'), expiredAtDate) &&
      !this.endingSoonDialogRef
    ) {
      this.endingSoonDialogRef = this.dialog.open(EndingSoonComponent, {
        width: '500px',
      });
    }

    // Close the other modal and show the expired modal.
    if (dayjs().isSameOrAfter(expiredAtDate)) {
      this.oktaAuth.logOutAndResetOle(false);
      this.dialog.closeAll();
      this._hideNavSpinner();
    }
     **/
  }

  /**
   * Set session check for expiration based on visibility of tab.
   * @param vis
   * @private
   */
  private setSessionTimerBasedOnVisibility(vis: boolean): void {
    // Only add interval if tab is visible
    if (vis) {
      this.checkEverySecondIfExpiringSoonOrExpired = interval(1000).subscribe(
        () => {
          this.sessionHasExpiredOrExpiringSoonModals();
        }
      );
    } else if (this.checkEverySecondIfExpiringSoonOrExpired) {
      // Stop the interval timer until they come back to the tab
      this.checkEverySecondIfExpiringSoonOrExpired.unsubscribe();
    }
  }

  /**
   * Init session timer if logged in.
   * @param isLoggedIn
   * @private
   */
  private initSessionTimerBasedOnLogin(isLoggedIn: boolean): void {
    // Remove event listener if it was added
    if (this.visibilityEventListener) {
      removeVisibilityEventListener(
        this.setSessionTimerBasedOnVisibility.bind(this)
      );
      this.visibilityEventListener = false;
    }

    if (isLoggedIn) {
      // Is visibility API even supported?
      if (
        addVisibilityEventListener(
          this.setSessionTimerBasedOnVisibility.bind(this)
        )
      ) {
        this.visibilityEventListener = true;
      } else {
        // Start the timer regardless then. The user just may miss it if the tab isn't active
        this.setSessionTimerBasedOnVisibility(true);
      }
    } else if (
      !isLoggedIn &&
      localStorage &&
      localStorage.getItem('session_expires_at') !== 'Not Authenticated'
    ) {
      localStorage.setItem('session_expires_at', 'Not Authenticated');
    } else {
      // Stop the interval timer until they come back to the tab
      this.checkEverySecondIfExpiringSoonOrExpired &&
        this.checkEverySecondIfExpiringSoonOrExpired.unsubscribe();
    }
  }

  private _initData() {
    if (this.isDataInit) {
      this.displayListrakPopup();
      return;
    }
    this.isLoggedInSub = combineLatest([
      this.oktaAuth.isLoggedIn,
      this.isMaintenance$,
    ])
      .pipe(distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]))
      .subscribe(([isLoggedIn, isMaintenance]) => {
        if (isMaintenance) {
          return false;
        }
        this.isDataInit = true;
        // determine user's login status from oktaAuth isLoggedIn instead of currentUser payload
        this.isUserLoggedIn = isLoggedIn;
        this.initSessionTimerBasedOnLogin(isLoggedIn);
        this.displayListrakPopup();
        // Determine if the payload cache needs flushed for any of the following calls.
        // Is only applicable when we are signing a user out after they have SSO'd to JLG
        // or Jerrdan, signed out and then attempted to SSO back.
        const clearPayloadCache: boolean = this.oktaAuth.clearPayloadCache;

        // Get the user's cart, menus, and OCIDs when their state changes.
        if (this.platformBrowser) {
          this.cartService.getCart(false, clearPayloadCache).subscribe(() => {
            this.changeDetector.detectChanges();
          });
        }

        this.menuService.getMenus(clearPayloadCache).subscribe(() => {
          this.changeDetector.detectChanges();
        });

        this.localization
          .getOCIDs(appOcids, clearPayloadCache)
          .subscribe(() => {
            this.localization.setPaginationLocalization();
            this.changeDetector.detectChanges();
          });

        // We can set the clearPayloadCache to false now that we have made all 3 requests to update
        // the user's state. The setting of this variable to false does not depend on the completion
        // of the previous http requests.
        this.oktaAuth.clearPayloadCache = false;
      });

    this.localeSub = this.currentUserService.userSubject.subscribe((user) => {
      if (user) {
        // load the correct BV script based on user language and environment
        if (user.erpEnvironment === UserEnvironments.AA && this.isJlg) {
          BVLoader.load(user.locale, environment.production);
        }
        // send logged in user data to Google Analytics
        const dataLayer = (this.winRef.nativeWindow.dataLayer =
          this.winRef.nativeWindow.dataLayer || []);
        if (user.accountType !== '' && !!user.login) {
          dataLayer.push({
            event: 'userUpdate',
            user_id: user.customerNumber,
            accountType: user.accountType,
            NCM: user.customerNumber,
          });
        }
      }
      // Only refresh the menus and ocids if the user already existed.
      if (this.user && user) {
        // If the user locale has changed
        if (this.locale !== user.locale) {
          const currentOCIDs = Object.keys(this.localization.OCIDs.value);
          if (currentOCIDs.length > 0) {
            this.localization
              .refreshOCIDs()
              .pipe(
                mergeMap(() => {
                  this.localization.setPaginationLocalization();
                  // On locale change, set localization for pagination and refresh the page to get the correct cartridges
                  // Clear _gl tracking param, otherwise it'd redirect to a 404 page
                  return from(
                    this.router.navigate([], {
                      queryParams: {
                        _gl: null,
                      },
                      queryParamsHandling: 'merge',
                    })
                  );
                })
              )
              .subscribe(() => {
                this.changeDetector.detectChanges();
              });
          } else {
            this.menuService.getMenus().subscribe(() => {
              this.changeDetector.detectChanges();
            });
          }
        }
        this.locale = user.locale;
      } else if (user) {
        // Still set the locale if the user is returned from the subject.
        this.locale = user.locale;
      }
      this.user = user;
    });
  }

  /**
   * Check if logged and call the checkIfExpiringSoonOrExpired function.
   * If for whatever reason and logged in there isn't a local storage retrieve and update session_expires_at.
   * This will update the session time which is a side effect.
   * Else set the session_expired_at as Not Authenticated.
   */
  private sessionHasExpiredOrExpiringSoonModals(): void {
    /**
    this.checkIfExpiringSoonOrExpired();
    if (
      (localStorage.getItem('session_expires_at') === null ||
        localStorage.getItem('session_expires_at') === 'Not Authenticated') &&
      this.isUserLoggedIn
    ) {
      this.oktaAuth.getExpiredSessionTime();
    }
     **/
  }

  private displayListrakPopup() {
    // Trigger the Listrak popup after 3 seconds if there's no cookie, user is logged in and AA, and it's not the homepage
    if (
      this.isUserLoggedIn &&
      this.user?.erpEnvironment === UserEnvironments.AA &&
      !this.contentService.getCookie('dontShowPopup') &&
      this.contentService.getCookieValue('cookieConsent') === 'true' &&
      this.router.url !== '/'
    ) {
      setTimeout(() => {
        this.listrakService.showPopup('JLG-Popup');
        // Create a cookie so we don't trigger the popup again
        const d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
        this.contentService.createCookie(
          'dontShowPopup',
          'true',
          d.toUTCString()
        );
      }, 3000);
    }
  }
}
