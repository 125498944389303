import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { INavMenu } from '../../../contracts/user/inav-menu';
import { map } from 'rxjs/operators';
import { CLEARSKY_MENU_MAPPING } from './navigation.const';
import { LayoutService } from '../../../service/layout.service';
import { MenuService } from '../../../service/user/menu.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cs-layout-nav',
  templateUrl: './cs-layout-nav.component.html',
  styleUrls: ['./cs-layout-nav.component.scss'],
})
export class CsLayoutNavComponent {
  @Input() hideMenuItems = false;
  navItems$: Observable<INavMenu[]> = this.menuService.getClearskyMenu().pipe(
    map((menu) => {
      return menu.map((i) => {
        const menuMappings = CLEARSKY_MENU_MAPPING[i.label!.toLowerCase()];
        if (menuMappings) {
          i = { ...i, ...menuMappings };
        }
        return i;
      });
    })
  );
  ocids: OcidItems = {};
  currentPath = this.router.url;

  constructor(
    private layoutService: LayoutService,
    private menuService: MenuService,
    private localization: LocalizationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.localization
      .getOCIDs(
        Object.values(CLEARSKY_MENU_MAPPING).reduce((prev, t) => {
          if (t.tooltip) {
            prev.push(t.tooltip);
          }

          return prev;
        }, [] as string[])
      )
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Is CSV3 nav menu collapsed to icon mode?
   */
  get navMinimized(): boolean {
    return this.layoutService.clearskyNavCollapsed;
  }
}
