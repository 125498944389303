import { Component, Input, OnInit } from '@angular/core';
import { IShippingGroup } from '../../../../../../contracts/commerce/ishipping-group';
import { IUser } from '../../../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../../../service/user/current-user.service';
import { LocalizationService } from '../../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-checkout-freight-type-review',
  templateUrl: './checkout-freight-type-review.component.html',
})
export class CheckoutFreightTypeReviewComponent implements OnInit {
  @Input() shipping: IShippingGroup;
  @Input() title: string;
  @Input() icon: string;
  @Input() isFreightQuote: boolean;
  user: IUser;
  ocids: {} = {};

  constructor(
    private localization: LocalizationService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
      }
    });
  }
}
