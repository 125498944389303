import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import {
  Widgets,
  WidgetsDisplay,
} from 'app/contracts/clearsky/dashboard/cs-dashboard.dto';
import { FleetAgeDialogComponent } from '../fleet-age/fleet-age-dialog/fleet-age-dialog.component';
import { MachineDialogConfig } from '../../../../contracts/clearsky/machine/machine.dialog.config';
import { OcidItems } from '../../../../contracts/ocid-items';
import { ClearskyService } from '../../../clearsky.service';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-average-age',
  templateUrl: './fleet-average-age.component.html',
  styleUrls: ['./fleet-average-age.component.scss'],
})
export class FleetAverageAgeComponent implements OnInit {
  widgetName = Widgets.FLEET_AVERAGE_AGE;
  displayName = WidgetsDisplay.fleetAverageAge;
  average: string | undefined;
  ocids: OcidItems = {};

  constructor(
    private dialog: MatDialog,
    private localization: LocalizationService,
    private clearskyService: ClearskyService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([this.displayName])
      .subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.months-label',
      ])
      .pipe(first())
      .subscribe();

    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .subscribe((page) => {
        this.average = (
          page && page.aggregations && page.aggregations.fleetOverview
            ? page.aggregations.fleetOverview.fleetAverageAge * 12
            : 0
        ).toFixed(2);
      });
  }

  /**
   * Open fleet age widget when clicking on title.
   */
  openFleetAgeWidget(): void {
    this.dialog.open(FleetAgeDialogComponent, {
      ...MachineDialogConfig,
    });
  }
}
