import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { PlateIdReplacementComponent } from './plate-id-replacement.component';
import { PlateIdReplacementService } from './plate-id-replacement.service';
import { ReplacementFormComponent } from './replacement-form/replacement-form.component';
import { RequestTypeFormComponent } from './request-type-form/request-type-form.component';


@NgModule({
  declarations: [
    PlateIdReplacementComponent,
    RequestTypeFormComponent,
    ReplacementFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    PlateIdReplacementService
  ]
})
export class PlateIdReplacementModule { }
