import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderNowComponent } from './order-now/order-now.component';
import { SharedModule } from '../../../shared/shared.module';
import { ImportPartsComponent } from './import-parts/import-parts.component';

@NgModule({
  declarations: [OrderNowComponent, ImportPartsComponent],
  imports: [CommonModule, SharedModule],
})
export class PartsModule {}
