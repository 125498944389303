import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { combineLatest, of } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { INavMenu } from '../../../contracts/user/inav-menu';
import { NotificationService } from '../../../service/notification/notification.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { MenuService } from '../../../service/user/menu.service';
import { UserAddressesService } from '../../../service/user/user-addresses.service';
import { UserService } from '../../../service/user/user.service';
import { ShippingAddressModel } from '../../../shared/address.model';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { ListrakService } from '../../../service/gtm/listrak.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { customValidation } from '../../../shared/password-validator.directive';
import { OcidItems } from '../../../contracts/ocid-items';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { CountryWithoutZip } from 'app/contracts/user/iaddress';
import { OktaAuthWrapper } from '../../../service/auth/okta.auth.wrapper';
import { Router } from '@angular/router';
import { AuthErrorCode } from '../../../shared/user.profile.model';
import { AccountType } from '../../../contracts/user/iuser';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-user-registration',
  styleUrls: ['user-registration.component.scss'],
  templateUrl: './user-registration.component.html',
  providers: [NotificationService],
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
  profileFormGroup: UntypedFormGroup;
  userTypeFormGroup: UntypedFormGroup;
  isLinear = true;
  agreed = false;
  consent = false;
  countryOptions: INavMenu;
  stateProvinceOptions: INavMenu;
  retailStateOptions: INavMenu;
  retailCountryOptions: INavMenu;
  nonRetailStateOptions: INavMenu;
  nonRetailCountryOptions: INavMenu;
  securityQuestionOptions: INavMenu;
  userRoleOptions: INavMenu;
  accountLanguageOptions: INavMenu;
  passwordHide = true;
  existingAccount = false;
  idChecked = false;
  newUserLogin: string = null;
  ocids: OcidItems = {};
  addressValidated = false;
  accountNumberLabel = '';
  platformBrowser = false;
  userAccountType = '';
  userTypeSelected = false;
  isJlg = environment.jlgStyling;
  brand = environment.jlgStyling ? 'JLG' : 'Jerr-Dan';
  enableRecaptchaV2 = false;
  captchaPassed = !environment.production;
  private nextStepId: string;
  accountType = AccountType;
  customerNumber: string;

  @ViewChild(MatStepper) stepper: MatStepper;
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private _formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private localization: LocalizationService,
    private currentUserService: CurrentUserService,
    private userService: UserService,
    private userAddressService: UserAddressesService,
    private menuService: MenuService,
    private confirmationService: ConfirmationDialogsService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private renderer: Renderer2,
    private ecommService: EcommerceService,
    private listrak: ListrakService,
    private oktaAuth: OktaAuthWrapper,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    this.userTypeFormGroup = this._formBuilder.group({
      accountNum: [''],
    });
    this.profileFormGroup = this._formBuilder.group(
      {
        firstName: ['', Validators.pattern(/^[a-zA-Z\s.]*$/)],
        lastName: ['', Validators.pattern(/^[a-z '-]+$/i)],
        language: [''],
        email: ['', Validators.email],
        marketingUserRole: [''],
        securityQ: [''],
        securityA: [''],
        login: ['', Validators.email],
        password: [
          '',
          [
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[!@#$%^&*])).+$/
            ),
            Validators.minLength(8),
          ],
        ],
        confirmPassword: [''],
        companyName: [''],
        address1: [''],
        address2: [''],
        address3: [''],
        city: ['', Validators.pattern(/^[[a-zA-Z \-]+]*$/)],
        country: [''],
        state: [''],
        zip: [''],
        accountNum: [''],
        defaultStoreId: [''],
        phone: ['', Validators.pattern(/^[0-9()+-.ext ]*$/)],
        fax: ['', Validators.pattern(/^[0-9() +-]*$/)],
        notes: [''],
      },
      {
        validator: [customValidation()],
      }
    );
    // Get OCIDs for page (including global)
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
      if (this.isJlg) {
        this.accountNumberLabel = this.ocids['global.jlg-account-number'];
      } else {
        this.accountNumberLabel = this.ocids['global.jerrdan-account-number'];
      }
    });
    this.localization
      .getOCIDs([
        'address-validation.message-7',
        'registration.access-agreement',
        'profile.company-contact-name-label',
        'registration.message-1',
        'registration.message-3',
        'registration.message-4',
        'registration.login-id',
        'registration.login-id-available',
        'registration.notification-email',
        'registration.your-address-1',
        'registration.your-address-2',
        'registration.your-address-3',
        'global.terms-intro',
        'global.privacy-intro',
        'account.login-id-info-box',
        'account-profile.password-message-1',
        'account.security-question-info-box',
        'account.account-number-info-box',
        'registration.store-id-info-box',
        'account-profile-message-1',
        'account.email-info-box',
        'global.privacy-consent-1',
        'global.privacy-consent-2',
        'global.privacy-consent-3',
        'registration.account-holder-question',
        'registration.account-holder-message',
        'registration.account-holder-info-message',
        'registration.confirm-user-type-label',
        'registration.retail-confirmation-message',
      ])
      .subscribe();
    // Subscribe to menus.
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? combineLatest(
              this.menuService.getMenuByUxKey('countries'),
              this.menuService.getMenuByUxKey('retailCountries'),
              this.menuService.getMenuByUxKey('account-security-questions'),
              this.menuService.getMenuByUxKey('account-marketing-roles'),
              this.menuService.getMenuByUxKey('account-languages')
            )
            : of(null)
        )
      )
      .subscribe((menus: INavMenu[]) => {
        if (menus) {
          // Set country related menu
          this.nonRetailCountryOptions = menus[0];
          this.nonRetailStateOptions = {};
          this.nonRetailCountryOptions?.childMenus.forEach((menu: INavMenu) => {
            this.nonRetailStateOptions[menu.uxKey] = menu.childMenus;
          });
          // Set default country/state options
          this.countryOptions = this.nonRetailCountryOptions;
          this.stateProvinceOptions = this.nonRetailStateOptions;
          // Only set retail countries menu on JLG
          if (this.isJlg) {
            this.retailCountryOptions = menus[1];
            this.retailStateOptions = {};
            this.retailCountryOptions?.childMenus.forEach((menu: INavMenu) => {
              this.retailStateOptions[menu.uxKey] = menu.childMenus;
            });
          }
          this.securityQuestionOptions = menus[2];
          this.userRoleOptions = menus[3];
          this.accountLanguageOptions = menus[4];
        }
      });

    // add reg-page class to body to display recaptcha badge
    if (this.platformBrowser) {
      this.renderer.addClass(document.body, 'reg-page');
    }
  }

  onAgree() {
    this.agreed = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  selectUserType(type: string) {
    this.userAccountType = type;
    this.userTypeSelected = true;
    if (type == AccountType.RETAIL) {
      // change menus based on user selection
      this.countryOptions = this.retailCountryOptions;
      this.stateProvinceOptions = this.retailStateOptions;
    } else {
      this.countryOptions = this.nonRetailCountryOptions;
      this.stateProvinceOptions = this.nonRetailStateOptions;
    }
  }

  /**
   * Fires when users pass reCAPTCHA v2, allowing them to proceed with the registration
   *
   * @param {string} captchaResponse
   */
  recaptchaResolved(captchaResponse: string) {
    this.enableRecaptchaV2 = false;
    this.captchaPassed = true;
  }

  onSubmit() {
    // submit this.profileFormGroup.value, but first, users must pass the recaptcha test (OLE PROD only)
    if (!this.captchaPassed) {
      this.recaptchaV3Service
        .execute('register')
        .pipe(
          concatMap((token) => {
            // now validate that token with the backend API
            return this.currentUserService.validateCaptcha(token);
          })
        )
        .subscribe((result: any) => {
          if (result.success) {
            this.registerAccount();
          } else {
            // enable recaptcha v2 if v3 fails
            this.enableRecaptchaV2 = true;
          }
        });
    } else {
      // if it's OLE TEST or if user passes recaptcha v2, allow them to register
      this.registerAccount();
    }
  }

  stepChanged(event, stepper) {
    if (event.selectedIndex === 0) {
      stepper.selected.interacted = false;
    }

    // Scroll next step into view every step change
    this.nextStepId = stepper._getStepLabelId(event.selectedIndex);
  }

  onAnimationComplete(): void {
    if (!this.nextStepId) {
      return;
    }

    // If we need to go to the next step, scroll into view
    if (isPlatformBrowser(this.platformId)) {
      const stepElement = this.document.getElementById(this.nextStepId);
      if (stepElement) {
        // Take header into consideration
        const y =
          stepElement.getBoundingClientRect().top +
          window.pageYOffset -
          this.document.querySelector('#header').clientHeight;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }

  checkLoginId() {
    this.idChecked = false;
    if (this.profileFormGroup.controls.login.valid) {
      this.getExistingAccount(this.profileFormGroup.controls['login'].value);
    }
  }

  getExistingAccount(userId: string) {
    this.notificationService.reset();
    this.userService.checkExistingAccount(userId).subscribe(
      (data) => {
        this.idChecked = true;
        if (data['userAvailable'] === 'true') {
          this.existingAccount = false;
        } else {
          this.existingAccount = true;
          this.profileFormGroup.controls.login.setErrors({ emailAlreadyExists: true })
        }
      },
      (error) => {
        console.log(error);
        this.notificationService.addError(error.error.title);
      }
    );
  }

  registerAccount() {
    // Fixes issue of toString error in IE and gets customerNumber from appropriate field

    if (this.isJlg) {
      this.customerNumber = this.userTypeFormGroup.controls['accountNum'].value
        ? this.userTypeFormGroup.controls['accountNum'].value
          .toString()
          .replace(/[^0-9]/g, '')
        : '';
    } else {
      this.customerNumber = this.profileFormGroup.controls['accountNum'].value
        ? this.profileFormGroup.controls['accountNum'].value
          .toString()
          .replace(/[^0-9]/g, '')
        : '';
    }
    const defaultStoreId = this.profileFormGroup.controls['defaultStoreId']
      .value
      ? this.profileFormGroup.controls['defaultStoreId'].value.toString()
      : '';
    const PostalCode = this.profileFormGroup.controls['zip'].value
      ? this.profileFormGroup.controls['zip'].value.toString()
      : '';
    // find pardot visitor cookie value
    const cookie = {};
    document.cookie.split(';').forEach(function (el) {
      const [k, v] = el.split('=');
      cookie[k.trim()] = v;
    });
    const visitorId = cookie['visitor_id471491']
      ? cookie['visitor_id471491']
      : '';
    const newUser = {
      login: this.profileFormGroup.controls['login'].value,
      visitorId: visitorId,
      password: this.profileFormGroup.controls['password'].value,
      firstName: this.truncateValue(
        this.profileFormGroup.controls['firstName'].value,
        40
      ),
      lastName: this.truncateValue(
        this.profileFormGroup.controls['lastName'].value,
        40
      ),
      recoveryQuestion: this.profileFormGroup.controls['securityQ'].value,
      recoveryAnswer: this.profileFormGroup.controls['securityA'].value,
      locale: this.profileFormGroup.controls['language'].value,
      marketingUserRole:
        this.profileFormGroup.controls['marketingUserRole'].value,
      customerNumber: this.customerNumber,
      email: this.profileFormGroup.controls['email'].value,
      specialNotes: this.profileFormGroup.controls['notes'].value,
      homeAddress: {
        address1: this.truncateValue(
          this.profileFormGroup.controls['address1'].value,
          50
        ),
        address2: this.truncateValue(
          this.profileFormGroup.controls['address2'].value,
          50
        ),
        address3: this.truncateValue(
          this.profileFormGroup.controls['address3'].value,
          50
        ),
        city: this.profileFormGroup.controls['city'].value,
        companyName: this.profileFormGroup.controls['companyName'].value,
        country: this.profileFormGroup.controls['country'].value,
        phoneNumber: this.truncateValue(
          this.profileFormGroup.controls['phone'].value,
          30
        ),
        postalCode: this.truncateValue(PostalCode, 10),
        faxNumber: this.truncateValue(
          this.profileFormGroup.controls['fax'].value,
          30
        ),
        state: this.profileFormGroup.controls['state'].value,
      },
    };
    // Add default store id to object if it exists, otherwise it will throw an error when registering
    // the user.
    if (defaultStoreId) {
      newUser['defaultStoreId'] = defaultStoreId;
    }
    this.register(newUser);
  }

  toReview() {
    const PostalCode = this.profileFormGroup.controls['zip'].value
      ? this.profileFormGroup.controls['zip'].value.toString()
      : '';
    const registrationAddress = new ShippingAddressModel(
      '',
      this.profileFormGroup.controls['companyName'].value
        ? this.profileFormGroup.controls['companyName'].value
        : '',
      this.profileFormGroup.controls['address1'].value
        ? this.profileFormGroup.controls['address1'].value
        : '',
      this.profileFormGroup.controls['address2'].value
        ? this.profileFormGroup.controls['address2'].value
        : '',
      this.profileFormGroup.controls['address3'].value
        ? this.profileFormGroup.controls['address3'].value
        : '',
      this.profileFormGroup.controls['city'].value,
      this.profileFormGroup.controls['state'].value,
      PostalCode ? PostalCode : '',
      this.profileFormGroup.controls['country'].value
        ? this.profileFormGroup.controls['country'].value
        : '',
      '',
      '',
      false
    );
    // We're validation all addresses except those from countries without a zip code.
    // US uses UPS and the rest of the countries use DHL.
    this.notificationService.reset();
    const countriesToSkip = ['UK', 'IE', 'EI'];
    if (
      !this.addressValidated &&
      this.zipRequired() &&
      countriesToSkip.every((country) => registrationAddress.country != country)
    ) {
      this.userAddressService
        .isAddressValid({
          address1: registrationAddress.address1
            ? registrationAddress.address1
            : '',
          address2: registrationAddress.address2
            ? registrationAddress.address2
            : '',
          address3: registrationAddress.address3
            ? registrationAddress.address3
            : '',
          city: registrationAddress.city ? registrationAddress.city : '',
          state: registrationAddress.state ? registrationAddress.state : '',
          postalCode: registrationAddress.postalCode
            ? registrationAddress.postalCode
            : '',
          country: registrationAddress.country
            ? registrationAddress.country
            : '',
        })
        .subscribe(
          (res) => {
            if (res === 'valid') {
              this.stepper.next();
            } else if (res) {
              this.userAddressService
                .confirmAddress(res, registrationAddress)
                .subscribe((result) => {
                  if (result) {
                    this.addressValidated = true;
                    this.profileFormGroup.controls['address1'].setValue(
                      this.toTitleCase(result.addressLine)
                    );
                    this.profileFormGroup.controls['city'].setValue(
                      this.toTitleCase(result.city)
                    );
                    this.profileFormGroup.controls['state'].setValue(
                      result.state
                    );
                    this.profileFormGroup.controls['zip'].setValue(result.zip);
                  }
                });
            } else {
              this.userAddressService
                .confirmAddress(res, registrationAddress)
                .subscribe((result) => {
                  if (result) {
                    this.addressValidated = true;
                    this.profileFormGroup.controls['address1'].setValue(
                      this.toTitleCase(result.addressLine)
                    );
                    this.profileFormGroup.controls['city'].setValue(
                      this.toTitleCase(result.city)
                    );
                    this.profileFormGroup.controls['state'].setValue(
                      result.state
                    );
                    this.profileFormGroup.controls['zip'].setValue(result.zip);
                  }
                });
            }
          },
          (error) => {
            // status 400 is being used for DHL errors
            if (error.error.httpStatus === 400) {
              this.confirmationService.confirm(
                this.ocids['address-validation.message-7'],
                ''
              );
            } else {
              this.notificationService.addError(error.error.title);
            }
          }
        );
    } else {
      // If none of the address has been filled out, just register the use, otherwise an error will be thrown
      if (this.profileFormGroup.valid) {
        this.stepper.next();
      } else {
        setTimeout(function () {
          // console.log(document.querySelector('.mat-error'));
          if (this.platformBrowser) {
            document
              .querySelector('.mat-error')
              .scrollIntoView({ behavior: 'smooth' });
          }
        }, 500);
      }
    }
  }

  /**
   * Calls the register user endpoint.
   *
   * @param newUser
   */
  register(newUser) {
    this.notificationService.reset();
    this.currentUserService.registerUser(newUser).subscribe(
      (data: any) => {
        this.newUserLogin = data.login;
        this.stepper.next();
        this.listrak.captureRegistration(data);
        // push GA + FB Conversion Event
        if (this.platformBrowser) {
          this.ecommService.register(this.userAccountType, this.customerNumber);
        }
        // automatically log user in if they're retail
        if(this.userAccountType === AccountType.RETAIL) {
          this.login();
        }
      },
      (error) => {
        this.notificationService.addError(error.error.title);
        this.stepper.previous();
      }
    );
  }

  /**
   * Removes non-numeric characters from field on key up.
   */
  removeSpecialChars() {
    const accountNum = this.isJlg
      ? this.userTypeFormGroup.controls['accountNum']
      : this.profileFormGroup.controls['accountNum'];
    // Test if the value of the account number field meets the regex criterial of numeric-only chars.
    if (/[^0-9]/g.test(accountNum.value.toString())) {
      accountNum.setValue(accountNum.value.toString().replace(/[^0-9]/g, ''));
    }
  }

  /**
   * Convert string to title case.
   *
   * @returns {string}
   */
  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Method used to truncate a string to its character limit before passing it to register.
   *
   * @param {string} value
   * @param {number} limit
   *
   * @returns {string}
   */
  truncateValue(value: string, limit: number) {
    return value.substring(0, limit);
  }

  get password() {
    return this.profileFormGroup.controls.password;
  }

  login() {
    this.notificationService.reset();
    this.oktaAuth.login(this.profileFormGroup.controls['login'].value, this.profileFormGroup.controls['password'].value).subscribe({
      next: () => {
        // If the user is logging in via the global login form navigate the user with the return url if it exists
        // or the current url.
        if (this.oktaAuth.returnUrl) {
          this.router.navigateByUrl(this.oktaAuth.returnUrl);
        } else {
          // Navigate the user to the home page if they had been resetting their password or unlocking their account.
          this.router.navigate(['/']);
        }
      },
      error: (error) => {
        // Set error according to all special conditions (where action can be taken on the error) or error that
        // is only displayed and there is no action.
        if (
          error['o:errorCode'] === AuthErrorCode.FORBIDDEN ||
          error['o:errorCode'] === AuthErrorCode.FORBIDDEN2
        ) {
          // If a user tries to sign in with a login that is not recognized, set OCID. We are
          // displaying a different error message when an account is not recognized.
          this.notificationService.addError(this.ocids['signin.invalid-login']);
        } else if (error.status === '401') {
          // Invalid request parameters.
          this.notificationService.addError(this.ocids['signin.invalid-login']);
        } else {
          // All other errors.
          this.notificationService.addError(error.title);
        }
      },
    });
  }

  /**
   * Determine whether or not the postal code field should be required
   * @returns {boolean}
   */
  zipRequired(): boolean {
    return CountryWithoutZip.every(
      (country) => this.profileFormGroup.controls['country'].value != country
    );
  }

  ngOnDestroy(): void {
    // remove reg-page class from body once user leaves page to hide captcha badge
    if (this.platformBrowser) {
      this.renderer.removeClass(document.body, 'reg-page');
    }
  }
}
