export const CsListColumns: string[] = [
  'mxAge',
  'cn',
  'pwrSrc',
  'rep',
  'tid',
  'dtc',
  'eid',
  'brand',
  'model',
  'hasTA',
  'lrt',
  'sn',
  'csDev',
  'snName',
  'mtype',
  'hasBA',
  'ar',
  'hasEA',
  'yb',
  'mgroup',
  'hasLFSOC',
  'hasSA',
  'hrs',
  'ign',
  'flvlQ',
  'copHrs',
  'sstat',
  'dolc',
  'defRmQ',
  'socQ',
  'sofVer',
  'sofStat',
  'hib',
  'pvc'
];
