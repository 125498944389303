// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-list-item-code {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  margin-bottom: 0.25rem;
  font-weight: bold;
  display: inline-block;
}
.article-list-item-code._engine {
  background-color: #fae4d7;
  color: var(--orange);
}
.article-list-item-code._machine {
  background-color: #dcf3f7;
  color: #006f77;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFydGljbGUtcmVzdWx0LWxpc3QtaXRlbS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGtCQUFBO0VBQ0EsdUJBQUE7RUFDQSxrQkFBQTtFQUNBLHNCQUFBO0VBQ0EsaUJBQUE7RUFDQSxxQkFBQTtBQUNGO0FBQ0U7RUFDRSx5QkFBQTtFQUNBLG9CQUFBO0FBQ0o7QUFFRTtFQUNFLHlCQUFBO0VBQ0EsY0FBQTtBQUFKIiwiZmlsZSI6ImFydGljbGUtcmVzdWx0LWxpc3QtaXRlbS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5hcnRpY2xlLWxpc3QtaXRlbS1jb2RlIHtcclxuICBmb250LXNpemU6IC43NXJlbTtcclxuICBwYWRkaW5nOiAuMjVyZW0gLjVyZW07XHJcbiAgYm9yZGVyLXJhZGl1czogNXB4O1xyXG4gIG1hcmdpbi1ib3R0b206IC4yNXJlbTtcclxuICBmb250LXdlaWdodDogYm9sZDtcclxuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XHJcblxyXG4gICYuX2VuZ2luZSB7XHJcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmFlNGQ3O1xyXG4gICAgY29sb3I6IHZhcigtLW9yYW5nZSk7XHJcbiAgfVxyXG5cclxuICAmLl9tYWNoaW5lIHtcclxuICAgIGJhY2tncm91bmQtY29sb3I6ICNkY2YzZjc7XHJcbiAgICBjb2xvcjogIzAwNmY3NztcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/article-result-list/article-result-list-item/article-result-list-item.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;AACF;AACE;EACE,yBAAA;EACA,oBAAA;AACJ;AAEE;EACE,yBAAA;EACA,cAAA;AAAJ;AACA,g7BAAg7B","sourcesContent":[".article-list-item-code {\r\n  font-size: .75rem;\r\n  padding: .25rem .5rem;\r\n  border-radius: 5px;\r\n  margin-bottom: .25rem;\r\n  font-weight: bold;\r\n  display: inline-block;\r\n\r\n  &._engine {\r\n    background-color: #fae4d7;\r\n    color: var(--orange);\r\n  }\r\n\r\n  &._machine {\r\n    background-color: #dcf3f7;\r\n    color: #006f77;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
