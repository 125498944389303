import { CsFilterBuilder } from './cs-filter-builder';
import { CSFilterType, QAPIFilter } from '../machine-filter-v2';

export class CsActiveLastDaysBuilder extends CsFilterBuilder {
  buildFilter(values: unknown[]): QAPIFilter[] {
    return this.buildActiveFilter(values, true);
  }

  buildActiveFilter(values: unknown[], flag: boolean): QAPIFilter[] {
    return [
      {
        column: 'miuAct',
        filterType: flag ? CSFilterType.ANY : CSFilterType.NONE,
        values,
      },
    ];
  }
}
