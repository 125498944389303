import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-icon',
  templateUrl: './widget-icon.component.html',
  styleUrls: ['./widget-icon.component.scss'],
})
export class WidgetIconComponent {
  @Input() icon!: string;
}
