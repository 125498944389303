import { Component } from '@angular/core';

@Component({
  selector: 'app-cs-layout-main',
  templateUrl: './cs-layout-main.component.html',
  styleUrls: ['./cs-layout-main.component.scss']
})
export class CsLayoutMainComponent {

}
