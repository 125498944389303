import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { CartService } from '../../../../../service/cart/cart.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { ConfirmationDialogsService } from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-invalid-part-warning',
  templateUrl: './invalid-part-warning.component.html',
  providers: [NotificationService],
})
export class InvalidPartWarningComponent implements OnInit {
  @Input() items: ICommerceItemWithCart[] = [];
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  ocids = {};
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private cartService: CartService,
    private zone: NgZone,
    private confirmationService: ConfirmationDialogsService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  /**
   * Event listener on remove click.
   */
  onRemove() {
    // Show dialog first
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.notificationService.reset();
          // Remove added items ( ugly for now since only one item can be removed at a time :( Future enhancement? )
          this.zone.runOutsideAngular(() => {
            return this.cartService
              .removeAll(this.items, 'Invalid Item')
              .subscribe(
                () => {
                  this.zone.run(() => {
                    this.removed.emit();
                  });
                },
                (err) => {
                  this.zone.run(() => {
                    this.notificationService.addError(err.error?.title);
                  });
                }
              );
          });
        }
      });
  }
}
