import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CsDashboard,
  CsDashboardDto,
  DefaultColumns,
  DefaultWidgets,
} from '../contracts/clearsky/dashboard/cs-dashboard.dto';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  concatMap,
  debounceTime,
  map,
  mergeMap,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { ClearskyService } from './clearsky.service';
import { isPlatformBrowser } from '@angular/common';
import * as isEqual from 'lodash.isequal';

const formatDashboardRequest = (data: CsDashboardDto) => {
  return {
    ...data,
    data: {
      ...data.data,
      filters: data.data.filters.map((filter) => {
        return {
          fieldName: filter.key,
          values: filter.values,
        };
      }),
    },
  };
};

@Injectable({
  providedIn: 'root',
})
export class ClearskyDashboardService {
  private dashboardCookieName = 'csLoadedDashboard';

  private _dashboards: BehaviorSubject<CsDashboard[]> = new BehaviorSubject<
    CsDashboard[]
  >([]);
  dashboards$: Observable<CsDashboard[]> = this._dashboards
    .asObservable()
    .pipe(shareReplay(1));

  private _loadedDashboard: BehaviorSubject<CsDashboard> =
    new BehaviorSubject<CsDashboard>(
      this.initCookie(this.dashboardCookieName, null, (dashboard) => {
        if (dashboard) {
          this.load(dashboard.id);
        }
      })
    );
  loadedDashboard$: Observable<CsDashboard> = this._loadedDashboard
    .asObservable()
    .pipe(
      tap((dashboard) =>
        this.updateCookie(this.dashboardCookieName, dashboard)
      ),
      shareReplay(1)
    );

  inEditMode$: Observable<boolean> = combineLatest([
    this.loadedDashboard$,
    this.clearskyService.currentFilters$,
    this.clearskyService.currentWidgets$,
    this.clearskyService.currentColumns$,
  ]).pipe(
    debounceTime(0),
    map(([dashboard, filters, widgets, columns]) => {
      // Is there even a loaded dashboard?
      const dashboardData = dashboard
        ? {
          ...dashboard.data,
          ...{
            filters: this.clearskyService.convertDashboardFilters(
              dashboard.data
            ),
          },
        }
        : {
          filters: [],
          widgets: DefaultWidgets,
          columns: DefaultColumns,
        };

      // Has the filters changed?
      const currentData = { filters, widgets, columns };

      // lodash isEqual returns false if array orders are not the same
      currentData.filters.forEach((filter) => {
        if (filter.values instanceof Array) {
          if (filter.values.every(element => typeof element === 'number' && !isNaN(element))) {
            filter.values.sort((a: number, b: number) => a - b);
          } else {
            filter.values.sort();
          }
        }
      });

      return !isEqual(dashboardData, currentData);
    })
  );

  constructor(
    private http: HttpClient,
    private clearskyService: ClearskyService,
    @Inject(PLATFORM_ID) private platformId: string
  ) { }

  /**
   * Create a new dashboard.
   * @param data
   * @param formatted
   */
  create(
    data: CsDashboardDto | CsDashboard,
    formatted = false
  ): Observable<CsDashboard> {
    return this.http
      .post<CsDashboard>(
        `${environment.apiUrl}/dashboards`,
        formatted ? data : formatDashboardRequest(data as CsDashboardDto)
      )
      .pipe(
        mergeMap((res) => {
          this._loadedDashboard.next(res);

          return forkJoin([of(res), this.all()]);
        }),
        map(([dashboard, dashboards]) => dashboard)
      );
  }

  /**
   * Copy a dashboard by ID or instance.
   * @param dashboard
   */
  copy(dashboard: number | CsDashboard): Observable<CsDashboard> {
    const loadedDashboard: Observable<CsDashboard> =
      typeof dashboard === 'string'
        ? this.load(dashboard)
        : of(dashboard as CsDashboard);

    return loadedDashboard.pipe(
      mergeMap((dashboard) => this.create(dashboard, true))
    );
  }

  /**
   * Update a dashboard by ID
   * @param id
   * @param data
   */
  update(id: number, data: CsDashboardDto, loadDefault): Observable<CsDashboard> {
    return this.http
      .patch<CsDashboard>(
        `${environment.apiUrl}/dashboards/${id}`,
        formatDashboardRequest(data)
      )
      .pipe(
        mergeMap((res) => {
          if (loadDefault) {
            this._loadedDashboard.next(res);
          }
          return forkJoin([of(res), this.all()]);
        }),
        map(([dashboard, dashboards]) => dashboard)
      );
  }

  /**
   * Get all dashboards.
   */
  all(): Observable<CsDashboard[]> {
    return this.http
      .get<CsDashboard[]>(`${environment.apiUrl}/dashboards`)
      .pipe(
        map((res) => {
          this._dashboards.next(res);
          return res;
        })
      );
  }

  /**
   * Get a dashboard.
   * @param id
   */
  get(id: number): Observable<CsDashboard> {
    return this.http.get<CsDashboard>(`${environment.apiUrl}/dashboards/${id}`);
  }

  /**
   * Load a dashboard.
   * @param id
   */
  load(id: number, loadAsExisting = true): Observable<CsDashboard> {
    return this.get(id).pipe(
      map((res) => {
        this._loadedDashboard.next(loadAsExisting ? res : null);
        // Now interact with clearsky and activate everything
        this.clearskyService.activateDashboard(res);

        return res;
      })
    );
  }

  /**
   * Reset the current loaded dashboard.
   */
  reset(): void {
    this._loadedDashboard.next(null);
  }

  /**
   * Start a new dashboard experience.
   */
  startNew(): void {
    this.reset();

    // Also reset all other cookies
    this.clearskyService.resetFilters();
    this.clearskyService.resetColumns();
    this.clearskyService.resetWidgets();
  }

  /**
   * Delete a dashboard
   * @param id
   */
  delete(id: number): Observable<CsDashboard[]> {
    return this.http
      .delete<CsDashboard>(`${environment.apiUrl}/dashboards/${id}`)
      .pipe(concatMap(() => this.all()));
  }

  /**
   * Share a dashboard.
   * @param id
   * @param emails
   */
  share(id: number, emails: string): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/dashboards/dashboardEmail`, {
      toEmails: emails.replace(' ', ''),
      id,
    });
  }

  /**
   * Initialize selected filters on load.
   * @private
   */
  private initCookie(key: string, defaultValue: any, callback?: Function): any {
    // Check local storage
    let value = defaultValue;

    if (isPlatformBrowser(this.platformId)) {
      const cookie = localStorage.getItem(key);
      value = cookie ? JSON.parse(cookie) : value;

      if (callback) {
        callback(value);
      }
    }

    return value;
  }

  /**
   * Update filter selection cookie.
   * @param key
   * @param payload
   * @private
   */
  private updateCookie(key: string, payload: any): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(payload));
    }
  }
}
