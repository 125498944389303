import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ClearskySubscriptionPlan,
  ClearskySubscriptionPlanName,
} from '../../../contracts/clearsky/subscription/clearsky-subscription';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { first } from 'rxjs/operators';
import { IUser, UserEnvironments } from 'app/contracts/user/iuser';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { ClearskySubscriptionService } from '../clearsky-subscription.service';
import { combineLatest } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-package-selection',
  templateUrl: './subscription-package-selection.component.html',
  styleUrls: ['./subscription-package-selection.component.scss'],
})
export class SubscriptionPackageSelectionComponent implements OnInit {
  user: IUser;
  @Input() plans: ClearskySubscriptionPlan[] = [];
  @Input() showUpgrade: boolean;
  @Output() planSelected: EventEmitter<ClearskySubscriptionPlan> =
    new EventEmitter<ClearskySubscriptionPlan>();
  activePlan: ClearskySubscriptionPlan | undefined;
  features = [
    {
      name: 'subscription.mobile-app-label',
      tooltip: 'subscription.mobile-app-helper-text',
      plans: [
        ClearskySubscriptionPlanName.basic,
        ClearskySubscriptionPlanName.pro,
      ],
    },
    {
      name: 'subscription.data-collection-label',
      tooltip: 'subscription.data-collection-helper-text',
      plans: [
        ClearskySubscriptionPlanName.basic,
        ClearskySubscriptionPlanName.pro,
      ],
    },
    {
      name: 'subscription.remote-monitoring-label',
      tooltip: 'subscription.remote-monitoring-helper-text',
      plans: [
        ClearskySubscriptionPlanName.basic,
        ClearskySubscriptionPlanName.pro,
      ],
    },
    {
      name: 'subscription.access-control-label',
      tooltip: 'subscription.access-control-helper-text',
      plans: [
        ClearskySubscriptionPlanName.basic,
        ClearskySubscriptionPlanName.pro,
      ],
    },
    {
      name: 'subscription.technical-manual-label',
      tooltip: 'subscription.technical-manual-helper-text',
      plans: [
        ClearskySubscriptionPlanName.basic,
        ClearskySubscriptionPlanName.pro,
      ],
    },
    {
      name: 'subscription.advanced-monitoring-label',
      tooltip: 'subscription.advanced-monitoring-helper-text',
      plans: [ClearskySubscriptionPlanName.pro],
    },
    {
      name: 'subscription.advanced-tracking-label',
      tooltip: 'subscription.advanced-tracking-helper-text',
      plans: [ClearskySubscriptionPlanName.pro],
    },
    {
      name: 'subscription.advanced-analysis-label',
      tooltip: 'subscription.advanced-analysis-helper-text',
      plans: [ClearskySubscriptionPlanName.pro],
    },
  ];
  planNames = ClearskySubscriptionPlanName;
  ocids: OcidItems = {};
  hidePurchaseButton = false;
  constructor(
    private localization: LocalizationService,
    private currentUserService: CurrentUserService,
    private subscription: ClearskySubscriptionService
  ) { }

  ngOnInit(): void {
    combineLatest([this.subscription.getMachineCount(), this.currentUserService.getUser()])
      .subscribe(([res, user]) => {
        this.user = user;
        if (!res.clearSky && (user.erpEnvironment === UserEnvironments.BP || user.erpEnvironment === UserEnvironments.NZ)) {
          this.hidePurchaseButton = true;
        } else {
          this.hidePurchaseButton = this.plans.some(plan => plan.itemNumber == this.planNames.pro && plan.subscribe);
        }
      });
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'subscription.mobile-app-label',
        'subscription.mobile-app-helper-text',
        'subscription.data-collection-label',
        'subscription.data-collection-helper-text',
        'subscription.remote-monitoring-label',
        'subscription.remote-monitoring-helper-text',
        'subscription.access-control-label',
        'subscription.access-control-helper-text',
        'subscription.technical-manual-label',
        'subscription.technical-manual-helper-text',
        'subscription.advanced-monitoring-label',
        'subscription.advanced-monitoring-helper-text',
        'subscription.advanced-tracking-label',
        'subscription.advanced-tracking-helper-text',
        'subscription.advanced-analysis-label',
        'subscription.advanced-analysis-helper-text',
        'clearsky.ready-label',
        'clearsky.select-plan-label',
        'clearsky.best-value-label',
        'clearsky.best-fleet-label',
        'clearsky.per-unit-annually-label',
        'clearsky.unit-ann-label',
        'clearsky.get-started-label',
        'clearsky.your-current-selection'
      ])
      .pipe(first())
      .subscribe();
  }

  /**
   * Select and activate plan.
   * @param plan
   */
  selectPlan(plan: ClearskySubscriptionPlan): void {
    this.activePlan = plan;
    this.planSelected.emit(this.activePlan);
  }
}
