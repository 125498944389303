import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BingMapsService } from '../../../service/bing-maps.service';
import { JlgLocation } from '../../../contracts/clearsky/jlg-location';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-landmark-pushpin-infobox',
  templateUrl: './landmark-pushpin-infobox.component.html',
})
export class LandmarkPushpinInfoboxComponent implements OnInit, OnChanges {
  @Input() map: Microsoft.Maps.Map;
  @Input() selectedLandmark: JlgLocation;
  @Input() selectedTargetLocation: Microsoft.Maps.Location;
  @Output() infoBoxClosed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('infoBoxTemplate') infoBoxTemplate: ElementRef;
  protected infoBox: Microsoft.Maps.Infobox;
  ocids: OcidItems = {};

  constructor(private bingMaps: BingMapsService, private localization: LocalizationService) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.infoBox = new Microsoft.Maps.Infobox(this.map.getCenter(), {
      visible: false,
    });

    // Assign the infobox to the map instance.
    this.infoBox.setMap(this.map);
    Microsoft.Maps.Events.addHandler(
      this.infoBox,
      'click',
      this.handleClickInfoBox.bind(this)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLandmark && !changes.selectedLandmark.isFirstChange()) {
      this.setInfoboxContent();
    }
  }

  private setInfoboxContent(): void {
    if (!this.selectedLandmark) {
      this.infoBox.setOptions({ visible: false });
      return;
    }

    // Set the infobox options with the metadata of the pushpin.
    this.bingMaps
      .getAddressByGeo(
        this.selectedLandmark.latitude,
        this.selectedLandmark.longitude
      )
      .subscribe(() => {
        this.infoBox.setOptions({
          location: this.selectedTargetLocation,
          htmlContent: this.infoBoxTemplate.nativeElement.innerHTML,
          visible: true,
        });

        const infoBoxWidth = this.infoBox.getWidth();
        const infoBoxHeight = this.infoBox.getHeight();
        this.infoBox.setOptions({ visible: false });

        // Now center user to pushpin location minus half the width & height of the info box
        // to avoid it going off screen.
        this.map.setView({
          center: this.infoBox.getLocation(),
          centerOffset: new Microsoft.Maps.Point(
            -infoBoxWidth * 0.5,
            infoBoxHeight * 0.5
          ),
        });

        this.infoBox.setOptions({ visible: true });
      });
  }

  /**
   * Handle event when user clicks on the infobox.
   * @param e
   * @protected
   */
  private handleClickInfoBox(e: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalEvent: { target: any };
  }): void {
    let element = e.originalEvent.target;
    const classes = [...element.classList];

    // Hacky way of doing it, but don't want to attach event listeners every time a different pin is clicked
    if (classes.includes('action-icon')) {
      element = element.parentElement;
    }

    // Close functionality
    if (element.id === 'info-box-close') {
      this.infoBox.setOptions({ visible: false });
      this.infoBoxClosed.emit();
    }

    // Get directions
    if (element.id === 'go-directions') {
      this.getDirectionsToAddress();
    }
  }

  /**
   * Get directions to landmark.
   * @protected
   */
  private getDirectionsToAddress(): void {
    this.bingMaps.getDirectionsByAddress(this.selectedLandmark.address);
  }
}
