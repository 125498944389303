import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  host: { 'class': 'report-form' }
})
export class ReportFormComponent implements OnInit, OnDestroy {
  @Input('key') key: string;
  days: Array<string>;
  parentForm: UntypedFormGroup;
  form: UntypedFormGroup = this.fb.group({
    onlyToday: this.fb.control(false),
    days: this.getDays(),
    time: this.fb.control(''),
    timeZone: this.fb.control(''),
    email: this.fb.control('')
  });
  ocids: OcidItems = {};

  // Let Angular inject the control container
  constructor(
    private controlContainer: ControlContainer,
    private localization: LocalizationService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.report-schedule-label',
        'clearsky.select-timezone-label',
        'clearsky.separate-email-label',
        'clearsky.today-label',
        'clearsky.report-sent-dashboard-label'
      ])
      .pipe(first())
      .subscribe();

    this.days = Object.keys(this.form.controls.days.value);

    this.form.get('onlyToday').valueChanges.subscribe((onlyToday) => {
      if (onlyToday) {
        this.form.controls.days.reset({});
        this.form.controls.days.disable();
      } else {
        this.form.controls.days.enable();
        this.form.controls.days.markAsTouched();
      }
    })

    // Set the parentForm property to the FormGroup that was passed to us, so that our
    // view can bind to it
    this.parentForm = <UntypedFormGroup>this.controlContainer.control;
    this.parentForm.setControl(this.key, this.form);
    this.parentForm.updateValueAndValidity();
  }

  /**
   * Creates the days FormGroup
   */
  private getDays(): UntypedFormGroup {
    return this.fb.group({
      monday: this.fb.control(false),
      tuesday: this.fb.control(false),
      wednesday: this.fb.control(false),
      thursday: this.fb.control(false),
      friday: this.fb.control(false),
      saturday: this.fb.control(false),
      sunday: this.fb.control(false)
    }, {
      validators: (formGroup: AbstractControl): ValidationErrors | null => {
        let error = null;
        // Make sure at least one checkbox is checked
        if (!this.form?.get('onlyToday')?.value && !Object.values(formGroup.value).includes(true)) {
          error = { requireCheckboxesToBeChecked: true };
        }
        if (formGroup.parent) formGroup.parent.updateValueAndValidity();
        return error;
      }
    });
  }

  ngOnDestroy(): void {
    this.parentForm.removeControl(this.key);
  }

}
