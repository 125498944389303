import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IAddress } from '../../../../../contracts/user/iaddress';
import { INavMenu } from '../../../../../contracts/user/inav-menu';
import { IUser } from '../../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { MenuService } from '../../../../../service/user/menu.service';
import { ChangeAddressComponent } from '../../../checkout/change-address/change-address.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-web2case-shipping-address-form',
  templateUrl: './web2case-shipping-address-form.component.html',
})
export class Web2CaseShippingAddressFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() shippingAddressForm: UntypedFormGroup;
  @Input() customerPickup;
  useDefaultShippingAddress = false;
  user: IUser;
  countries: INavMenu;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private userService: CurrentUserService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    // Subscribe to user data.
    this.userService.userSubject.subscribe((user: IUser) => (this.user = user));

    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('countries') : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.countries = menu;
        }
      });
    this.shippingAddressForm.addControl(
      'JobSiteName',
      this.formBuilder.control('', Validators.required)
    );
    this.shippingAddressForm.addControl(
      'JobSiteStreet',
      this.formBuilder.control('', Validators.required)
    );
    this.shippingAddressForm.addControl(
      'JobSiteZipCode',
      this.formBuilder.control('', Validators.required)
    );
    this.shippingAddressForm.addControl(
      'JobSiteCity',
      this.formBuilder.control('', Validators.required)
    );
    this.shippingAddressForm.addControl(
      'JobSiteCountry',
      this.formBuilder.control('', Validators.required)
    );
    this.shippingAddressForm.addControl(
      'JobSiteContactName',
      this.formBuilder.control('')
    );
    this.shippingAddressForm.addControl(
      'ContactPhone',
      this.formBuilder.control('', Validators.pattern(/^[0-9()+-.ext ]*$/))
    );
  }

  /**
   * Toggle the form controls if the user wants to use their default shipping address or not.
   * @param {MatCheckboxChange} event
   */
  userDefShippingChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.setAddress(this.user.shippingAddress);
    } else {
      this.shippingAddressForm.patchValue({
        JobSiteName: '',
        JobSiteStreet: '',
        JobSiteZipCode: '',
        JobSiteCity: '',
        JobSiteCountry: '',
        JobSiteContactName: '',
        ContactPhone: '',
      });
    }
  }

  /**
   * Opens the change address component to let the user change the currently selected
   * address.
   * @param {MouseEvent} event
   */
  selectOrChangeAddress(event: MouseEvent) {
    const dialogRef = this.dialog.open(ChangeAddressComponent, {
      width: '850px',
    });
    dialogRef.afterClosed().subscribe((result: IAddress) => {
      if (result) {
        this.useDefaultShippingAddress = false;
        this.setAddress(result);
      }
    });
  }

  /**
   * Sets the shipping address form given an address.
   * @param {IAddress} address
   */
  setAddress(address: IAddress) {
    this.shippingAddressForm.patchValue({
      JobSiteName: address.customerName,
      JobSiteStreet: address.address1,
      JobSiteZipCode: address.postalCode,
      JobSiteCity: address.city,
      JobSiteCountry: address.country,
      JobSiteContactName: address.contactName,
      ContactPhone: address.phoneNumber,
    });
  }
}
