import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ComponentType } from '@angular/cdk/portal';

@Injectable()
export class ConfirmationDialogsService {
  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    btnOkText = 'Ok',
    btnCancelText = 'Cancel',
    dialogComponent?: ComponentType<ConfirmationDialogComponent>,
    config?: MatDialogConfig
  ): Observable<boolean> {
    const dialogConfig = config || {};
    const dialogData = config?.data || {};
    if (dialogConfig?.data) delete dialogConfig.data;
    const dialogRef = this.dialog.open(
      dialogComponent || ConfirmationDialogComponent,
      {
        data: {
          title: title,
          content: message,
          btnOkText: btnOkText,
          btnCancelText: btnCancelText,
          ...dialogData,
        },
        ...dialogConfig,
      }
    );

    return dialogRef.afterClosed();
  }
}
