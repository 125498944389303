// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  display: flex;
  position: relative;
  width: 100%;
}
.progress-bar.default {
  background-color: var(--gray25);
  border: 2px solid var(--gray25);
}
.progress-bar.default .progress-bar-fill {
  background-color: #fff;
  color: #000;
}
.progress-bar-fill {
  text-align: center;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2dyZXNzLWJhci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSxrQkFBQTtFQUNBLFdBQUE7QUFDRjtBQUNFO0VBQ0UsK0JBQUE7RUFDQSwrQkFBQTtBQUNKO0FBQ0k7RUFDRSxzQkFBQTtFQUNBLFdBQUE7QUFDTjtBQUdFO0VBQ0Usa0JBQUE7RUFDQSxpQkFBQTtBQURKIiwiZmlsZSI6InByb2dyZXNzLWJhci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5wcm9ncmVzcy1iYXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gIHdpZHRoOiAxMDAlO1xuXG4gICYuZGVmYXVsdCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tZ3JheTI1KTtcbiAgICBib3JkZXI6IDJweCBzb2xpZCB2YXIoLS1ncmF5MjUpO1xuXG4gICAgLnByb2dyZXNzLWJhci1maWxsIHtcbiAgICAgIGJhY2tncm91bmQtY29sb3I6ICNmZmY7XG4gICAgICBjb2xvcjogIzAwMDtcbiAgICB9XG4gIH1cblxuICAmLWZpbGwge1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,+BAAA;EACA,+BAAA;AACJ;AACI;EACE,sBAAA;EACA,WAAA;AACN;AAGE;EACE,kBAAA;EACA,iBAAA;AADJ;AACA,w1BAAw1B","sourcesContent":[".progress-bar {\n  display: flex;\n  position: relative;\n  width: 100%;\n\n  &.default {\n    background-color: var(--gray25);\n    border: 2px solid var(--gray25);\n\n    .progress-bar-fill {\n      background-color: #fff;\n      color: #000;\n    }\n  }\n\n  &-fill {\n    text-align: center;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
