import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { Router } from '@angular/router';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-applied-filters',
  styleUrls: [
    '../guided-navigation/guided-navigation.component.scss',
    '../refinement-menu/refinement-menu.component.scss',
  ],
  templateUrl: './applied-filters.component.html',
})
export class AppliedFiltersComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    clearAllRefinementsLabel: string;
    refinementCrumbs: {
      removeAction: {
        navigationState: string;
        contentPath: string;
      };
      displayName: string;
      count: number;
      label: number;
      ancestors: object[];
      multiSelect: boolean;
    }[];
    removeAllAction: {
      contentPath: string;
      label: string;
      link: string;
      navigationState: string;
      siteRootPath: string;
    };
  };
  ocids: {} = {};

  constructor(
    private router: Router,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization.getOCIDs(['browse.applied-filter']).subscribe();
  }

  /**
   * Event listener on click of X.
   *
   * @param {number} index
   */
  onRemoveFilter(index: number) {
    const crumb = this.data.refinementCrumbs[index];
    return this.router.navigateByUrl(
      crumb.removeAction.contentPath + crumb.removeAction.navigationState
    );
  }

  onRemoveAll() {
    return this.router.navigateByUrl(
      this.data.removeAllAction?.contentPath +
        this.data.removeAllAction?.navigationState
    );
  }
}
