import { Component, Input, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { OcidItems } from 'app/contracts/ocid-items';
import { ScannerService } from 'app/service/scanner.service';
import { IOCIDAndValue } from '../../web2case.component';

@Component({
  selector: 'app-service-form',
  templateUrl: './service-form.component.html',
})
export class ServiceFormComponent implements OnInit {
  @Input() ocids: OcidItems;
  @Input() serviceForm: UntypedFormGroup;
  uploadRequired = false;
  serviceOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.repair-follow-up-req',
      value: 'Field repair follow up',
    },
    {
      ocid: 'web2case.repair-req',
      value: 'Repair request',
    },
    {
      ocid: 'web2case.documentation-req',
      value: 'Documentation required',
    },
    {
      ocid: 'web2case.quote-req',
      value: 'Quotation request',
    },
    {
      ocid: 'web2case.technical-diagnostic-label',
      value: 'Technical/Diagnostic',
    },
    {
      ocid: 'web2case.warranty-req',
      value: 'Warranty request',
    },
    {
      ocid: 'web2case.invoicing-req',
      value: 'Invoicing',
    },
    {
      ocid: 'web2case.others',
      value: 'Others',
    },
  ];
  typeOfDocsOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.legal',
      value: 'Legal docs',
    },
    {
      ocid: 'web2case.schematic',
      value: 'Schematics',
    },
    {
      ocid: 'web2case.others',
      value: 'Others',
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private scanner: ScannerService
  ) { }

  ngOnInit() {
    this.serviceForm.addControl(
      'Level',
      this.formBuilder.control('', Validators.required)
    );
  }

  /**
   * it just toggle the CallbackPhoneNumber field.
   */
  toggleUrgentRequest() {
    if (!this.serviceForm.get('CallbackPhoneNumber')) {
      this.serviceForm.addControl(
        'CallbackPhoneNumber',
        this.formBuilder.control('', Validators.required)
      );
    } else {
      this.serviceForm.removeControl('CallbackPhoneNumber');
    }
  }

  /**
   * Event fired when the type of service dropdown select has changed. Adds various
   * form controls and groups based on its value.
   * @param {MatSelectChange} event
   */
  typeOfServiceChanged(event: MatSelectChange) {
    this.uploadRequired = false;
    const typeOfServiceValue: string = event.value;
    if (
      typeOfServiceValue === 'Field repair follow up' ||
      typeOfServiceValue === 'Repair request' ||
      typeOfServiceValue === 'Documentation required' ||
      typeOfServiceValue === 'Quotation request' ||
      typeOfServiceValue === 'Technical/Diagnostic' ||
      typeOfServiceValue === 'Warranty request' ||
      typeOfServiceValue === 'Invoicing' ||
      typeOfServiceValue === 'Others'
    ) {
      if (!this.serviceForm.get('MachineSerial')) {
        this.serviceForm.addControl(
          'MachineSerial',
          this.formBuilder.control('')
        );
      }
      if (!this.serviceForm.get('FleetNumber')) {
        this.serviceForm.addControl(
          'FleetNumber',
          this.formBuilder.control('')
        );
      }
      if (!this.serviceForm.get('CaseDescription')) {
        this.serviceForm.addControl(
          'CaseDescription',
          this.formBuilder.control('', Validators.required)
        );
      }
    }
    if (typeOfServiceValue === 'Technical/Diagnostic') {
      if (!this.serviceForm.get('isUrgentRequest')) {
        this.serviceForm.addControl(
          'isUrgentRequest',
          this.formBuilder.control(false)
        );
      } else {
        this.serviceForm.removeControl('isUrgentRequest');
      }
    }
    if (
      typeOfServiceValue === 'Repair request' ||
      typeOfServiceValue === 'Quotation request' ||
      typeOfServiceValue === 'Technical/Diagnostic'
    ) {
      if (!this.serviceForm.get('MachineFaultDescription')) {
        this.serviceForm.addControl(
          'MachineFaultDescription',
          this.formBuilder.control('')
        );
      }
      if (!this.serviceForm.get('EngineFaultDescription')) {
        this.serviceForm.addControl(
          'EngineFaultDescription',
          this.formBuilder.control('')
        );
      }
      if (!this.serviceForm.get('jobSiteInfoForm')) {
        this.serviceForm.addControl(
          'jobSiteInfoForm',
          new UntypedFormGroup({})
        );
      }
    } else {
      this.serviceForm.removeControl('MachineFaultDescription');
      this.serviceForm.removeControl('EngineFaultDescription');
      this.serviceForm.removeControl('jobSiteInfoForm');
    }
    if (
      typeOfServiceValue === 'Technical/Diagnostic' ||
      typeOfServiceValue === 'Repair request' ||
      typeOfServiceValue === 'Quotation request'
    ) {
      if (!this.serviceForm.get('CustomerPO')) {
        this.serviceForm.addControl('CustomerPO', this.formBuilder.control(''));
      }
    } else {
      this.serviceForm.removeControl('CustomerPO');
    }
    if (
      typeOfServiceValue === 'Documentation required'
    ) {
      if (!this.serviceForm.get('Level2')) {
        this.serviceForm.addControl(
          'Level2',
          this.formBuilder.control('', Validators.required)
        );
      }
    } else {
      this.serviceForm.removeControl('Level2');
    }
    if (typeOfServiceValue === 'Warranty request') {
      if (!this.serviceForm.get('WarrantyClaimReference')) {
        this.serviceForm.addControl(
          'WarrantyClaimReference',
          this.formBuilder.control('')
        );
      }
    } else {
      this.serviceForm.removeControl('WarrantyClaimReference');
    }
    if (typeOfServiceValue === 'Invoicing') {
      if (!this.serviceForm.get('InvoiceNumber')) {
        this.serviceForm.addControl(
          'InvoiceNumber',
          this.formBuilder.control('')
        );
      }
    } else {
      this.serviceForm.removeControl('InvoiceNumber');
    }
    if (typeOfServiceValue === 'Others') {
      this.uploadRequired = true;
      if (!this.serviceForm.get('uploadForm')) {
        this.serviceForm.addControl('uploadForm', new UntypedFormGroup({}));
      }
    } else {
      this.serviceForm.removeControl('uploadForm');
    }
  }

  /**
   * Returns true if the machine serial number is required.
   * @returns {boolean}
   */
  machineSerialNumberRequired() {
    return this.serviceForm.get('Level').value !== 'Field repair follow up';
  }

  /**
   * Gets the job site form.
   * @returns {FormGroup}
   */
  get jobSiteInfoForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.serviceForm.get('jobSiteInfoForm');
  }
  /**
   * Gets the upload form.
   * @returns {FormGroup}
   */
  get uploadForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.serviceForm.get('uploadForm');
  }

  /**
   * Opens barcode scanner
   */
  async openScanner() {
    (await this.scanner.scanSerialNum('Web2Case - Service')).subscribe(
      (data?) => {
        if (data) {
          this.serviceForm.get('MachineSerial').setValue(data);
        }
      }
    );
  }
}
