import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../../../../cartridge/cartridge.class';
import { SearchService } from '../../../../search/search.service';

@Component({
  selector: 'app-product-detail-flyout-item',
  templateUrl: './product-detail-flyout-item.component.html',
  host: {
    'class': 'auto-suggest-panel'
  }
})
export class ProductDetailFlyoutItemComponent implements OnInit, CartridgeInterface {
  @Input('data') data: {
    records: object[]
  };

  constructor(private searchService: SearchService) { }

  ngOnInit() {
    this.searchService.isResultsListSearch.next(false);
  }

}
