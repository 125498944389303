import { shadeRGBColor } from '../../../shared/shade-rgb.pipe';
import { mergeDeep } from '../../../shared/deep-merge';
import * as cloneDeep from 'lodash.clonedeep';
import { WidgetColors } from '../dashboard/cs-colors.dto';

export const BaseChartConfig = {
  chart: {
    style: {
      fontSize: '15px',
      width: '100%',
    },
    className: 'tw-flex tw-items-center tw-justify-center',
  },
  colors: [
    '#2caffe',
    '#544fc5',
    '#00e272',
    '#fe6a35',
    '#6b8abc',
    '#d568fb',
    '#2ee0ca',
    '#fa4b42',
    '#feb56a',
  ],
  credits: {
    enabled: false,
  },
  legend: {
    verticalAlign: 'top',
  },
  title: {
    text: undefined,
  },
  plotOptions: {
    series: {
      dataLabels: {
        style: {
          textOutline: 'none',
          fontSize: '10px',
          color: '#79797B',
        },
      },
    },
  },
};

export const BaseSolidGaugeConfig: unknown = mergeDeep(
  cloneDeep(BaseChartConfig),
  {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'transparent',
    },
    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc',
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      stops: [
        [0.1, WidgetColors.red],
        [0.5, WidgetColors.yellow],
        [0.9, WidgetColors.green],
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
      },
      labels: {
        y: 16,
      },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
  }
);

export const BasePlotChart = {
  pie: mergeDeep(cloneDeep(BaseChartConfig), {
    chart: {
      type: 'pie',
      margin: [0, 0, 0, 0],
    },
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'middle',
      y: 30,
    },
    legend: {
      enabled: false,
      verticalAlign: 'top',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        startAngle: 90,
        endAngle: 90,
        center: ['50%', '50%'],
        size: '100%',
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.y}</b>',
          inside: true,
          distance: -50,
          style: {
            color: '#FFFFFF',
          },
        },
        showInLegend: true,
      },
    },
    series: [],
  }),
  column: {
    chart: {
      type: 'column',
      height: '100%',
    },
    title: {
      text: undefined,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>',
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.y}</b>',
          inside: true,
          distance: -50,
        },
        showInLegend: false,
      },
    },
    legend: {
      verticalAlign: 'top',
    },
    series: [],
  },
  scatter: mergeDeep(cloneDeep(BaseChartConfig), {
    chart: {
      type: 'scatter',
      zoomType: 'xy',
      resetZoomButton: {
        theme: {
          display: 'none',
        },
      },
    },
    title: {
      text: undefined,
    },
    xAxis: {
      title: {
        enabled: true,
        text: undefined,
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
    },
    legend: {
      verticalAlign: 'top',
      borderWidth: 1,
    },
    plotOptions: {
      scatter: {
        cursor: 'pointer',
        color: WidgetColors.orange,
        marker: {
          radius: 8,
        },
        states: {
          hover: {
            marker: {
              enabled: false,
            },
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.x} {point.y}</b>',
        },
      },
    },
    series: [],
  }),
};

export const ZoneColors = {
  ZONE2: [
    shadeRGBColor(WidgetColors.orange, 0.5),
    WidgetColors.orange,
  ],
  ZONE3: [
    shadeRGBColor(WidgetColors.orange, 0.6),
    shadeRGBColor(WidgetColors.orange, 0.4),
    WidgetColors.orange,
  ],
  ZONE4: [
    shadeRGBColor(WidgetColors.orange, 0.8),
    shadeRGBColor(WidgetColors.orange, 0.6),
    shadeRGBColor(WidgetColors.orange, 0.3),
    WidgetColors.orange,
  ],
  ZONE5: [
    shadeRGBColor(WidgetColors.orange, 0.8),
    shadeRGBColor(WidgetColors.orange, 0.6),
    shadeRGBColor(WidgetColors.orange, 0.4),
    shadeRGBColor(WidgetColors.orange, 0.2),
    WidgetColors.orange,
  ],
};

/**
 * Returns min and max numbers for each zone
 * @param zoneCount
 * @param min (minimum value in dataset)
 * @param max (maximum value in dataset)
 *
 * @return {rgba(): [min, max]}
 */
export const getMinMaxZoneColors = (
  zoneCount: number,
  min: number,
  max: number
): { [color: string]: number[] } => {
  // Now determine max and min values
  const diff = (max - min) / zoneCount;

  const zones = {
    '#fff': [0, 0], // Always add a 0 check
  };
  let currentNum = min;
  for (let i = 0; i < zoneCount; i++) {
    // Ceil because it keeps adding more than two decimal places and won't round up causing errors
    const newNum = Math.ceil(4 * (currentNum + diff)) / 4;

    currentNum = currentNum === 0 ? 0.1 : currentNum;
    zones[ZoneColors[`ZONE${zoneCount}`][i]] = [currentNum, newNum];

    currentNum = newNum;
  }

  return zones;
};

/**
 * Get zone scale based on zone of numbers.
 * @param zones
 */
export const getZoneScale = (zones: number[]): { [num: string]: string } => {
  const sortedNumbers: number[] = [...new Set(zones)].sort((n1, n2) => n1 - n2);

  const scale = ZoneColors[`ZONE${zones.length}`];
  let currentScaleIndex = 0;
  return sortedNumbers.reduce(
    (prev, num) => {
      if (num === 0) {
        return prev;
      }

      prev[num] = scale[currentScaleIndex];
      currentScaleIndex++;

      return prev;
    },
    {
      0: 'rgb(255, 255, 255)',
    }
  );
};

/**
 * Get tooltip html for zone charts.
 * @param zone
 * @param historicalData
 * @param detail
 */
export const getZoneTooltip = (
  zone: number,
  historicalData: object,
  detail?: { [zone: string]: string }
) => {
  const zoneName = `z${zone}`;
  const zoneNumber = historicalData[zoneName];
  let tooltipText = `<b>Zone ${zone}</b><br />`;

  // Append statistics
  if (detail) {
    tooltipText += detail[`z${zone}`].replace(',', '<br />') + '<br />';

    // Append rest of tooltip requirements
    tooltipText += `Time spent in zone (hours): ${zoneNumber}<br />`;

    // Calculate percentage of time spent in zone
    const totalZonesAmt = Object.values(historicalData).reduce(
      (prev, num) => prev + num,
      0
    );
    const percentage =
      (zoneNumber / (totalZonesAmt === 0 ? 1 : totalZonesAmt)) * 100;
    tooltipText += `Percent of time spent in zone: ${Math.round(percentage)}%`;
  }

  return tooltipText;
};

/**
 * Get fault/dtc svty color.
 * @param svty
 */
export const getDtcSvtyColor = (svty: number): string => {
  switch (svty) {
    case 1:
      return WidgetColors.green;
    case 2:
      return WidgetColors.orange;
    case 3:
      return WidgetColors.red;
    default:
      return WidgetColors.grey;
  }
};

/**
 * Get fault/dtc source color.
 * @param source
 */
export const getDtcSrcColor = (source: number): string => {
  switch (source) {
    case 1:
      return 'rgb(251, 229, 215)';
    case 2:
      return 'rgb(219, 244, 248)';
    case 3:
      return 'rgb(3, 63, 99)';
    case 4:
      return 'rgb(41, 115, 115)';
    default:
      return WidgetColors.grey;
  }
};
