// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .recommender-header {
  font-size: 2.5em;
  text-align: center;
}
::ng-deep .recommender-wrapper {
  margin: 2em 0;
}
::ng-deep .recommender-wrapper .slick-slide {
  padding: 0 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxpc3RyYWstcmVjb21tZW5kZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxnQkFBQTtFQUNBLGtCQUFBO0FBQUo7QUFFRTtFQUNFLGFBQUE7QUFBSjtBQUNJO0VBQ0UsZUFBQTtBQUNOIiwiZmlsZSI6Imxpc3RyYWstcmVjb21tZW5kZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6Om5nLWRlZXAgLnJlY29tbWVuZGVyIHtcclxuICAmLWhlYWRlciB7XHJcbiAgICBmb250LXNpemU6IDIuNWVtO1xyXG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xyXG4gIH1cclxuICAmLXdyYXBwZXIge1xyXG4gICAgbWFyZ2luOiAyZW0gMDtcclxuICAgIC5zbGljay1zbGlkZSB7XHJcbiAgICAgIHBhZGRpbmc6IDAgMTBweDtcclxuICAgIH1cclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/listrak-recommender/listrak-recommender.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AACI;EACE,eAAA;AACN;AACA,wnBAAwnB","sourcesContent":["::ng-deep .recommender {\r\n  &-header {\r\n    font-size: 2.5em;\r\n    text-align: center;\r\n  }\r\n  &-wrapper {\r\n    margin: 2em 0;\r\n    .slick-slide {\r\n      padding: 0 10px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
