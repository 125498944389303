import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
selector: 'app-wrecker-form',
templateUrl: './wrecker-form.component.html'
})
export class WreckerFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() wreckerForm: UntypedFormGroup;
  wheelGridStyleOpts = ['Manual', 'T37', 'Self Loading'];
  srsOptionOpts = ['SRS-8', 'SRS-10', 'None'];
  tunnelCompartmentOpts = ['0', '1', '2', '3'];

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.wreckerForm.addControl('wheelLiftModelNumber', this.formBuilder.control('', Validators.required));
    this.wreckerForm.addControl('wreckerBoomModelNumber', this.formBuilder.control('', Validators.required));
    this.wreckerForm.addControl('wheelGridStyle', this.formBuilder.control('', Validators.required));
    this.wreckerForm.addControl('tunnelCompartment', this.formBuilder.control('', Validators.required));
    this.wreckerForm.addControl('srsOption', this.formBuilder.control('', Validators.required));
  }

}