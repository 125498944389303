// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-slider {
  margin: 20px 0;
}
.cart-slider-title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.cart-slider .product-card {
  width: 80%;
  margin: 5px auto 0;
}
.cart-slider .product-card-content {
  text-align: center;
  font-weight: 700;
}
.cart-slider .product-card._single {
  width: 100%;
}
.cart-slider .product-card ._articleHide {
  display: none;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhcnRzLXNsaWRlci1jYXJ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsY0FBQTtBQUNGO0FBQUU7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSx5QkFBQTtFQUNBLGtCQUFBO0FBRUo7QUFBRTtFQUNFLFVBQUE7RUFDQSxrQkFBQTtBQUVKO0FBREk7RUFDRSxrQkFBQTtFQUNBLGdCQUFBO0FBR047QUFESTtFQUNFLFdBQUE7QUFHTjtBQURJO0VBQ0UsYUFBQTtBQUdOIiwiZmlsZSI6InBhcnRzLXNsaWRlci1jYXJ0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNhcnQtc2xpZGVyIHtcclxuICBtYXJnaW46IDIwcHggMDtcclxuICAmLXRpdGxlIHtcclxuICAgIGZvbnQtc2l6ZTogMjRweDtcclxuICAgIGZvbnQtd2VpZ2h0OiA3MDA7XHJcbiAgICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlO1xyXG4gICAgbWFyZ2luLWJvdHRvbTogNXB4O1xyXG4gIH1cclxuICAucHJvZHVjdC1jYXJkIHtcclxuICAgIHdpZHRoOiA4MCU7XHJcbiAgICBtYXJnaW46IDVweCBhdXRvIDA7XHJcbiAgICAmLWNvbnRlbnQge1xyXG4gICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XHJcbiAgICAgIGZvbnQtd2VpZ2h0OiA3MDA7XHJcbiAgICB9XHJcbiAgICAmLl9zaW5nbGUge1xyXG4gICAgICB3aWR0aDogMTAwJTtcclxuICAgIH1cclxuICAgIC5fYXJ0aWNsZUhpZGUge1xyXG4gICAgICBkaXNwbGF5OiBub25lO1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/parts-slider-cart/parts-slider-cart.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,UAAA;EACA,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,gBAAA;AAGN;AADI;EACE,WAAA;AAGN;AADI;EACE,aAAA;AAGN;AACA,gjCAAgjC","sourcesContent":[".cart-slider {\r\n  margin: 20px 0;\r\n  &-title {\r\n    font-size: 24px;\r\n    font-weight: 700;\r\n    text-transform: uppercase;\r\n    margin-bottom: 5px;\r\n  }\r\n  .product-card {\r\n    width: 80%;\r\n    margin: 5px auto 0;\r\n    &-content {\r\n      text-align: center;\r\n      font-weight: 700;\r\n    }\r\n    &._single {\r\n      width: 100%;\r\n    }\r\n    ._articleHide {\r\n      display: none;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
