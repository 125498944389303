import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ClearskyService } from '../../../../clearsky.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import {
  AvailableMachineWidgets,
  canMachineWidgetShow,
  filterShowableMachineWidgets,
  MachineWidgetsDisplay,
  MachineWidgetsSizing,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { WidgetGridDialogComponent } from '../../../../shared/widget-grid-dialog/widget-grid-dialog.component';
import { Machine } from '../../../../../contracts/clearsky/machine/machine.dto';
import { UntilDestroy } from '@ngneat/until-destroy';

interface DialogData {
  machine: Machine;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-widget-dialog',
  templateUrl: '../../../widgets/widget-dialog/widget-dialog.component.html',
})
export class MachineWidgetDialogComponent implements OnInit {
  selectedWidgets: string[];
  currentWidgets$: Observable<string[]> =
    this.clearskyService.currentMachineWidgets$;
  widgetsDisplay: { [key: string]: string } = MachineWidgetsDisplay;
  widgets: string[] = [];
  ocids: OcidItems = {};
  private activeWidgets: string[];

  constructor(
    public dialogRef: MatDialogRef<MachineWidgetDialogComponent>,
    protected clearskyService: ClearskyService,
    private localization: LocalizationService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.initOCIDs();
    this.widgets = filterShowableMachineWidgets(
      this.data.machine,
      AvailableMachineWidgets
    );

    // Preserve any custom order of the widgets
    this.currentWidgets$.subscribe((currentWidgets) => {
      // Only show ones that are available based on machine payload
      this.activeWidgets = filterShowableMachineWidgets(
        this.data.machine,
        currentWidgets
      );
      this.widgets = [...new Set([...this.activeWidgets, ...this.widgets])];
    });
  }

  /**
   * Open customize widget in grid format dialog.
   */
  openWidgetGridDialog(): void {
    const dialog = this.dialog.open(WidgetGridDialogComponent, {
      panelClass: ['clearsky-dialog'],
      width: '90vw',
      autoFocus: false,
      data: {
        // Append active widgets first so order is kept, but filter the ones that can't show based on machine payload
        widgets: [
          ...this.activeWidgets,
          ...Object.values(AvailableMachineWidgets).filter(
            (w) =>
              !this.activeWidgets.includes(w) &&
              canMachineWidgetShow(this.data.machine, w)
          ),
        ].map((widget) => ({
          key: widget,
          label: this.ocids[MachineWidgetsDisplay[widget]],
          active: this.activeWidgets.includes(widget),
          row: MachineWidgetsSizing[widget]
            ? MachineWidgetsSizing[widget].row
            : undefined,
          col: MachineWidgetsSizing[widget]
            ? MachineWidgetsSizing[widget].col
            : undefined,
        })),
      },
    });

    // After it's closed update the widget order
    dialog.afterClosed().subscribe((widgets) => {
      if (widgets) {
        this.clearskyService.updateMachineWidgets(widgets);
      }
    });

    // Close this dialog after it opens
    dialog.afterOpened().subscribe(() => this.dialogRef.close());
  }

  /**
   * Initialize OCIDs for dialog.
   * @private
   */
  private initOCIDs(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        ...Object.values(this.widgetsDisplay),
        'clearsky.save-label',
        'clearsky.custom-widget-label',
      ])
      .pipe(first())
      .subscribe();
  }

  /**
   * Close dialog and update selected widgets.
   */
  close(): void {
    this.dialogRef.close(this.selectedWidgets);
  }
}
