import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paramRemoval' })
/**
 * Function that removes query parameters from the url
 * @param {string} url - the value being modified.
 * @param {number} param - the parameter name
 */
export class ParamRemovalPipe implements PipeTransform {
  transform(url: string, param: string) {
    return url.indexOf(param) ? url.split('?' + param)[0] : url;
  }
}
