// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-userlist {
  margin-top: 2em;
}
.account-userlist ._responsive {
  table-layout: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFjY291bnQtbWFuYWdlLXVzZXJzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtBQUNGO0FBQUU7RUFDRSxrQkFBQTtBQUVKIiwiZmlsZSI6ImFjY291bnQtbWFuYWdlLXVzZXJzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFjY291bnQtdXNlcmxpc3Qge1xyXG4gIG1hcmdpbi10b3A6IDJlbTtcclxuICAuX3Jlc3BvbnNpdmUge1xyXG4gICAgdGFibGUtbGF5b3V0OiBhdXRvO1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/account/account-manage-users/account-manage-users.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AACA,ocAAoc","sourcesContent":[".account-userlist {\r\n  margin-top: 2em;\r\n  ._responsive {\r\n    table-layout: auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
