import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ClearskyService } from '../clearsky.service';
import { Observable, Subscription } from 'rxjs';
import {
  CsDashboard,
  WidgetsKey,
  WidgetsSizing,
} from '../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyDashboardService } from '../clearsky-dashboard.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WidgetService } from '../widget.service';
import { WidgetItemComponent } from '../machines/widgets/widget-item/widget-item.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { first, skip } from 'rxjs/operators';
import { LocalizationService } from '../../shared/localization/localization.service';
import { OcidItems } from '../../contracts/ocid-items';
import Sortable from 'sortablejs';
import { LayoutService } from 'app/service/layout.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, AfterViewInit {
  grid: ElementRef | undefined;
  subs: Subscription;
  @ViewChild('grid', { read: ElementRef }) set content(content: ElementRef) {
    if (content) {
      this.grid = content;
      this.initDrag();
    }
  }
  @ViewChildren(WidgetItemComponent, { read: ViewContainerRef })
  widgetListItems: QueryList<ViewContainerRef> | undefined;
  @ViewChild('widgetContainer') widgetContainer!: ElementRef;
  currentWidgets$: Observable<string[]> = this.clearskyService.currentWidgets$;
  widgets: string[] = [];
  newlyAddedWidgets: string[] = [];
  widgetLength = 0;
  ocids: OcidItems = {};
  inEditMode$: Observable<boolean> = this.dashboardService.inEditMode$;
  isMobile: boolean;
  moreOptions = [
    {
      text: 'Download PDF',
      click: this.onDownloadPDF.bind(this),
    },
    {
      text: 'Download PNG',
      click: this.onDownloadPDF.bind(this, false),
    },
  ];
  widgetSizing = WidgetsSizing;
  private dashboard: CsDashboard | undefined;
  private gridInstance: Sortable | undefined;
  private yOffset = -200;

  constructor(
    private widgetService: WidgetService,
    private clearskyService: ClearskyService,
    private dashboardService: ClearskyDashboardService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.subs = this.layoutService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile
    })
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.download-pdf-label'])
      .pipe(first())
      .subscribe();

    this.dashboardService.loadedDashboard$.subscribe((dashboard) => {
      this.dashboard = dashboard;
    });

    this.clearskyService.currentWidgets$
      .pipe(first())
      .subscribe((widgets) => (this.widgets = widgets));

    // Only determine on changes
    this.clearskyService.currentWidgets$.pipe(skip(1)).subscribe((widgets) => {
      // Create array of newly added widgets
      this.newlyAddedWidgets = widgets.filter(
        (widget) => !this.widgets.includes(widget)
      );
      this.widgets = widgets;

      // Does the newly added widget depend on backend data?
      if (
        Object.values(WidgetsKey).some((i) =>
          this.newlyAddedWidgets.includes(i)
        )
      ) {
        this.clearskyService.forceMachinesReload();
      }
    });

    // See when we should scroll to a specific widget in the list
    this.widgetService.scrollToWidget$.subscribe((widget) => {
      this.scrollToWidget(widget);
    });
  }

  ngAfterViewInit(): void {
    // When widgets update, scroll user where necessary
    if (!this.widgetListItems) {
      return;
    }
    this.widgetListItems.changes.subscribe(() => {
      const newWidgets = this.widgetListItems?.toArray().map((el) => {
        return el.element.nativeElement.id;
      });

      // Only start doing this logic after the initial set so we don't scroll the user to the bottom on page load
      if (this.widgets.length) {
        const added = newWidgets?.filter((w) => !this.widgets.includes(w));

        if (added?.length) {
          // Scroll user to bottom to see their new widgets
          if (isPlatformBrowser(this.platformId)) {
            let y: number;
            if (added.length > 1) {
              y = this.widgetContainer
                ? this.widgetContainer.nativeElement.getBoundingClientRect()
                  .bottom + this.yOffset
                : null;
            } else {
              y =
                (
                  this.document.querySelector(`#${added[0]}`) as HTMLElement
                ).getBoundingClientRect().top +
                window.scrollY +
                this.yOffset;
            }

            if (!y) {
              return;
            }
            window.scrollTo(0, y);
          }
        }
      }

      this.widgets = newWidgets as string[];
    });
  }

  /**
   * Download PDF of widget dashboard.
   */
  onDownloadPDF(pdf = true): void {
    if (isPlatformBrowser(this.platformId)) {
      // Don't like doing it this way, but ViewChildren is always empty :(
      const charts = this.widgets.map((widget) => {
        return {
          name: widget,
          element: this.document.querySelector(`#${widget}`) as Element,
        };
      });

      this.widgetService.downloadWidgetsPDF(
        charts,
        pdf,
        this.widgetContainer.nativeElement,
        this.isMobile,
        this.dashboard
      );
    }
  }

  /**
   * Init drag and drop functionality.
   * @private
   */
  private initDrag(): void {
    if (!this.grid) {
      return;
    }
    this.gridInstance = Sortable.create(this.grid.nativeElement, {
      handle: '.widget-drag-handler',
      forceFallback: true,
      onUpdate: this.onWidgetDragComplete.bind(this),
    });
  }

  /**
   * Update the order of the widgets.
   */
  private onWidgetDragComplete(): void {
    if (!this.gridInstance) {
      return;
    }
    this.clearskyService.updateWidgets(this.gridInstance.toArray());
  }

  /**
   * Scroll to specific widget by id.
   * @param id
   */
  scrollToWidget(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const yOffset = -200;
      const y =
        (
          this.document.querySelector(`#${id}`) as HTMLElement
        ).getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo(0, y);
    }
  }
}
