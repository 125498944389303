import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOrderDetail, IReturnSelection } from '../../../../../../contracts/orders/iorder-detail';
import { IUser } from '../../../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../../contracts/ocid-items';
import {
  IReturnCodes,
  ReturnItem,
  ReturnConfirmation,
} from 'app/contracts/orders/ireturn';
import { InvoiceInformationService } from '../../invoice-information/invoice-information.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'environments/environment';
import { OrderInquiryService } from '../../parts-order-inquiry/order-inquiry.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-rpa-form',
  styleUrls: ['./rpa-form.component.scss'],
  templateUrl: './rpa-form.component.html',
})
export class RpaFormComponent implements OnInit {
  @Input() order!: IOrderDetail;
  @Input() user!: IUser;
  @Input() initialSelection!: IReturnSelection;
  @Input() ocids!: OcidItems;
  @Input() platformBrowser!: boolean;
  @Output() cancelReturn: EventEmitter<boolean> = new EventEmitter<boolean>();
  returnSelection = new SelectionModel<ReturnItem>(true, []);
  returnReasons: IReturnCodes[];
  rpaForm: UntypedFormGroup = this.formBuilder.group({
    itemsToReturn: this.formBuilder.array([]),
    reasonCode: ['', Validators.required],
    comments: ['', Validators.required],
  });
  availableItems: ReturnItem[] = [];
  formSubmitted = false;
  returnConfirmation: ReturnConfirmation;
  totalCredit = 0;
  brand = environment.jlgStyling ? 'JLG' : 'Jerr-Dan';
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    private invoiceService: InvoiceInformationService,
    private orderService: OrderInquiryService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.order.shipments.forEach((shipment, i) => {
      shipment.details.forEach((item, j, array) => {
        // Create an array of available items to return
        if(item.returnEligible) {
          this.availableItems.push({
            displayInvoice: i === i && !j,
            lastItem: j === array.length - 1,
            invoiceDisplayURL: shipment.invoiceDisplayURL,
            invoiceNumber: shipment.invoiceNumber,
            thumbnailImageUrl: item.thumbnailImageUrl,
            itemDescription: item.itemDescription,
            itemNumber: item.itemNumber,
            // using unitPrice instead of listPrice since the latter can be 0
            // also setting it to 2 decimal places, otherwise the math calculation is off
            listPrice: +(+item.unitPrice).toFixed(2),
            quantity: +item.quantity,
            eligibleQty: +item.returnEligibleQty,
            returnQty: +item.returnEligibleQty,
            // set initial total price to 0, this will be dyanamically calculated as user updates their selection
            totalPrice: 0,
            shipmentNumber: shipment.shipmentNumber,
          });
          // add quantity field for each item
          this.itemsToReturn.push(
            this.formBuilder.group({
              quantity: [
                {
                  value : +item.returnEligibleQty,
                  disabled: item.returnEligibleQty === '1'
                },
                
                [
                  Validators.required,
                  Validators.max(+item.returnEligibleQty),
                  Validators.min(+item.returnEligibleQty ? 1 : 0),
                ],
              ],
            })
          );
        }
      });
    });

    // Populate the return selection with the initial selection
    const selectedItem = this.availableItems.find(
      (item) => item.itemNumber === this.initialSelection.itemNumber && item.invoiceNumber === this.initialSelection.invoiceNumber
    );
    this.returnSelection.select(selectedItem);
    selectedItem.totalPrice = selectedItem.listPrice * selectedItem.returnQty;
    this.totalCredit = selectedItem.totalPrice;

    // Get list of return codes
    this.orderService.getReasonCodes().subscribe((codes: IReturnCodes[]) => {
      this.returnReasons = codes;
    });
  }

  /**
   * Get the invoice image.
   */
  getInvoiceImage(displayUrl: string): void {
    // Only get the invoice image if the platform is browser.
    if (this.platformBrowser) {
      this.invoiceService.getInvoiceImage(displayUrl).subscribe(
        (indInvoice: string) => {
          if (window.navigator) {
            // If the browser is Chrome or Firefox, open the invoice in a new tab for the user to view.
            // Removed IE support when upgrading to Angular 13.
            const newTab = window.open();
            newTab.document.body.innerHTML = `<iframe src='data:application/pdf;base64,${indInvoice}' width='100%' height='100%'></iframe>`;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  /**
   * When an item is selected/deselected
   * @param item
   */
  selectItem(item: ReturnItem): void {
    this.returnSelection.toggle(item);
    // reset the item total price to $0 if it's deselected
    if(!this.returnSelection.isSelected(item)) {
      item.totalPrice = 0;
    }
    this.calculateTotal();
  }

  /**
   * When the return qty is updated
   * @param item
   * @param input
   */
  onUpdateQuantity(item: ReturnItem, input): void {
    if (input.valid) {
      // Push item with updated qty to returnSelection
      this.returnSelection.deselect(item);
      item.returnQty = input.value;
      this.returnSelection.select(item);
      this.calculateTotal();
    }
  }

  /**
   * Update the total credit based on item selected and qty entered
   */
  calculateTotal(): void {
    this.totalCredit = 0;
    this.returnSelection.selected.forEach((item: ReturnItem) => {
      item.totalPrice = item.returnQty * item.listPrice;
      this.totalCredit += item.totalPrice;
    });
  }

  /**
   * Submit the RPA form
   */
  submitReturn(): void {
    if (this.rpaForm.valid) {
      const formData = {
        comments: this.rpaForm.controls.comments.value,
        originalOrderId: this.order.orderNumber,
        reasonCode: this.rpaForm.controls.reasonCode.value,
        reasonCodeDesc: this.returnReasons.find(
          (reason) =>
            reason.reasonCode === this.rpaForm.controls.reasonCode.value
        ).description,
        returnItemDetails: this.returnSelection.selected.map((item) => {
          return {
            itemNumber: item.itemNumber,
            quantity: item.returnQty,
            shipmentNumber: item.shipmentNumber,
          };
        }),
      };
      this.orderService
        .submitReturn(formData)
        .subscribe((data: ReturnConfirmation) => {
          this.returnConfirmation = data;
          this.formSubmitted = true;
        });
    }
  }

  /**
   * Gets the item qty rows form.
   * @returns {FormArray}
   */
  get itemsToReturn(): UntypedFormArray {
    return this.rpaForm.controls.itemsToReturn as UntypedFormArray;
  }

  /**
   * Cancel the return and go back to order details
   */
  goBack(): void {
    this.cancelReturn.emit(true);
  }
}
