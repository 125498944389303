import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  generateManifest() {
    // Ignore if server is calling this function
    if (!this.isBrowser) return;

    // Locate manifest <link> tag in index.html
    const manifestSelector = document.querySelector('#manifest');
    if (manifestSelector == null) {
      console.log('Error: Failed to find "manifest" id.');
      return;
    }

    // Create constants to fill template
    const isJlg = -1 < window.location.hostname.indexOf('jlg.com');
    const name = `${isJlg ? 'JLG' : 'JerrDan'} OnlineExpress`;
    const theme_color = isJlg ? '#ff5405' : '#ee3224';
    const start_url = window.location.origin;
    const file_prefix = isJlg ? 'jlg' : 'jd';

    // Reference all needed icons
    const icons = [];
    for (const size of ['192', '384', '512', '1024']) {
      icons.push({
        src: `${start_url}/assets/icon/pwa/${file_prefix}_a_${size}.png`,
        type: 'image/png',
        sizes: `${size}x${size}`,
        purpose: 'any',
      });
      icons.push({
        src: `${start_url}/assets/icon/pwa/${file_prefix}_m_${size}.png`,
        type: 'image/png',
        sizes: `${size}x${size}`,
        purpose: 'maskable',
      });
    }

    // Construct manifest object
    const manifest = {
      name: name,
      short_name: 'OnlineExpress',
      display: 'standalone',
      start_url: start_url,
      id: start_url,
      theme_color: theme_color,
      background_color: '#ffffff',
      icons: icons,
    };

    // Convert manifest object to blob and create reference from <link> tag
    const manifestString = JSON.stringify(manifest);
    const manifestBlob = new Blob([manifestString], { type: 'application/json' });
    const manifestURL = window.URL.createObjectURL(manifestBlob);
    manifestSelector.setAttribute('href', manifestURL);
  }
}
