import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-no-content-hero-carousel-image',
  templateUrl: './no-content-hero-carousel-image.component.html'
})
export class NoContentHeroCarouselImageComponent implements OnInit {
  // Index of slide.
  @Input('index') index;
  // Index of the active slide.
  @Input('activeSlide') activeSlide;
  // the slide object.
  @Input('slide') slide;

  constructor() { }

  ngOnInit() {
  }

}
