import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PlacardService } from '../placard.service';

@UntilDestroy({ checkProperties: true }) 
@Component({
	selector: 'app-vin-form',
	templateUrl: './vin-form.component.html'
})
export class VinFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() vinForm: UntypedFormGroup;
  @Output() truckDataChanged: EventEmitter<any> = new EventEmitter<any>();
	yearInvalid = false;
	truckInfo = {};

  constructor(private formBuilder: UntypedFormBuilder,
							private placardService: PlacardService) {}

  ngOnInit() {
		this.vinForm.addControl('vin', this.formBuilder.control('', Validators.required));
    this.vinForm.addControl('year', this.formBuilder.control('', Validators.required));
  }

	validateVin() {
		this.placardService.vinNumberLookup(this.vinNum.value, this.year.value)
    	.subscribe((data: any) => {
				if (data.Results) {
					const errorCodes = data.Results.filter(item => item.Variable === 'Error Code')[0];
					errorCodes.Value.indexOf('0') === -1 && this.vinNum.setErrors({'vinNotFound': true});
					if (this.year.value) {
						errorCodes.Value.indexOf('12') > -1 && this.year.setErrors({'invalidYear': true});
						this.truckInfo = {
							vin: this.vinNum.value,
							year: this.year.value,
							truckMake: data.Results.filter(item => item.Variable === 'Make')[0].Value,
							model: data.Results.filter(item => item.Variable === 'Model')[0].Value,
							driveType: data.Results.filter(item => item.Variable === 'Drive Type')[0].Value,
							engineType: data.Results.filter(item => item.Variable === 'Fuel Type - Primary')[0].Value,
							chassisBrakeType: data.Results.filter(item => item.Variable === 'Brake System Type')[0].Value
						}
						this.truckDataChanged.emit(this.truckInfo);
					}
				} else {
					this.vinNum.setErrors({'vinNotFound': true});
				}
      }, (error) => {
				this.vinNum.setErrors({'vinNotFound': true});
      });
	}

	get vinNum() {
    return this.vinForm.get('vin');
  }

	get year() {
    return this.vinForm.get('year');
  }
}
