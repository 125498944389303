// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtc-description {
  margin: 0 0 0 1rem;
  list-style: none;
  padding: 0;
}
.dtc-map {
  height: 250px;
  width: 100%;
  margin: 2rem 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR0Yy1idWJibGUtZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0Usa0JBQUE7RUFDQSxnQkFBQTtFQUNBLFVBQUE7QUFBSjtBQUdFO0VBQ0UsYUFBQTtFQUNBLFdBQUE7RUFDQSxjQUFBO0FBREoiLCJmaWxlIjoiZHRjLWJ1YmJsZS1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZHRjIHtcbiAgJi1kZXNjcmlwdGlvbiB7XG4gICAgbWFyZ2luOiAwIDAgMCAxcmVtO1xuICAgIGxpc3Qtc3R5bGU6IG5vbmU7XG4gICAgcGFkZGluZzogMDtcbiAgfVxuXG4gICYtbWFwIHtcbiAgICBoZWlnaHQ6IDI1MHB4O1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIG1hcmdpbjogMnJlbSAwO1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/widgets/dtc-widget/dtc-bubble-dialog/dtc-bubble-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,gBAAA;EACA,UAAA;AAAJ;AAGE;EACE,aAAA;EACA,WAAA;EACA,cAAA;AADJ;AACA,olBAAolB","sourcesContent":[".dtc {\n  &-description {\n    margin: 0 0 0 1rem;\n    list-style: none;\n    padding: 0;\n  }\n\n  &-map {\n    height: 250px;\n    width: 100%;\n    margin: 2rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
