import {Component, Input, OnInit, AfterViewInit, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-rich-text-main',
  templateUrl: './rich-text-main.component.html'
})
export class RichTextMainComponent implements OnInit, AfterViewInit {
  @Input() data;
  platformBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    // After the page has loaded, determine if an anchor is set in the url (#anyAnchorId). If
    // an anchor exists, scroll to the location of the element with the anchor id.
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) {
        this.scroll(fragment);
      }
    });
  }

  /**
   * Event called when any part of the innerHtml content has been clicked.
   * @param {any} event 
   */
  clickEvent(event) {
    if (event.target.tagName.toLowerCase() === 'a' || event.target.tagName.toLowerCase() === 'img') {
      // If the element is an anchor and has an attribute 'scrollToAnchor', grab the attribute's
      // value and scroll to it. Pass 'true' to set the anchor in the url.
      if (event.target.getAttribute('scrollToAnchor')) {
        this.scroll(event.target.getAttribute('scrollToAnchor'), true);
      } else if (event.target.getAttribute('routerLink')) {
        // If the element is an anchor and has an attribute 'routerLink', grab the attribute's
        // value and navigate to it.
        this.router.navigate([event.target.getAttribute('routerLink')]);
      }
    }
  }

  /**
   * Method to scroll. Accepts the anchor id and an optional seturl parameter that determines if
   * the anchor id needs set in the browser url.
   * @param {string} anchor 
   * @param {boolean} setUrl 
   */
  scroll(anchor: string, setUrl?: boolean) {
    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      const el = document.getElementById(anchor);
      if (el) {
        // If the element exists that the anchor id is set as, scroll to it and set the location in the
        // browser search bar.
        el.scrollIntoView();
        if (setUrl) {
          this.location.go(`${this.router.url}#${anchor}`);
        }
      }
    }
  }
}
