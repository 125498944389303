export const MachineDialogConfig = {
  panelClass: [
    'mat-mdc-dialog-right',
    'xl:tw-w-[35%]',
    'tw-w-full',
    'machine-details-dialog',
    'clearsky-dialog',
  ],
  autoFocus: false,
};
