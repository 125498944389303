import {
  Injectable
} from '@angular/core';
import { ICategoryMenu } from '../../contracts/fleet-solutions/equipment-dashboard/category/icategory-menu';
import { IManagedUser } from '../../contracts/user/imanaged-user';
import { IGiftListItem } from '../../contracts/commerce/igift-list-item';
import { saveAs } from 'file-saver-es';
import xlsx from 'xlsx-populate/browser/xlsx-populate.min';

export interface IManageUserExportFormat {
  firstName: string;
  lastName: string;
  login: string;
  customerNumber: string;
  lastActivity: string;
  roles: string;
  oleUserState: string;
}

@Injectable()
export class ExcelService {

  constructor() {}

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_${new Date().toISOString().slice(0, 10)}.xlsx`;
  }

  public exportPurchaseHistory(json: any[], excelFileName: string, dateRange: string, customerNum: number, customerName: string): void {
    const headers = Object.keys(json[0]);

    xlsx.fromBlankAsync()
      .then(workbook => {
        // Modify the workbook.
        const sheet = workbook.sheet(0).name('data');
        sheet.cell(1, 1).value([
          ['90 Day Parts Purchase History'],
          [dateRange],
          ['Customer Number: ' + customerNum],
          ['Customer Name: ' + customerName],
          ['']
        ]);
        sheet.row(6).cell(1).value([headers]);
        sheet.row(7).cell(1).value(this.convertDataByHeaders(headers, json));

        headers.forEach((header, i) => {
          if (i === 6) {
            sheet.column(i + 1).hidden(true);
          } else {
            sheet.column(i + 1).width(25);
          }
        });

        // Write to file.
        workbook.outputAsync('blob').then(blob => {
          saveAs(blob, ExcelService.toExportFileName(excelFileName));
        });
      });
  }

  // Generic data export
  public exportData(headers: string[], json: any[], excelFileName: string): void {
    xlsx.fromBlankAsync()
      .then(workbook => {
        // Modify the workbook.
        const sheet = workbook.sheet(0).name('data');
        sheet.row(1).cell(1).value([headers]);
        sheet.row(2).cell(1).value(this.convertDataByHeaders(headers, json));

        // Write to file.
        workbook.outputAsync('blob').then(blob => {
          saveAs(blob, ExcelService.toExportFileName(excelFileName));
        });
      });
  }

  /**
   * Exports the manage users to an excel file.
   * @param {string[]} headers
   * @param {string[]} properties
   * @param {string} title
   * @param {IManagedUser[]} users
   * @param {{}} userRoles
   * @param {{}} userStatuses
   */
  public exportManageUsers(headers: string[], properties: string[], title: string, users: IManagedUser[], userRoles: {}, userStatuses: {}): void {
    xlsx.fromBlankAsync()
      .then(workbook => {
        // Modify the workbook.
        const sheet = workbook.sheet(0).name(title);
        sheet.row(1).cell(1).value([headers]);
        sheet.row(2).cell(1).value(users.map(user => {
          return properties.reduce((prev, curr) => {
            switch(curr) {
              case 'roles':
                prev.push(userRoles[user.login]);
                break;
              case 'oleUserState':
                prev.push(userStatuses[user.login]);
                break;
              default:
                prev.push(user[curr]);
            }

            return prev;
          }, []);
        }));

        // Write to file.
        workbook.outputAsync('blob').then(blob => {
          saveAs(blob, ExcelService.toExportFileName(title));
        });
      });
  }

  /**
   * Export the items of a shopping list for item upload on the Order Now page.
   * @param {IGiftListItem[]} listItems
   * @param {string} title
   */
  public exportShoppingList(listItems: IGiftListItem[], title: string) {
    // Determine if any special characters that cannot be in a file name are in the title.
    // If there are, replace them with the . character.
    for (let i = 0; i < title.length; i++) {
      if (title[i].match(/:|\\|\/|\?|\*|\[|\]/) !== null) {
        title = title.replace(/:|\\|\/|\?|\*|\[|\]/, '.');
      }
    }
    // If the length of the title is greater than 31 characters, trim the title.
    if (title.length > 31) {
      title = title.substring(0, 31);
    }

    xlsx.fromBlankAsync()
      .then(workbook => {
        // Modify the workbook.
        const sheet = workbook.sheet(0).name(title);
        sheet.row(1).cell(1).value([['ItemNumber', 'Quantity']]);
        sheet.row(2).cell(1).value(listItems.map(item => {
          return [item.itemNumber.toString(), item.quantityDesired.toString()];
        }));

        // Write to file.
        workbook.outputAsync('blob').then(blob => {
          saveAs(blob, ExcelService.toExportFileName(title));
        });
      });
  }

  /**
   * Method that lets you recurively loop through the category data in order
   * to properly set up the excel file.
   * @param {ICategoryMenu} menu
   * @param {number} padding
   */
  public getMenuAsArray(menu: ICategoryMenu, padding: number) {
    // Set a new data array.
    var data = [];
    // Set padding size. This indicates how much left cell padding should exist
    // to the left of nested data.
    padding = padding + 1;
    // Loop through the menu to get the data.
    menu.subMenu.forEach((subMenu: ICategoryMenu) => {
      // Set a new newData array.
      var newData = [];
      // Set the padding.
      for (let i = 0; i < padding; i++) {
        newData.push([]);
      }
      // Push the new data.
      newData.push([subMenu.text]);
      data.push(newData);
      // If a submenu exists, call this method recursively to get its data.
      if (subMenu.subMenu) {
        subMenu.subMenu.forEach((deepMenu: ICategoryMenu) =>
          this.getMenuAsArray(deepMenu, padding).forEach((newData) => data.push(newData))
        );
      }
    });
    // Return the data.
    return data;
  }

  /**
   * Convert the data into the correct format based on headers.
   * @param headers
   * @param json
   * @private
   */
  private convertDataByHeaders(headers: any[], json: any[]): any[][] {
    return json.map(item => {
      return headers.reduce((prev, curr) => {
        prev.push(item[curr]);
        return prev;
      }, []);
    })
  }
}
