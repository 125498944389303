import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { INavMenu } from '../../../../contracts/user/inav-menu';
import { MenuService } from '../../../../service/user/menu.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-start',
  styleUrls: ['./account-start.component.scss'],
  templateUrl: './account-start.component.html',
})
export class AccountStartComponent implements OnInit, OnDestroy {
  dashboardOptions: INavMenu;

  constructor(
    private menuService: MenuService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Subscribe to menu data
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? this.menuService.getMenuByUxKey('my-account-dashboard')
            : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.dashboardOptions = menu;
        }
      });
  }

  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
