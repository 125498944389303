import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LayoutService } from '../../../service/layout.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-manual-result-list',
  styleUrls: ['./manual-result-list.component.scss'],
  templateUrl: './manual-result-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: ['#inner-wrap{width:100% !important;}'],
})
export class ManualResultListComponent
  implements OnInit, AfterViewInit, CartridgeInterface {
  @Input() data: {
    '@type': string;
    firstRecNum: number;
    lastRecNum: number;
    recsPerPage: number;
    totalNumRecs: number;
    precomputedSorts: object[];
    sortOptions: object[];
    records: object[];
    originalTerms: string[];
    pagingActionTemplate: {
      navigationState: string;
      contentPath: string;
    };
    zeroSearchResultsPage: string; // url
  };
  pageSizeOptions: number[] = [12, 24, 48];
  currentPage = 0;
  currentSize = 12;
  pageEvent: PageEvent;
  dataSource: MatTableDataSource<unknown>;
  ocids: OcidItems = {};
  dataType = {
    MANUALS: 'JDCManualResultsList',
    TECHPUB: 'JLGManualResultsList'
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private localization: LocalizationService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<unknown>(this.data.records);
    this.currentPage = Math.floor(
      this.data.firstRecNum / this.data.recsPerPage
    );
    this.currentSize = this.data.recsPerPage;
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'browse.top-result',
        'browse.search-result-label-auto-correct-line1',
        'browse.search-result-label-auto-correct-line2',
      ])
      .subscribe();
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe((e) => {
      this.onNavigate(e);
    });
  }

  onNavigate(e) {
    const url = [];
    let params = Object.assign({}, this.route.snapshot.queryParams);
    // Loop through segments and build router commands
    this.route.snapshot.url.forEach((segment: UrlSegment) => {
      const path = segment.path.split('?');
      url.push(path[0]);
      if (path[1]) {
        const decodedParams = this.getQueryParamsObj(path[1]);
        params = { ...decodedParams, ...params };
      }
    });
    // No = offset starting at 0, Nrpp = results per page
    params['No'] = e.pageSize * e.pageIndex;
    params['Nrpp'] = e.pageSize;
    // Navigate user to new url to get new payload (hopefully API endpoint will be made in future as enhancement)
    this.router.navigate(url, {
      queryParams: params,
    });
    return e;
  }

  getQueryParamsObj(queryString: string): { [key: string]: string } {
    const urlSearchParams = new URLSearchParams(queryString);
    const paramsObject = {};
  
    urlSearchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
  
    return paramsObject;
  }
}
