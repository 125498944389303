import { Component, Input, OnInit } from '@angular/core';
import {
  Machine,
  MachineAccessRestrictionIcons,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { AssetAccessControlDialogComponent } from './asset-access-control-dialog/asset-access-control-dialog.component';
import { ClearskyService } from '../../../../clearsky.service';
import {
  CSLegend,
  CSRefBasic,
} from '../../../../../contracts/clearsky/clearsky-legend';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-access-control',
  templateUrl: './asset-access-control.component.html',
  styleUrls: ['./asset-access-control.component.scss'],
})
export class AssetAccessControlComponent implements OnInit {
  @Input() machine: Machine;
  widgetName = MachineWidgets.ACCESS_CONTROL;
  displayName = MachineWidgetsDisplay.accessControl;
  accessTypes: CSRefBasic[] = [];
  accessTypeIcons = MachineAccessRestrictionIcons;
  ocids: OcidItems = {};
  legend: CSLegend = {};

  constructor(
    private clearskyService: ClearskyService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.accessTypes = legend.ar ?? [];
    });
  }

  /**
   * On click change restriction icon event listener.
   */
  onChangeRestriction(status: number): void {
    this.dialog
      .open(AssetAccessControlDialogComponent, {
        panelClass: ['clearsky-dialog'],
        autoFocus: false,
        maxWidth: '500px',
        data: {
          machine: this.machine,
          status: status,
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm && this.machine.sn) {
          this.clearskyService
            .updateMachine(this.machine.sn, {
              accessRestriction: status,
            })
            .subscribe();
        }
      });
  }
}
