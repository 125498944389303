import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { HistoricSelection } from '../../../contracts/clearsky/machine/machine.dto';
import { UntypedFormControl } from '@angular/forms';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-historic-select',
  templateUrl: './historic-select.component.html',
})
export class HistoricSelectComponent implements OnInit, OnChanges {
  @Input() enableMostRecent = false;
  @Input() historicSelection?: string;
  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();
  ranges = [
    { key: HistoricSelection.HOURS_24, name: '24 Hours' },
    { key: HistoricSelection.DAYS_7, name: '7 Days' },
    { key: HistoricSelection.DAYS_14, name: '14 Days' },
    { key: HistoricSelection.DAYS_31, name: '31 Days' },
    { key: HistoricSelection.DAYS_90, name: '90 Days' },
  ];
  rangeControl: UntypedFormControl = new UntypedFormControl();
  mostRecent = HistoricSelection.MOST_RECENT;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs(['clearsky.most-label'])
      .subscribe((ocids) => (this.ocids = ocids));

    // Do we have a default value for the historic dropdown? If not, use default
    if (this.historicSelection) {
      this.rangeControl.setValue(this.historicSelection);
    } else {
      this.rangeControl.setValue(
        this.enableMostRecent
          ? HistoricSelection.MOST_RECENT
          : HistoricSelection.HOURS_24
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.historicSelection &&
      !changes.historicSelection.isFirstChange()
    ) {
      this.rangeControl.setValue(changes.historicSelection.currentValue);
    }
  }

  /**
   * Triggered on selection change from form.
   * @param value
   */
  onSelect(value: string): void {
    this.rangeControl.setValue(value);
    this.selectionChanged.emit(value);
  }
}
