import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Widgets } from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { WidgetService } from '../../../widget.service';

@Component({
  selector: 'app-widget-item',
  templateUrl: './widget-item.component.html',
  styleUrls: ['./widget-item.component.scss'],
})
export class WidgetItemComponent implements OnInit {
  @Input('type') widgetType?: string;
  @Input() col: number;
  @Input() row: number;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;
  @HostBinding('class') classes;
  widgetTypes = {
    [Widgets.FUEL_LEVEL]: import('../fuel-level/fuel-level.component').then(
      (m) => m.FuelLevelComponent
    ),
    [Widgets.IGNITION_STATUS]: import(
      '../engine-status/engine-status.component'
    ).then((m) => m.EngineStatusComponent),
    [Widgets.DEF_REMAINING]: import('../def-level/def-level.component').then(
      (m) => m.DefLevelComponent
    ),
    [Widgets.MODEL_TYPE]: import('../model-type/model-type.component').then(
      (m) => m.ModelTypeComponent
    ),
    [Widgets.MACHINE_IN_USE]: import(
      '../machine-in-use/machine-in-use.component'
    ).then((m) => m.MachineInUseComponent),
    [Widgets.FLEET_AGE]: import('../fleet-age/fleet-age.component').then(
      (m) => m.FleetAgeComponent
    ),
    [Widgets.LAST_REPORTED]: import(
      '../last-reported/last-reported.component'
    ).then((m) => m.LastReportedComponent),
    [Widgets.DTC]: import('../dtc-widget/dtc-widget.component').then(
      (m) => m.DtcWidgetComponent
    ),
    [Widgets.ALERTS]: import('../alerts-widget/alerts-widget.component').then(
      (m) => m.AlertsWidgetComponent
    ),
    [Widgets.HARDWARE_REPORTING_METRIC]: import(
      '../hardware-reporting-metric/hardware-reporting-metric.component'
    ).then((m) => m.HardwareReportingMetricComponent),
    [Widgets.ENVELOPE_PROFILE_USE]: import(
      '../envelope-profile-use/envelope-profile-use.component'
    ).then((m) => m.EnvelopeProfileUseComponent),
    [Widgets.BATTERY_MONITORING]: import(
      '../battery-monitoring/battery-monitoring.component'
    ).then((m) => m.BatteryMonitoringComponent),
    [Widgets.QUICK_LINKS]: import('../quick-links/quick-links.component').then(
      (m) => m.QuickLinksComponent
    ),
    [Widgets.FLEET_AVERAGE_AGE]: import(
      '../fleet-average-age/fleet-average-age.component'
    ).then((m) => m.FleetAverageAgeComponent),
    [Widgets.STATE_OF_CHARGE]: import(
      '../state-of-charge-widget/state-of-charge-widget.component'
    ).then((m) => m.StateOfChargeWidgetComponent),
    [Widgets.FLEET_OVERVIEW]: import(
      '../fleet-overview-widget/fleet-overview-widget.component'
    ).then((m) => m.FleetOverviewWidgetComponent),
    [Widgets.SITE_NETWORKS]: import(
      '../site-network-widget/site-network-widget.component'
    ).then((m) => m.SiteNetworkWidgetComponent),
  };

  constructor(protected widgetService: WidgetService) {}

  ngOnInit(): void {
    this.updateWidgetClass();

    if (this.widgetType) {
      this.widgetService.injectWidget(
        this.container,
        this.widgetType,
        this.widgetTypes
      );
    }
  }

  protected updateWidgetClass(): void {
    this.classes = `widget-list-item ${this.widgetType || ''}`;

    // Apply row class?
    if (this.row) {
      this.classes += ` row-${this.row}`;
    }

    // Apply col class?
    if (this.col) {
      this.classes += ` col-${this.col}`;
    }
  }
}
