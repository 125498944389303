import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../contracts/ocid-items';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as dayjs from 'dayjs';

interface Event {
  date: number;
  event: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-network-history-log',
  templateUrl: './network-history-log.component.html',
})
export class NetworkHistoryLogComponent
  implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @Input() network!: SiteNetwork;
  @Input() maxRows?: number;
  dataSource: MatTableDataSource<Event> = new MatTableDataSource<Event>([]);
  ocids: OcidItems = {};
  pageSize = 30;

  constructor(private localization: LocalizationService) { }

  ngOnInit(): void {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    // Todo: Now get the OCIDs for the columns
    this.localization.getOCIDs([
      'clearsky.time-label',
      'account.date-created-title',
      'clearsky.event-label',
    ]).subscribe(() => {
      // Gather entries
      this.setData();
    });
  }

  /**
   * On input changes.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxRows && !changes.maxRows.isFirstChange()) {
      this.setData();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Set datasource data for table.
   * @private
   */
  private setData(): void {
    this.dataSource.data = Object.entries(this.network.machines).reduce(
      (prev, [asset, data], index) => {
        if (this.maxRows && index > this.maxRows) {
          return prev;
        }

        // Push date entered
        if (data.dateEntered) {
          prev.push({
            date: data.dateEntered,
            event: `Asset ID ${asset} joined network`,
          });
        }

        // Push date exited
        if (data.dateExited) {
          prev.push({
            date: data.dateExited,
            event: `Asset ID ${asset} left network`,
          });
        }

        return prev;
      },
      [] as Event[]
    );
  }
}
