import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-persistent-slide-out',
  templateUrl: './persistent-slide-out.component.html',
  host: { 'class': 'clearsky-dialog _nomin _persistent' }
})
export class PersistentSlideOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
