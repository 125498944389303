import * as Highcharts from 'highcharts';
import { EnvelopeProfileChart } from './envelope-profile-chart';

export class EnvelopeProfileScissorChart extends EnvelopeProfileChart {
  static createInstance(): EnvelopeProfileScissorChart {
    return new this();
  }

  /**
   * Draw zones (determine placements of labels and rects).
   * @param svg
   * @param tooltip
   * @protected
   */
  protected drawZones(svg: Highcharts.Chart, tooltip?: Function): void {
    const renderer = svg.renderer;
    const width = svg.plotWidth;
    const height = svg.plotHeight - 10;
    const zoneHeight = height / this.zoneCount;
    this.chartSVG = renderer.g('chart').add();

    for (let i = 1; i <= this.zoneCount; i++) {
      // Now add tooltip label
      const zoneTooltip = tooltip
        ? this.renderTooltip(
            svg,
            i,
            {
              x: 0,
              y: zoneHeight * (this.zoneCount - i),
            },
            tooltip ? tooltip(i) : null
          )
        : null;

      // Add rectangle
      this.renderRect(svg, i, {
        x: 10,
        y: zoneHeight * (this.zoneCount - i) + 10,
        width,
        height: zoneHeight,
        tooltip: zoneTooltip,
      });

      // Add label
      this.renderLabel(svg, i, {
        x: svg.plotLeft,
        y: zoneHeight * (this.zoneCount - i) + 20,
      });
    }
  }
}
