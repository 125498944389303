export interface IRefinementBreadcrumb {
  label: string;
  count: number;
  navigationState: string;
  contentPath: string;
  properties: {
    pdpNavigationState: string;
    selected: boolean;
  };
}

export const DimensionName = {
  KNOWLEDGE: 'knowledgeArticle',
  MANUALS: 'jdcManual',
  TECHPUB: 'jlgManual',
  SOFTWARE: 'controlSoftware',
  PRODUCT: 'product',
};
