import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { AppRoutingModule } from '../app-routing.module';
import { OrderInquiryService } from '../endeca/cartridges/account/account-orders/parts-order-inquiry/order-inquiry.service';
import { CreditCardDialogComponent } from '../endeca/cartridges/account/account-payment-preferences/credit-card-dialog/credit-card-dialog.component';
import { ForgotUnlockWrapperService } from '../endeca/cartridges/forgot-unlock-wrapper/forgot-unlock-wrapper.service';
import { EndecaModule } from '../endeca/endeca.module';
import { CartService } from '../service/cart/cart.service';
import { CustomerPickupService } from '../service/cart/customer-pickup.service';
import { PaymentGroupsService } from '../service/cart/payment-groups.service';
import { ShippingGroupsService } from '../service/cart/shipping-groups.service';
import { ShippingService } from '../service/cart/shipping.service';
import { ConfirmationAlertService } from '../service/confirmation/confirmation-alert.service';
import { ContentService } from '../service/content.service';
import { ProductsApiService } from '../service/product/products-api.service';
import { CurrentUserService } from '../service/user/current-user.service';
import { MenuService } from '../service/user/menu.service';
import { UserService } from '../service/user/user.service';
import { LayoutService } from '../service/layout.service';
import { WindowRefService } from '../service/window-ref/window-ref.service';
import { ConfirmAddressComponent } from '../shared/confirm-address/confirm-address.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogsService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { AuthContainerComponent } from './header/auth-container/auth-container.component';
import { CSRDialogComponent } from './header/auth-container/csr-dialog/csr-dialog.component';
import { ProfileCompletionDialogComponent } from './header/auth-container/profile-completion-dialog/profile-completion-dialog.component';
import { OktaAuthWrapper } from '../service/auth/okta.auth.wrapper';
import { HeaderComponent } from './header/header.component';
import { ServiceComponent } from './header/service/service.component';
import { SignInDialogComponent } from './header/sign-in-dialog/sign-in-dialog.component';
import { NavMenuItemComponent } from './nav-menu-item/nav-menu-item.component';
import { CookieNoticeComponent } from './footer/cookie-notice/cookie-notice.component';
import { LangComponent } from './lang/lang.component';
import { LangSelectorComponent } from './lang/lang-selector/lang-selector.component';
import { CartPreviewComponent } from './header/cart-preview/cart-preview.component';
import { ListrakService } from '../service/gtm/listrak.service';
import { EcommerceService } from '../service/gtm/ecommerce-service';
import { NavigationComponent } from './header/navigation/navigation.component';
import { FooterLinksComponent } from './footer/footer-links/footer-links.component';
import { NavigationItemComponent } from './header/navigation/navigation-item/navigation-item.component';
import { EndingSoonComponent } from './ending-soon/ending-soon.component';
import { HamburgerComponent } from './header/hamburger/hamburger.component';
import { KnowledgeArticleService } from '../service/knowledge-article.service';
import { ScannerComponent } from './scanner/scanner.component';
import { PendingAccountDialogComponent } from './header/auth-container/pending-account-dialog/pending-account-dialog.component';
import { SuspendedAccountDialogComponent } from './header/auth-container/suspended-account-dialog/suspended-account-dialog.component';

@NgModule({
  declarations: [
    AuthContainerComponent,
    CartPreviewComponent,
    ConfirmAddressComponent,
    ConfirmationDialogComponent,
    CreditCardDialogComponent,
    CSRDialogComponent,
    ProfileCompletionDialogComponent,
    PendingAccountDialogComponent,
    SuspendedAccountDialogComponent,
    FooterComponent,
    HeaderComponent,
    LangComponent,
    LangSelectorComponent,
    NavMenuItemComponent,
    ServiceComponent,
    SignInDialogComponent,
    CookieNoticeComponent,
    NavigationComponent,
    FooterLinksComponent,
    NavigationItemComponent,
    EndingSoonComponent,
    HamburgerComponent,
    ScannerComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    EndecaModule,
    HttpClientModule,
    SharedModule,
  ],
  exports: [AppRoutingModule, EndecaModule, FooterComponent, HeaderComponent],
  providers: [
    CartService,
    ConfirmationAlertService,
    ConfirmationDialogsService,
    ContentService,
    CurrentUserService,
    CustomerPickupService,
    ForgotUnlockWrapperService,
    KnowledgeArticleService,
    LayoutService,
    MenuService,
    OAuthService,
    OktaAuthWrapper,
    OrderInquiryService,
    PaymentGroupsService,
    ProductsApiService,
    ShippingGroupsService,
    ShippingService,
    UrlHelperService,
    UserService,
    WindowRefService,
    ListrakService,
    EcommerceService,
  ],
})
export class CoreModule {}
