import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-base-breadcrumbs',
  styleUrls: ['./base-breadcrumbs.component.scss'],
  templateUrl: './base-breadcrumbs.component.html',
})
export class BaseBreadcrumbsComponent {
  @Input() breadcrumbs!: {
    label: string;
    url: string;
  };

  constructor() {}
}
