import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'passwordMask' })
export class PasswordPipe implements PipeTransform {
  transform(value) {
    let mask = '';
    for (let i = 0; i < value.length; i++) {
      mask += '\u25CF';
    }
    return mask;
  }
}
