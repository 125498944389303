import { Component, Input, OnInit } from '@angular/core';
import { Machine } from '../../../../contracts/clearsky/machine/machine.dto';
import { Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { BingMapsService } from '../../../../service/bing-maps.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-map-address',
  templateUrl: './machine-map-address.component.html',
})
export class MachineMapAddressComponent implements OnInit {
  @Input() machine: Machine;
  @Input() formattedAddress?: Microsoft.Maps.IAddress;
  @Input() iconColor?: string = 'gray';
  @Input() showDirections?: boolean = true;
  address$: Observable<string>;
  ocids: OcidItems = {};

  constructor(
    private bingMaps: BingMapsService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['global.get-directions-label', 'global.no-data-label'])
      .pipe(first())
      .subscribe();

    this.address$ = of(this.formattedAddress).pipe(
      mergeMap((address) => {
        if (!address && this.machine.loc) {
          return this.bingMaps.getAddressByGeo(
            this.machine.loc.lat,
            this.machine.loc.lng
          );
        }

        return of(address);
      }),
      map((address) => {
        return address ? address.formattedAddress : '';
      })
    );
  }

  /**
   * Get directions for machine.
   */
  onGetDirections(): void {
    this.bingMaps.getDirections(this.machine.loc.lat, this.machine.loc.lng);
  }
}
