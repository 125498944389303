import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  CSFieldType,
  Machine,
  MachineFieldDisplay,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ClearskyService } from '../../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { KnowledgeArticle } from '../../../../../contracts/atg/knowledge-article.dto';
import { KnowledgeArticleService } from '../../../../../service/knowledge-article.service';
import { isPlatformBrowser } from '@angular/common';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import {
  CSRefBasic,
  CSRefDTC,
} from '../../../../../contracts/clearsky/clearsky-legend';
import { CSFilter } from '../../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsAggCountData } from '../../../../../contracts/clearsky/agg-data';
import { getDtcSrcColor } from '../../../../../contracts/clearsky/machine/machine.chart.config';
import { BingMapsService } from '../../../../../service/bing-maps.service';
import { CsRequestKeys } from '../../../../../contracts/clearsky/cs-machines-request';

interface DialogData {
  dtc: CSRefDTC;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dtc-bubble-dialog',
  templateUrl: './dtc-bubble-dialog.component.html',
  styleUrls: ['./dtc-bubble-dialog.component.scss'],
})
export class DtcBubbleDialogComponent implements OnInit {
  @ViewChild('map') map: ElementRef;
  bingMap: Microsoft.Maps.Map;
  dtcColor: string;
  machinesWithDtc: Machine[] = [];
  knowledgeArticles$: Observable<KnowledgeArticle[]> =
    this.kaService.getKnowledgeArticles(this.data.dtc.id.toString());
  displayedColumns = ['sn', 'eid', 'model'];
  fieldType = CSFieldType;
  ocids: OcidItems = {};
  aggData: CsAggCountData | undefined;
  svty: CSRefBasic | undefined;
  private subs: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PLATFORM_ID) private platformId: string,
    private clearskyService: ClearskyService,
    private kaService: KnowledgeArticleService,
    public dialogRef: MatDialogRef<DtcBubbleDialogComponent>,
    private router: Router,
    private localization: LocalizationService,
    private bingMaps: BingMapsService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(
        CsRequestKeys.dtcBubbleSO,
        {
          filters: [
            {
              key: CSFilter.dtcId.key,
              values: [this.data.dtc.id],
            },
          ],
        },
        false
      ),
      this.localization.getOCIDs([
        ...Object.values(MachineFieldDisplay),
        'clearsky.dtc-label',
        'clearsky.code-identifier-label',
        'clearsky.code-description-label',
        'clearsky.code-severity-label',
        'clearsky.number-w-label',
        'clearsky.machines-fault-label',
        'clearsky.knowledge-label',
        'clearsky.there-knowledge-this-label',
      ]),
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.legendRef$,
    ]).subscribe(([widget, ocids, page, legend]) => {
      this.ocids = ocids;
      this.machinesWithDtc = (widget && (widget.machines as Machine[])) || [];
      this.aggData = (
        (page && page.aggregations && page.aggregations.dtcs) ||
        []
      ).find((d) => d.id === this.data.dtc.id);
      this.svty = (legend.svtyCategory || []).find(
        (s) => s.id === this.data.dtc.svty
      );

      this.bingMaps.initialize().subscribe(() => {
        this.loadMap();
      });
    });

    this.dtcColor = getDtcSrcColor(this.data.dtc.src);
  }

  /**
   * Load the map and subscribe to machine updates to draw pins.
   */
  loadMap() {
    this.bingMap = new Microsoft.Maps.Map(this.map.nativeElement, {
      showMapTypeSelector: false,
    });

    // Subscribe to filtered machines with this DTC code
    const locs = this.machinesWithDtc
      .filter((machine) => machine.loc)
      .map((machine) => {
        if (!machine.loc) {
          return;
        }

        const loc = new Microsoft.Maps.Location(
          machine.loc.lat,
          machine.loc.lng
        );
        const pushpin = new Microsoft.Maps.Pushpin(loc, {
          title: machine.eid,
          color: Microsoft.Maps.Color.fromHex('#696557'),
        });

        // Store some metadata with the pushpin so we can show info box
        pushpin.metadata = {
          machine: machine,
        };

        // Now insert the layer on the map
        this.bingMap.entities.push(pushpin);

        // Add a click event handler to the pushpin.
        Microsoft.Maps.Events.addHandler(pushpin, 'click', () =>
          this.onMachineClicked(machine)
        );

        return loc;
      });

    // Now center user on pins
    if (locs.length) {
      const rect = Microsoft.Maps.LocationRect.fromLocations(locs);
      this.bingMap.setView({
        bounds: rect,
        padding: 50,
      });
    }
  }

  /**
   * Navigate to asset view.
   * @param machine
   */
  onMachineClicked(machine: Machine): void {
    this.dialogRef.close();
    this.router.navigate(['/clearsky/machine', machine.sn]);
  }

  /**
   * Navigate to knowledge article.
   * @param article
   */
  goToArticle(article: KnowledgeArticle): void {
    if (isPlatformBrowser(this.platformId)) {
      window.open(article.url, '_blank');
    }
  }
}
