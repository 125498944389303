// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.engine-status {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.engine-status._on {
  color: #087D27;
}
.engine-status._off {
  color: #ef5350;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hY2hpbmUtZW5naW5lLXN0YXR1cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGFBQUE7RUFDQSxpQkFBQTtFQUNBLG1CQUFBO0VBQ0EsMkJBQUE7RUFDQSx5QkFBQTtFQUNBLGtCQUFBO0FBREY7QUFHRTtFQUNFLGNBQUE7QUFESjtBQUdFO0VBQ0UsY0FBQTtBQURKIiwiZmlsZSI6Im1hY2hpbmUtZW5naW5lLXN0YXR1cy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgJy4uLy4uLy4uLy4uLy4uL25vZGVfbW9kdWxlcy9AYW5ndWxhci9tYXRlcmlhbCcgYXMgbWF0O1xuXG4uZW5naW5lLXN0YXR1cyB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtd3JhcDogbm93cmFwO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gIHRleHQtdHJhbnNmb3JtOiB1cHBlcmNhc2U7XG4gIG1hcmdpbi1ib3R0b206IDVweDtcblxuICAmLl9vbiB7XG4gICAgY29sb3I6ICMwODdEMjc7XG4gIH1cbiAgJi5fb2ZmIHtcbiAgICBjb2xvcjogbWF0LmdldC1jb2xvci1mcm9tLXBhbGV0dGUobWF0LiRyZWQtcGFsZXR0ZSwgNDAwKTtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/machine-engine-status/machine-engine-status.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,2BAAA;EACA,yBAAA;EACA,kBAAA;AADF;AAGE;EACE,cAAA;AADJ;AAGE;EACE,cAAA;AADJ;AACA,o3BAAo3B","sourcesContent":["@use '../../../../../node_modules/@angular/material' as mat;\n\n.engine-status {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-start;\n  text-transform: uppercase;\n  margin-bottom: 5px;\n\n  &._on {\n    color: #087D27;\n  }\n  &._off {\n    color: mat.get-color-from-palette(mat.$red-palette, 400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
