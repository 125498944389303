import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-blocks-item-header',
  templateUrl: './grid-blocks-item-header.component.html',
  styleUrls: ['./grid-blocks-item-header.component.scss'],
  host: {'class': 'grid-blocks-item-header'}
})
export class GridBlocksItemHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
