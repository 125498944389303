import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IEquipmentModel, RequestTypeMode } from '../models/request-mode.enum';
import { PlateIdReplacementService } from '../plate-id-replacement.service';
import { ScannerService } from 'app/service/scanner.service';
import { ConfirmationDialogsService } from 'app/shared/confirmation-dialog/confirmation-dialog.service';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-request-type-form',
  templateUrl: './request-type-form.component.html'
})
export class RequestTypeFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() requestTypeForm: UntypedFormGroup;
  @Output() requestTypeFormSubmittedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestTypeChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() equipmentChanged: EventEmitter<IEquipmentModel> = new EventEmitter<IEquipmentModel>();
  requestTypeFormValid: boolean = false;
  requestMode = RequestTypeMode;
  serialSearch = '';
  attachmentPart = '';
  attachmentSerialNumber = '';
  manufacturedDateConst = +new Date('2023/12/31');
  invalidNumber: boolean;
  equipment: IEquipmentModel;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private plateIdReplacementService: PlateIdReplacementService,
    private scanner: ScannerService,
    private confirmationService: ConfirmationDialogsService
  ) { }

  ngOnInit() {
    this.requestTypeForm.addControl('recordType', this.formBuilder.control('', Validators.required));
  }

  onSerialSearchEnter(event: KeyboardEvent) {
    event.preventDefault();
    this.onSerialSearch();
  }

  onSerialSearch() {
    this.plateIdReplacementService
      .serialNumberLookup(this.serialSearch)
      .subscribe({
        next: (data) => {
          this.invalidNumber = !data['modelNumber'].length
          this.equipment = {
            serial: this.serialSearch,
            model: data['modelNumber'],
            type: data['equipmentType'].toLowerCase(),
            desc: data['equipmentTypeDescription'],
          };
          this.equipmentChanged.emit(this.equipment);
        },
        error: () => {
          this.invalidNumber = true;
        },
      });
  }

  /**
   * Opens barcode scanner
   */
  async openScanner() {
    (await this.scanner.scanSerialNum('Placard Request')).subscribe((data?) => {
      if (data) {
        this.serialSearch = data;
      }
    });
  }

  /**
   * Event fired when the request type button toggle changes. Adds various
   * form controls and groups based on its value.
   * @param {MatButtonToggleChange} event
   */
  requestTypeChanged(event: MatButtonToggleChange) {
    this.requestTypeChangedEvent.emit(event.value);
    if (event.value === RequestTypeMode.ATTACHMENT) {
      this.requestTypeForm.addControl('attachmentPartNumber', this.formBuilder.control('', [Validators.required]));
      this.requestTypeForm.addControl('attachmentSerialNumber', this.formBuilder.control(''));
    } else {
      this.requestTypeForm.removeControl('attachmentPartNumber');
      this.requestTypeForm.removeControl('attachmentSerialNumber');
    }
  }
  /**
   * Event fired when the provide assistance dropdown select has changed. Adds various
   * form controls and groups based on its value.
   * @param {MatSelectChange} event
   */
  placardTypeChanged(event: MatSelectChange) {
    if (event.value === 'Other' && !this.requestTypeForm.get('explain')) {
      this.requestTypeForm.addControl('explain', this.formBuilder.control('', Validators.required));
    } else {
      this.requestTypeForm.removeControl('explain');
    }
  }
  /**
   * Emits the FormGroupDirective if the form is valid.
   */
  submitrequestTypeForm() {
    // if (this.requestTypeForm.valid) {
    this.requestTypeFormSubmittedEvent.emit();
    // }
  }

  /**
 * Open the serial number tips dialog
 */
  openSNTip() {
    this.confirmationService.confirm(
      '',
      this.ocids['id-request.how-to-find-sn'],
      this.ocids['global.close'],
      ''
    );
  }
}