import { Machine } from './machine/machine.dto';

export interface SiteNetwork {
  networkCreationTime: number;
  bounds: {
    elevation: number;
    latitude: number;
    longitude: number;
  }[];
  siteActive: boolean;
  siteNetworkName: string;
  siteNetworkID: string;
  snID?: string;
  centerPoint: {
    latitude: number;
    longitude: number;
  };
  machines: {
    [serialNumber: string]: {
      dateEntered: number;
      dateExited: number;
      networkStatus: boolean;
    };
  };
  machinesData: Machine[];
  machineCount: number;
}

export const SiteNetworkFieldDisplay: { [field: string]: string } = {
  siteNetworkName: 'Network Name',
  siteActive: 'Site Active',
  machineCount: 'Machines Onsite',
  networkCreationTime: 'Created On',
};

export interface SiteNetworkUpdate {
  siteNetworkName?: string;
}

export interface LoggedSiteNetworkChange {
  addToNetwork: boolean;
  sn: string;
  snID: string;
}
