// For the new code (legend rarely changes FYI)
export const MxMGroupImg: { [key: number | string]: string } = {
  0: 'https://www.jlg.com/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/70235-source',
  1: 'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/jlg/current-materials-no-password/products/americas - ansi/engine-powered-boom-lifts/engine-powered-boom-family-materials/images/engine-powered-booms.png',
  2: 'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/jlg/current-materials-no-password/products/americas%20-%20ansi/electric-powered-scissor-lifts/electric-powered-scissor-family-materials/images/scissor-lifts.png',
  3: '/assets/img/clearsky/Telehandlers.png',
  4: 'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/JLG/Current-Materials-No-Password/Products/Americas---ANSI/Vertical-Lifts-and-Stock-Pickers/Vertical-Lift-and-Stock-Picker-Family-Materials/Images/1230ES-Gallery-Silo.png?rev=5ea579289dfd4fad984dee0ddc676228',
  5: '/assets/img/clearsky/ServiceVehicle.jpg',
  scissorLifts:
    'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/jlg/current-materials-no-password/products/americas%20-%20ansi/electric-powered-scissor-lifts/electric-powered-scissor-family-materials/images/scissor-lifts.png',
  boomLifts:
    'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/jlg/current-materials-no-password/products/americas - ansi/engine-powered-boom-lifts/engine-powered-boom-family-materials/images/engine-powered-booms.png',
  telehandlers: '/assets/img/clearsky/Telehandlers.png',
  lowLvlAcs:
    'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/JLG/Current-Materials-No-Password/Products/Americas---ANSI/Low-Level-Access/Family-Materials/eco-426x526.png?rev=cb786fc132e54c0da07293bf23a9efaf',
  vertLifts:
    'https://cdn-jlg.scdn5.secure.raxcdn.com/-/media/JLG/Current-Materials-No-Password/Products/Americas---ANSI/Vertical-Lifts-and-Stock-Pickers/Vertical-Lift-and-Stock-Picker-Family-Materials/Images/1230ES-Gallery-Silo.png?rev=5ea579289dfd4fad984dee0ddc676228',
  svcVeh: '/assets/img/clearsky/ServiceVehicle.jpg',
};

export const MxPlaceholderImg: string =
  'https://www.jlg.com/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/70235-source';

export interface MachineModelNumberImages {
  modelNumber: string;
  imageUrl: string;
}
