import { Component, Input, OnInit } from '@angular/core';
import {
  getQuantifiedColor,
  Machine,
  MachineChargeStatus,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from '../../../../contracts/ocid-items';
import * as dayjs from 'dayjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from 'app/clearsky/clearsky.service';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-battery-monitoring-with-blocks',
  templateUrl: './asset-battery-monitoring-with-blocks.component.html',
  styleUrls: ['./asset-battery-monitoring-with-blocks.component.scss'],
})
export class AssetBatteryMonitoringWithBlocksComponent implements OnInit {
  @Input() machine: Machine;
  @Input() historic: string;
  lastRefillDate: string;
  elapsedChargeDate: string;
  stateOfChargeIconColor: string;
  batteryVoltageIconColor: string;
  waterIconColor: string;
  ocids: OcidItems = {};
  legend: CSLegend;

  constructor(
    private localization: LocalizationService,
    private clearskyService: ClearskyService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.water-label',
        'clearsky.last-refill-label',
        'clearsky.elapsed-charge time-label',
      ])
      .pipe(first())
      .subscribe();
    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );
    this.lastRefillDate = dayjs(this.machine.lwr).format('MM/DD/YYYY');

    this.stateOfChargeIconColor = getQuantifiedColor(this.machine.socQ);
    this.batteryVoltageIconColor = getQuantifiedColor(this.machine.batVQ);
    this.waterIconColor = getQuantifiedColor(this.machine.batH2OPQ);

    const now = dayjs();
    if (this.machine.csd) {
      const chargeStartDate = dayjs(this.machine.csd);
      const elapsedHours = this.machine.ect / 3600; // hours
      const elapsedMinutes = (elapsedHours % 1) * 60;
      this.elapsedChargeDate =
        this.machine.cstat === MachineChargeStatus.CHRGING
          ? `${now.diff(chargeStartDate, 'hours')} hours ${now.diff(
              chargeStartDate,
              'minutes'
            )} minutes`
          : `${Math.floor(elapsedHours)} hours ${Math.round(
              elapsedMinutes
            )} minutes`;
    }
  }
}
