// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clearsky-breadcrumbs {
  position: fixed;
  background-color: white;
  z-index: 102;
  width: 100%;
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
  height: 45px;
  top: calc(var(--ole-header-height) + var(--proxy-header-height));
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNsZWFyc2t5LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZUFBQTtFQUNBLHVCQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7RUFDQSwyQ0FBQTtFQUNBLFlBQUE7RUFDQSxnRUFBQTtBQUNKIiwiZmlsZSI6ImNsZWFyc2t5LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsZWFyc2t5LWJyZWFkY3J1bWJzIHtcbiAgICBwb3NpdGlvbjogZml4ZWQ7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogd2hpdGU7XG4gICAgei1pbmRleDogMTAyO1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGJveC1zaGFkb3c6IDAgMCA1cHggM3B4IHJnYmEoMCwgMCwgMCwgMC4wNSk7XG4gICAgaGVpZ2h0OiA0NXB4O1xuICAgIHRvcDogY2FsYygje3ZhcigtLW9sZS1oZWFkZXItaGVpZ2h0KX0gKyAje3ZhcigtLXByb3h5LWhlYWRlci1oZWlnaHQpfSk7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/clearsky.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,2CAAA;EACA,YAAA;EACA,gEAAA;AACJ;AACA,grBAAgrB","sourcesContent":[".clearsky-breadcrumbs {\n    position: fixed;\n    background-color: white;\n    z-index: 102;\n    width: 100%;\n    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);\n    height: 45px;\n    top: calc(#{var(--ole-header-height)} + #{var(--proxy-header-height)});\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
