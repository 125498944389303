import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements OnInit {
  public title: string;
  public content: string;
  public btnOkText?: string;
  public btnCancelText?: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  /**
   * When user clicks confirm button.
   */
  onConfirm() {
    this.dialogRef.close(true);
  }

  /**
   * When user clicks cancel button.
   */
  onClose() {
    this.dialogRef.close();
  }
}
