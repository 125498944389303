import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChartLegendOption} from '../contracts/ChartLegendOption';

@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.scss'],
})
export class ChartLegendComponent {
  @Input() legacy = false;
  @Input() orientation = 'horizontal';
  @Input() options?: ChartLegendOption[] = [];
  @Input() enableClick? = true;
  @Output() onClick?: EventEmitter<any> = new EventEmitter<any>();
}
