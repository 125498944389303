import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRemoveItemDto } from '../../../../../contracts/commerce/dto/iremove-item.dto';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { CartService } from '../../../../../service/cart/cart.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-core-return-warning',
  templateUrl: './core-return-warning.component.html',
})
export class CoreReturnWarningComponent implements OnInit {
  @Input() item: ICommerceItemWithCart;
  @Output() confirmed: EventEmitter<ICommerceItemWithCart> =
    new EventEmitter<ICommerceItemWithCart>();
  @Output() remove: EventEmitter<IRemoveItemDto> =
    new EventEmitter<IRemoveItemDto>();
  public ocids = {};
  public confirmCheckbox = false;
  public showRequired = false;

  constructor(
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  /**
   * Event listener on confirm button.
   */
  public onConfirm() {
    // Did they confirm?
    if (this.confirmCheckbox) {
      // Update item
      this.notificationService.reset();
      this.cartService.confirmCoreReturn(this.item).subscribe(
        (item: ICommerceItemWithCart) => {
          this.showRequired = false;
          this.confirmed.emit(item);
        },
        (err) => {
          this.notificationService.addError(err.error?.title);
        }
      );
    } else {
      this.showRequired = true;
    }
  }

  /**
   * Event listener for remove button.
   */
  public onRemove() {
    this.notificationService.reset();
    this.remove.emit({
      item: this.item,
      showDialog: true,
    });
  }
}
