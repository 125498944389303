import { Component, Input, OnInit } from '@angular/core';
import { Machine } from '../../../../../contracts/clearsky/machine/machine.dto';
import { MachinePersonalitiesDialogComponent } from '../../machine-personalities-dialog/machine-personalities-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MachineSetupDialogComponent } from '../../machine-setup-dialog/machine-setup-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ClearskyService } from '../../../../clearsky.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { CSLegend } from '../../../../../contracts/clearsky/clearsky-legend';
import { MxPlaceholderImg } from '../../../../../contracts/clearsky/machine/machine.images';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-general-machine-info',
  templateUrl: './general-machine-info.component.html',
  styleUrls: ['general-machine-info.component.scss'],
})
export class GeneralMachineInfoComponent implements OnInit {
  @Input() machine: Machine;
  eidPend$: Observable<boolean>;
  placeholderImg = MxPlaceholderImg;
  widgetName = MachineWidgets.GENERAL_MACHINE_INFO;
  displayName = MachineWidgetsDisplay.machineInfo;
  inEditMode = false;
  assetIdControl: UntypedFormControl = new UntypedFormControl(
    null,
    Validators.required
  );
  ocids: OcidItems = {};
  legend?: CSLegend;

  constructor(
    private snackBar: MatSnackBar,
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private dialog: MatDialog,
    private gAService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.eidPend$ = this.clearskyService.isPropUpdPend(this.machine, 'eid');

    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.year-label',
        'clearsky.make-label',
        'clearsky.pvc-label',
        'clearsky.operating-label',
        'clearsky.personalities-label',
        'web2case.level1.machine-setup',
        'clearsky.asset-id-edit-warning',
        'clearsky.software-part-label',
        'clearsky.software-version-label',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));
    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );

    this.assetIdControl.setValue(this.machine.eid);
  }

  /**
   * On update machine.
   */
  onSave(): void {
    this.gAService.eventEmitter(
      'clearsky_click',
      'asset_view',
      'machine_info_update',
      'serial_number',
      this.machine.sn
    );
    this.clearskyService
      .updateMachine(this.machine.sn, {
        eid: this.assetIdControl.value,
      })
      .subscribe({
        next: () => {
          this.machine.eid = this.assetIdControl.value;
          this.snackBar.open(this.ocids['clearsky.asset-id-edit-warning'], null, {
            duration: 3000,
            verticalPosition: 'top',
          });
          this.inEditMode = false;
        },
        error: (err) => {
          this.snackBar.open(err, this.ocids['global.close'], {
            verticalPosition: 'top',
          });
        },
      });
  }

  /**
   * On click for machine setup link.
   */
  onOpenMachineSetup(): void {
    this.dialog.open(MachineSetupDialogComponent, {
      panelClass: ['mat-dialog-right', '_35', 'clearsky-dialog'],
      data: {
        machine: this.machine,
      },
    });
  }

  /**
   * On click for machine personalities link.
   */
  onOpenPersonalities(): void {
    this.dialog.open(MachinePersonalitiesDialogComponent, {
      panelClass: ['mat-dialog-right', '_35', 'clearsky-dialog'],
      data: {
        machine: this.machine,
      },
    });
  }
}
