import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { first } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { WindowRefService } from '../../../../service/window-ref/window-ref.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-manual-result-list-item]',
  styleUrls: ['./manual-result-list-item.component.scss'],
  templateUrl: './manual-result-list-item.component.html',
})
export class ManualResultListItemComponent implements OnInit {
  @Input() item!: {
    records: object[];
    numRecords: number;
    detailsAction: {
      contentPath: string;
      recordState: string;
    };
    attributes: {
      'jdcManual.creationDate': string[];
      'jdcManual.damUrl': string[];
      'jdcManual.description': string[];
      'jdcManual.keywords': string[];
      'jdcManual.manualType': string[];
      'jdcManual.modelNumber': string[];
      'jdcManual.modelSeries': string[];
      'jdcManual.modelType': string[];
      'jdcManual.revision': string[];
      'jdcManual.snRange': string[];
      'jdcManual.title': string[];
      'jdcManual.techPubPartNumber': string[];
      'record.id': string[];
    };
  };
  ocids: OcidItems = {};
  linkCopied = false;
  platformBrowser = isPlatformBrowser(this.platformId);
  manualTitle = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'control-software.software-part-number-label',
        'control-software.serial-num-range',
      ])
      .pipe(first())
      .subscribe();

    this.manualTitle += this.item.attributes['jdcManual.manualType']
      ? `${this.item.attributes['jdcManual.manualType'][0]} - `
      : '';
    this.manualTitle += this.item.attributes['jdcManual.title']
      ? this.item.attributes['jdcManual.title'][0]
      : '';
    this.manualTitle += this.item.attributes['jdcManual.techPubPartNumber']
      ? ` ${this.item.attributes['jdcManual.techPubPartNumber'][0]}`
      : '';
    this.manualTitle += this.item.attributes['jdcManual.revision']
      ? ` - Rev: ${this.item.attributes['jdcManual.revision'][0]}`
      : '';
    this.manualTitle += this.item.attributes['jdcManual.creationDate']
      ? ` (${this.item.attributes['jdcManual.creationDate'][0]})`
      : '';
  }
}
