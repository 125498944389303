import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from 'app/clearsky/clearsky.service';
import { CsRequestKeys } from 'app/contracts/clearsky/cs-machines-request';
import { ContentService } from 'app/service/content.service';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-no-machine-data-alert',
  templateUrl: './no-machine-data-alert.component.html',
  styleUrls: ['./no-machine-data-alert.component.scss'],
})
export class NoMachineDataAlertComponent {
  showBanner: boolean;
  header: string = 'clearsky.no-machine-data-label';
  content: string = 'clearsky.fleet-coming-soon-label';
  @Input() marginLeft;

  constructor(
    private contentService: ContentService,
    private clearskyService: ClearskyService
  ) {
    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .subscribe((page) => {
        if (
          page &&
          page.aggregations &&
          !page.aggregations.totalCustMachinesWithDevice
        ) {
          if (!this.contentService.getCookie('noMachineDataBanner')) {
            this.setCookie(true);
          }
          this.showBanner =
            this.contentService.getCookieValue('noMachineDataBanner') ===
            'true';
        } else {
          this.showBanner = false;
        }
      });
  }

  closeAlert() {
    this.showBanner = false;
    this.setCookie(this.showBanner);
  }

  setCookie(val) {
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    this.contentService.createCookie(
      'noMachineDataBanner',
      val.toString(),
      d.toUTCString()
    );
  }
}
