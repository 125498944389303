import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {

  transform(value: string, format: string) {
    // Make the format lowercase in case there are any inconsistencies with what the backend returns to the front end.
    format = format.toLowerCase();
    if (value) {
      switch (format) {
        case 'dd/mm/yyyy': {
          return value.substring(6, value.length) + '/' + value.substring(4,6) + '/' + value.substring(0,4);
        }
        case 'mm/dd/yyyy': {
          return value.substring(4,6) + '/' + value.substring(6, value.length) + '/' + value.substring(0,4);
        }
        default: {
          return value.substring(0,4) + '/' + value.substring(4,6) + '/' + value.substring(6, value.length);
        }
      }
    }
    return '';
  }
  
}
