import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IWorkOrder } from '../../../../../contracts/web2case/iweb2case';
import { ExcelService } from '../../../../../service/excel/excel.service';
import { DatePipe } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentService } from '../../../../../service/content.service';
import { OcidItems } from '../../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-w2c-work-orders',
  styleUrls: ['../w2c-cases/w2c-cases.component.scss'],
  templateUrl: './w2c-work-orders.component.html',
})
export class W2CWorkOrdersComponent implements OnChanges {
  @Input() workOrders!: IWorkOrder[];
  @Input() ocids!: OcidItems;
  @Input() statusMenu!: [];
  @ViewChild(MatPaginator) workOrderPaginator!: MatPaginator;
  @ViewChild(MatSort) workOrderSort!: MatSort;
  workOrderSource = new MatTableDataSource<IWorkOrder>([]);
  isFocused = false;
  workOrderNum = '';
  statusFilter = '';
  jobFilter = '';
  repairFilter = '';
  jobFilterMenu!: unknown[];
  repairFilterMenu = [];
  pageSizeOptions: number[] = [10, 20, 50];
  workOrderLength = 0;
  submitted = false;

  constructor(
    private excelService: ExcelService,
    private date: DatePipe,
    private content: ContentService
  ) { }

  ngOnChanges() {
    this.workOrderLength = this.workOrders.length;
    this.workOrderSource = new MatTableDataSource<IWorkOrder>(this.workOrders);
    this.jobFilterMenu = [
      { label: this.ocids['w2c.any-job-type-label'], value: '' },
      ...[...new Map(this.workOrders.map((v) => [v.jobType, v])).values()].map(
        (item) => ({ label: item.jobType, value: item.jobType })
      ),
    ];
    this.repairFilterMenu = [
      { label: this.ocids['w2c.any-repair-type-label'], value: '' },
      ...[
        ...new Map(this.workOrders.map((v) => [v.repairType, v])).values(),
      ].map((item) => ({ label: item.repairType, value: item.repairType })),
    ];
    this.workOrderSource.paginator = this.workOrderPaginator;
  }

  workOrderFiltering() {
    const filteredData = this.workOrders.filter((item) => {
      return (
        (this.statusFilter ? item.status === this.statusFilter : true) &&
        (this.jobFilter ? item.jobType === this.jobFilter : true) &&
        (this.repairFilter ? item.repairType === this.repairFilter : true) &&
        (this.workOrderNum ? item.workOrderNumber === this.workOrderNum : true)
      );
    });
    this.isFocused = false;
    this.workOrderSource = new MatTableDataSource<IWorkOrder>(filteredData);
    this.workOrderSource.paginator = this.workOrderPaginator;
    this.workOrderLength = filteredData.length;
    this.submitted = true;
  }

  sortWorkOrders(sort: Sort) {
    const data = this.workOrderSource.data;
    if (!sort.active || sort.direction === '') {
      return;
    }
    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'workOrderNumber':
          return this.content.compare(
            a.workOrderNumber,
            b.workOrderNumber,
            isAsc
          );
        case 'status':
          return this.content.compare(a.status, b.status, isAsc);
        case 'jobType':
          return this.content.compare(a.jobType, b.jobType, isAsc);
        case 'repairType':
          return this.content.compare(a.repairType, b.repairType, isAsc);
        case 'machineSerialNumber':
          return this.content.compare(
            a.machineSerialNumber,
            b.machineSerialNumber,
            isAsc
          );
        case 'model':
          return this.content.compare(a.model, b.model, isAsc);
        case 'suggestedMaintenanceDate':
          return this.content.compare(
            a.suggestedMaintenanceDate,
            b.suggestedMaintenanceDate,
            isAsc
          );
        case 'createdDate':
          return this.content.compare(a.createdDate, b.createdDate, isAsc);
        default:
          return 0;
      }
    });

    this.workOrderSource = new MatTableDataSource<IWorkOrder>(sortedData);
  }

  identify(index, item): string {
    return item.workOrderNumber;
  }

  export() {
    if (this.workOrderLength) {
      const headers = [
        'WorkOrderNumber',
        'Status',
        'JobType',
        'RepairType',
        'MachineSerialNumber',
        'Model',
        'SuggestedMaintenanceDate',
        'CreatedDate',
      ];

      const json = this.workOrderSource.data.map((data: IWorkOrder) => {
        return {
          WorkOrderNumber: data.workOrderNumber,
          Status: data.status,
          JobType: data.jobType,
          RepairType: data.repairType,
          MachineSerialNumber: data.machineSerialNumber,
          Model: data.model,
          SuggestedMaintenanceDate: this.date.transform(
            data.suggestedMaintenanceDate,
            'dd/MM/yyyy'
          ),
          CreatedDate: this.date.transform(data.createdDate, 'dd/MM/yyyy'),
        };
      });
      this.excelService.exportData(headers, json, 'WorkOrders');
    }
  }

  resetForm(event) {
    event.preventDefault();
    this.workOrderNum = '';
    this.statusFilter = '';
    this.jobFilter = '';
    this.repairFilter = '';
    this.workOrderFiltering();
    this.submitted = false;
  }

  get isDirty() {
    return this.submitted && (this.workOrderNum || this.statusFilter || this.jobFilter || this.repairFilter);
  }
}
