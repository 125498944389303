// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-typeahead-top {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJlc3VsdC1saXN0LWZseW91dC1pdGVtLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsV0FBQTtBQUNGIiwiZmlsZSI6InJlc3VsdC1saXN0LWZseW91dC1pdGVtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlYXJjaC10eXBlYWhlYWQtdG9wIHtcclxuICB3aWR0aDogMTAwJTtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/results-list-flyout-item/result-list-flyout-item.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACA,wWAAwW","sourcesContent":[".search-typeahead-top {\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
