import { Pipe, PipeTransform } from '@angular/core';
import { INavMenu } from '../../app/contracts/user/inav-menu';
@Pipe({
  name: 'shippingTranslation'
})
export class ShippingTranslationPipe implements PipeTransform {
  transform(label: string, shippingInstructionMenu: INavMenu): string {
    const translatedLabel = shippingInstructionMenu.childMenus?.find(item => item.uxKey === label)?.label;
    return translatedLabel || label; // Return the translated label if found, otherwise return the original label
  }
}