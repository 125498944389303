import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import {
  IOrderDetail,
  OrderPurchaseType,
  TypeOfPayment,
  ShippingMethod,
  IReturnSelection,
} from '../../../../../contracts/orders/iorder-detail';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { IAppConfig } from '../../../../../contracts/user/config/iapp-config';
import { IAddress } from '../../../../../contracts/user/iaddress';
import { IUser, UserEnvironments } from '../../../../../contracts/user/iuser';
import { OrderInquiryService } from '../parts-order-inquiry/order-inquiry.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CartService } from '../../../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../../../service/confirmation/confirmation-alert.service';
import { IAddItemsDto } from '../../../../../contracts/commerce/dto/iadd-items-dto';
import { IOrderDetails } from '../../../../../contracts/orders/iorder-detail-orderDetails';
import {
  IOrderSpecialCharges,
  ChargeIds,
} from '../../../../../contracts/orders/iorder-detail-specialCharges';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { SalesType } from '../../../../../contracts/commerce/iorder';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { WindowRefService } from 'app/service/window-ref/window-ref.service';
import { environment } from '../../../../../../environments/environment';
import { MenuService } from 'app/service/user/menu.service';
import { Subscription, combineLatest, of } from 'rxjs';
import { INavMenu } from 'app/contracts/user/inav-menu';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-order-details',
  styleUrls: ['./order-details.component.scss'],
  templateUrl: './order-details.component.html',
})
export class OrderDetailsComponent implements OnInit {
  @Input() data!: {
    orderNumber: string;
  };
  ocids: OcidItems = {};
  user!: IUser;
  appConfig!: IAppConfig;
  order!: IOrderDetail;
  accountAddress!: IAddress;
  customerPickup = false;
  platformBrowser = false;
  showShipping = false;
  showPayment = false;
  dataLayer = (this.winRef.nativeWindow.dataLayer =
    this.winRef.nativeWindow.dataLayer || []);
  unshippedItems: IOrderDetails[] = [];
  specialCharges: IOrderSpecialCharges[] = [];
  typeOfPayment = TypeOfPayment;
  purchaseType = OrderPurchaseType;
  shippingMethod = ShippingMethod;
  startReturn = false;
  returnSelection: IReturnSelection;
  isCoreReturn = false;
  placeholderImg = environment.imagePath + environment.placeholderImg;
  shippingInstructionMenu: INavMenu;
  subscription: Subscription;
  shipViaTruck: INavMenu;
  orderTypeMenu: INavMenu;
  freightPaymentMethods: INavMenu;
  shipViaParcel: INavMenu;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private location: Location,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private orderService: OrderInquiryService,
    private currentUserService: CurrentUserService,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private ecommService: EcommerceService,
    private winRef: WindowRefService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Get OCIDS.
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'buy.shipping-options-primary',
        'buy.shipping-options-secondary',
        'orderdetails-title',
        'orderdetails.order-number',
        'orderdetails.order-date',
        'shipping.freight-billing-message',
        'shipping.ship-via-label',
        'account.items-title',
        'orderdetails.line-sequence',
        'order-details.shipped-from',
        'order-details.shipped-qty',
        'orderdetails.ordered',
        'buy.item-subtotal',
        'orderdetails.shipment-history',
        'orderdetails.shipment-number',
        'orderdetails.ship-date',
        'orderdetails.tracking-number',
        'orderdetails.invoice-number',
        'shipping.broker-label',
        'shipping.collect-label',
        'shipping.incoterms',
        'shipping.named-place-label',
        'order-details.paid-by-credit-card',
        'order-details.paid-by-account',
        'order-inquiry.tax-freight-warning-message',
        'order-history.customer-pickup-label',
        'order-history.pick-up-details-label',
        'order-details.paid-by-pay-pal',
        'rpa.total-potential-credit-label',
        'rpa.table-return-qty-label',
        'rpa.missing-items-message',
        'rpa.restocking-fee-warning-message',
        'rpa.success-title',
        'rpa.success-body',
        'rpa.success-return-num',
        'rpa.submit-total-credit-error',
        'web2case.reason-for-return',
        'rpa.showing-items-text',
        'rpa.select-items-label',
        'rpa.eligible-qty-label',
        'rpa.missing-items-info-message',
        'buy.core-deposit',
        'order-details.core-deposit-warning',
      ])
      .subscribe();
    // Get the current user, primarily used for app configurations.
    this.currentUserService.userSubject
      .pipe(distinctUntilChanged())
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.appConfig = user.appConfig;
          if (user.billingAddress) {
            this.accountAddress = user.billingAddress;
          }
        }
      });
    // Get the order.
    this.notificationService.reset();
    this.orderService
      .getOrderDetails({
        orderNumber: this.data.orderNumber,
      })
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (orderDetail: IOrderDetail) => {
          this.order = orderDetail;
          // show core deposit based on these requirements
          this.isCoreReturn =
            !!this.order.coreReturnExist &&
            !!Number(this.order.coreReturnAmountTotal) &&
            this.user.erpEnvironment === UserEnvironments.AA &&
            environment.jlgStyling;

          if (orderDetail.salesType === SalesType.Pickup) {
            this.customerPickup = true;
          }

          if (this.order.shipments.length) {
            // Create a temporary array of shipped item details
            let shippedArray: IOrderDetails[] = [];
            this.order.shipments.forEach((shipment) => {
              const tempArray = this.order.orderDetails.filter((detail) =>
                shipment.details.some(
                  ({ itemNumber }) => detail.itemNumber === itemNumber
                )
              );
              shippedArray = [...shippedArray, ...tempArray];
            });
            // Set unshipped items to those not included in the shippedArray
            this.unshippedItems = this.order.orderDetails.filter(
              (ar) =>
                !shippedArray.find((rm) => rm.itemNumber === ar.itemNumber)
            );
          } else {
            this.unshippedItems = this.order.orderDetails;
          }
          // Get special charges (if any)
          this.specialCharges = this.order.specialCharges;
        },
        error: (error) => {
          this.notificationService.addError(error.error.title);
        },
      });

    this.subscription = this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? combineLatest([
              this.menuService.getMenuByUxKey('user-defaults-shipping-instructions'),
              this.menuService.getMenuByUxKey('ship-via-truck'),
              this.menuService.getMenuByUxKey('order-type'),
              this.menuService.getMenuByUxKey('freight-payment-methods'),
              this.menuService.getMenuByUxKey('ship-via-parcel')
            ])
            : of(null)
        )
      )
      .subscribe((menus: INavMenu[]) => {
        if (menus) {
          this.shippingInstructionMenu = menus[0];
          this.shipViaTruck = menus[1];
          this.orderTypeMenu = menus[2];
          this.freightPaymentMethods = menus[3];
          this.shipViaParcel = menus[4];
        }
      });
  }

  /**
   * Return to the previous page (more than likely the order inquiry page).
   */
  goBack() {
    this.location.back();
  }

  /**
   * Toggle shipment panel
   */
  toggleShipment() {
    this.showShipping = !this.showShipping;
    if (this.showShipping) {
      this.dataLayer.push({
        event: 'orderDetails.showShipping',
        orderNum: this.order.comOrderNumber,
      });
    }
  }

  /**
   * Toggle payment panel
   */
  togglePayment() {
    this.showPayment = !this.showPayment;
    if (this.showPayment) {
      this.dataLayer.push({
        event: 'orderDetails.showPayment',
        orderNum: this.order.comOrderNumber,
      });
    }
  }

  /**
   * Add single item back to cart
   *
   * @param {IOrderDetails} item
   */
  addToCart(item: IOrderDetails) {
    this.cartService.addOneToCart(item.itemNumber, 1).subscribe({
      next: () => {
        this.alertService.addToCartConfirm(
          item.itemDescription ? item.itemDescription : item.itemNumber
        );
        // Send Add to Cart action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.addToCart(
            item.itemNumber,
            item.itemCategoryName ?? '',
            item.itemDescription,
            1,
            'Order Details - Part Level'
          );
        }
      },
    });
  }

  /**
   * Add all items back to cart
   */
  addAllToCart() {
    const itemsToAdd: IAddItemsDto = { items: [] };
    const gaItemsToAdd = [];
    const source = 'Order Details - Order Level';
    this.order.orderDetails.forEach((item) => {
      itemsToAdd.items.push({
        catalogRefId: item.itemNumber,
        quantity: parseInt(item.orderQuantity),
        productId: item.itemNumber,
      });
      gaItemsToAdd.push({
        item_id: item.itemNumber,
        item_name: item.itemDescription ?? '',
        item_list_name: source,
        item_category: item.itemCategoryName ?? '',
        id: item.itemNumber,
        name: item.itemDescription ?? '',
        quantity: item.orderQuantity,
      });
    });
    this.cartService.addToCart(itemsToAdd).subscribe({
      next: () => {
        this.alertService.alertUser('Items have been added to your cart.');
        // Send Add to Cart action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.addAllToCart(gaItemsToAdd, source);
        }
      },
      error: (error) => {
        this.notificationService.addError(error.error.title);
      },
    });
  }

  /**
   * Start RPA return
   * @param {IReturnSelection} returnSelected
   */
  returnItem(returnSelected: IReturnSelection) {
    this.startReturn = true;
    this.returnSelection = returnSelected;
  }

  /**
   * Check if the unshipped item has any special charges by comparing lineSequenceNumber to itemReference
   * @param {string} lineNum
   */
  checkSpecialCharge(lineNum: string) {
    return (
      this.specialCharges.length &&
      this.specialCharges.some(
        (i) =>
          +i.itemReference === +lineNum &&
          +i.amount > 0 &&
          i.chargeId === ChargeIds.CORS
      ) &&
      this.user.erpEnvironment === UserEnvironments.AA
    );
  }

  /**
   * Print the page.
   */
  print() {
    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      window.print();
    }
  }
}
