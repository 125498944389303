import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AccountAddressBookComponent } from '../cartridges/account/account-address-book/account-address-book.component';
import { AccountManageUsersComponent } from '../cartridges/account/account-manage-users/account-manage-users.component';
import { AccountSubscriptionManagementComponent } from '../cartridges/account/account-subscription-management/account-subscription-management.component';
import { AccountNavigationComponent } from '../cartridges/account/account-navigation/account-navigation.component';
import { AccountOrdersStartComponent } from '../cartridges/account/account-orders/account-orders-start/account-orders-start.component';
import { InvoiceInformationComponent } from '../cartridges/account/account-orders/invoice-information/invoice-information.component';
import { OrderDetailsComponent } from '../cartridges/account/account-orders/order-details/order-details.component';
import { PartsOrderInquiryComponent } from '../cartridges/account/account-orders/parts-order-inquiry/parts-order-inquiry.component';
import { PartsPurchaseHistoryComponent } from '../cartridges/account/account-orders/parts-purchase-history/parts-purchase-history.component';
import { AccountPaymentComponent } from '../cartridges/account/account-payment-preferences/account-payment-preferences.component';
import { AccountProfileComponent } from '../cartridges/account/account-profile/account-profile.component';
import { AccountShoppingListComponent } from '../cartridges/account/account-shopping-list/account-shopping-list.component';
import { AccountShoppingListsComponent } from '../cartridges/account/account-shopping-lists/account-shopping-lists.component';
import { AccountStartComponent } from '../cartridges/account/account-start/account-start.component';
import { AdsenseComponent } from '../cartridges/adsense/adsense.component';
import { AppliedFiltersComponent } from '../cartridges/applied-filters/applied-filters.component';
import { AutoSuggestPanelComponent } from '../cartridges/auto-suggest-panel/auto-suggest-panel.component';
import { CategoryPartsAutoSuggestComponent } from '../cartridges/auto-suggest-panel/category-parts-auto-suggest/category-parts-auto-suggest.component';
import { FlyOutPanelSlotComponent } from '../cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel-slot.component';
import { FlyOutPanelComponent } from '../cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/flyout-panel.component';
import { ProductDetailFlyoutItemComponent } from '../cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/product-detail-flyout-item/product-detail-flyout-item.component';
import { ResultsListFlyoutItemComponent } from '../cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/results-list-flyout-item/results-list-flyout-item.component';
import { KeywordSearchAutoSuggestComponent } from '../cartridges/auto-suggest-panel/keyword-search-auto-suggest/keyword-search-auto-suggest.component';
import { BreadcrumbsComponent } from '../cartridges/breadcrumbs/breadcrumbs.component';
import { PdpCrumbsComponent } from '../cartridges/breadcrumbs/pdp-crumbs/pdp-crumbs.component';
import { CategoryNavigationComponent } from '../cartridges/category-navigation/category-navigation.component';
import { CheckoutComponent } from '../cartridges/checkout/checkout.component';
import { OrderConfirmationComponent } from '../cartridges/checkout/order-confirmation/order-confirmation.component';
import { ContentSlotLeftComponent } from '../cartridges/content-slot-left/content-slot-left.component';
import { ContentSlotMainComponent } from '../cartridges/content-slot-main/content-slot-main.component';
import { ContentWithImageHeaderComponent } from '../cartridges/content-with-image-header/content-with-image-header.component';
import { EmergencyAlertsComponent } from '../cartridges/emergency-alerts/emergency-alerts.component';
import { ForgotUnlockWrapperComponent } from '../cartridges/forgot-unlock-wrapper/forgot-unlock-wrapper.component';
import { FormWithBackgroundImageComponent } from '../cartridges/form-with-background-image/form-with-background-image.component';
import { GuidedNavigationComponent } from '../cartridges/guided-navigation/guided-navigation.component';
import { HeroCarouselComponent } from '../cartridges/hero-carousel/hero-carousel.component';
import { HorizontalRecordSpotlightComponent } from '../cartridges/horizontal-record-spotlight/horizontal-record-spotlight.component';
import { IFrameComponent } from '../cartridges/i-frame/i-frame.component';
import { ImageNavigationComponent } from '../cartridges/image-navigation/image-navigation.component';
import { InteractiveManualsComponent } from '../cartridges/interactive-manuals/interactive-manuals.component';
import { JumboTextListComponent } from '../cartridges/jumbo-text-list/jumbo-text-list.component';
import { LatestNewsComponent } from '../cartridges/latest-news/latest-news.component';
import { MarketingBoxBannerComponent } from '../cartridges/marketing-box-banner/marketing-box-banner.component';
import { PageHeadingComponent } from '../cartridges/page-heading/page-heading.component';
import { OrderNowComponent } from '../cartridges/parts/order-now/order-now.component';
import { PopularCategoriesComponent } from '../cartridges/popular-categories/popular-categories.component';
import { ProductDetailRelatedItemsComponent } from '../cartridges/product-detail-related-items/product-detail-related-items.component';
import { ProductDetailComponent } from '../cartridges/product-detail/product-detail.component';
import { ResultsListComponent } from '../cartridges/results-list/results-list.component';
import { RichTextMainComponent } from '../cartridges/rich-text-main/rich-text-main.component';
import { SchematicsComponent } from '../cartridges/schematics/schematics.component';
import { SearchAdjustmentsComponent } from '../cartridges/search-adjustments/search-adjustments.component';
import { SearchBoxBannerComponent } from '../cartridges/search-box-banner/search-box-banner.component';
import { ShoppingCartComponent } from '../cartridges/shopping-cart/shopping-cart.component';
import { SERedirectComponent } from '../cartridges/smart-equip-redirect/se-redirect.component';
import { SubCategoryNavigationComponent } from '../cartridges/sub-category-navigation/sub-category-navigation.component';
import { TabsComponent } from '../cartridges/tabs/tabs.component';
import { TextPromoComponent } from '../cartridges/text-promo/text-promo.component';
import { TrustFactorsComponent } from '../cartridges/trust-factors/trust-factors.component';
import { UserRegistrationComponent } from '../cartridges/user-registration/user-registration.component';
import { VideoComponent } from '../cartridges/video/video.component';
import { Web2CaseComponent } from '../cartridges/web2case/web2case.component';
import { ZeroResultsMessagesComponent } from '../cartridges/zero-results-messages/zero-results-messages.component';
import { EndecaService } from '../endeca.service';
import { CartridgeInterface } from './cartridge.class';
import { ArticleSearchBoxComponent } from '../cartridges/article-search-box/article-search-box.component';
import { ArticleResultListComponent } from '../cartridges/article-result-list/article-result-list.component';
import { KnowledgeArticleAutoSuggestComponent } from '../cartridges/auto-suggest-panel/knowledge-article-auto-suggest/knowledge-article-auto-suggest.component';
import { ArticleDetailComponent } from '../cartridges/article-detail/article-detail.component';
import { PlacardComponent } from '../cartridges/placard/placard.component';
import { MarketingBoxCartComponent } from '../cartridges/marketing-box-cart/marketing-box-cart.component';
import { PartsSliderCartComponent } from '../cartridges/parts-slider-cart/parts-slider-cart.component';
import { JumpLinkCarouselComponent } from '../cartridges/jump-link-carousel/jump-link-carousel.component';
import { JumpLinkComponent } from '../cartridges/jump-link-carousel/jump-link/jump-link.component';
import { ThreeColumnComponent } from '../cartridges/three-column/three-column.component';
import { ThreeColumnCardComponent } from '../cartridges/three-column/three-column-card/three-column-card.component';
import { ContentSlotHeaderComponent } from '../cartridges/content-slot-header/content-slot-header.component';
import { ContentSlotFooterComponent } from '../cartridges/content-slot-footer/content-slot-footer.component';
import { TabSearchCountComponent } from '../cartridges/tab-search-count/tab-search-count.component';
import { TabSearchResultsComponent } from '../cartridges/tab-search-results/tab-search-results.component';
import { AccountW2CComponent } from '../cartridges/account/account-w2c/account-w2c.component';
import { ControlResultListComponent } from '../cartridges/control-result-list/control-result-list.component';
import { ControlDetailComponent } from '../cartridges/control-detail/control-detail.component';
import { H1Component } from '../cartridges/h1/h1.component';
import { ApplicationTourComponent } from '../cartridges/application-tour/application-tour.component';
import { ListrakRecommenderComponent } from '../cartridges/listrak-recommender/listrak-recommender.component';
import { ManualResultListComponent } from '../cartridges/manual-result-list/manual-result-list.component';
import { TwoColumnComponent } from '../cartridges/two-column/two-column.component';
import { MachineDetailComponent } from '../../clearsky/machines/machine-detail/machine-detail.component';
import { SubscriptionStepperComponent } from 'app/clearsky/subscription/subscription-stepper/subscription-stepper.component';
import { AccessControlComponent } from '../../clearsky/access-control/access-control.component';
import { DashboardComponent } from '../../clearsky/dashboard/dashboard.component';
import { ListComponent } from '../../clearsky/machines/list/list.component';
import { MapComponent } from '../../clearsky/map/map.component';
import { SubscriptionOrderConfirmationComponent } from '../../clearsky/subscription/subscription-order-confirmation/subscription-order-confirmation.component';
import { RatingsComponent } from '../cartridges/product-detail/ratings/ratings.component';
import { RpaDetailsComponent } from '../cartridges/account/account-orders/order-details/rpa-details/rpa-details.component';
import { SearchBoxHeaderComponent } from '../cartridges/search-box-header/search-box-header.component';
import { RequestFormComponent } from '../../clearsky/request-form/request-form.component';
import { UnauthorizedResponseComponent } from '../cartridges/unauthorized-response/unauthorized-response.component';
import { PlateIdReplacementComponent } from '../cartridges/plate-id-replacement/plate-id-replacement.component';
import { PardotFormComponent } from '../cartridges/pardot-form/pardot-form.component';

interface payloadData {
  '@type': string;
}
@Component({
  selector: 'app-cartridge',
  templateUrl: './cartridge.component.html',
})
export class CartridgeComponent implements OnInit, CartridgeInterface {
  @Input() data!: payloadData;
  @Input() type!: unknown;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;
  @HostBinding('class') classNames = '';
  cartridgeTypes = {
    AddressBook: AccountAddressBookComponent,
    AppliedFilters: AppliedFiltersComponent,
    AppliedFiltersMain: AppliedFiltersComponent,
    AutoSuggestPanel: AutoSuggestPanelComponent,
    Breadcrumbs: BreadcrumbsComponent,
    SearchBoxBanner: SearchBoxBannerComponent,
    BulletinBoardMessages: LatestNewsComponent,
    Cart: ShoppingCartComponent,
    CategoryNavigation: CategoryNavigationComponent,
    CategoryPartsAutoSuggest: CategoryPartsAutoSuggestComponent,
    CategoryPartsEquipmentPagesAutoSuggest: CategoryPartsAutoSuggestComponent,
    Checkout: CheckoutComponent,
    ClearSkyDashboard: DashboardComponent,
    ClearSkyList: ListComponent,
    ClearSkyMap: MapComponent,
    ClearSkySiteNetworks: MapComponent,
    ClearSkyAccessControlDashboard: AccessControlComponent,
    ClearSkyADP: MachineDetailComponent,
    ClearSkyRequestForm: RequestFormComponent,
    ClearSkySubscription: SubscriptionStepperComponent,
    ClearSkySubscriptionOrderConfirmation:
      SubscriptionOrderConfirmationComponent,
    ContentSlotLeft: ContentSlotLeftComponent,
    ContentSlotMain: ContentSlotMainComponent,
    ContentSlotHeader: ContentSlotHeaderComponent,
    ContentSlotFooter: ContentSlotFooterComponent,
    ContentSlotTOS: ContentSlotMainComponent,
    ControlSoftwareAutoSuggest: KnowledgeArticleAutoSuggestComponent,
    ControlSoftwareDetail: ControlDetailComponent,
    ControlSoftwareResultsList: ControlResultListComponent,
    ControlSoftwareSearchForm: ArticleSearchBoxComponent,
    EditProfileForm: AccountProfileComponent,
    EmergencyAlerts: EmergencyAlertsComponent,
    EquipmentDetail: ProductDetailComponent,
    EquipmentDetailRelatedItems: ProductDetailRelatedItemsComponent,
    FeaturedParts: ProductDetailRelatedItemsComponent,
    FlyOutPanel: FlyOutPanelComponent,
    FlyOutPanelSlot: FlyOutPanelSlotComponent,
    FormWithBackgroundImage: FormWithBackgroundImageComponent,
    GoogleAdSense: AdsenseComponent,
    GuidedNavigation: GuidedNavigationComponent,
    H1TitleTag: H1Component,
    H1TitleTagHeader: H1Component,
    HeroCarousel: HeroCarouselComponent,
    HeroCarouselHeader: HeroCarouselComponent,
    HorizontalRecordSpotlight: HorizontalRecordSpotlightComponent,
    IFrame: IFrameComponent,
    ImageNavigation: ImageNavigationComponent,
    ImageWithCTACard: ThreeColumnCardComponent,
    InteractiveManuals: InteractiveManualsComponent,
    InvoiceInformation: InvoiceInformationComponent,
    JDCManualAutoSuggest: KnowledgeArticleAutoSuggestComponent,
    JLGManualAutoSuggest: KnowledgeArticleAutoSuggestComponent,
    JDCManualResultsList: ManualResultListComponent,
    JLGManualResultsList: ManualResultListComponent,
    JDCManualSearchForm: ArticleSearchBoxComponent,
    JLGManualSearchForm: ArticleSearchBoxComponent,
    KeywordSearchAutoSuggest: KeywordSearchAutoSuggestComponent,
    KnowledgeArticleAutoSuggest: KnowledgeArticleAutoSuggestComponent,
    KnowledgeArticleDetail: ArticleDetailComponent,
    KnowledgeArticleDetailRelatedItems: ProductDetailRelatedItemsComponent,
    KnowledgeArticleResultsList: ArticleResultListComponent,
    KnowledgeSearchForm: ArticleSearchBoxComponent,
    ListrakRecommender: ListrakRecommenderComponent,
    ManageUsers: AccountManageUsersComponent,
    ClearSkySubscriptionManagement: AccountSubscriptionManagementComponent,
    MarketingBoxBanner: MarketingBoxBannerComponent,
    MarketingBoxCart: MarketingBoxCartComponent,
    MyAccountDashboard: AccountStartComponent,
    MyAccountNavigation: AccountNavigationComponent,
    OLEApplicationTour: ApplicationTourComponent,
    OrderConfirmation: OrderConfirmationComponent,
    OrderDetails: OrderDetailsComponent,
    OrderInquiry: PartsOrderInquiryComponent,
    OrderNow: OrderNowComponent,
    OrdersDashboard: AccountOrdersStartComponent,
    Orders90DayHistory: PartsPurchaseHistoryComponent,
    PageHeading: PageHeadingComponent,
    PardotGenericForm: PardotFormComponent,
    PartsSliderCart: PartsSliderCartComponent,
    PaymentPreferences: AccountPaymentComponent,
    EDPBreadcrumbs: PdpCrumbsComponent,
    PDPBreadcrumbs: PdpCrumbsComponent,
    Placard: PlacardComponent,
    PopularCategories: PopularCategoriesComponent,
    ProductDetail: ProductDetailComponent,
    ProductDetailFlyOutItem: ProductDetailFlyoutItemComponent,
    ProductDetailRelatedItems: ProductDetailRelatedItemsComponent,
    ProductDetailRatingsReviews: RatingsComponent,
    PromotionalTextBanner: TextPromoComponent,
    PromotionalTextBannerFooter: TextPromoComponent,
    RecordSearchAutoSuggestItem: ResultsListFlyoutItemComponent,
    RegistrationForm: UserRegistrationComponent,
    ResetPasswordForm: ForgotUnlockWrapperComponent,
    ResultsList: ResultsListComponent,
    ResultsListFlyOutItem: ResultsListFlyoutItemComponent,
    ReturnPartsAuthorizationDetail: RpaDetailsComponent,
    RichTextHeader: RichTextMainComponent,
    RichTextMain: RichTextMainComponent,
    RichTextTOS: RichTextMainComponent,
    ReplacementIdPlateRequest: PlateIdReplacementComponent,
    SchematicsIFrame: SchematicsComponent,
    SearchAdjustments: SearchAdjustmentsComponent,
    SearchBoxBannerHeader: SearchBoxHeaderComponent,
    ShoppingList: AccountShoppingListComponent,
    ShoppingLists: AccountShoppingListsComponent,
    SmartEquipRedirect: SERedirectComponent,
    SublayoutControlContentWithImageHeader: ContentWithImageHeaderComponent,
    SublayoutControlJumboTextHeaderWithList: JumboTextListComponent,
    SublayoutControlWithTabs: TabsComponent,
    SublayoutControlWithVideo: VideoComponent,
    SubCategoryNavigation: SubCategoryNavigationComponent,
    TabbedSearchCounts: TabSearchCountComponent,
    TwoColumnMediaText: TwoColumnComponent,
    ThreeColumnImagesWithCTA: ThreeColumnComponent,
    ThreeColumnImagesWithCTAHeader: ThreeColumnComponent,
    TrustFactors: TrustFactorsComponent,
    TwoColumnJumpLinkCarousel: JumpLinkCarouselComponent,
    TwoColumnJumpLinkCarouselHeader: JumpLinkCarouselComponent,
    TwoColumnJumpLink: JumpLinkComponent,
    TwoTabSearchResults: TabSearchResultsComponent,
    UnauthorizedResponse: UnauthorizedResponseComponent,
    UnlockAccountForm: ForgotUnlockWrapperComponent,
    W2CPortal: AccountW2CComponent,
    Web2Case: Web2CaseComponent,
    ZeroResultsMessages: ZeroResultsMessagesComponent,
  };

  constructor(private endecaService: EndecaService) {}

  ngOnInit() {
    // Set class of cartridge as cartridge type
    this.classNames = this.data['@type'];

    // Load in the cartridge
    this.endecaService.injectCartridge(
      this.container,
      this.data,
      this.cartridgeTypes
    );
  }
}
