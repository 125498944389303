import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { DynamicRouteComponent } from './dynamic-route/dynamic-route.component';
import { AuthEndecaPayloadResolver } from './endeca/auth-endeca-payload.resolver.service';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { PayPalRedirectComponent } from './paypal-redirect/paypal-redirect.component';
import { appRouteResolver } from './app-route-resolver';

const appRoutes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    data: { title: 'Maintenance' },
  },
  {
    path: 'paypal-redirect',
    component: PayPalRedirectComponent,
    data: { title: 'PayPal Redirect' },
  },
  {
    path: '**',
    component: DynamicRouteComponent,
    resolve: {
      shouldRoute: appRouteResolver,
      response: AuthEndecaPayloadResolver,
    },
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [AuthEndecaPayloadResolver],
  exports: [RouterModule],
})
export class AppRoutingModule {}
