// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookie {
  position: fixed;
  bottom: 0;
  min-height: 100px;
  background: var(--white);
  width: 100%;
  padding: 30px 0;
  z-index: 102;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
}
.cookie-header {
  font-size: 18px;
  font-weight: 900;
  font-family: var(--header-font);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvb2tpZS1ub3RpY2UuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsU0FBQTtFQUNBLGlCQUFBO0VBQ0Esd0JBQUE7RUFDQSxXQUFBO0VBQ0EsZUFBQTtFQUNBLFlBQUE7RUFDQSwwQ0FBQTtBQUNGO0FBQ0U7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSwrQkFBQTtBQUNKIiwiZmlsZSI6ImNvb2tpZS1ub3RpY2UuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29va2llIHtcclxuICBwb3NpdGlvbjogZml4ZWQ7XHJcbiAgYm90dG9tOiAwO1xyXG4gIG1pbi1oZWlnaHQ6IDEwMHB4O1xyXG4gIGJhY2tncm91bmQ6IHZhcigtLXdoaXRlKSA7XHJcbiAgd2lkdGg6IDEwMCU7XHJcbiAgcGFkZGluZzogMzBweCAwO1xyXG4gIHotaW5kZXg6IDEwMjtcclxuICBib3gtc2hhZG93OiAwIDNweCA2cHggMCByZ2JhKDAsIDAsIDAsIDAuMyk7XHJcblxyXG4gICYtaGVhZGVyIHtcclxuICAgIGZvbnQtc2l6ZTogMThweDtcclxuICAgIGZvbnQtd2VpZ2h0OiA5MDA7XHJcbiAgICBmb250LWZhbWlseTogdmFyKC0taGVhZGVyLWZvbnQpO1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/core/footer/cookie-notice/cookie-notice.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,iBAAA;EACA,wBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,0CAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,+BAAA;AACJ;AACA,o2BAAo2B","sourcesContent":[".cookie {\r\n  position: fixed;\r\n  bottom: 0;\r\n  min-height: 100px;\r\n  background: var(--white) ;\r\n  width: 100%;\r\n  padding: 30px 0;\r\n  z-index: 102;\r\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);\r\n\r\n  &-header {\r\n    font-size: 18px;\r\n    font-weight: 900;\r\n    font-family: var(--header-font);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
