import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Collapse } from './collapse';
import { CollapseStack } from './collapse-stack';

@NgModule({
  declarations: [Collapse],
  exports: [Collapse],
  imports: [
    CommonModule
  ],
  providers: [CollapseStack]
})
export class CollapseModule { }
