import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
 
@Injectable()
export class CanonicalService { 
  constructor(@Inject(DOCUMENT) private dom) { }
  
  createCanonicalURL(url: string) {
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }
} 