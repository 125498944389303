import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  combineLatest,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { CsDashboard } from '../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyDashboardService } from '../../clearsky-dashboard.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { catchError, debounceTime, distinctUntilChanged, first, skip, take, tap } from 'rxjs/operators';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { SaveDashboardDialogComponent } from '../../dashboard/save-dashboard-dialog/save-dashboard-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDialogComponent } from '../../machines/widgets/widget-dialog/widget-dialog.component';
import { DOCUMENT } from '@angular/common';
import { ClearskyService } from '../../clearsky.service';
import { EmailDashboardDialogComponent } from '../../dashboard/email-dashboard-dialog/email-dashboard-dialog.component';
import { MachineFilterSelection } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { MatMenuTrigger } from '@angular/material/menu';
import { LayoutService } from '../../../service/layout.service';
import { EndecaService } from '../../../endeca/endeca.service';
import { CurrentUserService } from 'app/service/user/current-user.service';

interface MoreOption {
  text: string;
  click: () => void;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-header',
  templateUrl: './fleet-header.component.html',
  styleUrls: [
    './fleet-header.component.scss',
    '../../cs-layout/cs-layout-header.component.scss',
  ],
})
export class FleetHeaderComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-nav-min') get minimizedClass() {
    return this.layoutService.clearskyNavCollapsed;
  }
  @HostBinding('class.tw-ml-0') get hiddenClass() {
    return !this.currentUserService.user.clearSky;
  }
  @HostBinding('class.no-content-padding') noContentPadding = false;
  @Input() showCustomizeButton = true;
  @Input() showMoreOptions = true;
  @Input() moreOptions: MoreOption[] = [];
  dashboards$: Observable<CsDashboard[]> = this.dashboardService.dashboards$;
  loadedDashboard$: Observable<CsDashboard> =
    this.dashboardService.loadedDashboard$;
  inEditMode$: Observable<boolean> = this.dashboardService.inEditMode$;
  currentFilters$: Observable<MachineFilterSelection[]> =
    this.clearskyService.currentFilters$;
  ocids: OcidItems = {};
  dashboard: CsDashboard | undefined;
  showFilters = false;
  filterCollapsed = true;
  private widgets: string[] = [];
  private onDestroy$ = new Subject<void>();
  private promptEditClear$: Observable<boolean> = this.inEditMode$.pipe(
    first(),
    mergeMap((inEditMode) => {
      // Prompt user about edit clear
      if (inEditMode) {
        return this.confirmation.confirm(
          '',
          this.ocids['clearsky.clear-dashboard-label'],
          this.ocids['global.confirm'],
          this.ocids['global.cancel']
        );
      } else {
        return of(true);
      }
    })
  );
  private subs: Subscription = new Subscription();

  constructor(
    private dashboardService: ClearskyDashboardService,
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private confirmation: ConfirmationDialogsService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private currentUserService: CurrentUserService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
    private endecaService: EndecaService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.choose-dashboard-label',
        'clearsky.override-dashboard-label',
        'clearsky.continue-label',
        'clearsky.delete-dashboard-label',
        'clearsky.clear-dashboard-label',
        'clearsky.filter-label',
        'clearsky.default-dashbard-filter',
        'clearsky.add-new-label',
        'clearsky.refresh-label',
        'clearsky.customize-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.endecaService.suppressContentPadding$
      .pipe(distinctUntilChanged())
      .subscribe((suppressContentPadding: boolean) => {
        this.noContentPadding = suppressContentPadding;
      });

    this.loadedDashboard$.subscribe((dashboard) => {
      this.dashboard = dashboard;
    });

    this.clearskyService.currentWidgets$.subscribe(
      (widgets) => (this.widgets = widgets)
    );

    // Are they trying to load a dashboard from a shared dashboard url?
    const dashboardId = this.route.snapshot.queryParams['dashboardId'];
    if (dashboardId) {
      this.dashboardService
        .load(dashboardId, false)
        .pipe(
          catchError((err) => {
            this.snackbar.open('Dashboard could not be loaded.', undefined, {
              duration: 3000,
              verticalPosition: 'top',
            });
            this.router.navigateByUrl('/clearsky');
            return throwError(err);
          })
        )
        .subscribe((dashboard) => {
          this.snackbar.open(
            `Save ${dashboard.name} to add it to your list of dashboards.`,
            undefined,
            {
              duration: 6000,
              verticalPosition: 'top',
            }
          );
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { dashboardId: null },
            queryParamsHandling: 'merge',
          });
        });
    }

    // If there are filters then show them
    combineLatest([this.clearskyService.currentFilters$,
    this.clearskyService.currentColumns$,
    this.clearskyService.currentWidgets$]).pipe(tap(([filters, columns, widgets]) => {
      this.showFilters = !!filters.length;
    }), skip(1), debounceTime(200), tap(() => {
      this.onSaveDashboard();
    })).subscribe();
  }

  resetFilter() {
    this.clearskyService.resetFilters();
  }

  /**
   * Cancel changes.
   */
  onCancel(): void {
    this.showFilters = false;
  }

  /**
   * On show filters button click.
   */
  onShowFilters(): void {
    this.showFilters = true;
  }

  /**
   * Start a new dashboard.
   */
  onNewDashboard(): void {
    this.promptEditClear$.subscribe((confirmed) => {
      if (confirmed) {
        // Reset all edits
        this.dashboardService.startNew();
      }
    });
  }

  /**
   * On new dashboard and prompt save.
   */
  onNewSaveDashboard(): void {
    this.promptEditClear$.subscribe((confirmed) => {
      if (confirmed) {
        // Reset all edits and prompt new dashboard dialog
        this.dashboardService.startNew();

        this.dialog.open(SaveDashboardDialogComponent, {
          panelClass: ['clearsky-dialog'],
        });
      }
    });
  }

  /**
   * Load the dashboard.
   * @param id
   */
  loadDashboard(id: number): void {
    combineLatest([this.currentFilters$, this.inEditMode$])
      .pipe(
        mergeMap(([filters, inEditMode]) => {
          if (!inEditMode) {
            return of(true);
          } else {
            if (filters.length) {
              return this.confirmation.confirm(
                '',
                this.ocids['clearsky.override-dashboard-label'],
                this.ocids['clearsky.continue-label'],
                this.ocids['global.cancel']
              );
            } else {
              return of(true);
            }
          }
        }),
        mergeMap((confirmed) => {
          if (confirmed) {
            return this.dashboardService.load(id);
          }

          return of(true);
        }),
        first()
      )
      .subscribe();
  }

  /**
   * On delete dashboard button click
   */
  onDeleteDashboard(trigger: MatMenuTrigger, dashboard: CsDashboard): void {
    this.confirmation
      .confirm(
        '',
        `${this.ocids['clearsky.delete-dashboard-label']} '${dashboard.name}'?`,
        this.ocids['global.delete'],
        this.ocids['global.cancel']
      )
      .pipe(
        mergeMap((confirmed) => {
          if (confirmed) {
            return this.dashboardService.delete(dashboard.id);
          }

          return of(false);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.dashboardService.startNew();
          trigger.closeMenu();
        }
      });
  }

  /**
   * On edit dashboard button click
   */
  onUpdateDashboard(trigger: MatMenuTrigger, dashboard: CsDashboard): void {
    const dialogRef = this.dialog.open(SaveDashboardDialogComponent, {
      panelClass: ['clearsky-dialog'],
      data: {
        dashboard,
        edit: true,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // this.dashboardService.startNew();
        trigger.closeMenu();
      }
      return of(false);
    });
  }

  /**
   * On customize click event listener.
   */
  onCustomize(): void {
    this.dialog.open(WidgetDialogComponent, {
      panelClass: ['clearsky-dialog'],
      autoFocus: false,
    });
  }

  /**
   * Share Dashboard
   */
  onShareDashboard(): void {
    this.dialog.open(EmailDashboardDialogComponent, {
      panelClass: ['clearsky-dialog'],
    });
  }

  /**
   * Clear edits from loaded dashboard.
   */
  onClearEdits(): void {
    this.confirmation
      .confirm(
        '',
        this.ocids['clearsky.clear-dashboard-label'],
        this.ocids['global.confirm'],
        this.ocids['global.cancel']
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          // Reset dashboard or start new?
          if (this.dashboard) {
            this.loadedDashboard$
              .pipe(
                mergeMap((dashboard) => {
                  return this.dashboardService.load(dashboard.id);
                }),
                first()
              )
              .subscribe();
          } else {
            this.dashboardService.startNew();
          }
        }
      });
  }

  /**
   * Save dashboard.
   */
  onSaveDashboard(): void {
    if (this.dashboard) {
      // Update current dashboard
      this.subs.add(
        combineLatest([
          this.clearskyService.currentFilters$,
          this.clearskyService.currentColumns$,
          this.clearskyService.currentWidgets$,
        ])
          .pipe(
            take(1),
            mergeMap(([filters, columns, widgets]) => {
              const data = {
                name: this.dashboard.name,
                data: {
                  filters,
                  widgets,
                  columns,
                },
              };

              return this.dashboardService.update(
                this.dashboard.id,
                data,
                true
              );
            })
          )
          .subscribe()
      );
    }
  }

  /**
   * Force machines reload.
   */
  forceReload(): void {
    this.clearskyService.forceMachinesReload();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
