import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuService } from 'app/service/user/menu.service';
import { INavMenu } from 'app/contracts/user/inav-menu';
import { CountryWithoutZip } from 'app/contracts/user/iaddress';
import { AccountType } from 'app/contracts/user/iuser';
import { combineLatest, mergeMap, of } from 'rxjs';
import { environment } from 'environments/environment';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { UserAddressesService } from 'app/service/user/user-addresses.service';
import { ConfirmationDialogsService } from 'app/shared/confirmation-dialog/confirmation-dialog.service';
import { ICustomerSwitch } from 'app/contracts/user/icustomer-switch';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-profile-completion-dialog',
  templateUrl: './profile-completion-dialog.component.html',
  styleUrls: ['./profile-completion-dialog.component.scss'],
  providers: [NotificationService],
})
export class ProfileCompletionDialogComponent implements OnInit, OnDestroy {
  profileFormGroup: UntypedFormGroup;
  firstName: string;
  step = 1;
  formSubmitted = false;
  ocids = {};
  countryOptions: INavMenu;
  stateProvinceOptions: INavMenu;
  retailStateOptions: INavMenu;
  retailCountryOptions: INavMenu;
  nonRetailStateOptions: INavMenu;
  nonRetailCountryOptions: INavMenu;
  addressValidated = false;
  userAccountType = '';
  accountType = AccountType;
  isJlg = environment.jlgStyling;
  brand = environment.jlgStyling ? 'JLG' : 'Jerr-Dan';
  reviewMsgVisible = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ProfileCompletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localization: LocalizationService,
    private changeDetector: ChangeDetectorRef,
    private menuService: MenuService,
    private notificationService: NotificationService,
    private currentUserService: CurrentUserService,
    private userAddressService: UserAddressesService,
    private confirmationService: ConfirmationDialogsService
  ) { }

  ngOnInit() {
    this.firstName = this.data.firstName;
    this.userAccountType = this.data.userType;
    this.profileFormGroup = this._formBuilder.group(
      {
        contactName: [''],
        address1: [''],
        address2: [''],
        address3: [''],
        city: ['', Validators.pattern(/^[[a-zA-Z \-]+]*$/)],
        country: [''],
        state: [''],
        postalCode: [''],
        defaultStoreId: [''],
        phoneNumber: ['', Validators.pattern(/^[0-9()+-.ext ]*$/)],
      }
    );

    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus
            ? combineLatest([
              this.menuService.getMenuByUxKey('countries'),
              this.menuService.getMenuByUxKey('retailCountries'),
              this.menuService.getMenuByUxKey('account-security-questions'),
              this.menuService.getMenuByUxKey('account-marketing-roles'),
              this.menuService.getMenuByUxKey('account-languages')
            ])
            : of(null)
        )
      )
      .subscribe((menus: INavMenu[]) => {
        if (menus) {
          // Set country related menu
          this.nonRetailCountryOptions = menus[0];
          this.nonRetailStateOptions = {};
          this.nonRetailCountryOptions?.childMenus.forEach((menu: INavMenu) => {
            this.nonRetailStateOptions[menu.uxKey] = menu.childMenus;
          });
          // Set default country/state options
          this.countryOptions = this.nonRetailCountryOptions;
          this.stateProvinceOptions = this.nonRetailStateOptions;
          // Only set retail countries menu on JLG
          if (this.isJlg) {
            this.retailCountryOptions = menus[1];
            this.retailStateOptions = {};
            this.retailCountryOptions?.childMenus.forEach((menu: INavMenu) => {
              this.retailStateOptions[menu.uxKey] = menu.childMenus;
            });
          }
          this.selectUserType(this.userAccountType)
        }
      });

    this.localization
      .getOCIDs([
        'sso.welcome-back-label',
        'sso.recognize-brand-label',
        'sso.company-information-label',
        'sso.brand-profile-label',
        'sso.profile-complete-label',
        'sso.go-to-homepage-label',
        'sso.account-review-label',
        'profile.company-contact-name-label',
        'registration.your-address-1',
        'registration.your-address-2',
        'registration.your-address-3',
        'registration.store-id-info-box'
      ])
      .subscribe();

    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  selectUserType(type: string) {
    if (type.toLowerCase() == AccountType.RETAIL.toLowerCase()) {
      // change menus based on user selection
      this.countryOptions = this.retailCountryOptions;
      this.stateProvinceOptions = this.retailStateOptions;
    } else {
      this.countryOptions = this.nonRetailCountryOptions;
      this.stateProvinceOptions = this.nonRetailStateOptions;
    }
  }


  /**
 * Determine whether or not the postal code field should be required
 * @returns {boolean}
 */
  zipRequired(): boolean {
    return CountryWithoutZip.every(
      (country) => this.profileFormGroup.controls['country'].value != country
    );
  }

  updateProfile(newUserProfile) {
    this.notificationService.reset();
    this.currentUserService.updateNewUserProfile(newUserProfile.homeAddress).subscribe(
      (_res: ICustomerSwitch) => {
        if (_res.status == '403') {
          this.reviewMsgVisible = true;
        }
        this.step = 3;
      },
      (error) => this.handleError(error)
    );
  }

  handleError(error) {
    let errors;
    try {
      errors = JSON.parse(error.error.detail);
    } catch (err) {
      errors = [error.error.title];
    }
    errors.forEach((item) =>
      this.notificationService.addError(item.errorSummary)
    );
  }

  completeProfile() {
    if (this.profileFormGroup.invalid) {
      return;
    }
    const newUserProfile = { homeAddress: this.profileFormGroup.value };
    if (
      !this.addressValidated &&
      newUserProfile.homeAddress.country != 'UK' &&
      newUserProfile.homeAddress.country != 'IE' &&
      newUserProfile.homeAddress.country != 'EI'
    ) {
      this.notificationService.reset();
      this.userAddressService
        .isAddressValid(newUserProfile.homeAddress)
        .subscribe(
          (data) => {
            // If this address is valid
            if (data === 'valid') {
              this.updateProfile(newUserProfile);
            } else if (data) {
              this.userAddressService
                .confirmAddress(data, newUserProfile.homeAddress)
                .subscribe((result) => {
                  if (result) {
                    this.addressValidated = true;
                    this.profileFormGroup
                      .get('address1')
                      .setValue(this.toTitleCase(result.addressLine));
                    this.profileFormGroup
                      .get('city')
                      .setValue(this.toTitleCase(result.city));
                    this.profileFormGroup
                      .get('state')
                      .setValue(result.state);
                    this.profileFormGroup
                      .get('postalCode')
                      .setValue(result.zip);
                  }
                });
            } else {
              this.userAddressService
                .confirmAddress(data, newUserProfile.homeAddress)
                .subscribe((result) => {
                  if (result) {
                    this.addressValidated = true;
                    this.profileFormGroup
                      .get('address1')
                      .setValue(this.toTitleCase(result.addressLine));
                    this.profileFormGroup
                      .get('city')
                      .setValue(this.toTitleCase(result.city));
                    this.profileFormGroup
                      .get('state')
                      .setValue(result.state);
                    this.profileFormGroup
                      .get('postalCode')
                      .setValue(result.zip);
                  }
                });
            }
          },
          (error) => {
            // status 400 is being used for DHL errors
            if (error.error.httpStatus === 400) {
              this.confirmationService.confirm(
                this.ocids['address-validation.message-7'],
                ''
              );
            } else {
              this.handleError(error);
            }
          }
        );
    } else {
      // If the address being submitted is not US or already validated, no need to validate, go ahead and update
      this.updateProfile(newUserProfile);
    }
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
