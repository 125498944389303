import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IOrder,
  OrderItemType,
} from '../../../../../contracts/commerce/iorder';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IUser } from '../../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MenuService } from 'app/service/user/menu.service';
import { Subscription, mergeMap, of } from 'rxjs';
import { INavMenu } from 'app/contracts/user/inav-menu';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-shipping-address-section-review]',
  templateUrl: './shipping-address-section-review.component.html',
})
export class ShippingAddressSectionReviewComponent implements OnInit {
  @Input() cart: IOrder;
  @Input() user: IUser;
  @Input() customerPickup = false;
  @Output() toggleForm: EventEmitter<any> = new EventEmitter<any>();
  ocids: {} = {};
  showOrderType = false;
  subscription: Subscription;
  orderTypeMenu: INavMenu;

  constructor(private localization: LocalizationService, private menuService: MenuService ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.showOrderType =
      this.cart.salesTypeDesc &&
      !(
        this.cart.orderItemType === OrderItemType.Machine &&
        this.cart.shippingGroups.items.length === 1
      );

      this.subscription = this.menuService.menus$
      .pipe(
        mergeMap((menus) => menus ? this.menuService.getMenuByUxKey('order-type') : of(null))
      )
      .subscribe((menus: INavMenu) => {
        if (menus) {
          this.orderTypeMenu = menus;
        }
      });
  }

  /**
   * Shipping address.
   *
   * @return {object}
   */
  get address() {
    return this.cart.shippingGroups.items[0].shippingAddress;
  }

  /**
   * Toggle form to edit mode.
   */
  onToggleForm() {
    this.toggleForm.emit();
  }
}
