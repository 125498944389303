import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { isPlatformBrowser } from '@angular/common';
import { ContentService } from '../../../service/content.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-cookie-notice',
  styleUrls: ['./cookie-notice.component.scss'],
  templateUrl: './cookie-notice.component.html',
})
export class CookieNoticeComponent implements OnInit {
  ocids!: OcidItems;
  requiresConsent = false;
  platformBrowser = false;
  dataLayer = (this.winRef.nativeWindow.dataLayer =
    this.winRef.nativeWindow.dataLayer || []);
  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private contentService: ContentService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'cookies.title',
        'cookies.description-text',
        'cookies.accept-label',
        'cookies.reject-label',
      ])
      .subscribe();

    // Check if the cookie acceptance cookie exists
    if (this.platformBrowser) {
      this.requiresConsent = !this.contentService.getCookie('cookieConsent');
      if (
        !this.requiresConsent &&
        this.contentService.getCookieValue('cookieConsent') === 'true'
      ) {
        // This will allow GTM tags to load
        this.dataLayer.push({
          event: 'consentSelected',
          cookieConsent: true,
        });
      }
    }
  }

  acceptCookie(accept: boolean) {
    if (this.platformBrowser) {
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      this.contentService.createCookie(
        'cookieConsent',
        accept.toString(),
        d.toUTCString()
      );
      this.requiresConsent = false;
      if (accept) {
        this.dataLayer.push({
          event: 'consentSelected',
          cookieConsent: true,
        });
      }
    }
  }
}
