import { Component, Input } from '@angular/core';
import { Machine } from '../../../contracts/clearsky/machine/machine.dto';

@Component({
  selector: 'app-machine-engine-status',
  templateUrl: './machine-engine-status.component.html',
  styleUrls: ['./machine-engine-status.component.scss'],
})
export class MachineEngineStatusComponent {
  @Input() machine!: Machine;
}
