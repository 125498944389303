import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import {LayoutService} from '../../../service/layout.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ContentService} from '../../../service/content.service';
import {INavMenu} from '../../../contracts/user/inav-menu';
import {MenuService} from '../../../service/user/menu.service';
import {OktaAuthWrapper} from '../../../service/auth/okta.auth.wrapper';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  isTablet$: Observable<boolean> = this.layoutService.isTabletMax$;
  isJlg = environment.jlgStyling;
  isMaintenance$: Observable<boolean> = this.contentService.isMaintenance$;
  isLoggedIn$: Observable<boolean>;
  menu$: Observable<INavMenu>;

  constructor(@Inject(PLATFORM_ID) private platformId: string,
              private menuService: MenuService,
              private oktaAuth: OktaAuthWrapper,
              public layoutService: LayoutService,
              private contentService: ContentService) {

    this.layoutService.isDesktop$.subscribe(isDesktop => {
        // Subscribe to menu data
        this.menu$ = this.menuService.menus$.pipe(
          map(menus => {
            if (menus) {
              // If no items are in the menu then hide the hamburger icon only when desktop, ticket #O20R-4053.
              this.layoutService.hideHamburgerIcon = !menus.headerMenu && isDesktop;
              return menus.headerMenu;
            }
          })
        );
      }
    );
  }

  @ViewChild('collapse') set collapse(serviceMenu) {
    // Reset the nav and hamburger state if the directive has collapsed.
    if (serviceMenu) {
      serviceMenu.collapseChange.subscribe((hasChanged) => {
        if (hasChanged) {
          this.layoutService.showMenuType();
        }
      });
    }
  }

  ngOnInit(): void {
    // Check if user is logged in (required for chat display)
    this.isLoggedIn$ = this.oktaAuth.isLoggedIn;
  }

}
