import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { IAddPickupWarehouseToOrder } from '../../contracts/commerce/dto/iadd-pickup-warehouse-to-order';

@Injectable()
export class CustomerPickupService {

  constructor(private http: HttpClient) {}

/**
 * Returns a list of warehouses available to a user to select which warehouse they would
 * like to pick up their order.
 * 
 * @returns {Observable<IWarehouseAddress[]>}
 */
  getWarehouses() {
    return this.http.get(`${environment.apiUrl}/warehouses/eligiblePickup`);
  }

  /**
   * Sets the warehouse the user selected as the warehouse they would like to pick up
   * their order.
   * 
   * @param {IAddPickupWarehouseToOrder} dto
   * @returns {Observable<any>}
   */
  addPickupWarehouseToOrder(dto: IAddPickupWarehouseToOrder) {
    return this.http.post(`${environment.apiUrl}/cart/pickupWarehouse`, dto);
  }
}
