import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { IInvoice } from '../../../../../contracts/invoice/iInvoice';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IInvoiceDto } from '../../../../../contracts/invoice/iInvoice-dto';
import { IInvoicePaymentOption, IPayInvoiceDto } from '../../../../../contracts/invoice/ipay-invoice-dto';
import { IInvoicePayloadResponse } from '../../../../../contracts/invoice/iinvoice-payload-response';

@Injectable()
export class InvoiceInformationService {
  private invoices$: BehaviorSubject<IInvoice[]> = new BehaviorSubject<
    IInvoice[]
  >([]);
  public invoices: Observable<IInvoice[]> = this.invoices$.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Get invoices for a user.
   *
   * @param {IInvoiceDto} params
   * @returns {Observable<IInvoicePayloadResponse>}
   */
  getInvoices(params: IInvoiceDto) {
    const queryString = require('query-string');
    const query = queryString.stringify(params);
    return this.http
      .get(`${environment.ATG_BASE_URL}/invoices?${query}`, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        map((res: IInvoicePayloadResponse) => {
          this.invoices$.next(res.items);
          return res;
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Pay invoices for a user.
   *
   * @param {IPayInvoiceDto} payInvoicesDto
   * @returns {Observable<IInvoice[]>}
   */
  payInvoices(payInvoicesDto: IPayInvoiceDto) {
    return this.http.post(
      `${environment.ATG_BASE_URL}/invoices/pay`,
      payInvoicesDto,
      {
        headers: new HttpHeaders()
          .set('accept', 'application/json')
          .set('Content-Type', 'application/json'),
      }
    );
  }

  /**
   * Get an invoice image.
   * @param {string} invoiceUrl
   * @returns {string}
   */
  getInvoiceImage(invoiceUrl: string) {
    return this.http.get(`${environment.referer}${invoiceUrl}`, {
      responseType: 'text',
    });
  }

  /**
   * Get invoice payment options.
   * @returns {IInvoicePaymentOption}
   */
  getInvoicePaymentOptions() {
    return this.http.get<IInvoicePaymentOption[]>(`${environment.ATG_BASE_URL}/invoices/invoicePaymentOptions`);
  }  
}
