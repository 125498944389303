import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IPlateIDReplacementDto } from '../../../contracts/plate-id-replacement/iplate-id-replacement-dto';
import { of } from 'rxjs';

@Injectable()
export class PlateIdReplacementService {

  constructor(private http: HttpClient) { }

  /**
   * Posts case to Salesforce.
   * @param {IPlateIDReplacementDto} replacementPlateIdDto
   * @returns {Observable<any>} 
   */
  postCase(replacementPlateIdDto: IPlateIDReplacementDto) {
    return this.http.post(`${environment.apiUrl}/placard/CustMachReplacementForm`, replacementPlateIdDto);
  }

  /**
   * Lookup serial number
   *
   * @param {string} serialNum
   * @returns {Observable<any>}
   */
  serialNumberLookup(serialNumber: string) {
    return this.http.post(`${environment.apiUrl}/placard/pvcLookup`, { serialNumber });
  }
}
