import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../service/layout.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ContentService } from '../../../service/content.service';
import { CollapseStack } from '../../../shared/collapse/collapse-stack';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
})
export class HamburgerComponent implements OnInit {
  isMaintenance$: Observable<boolean> = this.contentService.isMaintenance$.pipe(
    shareReplay()
  );
  public navCollapsed = true;

  constructor(
    public layoutService: LayoutService,
    private contentService: ContentService,
    private collapseStack: CollapseStack
  ) {}

  ngOnInit(): void {
    this.layoutService.navCollapsed$.subscribe((isNavCollapsed) => {
      this.navCollapsed = isNavCollapsed;
    });
  }

  openMainMenu(e: MouseEvent): void {
    // So click outside from auth container doesn't trigger and close it
    e.stopPropagation();
    this.layoutService.showMenuType('main');
  }

  closeMenu(e: MouseEvent): void {
    // So click outside from auth container doesn't trigger and close it
    e.stopPropagation();
    this.layoutService.showMenuType();
    this.layoutService.setAuthCollapsed(true);
    this.collapseStack.dismissAll();
  }
}
