import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  BasicWeather,
  HistoricSelection,
  Machine,
  MachineLocation,
  TemperatureUnitType,
  UnitType,
} from 'app/contracts/clearsky/machine/machine.dto';
import { MatDialog } from '@angular/material/dialog';
import { MachineBreadcrumbsDialogComponent } from '../../machine-breadcrumbs-dialog/machine-breadcrumbs-dialog.component';
import { DatePipe } from '@angular/common';
import { Observable, map } from 'rxjs';
import { ClearskyService } from '../../../../clearsky.service';
import { BingMapsService } from '../../../../../service/bing-maps.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WidgetService } from '../../../../widget.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import * as dayjs from 'dayjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { UserEnvironments } from 'app/contracts/user/iuser';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-location-widget',
  templateUrl: './location-widget.component.html',
  styleUrls: ['location-widget.component.scss'],
})
export class LocationWidgetComponent implements OnInit {
  @ViewChild('map') map: ElementRef;
  @Input() machine: Machine;
  historic = HistoricSelection.MOST_RECENT;
  historicMapping = {
    [HistoricSelection.HOURS_24]: 1,
    [HistoricSelection.DAYS_7]: 7,
    [HistoricSelection.DAYS_14]: 14,
    [HistoricSelection.DAYS_31]: 31,
    [HistoricSelection.DAYS_90]: 90,
  };
  isMostRecent: boolean;
  formattedGpsTimestamp: string | undefined | null;
  weather$: Observable<BasicWeather | null>;
  unit: UnitType = UnitType.IMPERIAL; // Todo: Update once unit is hooked up into currentUser
  isImperial: boolean;
  widgetName = MachineWidgets.MACHINE_LOCATION;
  displayName = MachineWidgetsDisplay.machineLocation;
  ocids: OcidItems = {};
  historicData: MachineLocation[] = [];
  private widgets: string[];
  unitOfTemperature$: Observable<TemperatureUnitType>;
  temperatureUnitType = TemperatureUnitType;

  constructor(
    private clearskyService: ClearskyService,
    private widgetService: WidgetService,
    private bingMaps: BingMapsService,
    private localization: LocalizationService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar,
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    this.unitOfTemperature$ = this.currentUserService.getUser().pipe(
      untilDestroyed(this),
      map(user => user.unitOfTemperature ?
        user.unitOfTemperature : (user.erpEnvironment === UserEnvironments.AA ?
          this.temperatureUnitType.FAHRENHEIT : this.temperatureUnitType.CELSIUS
        )
      )
    );
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.site-label',
        'clearsky.last-label',
        'clearsky.location-temp-label',
        'clearsky.there-location-machine-label',
        'clearsky.f-label',
        'clearsky.c-label',
        'global.close',
        'clearsky.last-label',
        'clearsky.no-data-label',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.checkIsMostRecent();
    this.updateHistoricData();
    this.formattedGpsTimestamp = this.machine.loc
      ? this.datePipe.transform(this.machine.loc.ct, 'medium')
      : undefined;
    this.weather$ = this.clearskyService.getLocalWeather(
      this.machine,
      this.unit
    );
    this.isImperial = this.unit === UnitType.IMPERIAL;

    // Get current widgets
    this.clearskyService.currentMachineWidgets$.subscribe(
      (widgets) => (this.widgets = widgets)
    );
  }

  /**
   * Update map based on historic selection.
   * @param historic
   */
  onHistoricChange(historic: string): void {
    this.historic = historic;
    this.checkIsMostRecent();
    this.updateHistoricData();

    // If no historic data, show dialog
    if (!this.isMostRecent && !this.historicData.length) {
      const lastTimestamp = this.machine.locT90d
        ? this.machine.locT90d[0].ct
        : this.machine.loc && this.machine.loc.ct;
      let msg = `${this.ocids['clearsky.no-data-label']}.`;
      if (lastTimestamp) {
        msg = ` ${this.ocids['clearsky.last-label']}: ${dayjs(
          lastTimestamp
        ).format('M/D/YYYY h:m:s A')}`;
      }

      this.snackbar.open(msg, this.ocids['global.close'], {
        duration: 7500,
        verticalPosition: 'top',
      });
    }
  }

  private updateHistoricData(): void {
    // Yes so let's grab all historic in past given days
    const hisLoc = this.machine.locT90d ?? [];
    const timestamp = dayjs().subtract(
      this.historicMapping[this.historic],
      'days'
    );
    this.historicData = hisLoc.filter((loc) =>
      dayjs(loc.ct).isAfter(timestamp)
    );
  }

  /**
   * Expand current historic map in modal
   */
  expandMap(): void {
    this.dialog.open(MachineBreadcrumbsDialogComponent, {
      data: {
        machine: this.machine,
        historicData: this.historicData,
        historic: this.historic,
      },
      panelClass: ['clearsky-dialog'],
      width: '90vw',
      height: '70vh',
      autoFocus: false,
    });
  }

  /**
   * On activate local weather widget.
   */
  onActivateWeatherWidget(): void {
    if (this.widgets.includes(MachineWidgets.LOCAL_WEATHER)) {
      this.widgetService.scrollToWidget(MachineWidgets.LOCAL_WEATHER);
    } else {
      this.clearskyService.addMachineWidget(MachineWidgets.LOCAL_WEATHER);
    }
  }

  /**
   * Get directions for machine.
   */
  onGetDirections(): void {
    this.machine.loc &&
      this.bingMaps.getDirections(this.machine.loc.lat, this.machine.loc.lng);
  }

  /**
   * Check on most recent condition.
   * @private
   */
  private checkIsMostRecent(): void {
    this.isMostRecent = this.historic === HistoricSelection.MOST_RECENT;
  }
}
