// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-adjustments h1,
.search-adjustments h3 {
  font-weight: normal !important;
  margin: 0px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlYXJjaC1hZGp1c3RtZW50cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTs7RUFFRSw4QkFBQTtFQUNBLHNCQUFBO0FBQUoiLCJmaWxlIjoic2VhcmNoLWFkanVzdG1lbnRzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnNlYXJjaC1hZGp1c3RtZW50cyB7XHJcbiAgaDEsXHJcbiAgaDMge1xyXG4gICAgZm9udC13ZWlnaHQ6IG5vcm1hbCAhaW1wb3J0YW50O1xyXG4gICAgbWFyZ2luOiAwcHggIWltcG9ydGFudDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/search-adjustments/search-adjustments.component.scss"],"names":[],"mappings":"AACE;;EAEE,8BAAA;EACA,sBAAA;AAAJ;AACA,gdAAgd","sourcesContent":[".search-adjustments {\r\n  h1,\r\n  h3 {\r\n    font-weight: normal !important;\r\n    margin: 0px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
