import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IBroker } from '../../../../contracts/commerce/ibroker';
import { ICodeAndDesc } from '../../../../contracts/commerce/icode-and-desc';
import { IShipVias } from '../../../../contracts/commerce/iship-vias';
import { INavMenu, IUserNavMenus } from '../../../../contracts/user/inav-menu';
import { IUser } from '../../../../contracts/user/iuser';
import { CartService } from '../../../../service/cart/cart.service';
import { ShippingService } from '../../../../service/cart/shipping.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { MenuService } from '../../../../service/user/menu.service';
import { UserAddressesService } from '../../../../service/user/user-addresses.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { customValidation } from '../../../../shared/password-validator.directive';
import { TemperatureUnitType, UnitType } from 'app/contracts/clearsky/machine/machine.dto';

export interface menuByKey {
  uxKey?: INavMenu;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-profile',
  styleUrls: [
    './account-profile.component.scss',
    '../../user-registration/user-registration.component.scss',
  ],
  templateUrl: './account-profile.component.html',
})
export class AccountProfileComponent implements OnInit {
  platformBrowser = false;
  user!: IUser;

  personalInformation!: UntypedFormGroup;
  addressInformation!: UntypedFormGroup;
  protectedInformation!: UntypedFormGroup;
  defaultInformation!: UntypedFormGroup;
  defaultStoreId!: UntypedFormControl;
  editSecurityQ = false;
  editPassword = false;

  ocids: OcidItems = {};
  shippingInstructionOptions!: {}[];
  shipViaTruckOptions!: IShipVias;
  shipViaParcelOptions!: IShipVias;
  orderTypeOptions!: ICodeAndDesc[];
  freightPaymentMethodsOptions!: {}[];
  brokerOptions!: IBroker[];
  countryOptions!: INavMenu;
  stateProvinceOptions!: menuByKey;
  securityQuestionOptions!: INavMenu;
  userRoleOptions!: INavMenu;
  accountLanguageOptions!: INavMenu;

  addressValidated = true;
  instructionDesc: {} = {};
  instructionsHelpText = '';
  passwordHide = {
    current: true,
    new: true,
    confirm: true,
  };
  unitsofMeasure = [
    {
      ocid: 'global.metric',
      value: UnitType.METRIC,
    },
    {
      ocid: 'global.imperial',
      value: UnitType.IMPERIAL,
    },
  ];

  unitsofTemperature = [
    {
      ocid: 'global.fahrenheit-label',
      value: TemperatureUnitType.FAHRENHEIT,
    },
    {
      ocid: 'global.celsius-label',
      value: TemperatureUnitType.CELSIUS,
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private _formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private currentUserService: CurrentUserService,
    private userAddressService: UserAddressesService,
    private shippingService: ShippingService,
    private cartService: CartService,
    private localization: LocalizationService,
    private menuService: MenuService,
    private confirmationService: ConfirmationDialogsService,
    private ngZone: NgZone,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.notificationService.reset();

    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    combineLatest([
      this.localization.getOCIDs([
        'account.email-info-box',
        'account.security-question-info-box',
        'account.update-password',
        'account.update-security-question',
        'account-profile.confirm-new-password',
        'account-profile.current-password',
        'account-profile.new-password',
        'account-profile.password-message-1',
        'address-validation.message-7',
        'broker.hint-text',
        'buy.shipping-options-primary',
        'buy.shipping-options-secondary',
        'global.order-type-conventional',
        'global.order-type-economy',
        'global.order-type-priority',
        'profile.account-address-hint-text',
        'profile.account-information',
        'profile.account-number-hint-text',
        'profile.account-security',
        'profile.company-contact-name-label',
        'profile.jlg-customer-number',
        'profile.update-security-question-message',
        'profile.user-address',
        'profile.user-address-hint-text',
        'profile.user-shipping-defaults',
        'profile.unit-measure-info',
        'shipping.broker-label',
        'shipping.freight-billing-message',
        'shipping-instructions.back-order-info',
        'shipping-instructions.complete-info',
        'shipping-instructions.partial-complete-info',
        'unit-measure.field-label',
        'profile.unit-of-temperature-label'
      ]),
      this.menuService.menus$,
    ])
      .pipe(
        mergeMap(([ocids, menus]) => {
          if (ocids && menus) {
            this.instructionDesc = {};
            this.instructionDesc = {
              'PARTIAL & B/O': this.ocids[
                'shipping-instructions.back-order-info'
              ],
              COMPLETE: this.ocids['shipping-instructions.complete-info'],
              'PARTIAL & COMPLETE': this.ocids[
                'shipping-instructions.partial-complete-info'
              ],
            };
            return this.menuService.getMenuByUxKey(
              'user-defaults-shipping-instructions'
            );
          }
          return of(null);
        })
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.shippingInstructionOptions = [];
          this.user.appConfig.validShippingInstructions
            .split(',')
            .forEach((instruction: string) => {
              const instIndex = menu.childMenus.findIndex(
                (subMenu: INavMenu) => subMenu.uxKey === instruction
              );
              this.shippingInstructionOptions.push(menu.childMenus[instIndex]);
            });
          this.instructionsHelpText = '';
          this.shippingInstructionOptions.forEach(
            (instruction: INavMenu, index: number) => {
              this.instructionsHelpText +=
                instruction.label +
                ' - ' +
                this.instructionDesc[instruction.uxKey] +
                (index != this.shippingInstructionOptions.length ? '\n\n' : '');
            }
          );
        }
      });

    combineLatest([
      this.currentUserService.userSubject,
      this.menuService.menus$,
    ])
      .pipe(
        mergeMap((result: [IUser, IUserNavMenus]) => {
          const user: IUser = result[0];
          const menus: IUserNavMenus = result[1];
          // If user is found, try and get the default shipping address
          if (user) {
            this.user = user;
            this.setForms();
            if (menus) {
              // get the correct country menu based on user account type
              if (user.accountType == 'Retail') {
                return this.menuService.getMenuByUxKey('retailCountries');
              } else {
                return this.menuService.getMenuByUxKey('countries');
              }
            }
          }
          return of(null);
        })
      )
      .subscribe({
        next: (menu: INavMenu) => {
          if (menu) {
            this.countryOptions = menu;
            this.stateProvinceOptions = {};
            this.countryOptions.childMenus.forEach((menu: INavMenu) => {
              this.stateProvinceOptions[menu.uxKey] = menu.childMenus;
            });
          }
        },
        error: (error) => this.handleError(error)
      });
    // Subscribe to user changes for the purpose of getting default brokers
    // if the user has appconfig.showBrokers.
    this.currentUserService.userSubject
      .pipe(
        mergeMap((user: IUser) => {
          if (user) {
            return user.appConfig.showBroker
              ? this.shippingService.getBrokers()
              : of(null);
          }
          return of(null);
        })
      )
      .subscribe((brokers: IBroker[]) => {
        this.brokerOptions = brokers;
      });

    // Get Ship Via Options
    this.shippingService.getAvailShipVias().subscribe({
      next: (shipVias: IShipVias[]) => {
        const primaryShipVias = shipVias.find(
          (shipViaOptions: IShipVias) =>
            shipViaOptions.freightType === 'Primary'
        );
        const secondaryShipVias = shipVias.find(
          (shipViaOptions: IShipVias) =>
            shipViaOptions.freightType === 'Secondary'
        );
        if (primaryShipVias) {
          this.shipViaParcelOptions = primaryShipVias;
        }
        if (secondaryShipVias) {
          this.shipViaTruckOptions = secondaryShipVias;
        }
      },
      error: (error) => this.handleError(error)
    });
    // Get the available sales types a user can use to select their default sales type.
    this.cartService.getSalesTypes().subscribe({
      next: (salesTypes: ICodeAndDesc[]) => {
        this.orderTypeOptions = salesTypes;
      },
      error: (error) => this.handleError(error)
    });
    this.menuService.menus$
      .pipe(
        mergeMap((menus: IUserNavMenus) =>
          menus
            ? combineLatest([
              this.menuService.getMenuByUxKey('freight-payment-methods'),
              this.menuService.getMenuByUxKey('account-security-questions'),
              this.menuService.getMenuByUxKey('account-marketing-roles'),
              this.menuService.getMenuByUxKey('account-languages')
            ])
            : of(null)
        )
      )
      .subscribe((menus: INavMenu[]) => {
        if (menus) {
          const freightPaymentMethods: INavMenu = menus[0];
          const accountSecurityQuestions: INavMenu = menus[1];
          const accountMarketingRoles: INavMenu = menus[2];
          const accountLanguages: INavMenu = menus[3];
          if (freightPaymentMethods) {
            this.freightPaymentMethodsOptions = [];
            this.user.appConfig.validFreightTermsSecondary
              .split(',')
              .forEach((method: string) => {
                const methodIndex = freightPaymentMethods.childMenus.findIndex(
                  (subMenu: INavMenu) => subMenu.uxKey === method
                );
                this.freightPaymentMethodsOptions.push(
                  freightPaymentMethods.childMenus[methodIndex]
                );
              });
          }
          if (accountSecurityQuestions) {
            this.securityQuestionOptions = accountSecurityQuestions;
          }
          if (accountMarketingRoles) {
            this.userRoleOptions = accountMarketingRoles;
          }
          if (accountLanguages) {
            this.accountLanguageOptions = accountLanguages;
          }
        }
      });
  }

  setForms() {
    this.setPersonalInformation();
    this.setAddressInformation();
    this.setProtectedInformation();
    this.setDefaultInformation();
    this.setDefaultStoreId();
  }

  updateInformation(
    personalInfo: UntypedFormGroup,
    addressInfo: UntypedFormGroup,
    protectedInfo: UntypedFormGroup,
    defaultInfo: UntypedFormGroup,
    defaultStoreId: UntypedFormControl
  ) {
    this.ngZone.run(() => {
      // Are the forms valid?
      if (
        personalInfo.valid &&
        addressInfo.valid &&
        protectedInfo.valid &&
        defaultInfo.valid &&
        defaultStoreId.valid
      ) {
        // Have any of the forms been changed?
        if (
          personalInfo.dirty ||
          addressInfo.dirty ||
          protectedInfo.dirty ||
          defaultInfo.dirty ||
          defaultStoreId.dirty
        ) {
          const newUserProfile = {
            firstName: this.truncateValue(
              personalInfo.get('firstName').value,
              40
            ),
            lastName: this.truncateValue(
              personalInfo.get('lastName').value,
              40
            ),
            email: personalInfo.get('email').value,
            locale: personalInfo.get('locale').value,
            unitOfMeasure: personalInfo.get('unitOfMeasure').value,
            unitOfTemperature: personalInfo.get('unitOfTemperature').value,
            marketingUserRole: personalInfo.get('marketingUserRole').value,
            defaultStoreId: defaultStoreId.value.toString(),
            defaultSalesType: defaultInfo.get('orderType').value,
            defaultShippingInstructions: defaultInfo.get('shippingInstructions')
              .value,
            defaultShipVia: defaultInfo.get('shipViaParcel').value,
            defaultShipVia2: defaultInfo.get('shipViaTruck').value,
            defaultFreight: defaultInfo.get('parcelPaymentMethod').value,
            defaultFreight2: defaultInfo.get('truckPaymentMethod').value,
            defaultBroker1: defaultInfo.get('primaryBroker').value,
            defaultBroker2: defaultInfo.get('secondaryBroker').value,
            homeAddress: {
              address1: this.truncateValue(
                addressInfo.get('address1').value
                  ? addressInfo.get('address1').value.toString()
                  : '',
                50
              ),
              address2: this.truncateValue(
                addressInfo.get('address2').value
                  ? addressInfo.get('address2').value
                  : '',
                50
              ),
              address3: this.truncateValue(
                addressInfo.get('address3').value
                  ? addressInfo.get('address3').value
                  : '',
                50
              ),
              city: addressInfo.get('city').value
                ? addressInfo.get('city').value.toString()
                : '',
              country: addressInfo.get('country').value
                ? addressInfo.get('country').value.toString()
                : '',
              state: addressInfo.get('state').value
                ? addressInfo.get('state').value
                : '',
              postalCode: this.truncateValue(
                addressInfo.get('zip').value
                  ? addressInfo.get('zip').value.toString()
                  : '',
                10
              ),
              phoneNumber: this.truncateValue(
                personalInfo.get('phone').value,
                30
              ),
              faxNumber: this.truncateValue(
                personalInfo.get('fax').value
                  ? personalInfo.get('fax').value
                  : '',
                30
              ),
            },
          };
          // NOTE: VALIDATION IS DONE ABOVE
          if (
            protectedInfo.controls.securityA.value !== '' &&
            protectedInfo.controls.password.value !== ''
          ) {
            // If both the recovery question/answer and the new password is given
            newUserProfile['recoveryQuestion'] =
              protectedInfo.controls.securityQ.value;
            newUserProfile['recoveryAnswer'] =
              protectedInfo.controls.securityA.value;
            newUserProfile['password'] =
              protectedInfo.controls.currentPassword.value;
            newUserProfile['newPassword'] =
              protectedInfo.controls.password.value;
          } else if (protectedInfo.controls.securityA.value !== '') {
            // If just the recovery question/answer has changed
            newUserProfile['recoveryQuestion'] =
              protectedInfo.controls.securityQ.value;
            newUserProfile['recoveryAnswer'] =
              protectedInfo.controls.securityA.value;
            newUserProfile['password'] =
              protectedInfo.controls.currentPassword.value;
          } else if (protectedInfo.controls.password.value !== '') {
            // If the new password is given
            newUserProfile['password'] =
              protectedInfo.controls.currentPassword.value;
            newUserProfile['newPassword'] =
              protectedInfo.controls.password.value;
          }
          // We're now validating all addresses except UK, IE, and EI. US uses UPS and the rest of the countries use DHL
          if (
            !this.addressValidated &&
            newUserProfile.homeAddress.country != 'UK' &&
            newUserProfile.homeAddress.country != 'IE' &&
            newUserProfile.homeAddress.country != 'EI'
          ) {
            this.notificationService.reset();
            this.userAddressService
              .isAddressValid(newUserProfile.homeAddress)
              .subscribe({
                next: (data) => {
                  // If this address is valid
                  if (data === 'valid') {
                    this.updateProfile(newUserProfile);
                  } else if (data) {
                    this.userAddressService
                      .confirmAddress(data, newUserProfile.homeAddress)
                      .subscribe((result) => {
                        if (result) {
                          this.addressValidated = true;
                          this.addressInformation
                            .get('address1')
                            .setValue(this.toTitleCase(result.addressLine));
                          this.addressInformation
                            .get('city')
                            .setValue(this.toTitleCase(result.city));
                          this.addressInformation
                            .get('state')
                            .setValue(result.state);
                          this.addressInformation
                            .get('zip')
                            .setValue(result.zip);
                        }
                      });
                  } else {
                    this.userAddressService
                      .confirmAddress(data, newUserProfile.homeAddress)
                      .subscribe((result) => {
                        if (result) {
                          this.addressValidated = true;
                          this.addressInformation
                            .get('address1')
                            .setValue(this.toTitleCase(result.addressLine));
                          this.addressInformation
                            .get('city')
                            .setValue(this.toTitleCase(result.city));
                          this.addressInformation
                            .get('state')
                            .setValue(result.state);
                          this.addressInformation
                            .get('zip')
                            .setValue(result.zip);
                        }
                      });
                  }
                },
                error: (error) => {
                  // status 400 is being used for DHL errors
                  if (error.error.httpStatus === 400) {
                    this.confirmationService.confirm(
                      this.ocids['address-validation.message-7'],
                      ''
                    );
                  } else {
                    this.handleError(error);
                  }
                }
              });
          } else {
            // If the address being submitted is not US or already validated, no need to validate, go ahead and update
            this.updateProfile(newUserProfile);
          }
        }
      } else if (this.platformBrowser) {
        if (!personalInfo.valid) {
          document.getElementById('personalInfo').scrollIntoView({
            behavior: 'smooth',
          });
        } else if (!addressInfo.valid) {
          document.getElementById('personalInfo').scrollIntoView({
            behavior: 'smooth',
          });
        } else if (!protectedInfo.valid) {
          document.getElementById('accountSecurity').scrollIntoView({
            behavior: 'smooth',
          });
        } else if (!defaultInfo.valid) {
          document.getElementById('userShippingDefaults').scrollIntoView({
            behavior: 'smooth',
          });
        }
        console.log('Forms are not valid.');
      }
    });
  }

  get securityInfoChanged() {
    if (
      this.protectedInformation.controls.securityQ.value ||
      this.protectedInformation.controls.securityA.value
    ) {
      return true;
    }
    return false;
  }

  updateProfile(newUserProfile) {
    this.notificationService.reset();
    this.currentUserService.updateUserProfile(newUserProfile).subscribe({
      next: (res: IUser) => {
        // Reset forms

        this.editPassword = false;
        this.editSecurityQ = false;
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      },
      error: (error) => this.handleError(error)
    });
  }

  setPersonalInformation() {
    this.personalInformation = this._formBuilder.group({
      firstName: [this.user.firstName, Validators.pattern(/^[a-zA-Z-\s.]*$/)],
      lastName: [this.user.lastName, Validators.pattern(/^[a-zA-Z-\s.]*$/)],
      locale: [this.user.locale],
      email: [this.user.email, Validators.email],
      unitOfMeasure: [
        this.user.unitOfMeasure
          ? this.user.unitOfMeasure
          : this.user.erpEnvironment == 'AA'
            ? UnitType.IMPERIAL
            : UnitType.METRIC,
      ],
      unitOfTemperature: [
        this.user.unitOfTemperature
          ? this.user.unitOfTemperature
          : this.user.erpEnvironment == 'AA'
            ? TemperatureUnitType.FAHRENHEIT
            : TemperatureUnitType.CELSIUS,
      ],
      marketingUserRole: [this.user.marketingUserRole],
      phone: [
        this.user.homeAddress
          ? this.user.homeAddress.phoneNumber
            ? this.user.homeAddress.phoneNumber
            : ''
          : '',
      ],
      fax: [
        this.user.homeAddress
          ? this.user.homeAddress.faxNumber
            ? this.user.homeAddress.faxNumber
            : ''
          : '',
        Validators.pattern(/^[0-9() +-]*$/),
      ],
    });
  }

  setProtectedInformation() {
    this.protectedInformation = this._formBuilder.group({
      login: [this.user.login],
      securityQ: [''],
      securityA: [''],
      currentPassword: [''],
      password: [
        '',
        [
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[!@#$%^&*])).+$/
          ),
          Validators.minLength(8),
        ],
      ],
      confirmPassword: [''],
    });
  }

  setAddressInformation() {
    this.addressInformation = this._formBuilder.group({
      address1: [
        this.user.homeAddress
          ? this.user.homeAddress.address1
            ? this.user.homeAddress.address1
            : ''
          : '',
      ],
      address2: [
        this.user.homeAddress
          ? this.user.homeAddress.address2
            ? this.user.homeAddress.address2
            : ''
          : '',
      ],
      address3: [
        this.user.homeAddress
          ? this.user.homeAddress.address3
            ? this.user.homeAddress.address3
            : ''
          : '',
      ],
      city: [
        this.user.homeAddress
          ? this.user.homeAddress.city
            ? this.user.homeAddress.city
            : ''
          : '',
      ],
      country: [
        this.user.homeAddress
          ? this.user.homeAddress.country
            ? this.user.homeAddress.country
            : ''
          : '',
      ],
      state: [
        this.user.homeAddress
          ? this.user.homeAddress.state
            ? this.user.homeAddress.state
            : ''
          : '',
      ],
      zip: [
        this.user.homeAddress
          ? this.user.homeAddress.postalCode
            ? this.user.homeAddress.postalCode
            : ''
          : '',
      ],
    });
  }

  setDefaultInformation() {
    this.defaultInformation = this._formBuilder.group({
      orderType: [this.user.defaultSalesType ? this.user.defaultSalesType : ''],
      shippingInstructions: [
        this.user.defaultShippingInstructions
          ? this.user.defaultShippingInstructions
          : '',
      ],
      shipViaParcel: [this.user.defaultShipVia ? this.user.defaultShipVia : ''],
      parcelPaymentMethod: [
        this.user.defaultFreight ? this.user.defaultFreight : '',
      ],
      primaryBroker: [this.user.defaultBroker1 ? this.user.defaultBroker1 : ''],
      shipViaTruck: [
        this.user.defaultShipVia2 ? this.user.defaultShipVia2 : '',
      ],
      truckPaymentMethod: [
        this.user.defaultFreight2 ? this.user.defaultFreight2 : '',
      ],
      secondaryBroker: [
        this.user.defaultBroker2 ? this.user.defaultBroker2 : '',
      ],
    });
  }

  setDefaultStoreId() {
    this.defaultStoreId = this._formBuilder.control(
      this.user.defaultStoreId ? this.user.defaultStoreId : ''
    );
  }

  showPasswordForm() {
    this.editPassword = true;
    // Add password validation only if user is editing their password
    this.protectedInformation.addValidators([customValidation()]);
    this.protectedInformation.updateValueAndValidity();
  }

  cancelForm() {
    this.notificationService.reset();
    this.editPassword = false;
    this.editSecurityQ = false;
    this.setForms();
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Method used to truncate a string to its character limit before passing it to update current user.
   *
   * @param {string} value
   * @param {number} limit
   *
   * @returns {string}
   */
  truncateValue(value: string, limit: number) {
    return value.substring(0, limit);
  }

  get password() {
    return this.protectedInformation.controls.password;
  }

  handleError(error) {
    let errors;
    try {
      errors = JSON.parse(error.error.detail);
    } catch (err) {
      errors = [error.error.title];
    }
    errors.forEach((item) =>
      this.notificationService.addError(item.errorSummary)
    );
  }
}
