import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CollapseStack {
  // Todo: Enhance this to be a CollapseRef of sorts (not Collapse or else recursion will break the directive)
  private _collapseRefs: any[] = [];

  constructor(@Inject(DOCUMENT) private _document: Document,
              @Inject(PLATFORM_ID) private platformId: string) { }

  /**
   +* Dismiss all collapsables that have an overlay.
   +*/
  dismissAll(): void {
    this._collapseRefs.forEach(ref => ref.toggle(false));
    this._deactivateOverlay();
  }

  /**
   +* Add a collapsable that has an overlay.
   +*
   +* @param collapse
   +*/
  add(collapse: any): void {
    this._collapseRefs.push(collapse);

    this._activateOverlay();
  }

  /**
   +* Remove a collapsable that has an overlay.
   +*
   +* @param collapse
   +*/
  remove(collapse: any): void {
    this._collapseRefs = this._collapseRefs.filter(c => c !== collapse);

    if (! this._collapseRefs.length) {
      this._deactivateOverlay();
    }
  }

  /**
   +* Activate all overlay related elements.
   +*
   +* @private
   +*/
  private _activateOverlay(): void {
    this.runOnBrowser(() => {
      this._document.body.classList.add('body-overlay-open')
    })
  }

  /**
   +* Hide all overlay related elements.
   +*
   +* @private
   +*/
  private _deactivateOverlay(): void {
    this.runOnBrowser(() => {
      this._document.body.classList.remove('body-overlay-open')
    })
  }

  /**
   * Only execute code on browser.
   * @param callback
   */
  runOnBrowser(callback: Function): void {
    if (isPlatformBrowser(this.platformId)) {
      callback();
    }
  }
}
