import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-jumbo-text-list',
  styleUrls: ['./jumbo-text-list.component.scss'],
  templateUrl: './jumbo-text-list.component.html',
})
export class JumboTextListComponent implements CartridgeInterface {
  @Input() data!: {
    backgroundImageAlt: string;
    backgroundImageSrcUrl: string;
    backgroundImageTitle: string;
    headerText: string;
    introText: string;
    sublayoutItems: {
      title: string;
      contentText: string;
    }[];
  };
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor() {}
}
