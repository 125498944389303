import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { WindowRefService } from 'app/service/window-ref/window-ref.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
})
export class ScannerComponent implements OnInit, OnDestroy {
  isReady = false;
  ocids: OcidItems;
  camera;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public dialogRef: MatDialogRef<ScannerComponent>,
    private localization: LocalizationService,
    private winRef: WindowRefService
  ) {}

  async ngOnInit(): Promise<void> {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    await SDCCore.configure({
      licenseKey: environment.scanditKey,
      libraryLocation:
        'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
      moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
    });
    // Create the data capture context.
    const context = await SDCCore.DataCaptureContext.create();

    // Try to use the world-facing (back) camera and set it as the frame source of the context. The camera is off by
    // default and must be turned on to start streaming frames to the data capture context for recognition.
    this.camera = SDCCore.Camera.default;
    await context.setFrameSource(this.camera);

    // The barcode capturing process is configured through barcode capture settings,
    // they are then applied to the barcode capture instance that manages barcode recognition.
    const settings = new SDCBarcode.BarcodeCaptureSettings();

    // The settings instance initially has all types of barcodes (symbologies) disabled. For the purpose of this
    // sample we enable a very generous set of symbologies. In your own app ensure that you only enable the
    // symbologies that your app requires as every additional enabled symbology has an impact on processing times.
    settings.enableSymbologies([
      SDCBarcode.Symbology.Code128,
      SDCBarcode.Symbology.Code39,
      SDCBarcode.Symbology.EAN8,
      SDCBarcode.Symbology.UPCE,
      SDCBarcode.Symbology.EAN13UPCA,
    ]);

    // Some linear/1D barcode symbologies allow you to encode variable-length data. By default, the Scandit
    // Data Capture SDK only scans barcodes in a certain length range. If your application requires scanning of one
    // of these symbologies, and the length is falling outside the default range, you may need to adjust the "active
    // symbol counts" for this symbology. This is shown in the following few lines of code for one of the
    // variable-length symbologies.
    const symbologySetting = settings.settingsForSymbology(
      SDCBarcode.Symbology.Code39
    );
    symbologySetting.activeSymbolCounts = [
      7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];

    // Create a new barcode capture mode with the settings from above.
    const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
      context,
      settings
    );
    // Disable the barcode capture mode until the camera is accessed.
    await barcodeCapture.setEnabled(false);

    // Register a listener to get informed whenever a new barcode got recognized.
    barcodeCapture.addListener({
      didScan: async (barcodeCapture, session) => {
        // Handle scanned data according to your needs here.
        // await barcodeCapture.setEnabled(false);
        // await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
        const barcode = session.newlyRecognizedBarcodes[0];

        if (isPlatformBrowser(this.platformId)) {
          const dataLayer = (this.winRef.nativeWindow.dataLayer =
            this.winRef.nativeWindow.dataLayer || []);
          dataLayer.push({
            event: 'barcodeScanned',
            scanSource: this.data.source,
            barcode: barcode.data,
          });
        }

        this.dialogRef.close(barcode.data);
      },
    });

    // To visualize the ongoing barcode capturing process on screen, set up a data capture view that renders the
    // camera preview. The view must be connected to the data capture context.
    const view = await SDCCore.DataCaptureView.forContext(context);

    // Connect the data capture view to the rendered HTML element.
    view.connectToElement(document.getElementById('data-capture-view'));

    // Add a control to be able to switch cameras.
    view.addControl(new SDCCore.CameraSwitchControl());

    // Add a barcode capture overlay to the data capture view to render the location of captured barcodes on top of
    // the video preview. This is optional, but recommended for better visual feedback.
    const barcodeCaptureOverlay =
      await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
        barcodeCapture,
        view,
        SDCBarcode.BarcodeCaptureOverlayStyle.Frame
      );
    const viewfinderSquare = new SDCCore.RectangularViewfinder(
      SDCCore.RectangularViewfinderStyle.Square,
      SDCCore.RectangularViewfinderLineStyle.Light
    );
    const viewfinderLine = new SDCCore.LaserlineViewfinder(
      SDCCore.LaserlineViewfinderStyle.Animated
    );
    await barcodeCaptureOverlay.setViewfinder(viewfinderLine);

    // Switch the camera on to start streaming frames.
    // The camera is started asynchronously and will take some time to completely turn on.
    await this.camera.switchToDesiredState(SDCCore.FrameSourceState.On);
    await barcodeCapture.setEnabled(true);
    this.isReady = true;
  }
  closeDialog() {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
  }
}