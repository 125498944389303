import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Machine } from 'app/contracts/clearsky/machine/machine.dto';
import { getBingMapInstance } from '../../../contracts/clearsky/machine/machine.map.config';
import { BingMapsService } from '../../../service/bing-maps.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-map',
  templateUrl: './machine-map.component.html',
})
export class MachineMapComponent implements AfterViewInit {
  @Input() machine: Machine;
  @ViewChild('map') map: ElementRef;
  bingMap: Microsoft.Maps.Map;

  constructor(private bingMaps: BingMapsService) {}

  ngAfterViewInit(): void {
    this.bingMaps.initialize().subscribe(() => {
      this.loadMap();
    });
  }

  /**
   * Load the map and subscribe to machine updates to draw pins.
   */
  loadMap() {
    if (!this.machine.loc) {
      return;
    }

    this.bingMap = getBingMapInstance(this.map.nativeElement, {
      showLocateMeButton: false,
      showZoomButtons: false,
      center: new Microsoft.Maps.Location(
        this.machine.loc.lat,
        this.machine.loc.lng
      ),
    });

    const center = this.bingMap.getCenter();

    // Create custom Pushpin
    const pin = new Microsoft.Maps.Pushpin(center, {
      title: this.machine.eid,
    });

    // Add the pushpin to the map
    this.bingMap.entities.push(pin);
  }
}
