export interface UserProfile {
  token_type: string;
  access_token: string;
  session_token: string;
  firstName: string;
  lastName: string;
  email: string;
}
export const RequireType = {
  billTo: 'billTo',
  shipTo: 'shipTo',
};

export enum AuthErrorCode {
  LOCKED = 'UAM0003',
  FORBIDDEN = 'E0000007',
  FORBIDDEN2 = 'E0000095',
  INVALID = 'E0000087',
  EXPIRED = 'E0000011',
  NEW_PASSWORD_INVALID = 'E0000080',
  PENDING = 'UAM0002',
  SUSPENDED_CUSTOMER = 'S0000001',
}
