import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserService } from '../../../../service/user/user.service';
import { ICustomerResult } from '../../../../contracts/user/icustomer-result';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { LayoutService } from '../../../../service/layout.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-csr-dialog',
  templateUrl: './csr-dialog.component.html',
  providers: [NotificationService],
})
export class CSRDialogComponent implements OnInit, OnDestroy {
  csrSearchForm: UntypedFormGroup;
  formInvalid = false;
  dataSource = new MatTableDataSource<ICustomerResult>([]);
  formSubmitted = false;
  ocids = {};

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CSRDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private layoutService: LayoutService,
    private changeDetector: ChangeDetectorRef,
    private usersService: UserService
  ) {}

  ngOnInit() {
    // Build form
    this.csrSearchForm = this._formBuilder.group({
      customerName: [''],
      address: [''],
      city: [''],
      state: [''],
      postalCode: [''],
      country: [''],
    });

    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });

    this.csrSearchForm.setValidators(this.atLeastOneValidator());
  }

  /**
   * Event emitted when searching for customers.
   */
  searchCustomer(form: UntypedFormGroup) {
    if (this.csrSearchForm.valid) {
      this.notificationService.reset();
      // search customers and return users
      this.usersService.searchCustomers(this.csrSearchForm.value).subscribe(
        (customers: ICustomerResult[]) => {
          this.dataSource.data = customers;
          this.formSubmitted = true;
          this.changeDetector.detectChanges();
        },
        (error) => {
          this.notificationService.addError(error.error.title);
          this.dataSource.data = [];
          this.changeDetector.detectChanges();
        }
      );
    } else {
      this.formInvalid = true;
    }
  }

  /**
   * Event emitted when selecting a customer.
   *
   * @param {ICustomerResult} customer
   */
  selectCustomer(customer: ICustomerResult) {
    this.layoutService.setAuthCollapsed(false);
    this.dialogRef.close(customer);
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }

  /**
   * Validate that at least one control is not empty.
   *
   * @returns {(controlGroup) => ({atLeastOneRequired: {text: string}} | null)}
   */
  private atLeastOneValidator = () => {
    return (controlGroup) => {
      const controls = controlGroup.controls;
      if (controls) {
        const theOne = Object.keys(controls).find(
          (key) => controls[key].value !== ''
        );
        if (!theOne) {
          return {
            atLeastOneRequired: {
              text: this.ocids['sign-in-modal.message-1'],
            },
          };
        }
      }
      return null;
    };
  };
}
