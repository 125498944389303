import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Machine } from '../../../../contracts/clearsky/machine/machine.dto';
import * as Highcharts from 'highcharts';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import * as dayjs from 'dayjs';
import More from 'highcharts/highcharts-more';
import Xrange from 'highcharts/modules/xrange';
import * as utc from 'dayjs/plugin/utc';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { ClearskyService } from '../../../clearsky.service';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

// xrange usage
More(Highcharts);
Xrange(Highcharts);

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-utilization-xrange',
  templateUrl: './asset-utilization-xrange.component.html',
})
export class AssetUtilizationXrangeComponent implements OnInit {
  @Input() machine: Machine;
  private chartEl: ElementRef;
  ocids: OcidItems = {};
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chartEl = content;
      this.createChart();
    }
  }
  showChart = false;
  private plot;
  private yAxisCategories: string[];
  private legend!: CSLegend;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.graphic-default'])
      .pipe(first())
      .subscribe();
    dayjs.extend(utc);
    dayjs.extend(objectSupport);

    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.yAxisCategories = (this.legend.d90d || [])
        .slice(0, 14)
        .map((day) => dayjs.utc(day).format('M/D'));

      this.showChart = !!(this.machine.miuOnT && this.machine.miuOnT.length);
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      mergeDeep(cloneDeep(BaseChartConfig), {
        chart: {
          type: 'xrange',
          height: 400,
        },
        xAxis: {
          min: 0,
          max: 24 * 60, // 24 hours in minutes
          tickInterval: 3 * 60, // 3 hour interval
          labels: {
            formatter: function () {
              return `${this.value / 60}:00`;
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          formatter: function () {
            const startDate = this.point.custom.s.format('H:mm');
            const endDate = this.point.custom.t.format('H:mm');
            return `${startDate} - ${endDate}`;
          },
        },
        yAxis: {
          categories: this.yAxisCategories,
          reversed: true,
          title: {
            text: undefined,
          },
        },
        series: [],
      })
    );

    this.setChartData();
  }

  /**
   * Set chart data series.
   * @private
   */
  private setChartData(): void {
    this.plot.addSeries({
      data: this.machine.miuOnT.reduce((prev, series) => {
        const yIndex = this.yAxisCategories.findIndex(
          (monthDay) => monthDay === dayjs.utc(series.ts).format('M/D')
        );

        if (yIndex === -1) return prev;

        // if there is no timesOn data for Y axis add one with 0 value.
        if (series.ton.length === 0) {
          prev.push({
            x: 0,
            x2: 0,
            y: yIndex,
            color: WidgetColors.orange,
            custom: {
              s: 0,
              e: 0,
            },
          });
        }
        series.ton.forEach((times) => {
          const start = dayjs.utc(times.s);
          const startXAxis = start.hour() * 60 + start.minute();
          const end = dayjs.utc(times.e);

          prev.push({
            x: startXAxis,
            x2: end.diff(start, 'minutes') + startXAxis,
            y: yIndex,
            color: WidgetColors.orange,
            custom: {
              s: start,
              e: end,
            },
          });
        });

        return prev;
      }, []),
    });
  }
}
