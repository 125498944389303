import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { IUser, UserEnvironments } from '../../../../../contracts/user/iuser';
import { INavMenu } from '../../../../../contracts/user/inav-menu';
import { MenuService } from '../../../../../service/user/menu.service';

import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { CartService } from '../../../../../service/cart/cart.service';
import { ICodeAndDesc } from '../../../../../contracts/commerce/icode-and-desc';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import { PartsOrdersComponent } from '../parts-orders/parts-orders.component';
import { of } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-parts-order-inquiry',
  templateUrl: './parts-order-inquiry.component.html',
  styleUrls: ['../../account-w2c/account-w2c.component.scss'],
})
export class PartsOrderInquiryComponent implements OnInit {
  ocids = {};
  dateFormat = '';
  orderStatusOptions: INavMenu = {};
  orderTypeOptions!: ICodeAndDesc[];
  isOrderTab = true;
  platformBrowser = false;
  isAA = false;
  pageSizeOptions: number[] = [10, 20, 50, 200];
  @ViewChild('returnsList') returnsList!: PartsOrdersComponent;
  @ViewChild('ordersList') ordersList!: PartsOrdersComponent;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService,
    private currentUserService: CurrentUserService,
    private menuService: MenuService,
    private cartService: CartService
  ) {}

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'parts-order-inquiry.customer-number-search-message',
        'parts-order-inquiry.please-select-message',
        'parts.parts-order-inquiry',
        'orderdetails.order-status',
        'orderdetails.order-number',
        'orderdetails.start-date',
        'orderdetails.end-date',
        'orderdetails.three-year-limit',
        'orderdetails.order-date',
        'orderdetails.ordered-by',
        'orderdetails.ordered',
        'order-history.search-text',
        'search.returns-text',
        'parts.parts-order-inquiry-aa'
      ])
      .subscribe();

    // Subscribe to menu data to get search filter dropdown values
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('order-status') : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.orderStatusOptions = menu;
        }
      });
    this.cartService.getSalesTypes().subscribe((salesTypes: ICodeAndDesc[]) => {
      this.orderTypeOptions = salesTypes;
    });

    // Subscribe to the current user, but only take one to prevent multiple unnecessary
    // triggers of the subscriptions. Determine if the user is an enterprise user and proceed.
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        // Set the user's default date format.
        this.dateFormat = user.appConfig.dateFormat;
        // Check if it's AA user and show the return tab
        this.isAA = user.erpEnvironment === UserEnvironments.AA;
      }
      // If the user is not an enterprise user or user is empty, return null.
      return of(null);
    });
  }

  switchTabs(isOrderTab: boolean): void {
    this.isOrderTab = isOrderTab;
  }

  onLoadReturns(): void {
    this.isOrderTab = false;
  }

  /**
   * Export order/return history based on the active tab
   * @param isOrder
   */
  export(isOrder: boolean): void {
    if (isOrder) {
      this.ordersList.exportOrders();
    } else {
      this.returnsList.exportReturns();
    }
  }
}
