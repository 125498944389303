import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-popular-categories',
  styleUrls: ['./popular-categories.component.scss'],
  templateUrl: './popular-categories.component.html',
})
export class PopularCategoriesComponent implements CartridgeInterface {
  @Input() data!: {
    popularCategories: {
      categoryName: string;
      url: string;
    }[];
    sectionHeading: string;
  };

  constructor() {}
}
