import {
  Inject,
  Injectable,
  PLATFORM_ID,
  ViewContainerRef,
} from '@angular/core';
import {
  CsDashboard,
  WidgetPostion,
  Widgets,
  WidgetsDisplay,
  WidgetWidth,
} from '../contracts/clearsky/dashboard/cs-dashboard.dto';
import { isPlatformBrowser } from '@angular/common';
import html2canvas from 'html2canvas';
import { BehaviorSubject, first, Observable, Subject } from 'rxjs';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { MatDialog } from '@angular/material/dialog';
import { PdfDownloadDialogComponent } from './shared/pdf-download-dialog/pdf-download-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private _scrollToWidget: Subject<string> = new Subject<string>();
  scrollToWidget$: Observable<string> = this._scrollToWidget.asObservable();
  ocids: OcidItems;
  progress$: Subject<number> = new BehaviorSubject(0);
  downloadPdfStarted$ = new Subject();
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    public dialog: MatDialog
  ) {
    this.translations();
  }

  translations() {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.diagnostic-label',
        'clearsky.def-remaining-label',
        'clearsky.hardware-metric-label',
        'clearsky.battery-monitoring-label',
        'clearsky.state-of-charge-label',
      ])
      .subscribe((ocids) => {
        this.ocids = ocids;
      });
  }

  /**
   * Inject widget on the fly.
   * @param container
   * @param widgetType
   * @param widgetTypes
   * @param inputs
   * @param outputs
   */
  async injectWidget(
    container: ViewContainerRef,
    widgetType: string,
    widgetTypes: { [key: string]: Promise<any> },
    inputs?: { [key: string]: unknown },
    outputs?: { [key: string]: unknown }
  ): Promise<void> {
    // Check to see if widget component exists
    if (!widgetTypes.hasOwnProperty(widgetType)) {
      throw new Error(
        'Widget ' +
        widgetType +
        ' does not have a component reference! Please add this in the WidgetItemComponent!'
      );
    }

    // Lazy load the component
    const component = await widgetTypes[widgetType];
    const instance: any = container.createComponent(component);
    // Here we pass in the @Input
    if (inputs) {
      Object.entries(inputs).forEach(([prop, value]) => {
        instance.setInput(prop, value);
        // instance[prop] = value;
      });
    }
    // Here was pass in the @Output
    if (outputs) {
      Object.entries(outputs).forEach(([prop, value]) => {
        instance.instance[prop] = value;
        // instance[prop] = value;
      });
    }
  }

  /**
   * Download widget view as PDF.
   */
  async downloadWidgetsPDF(
    charts: { name: string; element: Element }[],
    pdf: boolean,
    container: Element,
    isMobile: boolean,
    dashboard?: CsDashboard
  ): Promise<void> {
    this.downloadPdfStarted$.next(true);
    if (isPlatformBrowser(this.platformId)) {
      return import('jspdf').then(async (m) => {
        let progress = 0;
        const downloadDialog = this.dialog.open(PdfDownloadDialogComponent, {
          width: '250px',
          panelClass: ['clearsky-dialog'],
        });

        const doc = new m.jsPDF({
          unit: 'px',
        });
        const documentName = dashboard
          ? dashboard.name
          : 'Equipment Inventory: Widget Dashboard';

        if (pdf) {
          // Loop through current plotlys and inject them
          let lastDrawPosition = 0;
          const pdfPageHeight = doc.internal.pageSize.getHeight() - 15;

          const chartsObj = charts.reduce((prev, item) => {
            prev[item.name] = item;
            return prev;
          }, {});
          let sortedCharts;
          let filteredItems = [];
          if (isMobile) {
            sortedCharts = this.getMobileChartsConfiguration(chartsObj);
            let toggle = false;
            sortedCharts = sortedCharts.forEach((item) => {
              if (item.chart) {
                filteredItems.push({
                  chart: item.chart,
                  width: WidgetWidth.Small,
                  y: toggle ? WidgetPostion.Right : WidgetPostion.Left,
                });
                toggle = !toggle;
              }
            });
          }
          else {
            sortedCharts = this.getDesktopChartConfiguration(chartsObj);
            const smallerWidgets = this.getDesktopSmallerChartConfiguration(chartsObj);

            filteredItems = sortedCharts.filter((item) => item.chart);
            let toggle = false;
            smallerWidgets.forEach((item) => {
              if (item.chart) {
                filteredItems.push({
                  chart: item.chart,
                  width: WidgetWidth.Small,
                  y: toggle ? WidgetPostion.Right : WidgetPostion.Left,
                });
                toggle = !toggle;
              }
            });
          }
          let existingChart;
          let previousLastDrawPosition = 0;
          let toggled = false;
          for (const chart of filteredItems) {
            progress += parseInt((100 / filteredItems.length).toFixed(2));
            this.updateProgress(progress);
            try {
              const canvas = await html2canvas(chart.chart.element as HTMLElement, {
                scale: 2,  // Adjust scale as needed
                useCORS: true,
              });
              doc.setFontSize(14);
              const imageData = canvas.toDataURL('image/jpeg'); // Use JPEG format
              const imageProperties = doc.getImageProperties(imageData);
              const ratio = chart.width / imageProperties.width;
              const imageHeight = imageProperties.height * ratio;
              let nextDrawPosition = parseInt((lastDrawPosition + imageHeight + 20).toString());

              if (nextDrawPosition >= pdfPageHeight) {
                doc.addPage();
                toggled = false;
                if (chart.y === WidgetPostion.Right) {
                  toggled = true;
                }
                lastDrawPosition = 0;
                previousLastDrawPosition = 0;
              }
              if (toggled) {
                chart.y = chart.y === WidgetPostion.Right ? WidgetPostion.Left : WidgetPostion.Right;
                if (existingChart && existingChart.width === WidgetWidth.Small && existingChart.y === WidgetPostion.Left) {
                  existingChart.y = existingChart.y === WidgetPostion.Right ? WidgetPostion.Left : WidgetPostion.Right;
                }
              }
              
              doc.addImage(
                imageData,
                'JPEG',
                chart.y === WidgetPostion.Left ? 15 : chart.y + 25,
                lastDrawPosition + 30,
                imageProperties.width * ratio,
                imageHeight
              );
              if (
                (existingChart && existingChart.width === WidgetWidth.Small && existingChart.y === WidgetPostion.Left) ||
                (chart.y === WidgetPostion.Left && chart.width === WidgetWidth.Large)
              ) {
                lastDrawPosition = parseInt((lastDrawPosition + imageHeight + 20).toString());
                if (previousLastDrawPosition > lastDrawPosition) {
                  lastDrawPosition = previousLastDrawPosition;
                }
                previousLastDrawPosition = lastDrawPosition;
              } else {
                previousLastDrawPosition = parseInt((lastDrawPosition + imageHeight + 20).toString());
                if (chart.y === WidgetPostion.Right) {
                  if (previousLastDrawPosition > lastDrawPosition) {
                    lastDrawPosition = previousLastDrawPosition;
                  }
                }
              }
              existingChart = chart;
            } catch (error) {
              console.error('Error capturing chart:', error);
            }
          }
          // Save and download PDF
          doc.save(`${documentName}.pdf`);
        } else {
          try {
            const canvas = await html2canvas(container as HTMLElement, {
              scale: isMobile ? 2 : 5,  // Adjust scale as needed
              useCORS: true,
            });
            const anchor = document.createElement('a');
            const image = canvas.toDataURL('image/png');
            anchor.setAttribute('href', image);
            anchor.download = `${documentName}.png`;
            anchor.click();
            anchor.remove();
          } catch (error) {
            console.error('Error capturing container:', error);
          }
        }
        this.downloadPdfStarted$.next(false);
        downloadDialog.close();
      });
    }

    return Promise.resolve();
  }

  getDesktopSmallerChartConfiguration(chartsObj) {
    const widgets = [
      Widgets.FLEET_AVERAGE_AGE,
      Widgets.LAST_REPORTED,
      Widgets.IGNITION_STATUS,
      Widgets.STATE_OF_CHARGE,
      Widgets.FUEL_LEVEL,
      Widgets.DEF_REMAINING,
    ];

    return widgets.map((widget, index) => ({
      chart: chartsObj[widget],
      y: index % 2 === 0 ? WidgetPostion.Left : WidgetPostion.Right,
      width: WidgetWidth.Small,
    }));
  }

  getDesktopChartConfiguration(chartsObj) {
    const widgets = [
      Widgets.FLEET_OVERVIEW,
      Widgets.MODEL_TYPE,
      Widgets.MACHINE_IN_USE,
      Widgets.ENVELOPE_PROFILE_USE,
      Widgets.FLEET_AGE,
      Widgets.HARDWARE_REPORTING_METRIC,
      Widgets.ALERTS,
      Widgets.DTC,
      Widgets.BATTERY_MONITORING,
    ];

    return widgets.map(widget => ({
      chart: chartsObj[widget],
      y: WidgetPostion.Left,
      width: WidgetWidth.Large,
    }));
  }

  getMobileChartsConfiguration(chartsObj) {
    const widgets = [
      Widgets.LAST_REPORTED,
      Widgets.IGNITION_STATUS,
      Widgets.STATE_OF_CHARGE,
      Widgets.FUEL_LEVEL,
      Widgets.DEF_REMAINING,
      Widgets.FLEET_OVERVIEW,
      Widgets.MODEL_TYPE,
      Widgets.HARDWARE_REPORTING_METRIC,
      Widgets.ALERTS,
      Widgets.FLEET_AGE,
      Widgets.MACHINE_IN_USE,
      Widgets.DTC,
      Widgets.FLEET_AVERAGE_AGE,
      Widgets.ENVELOPE_PROFILE_USE,
      Widgets.BATTERY_MONITORING,
    ];

    return widgets.map((widget, index) => ({
      chart: chartsObj[widget],
      y: index % 2 === 0 ? WidgetPostion.Left : WidgetPostion.Right,
      width: WidgetWidth.Small,
    }));
  }

  /**
   * Scroll to widget by id on any widget list.
   * @param id
   */
  scrollToWidget(id: string): void {
    this._scrollToWidget.next(id);
  }

  /**
   * Update progress bar state for pdf download
   * @param val
   */
  updateProgress(val: number) {
    this.progress$.next(val);
  }
}
