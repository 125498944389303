import { Component, Input } from '@angular/core';
import { INavMenu } from '../../../../contracts/user/inav-menu';
import { LayoutService } from '../../../../service/layout.service';

@Component({
  selector: '[app-navigation-item]',
  templateUrl: './navigation-item.component.html'
})
export class NavigationItemComponent {

  @Input() item: INavMenu;
  isCollapsed: boolean = true;

  constructor(public layoutService: LayoutService) { }

  get hasChildMenus(): boolean {
    return !!(this.item.childMenus && this.item.childMenus.length);
  }

  onToggle(e): void {
    e.stopPropagation();
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * Identify child menu items by unique identifier.
   * @param index
   * @param item
   */
  identifyChild(index: number, item: INavMenu): string {
    return item.label;
  }

}
