import { Component, HostBinding, Input } from '@angular/core';

enum SubscriptionIconPosition {
  left = 'left',
  right = 'right',
}

@Component({
  selector: 'app-subscription-addon-card-icon',
  templateUrl: './subscription-addon-card-icon.component.html',
  styleUrls: ['./subscription-addon-card-icon.component.scss'],
})
export class SubscriptionAddonCardIconComponent {
  @Input() icon!: string;
  @Input() position!: SubscriptionIconPosition;
  @HostBinding('class.subscription-addon-card-icon-left') get dTop() {
    return this.position === SubscriptionIconPosition.left;
  }
  @HostBinding('class.subscription-addon-card-icon-right') get dBottom() {
    return this.position === SubscriptionIconPosition.right;
  }
}
