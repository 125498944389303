import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';

const nextRoutes = ['/warranty-safety/safety-service-bulletins-new'];

export const appRouteResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  if (nextRoutes.some((r) => state.url.startsWith(r))) {
    location.href = state.url;
  }

  return true;
};
