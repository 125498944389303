import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorDisplayingInfoComponent } from './paginator-displaying-info.component';

@NgModule({
  declarations: [PaginatorDisplayingInfoComponent],
  exports: [PaginatorDisplayingInfoComponent],
  imports: [CommonModule],
})
export class PaginatorDisplayingInfoModule {}
