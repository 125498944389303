import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { mergeDeep } from '../../../../shared/deep-merge';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { BaseChartConfig } from 'app/contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import { ClearskyService } from '../../../clearsky.service';
import { CSLegend } from '../../../../contracts/clearsky/clearsky-legend';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

// Add heatmap module
HighchartsHeatmap(Highcharts);

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-machine-in-use',
  templateUrl: './asset-machine-in-use.component.html',
})
export class AssetMachineInUseComponent implements OnInit, OnChanges {
  private chartEl: ElementRef;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chartEl = content;
      this.createChart();
    }
  }
  @Input() machine: Machine;
  @Input() historic: string = HistoricSelection.HOURS_24;
  showChart = false;
  ocids: OcidItems = {};
  private plot;
  private chartDataMap = {
    [HistoricSelection.HOURS_24]: this.set24HourChartData.bind(this),
    [HistoricSelection.DAYS_7]: this.setWeekChartData.bind(this),
    [HistoricSelection.DAYS_14]: this.setWeekChartData.bind(this),
    [HistoricSelection.DAYS_31]: this.set31DayChartData.bind(this),
    [HistoricSelection.DAYS_90]: this.set90DayChartData.bind(this),
  };
  private historicChartConfig = {
    [HistoricSelection.HOURS_24]: () => {
      return {
        chart: {
          height: 220,
        },
        legend: {
          symbolHeight: 100,
        },
        xAxis: {
          categories: this.hour24AxisConfig,
        },
        yAxis: {
          categories: [],
          title: null,
          visible: false,
          reversed: false,
          labels: {
            enabled: false,
          },
        },
      };
    },
    [HistoricSelection.DAYS_7]: this.getWeekChartConfig.bind(this),
    [HistoricSelection.DAYS_14]: this.getWeekChartConfig.bind(this),
    [HistoricSelection.DAYS_31]: () => {
      return {
        chart: {
          height: 600,
        },
        legend: {
          symbolHeight: 480,
        },
        xAxis: {
          categories: this.axis7Day0To6,
        },
        yAxis: {
          categories: this.weeksInMonth,
          title: null,
          visible: true,
          reversed: true,
          labels: {
            enabled: false,
          },
        },
      };
    },
    [HistoricSelection.DAYS_90]: () => {
      return {
        chart: {
          height: 600,
        },
        legend: {
          symbolHeight: 480,
        },
        yAxis: {
          categories: this.axisWeekMultiple,
          reversed: true,
          visible: true,
          title: null,
          labels: {
            enabled: true,
          },
        },
        xAxis: {
          categories: this.axis7Day0To6,
          labels: {
            enabled: true,
          },
        },
      };
    },
  };
  private chartData = () => {
    const maxDataPoint = Math.max(...this.historicData.map((i) => i.y));

    return {
      chart: {
        type: 'heatmap',
        plotBorderWidth: 1,
        marginTop: 70,
        marginBottom: 50,
      },
      colorAxis: {
        min: 0,
        max: maxDataPoint > 0 ? maxDataPoint : 25,
        minColor: '#fff',
        maxColor: WidgetColors.blue,
      },
      dataLabels: {
        enabled: true,
      },
      title: {
        text: undefined,
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 200,
        title: {
          text: 'Minutes',
          style: {
            fontWeight: 'normal',
          },
        },
      },
      xAxis: {
        opposite: true,
      },
      tooltip: {
        formatter: function () {
          return `Date: ${this.point.dateString}, Minutes: ${this.point.value}`;
        },
      },
      plotOptions: {
        series: {
          colorByPoint: true,
          dataLabels: {
            style: {
              color: 'contrast',
            },
          },
        },
      },
      series: [],
    };
  };
  private axisWeekMultiple: string[];
  private weeksInMonth: number[];
  private hour24AxisConfig: string[];
  private axisWeek: string[];
  private axis7Day0To6: string[];
  private legend: CSLegend;
  private historicData: { x: string; y: number }[] = [];

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.graphic-default'])
      .pipe(first())
      .subscribe();

    dayjs.extend(isBetween);
    dayjs.extend(weekOfYear);
    dayjs.extend(isoWeek);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.initChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.historic && !changes.historic.isFirstChange()) {
      this.initChart();
    }
  }

  /**
   * Init chart based on historic data.
   * @private
   */
  private initChart(): void {
    this.historicData = this.getHistoricData();
    this.showChart = !!this.historicData.length;
    this.updateAxisInfo();

    if (this.showChart) {
      this.drawChart();
    }
  }

  /**
   * Update axis information based on historic selection.
   * @private
   */
  private updateAxisInfo(): void {
    this.axisWeekMultiple = Array.from(
      new Set(
        this.historicData.map((i) =>
          dayjs.utc(i.x).startOf('week').format('M/D')
        )
      )
    );

    this.weeksInMonth = Array.from(
      new Set(this.historicData.map((i) => dayjs.utc(i.x).week()))
    );

    this.hour24AxisConfig =
      this.historic === HistoricSelection.DAYS_7 ||
      this.historic === HistoricSelection.DAYS_14
        ? // Make it go from midnight to midnight
          [
            '12AM',
            ...this.getNumberArray(11).map((num) => `${num + 1}AM`),
            '12PM',
            ...this.getNumberArray(11).map((num) => `${num + 1}PM`),
          ]
        : this.historicData.map((item) => dayjs.utc(item.x).format('hA'));

    this.axisWeek = Array.from(
      new Set(this.historicData.map((i) => dayjs.utc(i.x).format('dddd (M/D)')))
    );

    this.axis7Day0To6 = this.getNumberArray(7).map((i) =>
      this.todaysTimestamp.set('days', i).format('dddd')
    );
  }

  /**
   * Create the chart.
   * @protected
   */
  protected createChart(): void {
    const chartData = mergeDeep(cloneDeep(BaseChartConfig), this.chartData());
    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      chartData as unknown
    );
    this.setChartData();
  }

  /**
   * Draw the chart or update it.
   */
  drawChart(): void {
    // Does the chart already exist?
    if (this.plot && this.showChart) {
      this.setChartData();
    } else if (!this.showChart && this.plot) {
      this.plot.destroy();
    }
  }

  /**
   * Get chart data based on historic data.
   * @private
   */
  private setChartData(): void {
    // Remove old series first
    this.resetChartData();

    const chartData = mergeDeep(cloneDeep(BaseChartConfig), this.chartData());
    // Adjust config based on historic
    this.plot.update(
      mergeDeep(chartData, this.historicChartConfig[this.historic]())
    );

    // Add series
    this.chartDataMap[this.historic]();
  }

  /**
   * Remove series from existing chart.
   * @private
   */
  private resetChartData(): void {
    if (this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Chart configuration for 24 Hour.
   * @private
   */
  private set24HourChartData(): void {
    this.plot.addSeries({
      borderWidth: 1,
      data: this.historicData.map((item, index) => {
        return {
          x: index,
          y: 0,
          value: item.y,
          dataLabels: {
            enabled: true,
          },
          dateString: dayjs.utc(item.x).format('dddd M/D/YYYY'),
        };
      }),
    });
  }

  /**
   * Chart configuration for week-based charts.
   * @private
   */
  private setWeekChartData(): void {
    this.plot.addSeries({
      borderWidth: 1,
      data: this.historicData.map((item) => {
        return {
          x: this.hour24AxisConfig.findIndex(
            (axisIndex) => dayjs.utc(item.x).format('hA') === axisIndex
          ),
          y: this.axisWeek.findIndex(
            (axisIndex) => dayjs.utc(item.x).format('dddd (M/D)') === axisIndex
          ),
          value: item.y,
          dataLabels: {
            enabled: true,
          },
          dateString: dayjs.utc(item.x).format('dddd M/D/YYYY'),
        };
      }),
    });
  }

  /**
   * Chart configuration for 31 days.
   * @private
   */
  private set31DayChartData(): void {
    this.plot.addSeries({
      borderWidth: 1,
      data: this.historicData.map((item) => {
        const timestamp = dayjs.utc(item.x);
        const weekNumber = timestamp.week();
        const dayString = timestamp.format('dddd');

        // Find the x and y position
        const x = this.axis7Day0To6.findIndex((i) => i === dayString);
        const y = this.weeksInMonth.findIndex((i) => i === weekNumber);
        return {
          x,
          y,
          value: item.y,
          dataLabels: {
            enabled: true,
          },
          dateString: dayjs.utc(item.x).format('dddd M/D/YYYY'),
        };
      }),
    });
  }

  /**
   * Chart configuration for 90 days.
   * @private
   */
  private set90DayChartData(): void {
    this.plot.addSeries({
      borderWidth: 1,
      data: this.historicData.map((item) => {
        const timestamp = dayjs.utc(item.x);
        const x = this.axis7Day0To6.findIndex(
          (i) => i === timestamp.format('dddd')
        );

        return {
          y: this.axisWeekMultiple.findIndex(
            (i) => i === timestamp.startOf('week').format('M/D')
          ),
          x,
          value: item.y,
          dataLabels: {
            enabled: true,
          },
          dateString: dayjs.utc(item.x).format('dddd M/D/YYYY'),
        };
      }),
    });
  }

  /**
   * Get chart config for weekly charts (7 and 14 day).
   * @private
   */
  private getWeekChartConfig() {
    return {
      chart: {
        height: 600,
      },
      legend: {
        symbolHeight: 480,
      },
      xAxis: {
        categories: this.hour24AxisConfig,
      },
      yAxis: {
        categories: this.axisWeek,
        title: null,
        visible: true,
        reversed: true,
        labels: {
          enabled: true,
        },
      },
    };
  }

  /**
   * Get an array of a sequence of numbers.
   * @param size
   * @private
   */
  private getNumberArray(size: number): number[] {
    return [...Array(size).keys()];
  }

  /**
   * Get current historic data selection from machine data.
   */
  private getHistoricData(): { x: string; y: number }[] {
    switch (this.historic) {
      case HistoricSelection.HOURS_24:
        return (this.machine.miuH14d ?? []).slice(0, 24).map((y, index) => ({
          x: (this.legend.d14h ?? [])[index],
          y,
        }));
      case HistoricSelection.DAYS_7:
        return (this.machine.miuH14d ?? [])
          .slice(0, 7 * 24)
          .map((y, index) => ({
            x: (this.legend.d14h ?? [])[index],
            y,
          }));
      case HistoricSelection.DAYS_14:
        return (this.machine.miuH14d ?? [])
          .slice(0, 14 * 24)
          .map((y, index) => ({
            x: (this.legend.d14h ?? [])[index],
            y,
          }));
      case HistoricSelection.DAYS_31:
        return (this.machine.miuH90d ?? []).slice(0, 31).map((y, index) => ({
          x: (this.legend.d90d ?? [])[index],
          y,
        }));
      case HistoricSelection.DAYS_90:
        return (this.machine.miuH90d ?? []).map((y, index) => ({
          x: (this.legend.d90d ?? [])[index],
          y,
        }));
      default:
        return [];
    }
  }

  /**
   * Get today's timestamp.
   */
  get todaysTimestamp(): dayjs.Dayjs {
    return dayjs();
  }
}
