import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartService } from '../../../../service/cart/cart.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';

@Component({
  selector: 'app-consult-factory',
  templateUrl: './consult-factory.component.html'
})
export class ConsultFactoryComponent implements OnInit {
  @ViewChild('f') slForm: NgForm;
  ocids: {} = {};
  platformBrowser: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    public dialogRef: MatDialogRef<ConsultFactoryComponent>,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cartService: CartService
  ) {}
  consent: boolean;
  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.localization.OCIDs.subscribe(
      (ocids: {}) => {
        this.ocids = ocids;
      }
    );
  }
  onCheckConsent(form: NgForm) {
    if (form.valid) {
      if (this.consent) {
        this.dialogRef.close();
      } else {
        this.cartService.removeFromCart(this.data.item, 'Consult Factory Item');
        // set the mustRead flag to false on product
        this.dialogRef.close();
      }
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
