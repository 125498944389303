import { Injectable } from "@angular/core";
import { from, forkJoin, Observable, Observer } from "rxjs";

@Injectable()
export class ScriptsService {
  private scripts = {
    bingMaps: {
      name: 'bingMaps',
      src: 'https://www.bing.com/api/maps/mapcontrol?key=Ar2lPUWEkOMjWoY7a3rZBuns2GmWK3_Q4WjaJk3ldPHzRudCYRb0uX_IsGP4mBlx',
      loaded: false
    }
  };

  /**
   * Loads a script by its name.
   * @param {string} name 
   * @returns {Promise<any>}
   */
  loadScript(name: string) {
    return new Observable((observer: Observer<any>) => {
      // If the script is already loaded, set the status of the script as 'Already Loaded'
      // and resolve the promise.
      if (this.scripts[name].loaded) {
        observer.next({ script: name, loaded: true, status: 'Already Loaded' });
        observer.complete();
      } else {
        // Otherwise, create a script element, set the type, src, async, and defer values.
        const script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.async = true;
        script.defer = true;
        // Handle cross browser onload event.
        if (script.readyState) { // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              // Set the loaded property to true and status to loaded and resolve the promise.
              observer.next({ script: name, loaded: true, status: 'Loaded' });
              observer.complete();
            }
          };
        } else { // All others
          script.onload = () => {
            this.scripts[name].loaded = true;
            // Set the loaded property to true and status to loaded and resolve the promise.
            observer.next({ script: name, loaded: true, status: 'Loaded' });
            observer.complete();
          };
        }
        script.onerror = (error: any) => {
          // Set the loaded property to false and status to loaded and resolve the promise.
          observer.next({ script: name, loaded: false, status: 'Failed to load Bing Maps.' });
        }
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    })
  }
  
}