import { ElementRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, mergeMap, Observable } from 'rxjs';
import { OrderInquiryService } from '../endeca/cartridges/account/account-orders/parts-order-inquiry/order-inquiry.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
export interface IUrlState {
  previousUrl: string;
  currentUrl: string;
}

@Injectable()
export class ContentService {
  private actionPerforming: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public actionPerforming$: Observable<boolean> = this.actionPerforming;
  private urlState: BehaviorSubject<IUrlState> = new BehaviorSubject<IUrlState>(
    {
      previousUrl: '',
      currentUrl: '',
    }
  );
  public urlState$: Observable<IUrlState> = this.urlState;
  public isMaintenance$: Observable<boolean> = this.urlState$.pipe(
    map((urlState) =>
      urlState.currentUrl === '/maintenance' ||
      isPlatformBrowser(this.platformId)
        ? location.pathname === '/maintenance'
        : false
    )
  );

  constructor(
    private orderInquiryService: OrderInquiryService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  /**
   * Perform action.
   *
   * @return void
   */
  public perform() {
    this.actionPerforming.next(true);
  }

  /**
   * Finish action.
   *
   * @return void
   */
  public finish() {
    this.actionPerforming.next(false);
  }

  /**
   * Is spinner performing?
   *
   * @returns {boolean}
   */
  public isPerforming(): boolean {
    return this.actionPerforming.getValue();
  }

  /**
   * Return an array of years.
   *
   * @param {number} min
   * @param {number} max
   * @returns {string[]}
   */
  getTwoDigitYears(min: number, max: number): string[] {
    const years: string[] = [];

    for (let i = min; i <= max; i++) {
      years.push(i.toString().substr(-2));
    }

    return years;
  }

  /**
   * Scroll the user to top of screen.
   */
  public scrollUserToTop() {
    // Smooth scroll on most browsers
    try {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } catch (e) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Set the url state by receiving the current url and using the url that is set as the
   * current url which is now the previous url. Additionally, reset any request parameters
   * if the previous url is not the order details page, return details page, or parts order inquiry page.
   *
   * @param {string} currentUrl
   */
  setUrlState(currentUrl: string) {
    const urlsArray = ['order-details', 'order-inquiry', 'return-details'];

    this.urlState.next({
      previousUrl: this.urlState.getValue().currentUrl,
      currentUrl: currentUrl,
    });
    if (
      urlsArray.every(
        (url) => !this.urlState.getValue().previousUrl.includes(url)
      )
    ) {
      this.orderInquiryService.resetRequestParms(true);
      this.orderInquiryService.resetRequestParms(false);
    }
  }

  /**
   * Check whether cookie exists using cookie name
   *
   * @param {string} cname
   */
  getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) !== -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Return value of cookie
   *
   * @param {string} cname
   */
  getCookieValue(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split('; ');
    let res;
    cookies.forEach((val) => {
      if (val.indexOf(name) === 0) {
        res = val.substring(name.length);
      }
    });
    return res;
  }

  /**
   * Create cookies
   *
   * @param {string} cname
   * @param {string} cvalue
   * @param {string} date
   */
  createCookie(cname: string, cvalue: string, date?: string) {
    this.document.cookie = `${cname}=${cvalue}${
      date ? `;expires=${date}` : ''
    };path=/`;
  }

  /**
   * Deletes cookie
   *
   * @param {string} cname
   */
  deleteCookie(cname: string) {
    this.document.cookie = cname + '=; Max-Age=0';
  }

  /**
   * Compare values for sorting purposes
   *
   * @param {string | number} a
   * @param {string | number} b
   * @param {boolean} isAsc
   */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (a === b) {
      return 0;
    } else if (a == null) {
      return 1;
    } else if (b == null) {
      return -1;
    } else if (isAsc) {
      return a < b ? -1 : 1;
    } else {
      return a < b ? 1 : -1;
    }
  }

  /**
   * Check when element enters/leaves the browser viewport
   *
   * @element {ElemtRef}
   */
  checkIntersection(element: ElementRef): Observable<boolean> {
    return new Observable((observer) => {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          observer.next(entries);
        },
        { threshold: [0], rootMargin: '0px 0px -50px' }
      );
      if (element?.nativeElement) {
        intersectionObserver.observe(element.nativeElement);
      }

      return () => {
        intersectionObserver.disconnect();
      };
    }).pipe(
      mergeMap((entries: IntersectionObserverEntry[]) => entries),
      map((entry) => entry.isIntersecting),
      distinctUntilChanged()
    );
  }
}
