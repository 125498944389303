import {
  Component,
  Input,
  OnInit,
  PLATFORM_ID,
  Inject,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SchematicsSelectorComponent } from './schematics-selector/schematics-selector.component';
import { SchematicsHelpComponent } from './schematics-help/schematics-help.component';
import { MenuService } from '../../../service/user/menu.service';
import { INavMenu } from '../../../contracts/user/inav-menu';
import { CartService } from '../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../service/confirmation/confirmation-alert.service';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { WindowRefService } from 'app/service/window-ref/window-ref.service';

interface Schematics {
  equipmentType: string;
  img: string;
  imgActive: string;
  models: Models[];
}
interface Models {
  text: string;
  value: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-schematics',
  styleUrls: ['./schematics.component.scss'],
  templateUrl: './schematics.component.html',
})
export class SchematicsComponent
  implements OnInit, CartridgeInterface, OnDestroy {
  ocids = {};
  @Input() data!: {
    schematicsFrameHeight: string;
    '@error': string;
  };
  iframeUrlSanitized!: SafeResourceUrl;
  platformBrowser = false;
  selectedModel!: string;
  selectionCompleted = false;
  types: INavMenu = {};
  models: INavMenu = {};
  menuFetched = false;
  dataLayer = (this.winRef.nativeWindow.dataLayer =
    this.winRef.nativeWindow.dataLayer || []);
  timer = { started: 0, ended: 0 };

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      const result = e.data;
      if (e.origin.endsWith('jlg.com') || e.origin.endsWith('jerrdan.com')) {
        if (result.type == 'gtm') {
          let action, value;
          switch (result.event) {
            case 'changeFunction':
              action = 'Change Functions';
              value = result.value;
              break;
            case 'showHideClick':
              action = 'Show/Hide Components';
              value = '';
              break;
            case 'transparentModelClick':
              action = 'Transparent Model';
              value = '';
              break;
            case 'solidModelClick':
              action = 'Solid Model';
              value = '';
              break;
            case 'serviceManual':
              action = 'Service Manual';
              value = '';
              break;
            case '2dModels':
              action = '2D';
              value = '';
              break;
            case 'helpLink':
              action = 'How to use Schematics';
              value = '';
          }
          this.dataLayer.push({
            event: 'schematicsClick',
            schematicsAction: action,
            schematicsModel: this.selectedModel,
            schematicsValue: value,
          });
        }
        if (result.type == 'popup') {
          this.dialog.open(SchematicsHelpComponent, {
            width: '500px',
          });
        }
        if (result.type == 'addToCart') {
          this.cartService.addOneToCart(result.itemNum, +result.qty).subscribe({
            next: () => {
              this.alertService.addToCartConfirm('');
              this.ecommService.addToCart(
                result.itemNum,
                result.categoryName,
                '',
                result.qty,
                '3D Schematics'
              );
            },
            error: (error) => {
              this.confirmationService.confirm(
                '',
                this.ocids['equipment-information-iframe.invalid-item-message'],
                '',
                this.ocids['global.confirm']
              );
            }
          });
        }
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private sanitizer: DomSanitizer,
    private localization: LocalizationService,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private confirmationService: ConfirmationDialogsService,
    public dialog: MatDialog,
    private ecommService: EcommerceService,
    private menuService: MenuService,
    private winRef: WindowRefService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['equipment-information-iframe.invalid-item-message'])
      .subscribe();

    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('schematics') : of(null)
        )
      )
      .subscribe((menus: INavMenu) => {
        if (menus && !this.menuFetched) {
          this.types = menus;
          this.types.childMenus.forEach((menu: INavMenu) => {
            this.models[menu.uxKey] = menu.childMenus;
          });
          this.openSelector(false);
          this.menuFetched = true;
        }
      });
  }

  /**
   * Opens the schematics model selector
   *
   * @param {boolean} canCancel
   */
  openSelector(canCancel = true) {
    const dialogRef = this.dialog.open(SchematicsSelectorComponent, {
      width: '520px',
      panelClass: 'schematics-selector',
      data: {
        canCancel: canCancel,
        types: this.types.childMenus,
        models: this.models,
      },
    });

    dialogRef.afterClosed().subscribe((model?) => {
      if (model) {
        this.dataLayer.push({
          event: 'schematicModelSelect',
          schematicsModel: model.type + ' - ' + model.model + ' - ' + model.sn,
        });
        // send an event for the previous model viewed
        if (this.timer.started) {
          this.sendViewDuration();
        }
        this.timer.started = new Date().getTime();
        this.selectedModel = model.name;
        this.iframeUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
          `/schematics/3DModels/${model.path}.html`
        );
        this.selectionCompleted = true;
      }
    });
  }
  /**
   * Send the view duration of the model to GA
   *
   * @param {boolean} canCancel
   */
  sendViewDuration(): void {
    this.timer.ended = new Date().getTime();
    const duration = this.timer.ended - this.timer.started;
    const minutes = Math.floor(duration / 60000);
    const seconds = +((duration % 60000) / 1000).toFixed(0);
    this.dataLayer.push({
      event: 'schematicsModelTimer',
      schematicsModel: this.selectedModel,
      duration:
        seconds === 60
          ? minutes + 1 + ':00'
          : minutes + ':' + (seconds < 10 ? '0' : '') + seconds,
    });
  }
  ngOnDestroy(): void {
    if (this.timer.started) {
      this.sendViewDuration();
    }
  }
}
