import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from '../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../service/confirmation/confirmation-alert.service';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-i-frame',
  templateUrl: './i-frame.component.html',
})
export class IFrameComponent implements OnInit, CartridgeInterface {
  ocids = {};
  @Input() data!: {
    iframeSrcUrl: string;
    iframeFrameBorder: string;
    iframeHeight: string;
    '@error': string;
  };
  iframeUrlSanitized: SafeResourceUrl = '';
  platformBrowser = false;

  @HostListener('window:message', ['$event'])
  onMessage(e: MessageEvent) {
    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      const product = e.data;
      if (
        (e.origin.endsWith('jlg.com') ||
          e.origin.endsWith('jerrdan.com') ||
          e.origin.endsWith('smartequip.net')) &&
        product.type === 'addToCart'
      ) {
        this.cartService.addOneToCart(product.itemNum, +product.qty).subscribe({
          next: () => {
            this.alertService.addToCartConfirm('');

            // Send Add to Cart action to GA if the platform is browser
            const productList = e.origin.endsWith('smartequip.net')
              ? 'SmartEquip'
              : 'Interactive Manuals';
            if (this.platformBrowser) {
              this.ecommService.addToCart(
                product.itemNum,
                product.categoryName,
                '',
                product.qty,
                productList
              );
            }
          },
          error: () => {
            this.confirmationService.confirm(
              '',
              this.ocids['equipment-information-iframe.invalid-item-message'],
              '',
              this.ocids['global.confirm']
            );
          },
        });
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private sanitizer: DomSanitizer,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private confirmationService: ConfirmationDialogsService,
    private localization: LocalizationService,
    private ecommService: EcommerceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['equipment-information-iframe.invalid-item-message'])
      .subscribe();

    // Subscribe to query params in case if equipment-inventory with sn query param
    this.route.queryParams.pipe(distinctUntilChanged()).subscribe((params) => {
      if (this.router.url.startsWith('/equipment-information') && params.sn) {
        const url = this.data.iframeSrcUrl;

        this.iframeUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
          url.toString().includes('?')
            ? `${url}&sn=${params.sn}`
            : `${url}?sn=${params.sn}`
        );
      } else {
        this.iframeUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.data.iframeSrcUrl
        );
      }
    });
  }
}
