// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  color: #bdbdbd;
  margin-top: 1rem;
  font-style: italic;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vLWNoYXJ0LW1zZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGNBQUE7RUFDQSxnQkFBQTtFQUNBLGtCQUFBO0FBREYiLCJmaWxlIjoibm8tY2hhcnQtbXNnLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSBcIi4uLy4uLy4uLy4uLy4uL25vZGVfbW9kdWxlcy9AYW5ndWxhci9tYXRlcmlhbFwiIGFzIG1hdDtcblxuOmhvc3Qge1xuICBjb2xvcjogbWF0LmdldC1jb2xvci1mcm9tLXBhbGV0dGUobWF0LiRncmF5LXBhbGV0dGUsIDQwMCk7XG4gIG1hcmdpbi10b3A6IDFyZW07XG4gIGZvbnQtc3R5bGU6IGl0YWxpYztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/chart/no-chart-msg/no-chart-msg.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AADF;AACA,4hBAA4hB","sourcesContent":["@use \"../../../../../node_modules/@angular/material\" as mat;\n\n:host {\n  color: mat.get-color-from-palette(mat.$gray-palette, 400);\n  margin-top: 1rem;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
