// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latest-news-content-container {
  padding: 0 0.25em;
}
.latest-news-title {
  font-size: 1.5em;
  margin: 0.5em 0;
}
.latest-news-message {
  height: 6em;
  margin: 0px !important;
  overflow: hidden;
  transition: height 0.3s ease-out;
}
.latest-news-message._open {
  height: auto;
}
.latest-news-more {
  float: right;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxhdGVzdC1uZXdzLWl0ZW0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxpQkFBQTtBQUFKO0FBRUU7RUFDRSxnQkFBQTtFQUNBLGVBQUE7QUFBSjtBQUVFO0VBQ0UsV0FBQTtFQUNBLHNCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxnQ0FBQTtBQUFKO0FBQ0k7RUFDRSxZQUFBO0FBQ047QUFFRTtFQUNFLFlBQUE7QUFBSiIsImZpbGUiOiJsYXRlc3QtbmV3cy1pdGVtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxhdGVzdC1uZXdzIHtcclxuICAmLWNvbnRlbnQtY29udGFpbmVyIHtcclxuICAgIHBhZGRpbmc6IDAgLjI1ZW07XHJcbiAgfVxyXG4gICYtdGl0bGUge1xyXG4gICAgZm9udC1zaXplOiAxLjVlbTtcclxuICAgIG1hcmdpbjogLjVlbSAwO1xyXG4gIH1cclxuICAmLW1lc3NhZ2Uge1xyXG4gICAgaGVpZ2h0OiA2ZW07XHJcbiAgICBtYXJnaW46IDBweCAhaW1wb3J0YW50O1xyXG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcclxuICAgIHRyYW5zaXRpb246IGhlaWdodCAuM3MgZWFzZS1vdXQ7XHJcbiAgICAmLl9vcGVuIHtcclxuICAgICAgaGVpZ2h0OiBhdXRvO1xyXG4gICAgfVxyXG4gIH1cclxuICAmLW1vcmV7XHJcbiAgICBmbG9hdDpyaWdodDtcclxuICB9XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/latest-news/lates-news-item/latest-news-item.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;AAAJ;AAEE;EACE,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,gCAAA;AAAJ;AACI;EACE,YAAA;AACN;AAEE;EACE,YAAA;AAAJ;AACA,o7BAAo7B","sourcesContent":[".latest-news {\r\n  &-content-container {\r\n    padding: 0 .25em;\r\n  }\r\n  &-title {\r\n    font-size: 1.5em;\r\n    margin: .5em 0;\r\n  }\r\n  &-message {\r\n    height: 6em;\r\n    margin: 0px !important;\r\n    overflow: hidden;\r\n    transition: height .3s ease-out;\r\n    &._open {\r\n      height: auto;\r\n    }\r\n  }\r\n  &-more{\r\n    float:right;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
