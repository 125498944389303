import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { IUser } from '../../../contracts/user/iuser';
import {
  IFileList,
  IWeb2CaseDto,
} from '../../../contracts/web2case/iweb2case-dto';
import { ContentService } from '../../../service/content.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { Web2CaseService } from './web2case.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, first } from 'rxjs/operators';

export interface IOCIDAndValue {
  ocid: string;
  value: string;
  nextLevelOpts?: IOCIDAndValue[];
}

interface IWeb2CaseDtoHelper {
  customerRequestType: object;
  files: IFileList[];
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-web-2-case',
  templateUrl: './web2case.component.html',
  styleUrls: ['./web2case.component.scss'],
})
export class Web2CaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper) stepper!: MatStepper;
  ocids = {};
  user!: IUser;
  requestInfoForm: UntypedFormGroup = new UntypedFormGroup({});
  controlName = '';
  caseSubmitted = false;
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localizationService: LocalizationService,
    private contentService: ContentService,
    private notificationService: NotificationService,
    private userService: CurrentUserService,
    private web2caseService: Web2CaseService,
    private winRef: WindowRefService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Get OCIDs for page (including global)
    this.localizationService.OCIDs.pipe(distinctUntilChanged()).subscribe(
      (ocids) => {
        this.ocids = ocids;
      }
    );
    this.localizationService
      .getOCIDs([
        // Web2CaseComponent OCIDs
        'web2case.page-title',
        'web2case.thank-you-message',
        'web2case.req-info',
        'web2case.how-can-we-help',
        'web2case.how-can-we-help-hint',
        'web2case.provide-first-name-error',
        'web2case.provide-last-name-error',
        'web2case.provide-valid-phone-num-error',
        'w2c.view-request-label',
        // How can we help dropdown OCIDs
        'web2case.training',
        'web2case.machine-down',
        'web2case.ootb',
        'web2case.service',
        // Type of training dropdown OCIDs
        'web2case.type-of-training',
        'web2case.service-bench',
        'web2case.product-usage-training',
        'web2case.service-tech-training',
        // Type of service dropdown OCIDs
        'web2case.type-of-service',
        'web2case.repair-follow-up-req',
        'web2case.repair-req',
        'web2case.documentation-req',
        'web2case.quote-req',
        'web2case.technical-diagnostic-label',
        'web2case.warranty-req',
        'web2case.invoicing-req',
        'web2case.others',
        'web2case.programmed-parts',
        'web2case.reman-crawler-remote-control',
        'web2case.reman-power-module',
        // Parts questions dropdown OCIDs
        'web2case.parts-questions',
        'web2case.complaint',
        'web2case.delivery',
        'web2case.ole-inquiry',
        'web2case.order-id-plate',
        'web2case.order-id-note',
        'web2case.parts-data-research',
        'web2case.quote',
        'web2case.return',
        // Specify category dropdown OCIDs
        'web2case.specify-category',
        'web2case.incorrect-part',
        'w2c-late-delievery-label',
        'w2c-wrong-packaging',
        'w2c-damaged-packaging',
        'w2c.activate-part',
        'web2case.quantity-discrepancy',
        'web2case.quality-of-parts',
        'w2c.picture-message',
        // Aspect of delivery dropdown OCIDs
        'web2case.aspect-of-delivery',
        'web2case.order-shipment',
        'web2case.transport-info',
        // Service Category dropdown OCIDs
        'web2case.specify-service-category',
        'web2case.machine-functions',
        'web2case.machine-tech-chars',
        'web2case.special-utilization',
        // Documentation Category dropdown OCIDs
        'web2case.type-of-docs',
        'web2case.legal',
        'web2case.schematic',
        // Info request dropdown OCIDs
        'web2case.what-info-requesting',
        'web2case.availability',
        'web2case.lead-time',
        'web2case.part-num-identification',
        // Reason for return dropdown OCIDs
        'web2case.reason-for-return',
        'web2case.duplicate-order-entry',
        'web2case.damaged-part',
        'web2case.part-not-required',
        'web2case.incorrect-part-ordered',
        'web2case.incorrect-ident-of-part',
        'web2case.other-please-explain',
        // How can we provide assistance dropdown OCIDs
        'web2case.provide-assistance',
        'web2case.service-parts',
        'web2case.parts-only',
        // Primary cause dropdown OCIDs
        'web2case.primary-cause',
        // Secondary cause dropdown OCIDs
        'web2case.secondary-cause',
        // What aspect dropdown OCIDs
        'web2case.what-aspect',
        // Form field OCIDs
        'web2case.warranty-claim-num',
        'orderdetails.invoice-number',
        'web2case.machine-serial-number',
        'web2case.serial-number-failing-module',
        'web2case.co-replacement-module',
        'web2case.select-items-label',
        'web2case.machine-model-number',
        'web2case.customer-fleet-number',
        'web2case.machine-fault-code-desc',
        'web2case.engine-fault-code-desc',
        'web2case.req-description',
        'w2c-activate-part-description-text',
        'web2case.customer-po-num',
        'web2case.running-hours',
        'web2case.rpm-settings',
        'web2case.what-was-error',
        'orderdetails.order-number',
        'web2case.quantity-received',
        'web2case.add-more-parts',
        'shopping-list.remove-item',
        // Job Site Form Field OCIDs
        'web2case.job-site-info',
        'web2case.job-site-contact-name',
        'web2case.job-site-phone-number',
        'web2case.job-site-name',
        'web2case.job-site-street',
        'web2case.job-site-postal-code',
        'web2case.job-site-city',
        'web2case.job-site-country',
        // Shipping Address Field OCIDs
        'web2case.same-as-def-shipping-add',
        'web2case.default-address-missing-message',
        'shipping.change-shipping-link',
        'account.contact-number-label',
        // Description hints
        'web2case.define-model-and-tf',
        'web2case.please-mention',
        'web2case.provide-error-code-message',
        'web2case.provide-case-num',
        // Invoice question fields
        'web2case.provide-order-num-or-invoice-num',
        'web2case.jlg-order-num',
        // Attachment upload
        'web2case.upload-attachment',
        'web2case.browse',
        'web2case.no-file-selected',
        'web2case.add-additional-attachment',
        'web2case.upload-packaging-image',
        'web2case.upload-part-image',
        'web2case.add-additional-image',
        'web2case.file-format-message',
        'web2case.file-message-fail',
        'web2case.file-size-message',
        'web2case.file-error-message-large',
        'w2c.item-number-ordered-label',
        'w2c.item-number-received-label',
        'w2c.qty-ordered-label',
        'w2case.machine-number-label',
        'w2c-qty-damaged-label',
        'web2case.reman-crawler-remote-control-message',
        'web2case.reman-crawler-reman-power-message',
        'w2case.urgent-request-label',
        'w2case.urgent-callback-label'
      ])
      .pipe(first())
      .subscribe();
    this.userService.userSubject
      .pipe(distinctUntilChanged())
      .subscribe((user: IUser) => (this.user = user));
  }

  /**
   * Form control name emitted by the request info form when the form has been submitted.
   * @param {string} controlName
   */
  requestInfoFormSubmitted(controlName: string) {
    this.controlName = controlName;
    const caseType: string = this.requestInfoForm.get('howToHelp').value;
    let web2caseDto: IWeb2CaseDto = {
      CaseRecordType: 'CustomerRequestType', // Static
      CustomerRequestType: {
        Name: `${this.user.firstName} ${this.user.lastName}`,
        Email: this.user.email,
        Telephone: this.user.homeAddress?.phoneNumber,
        CustomerAccountNumber: this.user.customerNumber,
        CustomerAccountName: this.user.companyName,
        CaseType: caseType,
        CaseSubject: caseType,
      },
    };
    let dto: IWeb2CaseDtoHelper;
    switch (caseType) {
      case 'Training':
        dto = this.web2caseDto(this.getFormValue('trainingForm'));
        web2caseDto = {
          ...web2caseDto,
          CustomerRequestType: {
            ...web2caseDto.CustomerRequestType,
            ...dto.customerRequestType,
          },
        };
        break;
      case 'Machine Down':
        dto = this.web2caseDto(this.getFormValue('machineDownForm'));
        web2caseDto = {
          ...web2caseDto,
          CustomerRequestType: {
            ...web2caseDto.CustomerRequestType,
            ...dto.customerRequestType,
          },
        };
        break;
      case 'Out of the Box':
        dto = this.web2caseDto(this.getFormValue('ootbForm'));
        web2caseDto = {
          ...web2caseDto,
          CustomerRequestType: {
            ...web2caseDto.CustomerRequestType,
            ...dto.customerRequestType,
          },
        };
        break;
      case 'Service':
        dto = this.web2caseDto(this.getFormValue('serviceForm'));
        dto.customerRequestType['isUrgentRequest'] = !!dto.customerRequestType['isUrgentRequest'];
        web2caseDto = {
          ...web2caseDto,
          CustomerRequestType: {
            ...web2caseDto.CustomerRequestType,
            ...dto.customerRequestType,
          },
        };
        break;
      case 'Parts':
        dto = this.web2caseDto(this.getFormValue('partsForm'));
        web2caseDto = {
          ...web2caseDto,
          CustomerRequestType: {
            ...web2caseDto.CustomerRequestType,
            ...dto.customerRequestType,
          },
        };
        break;
      default:
        break;
    }
    if (dto.files) {
      web2caseDto['filelist'] = dto.files;
    }
    this.web2caseService.postCase(web2caseDto).subscribe({
      next: () => {
        this.notificationService.reset();
        this.caseSubmitted = true;

        setTimeout(() => {
          // reset the form and remove error state on submit success
          this.requestInfoForm.removeControl(this.controlName);
          this.requestInfoForm.reset();
          this.resetForm(this.requestInfoForm);
          // Send form success event to GA
          if (this.platformBrowser) {
            const dataLayer = (this.winRef.nativeWindow.dataLayer =
              this.winRef.nativeWindow.dataLayer || []);
            dataLayer.push({
              event: 'formEvent',
              formAction: 'Success',
              formName: 'Web2Case',
            });
          }

          this.stepper.next();
        }, 1);
      },
      error: (error) => {
        this.notificationService.addError(error.error.title);
        this.caseSubmitted = false;
      },
    });
  }

  /**
   * Gets the form value from the request info form group.
   * @param {string} formName
   */
  getFormValue(formName: string) {
    return (<UntypedFormGroup>this.requestInfoForm.get(formName)).value;
  }

  /**
   * resets the form and clears validation errors
   * @param {FormGroup} form
   */
  resetForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  /**
   * Assists in creating the web2case dto object. Takes the form value as a parameter,
   * loops over the properties and assigns them to the dto. This makes sure we get all
   * values from the form, including the nested form objects.
   * @param {object} objectValue
   * @returns {IWeb2CaseDtoHelper}
   */
  web2caseDto(objectValue: object) {
    // Create the basic dto.
    const dto: IWeb2CaseDtoHelper = {
      customerRequestType: {},
      files: null,
    };
    Object.keys(objectValue).forEach((key: string) => {
      const keyValue = objectValue[key];
      // Since the upload form contains values for the filelist property on the dto,
      // we need to manually pull it out.
      if (key === 'uploadForm') {
        const uploadInput = keyValue['uploadInput'];
        dto.files = uploadInput.length > 0 ? uploadInput : null;
      } else if (key === 'partRows') {
        <
          {
            PartNoOrdered: string;
            PartDescription: string;
            PartNoQtyOrdered: string;
          }[]
          >keyValue.forEach((value, index: number) => {
            // Only add the part row if it has values.
            if (value.PartNoOrdered || value.PartDescription || value.PartNoQtyOrdered) {
              dto.customerRequestType = {
                ...dto.customerRequestType,
                [`PartNo${index + 1}`]: value.PartNoOrdered,
                [`Part${index + 1}Description`]: value.PartDescription,
                [`PartNo${index + 1}Qty`]: value.PartNoQtyOrdered?.toString(),
                [`PartsNumberReceived`]: value.PartNoReceived,
                [`QtyReceived`]: value.PartNoQtyReceived?.toString(),
                [`QtyDamaged`]: value.PartNoQtyDamaged?.toString(),
              };
            }
          });
      } else {
        // If the current key value is of type object, we will call the web2caseDto
        // function again, passing in the object to pull out the properties.
        if (typeof keyValue === 'object') {
          const innerDto: IWeb2CaseDtoHelper = this.web2caseDto(keyValue);
          dto.customerRequestType = {
            ...dto.customerRequestType,
            ...innerDto.customerRequestType,
          };
          if (innerDto.files) {
            dto.files = innerDto.files;
          }
        } else {
          dto.customerRequestType = {
            ...dto.customerRequestType,
            [key]: keyValue ? keyValue.toString() : '',
          };
        }
      }
    });
    return dto;
  }

  ngOnDestroy() {
    if (this.platformBrowser) {
      // Remove partNumForPrice cookie when user leaves this form
      if (this.contentService.getCookie('partNumForPrice')) {
        this.contentService.deleteCookie('partNumForPrice');
      }
      if (this.contentService.getCookie('level2Selection')) {
        this.contentService.deleteCookie('level2Selection');
      }

      // Send form not finished event to GA if user leaves without submitting the form
      if (!this.caseSubmitted) {
        const dataLayer = (this.winRef.nativeWindow.dataLayer =
          this.winRef.nativeWindow.dataLayer || []);
        dataLayer.push({
          event: 'formEvent',
          formAction: 'Unfinished',
          formName: 'Web2Case',
        });
      }
    }
  }
}
