import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

const CURRENCY_SYMBOL_MAPPING = {
  BRL: 'R$',
  EUR: '€',
  GBP: '£',
  NZD: '$',
  AUD: '$',
  USD: '$',
};

@Pipe({ name: 'formatCurrency' })
export class FormatCurrencyPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  /**
   * Function that transforms the value given to the value specified based on the parameters passed to the function.
   * @param {string} value - the value being modified.
   * @param {string} currencyCode A string value either USD, BRL, etc.
   */
  transform(value: string, currencyCode: string | null) {
    // Suppress the currency or currency code from displaying by passing an empty string.
    let valueAsCurrency = this.decimalPipe.transform(value, '1.2-2');
    const currencySymbol =
      CURRENCY_SYMBOL_MAPPING[currencyCode] ?? currencyCode + ' ';

    // If the user currency code is BRL, set currency delimiter.
    if (currencyCode === 'BRL') {
      // Get the index of the decimal to replace with a comma after replacing any existing commas with decimals.
      const indexOfDec = valueAsCurrency?.indexOf('.') || 0;

      // If there are any commas in the currency value, replace them with decimals.
      if (valueAsCurrency?.includes(',')) {
        valueAsCurrency = valueAsCurrency?.replace(/,/g, '.');
      }

      // Lastly replace the decimal (that would otherwise separate the whole currency
      // value with the remaining currency value) with a comma.
      valueAsCurrency = this.replaceAt(valueAsCurrency, indexOfDec, ',');
    }

    return this.getFormattedCurrency(valueAsCurrency, currencySymbol);
  }

  /**
   * Function to perform logic on what format to display the currency.
   * @param {string} value - the value being modified.
   * @param {string} currencySymbol - currency symbol to be displayed.
   */
  getFormattedCurrency(value: string | null, currencySymbol: string) {
    return currencySymbol + value;
  }

  /**
   * Replaces a string with another string at a given index.
   * @param {string} originalValue
   * @param {number} index
   * @param {replacement} replacement
   */
  replaceAt(originalValue: string | null, index: number, replacement: string) {
    return (
      originalValue?.slice(0, index) +
      replacement +
      originalValue?.slice(index + replacement.length)
    );
  }
}
