import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-content-with-image-header',
  styleUrls: ['./content-with-image-header.component.scss'],
  templateUrl: './content-with-image-header.component.html',
})
export class ContentWithImageHeaderComponent implements CartridgeInterface {
  @Input() data!: {
    backgroundColor: string;
    btnLinkToTarget: string;
    btnLinkToUrl: string;
    btnText: string;
    imageAlt: string;
    imageSrcUrl: string;
    imageTitle: string;
    leftContentText: string;
    rightContentText: string;
  };
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor() {}
}
