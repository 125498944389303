import { IConsultFactory } from './iconsult-factory';
import { IPhantomItem } from './iphantom-item';
import { ICompetitiveItem } from './icompetitive-item';
import { ICustomerItem } from './icustomer-item';

export class IProduct {
  ARDeepLink?: string;
  id: string;
  orderable?: boolean;
  itemNotFound?: boolean;
  itemDescription?: string;
  longDescription?: string;
  erpEnvironment?: string;
  warehouseId: string;
  itemNumber?: string;
  itemDiscontinued?: boolean;
  existingItem?: boolean;
  newItem?: boolean;
  isSerialized?: boolean;
  salesLit?: boolean;
  itemClass?: string;
  commodityCode?: string;
  crossRefParts?: string[];
  displayItemNumber: string;
  calloutText?: string;
  weight?: string;
  returnable?: boolean;
  unitOfMeasure?: string;
  machineWidth?: string;
  machinePlatformHeight?: string;
  machineWorkingHeight?: string;
  hazmat?: boolean;
  unspsc?: string;
  competitiveItem?: boolean;
  phantomItem?: boolean;
  competitivePartNum?: string;
  industryItemDesc?: string;
  customerPart?: string;
  customerItemDesc?: string;
  customerItem?: boolean;
  valueClass?: string;
  itemActive?: boolean;
  directShipItem?: boolean;
  coreReqItem?: boolean;
  coreReturnType?: string;
  quoteWeight?: number;
  itemLength?: number;
  width?: number;
  height?: number;
  specialPackaging?: string;
  freightType?: string;
  item289?: string;
  serialNumRequired?: boolean;
  retroItem?: boolean;
  rebuiltItem?: boolean;
  orderTypeRequired?: boolean;
  noChargeItem?: boolean;
  consultFactoryItem?: boolean;
  supersedeItem?: boolean;
  supersedeItemNumber?: string;
  supersedeItemUrl?: string;
  shippable?: boolean;
  netItem?: boolean;
  standardCost?: number;
  marketingTitle?: string;
  metatagDescription?: string;
  metatagPageTitle?: string;
  messages?: {
    messageId: string;
    messageDesc: string;
  }[];
  consultFactory?: IConsultFactory;
  phantomItems?: IPhantomItem[];
  competitiveItems?: ICompetitiveItem[];
  customerItems?: ICustomerItem[];
  productType?: string;
}

export const ProductTypes = {
  MACHINE: 'machine',
  PART: 'part',
};
