import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IAddSupersededItemDto } from '../../../../../contracts/commerce/dto/iadd-superseded-item.dto';
import { IRemoveItemDto } from '../../../../../contracts/commerce/dto/iremove-item.dto';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { OktaAuthWrapper } from '../../../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { ConfirmationAlertService } from '../../../../../service/confirmation/confirmation-alert.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { ShoppingListComponent } from '../../shopping-list/shopping-list.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-superseded-warning',
  templateUrl: './superseded-warning.component.html',
  providers: [NotificationService],
})
export class SupersededWarningComponent implements OnInit {
  @Input() item: ICommerceItemWithCart;
  @Output() add: EventEmitter<IAddSupersededItemDto> =
    new EventEmitter<IAddSupersededItemDto>();
  @Output() remove: EventEmitter<IRemoveItemDto> =
    new EventEmitter<IRemoveItemDto>();
  isLoggedIn = false;
  public ocids: {} = {};
  public quantity: UntypedFormControl;
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    public dialog: MatDialog,
    private router: Router,
    private oktaAuth: OktaAuthWrapper,
    private localization: LocalizationService,
    private alertService: ConfirmationAlertService,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.quantity = new UntypedFormControl(this.item.quantity, [
      Validators.required,
      Validators.min(1),
    ]);
    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn.subscribe(
      (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    );
  }

  /**
   * Add to cart.
   */
  public onAddToCart() {
    if (this.quantity.valid) {
      this.add.emit({
        supersedeItem: this.item,
        supersededItemNumber: this.item.supersededItemNumber,
        supersededQuantity: +this.quantity.value,
      });
    }
  }

  /**
   * Add item to shopping list.
   */
  public onAddToShoppingList() {
    if (this.isLoggedIn) {
      // Pop open dialog
      if (this.quantity.valid) {
        this.dialog
          .open(ShoppingListComponent, {
            width: '380px',
            data: {
              items: [
                {
                  itemNumber: this.item.itemNumber,
                  quantity: +this.quantity.value,
                },
              ],
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              // Emit the event to remove the item from the shopping cart.
              this.remove.emit({
                item: this.item,
                showDialog: false,
              });
              // Alert the user that items have been added to their shopping list.
              this.alertService.addToShoppingList(
                this.item.productDisplayName
                  ? this.item.productDisplayName
                  : this.item.itemNumber
              );
              // Push FB Pixel AddToWishlist Conversion Event
              if (this.platformBrowser) {
                this.ecommService.addToWishlist(
                  this.item.itemNumber,
                  +this.quantity.value,
                  'Superseded Item'
                );
              }
            }
          });
      }
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.router.navigate(['/cart']);
          }
        });
    }
  }

  /**
   * Event emitted when removing superseded item.
   */
  public onRemove() {
    this.remove.emit({
      item: this.item,
      showDialog: true,
    });
  }
}
