import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
selector: 'app-carrier-form',
templateUrl: './carrier-form.component.html'
})
export class CarrierFormComponent implements OnInit {
  @Input() ocids: {};
  @Input() carrierForm: UntypedFormGroup;
  towOpts = ['Wheel Lift','Towbar','None'];
  srsOpts = ['SRS-8','SRS-10','None'];


  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.carrierForm.addControl('towOption', this.formBuilder.control('', Validators.required));
    this.carrierForm.addControl('srsOption', this.formBuilder.control('', Validators.required));
  }

}