import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClearskyService } from '../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import * as localeData from 'dayjs/plugin/localeData';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import { mergeDeep } from '../../../../shared/deep-merge';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import * as utc from 'dayjs/plugin/utc';
import { CsAggMachineUseData } from '../../../../contracts/clearsky/agg-data';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { combineLatest, first, Subscription } from 'rxjs';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

// Add more for errorbars
More(Highcharts);

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-in-use',
  templateUrl: './machine-in-use.component.html',
  styleUrls: ['./machine-in-use.component.scss'],
})
export class MachineInUseComponent implements OnInit {
  chartEl: ElementRef;
  ocids: OcidItems;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  showChart = false;
  isLoading = true;
  displayName = WidgetsDisplay.miuTime24SumAgg7days;
  widgetName = Widgets.MACHINE_IN_USE;
  protected filter = ''; // Disable filter
  protected chartData = mergeDeep(cloneDeep(BaseChartConfig), {
    title: {
      text: undefined,
    },
    legend: {
      verticalAlign: 'top',
    },
    tooltip: {
      valueDecimals: 2,
    },
    xAxis: [
      {
        title: {
          text: undefined,
        },
      },
    ],
    yAxis: [
      {
        title: {
          text: 'Minutes',
        },
      },
      {
        opposite: true,
        max: 100,
        title: {
          text: '% of Machines',
        },
        visible: false,
        startOnTick: false,
        endOnTick: false,
      },
    ],
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1, // disable hover effects
          },
          hover: {
            enabled: false,
          },
        },
      },
      column: {
        color: '#eee',
        stacking: 'normal',
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: '#000',
          },
        },
        tooltip: {
          pointFormat: '{point.y}%',
          valueDecimals: 0,
        },
      },
      spline: {
        color: WidgetColors.blue,
        marker: {
          fillColor: WidgetColors.orange,
          radius: 8,
          symbol: 'circle',
        },
        showInLegend: false,
        lineWidth: 2,
      },
      errorbar: {
        lineWidth: 0,
      },
    },
    series: [],
  });
  private d7d: dayjs.Dayjs[] = [];
  private stats: { [key: string]: CsAggMachineUseData } = {};
  private plot;
  private subs: Subscription;

  constructor(private clearskyService: ClearskyService, private localization: LocalizationService) { }

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.graphic-default',
        this.displayName
      ])
      .pipe(first())
      .subscribe();
    // We need to get weekdays so require this
    dayjs.extend(localeData);
    dayjs.extend(utc);

    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.legendRef$,
    ]).subscribe(([dash, legend]) => {
      this.resetChartData();

      if (
        !dash ||
        !dash.aggregations ||
        !dash.aggregations.machineInUse ||
        !legend.d90d
      ) {
        return;
      }

      // Now determine the stats for each day in the 7 day period based on it's data
      const d7d = legend.d90d.slice(0, 7);
      this.d7d = d7d.map((day) => dayjs(day)).reverse();
      this.stats = d7d.reduce((prev, day, index) => {
        const format = dayjs(day).format('dddd');

        prev[format] = dash.aggregations.machineInUse[index];

        return prev;
      }, {} as { [day: string]: CsAggMachineUseData });

      this.showChart = true;
      this.isLoading = false;

      // Does the chart already exist?
      if (this.plot && this.showChart) {
        this.setChartData();
      }
    });
  }

  /**
   * Set the chart data.
   * @private
   */
  private setChartData(): void {
    this.plot.addSeries({
      name: 'Machines Turned On',
      type: 'column',
      yAxis: 1,
      data: Object.keys(this.stats)
        .map((day) => this.stats[day].onPercent)
        .reverse(),
      events: {
        click: (event: { point: { index: number } }) => {
          this.clearskyService.updateFilter(CSFilter.actLDays.key, [
            7 - event.point.index,
          ]);
        },
      },
    });

    this.plot.addSeries({
      name: 'Machines Not Turned On',
      type: 'column',
      yAxis: 1,
      data: Object.keys(this.stats)
        .map((day) => this.stats[day].offPercent)
        .reverse(),
      color: '#c9c9c9', // darker than inactive
      events: {
        click: (event: { point: { index: number } }) => {
          this.clearskyService.updateFilter(CSFilter.inactLDays.key, [
            7 - event.point.index,
          ]);
        },
      },
    });

    this.plot.addSeries({
      name: 'Average in Use',
      type: 'spline',
      data: Object.keys(this.stats).map((day) => this.stats[day].onAvg).reverse(),
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }
    const self = this;
    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      mergeDeep(this.chartData, {
        xAxis: [
          {
            categories: this.d7d,
            labels: {
              formatter: function () {
                return self.formatXLabel(dayjs(this.value).utc());
              },
            },
            title: {
              text: undefined,
            },
          },
        ],
      })
    );
    this.setChartData();
  }

  /**
   * Reset chart data.
   * @protected
   */
  private resetChartData(): void {
    if (this.plot && this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Format X Label based on day string and dayjs timestamp.
   * @param date
   * @private
   */
  private formatXLabel(date: dayjs.Dayjs): string {
    return `${date.format('dddd')} (${date.format('M/D')})`;
  }
}
