import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { combineLatest, of } from 'rxjs';
import { concatMap, mergeMap } from 'rxjs/operators';
import { ITokenResponse } from '../../../../../contracts/bambora/itoken-response';
import { IAddress } from '../../../../../contracts/user/iaddress';
import { ICreditCard } from '../../../../../contracts/user/icredit-card';
import {
  INavMenu,
  IUserNavMenus,
} from '../../../../../contracts/user/inav-menu';
import { IUser } from '../../../../../contracts/user/iuser';
import { ContentService } from '../../../../../service/content.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { MenuService } from '../../../../../service/user/menu.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { CreditCardService } from '../credit-card.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-credit-card-dialog',
  styleUrls: ['./credit-card-dialog.component.scss'],
  templateUrl: './credit-card-dialog.component.html',
})
export class CreditCardDialogComponent implements OnInit, OnDestroy {
  isAddingCreditCard = false;
  creditCardForm!: UntypedFormGroup;
  setAsDefaultCardChecked = false;
  creditCard = [];
  months!: INavMenu;
  years: string[] = [];
  countries: INavMenu[] = [];
  states: INavMenu[] = [];
  accountAddress!: IAddress;
  shippingAddress!: IAddress;
  user!: IUser;
  ocids: OcidItems = {};
  canSaveCC = true;
  paymentOption: string;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private userService: CurrentUserService,
    private creditCardService: CreditCardService,
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CreditCardDialogComponent>,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private menuService: MenuService,
    private contentService: ContentService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'payment.edit-credit-card',
        'payment.add-credit-card',
        'payment.credit-card-info',
        'creditcard.card-name-field',
        'creditcard.same-as-shipping',
        'payment.card-number',
        'payment.card-number-error',
        'payment.cc-expiration',
        'payment.card-expiration-error',
        'payment.cc-cvv',
        'payment.card-cvv-error',
        'payment.cc-default',
        'payment.credit-card-billing',
        'payment.cc-same-account-address',
        'payment.add-credit-card-cvv-info',
        'shipping.country-error',
        'shipping.state-province-error',
        'shipping.add-new-address'
      ])
      .subscribe();

    this.isAddingCreditCard = this.data.isAddCreditCard;

    this.creditCardForm = this._formBuilder.group({
      cardName: ['', Validators.required],
      cardNumber: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      ccv: ['', Validators.required],
      billingName: [
        '',
        [Validators.required, Validators.pattern(/^[A-Za-z 0-9'-]+$/)],
      ],
      billingAddress1: ['', Validators.required],
      billingAddress2: [''],
      billingCity: ['', Validators.required],
      billingState: [''],
      billingCountry: ['', Validators.required],
      billingPostalCode: ['', Validators.required],
    });

    // If they are editing a credit card, populate form
    if (!this.isAddingCreditCard) {
      this.creditCardForm.patchValue(this.data.creditCard);
    }

    // Get default billing address
    combineLatest([this.userService.userSubject, this.menuService.menus$])
      .pipe(
        mergeMap((result: [IUser, IUserNavMenus]) => {
          const user: IUser = result[0];
          const menus: IUserNavMenus = result[1];
          if (user) {
            this.user = user;
            this.canSaveCC = this.canSaveCC =
              (!user.saveCreditCardAllowed &&
                user.saveCreditCardAllowed != undefined) ||
                user.inProxy
                ? false
                : true;
            this.accountAddress = <IAddress>user.billingAddress;
            // If the address comes from an order, use the order shipping address as the default shipping address, otherwise
            // use the user's default shipping address.
            this.shippingAddress = this.data.orderShippingAddress
              ? <IAddress>this.data.orderShippingAddress
              : <IAddress>user.shippingAddress;

            // load country menu based on user account type
            if (menus) {
              if (user.accountType == 'Retail') {
                return combineLatest(
                  this.menuService.getMenuByUxKey('retailCountries'),
                  this.menuService.getMenuByUxKey('months')
                );
              } else {
                return combineLatest(
                  this.menuService.getMenuByUxKey('countries'),
                  this.menuService.getMenuByUxKey('months')
                );
              }
            }
          }
          return of(null);
        })
      )
      .subscribe((menus: INavMenu[]) => {
        if (menus) {
          this.countries = menus[0].childMenus;
          this.months = menus[1];
          // If the account address exists, set the default billing address of the CC to the user's default billing address.
          if (this.accountAddress) {
            this.addressCheckChanged('accountAddress');
            this.setStatesByCountry(this.accountAddress.country);
            this.paymentOption = 'accountAddress';
          } else if (this.shippingAddress) {
            // If the account address doesn't exist but the account shipping address exists, set the default billing address
            // of the CC to the user's default shipping address.
            this.addressCheckChanged('shippingAddress');
            this.setStatesByCountry(this.shippingAddress.country);
            this.paymentOption = 'shippingAddress';
          } else {
            this.addressCheckChanged('newAddress');
            this.paymentOption = 'newAddress';
          }
        }
      });

    // Setup years
    const curYear = new Date().getFullYear();
    this.years = this.contentService.getTwoDigitYears(curYear, curYear + 20);
  }

  /**
   * Event emitted when form is submitted.
   *
   * @param {FormGroup} form
   */
  verifyCreditCardForm(form: UntypedFormGroup) {
    this.notificationService.reset();
    // Check if form is valid
    if (form.valid) {
      let addressData;

      addressData = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        phoneNumber: this.accountAddress.phoneNumber,
        companyName: this.getFF('billingName'),
        address1: this.getFF('billingAddress1'),
        address2: this.getFF('billingAddress2'),
        address3: '',
        city: this.getFF('billingCity'),
        state: this.getFF('billingState'),
        country: this.getFF('billingCountry'),
        postalCode: this.getFF('billingPostalCode'),
      };

      // Make API call
      this.creditCardService
        .tokenizeCard(
          this.getFF('cardNumber'),
          this.getFF('expMonth'),
          this.getFF('expYear'),
          this.getFF('ccv')
        )
        .pipe(
          concatMap((response: ITokenResponse) => {
            // Now that we have a token, add the CC
            return this.creditCardService.addCreditCard(
              response.token,
              <IAddress>addressData,
              this.setAsDefaultCardChecked
            );
          })
        )
        .subscribe(
          (response: ICreditCard) => {
            // Close dialog after completion of both observables
            this.dialogRef.close(response);
          },
          (error) => {
            this.notificationService.addError(error.error.title);
          }
        );
    }
  }

  /**
   * Method that handles when the account address or shipping address checkbox selection changes.
   * @param {string} addressType
   */
  addressCheckChanged(addressType: string) {
    if (addressType === 'accountAddress') {
      this.setStatesByCountry(this.accountAddress.country);
      this.creditCardForm.patchValue({
        billingName: this.accountAddress.customerName,
        billingAddress1: this.accountAddress.address1,
        billingAddress2: this.accountAddress.address2,
        billingCity: this.accountAddress.city,
        billingState: this.accountAddress.state,
        billingCountry: this.accountAddress.country,
        billingPostalCode: this.accountAddress.postalCode,
      });
    } else if (addressType === 'shippingAddress') {
      this.setStatesByCountry(this.shippingAddress.country);
      this.creditCardForm.patchValue({
        billingName: this.shippingAddress.customerName,
        billingAddress1: this.shippingAddress.address1,
        billingAddress2: this.shippingAddress.address2,
        billingCity: this.shippingAddress.city,
        billingState: this.shippingAddress.state,
        billingCountry: this.shippingAddress.country,
        billingPostalCode: this.shippingAddress.postalCode,
      });
    } else {
      this.creditCardForm.patchValue({
        billingName: '',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingState: '',
        billingCountry: '',
        billingPostalCode: '',
      });
    }
  }

  /**
   * Event emitted when selecting country from dropdown.
   *
   * @param {MatSelect} value
   */
  onChangeCountry(value: MatSelect) {
    // Populate states dropdown
    this.setStatesByCountry(value.value);
  }

  /**
   * Set states options by country uxKey.
   *
   * @param {string} key
   */
  protected setStatesByCountry(key: string) {
    // Populate states dropdown
    const country: INavMenu = this.countries.find(
      (menu: INavMenu) => menu.uxKey === key
    );

    this.states = country.childMenus;
  }

  /**
   * Get form field value.
   *
   * @param field
   * @returns {any}
   */
  getFF(field) {
    return this.creditCardForm.get(field).value;
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.notificationService.reset();
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
