import { Pipe, PipeTransform } from '@angular/core';
import { CSLegend, CSRefBasic } from '../../contracts/clearsky/clearsky-legend';

/**
 * Convert value to read from legend.
 *
 * @param value
 * @param legend
 * @param col
 * @param prop
 */
export const convertToLegendVal = (
  value: number | undefined,
  legend: CSLegend,
  col: string,
  prop = 'desc'
): unknown => {
  if (value === undefined) {
    return value;
  }
  const legendMapper = legend[col as keyof CSLegend] ?? [];
  const rec = [...(legendMapper as CSRefBasic[])].find((x) => x.id === value);
  return rec ? rec[prop] : value;
};

@Pipe({ name: 'legendValue' })
export class LegendValuePipe implements PipeTransform {
  transform(
    value: number,
    legend: CSLegend,
    col: string,
    prop = 'desc'
  ): unknown {
    return convertToLegendVal(value, legend, col, prop);
  }
}
