import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { IRemoveItemDto } from '../../../../../contracts/commerce/dto/iremove-item.dto';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { isPlatformBrowser } from '@angular/common';
import { IUser } from '../../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-oos-warning',
  templateUrl: './machine-oos-warning.component.html',
})
export class MachineOOSWarningComponent implements OnInit {
  @Input() item: ICommerceItemWithCart;
  @Input() user: IUser;
  @Output() removed: EventEmitter<IRemoveItemDto> =
    new EventEmitter<IRemoveItemDto>();
  public ocids = {};
  private isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    private localization: LocalizationService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  ngOnInit() {
    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  /**
   * Event listener for remove button.
   */
  public onRemove(showDialog: boolean) {
    this.notificationService.reset();
    this.removed.emit({
      item: this.item,
      showDialog: showDialog,
    });
  }

  /**
   * prepopulates JLG form to request a quote
   */
  public requestQuote() {
    if (this.isBrowser) {
      const user = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        companyName: this.user.companyName,
        email: this.user.email,
        country: this.user.homeAddress.country,
        state: this.user.homeAddress.state,
        city: this.user.homeAddress.city,
        form: 'requestQuote',
        comment:
          'interested in purchasing ' +
          this.item.itemDescription +
          ' from OnlineExpress',
      };
      document.cookie =
        'jlgUserInfo=' + JSON.stringify(user) + ';path=/;domain=jlg.com';
      window.open('https://www.jlg.com/en/about-jlg/contact-us', '_blank');
      this.onRemove(false);
    }
  }
}
