import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LayoutService } from 'app/service/layout.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ISSOLogin } from '../contracts/auth/isso-login';
import { IEndecaPayloadResponse } from '../contracts/IEndecaPayloadResponse';
import { IUser } from '../contracts/user/iuser';
import { OktaAuthWrapper } from '../service/auth/okta.auth.wrapper';
import { CartService } from '../service/cart/cart.service';
import { CurrentUserService } from '../service/user/current-user.service';
import { EndecaService } from './endeca.service';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { setEnvironmentVars } from '../../environments/environment';
import { MenuService } from '../service/user/menu.service';
import { RequireType } from '../shared/user.profile.model';
import { ProfileCompletionDialogComponent } from '../core/header/auth-container/profile-completion-dialog/profile-completion-dialog.component';

@Injectable()
export class AuthEndecaPayloadResolver implements Resolve<object> {
  private previousUrl = '';
  private billToShipToDialog = true;
  public platformBrowser: boolean = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private userService: CurrentUserService,
    private oktaAuth: OktaAuthWrapper,
    private endecaService: EndecaService,
    private cartService: CartService,
    private layoutService: LayoutService,
    private router: Router,
    public dialog: MatDialog,
    private injector: Injector,
    private menuService: MenuService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<[IUser, IEndecaPayloadResponse | boolean]>
    | Promise<[IUser, IEndecaPayloadResponse | boolean]>
    | [IUser, IEndecaPayloadResponse | boolean] {
    this.setEnvVars();
    this.layoutService.setIsClearskyEnabled(false);

    return this.oktaAuth.isAuthenticated().pipe(
      mergeMap((user) => {
        // If this hasn't been set aka first visit, get menus too
        if (!this.oktaAuth.isLoggedInValue) {
          return this.menuService.getMenus().pipe(
            mergeMap(() => {
              const userData = user as ISSOLogin;

              // Check if data.require is 'billTo or shipTo'
              if (
                user !== false &&
                (userData.require === RequireType.billTo ||
                  userData.require === RequireType.shipTo) &&
                this.billToShipToDialog
              ) {
                this.billToShipToDialog = false;

                // Open the dialog and return an observable that resolves when the dialog is closed
                this.dialog
                  .open(ProfileCompletionDialogComponent, {
                    width: '100%',
                    maxWidth: '560px',
                    disableClose: true,
                    data: {
                      firstName: userData.firstName,
                      userType: userData.userType,
                    },
                  })
                  .afterClosed()
                  .subscribe(() => {
                    this.router.navigate(['/'], {
                      onSameUrlNavigation: 'reload',
                    });
                  });
              }

              // Continue SSO Login
              return this.handleSSOLogin(user, route, state);
            })
          );
        }

        return this.handleSSOLogin(user, route, state);
      })
    );
  }

  /**
   * Handle ssoLogin response. If the user has a valid session, let them continue and retrieve their user profile and the
   * endeca payload. If the user does not have a valid session, return them home.
   * @param {ISSOLogin} user
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @param {boolean} [clearPageCache=false] If we are sso-ing, we need to re-retrieve the page payload, and to do so we need
   * to clear the page cache from the node server. So, if we are sso-ing, we will pass true to this param and clear its cache.
   * @returns {Observable<[IUser, IEndecaPayloadResponse]>}
   */
  handleSSOLogin(
    user: ISSOLogin | boolean,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    clearPageCache = false
  ) {
    // If the session hasn't expired, get the user and endeca payload the user is requesting.
    if (user !== false && !(user as ISSOLogin).sessionExpired) {
      return forkJoin([
        this.userService.getUser(true),
        this.getEndecaPayload(route, state, clearPageCache),
      ]);
    }

    return forkJoin([
      this.userService.getUser(clearPageCache),
      this.getEndecaPayload(route, state, clearPageCache),
    ]);
  }

  /**
   * Does some logic on the route and user state before getting the endeca payload for that page. Returns the endeca payload
   * on success and an empty observable on failure when navigating to checkout and the user does not have permission.
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @param {boolean} [clearPageCache=false]
   * @returns {IEndecaPayloadResponse | Observable<any>}
   */
  getEndecaPayload(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    clearPageCache = false
  ): Observable<IEndecaPayloadResponse | boolean> {
    let url = '';

    if ('page' in route.data) {
      // Defined endpoint in routing data object
      url = route.data['page'];
    } else {
      // Grab url from requested page and hit that endpoint in ExM
      url = route.url.join('/');
    }

    // This removes the requirement to have the '' empty route defined. Having it defined in the router makes duplicate calls
    // to get the home component.
    url = '/' + (url === '' ? 'home' : url);

    if ('dynamicData' in route.data) {
      // Replace placeholders in url with dynamic params.
      for (const dData of route.data['dynamicData']) {
        const value = route.params[dData['key']];

        url = url.replace(new RegExp(dData['placeholder'], 'g'), value);
      }
    }

    // If the page the user is currently navigating to is the unauthorized page or the home page AND the
    // previous url was a page other than the home page and unauthorized page (my-account, order-now, etc),
    // set the return url to the previous page. This makes sure that the page the user was previously on was
    // an actual page the user has to be logged in for.
    if (
      (state.url === '/unauthorized' || state.url === '/') &&
      this.previousUrl !== '/' &&
      this.previousUrl !== '/unauthorized'
    ) {
      this.oktaAuth.returnUrl = this.previousUrl;
    }

    // If the page the user is currently navigating to is the home page (on logout as an example) AND
    // the previous url was not the home page AND the previous url was not the unauthorized page, reset
    // the return url. This makes sure to reset the return url on logout or else on logout it will redirect
    // to the unauthorized page.
    if (
      state.url === '/' &&
      this.previousUrl !== '/' &&
      this.previousUrl !== '/unauthorized'
    ) {
      this.oktaAuth.returnUrl = '';
    }

    // Set the current route as the previous url .
    this.previousUrl = state.url;
    // If the navigating url is /checkout, make a check to /cart/moveToCheckout to determine if the user has
    // resolved all special conditions and can proceed.
    if (state.url === '/checkout') {
      // Check if there are any special conditions on the cart page.
      return this.cartService.canMoveToCheckout().pipe(
        mergeMap(() => {
          // If the user is able to proceed to checkout, get the endeca payload for the page.
          return this.getPayload(route, url, clearPageCache);
        }),
        catchError((error) => {
          // If the user is not able to proceed to checkout, navigate them back to the cart page.
          this.router.navigate(['/cart']);
          return of(false);
        })
      );
    } else {
      // If the url is not /checkout, get the endeca payload.
      return this.getPayload(route, url, clearPageCache);
    }
  }

  /**
   * Gets the payload response for the page being requested.
   * @param {ActivatedRouteSnapshot} route
   * @param {string} url
   * @param {boolean} clearPageCache
   * @returns {IEndecaPayloadResponse}
   */
  getPayload(
    route: ActivatedRouteSnapshot,
    url: string,
    clearPageCache: boolean
  ): Observable<IEndecaPayloadResponse | boolean> {
    return this.endecaService
      .getPayload(url, route.queryParams, true) // clear payload no matter what and remove logic on code cleanup
      .pipe(
        map((response) => {
          // Handle redirects
          const queryString = require('query-string');
          if (response['endeca:redirect']) {
            const redirectUrl: string = response['endeca:redirect'].link.url;
            // Redirect to external url if present.
            if (redirectUrl.startsWith('http')) {
              document.location.href = redirectUrl;
              return false;
            }
            const validUrl = queryString.parseUrl(redirectUrl);

            this.router.navigate([validUrl.url], {
              queryParams: validUrl.query,
            });
            return false;
          }

          // Get query params and add them to url
          if (Object.keys(route.queryParams).length) {
            // https://www.npmjs.com/package/query-string
            const query = queryString.stringify(route.queryParams); // Encode params
            url += '?' + query;
          }

          return {
            payload: response,
            url: url,
          };
        })
      );
  }

  private setEnvVars(): void {
    // Set the environment variables before they are needed for any service.
    // If the code is executing on the server, get the host via injector. Otherwise,
    // get it by the location.
    if (isPlatformServer(this.platformId)) {
      const request = this.injector.get('request');
      // Get the x-forwarded-host from the request.
      let forwardedHost: string = request.get('x-forwarded-host');
      // Need to set the respective protocol depending on the environment.
      forwardedHost = request.get('https')
        ? `https://${forwardedHost}`
        : `http://${forwardedHost}`;
      setEnvironmentVars(`http://${request.get('host')}`, forwardedHost);
    } else {
      setEnvironmentVars(
        location.protocol +
          '//' +
          location.hostname +
          (location.port ? ':' + location.port : '')
      );
    }
  }
}
