import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLegendComponent } from './chart-legend.component';
import { ChartLegendOptionComponent } from './chart-legend-option/chart-legend-option.component';



@NgModule({
  declarations: [
    ChartLegendComponent,
    ChartLegendOptionComponent
  ],
  exports: [
    ChartLegendComponent,
    ChartLegendOptionComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ChartLegendModule { }
