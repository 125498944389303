import { Component, Input, OnInit } from '@angular/core';
import { getDtcSvtyColor } from '../../../contracts/clearsky/machine/machine.chart.config';

@Component({
  selector: 'app-dtc-category-dot-title',
  templateUrl: './dtc-category-dot-title.component.html',
})
export class DtcCategoryDotTitleComponent implements OnInit {
  @Input() label: string;
  @Input() id: number;
  @Input() withoutLabel;
  colorClass: string;

  ngOnInit(): void {
    this.colorClass = getDtcSvtyColor(this.id);
  }
}
