import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ILatestNewsItem } from '../latest-news.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-latest-news-item]',
  styleUrls: ['./latest-news-item.component.scss'],
  templateUrl: './latest-news-item.component.html',
})
export class LatestNewsItemComponent implements OnInit {
  @Input() news: ILatestNewsItem;
  @ViewChild('messageContainer') messageEl!: ElementRef;
  isOpen = false;
  messageHeight!: string;
  ocids!: OcidItems;

  constructor(private localization: LocalizationService) { }

  ngOnInit() {
    // Subscribe to any ocid changes.
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }


  /**
   * Toggles the open, closed state of the message.
   */
  textToggle() {
    if (!this.isOpen) {
      this.isOpen = true;
      this.messageHeight = this.messageEl.nativeElement.scrollHeight;
    } else {
      this.isOpen = false;
      this.messageHeight = null;
    }
  }
}
