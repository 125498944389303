import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IRemoveItemDto } from '../../../../contracts/commerce/dto/iremove-item.dto';
import { ICommerceItemAvailability } from '../../../../contracts/commerce/icommerce-item-availability';
import { ICommerceItemWithCart } from '../../../../contracts/commerce/icommerce-item-with-cart';
import { IUser } from '../../../../contracts/user/iuser';
import { SignInDialogComponent } from '../../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ShoppingListComponent } from '../shopping-list/shopping-list.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { environment } from '../../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'tr[app-shopping-cart-item]',
  styleUrls: ['./shopping-cart-item.component.scss'],
  templateUrl: './shopping-cart-item.component.html',
})
export class ShoppingCartItemComponent implements OnInit {
  @Input() index!: number;
  @Input() item!: ICommerceItemWithCart;
  @Input() availability!: ICommerceItemAvailability;
  @Input() toRemove!: boolean;
  @Input() isLoggedIn!: boolean;
  @Input() user!: IUser;
  @Output() removedItem: EventEmitter<IRemoveItemDto> =
    new EventEmitter<IRemoveItemDto>();
  @Output() quantityUpdated: EventEmitter<object> = new EventEmitter<object>();
  @Output() triggerEditSerialNums: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() checkboxUpdated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() toggleWarehouseView: EventEmitter<ICommerceItemWithCart> =
    new EventEmitter<ICommerceItemWithCart>();
  ocids: OcidItems = {};
  quantity!: UntypedFormControl;
  warehouseVisible = false;
  hideWarehouseLink = false;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    // Set localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'shopping-cart.view-locations-label',
        'shopping-cart.warehouse-details-label',
        'shopping-cart.warehouse-details-info',
      ])
      .subscribe();

    this.quantity = new UntypedFormControl(this.item.quantity, [
      Validators.required,
      Validators.min(1),
    ]);

    // Hide the warehouse link if the warehouseName property doesn't exist in the payload OR if there's 0 in stock OR if it's direct ship
    this.hideWarehouseLink =
      !this.availability?.warehouseInfo?.some(
        (warehouse) => warehouse.warehouseName
      ) ||
      !this.availability?.totalAvailability ||
      this.availability?.directShipItem;
  }

  /**
   * Event emitted when removing an item from cart.
   *
   * @param {ICommerceItemWithCart} item
   */
  onRemoveItem(item: ICommerceItemWithCart) {
    this.removedItem.emit({
      item: item,
      showDialog: true,
    });
  }

  /**
   * Event emitted when changing quantity of item in cart.
   *
   * @param value
   */
  onUpdateQuantity(value) {
    if (this.quantity.valid && +value !== this.item.quantity) {
      this.quantityUpdated.emit({
        newQty: +value,
        oldQty: this.item.quantity,
      });
    }
  }

  /**
   * Trigger event emitter to show edit serial numbers box above the cart.
   *
   * @param {any} event
   */
  editSerialNums(event) {
    this.triggerEditSerialNums.emit();
  }

  /**
   * Event emmited when toggling View Warehouse link
   *
   */
  wareHouseViewClick(): void {
    this.warehouseVisible = !this.warehouseVisible;
    this.toggleWarehouseView.emit(this.item);
  }

  /**
   * Event emmited when toggling checkbox
   *
   */
  toggleItem(event): void {
    this.checkboxUpdated.emit(event.checked);
  }

  /**
   * Event emitted when moving an item to shopping list.
   *
   * @param {ICommerceItemWithCart} item
   */
  moveToShoppingList(item: ICommerceItemWithCart): void {
    if (this.isLoggedIn) {
      const shoppingListModal = this.dialog.open(ShoppingListComponent, {
        width: '380px',
        data: {
          items: [
            {
              itemNumber: item.itemNumber,
              quantity: item.quantity,
            },
          ],
        },
      });
      shoppingListModal.afterClosed().subscribe((result) => {
        if (result) {
          this.removedItem.emit({
            item: item,
            showDialog: false,
          });
        }
      });
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.router.navigate(['/cart']);
          }
        });
    }
  }
}
