import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { merge, of } from 'rxjs';
import {
  catchError,
  map,
  onErrorResumeNext,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { IAddressPaginationDto } from '../../../../contracts/user/dto/iaddress-pagination-dto';
import { IAddress } from '../../../../contracts/user/iaddress';
import { IAddressPagination } from '../../../../contracts/user/iaddress-pagination';
import { IUser, UserEnvironments } from '../../../../contracts/user/iuser';
import { NotificationService } from '../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { UserAddressesService } from '../../../../service/user/user-addresses.service';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ShippingAddressDialogComponent } from './shipping-address-dialog/shipping-address-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-address-book',
  styleUrls: ['./account-address-book.component.scss'],
  templateUrl: './account-address-book.component.html',
  providers: [NotificationService],
})
export class AccountAddressBookComponent implements OnInit, OnDestroy {
  addresses: IAddress[] = [];
  user!: IUser;
  isEmeaUser = false;
  displayedColumns = [
    'id',
    'company',
    'address',
    'zip',
    'country',
    'state',
    'default',
  ];
  dataSource = new MatTableDataSource<any>(this.addresses);
  dataLength!: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50];
  showDeleteButton = false;

  @ViewChild('searchAddressForm') searchAddressForm!: NgForm;
  search = '';
  readonly searchEvent: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  ocids: OcidItems = {};
  submitted = false;

  constructor(
    public dialog: MatDialog,
    public userAddressService: UserAddressesService,
    public route: ActivatedRoute,
    private notificationService: NotificationService,
    private localization: LocalizationService,
    private currentUserService: CurrentUserService,
    private confirmationService: ConfirmationDialogsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'address.create-ship-to-request',
        'account.shipping-addresses-title',
        'account.search-address-text',
        'shipping.add-new-address',
        'account.default-text',
        'account.make-default-text',
        'shipping.shipping-id',
        'account.contact-number-label',
        'delete-address.tooltip',
        'delete-address.module',
      ])
      .subscribe();

    // Get the users default shipping and billing addresses, if either is set
    this.currentUserService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        this.isEmeaUser =
          !this.user.appConfig.createShiptoAddressAllowed &&
          this.user.appConfig.hasOwnProperty('createShiptoAddressRequestLink');
        this.showDeleteButton = !(
          this.user.erpEnvironment === UserEnvironments.BP ||
          this.user.erpEnvironment === UserEnvironments.NZ
        );
      }
    });
    // Subscribe to search, sort, and paging events.
    merge(this.searchEvent, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.notificationService.reset();
          const params: IAddressPaginationDto = {
            addressType: 'secondaryAddresses',
            pageIndex: this.paginator.pageSize
              ? this.paginator.pageSize
              : this.pageSize,
            page: this.paginator.pageIndex + 1,
            sortBy: this.sort.active ? this.sort.active : 'shipToNumber',
          };
          if (this.search !== '') {
            params.searchTerm = this.search;
          }
          // Initial sort needs to be descending in order to sort by the most recently created ship to.
          // This logic checks to see if the sort direction is not 'asc' because the sort variable is
          // initialized as an empty string.
          if (this.sort.direction !== 'asc') {
            params.sortDir = 'desc';
          }
          return this.userAddressService
            .getAddressPagination(
              params,
              this.user.shippingAddress ? this.user.shippingAddress.id : null
            )
            .pipe(
              catchError((error) => {
                // Show the error to the user.
                this.notificationService.addError(error.error.title);
                // Return null.
                return null;
              }),
              onErrorResumeNext()
            );
        }),
        map((addresses: IAddressPagination) => {
          this.dataLength = addresses.totalResults;
          return addresses.items;
        }),
        catchError((err) => {
          if (err) {
            // If not cancelled
            this.notificationService.addError(err.error?.title);
          }

          return of([]);
        })
      )
      .subscribe((addresses: IAddress[]) => {
        this.dataSource.data = addresses;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }

  /**
   * Delete address
   */
  deleteAddress(shipToNumber: string) {
    this.confirmationService
      .confirm(this.ocids['delete-address.module'], '')
      .subscribe((result) => {
        if (result) {
          this.userAddressService.deleteAddress(shipToNumber).subscribe(
            () => {
              // Emit a search event to refresh the addresses.
              this.searchEvent.emit(this.search);
            },
            (error) => {
              this.notificationService.addError(error.error.title);
            }
          );
        }
      });
  }

  /**
   * Reset filter.
   */
  onReset() {
    this.search = '';
    this.onSearch();
    this.submitted = false;
  }

  /**
   * Emit event of search.
   */
  onSearch() {
    this.paginator.firstPage(); // Go to first page before searching!
    this.submitted = true;
    this.searchEvent.emit(this.search);
  }

  /**
   * Event listener when user clicks address default checkbox.
   *
   * @param {any} event
   * @param {IAddress} row
   */
  onSetDefault(event, row: IAddress) {
    // If the row isn't already set as default, set it as the default
    if (!row['isDefault']) {
      this.notificationService.reset();
      // Set the default address
      this.userAddressService.makeDefaultAddress(row).subscribe(
        () => {
          // Emit a search event to refresh the addresses.
          this.searchEvent.emit(this.search);
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
    } else {
      // In case the user deselects the already selected default shipping address, make sure it stays selected
      event.source.checked = true;
    }
  }

  /**
   * Event listener when user clicks add address link.
   */
  onAddAddress(): void {
    this.dialog
      .open(ShippingAddressDialogComponent, {
        width: '720px',
        data: {
          allowMakeDefault: this.user.appConfig.canChangeDefaultShipTo,
          isEMEAUser: this.isEmeaUser,
        },
      })
      .afterClosed()
      .subscribe((address: IAddress) => {
        if (address) {
          // Emit a search event to trigger a refresh of the table.
          this.searchEvent.emit();
        }
      });
  }

  get isDirty() {
    return this.search && this.submitted;
  }
}
