import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IWarehouseAddress } from '../../../../contracts/commerce/iwarehouse-address';
import { CustomerPickupService } from '../../../../service/cart/customer-pickup.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-customer-pickup-dialog',
  styleUrls: ['./customer-pickup-dialog.component.scss'],
  templateUrl: './customer-pickup-dialog.component.html',
})
export class CustomerPickupDialogComponent implements OnInit {
  warehouses: IWarehouseAddress[] = [];
  customerPickupForm!: UntypedFormGroup;
  selectedWarehouse!: IWarehouseAddress;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<CustomerPickupDialogComponent>,
    private localization: LocalizationService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private customerPickupService: CustomerPickupService,
    @Inject(MAT_DIALOG_DATA) public data: { warehouseId: string }
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'shopping-cart.customerpickup-module-text',
        'shopping-cart.customerpickup-module-hours',
      ])
      .subscribe();

    // Building the customer pickup form.
    this.customerPickupForm = this.formBuilder.group({
      customerAddressPickup: ['', Validators.required],
    });
    this.notificationService.reset();

    // Get warehouses the user is able to choose from.
    this.customerPickupService.getWarehouses().subscribe(
      (warehouses: IWarehouseAddress[]) => {
        this.warehouses = warehouses;
        this.selectedWarehouse = this.data.warehouseId
          ? this.warehouses.find(
              (warehouse: IWarehouseAddress) =>
                warehouse.house === this.data.warehouseId
            )
          : this.warehouses[0];

        // Patch the value of the customer pickup form with either the pre-selected warehouse or the first warehouse
        // in the warehouses array.
        this.customerPickupForm.patchValue({
          customerAddressPickup:
            this.data.warehouseId !== null
              ? this.data.warehouseId
              : this.warehouses[0].house,
        });
      },
      (error) => {
        this.notificationService.addError(error.error.title);
      }
    );
  }

  /**
   * When the user selects the warehouse by clicking a radio button, set the currently selected warehouse.
   */
  selectWarehouse() {
    const warehouseId: string = this.customerPickupForm.get(
      'customerAddressPickup'
    ).value;
    this.selectedWarehouse = this.warehouses.find(
      (warehouse: IWarehouseAddress) => warehouse.house === warehouseId
    );
  }

  /**
   * Event emitted when the user has confirmed the pickup location.
   */
  confirmPickupLocation() {
    const warehouseId: string = this.customerPickupForm.get(
      'customerAddressPickup'
    ).value;
    this.notificationService.reset();

    // Set the warehouse on the order.
    this.customerPickupService
      .addPickupWarehouseToOrder({
        warehouseId,
      })
      .subscribe(
        () => {
          // Close the dialog box.
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
  }
}
