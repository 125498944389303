import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  Input,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { ContentService } from '../../../service/content.service';
import { IApplicationTourStep } from '../../../contracts/IApplicationTourStep';
import { isPlatformBrowser } from '@angular/common';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-application-tour',
  styleUrls: ['./application-tour.component.scss'],
  templateUrl: './application-tour.component.html',
})
export class ApplicationTourComponent implements OnInit, AfterViewInit {
  @Input() data!: {
    classPrefix: string;
    confirmCancel: boolean;
    confirmCancelMessage: string;
    defaultStepOptions: string;
    exitOnEsc: boolean;
    keyboardNavigation: boolean;
    modalContainer: string;
    useModalOverlay: boolean;
    tourName: string;
    steps: object[];
  };
  tourId = '';
  steps: object[] = [];
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private shepherdService: ShepherdService,
    public router: Router,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.tourId = this.data.tourName.replace(/\s/g, '');
    this.steps = this.data.steps.map((step: IApplicationTourStep, index) => {
      const formattedStep: IApplicationTourStep = {};
      formattedStep.id &&= step.id;
      formattedStep.classes &&= step.classes;
      formattedStep.scrollTo &&= step.scrollTo;
      formattedStep.title = step.title;
      formattedStep.text = step.text;
      if (index === 0) {
        formattedStep.beforeShowPromise = function () {
          return new Promise<void>(function (resolve) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 500);
          });
        };
      }
      if (step.attachToElement) {
        formattedStep.attachTo = {
          element: step.attachToElement,
          on: step.attachToElementOn,
        };
      }

      formattedStep.buttons = step.buttons?.map((button) => {
        return {
          classes: button.classes,
          text: button.text,
          action(): unknown {
            if (button.url) {
              return (document.location = button.url);
            }
            if (button.text === 'Exit') {
              return this.cancel();
            }
            if (button.text === 'Done') {
              return this.complete();
            }
            if (button.text === 'Next') {
              return this.next();
            }
            if (button.text === 'Back') {
              return this.back();
            }
          },
        };
      });
      return formattedStep;
    });
  }

  ngAfterViewInit(): void {
    this.shepherdService.confirmCancel = this.data.confirmCancel;
    this.shepherdService.modal = this.data.useModalOverlay;
    this.shepherdService.confirmCancelMessage = this.data.confirmCancelMessage;
    this.shepherdService.defaultStepOptions = {
      scrollTo: { behavior: 'smooth', block: 'center' },
      highlightClass: 'shepherd-highlight',
      cancelIcon: {
        enabled: true,
      },
    };
    this.shepherdService.requiredElements = [];
    this.shepherdService.addSteps(this.steps);
    if (this.platformBrowser) {
      if (!this.contentService.getCookie('hideSteps_' + this.tourId)) {
        this.shepherdService.start();
      }
    }

    this.shepherdService.tourObject.on('complete', () => {
      this.createStepsCookie();
    });
    this.shepherdService.tourObject.on('cancel', () => {
      this.createStepsCookie();
    });
  }

  createStepsCookie() {
    const d = new Date();
    d.setTime(d.getTime() + 1000 * 24 * 60 * 60 * 1000);
    this.contentService.createCookie(
      'hideSteps_' + this.tourId,
      'true',
      d.toUTCString()
    );
  }
}
