// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 4px;
  white-space: nowrap;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhZGdlLWxhYmVsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtFQUNBLGdCQUFBO0VBQ0Esa0JBQUE7RUFDQSxtQkFBQTtBQUNGIiwiZmlsZSI6ImJhZGdlLWxhYmVsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xyXG4gIGZvbnQtc2l6ZTogMTJweDtcclxuICBwYWRkaW5nOiAzcHggNXB4O1xyXG4gIGJvcmRlci1yYWRpdXM6IDRweDtcclxuICB3aGl0ZS1zcGFjZTogbm93cmFwO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/badge-label/badge-label.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACF;AACA,wcAAwc","sourcesContent":[":host {\r\n  font-size: 12px;\r\n  padding: 3px 5px;\r\n  border-radius: 4px;\r\n  white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
