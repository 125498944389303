import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  isDevMode,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { IAddPaymentGroupDto } from '../../../contracts/commerce/dto/iadd-payment-group.dto';
import { IApplyShippingInfoDto } from '../../../contracts/commerce/dto/iapply-shipping-info-dto';
import { ICheckoutShipping } from '../../../contracts/commerce/dto/icheckout-shipping';
import { ICheckoutShippingAddress } from '../../../contracts/commerce/dto/icheckout-shipping-address';
import { IPaymentInfo } from '../../../contracts/commerce/dto/ipayment-info';
import { IShippingInfoDto } from '../../../contracts/commerce/dto/ishipping-info-dto';
import { IShippingInfoFreightTermDto } from '../../../contracts/commerce/dto/ishipping-info-freight-term.dto';
import { IUpdateInstructionDto } from '../../../contracts/commerce/dto/iupdate-instruction.dto';
import { ICommerceItemAvailability } from '../../../contracts/commerce/icommerce-item-availability';
import { ICommerceItemWithCart } from '../../../contracts/commerce/icommerce-item-with-cart';
import { IFreightTypeItems } from '../../../contracts/commerce/ifreight-type-items';
import { IOrder, SalesType } from '../../../contracts/commerce/iorder';
import { IShippingInfo } from '../../../contracts/commerce/ishipping-info';
import { IShippingInfoFreightType } from '../../../contracts/commerce/ishipping-info-freight-type';
import { IShippingInfoFreightTypeItem } from '../../../contracts/commerce/ishipping-info-freight-type-item';
import { ICreditCard } from '../../../contracts/user/icredit-card';
import { IUser, UserEnvironments } from '../../../contracts/user/iuser';
import { CartService } from '../../../service/cart/cart.service';
import { PaymentGroupsService } from '../../../service/cart/payment-groups.service';
import { ShippingGroupsService } from '../../../service/cart/shipping-groups.service';
import { ShippingService } from '../../../service/cart/shipping.service';
import { ContentService } from '../../../service/content.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { ListrakService } from '../../../service/gtm/listrak.service';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-checkout',
  styleUrls: ['./checkout.component.scss'],
  templateUrl: './checkout.component.html',
})
export class CheckoutComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild('summaryEl') summaryEl: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onScroll(e): void {
    this.adjustPosition();
  }
  cart!: IOrder;
  cartItems: ICommerceItemWithCart[] = [];
  itemsAvailability = {};
  user!: IUser;
  customerPickup = false;
  completed = {
    shippingAddress: false,
    shipping: false,
    payment: false,
    review: false,
  };
  shippingAddressData!: ICheckoutShippingAddress;
  shippingAddressChanged: string = null;
  shippingInfo!: IShippingInfo;
  private shippingInfo$: Subject<IShippingInfo> = new Subject<IShippingInfo>();
  shippingInstruction!: string;
  shippingData!: ICheckoutShipping;
  shippingInfoReq!: IShippingInfoDto; // Tracking old options to merge new ones
  ocids = {};
  freightItems: IFreightTypeItems[] = [];
  showPayment = false;
  selectedCC!: ICreditCard;
  checkoutError = '';
  platformBrowser = false;
  private unsubscribe: Subject<void> = new Subject<void>();
  summaryPos = {
    right: '',
    position: '',
    top: '',
  };
  orderProducts = [];
  isCoreReturn = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private cartService: CartService,
    private router: Router,
    private route: ActivatedRoute,
    private localization: LocalizationService,
    private userService: CurrentUserService,
    private shippingService: ShippingService,
    private contentService: ContentService,
    private shippingGroupsService: ShippingGroupsService,
    private paymentService: PaymentGroupsService,
    private notificationService: NotificationService,
    private listrakService: ListrakService,
    private snackBar: MatSnackBar,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Get localization info
    this.localization.OCIDs.pipe(takeUntil(this.unsubscribe)).subscribe(
      (ocids) => (this.ocids = ocids)
    );
    this.localization
      .getOCIDs([
        'buy.in-stock',
        'buy.exclude-gst-message',
        'buy.item-subtotal',
        'buy.order-review-message',
        'checkout.order-review',
        'shopping-cart.availability-error',
        'buy.core-deposit'
      ])
      .subscribe();

    // Gather cart information
    this.route.data
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap((data) => {
          if (data.hasOwnProperty('pricing')) {
            return this.route.data['pricing'];
          }
          return this.cartService.getCart();
        }),
        mergeMap((cart: IOrder) => {
          // Check if they even have items in their cart, if not redirect back to cart
          if (cart.totalCommerceItemCount === 0) {
            this.router.navigate(['/cart']);
            return of();
          }
          this.customerPickup = cart.salesTypeCode === SalesType.Pickup;
          this.setCart(cart);
          // Only send to google analytics if the platform is browser.
          if (this.platformBrowser) {
            // Send order info to google analytics
            this.ecommService.checkout(
              cart.priceInfo.total,
              cart.priceInfo.currencyCode,
              this.orderProducts
            );
          }
          return this.cartService.getAvailability();
        })
      )
      .subscribe(
        (res: ICommerceItemAvailability[]) => {
          res.forEach((avail: ICommerceItemAvailability) => {
            this.itemsAvailability[avail.itemNumber] = {
              ...avail,
              warehouseVisible: false,
            };
          });
        },
        (err) => {
          console.log(err);
          // If the app is in DEV mode, show the developer error. If the app is not,
          // show the user friendly global error.
          this.notificationService.addError(
            isDevMode() ? err.error?.title : this.ocids['global.system-error']
          );
        }
      );

    // User subscription
    this.userService.userSubject
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: IUser | undefined) => {
        if (user) {
          this.user = user;
          // Set default shipping instructions
          this.shippingInstruction = user.defaultShippingInstructions
            ? user.defaultShippingInstructions
            : user.appConfig.defaultShippingInstructions;
        }
      });

    this.shippingInfo$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((info: IShippingInfo) => {
        // Loop through freight terms and organize cart items
        this.freightItems = info.freightTypes.map(
          (freightType: IShippingInfoFreightType) => {
            let items = [];

            if (freightType.itemDetails) {
              items = freightType.itemDetails.map(
                (freightItem: IShippingInfoFreightTypeItem) => {
                  // Get cart item based on itemNumber
                  const cartItem = this.cartItems.find(
                    (item: ICommerceItemWithCart) =>
                      item.itemNumber === freightItem.itemNumber
                  );
                  return { ...freightItem, ...cartItem };
                }
              );
            }
            return {
              type: freightType.freightShippingMethod,
              items: items,
            };
          }
        );
      });
  }

  /**
   * When user edits a specific step
   * @param step
   */
  onEditForm(step) {
    this.stepper.selectedIndex = step;
    this.onStepChange(step);
  }

  /**
   * When user selects a previous step
   * @param step
   */
  onStepChange(step) {
    switch (step) {
      case 0:
        this.completed.shippingAddress = false;
        this.completed.shipping = false;
        this.completed.payment = false;
        break;
      case 1:
        this.completed.shipping = false;
        this.completed.payment = false;
        break;
      case 2:
        this.completed.payment = false;
        break;
    }
  }

  /**
   * Event to set the shipping address changed value for the shipping
   * address section component.
   * @param {object} data
   */
  shippingAddressChangedEvent(data) {
    this.shippingAddressChanged = data.changed;
    // call item availability whenever user changes shipping address
    return this.cartService
      .getAvailability(data.shipToNumber)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res: ICommerceItemAvailability[]) => {
          res.forEach((avail: ICommerceItemAvailability) => {
            this.itemsAvailability[avail.itemNumber] = {
              ...avail,
              warehouseVisible: false,
            };
          });
        },
        (err) => {
          console.log(err);
          // If the app is in DEV mode, show the developer error. If the app is not,
          // show the user friendly global error.
          this.notificationService.addError(
            isDevMode() ? err.error?.title : this.ocids['global.system-error']
          );
        }
      );
  }

  /**
   * Event listener for when shipping form is submitted.
   *
   * @param {string} form
   * @param {object} data
   */
  onFormSubmit(form: string, data: object) {
    switch (form) {
      case 'shippingAddress':
        this.shippingAddressData = <ICheckoutShippingAddress>data;
        // If shipping info req is not set, set it now
        const body = {
          shipToNumber: this.shippingAddressData.shipTo?.shipToNumber,
        };

        // If shipping instructions are not set yet, set them now (first-time request)
        if (
          !this.shippingInfoReq ||
          !this.shippingInfoReq.shippingInstruction
        ) {
          body['shippingInstruction'] = this.shippingInstruction;
        }
        // Get shipping info for step 2
        this.updateShippingInfo(<IShippingInfoDto>body, () => {
          this.completed.shippingAddress = true;
          setTimeout(() => {
            this.stepper.next();
          }, 1);
        });
        break;
      case 'shipping':
        this.shippingData = <ICheckoutShipping>data;
        // Apply shipping info data

        this.applyShippingInfo()
          .pipe(mergeMap(() => this.cartService.getCart()))
          .subscribe((cart: IOrder) => {
            this.showPayment = true;
            this.ecommService.addShippingInfo(
              this.cart.priceInfo.total,
              this.cart.priceInfo.currencyCode,
              this.shippingData.shippingInstruction,
              this.orderProducts
            );
            this.setCart(cart);
            this.completed.shipping = true;
            setTimeout(() => {
              this.stepper.next();
            }, 1);
          });
        break;
      case 'payment':
        const paymentData = <IPaymentInfo>data;

        // Remove payment groups
        this.cartService
          .updateCart({
            PONumber: paymentData.poNum,
          })
          .pipe(
            mergeMap(() => {
              return this.paymentService.removeAllFromOrder(this.cart);
            }),
            mergeMap((order: IOrder | undefined) => {
              const data = {
                amount: this.cart.priceInfo.amount,
              };

              // Get payment type
              if (paymentData.creditCard) {
                data['paymentGroupClassType'] = 'tokenizedCreditCard';
                data['id'] = paymentData.creditCard.id;
              } else {
                data['paymentGroupClassType'] =
                  paymentData.paymentOption === 'myAccount'
                    ? 'account'
                    : 'payPal';
              }

              return this.paymentService.addToOrder(<IAddPaymentGroupDto>data);
            }),
            mergeMap(() => {
              return this.cartService.getCart();
            })
          )
          .subscribe(
            (cart: IOrder) => {
              this.setCart(cart);
              this.ecommService.addPaymentInfo(
                this.cart.priceInfo.total,
                this.cart.priceInfo.currencyCode,
                paymentData.paymentOption,
                this.orderProducts
              );
              this.completed.payment = true;
              setTimeout(() => {
                this.stepper.next();
              }, 1);
            },
            (err) => {
              // Do something with error
              console.log('checkout err', err);
            }
          );

        break;
    }
  }

  /**
   * Event listener on instructions changed.
   *
   * @param {string} shippingInstructions
   */
  public onInstructionsChanged(body: IUpdateInstructionDto) {
    const newBody: IShippingInfoDto = {
      ...{
        shipToNumber: this.shippingAddressData.shipTo.shipToNumber,
        shippingInstruction: body.shippingInstruction,
      },
      ...body.shipInfoFreightTerms,
    };

    this.updateShippingInfo(newBody);
  }

  /**
   * Event listener on freight terms changed.
   *
   * @param {IShippingInfoFreightTermDto} value
   */
  public onFreightTermChanged(value: IShippingInfoFreightTermDto) {
    const body = {
      ...{
        shippingInstruction: this.shippingInstruction,
        shipToNumber: this.shippingAddressData.shipTo.shipToNumber,
      },
      ...value,
    };
    return this.updateShippingInfo(<IShippingInfoDto>body);
  }

  /**
   * Update shipping information for shipping section.
   *
   * @param {IShippingInfoDto} request
   * @param {Function} callback
   */
  protected updateShippingInfo(request: IShippingInfoDto, callback?: Function) {
    // Get new shipping info
    this.notificationService.reset();

    // Log request if old one doesn't exist
    if (!this.shippingInfoReq) {
      this.shippingInfoReq = request;
    } else {
      // Old request exists so merge the two
      this.shippingInfoReq = Object.assign(this.shippingInfoReq, request);
    }

    // remove freightTerm3 and shipviaCode3 since they don't exist yet
    this.shippingInfoReq.hasOwnProperty('freightTerm3') &&
      delete this.shippingInfoReq['freightTerm3'];
    this.shippingInfoReq.hasOwnProperty('shipviaCode3') &&
      delete this.shippingInfoReq['shipviaCode3'];
    // Make request
    this.shippingService.getShippingInfo(this.shippingInfoReq).subscribe(
      (result: IShippingInfo) => {
        this.shippingInfo = result;
        this.shippingInfo$.next(result);
        this.shippingInstruction = request.shippingInstruction;
        // Log both freight terms so this is sent to getShippingInfo in future requests
        result.freightTypes.forEach(
          (value: IShippingInfoFreightType, index: number) => {
            if (index === 0) {
              this.shippingInfoReq.freightTerm1 = value.freightTerm;
            } else if (index === 1) {
              this.shippingInfoReq.freightTerm2 = value.freightTerm;
            }
          }
        );

        if (callback) {
          callback(result);
        }

        if (result.comment) {
          this.snackBar.open(result.comment, this.ocids['global.ok'], {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: 'fq-snack-bar-margin',
          });
        }
      },
      (err) => {
        console.log(err);
        // If the app is in DEV mode, show the developer error. If the app is not,
        // show the user friendly global error.
        this.notificationService.addError(
          isDevMode() ? err.error?.title : this.ocids['global.system-error']
        );
      }
    );
  }

  /**
   * Apply shipping info to order.
   *
   * @returns {Observable<Object>}
   */
  protected applyShippingInfo() {
    // Apply shipping info
    return this.shippingGroupsService.applyShippingInfo(<IApplyShippingInfoDto>{
      ...this.shippingData,
      ...{
        comment: this.shippingAddressData.comment,
        storeId: this.shippingAddressData.storeID,
        salesType: this.shippingAddressData.salesType,
        shipToNumber: this.shippingAddressData.shipTo.shipToNumber,
        shippingInstruction: this.shippingData.shippingInstruction,
        freightQuotedOrder: this.shippingInfo.freightQuotedOrder,
      },
    });
  }

  /**
   * Sets the selected card to the selected card or null if account is selected.
   *
   * @param {ICreditCard} creditCard
   */
  onSelectCC(creditCard: ICreditCard) {
    this.selectedCC = creditCard;
  }

  /**
   * Event listener for when order is submitted.
   */
  public onSubmitOrder() {
    this.checkoutError = '';
    this.notificationService.reset();
    this.cartService.checkout(this.cart).subscribe(
      (order: IOrder) => {
        // Only send to google analytics if the platform is browser.
        // Always pass 2 decimal places for price
        if (this.platformBrowser) {
          const gaItemsToAdd = [];
          order.commerceItems.items.map((item) => {
            gaItemsToAdd.push({
              item_id: item.itemNumber,
              item_name: item.productDisplayName,
              price: item.priceInfo.listPrice?.toFixed(2),
              id: item.itemNumber,
              quantity: item.quantity,
              name: item.productDisplayName,
              productId: item.itemNumber,
              item_category: item.categoryName,
            });
          });
          this.ecommService.purchase(order, gaItemsToAdd, this.user);
          // Listrak Purchase Conversion Event
          this.listrakService.placeOrder(this.user, order);
        }
        // Reroute to order confirmation
        this.router.navigate(['/checkout/order-confirmation', order.id]);
      },
      (err) => {
        console.log(err);
        // If the app is in DEV mode, show the developer error. If the app is not,
        // show the user friendly global error.
        this.checkoutError = err.error?.title;

        // Only execute the following if the platform is browser.
        if (this.platformBrowser) {
          this.contentService.scrollUserToTop();
        }
      }
    );
  }

  /**
   * Set cart property.
   *
   * @param {IOrder} cart
   */
  protected setCart(cart: IOrder) {
    // Populate cart information
    this.cart = cart;

    // Populate cart items
    const items = cart.commerceItems.items;
    this.cartItems = items ? items : [];
    // Reset orderProducts
    this.orderProducts = [];
    for (let i = 0; i < cart.commerceItems.items.length; i++) {
      this.orderProducts.push({
        item_name: cart.commerceItems.items[i].productDisplayName,
        item_id: cart.commerceItems.items[i].itemNumber,
        item_category: cart.commerceItems.items[i].categoryName,
        price: cart.commerceItems.items[i].priceInfo.listPrice,
        quantity: cart.commerceItems.items[i].quantity,
      });
    }

    // Determine whether or not to show the core deposit line
    this.isCoreReturn = !!cart.coreReturnExist && !!Number(cart.priceInfo?.coreReturnAmountTotal) && this.user.erpEnvironment === UserEnvironments.AA && environment.jlgStyling;
  }

  /**
   * adjust cart summary position
   *
   */
  adjustPosition(): void {
    if (window.innerWidth > 1110 && this.summaryEl) {
      const summaryDiv = this.summaryEl.nativeElement.getBoundingClientRect();
      const checkoutDiv = document
        .getElementById('checkoutMain')
        .getBoundingClientRect();
      if (
        window.pageYOffset - 100 > summaryDiv.top &&
        checkoutDiv.top + checkoutDiv.height >
          Math.abs(summaryDiv.top) + summaryDiv.height
      ) {
        this.summaryPos.position = 'fixed';
        this.summaryPos.right = checkoutDiv.left + 'px';
        this.summaryPos.top = '84px';
      } else {
        this.summaryPos.position = '';
        this.summaryPos.right = '';
        this.summaryPos.top = '';
      }
    }
  }

  ngOnDestroy() {
    this.snackBar.dismiss(); // close the FQ snack bar after checking out: https://jlgaccessit.atlassian.net/browse/O20R-5683
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
