import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { MatDialog } from '@angular/material/dialog';
import { ControlDownloadComponent } from './control-download/control-download.component';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-control-detail',
  templateUrl: './control-detail.component.html',
})
export class ControlDetailComponent implements OnInit {
  @Input() data!: {
    controlSoftwareInfo: {
      damFileSize: string;
      damUrl: string;
      description: string;
      modelNumber: string;
      modelSeries: string;
      modelType: string;
      moduleName: string;
      modulePartNumber: string;
      notes: string;
      softwarePartNumber: string;
      softwareVersion: string;
    };
  };
  ocids: OcidItems = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private dialog: MatDialog,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization
      .getOCIDs([
        'control-software.adobe-message',
        'control-software.software-part-number-label',
        'control-software.module-part-number-label',
        'web2case.level2.software-version',
        'cs.module-name-label',
        'cs.software-description-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  downloadFile() {
    this.dialog
      .open(ControlDownloadComponent)
      .afterClosed()
      .subscribe((download?) => {
        if (download && isPlatformBrowser(this.platformId)) {
          window.open(this.data.controlSoftwareInfo.damUrl);
          const dataLayer = (this.winRef.nativeWindow.dataLayer =
            this.winRef.nativeWindow.dataLayer || []);
          dataLayer.push({
            event: 'controlSoftwareDownload',
            docName: this.data.controlSoftwareInfo.description,
          });
        }
      });
  }
}
