import {Component, Input, OnInit} from '@angular/core';
import {CartridgeInterface} from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-content-slot-header',
  templateUrl: './content-slot-header.component.html'
})
export class ContentSlotHeaderComponent implements OnInit, CartridgeInterface {
  @Input('data') data: {
    contents: object[]
  };

  constructor() { }

  ngOnInit() {
    //
  }

}
