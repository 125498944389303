import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table-footer',
  templateUrl: './pricing-plan-table-footer.component.html',
  styleUrls: ['./pricing-plan-table-footer.component.scss'],
})
export class PricingPlanTableFooterComponent {
  @HostBinding('class') hostClass = 'pricing-plan-table-footer';
}
