import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyService } from '../../../clearsky.service';
import { combineLatest, Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import { Machine } from '../../../../contracts/clearsky/machine/machine.dto';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { CsAggCountData } from '../../../../contracts/clearsky/agg-data';
import { CSRefBasic } from '../../../../contracts/clearsky/clearsky-legend';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-hardware-reporting-metric',
  templateUrl: './hardware-reporting-metric.component.html',
  styleUrls: ['./hardware-reporting-metric.component.scss'],
})
export class HardwareReportingMetricComponent implements OnInit {
  chartEl: ElementRef;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  displayName = WidgetsDisplay.hardwareReportingStatus;
  widgetName = Widgets.HARDWARE_REPORTING_METRIC;
  machines: Machine[] = [];
  ocids: OcidItems = {};
  showChart = false;
  isLoading = true;
  private plot;
  private aggData: CsAggCountData[];
  private legend: CSRefBasic[];
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.localization.getOCIDs([
        this.displayName,
        'clearsky.number-label',
        'clearsky.graphic-default',
      ]),
      this.clearskyService.legendRef$,
    ]).subscribe(([page, ocids, legend]) => {
      this.ocids = ocids;
      this.aggData = (page && page.aggregations && page.aggregations.hrsFlags) || [];
      this.legend = legend.hrs || [];
      this.showChart = true;
      this.isLoading = false;

      // Does the chart already exist?
      if (this.plot && this.showChart) {
        this.setChartData();
      }
    });
  }

  /**
   * Set data for chart.
   * @protected
   */
  protected setChartData(): void {
    // Redraw data points
    this.plot.series[0].setData(this.getSeriesData());
  }

  /**
   * Create the chart.
   * @protected
   */
  protected createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    const chartData = mergeDeep(cloneDeep(BaseChartConfig), {
      chart: {
        type: 'bar',
      },
      xAxis: {
        categories: this.legend.map((l) => l.desc),
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: this.ocids['clearsky.number-label'],
        },
        labels: {
          overflow: 'justify',
        },
      },
      tooltip: {
        valueSuffix: ' machines',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          color: WidgetColors.blue,
          cursor: 'pointer',
          data: this.getSeriesData(),
          events: {
            click: (event: { point: { custom: { key: number } } }) => {
              this.clearskyService.updateFilter(CSFilter.hrm.key, [
                event.point.custom.key,
              ]);
            },
          },
        },
      ],
    });

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      chartData as unknown
    );
  }

  /**
   * Get series data.
   * @protected
   */
  protected getSeriesData(): object[] {
    return this.aggData.map((cat, index) => {
      const label = this.legend.find((l) => l.id === cat.id);

      return {
        x: index,
        y: cat.cnt,
        name: label?.desc,
        custom: {
          key: cat.id,
        },
      };
    });
  }
}
