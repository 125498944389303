import { HistoricSelection, Machine } from './machine.dto';

const getHistoricProperty = (
  mapping: { [historic: string]: unknown },
  historic: string
): unknown => {
  return mapping[historic] ?? mapping[HistoricSelection.HOURS_24];
};

export const getHistoricMachineUtilizationProperty = (
  machine: Machine,
  historic: string
): number => {
  return getHistoricProperty(
    {
      [HistoricSelection.HOURS_24]: machine.miuS,
      [HistoricSelection.DAYS_7]: machine.miuS7d,
      [HistoricSelection.DAYS_14]: machine.miuS14d,
      [HistoricSelection.DAYS_31]: machine.miuS31d,
      [HistoricSelection.DAYS_90]: machine.miuS90d,
    },
    historic
  ) as number;
};

export const getHistoricMachineEnergyUtilizationProperty = (
  machine: Machine,
  historic: string
): number => {
  return getHistoricProperty(
    {
      [HistoricSelection.HOURS_24]: machine.fuelS,
      [HistoricSelection.DAYS_7]: machine.fuelS7d,
      [HistoricSelection.DAYS_14]: machine.fuelS14d,
      [HistoricSelection.DAYS_31]: machine.fuelS31d,
      [HistoricSelection.DAYS_90]: machine.fuelS90d,
    },
    historic
  ) as number;
};

export const getHistoricMachineEnvelopeProfileProperty = (
  machine: Machine,
  historic: string
): { [zone: string]: number } => {
  return getHistoricProperty(
    {
      [HistoricSelection.HOURS_24]: machine.ezT,
      [HistoricSelection.DAYS_7]: machine.ezT7d,
      [HistoricSelection.DAYS_14]: machine.ezT14d,
      [HistoricSelection.DAYS_31]: machine.ezT31d,
      [HistoricSelection.DAYS_90]: machine.ezT90d,
    },
    historic
  ) as { [zone: string]: number };
};
