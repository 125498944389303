import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEndecaPayloadResponse } from '../../../../../contracts/IEndecaPayloadResponse';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-order-iframe',
  templateUrl: './order-iframe.component.html',
})
export class OrderIFrameComponent implements OnInit {
  payload: object;
  iFrameTitle: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.iFrameTitle = this.route.snapshot.data['breadcrumb'];
    this.route.data.subscribe((data: { response: IEndecaPayloadResponse }) => {
      this.payload = data.response.payload;
    });
  }
}
