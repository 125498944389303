import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { IAddSupersededItemDto } from '../../../contracts/commerce/dto/iadd-superseded-item.dto';
import { IRemoveItemDto } from '../../../contracts/commerce/dto/iremove-item.dto';
import { ICodeAndDesc } from '../../../contracts/commerce/icode-and-desc';
import { ICommerceItemAvailability } from '../../../contracts/commerce/icommerce-item-availability';
import { ICommerceItemWithCart } from '../../../contracts/commerce/icommerce-item-with-cart';
import { ICommerceSpecialConditions } from '../../../contracts/commerce/icommerce-special-conditions';
import { IOrder, SalesType } from '../../../contracts/commerce/iorder';
import { IProduct } from '../../../contracts/product/iproduct';
import { IAddress } from '../../../contracts/user/iaddress';
import { INavMenu } from '../../../contracts/user/inav-menu';
import { IUser, UserEnvironments } from '../../../contracts/user/iuser';
import { OktaAuthWrapper } from '../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { CartService } from '../../../service/cart/cart.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { ProductsApiService } from '../../../service/product/products-api.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { MenuService } from '../../../service/user/menu.service';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { CustomerPickupDialogComponent } from './customer-pickup-dialog/customer-pickup-dialog.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { environment } from '../../../../environments/environment';
import { ContentService } from 'app/service/content.service';
import { PartsOptions } from '../web2case/request-info-form/parts-form/parts-form.component';

interface QuantityUpdate {
  oldQty: number;
  newQty: number;
}
// Enhance component to not rely on subjects for cart items/cart info - cart item count can still be a subject due to header info
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shopping-cart',
  styleUrls: ['./shopping-cart.component.scss'],
  templateUrl: './shopping-cart.component.html',
})
export class ShoppingCartComponent implements OnInit {
  @Input() data!: {
    cartContent: object[];
  };
  @ViewChild('confirmDialog', { read: ViewContainerRef })
  confirmDialog!: ViewContainerRef;
  @ViewChild('addPartItemNum') addPartItemNum!: ElementRef;
  user!: IUser;
  isLoggedIn = false;
  cart!: IOrder;
  canCheckout = true;
  cartItems: ICommerceItemWithCart[] = [];
  consultItemInfo: IProduct[] = [];
  phantomItemInfo: IProduct[] = [];
  conditions!: ICommerceSpecialConditions;
  itemAvailability = {};
  itemsToRemove: ICommerceItemWithCart[] = [];
  itemsWarehouse = [];
  orderNowForm!: UntypedFormGroup;
  invalidItemNum = false;
  itemNum = '';
  salesType: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  salesTypes!: ICodeAndDesc[];
  salesTypeCode = SalesType;
  ocids = {};
  orderTypeText!: string;
  editSerialNums = false;
  customerPickupAddress: IAddress = null;
  warehouseId: string = null;
  inSufficientQuantity = false;
  platformBrowser = false;
  public isReady = false;
  private inProxy = null;
  showWarehouse = false;
  isCoreReturn = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private oktaAuth: OktaAuthWrapper,
    private cartService: CartService,
    public dialog: MatDialog,
    private confirmationService: ConfirmationDialogsService,
    private menuService: MenuService,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private currentUserService: CurrentUserService,
    private localization: LocalizationService,
    private zone: NgZone,
    private productsService: ProductsApiService,
    private notificationService: NotificationService,
    private ecommService: EcommerceService,
    private cookieService: ContentService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn.subscribe(
      (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    );

    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });

    this.localization
      .getOCIDs([
        'buy.shopping-cart-header',
        'buy.item-number-error',
        'buy.shopping-cart-empty-message',
        'buy.checkout',
        'buy.economy-order-label',
        'buy.exclude-gst-message',
        'buy.shopping-cart-move-all-list',
        'buy.shopping-cart-order-now',
        'browse.no-result-text',
        'buy.item-number-invalid',
        'buy.in-stock',
        'buy.shopping-cart-order-now-link',
        'buy.shopping-cart-remove',
        'buy.remove-item',
        'checkout.unit-price-info',
        'shopping-cart.availability-error',
        'shopping-cart.checkout-disabled-message',
        'shopping-cart.customerpickup-availability-cart-error',
        'shopping-cart.customer-pickup-location',
        'shopping-cart.economy-order',
        'shopping-cart.economy-order.ee',
        'shipping-type.info-customer-pickup',
        'global.order-type-conventional',
        'global.order-type-economy',
        'global.order-type-priority',
        'buy.order-type-retrofit16',
        'shopping-list.remove-item',
        // ConsultFactoryComponent OCIDs
        'buy.consult-factory-consent',
        // ConsultFactoryWarningComponent OCIDs
        'global.attachments',
        // PhantomWarningComponent OCIDs
        'buy.phantom-qty-req-label',
        'shopping-cart.phantom-item-add-component-button',
        // Psr289SerialNumOptWarningComponent OCIDs
        'buy.retrofit-item-remove-message',
        // Psr289SerialNumReqWarningComponent OCIDs
        'shopping-cart.retrofit-serial-number-error1',
        'shopping-cart.retrofit-serial-number-message-error2',
        'shopping-cart.equipment-part-error-message',
        'shopping-cart.warehouse-details-label',
        'surcharge.info-text',
        'core-return.info-message',
        'buy.core-deposit',
      ])
      .subscribe(() => {
        // Update order type text
        this.orderTypeText =
          this.ocids['global.order-type-priority'] +
          '\n\n' +
          this.ocids['global.order-type-conventional'] +
          '\n\n' +
          this.ocids['global.order-type-economy'] +
          '\n\n' +
          this.ocids['shipping-type.info-customer-pickup'];
      });
    // Get the secure buttons menu.
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('secure-buttons') : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          // Determine if the user can checkout if the checkout button is included in the secure buttons menu.
          this.canCheckout =
            menu.childMenus.filter(
              (subMenu: INavMenu) => subMenu.uxKey === 'checkout-button'
            ).length > 0;
        }
      });

    // Subscribe to subject's on page load
    this.currentUserService.userSubject
      .pipe(
        mergeMap((user: IUser) => {
          if (user) {
            // Set default order type
            this.user = user;
            // If inProxy is null it means the page is being navigated to. In this case, refresh the cart. This
            // logic helps prevent multiple calls being made to get cart info when the currentUser subscription is updated.
            // This issue was found when proxying and navigating to the same url, since the user subject was being updated
            // AND the page was being refreshed, the cart payload was being called twice. Only refresh the cart on page refresh.
            if (this.inProxy === null) {
              this.inProxy = user.inProxy;
              return this.refreshCart();
            }
          }
          return of(null);
        })
      )
      .subscribe(
        (cart: IOrder) => {
          if (cart) {
            this.setCart(cart);
            this.isReady = true;
            this.ecommService.viewCart(cart);
          }
        },
        (err) => {
          console.log(err);
        }
      );

    // Create order now form on right hand side of screen
    this.orderNowForm = this._formBuilder.group({
      itemNum: ['', Validators.required],
      qty: [],
    });

    this.cartService.cartRefresh$.subscribe(() => this.initiateCartRefresh());
  }

  printPage() {
    // Only print the cart page if the platform is browser.
    if (this.platformBrowser) {
      window.print();
    }
  }

  /**
   * Refresh cart.
   *
   * @param {IOrder} cart
   * @returns {Observable<IOrder>}
   */
  protected refreshCart(cart?: IOrder): Observable<IOrder> {
    // First getting item availability
    return (this.isLoggedIn ? this.cartService.getAvailability() : of([])).pipe(
      catchError(() => of([])),
      mergeMap((res) => {
        // If availability was returned
        if (res.length) {
          // Only show warehouse if it's AA or EE
          if (
            this.user.erpEnvironment === UserEnvironments.AA ||
            this.user.erpEnvironment === UserEnvironments.EE
          ) {
            this.showWarehouse = true;
          }
          const backOrderitems = [];
          // Look through item availability and create an object representation of the response.
          (<ICommerceItemAvailability[]>res).forEach(
            (avail: ICommerceItemAvailability) => {
              this.itemAvailability[avail.itemNumber] = avail;
              if (avail.backOrderable && avail.backOrderMessage && !avail.displayEstimatedShipDate) {
                backOrderitems.push(avail.itemNumber);
              }
            }
          );
          if (backOrderitems.length) {
            this.cookieService.createCookie('partNumForPrice', backOrderitems.join(','));
            this.cookieService.createCookie('level2Selection', PartsOptions.AVAILABILITY);
          }
        }
        // Next retrieve the cart payload.
        return cart ? of(cart) : this.cartService.getCartWithPricing();
      }),
      exhaustMap((cart: IOrder) => {
        const items = cart.commerceItems.items ? cart.commerceItems.items : [];

        // Update special conditions (like pricing not set)
        const conditions = this.cartService.getSpecialConditionsList(items);
        // Determine if sales type is customer pickup and if any items have a quantity greater than what is available.
        this.inSufficientQuantity = false;
        if (cart.salesTypeCode === SalesType.Pickup) {
          items.forEach((item: ICommerceItemWithCart) => {
            if (item.customerPickupAction) {
              if (item.customerPickupAction === 'inSufficientQuantity') {
                this.inSufficientQuantity = true;
              }
            }
          });
        }
        // Now get item info for each consult factory item if any
        const consultFactoryItems: string[] = conditions.consultFactory.map(
          (item: ICommerceItemWithCart) => item.itemNumber
        );
        const phantomItems: string[] = conditions.phantom.map(
          (item: ICommerceItemWithCart) => item.itemNumber
        );

        return forkJoin(
          of(cart),
          consultFactoryItems.length
            ? this.productsService.getProducts(consultFactoryItems)
            : of([]),
          phantomItems.length
            ? this.productsService.getProducts(phantomItems)
            : of([]),
          this.cartService.getSalesTypes()
        );
      }),
      mergeMap((res) => {
        // Set item info by itemNumber
        res[1].forEach((info: IProduct) => {
          if (info.crossRefParts?.length) {
            info.crossRefParts.forEach((itemNumber: string) => {
              this.consultItemInfo[itemNumber] = info;
            })
          }
          this.consultItemInfo[info.itemNumber] = info;
        });

        // Set itme info by itemNumber
        res[2].forEach((info: IProduct) => {
          if (info.crossRefParts?.length) {
            info.crossRefParts.forEach((itemNumber: string) => {
              this.phantomItemInfo[itemNumber] = info;
            })
          }
          this.phantomItemInfo[info.itemNumber] = info;
        });

        // Set the new sales types based on salesTypes endpoint.
        const newSalesTypeCodes: ICodeAndDesc[] = <ICodeAndDesc[]>res[3];

        if (newSalesTypeCodes.length) {
          this.salesTypes = newSalesTypeCodes;
          // If the sales types include S16 or S19, set the dropdown value to it.
          const includesS16: boolean =
            this.salesTypes.filter(
              (salesType: ICodeAndDesc) => salesType.code === 'S16'
            ).length > 0;
          const includesS19: boolean =
            this.salesTypes.filter(
              (salesType: ICodeAndDesc) => salesType.code === 'S19'
            ).length > 0;
          if (includesS16) {
            this.salesType.setValue('S16');
          } else if (includesS19) {
            this.salesType.setValue('S19');
          } else if (res[0].salesTypeCode) {
            // If the active sales type value does not equal S16 or S19 and this cart has a sales type, override user's default
            // and set it to the cart sales type code.
            this.salesType.setValue(res[0].salesTypeCode);
          }
        }

        // Update special conditions (like pricing not set)
        this.conditions = null;
        this.conditions = this.cartService.getSpecialConditionsList(
          res[0].commerceItems.items ? res[0].commerceItems.items : []
        );

        return of(res[0]);
      })
    );
  }

  /**
   * Set cart.
   *
   * @param {IOrder} cart
   */
  protected setCart(cart: IOrder) {
    this.cart = cart;

    const items = cart.commerceItems.items;
    this.cartItems = items ? items : [];

    this.itemsToRemove.length = !items && 0; // empty this array if cart is empty
    if (items?.length) {
      this.itemsWarehouse = this.cartItems.map((item) => {
        return {
          itemNum: item.itemNumber,
          open: false,
        };
      });
    }

    const warehouseId: string = this.cart.pickupWarehouse;
    if (warehouseId) {
      this.customerPickupAddress =
        this.cart.shippingGroups.items[0].shippingAddress;
      this.warehouseId = warehouseId;
    }

    // Determine whether or not to show the core deposit line
    this.isCoreReturn =
      !!cart.coreReturnExist &&
      !!Number(cart.priceInfo?.coreReturnAmountTotal) &&
      this.user.erpEnvironment === UserEnvironments.AA &&
      environment.jlgStyling;
  }

  /**
   * Determines if the item is found or not. If the item is not found, it sets a not found
   * error on the form control. Otherwise it resets the form control errors.
   */
  checkItemFound() {
    const field = this.orderNowItem;
    if (field.value !== '') {
      this.productsService
        .getProduct(field.value)
        .subscribe((product: IProduct) => {
          // Check if item was found
          if (product.itemNotFound) {
            field.setErrors({ itemNotFound: true });
          } else {
            field.setErrors(null);
          }
        });
    }
  }

  /**
   * Event emitter when order now form is submitted.
   *
   * @return void
   */
  addItem() {
    // Make request
    if (this.orderNowForm.valid) {
      this.notificationService.reset();
      const itemNum = this.orderNowForm.get('itemNum').value;
      // Sets the item quantity to the quantity form control but if the quantity is not given
      // given we default the quantity to 1 and add it to the cart.
      const itemQty = this.orderNowForm.get('qty').value
        ? this.orderNowForm.get('qty').value
        : 1;

      this.cartService
        .addOneToCart(itemNum, +itemQty)
        .pipe(
          mergeMap(() => {
            return this.refreshCart();
          })
        )
        .subscribe({
          next: (cart: IOrder) => {
            this.setCart(cart);
            this.orderNowForm.reset();

            // Send Add to Cart action to GA if the platform is browser
            if (this.platformBrowser) {
              this.ecommService.addToCart(
                itemNum,
                cart.orderItemType,
                '',
                +itemQty,
                'Order Now - Cart'
              );
              // Focus on item number input now
              this.addPartItemNum.nativeElement.focus();
            }
          },
          error: (err) => {
            console.log(err);

            this.notificationService.addError(err.error?.title);
          }
        });
    } else if (
      this.orderNowItem.errors ? this.orderNowItem.errors['required'] : false
    ) {
      // If there are errors on the item number and it is the required error, set the required error
      // on the form control to true and mark the input as dirty.
      this.orderNowItem.setErrors({ required: true });
      this.orderNowItem.markAsDirty();
    }
  }

  /**
   * When event is called when an item is being removed.
   *
   * @param {IRemoveItemDto} params
   */
  onRemoveItem(params: IRemoveItemDto) {
    if (!params.showDialog) {
      return this.removeItem(params.item);
    }

    // Show dialog first
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          return this.removeItem(params.item);
        }
      });
  }

  /**
   * Event listener for triggering the edit of serial numbers.
   */
  onTriggerEditSerialNums() {
    // Rebuild the psr289 serial number required condition or else you won't be able to populate
    // the psr289 condition box.
    this.cartItems.forEach((item: ICommerceItemWithCart) => {
      if (item.psr289 && item.serialNumReq) {
        this.conditions.psr289serialReq.push(item);
      }
    });
    this.editSerialNums = true;
    this.conditions.actionRequired = true;
  }

  /**
   * Get non-machine items
   *
   * @returns {ICommerceItemWithCart | undefined}
   */
  hasMachineItems(): ICommerceItemWithCart {
    return this.cartItems.find(
      (val: ICommerceItemWithCart) => val.productType === 'machine'
    );
  }

  /**
   * Remove item from cart.
   *
   * @param {ICommerceItemWithCart} item
   */
  removeItem(item: ICommerceItemWithCart) {
    const availability = this.itemAvailability[item.itemNumber];
    this.notificationService.reset();
    this.cartService
      .removeFromCart(
        item,
        'Shopping Cart',
        this.isLoggedIn ? this.user.customerNumber : undefined,
        this.isLoggedIn ? availability.totalAvailability : undefined,
        this.isLoggedIn ? (availability.backOrderMessage ? availability.estimatedShipDate : 'n/a') : undefined
      )
      .pipe(
        mergeMap(() => {
          return this.refreshCart();
        })
      )
      .subscribe(
        (cart: IOrder) => {
          this.setCart(cart);
        },
        (err) => {
          console.log(err);
          this.notificationService.addError(err.error?.title);
        }
      );
  }

  /**
   * Removes all items in the cart except the one item number that was selected.
   *
   * @param {string} itemNumber
   */
  onRemoveAllExcept(itemNumber: string) {
    // Remove added items ( ugly for now since only one item can be removed at a time, future enhancement? )
    this.zone.runOutsideAngular(() => {
      return this.cartService
        .removeAll(
          this.cartItems.filter((item) => item.itemNumber !== itemNumber),
          'Shopping Cart'
        )
        .subscribe(
          () => {
            this.zone.run(() => {
              this.initiateCartRefresh();
            });
          },
          (err) => {
            this.zone.run(() => {
              this.notificationService.addError(err.error?.title);
            });
          }
        );
    });
  }

  /**
   * Removes all items in the cart.
   *
   * @param {boolean} removeAll
   */
  onRemoveAll(removeAll: boolean) {
    const items = removeAll ? this.cartItems : this.itemsToRemove;
    // Show dialog first
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          // Remove all items ( ugly for now since only one item can be removed at a time, future enhancement? )
          this.zone.runOutsideAngular(() => {
            return this.cartService.removeAll(items, 'Shopping Cart').subscribe(
              () => {
                this.zone.run(() => {
                  this.initiateCartRefresh();
                });
              },
              (err) => {
                this.zone.run(() => {
                  this.notificationService.addError(err.error?.title);
                });
              }
            );
          });
        }
      });
  }

  /**
   * Event emitter when user clicks checkout button.
   *
   * @returns {Promise<boolean>}
   */
  public onCheckout() {
    // Verify endpoint before continuing to checkout that cart is indeed valid
    if (this.salesType.value !== null) {
      // Check if Economy Order Type is an optional sales type.
      const containsEconomyType = this.salesTypes.find(
        (salesType: ICodeAndDesc) => salesType.code === SalesType.Economy
      );
      // If the sales type options container Economy Order and check if they have it selected and that
      // sales type does not equal Customer Pickup

      if (
        containsEconomyType &&
        this.salesType.value !== SalesType.Economy &&
        this.salesType.value !== SalesType.Pickup
      ) {
        // Prompt the user to see if they want to use economy type.
        this.confirmationService
          .confirm(
            this.ocids['buy.economy-order-label'],
            this.user.erpEnvironment === UserEnvironments.EE
              ? this.ocids['shopping-cart.economy-order.ee']
              : this.ocids['shopping-cart.economy-order'],
            this.ocids['global.update'],
            this.ocids['global.no-thanks']
          )
          .subscribe((result) => {
            // If the user decides to update to Economy.
            if (result) {
              // Set the sales type value to Economy
              this.salesType.setValue(SalesType.Economy);
              // Update the cart and get any changes as well as navigate to checkout.
              this.onChangeSalesType(true);
            } else {
              // If the user decides to cancel, send them to checkout.
              this.router.navigate(['/checkout']);
            }
          });
      } else if (
        !containsEconomyType &&
        this.salesType.value === SalesType.Economy
      ) {
        // If there is no sales type physically selected however it is programmatically set. This is
        // after a user had economy order type selected and they remove enough items from the cart to
        // where they don't qualify for economy anymore.
        console.log('No valid sales type selected.');
      } else {
        // If economy was not an option, let them proceed.
        this.router.navigate(['/checkout']);
      }
    }
  }

  /**
   * Event emitted when moving cart to shopping list.
   */
  moveToList(moveAll: boolean) {
    const items = moveAll ? this.cartItems : this.itemsToRemove;
    if (this.isLoggedIn) {
      this.dialog
        .open(ShoppingListComponent, {
          width: '380px',
          data: {
            items: items.map((item: ICommerceItemWithCart) => {
              return {
                itemNumber: item.itemNumber,
                quantity: item.quantity,
              };
            }),
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.notificationService.reset();
            // Remove added items ( ugly for now since only one item can be removed at a time :( Future enhancement? )
            this.zone.runOutsideAngular(() => {
              return this.cartService
                .removeAll(items, 'Shopping Cart - Add to Shopping List')
                .pipe(mergeMap(() => this.refreshCart()))
                .subscribe(
                  (cart: IOrder) => {
                    this.zone.run(() => {
                      this.setCart(cart);
                    });
                  },
                  (err) => {
                    this.zone.run(() => {
                      this.notificationService.addError(err.error?.title);
                    });
                  }
                );
            });
          }
        });
    } else {
      this.triggerSignInModal();
    }
  }

  triggerSignInModal() {
    this.dialog
      .open(SignInDialogComponent, {
        panelClass: ['sign-in-dialog'],
        width: '720px',
      })
      .afterClosed()
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this.router.navigate(['/cart']);
        }
      });
  }

  /**
   * Event emitter when selecting option in sales type dropdown.
   *
   * @param {boolean} navToCheckout
   */
  onChangeSalesType(navToCheckout?: boolean) {
    const salesTypeCode: string = this.salesType.value;
    // If the sales type is customer pickup, execute the customer pickup logic.
    if (salesTypeCode === SalesType.Pickup) {
      this.checkCustomerPickup();
    } else {
      // If the sales type is not customer pickup, set customer pickup address and warehouseId to null.
      this.customerPickupAddress = null;
      this.warehouseId = null;
      this.updateSalesType(salesTypeCode, navToCheckout);
    }
  }

  /**
   * Updates the sales type.
   * @param {string} salesTypeCode
   * @param {boolean} navToCheckout
   */
  updateSalesType(salesTypeCode: string, navToCheckout?: boolean) {
    // Update cart
    this.notificationService.reset();

    this.cartService
      .updateCart({
        salesTypeCode: salesTypeCode,
      })
      .pipe(mergeMap(() => this.refreshCart()))
      .subscribe(
        (cart: IOrder) => {
          this.setCart(cart);

          // If navToCheckout is true, we want to send the user to the checkout page. This is used
          // for when the user has been prompted to choose economy order type, they have chosen to
          // update it, and are now being sent to checkout once it has been updated.
          if (navToCheckout) {
            this.router.navigate(['/checkout']);
          }
        },
        (err) => {
          this.notificationService.addError(err.error?.title);
        }
      );
  }

  /**
   * Performs logic and takes action if sales type is customer pickup. See comments below for more detail.
   */
  checkCustomerPickup() {
    // Determine if a customer pickup action is required.
    let customerPickupActionRequired = false;
    // If there are items in the cart.
    if (this.cart.commerceItems.items) {
      this.cart.commerceItems.items.forEach((item: ICommerceItemWithCart) => {
        if (item.customerPickupAction) {
          customerPickupActionRequired = true;
        }
      });
      // If action is not required, allow the user to proceed.
      if (!customerPickupActionRequired) {
        // ... and the user has a warehouse selected, set the warehouse.
        if (this.cart.pickupWarehouse) {
          // make sure to update the sales type
          this.updateSalesType(this.salesType.value);
        } else {
          // ... if the user does not have a shipping address selected, open the customer pickup dialog box for the
          // user to select a warehouse.
          this.openCustomerPickup();
        }
      }
    }
  }

  /**
   * Event listener for when cart item quantity is updated.
   *
   * @param {QuantityUpdate} quantity
   * @param {ICommerceItemWithCart} item
   */
  public onUpdateQuantity(
    quantity: QuantityUpdate,
    item: ICommerceItemWithCart
  ) {
    this.notificationService.reset();
    // The quantity has changed so let's make the API request!
    this.cartService
      .updateQuantity(item, quantity.newQty)
      .pipe(mergeMap(() => this.refreshCart()))
      .subscribe({
        next: (cart: IOrder) => {
          this.setCart(cart);
          const source = 'Shopping Cart - Update Quantity';
          // send add or remove event to GA if the platform is browser
          if (this.platformBrowser) {
            this.ecommService.addToCart(
              item.itemNumber,
              cart.orderItemType,
              item.productDisplayName,
              +(quantity.newQty - quantity.oldQty),
              source
            );
          } else {
            const availability = this.itemAvailability[item.itemNumber];
            this.ecommService.remove(
              item,
              source,
              +(quantity.oldQty - quantity.newQty),
              this.user.customerNumber,
              this.user.companyName,
              availability.totalAvailability,
              availability.backOrderMessage
                ? availability.estimatedShipDate
                : 'n/a'
            );
          }
        },
        error: (err) => {
          // Log error
          this.notificationService.addError(err.error?.title);
        }
      });
  }

  /**
   * Confirm warning of a commerce item.
   *
   */
  public initiateCartRefresh() {
    // Set edit serial number to false to hide the serial number box.
    this.editSerialNums = false;

    this.refreshCart().subscribe(
      (cart: IOrder) => {
        this.setCart(cart);
      },
      (err) => {
        // Log error
        console.log(err);
        this.notificationService.addError(err.error?.title);
      }
    );
  }

  /**
   * Event listener when pricing not setup items are removed.
   */
  public pricingNotSetupRemoved() {
    this.initiateCartRefresh();
  }

  /**
   * Event listener when invalid parts are removed.
   */
  public invalidPartsRemoved() {
    this.initiateCartRefresh();
  }

  /**
   * Event listener for toggling the warehouse list view.
   *
   * @param {ICommerceItemWithCart} item
   */
  onWarehouseViewToggle(item) {
    // Set warehouse view to open or close when toggled
    this.itemsWarehouse.find((i) => i.itemNum === item.itemNumber).open =
      !this.itemsWarehouse.find((i) => i.itemNum === item.itemNumber).open;
  }

  showWarehouseList(itemNumber) {
    return this.itemsWarehouse.find((i) => i.itemNum === itemNumber).open;
  }

  /**
   * Event listener for adding superseded item to cart.
   *
   * @param {IAddSupersededItemDto} info
   */
  public addSupersededItem(info: IAddSupersededItemDto) {
    this.notificationService.reset();
    this.cartService
      .removeFromCart(
        info.supersedeItem,
        'Superseded Item',
        this.user.customerNumber
      )
      .pipe(
        mergeMap(() =>
          this.cartService.addOneToCart(
            info.supersededItemNumber,
            info.supersededQuantity
          )
        ),
        mergeMap(() => this.refreshCart())
      )
      .subscribe({
        next: (cart: IOrder) => {
          this.setCart(cart);
          // Send Add to Cart action to GA if the platform is browser
          if (this.platformBrowser) {
            this.ecommService.addToCart(
              info.supersededItemNumber,
              cart.orderItemType,
              info.supersedeItem.productDisplayName,
              info.supersededQuantity,
              'Superseded Item'
            );
          }
        },
        error: (err) => {
          this.notificationService.addError(err.error?.title);
        }
      });
  }

  /**
   * Opens the customer pickup modal for selection or edit.
   */
  openCustomerPickup() {
    const dialogRef = this.dialog
      .open(CustomerPickupDialogComponent, {
        width: '350px',
        data: {
          warehouseId: this.warehouseId,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        // Update the sales type and refresh the cart.
        this.updateSalesType(this.salesType.value);
      });
  }

  /**
   * Select all the items in the cart
   */
  onSelectAll(checked: boolean) {
    if (checked) {
      this.cartItems.forEach((item) => {
        this.itemsToRemove.filter(() => !this.itemsToRemove.includes(item)) &&
          this.itemsToRemove.push(item);
      });
    } else {
      this.itemsToRemove.length = 0;
    }
  }

  /**
   * Update array of items to remove
   */
  updateItemsToRemove(toRemove: boolean, item: ICommerceItemWithCart) {
    if (toRemove) {
      this.itemsToRemove.push(item);
    } else {
      this.itemsToRemove = this.itemsToRemove.filter(
        (itemInArray) => itemInArray !== item
      );
    }
  }

  // Define form properties
  get orderNowItem() {
    return this.orderNowForm.get('itemNum');
  }
}
