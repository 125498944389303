import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-bulletin-board-message',
  styleUrls: ['./bulletin-board-message.component.scss'],
  templateUrl: './bulletin-board-message.component.html',
})
export class BulletinBoardMessageComponent implements OnInit {
  @Input() message!: {
    title: string;
    content: string;
  };
  @ViewChild('messageContainer') messageEl!: ElementRef;
  isOpen = false;
  messageHeight!: string;
  ocids!: OcidItems;

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Subscribe to any ocid changes.
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
  }

  textToggle() {
    if (!this.isOpen) {
      this.isOpen = true;
      this.messageHeight = this.messageEl.nativeElement.scrollHeight;
    } else {
      this.isOpen = false;
      this.messageHeight = null;
    }
  }
}
