import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of } from 'rxjs';
import {
  catchError,
  map,
  onErrorResumeNext,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { ICreditCardsResponse } from '../../../../contracts/commerce/icredit-cards-response';
import { ICreditCardDto } from '../../../../contracts/user/dto/icredit-card-dto';
import { ICreditCard } from '../../../../contracts/user/icredit-card';
import { NotificationService } from '../../../../service/notification/notification.service';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { CreditCardDialogComponent } from './credit-card-dialog/credit-card-dialog.component';
import { CreditCardService } from './credit-card.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-payment',
  templateUrl: './account-payment-preferences.component.html',
  providers: [NotificationService],
})
export class AccountPaymentComponent implements OnInit, OnDestroy {
  @ViewChild('confirmDialog', { read: ViewContainerRef })
  confirmDialog: ViewContainerRef;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 50];
  selection: SelectionModel<ICreditCard>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  refreshTable: EventEmitter<any> = new EventEmitter<any>();
  dataSource: MatTableDataSource<ICreditCard> =
    new MatTableDataSource<ICreditCard>([]);
  ocids: {} = {};

  constructor(
    public dialog: MatDialog,
    private confirmationService: ConfirmationDialogsService,
    private creditCardService: CreditCardService,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'payment.add-credit-card',
        'payment.cc-expiration',
        'payment.card-number',
        'account.default-text',
        'creditcard.delete-message',
      ])
      .subscribe();
    // Subscribe to search, sort, and paging events.
    merge(this.refreshTable, this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.notificationService.reset();
          // Create the parameters for how to page and sort the data.
          const params: ICreditCardDto = {
            pageIndex: this.paginator.pageSize
              ? this.paginator.pageSize
              : this.pageSize,
            page: this.paginator.pageIndex + 1,
            sortBy: this.sort.active ? this.sort.active : 'creditCardNumber',
          };
          // If the sort direction is descending, add it to the parameters. Default
          // sort is ascending.
          if (this.sort.direction === 'desc') {
            params.sortDir = 'desc';
          }
          // Get the credit cards.
          return this.creditCardService.getPaginatedCards(params).pipe(
            catchError((error) => {
              // Show the error to the user.
              this.notificationService.addError(error.error.title);
              // Return null.
              return null;
            }),
            onErrorResumeNext()
          );
        }),
        map((cards: ICreditCardsResponse) => {
          return cards;
        }),
        catchError((err) => {
          if (err) {
            // If not cancelled
            this.notificationService.addError(err.error?.title);
          }
          return of([]);
        })
      )
      .subscribe((cards: ICreditCardsResponse) => {
        const data = cards.items ? cards.items : [];
        // Loop through cards and set selection to default card
        const selectedCards: ICreditCard[] = [];
        data.forEach((card: ICreditCard) => {
          if (card.setAsDefault) {
            selectedCards.push(card);
          }
        });
        this.selection = new SelectionModel<ICreditCard>(true, selectedCards);
        this.dataSource.data = data;
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Set a credit card as a default.
   *
   * @param event
   * @param defaultCard
   */
  setDefault(event, defaultCard) {
    // Make API request to set the default credit card.
    this.creditCardService
      .setDefaultCreditCard(defaultCard, event.checked)
      .subscribe(
        (card: ICreditCard) => {
          // Refresh the table with the most updated data.
          this.refreshTable.emit();
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   * Event emitted when adding credit card to profile.
   */
  addCreditCard(): void {
    const dialogRef = this.dialog
      .open(CreditCardDialogComponent, {
        data: { isAddCreditCard: true },
        width: '720px',
      })
      .afterClosed()
      .subscribe((data?: ICreditCard) => {
        if (data) {
          // Refresh the table with the most updated data.
          this.refreshTable.emit();
        }
      });
  }

  /**
   * Event emitted when removing a credit card.
   *
   * @param {ICreditCard} card
   */
  onDeleteCard(card: ICreditCard) {
    // Confirm the user wants to delete the card.
    this.confirmationService
      .confirm(
        this.ocids['creditcard.delete-message'],
        '',
        this.ocids['global.delete'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          // Make request to delete the credit card.
          this.creditCardService.deleteCreditCard(card).subscribe(
            () => {
              // Refresh the table with the most updated data.
              this.refreshTable.emit();
            },
            (error) => {
              this.notificationService.addError(error.error.title);
            }
          );
        }
      });
  }

  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
