import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import {
  HistoricSelection,
  Machine,
  MachineDTC,
} from '../../../../../../contracts/clearsky/machine/machine.dto';
import { OcidItems } from '../../../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../../../shared/localization/localization.service';
import { KnowledgeArticleService } from '../../../../../../service/knowledge-article.service';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import { ClearskyService } from '../../../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-historic-dtcs-table',
  templateUrl: './machine-historic-dtcs-table.component.html',
  styleUrls: ['./machine-historic-dtcs-table.component.scss'],
})
export class MachineHistoricDtcsTableComponent implements OnInit, OnChanges {
  @Input() machine: Machine;
  @Input() historic = HistoricSelection.DAYS_90;
  @Input() showHistoric?: boolean;
  dtcs: MachineDTC[] = [];
  displayedColumns: string[] = ['id', 'stat', 'st', 'desc', 'svty', 'src'];
  hasResults = false;
  ocids: OcidItems = {};
  legend: CSLegend;

  constructor(
    private localization: LocalizationService,
    private kaService: KnowledgeArticleService,
    private clearskyService: ClearskyService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs(['clearsky.there-dtc-this-range-label'])
      .subscribe((ocids) => (this.ocids = ocids));

    dayjs.extend(isBetween);

    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.updateDatasource(this.historic);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.historic && !changes.historic.isFirstChange()) {
      this.updateDatasource(changes.historic.currentValue);
    }
  }

  /**
   * Set the data source by historic value.
   * @private
   */
  updateDatasource(historic: string): void {
    if (!this.legend) {
      return;
    }
    this.dtcs = (this.machine.dtcT ?? []).reduce((prev, dtc) => {
      const dtcFromLegend = (this.legend.dtc || []).find(
        (item) => item.id == dtc.id
      );
      if (dtcFromLegend) {
        const convertedDTC: MachineDTC = { ...dtc, ...dtcFromLegend };
        const dtcTime = dayjs(convertedDTC.st);
        const historicTimestamp = this.getTimeByHistoric(historic);

        if (
          dtcTime.isBetween(historicTimestamp, this.todaysTimestamp, null, '()')
        ) {
          prev.push(convertedDTC);
        }
      }
      return prev;
    }, [] as MachineDTC[]);

    this.hasResults = !!this.dtcs.length;
  }

  /**
   * Get the time we need to determine between todays timestamp for dtcs to show.
   * @param historic
   * @private
   */
  private getTimeByHistoric(historic: string): dayjs.Dayjs {
    switch (historic) {
      case HistoricSelection.DAYS_7:
        return this.todaysTimestamp.subtract(1, 'week');
      case HistoricSelection.DAYS_14:
        return this.todaysTimestamp.subtract(2, 'weeks');
      case HistoricSelection.DAYS_31:
        return this.todaysTimestamp.subtract(31, 'days');
      case HistoricSelection.DAYS_90:
        return this.todaysTimestamp.subtract(90, 'days');
      default:
        return this.todaysTimestamp.subtract(1, 'days');
    }
  }

  /**
   * Get today's timestamp.
   * @private
   */
  private get todaysTimestamp(): dayjs.Dayjs {
    return dayjs();
  }
}
