import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { WidgetItemComponent } from '../../../widgets/widget-item/widget-item.component';
import { MachineWidgets } from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { Machine } from '../../../../../contracts/clearsky/machine/machine.dto';

@Component({
  selector: 'app-machine-widget-item',
  templateUrl: '../../../widgets/widget-item/widget-item.component.html',
  styleUrls: ['../../../widgets/widget-item/widget-item.component.scss'],
})
export class MachineWidgetItemComponent
  extends WidgetItemComponent
  implements OnInit, OnChanges
{
  @Input() machine: Machine;
  @Output() gridRowChange: EventEmitter<number> = new EventEmitter<number>();
  @HostBinding('class') classes = '';
  widgetTypes: any = {
    [MachineWidgets.GENERAL_MACHINE_INFO]: import(
      '../general-machine-info/general-machine-info.component'
    ).then((m) => m.GeneralMachineInfoComponent),
    [MachineWidgets.MACHINE_LOCATION]: import(
      '../location-widget/location-widget.component'
    ).then((m) => m.LocationWidgetComponent),
    [MachineWidgets.ENGINE]: import(
      '../asset-engine-information/asset-engine-information.component'
    ).then((m) => m.AssetEngineInformationComponent),
    [MachineWidgets.ASSET_MACHINE_IN_USE]: import(
      '../machine-in-use-detail/machine-in-use-detail.component'
    ).then((m) => m.MachineInUseDetailComponent),
    [MachineWidgets.HISTORIC_DTCS]: import(
      '../machine-historic-dtcs/machine-historic-dtcs.component'
    ).then((m) => m.MachineHistoricDtcsComponent),
    [MachineWidgets.LOCAL_WEATHER]: import(
      '../machine-local-weather/machine-local-weather.component'
    ).then((m) => m.MachineLocalWeatherComponent),
    [MachineWidgets.QUICK_LINKS]: import(
      '../asset-quick-links/asset-quick-links.component'
    ).then((m) => m.AssetQuickLinksComponent),
    [MachineWidgets.MACHINE_UTILIZATION]: import(
      '../asset-utilization/asset-utilization.component'
    ).then((m) => m.AssetUtilizationComponent),
    [MachineWidgets.BATTERY_MONITORING]: import(
      '../machine-battery-monitoring/machine-battery-monitoring.component'
    ).then((m) => m.MachineBatteryMonitoringComponent),
    [MachineWidgets.BATTERY_MONITORING_GENERAL]: import(
      '../asset-battery-monitoring-general/asset-battery-monitoring-general.component'
    ).then((m) => m.AssetBatteryMonitoringGeneralComponent),
    [MachineWidgets.MACHINE_STATUS]: import(
      '../asset-machine-status/asset-machine-status.component'
    ).then((m) => m.AssetMachineStatusComponent),
    [MachineWidgets.ACCESS_CONTROL]: import(
      '../asset-access-control/asset-access-control.component'
    ).then((m) => m.AssetAccessControlComponent),
    [MachineWidgets.CONN_DEV]: import(
      '../asset-conn-dev/asset-conn-dev.component'
    ).then((m) => m.AssetConnDevComponent),
  };

  // Override parent
  ngOnInit() {
    this.updateWidgetClass();
  }

  ngOnChanges(): void {
    // Clear any old data
    this.container.clear();

    this.widgetService.injectWidget(
      this.container,
      this.widgetType,
      this.widgetTypes,
      {
        machine: this.machine,
      },
      {
        gridRowChange: this.gridRowChange,
      }
    );
  }
}
