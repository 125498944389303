import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lang-selector',
  styleUrls: ['./lang-selector.component.scss'],
  templateUrl: './lang-selector.component.html',
})
export class LangSelectorComponent {
  constructor(
    public dialogRef: MatDialogRef<LangSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { languageOptions }
  ) {}

  /**
   * Close the dialog once user selects a language and pass the data back to lang.component
   */
  selectLang(lang) {
    this.dialogRef.close(lang);
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
