import { Component, OnInit, ViewChild } from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyService } from '../../../clearsky.service';
import { WidgetService } from '../../../widget.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  SiteNetwork,
  SiteNetworkFieldDisplay,
} from '../../../../contracts/clearsky/site-network';
import { OcidItems } from '../../../../contracts/ocid-items';
import { combineLatest, mergeMap, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NetworkDetailDialogComponent } from '../../../networks/network-detail-dialog/network-detail-dialog.component';
import { CsAggSiteNetworkData } from '../../../../contracts/clearsky/agg-data';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-site-network-widget',
  templateUrl: './site-network-widget.component.html',
  styleUrls: ['./site-network-widget.component.scss'],
})
export class SiteNetworkWidgetComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  widgetName = Widgets.SITE_NETWORKS;
  displayName = WidgetsDisplay.siteNetworks;
  dataSource: MatTableDataSource<SiteNetwork> =
    new MatTableDataSource<SiteNetwork>([]);
  displayedColumns: string[] = [
    'siteNetworkName',
    'machineCount',
    'networkCreationTime',
    'siteActive',
  ];
  displayedColumnsPretty: { [field: string]: string } = {};
  pageSize = 30;
  hasResults = false;
  ocids: OcidItems = {};
  selection = new SelectionModel<string>(true, []);
  networks: SiteNetwork[] = [];
  networkCount = 0;
  machinesInNetworksCount = 0;
  newNetworkCount = 0;
  newJoinedMachineCount = 0;
  newLeftMachineCount = 0;
  isAllSelected = false;
  private aggData: CsAggSiteNetworkData;
  private subs: Subscription;

  constructor(
    protected clearskyService: ClearskyService,
    protected widgetService: WidgetService,
    protected dialog: MatDialog,
    protected localization: LocalizationService
  ) { }

  ngOnInit(): void {
    this.subs = this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.siteNetwork)
      .pipe(
        mergeMap((data) => {
          // Needing site network data here!
          return combineLatest([
            this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
            this.localization.getOCIDs([
              this.displayName,
              ...Object.values(SiteNetworkFieldDisplay),
              'clearsky.site-network-label',
              'clearsky.machines-joined-label',
              'clearsky.machines-left-label',
              'clearsky.filter-network-label',
              'clearsky.exisiting-networks-label',
              'clearsky.new-site-networks-label',
              'clearsky.no-results-label',
            ]),
          ]);
        })
      )
      .subscribe(([page, ocids]) => {
        this.ocids = ocids;
        if (!page || !page.aggregations) {
          return;
        }

        this.machinesInNetworksCount = page.aggregations.siteNetwork
          ? page.aggregations.siteNetwork.mxsInSiteNetworks
          : 0;
        this.newJoinedMachineCount = page.aggregations.siteNetwork
          ? page.aggregations.siteNetwork.mxsJoinedSiteNetworks24Hrs
          : 0;
        this.newLeftMachineCount = page.aggregations.siteNetwork
          ? page.aggregations.siteNetwork.mxsLeftSiteNetworks24Hrs
          : 0;
        this.networkCount = page.aggregations.siteNetwork
          ? page.aggregations.siteNetwork.totalSiteNetworks
          : 0;
        this.newNetworkCount = page.aggregations.siteNetwork
          ? page.aggregations.siteNetwork.newSiteNetworks24Hrs
          : 0;
        this.dataSource.data = [];
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.displayedColumnsPretty = this.displayedColumns.reduce(
          (prev, field) => {
            prev[field] = ocids[SiteNetworkFieldDisplay[field]] || field;
            return prev;
          },
          {} as { [field: string]: string }
        );

        this.hasResults = !!this.dataSource.data.length;

        // Go back to page 1 every time machines are updated
        this.paginator.pageIndex = 0;

        // Clear selection in case if it was removed as a filter
        this.selection.clear();

        // Do they already have machine selection?
        /**
      const networkFilter = filters.find(
        (f) => f.key === MachineFilters.NETWORK_ID
      );
      if (networkFilter) {
        this.selection.select(...(networkFilter.values as string[]));
      }
       **/

        // Filter only by name
        this.dataSource.filterPredicate = (network, filter: string) => {
          return network.siteNetworkName
            ? network.siteNetworkName.toLowerCase().includes(filter)
            : false;
        };

        // Every time selection is changed, update flag
        this.selection.changed.subscribe(() => {
          this.updateIsAllSelected();
        });
      });
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   */
  private updateIsAllSelected(): void {
    this.isAllSelected =
      this.selection.selected.length === this.dataSource.data.length;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  masterToggle(): void {
    this.isAllSelected
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) =>
        this.selection.select(row.siteNetworkID)
      );
  }

  /**
   * Update site network id filter on machines.
   */
  updateNetworkSelection(): void {
    // this.clearskyService.updateFilter(this.filter, this.selection.selected);
  }

  /**
   * Apply search filter.
   *
   * @param event
   */
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * On row click in table.
   * @param network
   */
  onRowClick(network: SiteNetwork): void {
    this.dialog.open(NetworkDetailDialogComponent, {
      panelClass: ['mat-dialog-right', '_35', 'clearsky-dialog'],
      autoFocus: false,
      data: { network },
    });
  }

  /**
   * Apply filter for is in network.
   */
  filterInNetwork(): void {
    this.clearskyService.updateFilter(CSFilter.inNetwork.key, [true]);
  }

  /**
   * Apply filter for entered network in last 24 hours.
   */
  filterEntered24(): void {
    this.clearskyService.updateFilter(CSFilter.siteEnter24.key, [true]);
  }

  /**
   * Apply filter for exited network in last 24 hours.
   */
  filterExited24(): void {
    this.clearskyService.updateFilter(CSFilter.siteExit24.key, [true]);
  }
}
