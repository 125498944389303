import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  PLATFORM_ID,
  Inject,
  OnInit,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-hero-carousel',
  templateUrl: './hero-carousel.component.html',
})
export class HeroCarouselComponent
  implements OnInit, AfterViewInit, CartridgeInterface
{
  @Input() data;
  @ViewChild('carousel') carousel: SlickCarouselComponent;
  slideConfig = {
    dots: true,
    autoplay: false,
    autoplaySpeed: 7000,
  };
  activeSlide = 0;
  platformBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngOnInit(): void {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    if (this.data.autoplay) this.slideConfig.autoplay = this.data.autoplay;
    if (this.data.autoplaySpeed)
      this.slideConfig.autoplaySpeed = this.data.autoplaySpeed;
  }

  ngAfterViewInit() {
    if (this.carousel) {
      this.carousel.afterChange.subscribe((changes) => {
        this.activeSlide = changes.currentSlide;
      });
    }
  }
}
