import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from '../../../clearsky.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { MatDialog } from '@angular/material/dialog';
import { KnowledgeArticleService } from '../../../../service/knowledge-article.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import * as cloneDeep from 'lodash.clonedeep';
import { mergeDeep } from '../../../../shared/deep-merge';
import {
  BaseChartConfig,
  getDtcSrcColor,
  getDtcSvtyColor,
} from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as Highcharts from 'highcharts';
import { DtcBubbleDialogComponent } from './dtc-bubble-dialog/dtc-bubble-dialog.component';
import { shadeRGBColor } from '../../../../shared/shade-rgb.pipe';
import { ChartLegendOption } from '../../../../shared/chart/contracts/ChartLegendOption';
import { OcidItems } from '../../../../contracts/ocid-items';
import {
  CSFilter,
  toggleCsFilterVal,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsAggCountData } from '../../../../contracts/clearsky/agg-data';
import {
  CSRefBasic,
  CSRefDTC,
} from '../../../../contracts/clearsky/clearsky-legend';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dtc-widget',
  templateUrl: './dtc-widget.component.html',
  styleUrls: ['./dtc-widget.component.scss'],
})
export class DtcWidgetComponent implements OnInit {
  chartEl: ElementRef | undefined;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  widgetName = Widgets.DTC;
  displayName = WidgetsDisplay.dtc;
  sources: ChartLegendOption[] = [];
  ocids: OcidItems = {};
  isLoading = true;
  showChart = false;
  private articleExistence: string[] = [];
  private aggData: CsAggCountData[];
  private srcData: CsAggCountData[];
  private legend: CSRefDTC[];
  private legendSrc: CSRefBasic[];
  private legendSvty: CSRefBasic[];
  private filter: unknown[];
  private filterSrc: unknown[];

  // Setting properties
  private chartData = mergeDeep(cloneDeep(BaseChartConfig), {
    chart: {
      type: 'packedbubble',
      events: {
        render: function () {
          const tooltipMsgs = [
            'DTC provides indication of a machine state.',
            'DTC may result in reduced machine performance.',
            'DTC may result in inhibited machine function.',
          ];
          const element = document.getElementById('legend-tooltip-box');
          const chart = this,
            legend = chart.legend;
          for (let i = 0, len = legend.allItems.length; i < len; i++) {
            (function (i) {
              const item = legend.allItems[i].legendItem;
              return;
              item
                .on(
                  'mouseenter',
                  function (e: {
                    target: {
                      parentElement: {
                        getBoundingClientRect: () => { x: number; y: number };
                      };
                    };
                  }) {
                    //show custom tooltip here
                    element.innerHTML = tooltipMsgs[i];
                    element.style.left =
                      e.target.parentElement.getBoundingClientRect().x +
                      20 +
                      'px';
                    element.style.top =
                      e.target.parentElement.getBoundingClientRect().y +
                      20 +
                      'px';
                    if (!element.classList.contains('tooltip-show')) {
                      element.classList.add('tooltip-show');
                    }
                  }
                )
                .on('mouseout', function (e: MouseEvent) {
                  //hide tooltip
                  if (element.classList.contains('tooltip-show')) {
                    element.classList.remove('tooltip-show');
                  }
                });
            })(i);
          }
        },
      },
    },
    tooltip: {
      useHTML: true,
      pointFormat:
        '<b>Code Identifier</b>: {point.name}<br />{point.description}<br /><b>Number of Machines w/ Fault #</b>: {point.value}',
    },
    plotOptions: {
      packedbubble: {
        cursor: 'pointer',
        draggable: false,
        minSize: '50%',
        maxSize: '200%',
        zMin: 0,
        zMax: 1000,
        layoutAlgorithm: {
          gravitationalConstant: 0.02,
          splitSeries: true,
          seriesInteraction: false,
          parentNodeLimit: true,
        },
        lineWidth: 3,
        fillColor: '#fff',
        dataLabels: {
          enabled: false,
          format: '{point.name}',
          filter: {
            property: 'y',
            operator: '>',
            value: 6,
          },
          style: {
            fontSize: '10px',
          },
        },
        states: {
          normal: {
            enabled: false,
          },
          hover: {
            enabled: false,
          },
        },
        marker: {
          fillOpacity: 1,
        },
      },
      series: {
        events: {
          legendItemClick: this.onLegendClick.bind(this),
        },
      },
    },
  });
  private plot;
  private optionsSrc: unknown[];
  private optionsSvty: unknown[];
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private kaService: KnowledgeArticleService,
    private localization: LocalizationService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.legendRef$,
    ])
      .pipe(
        mergeMap(([page, legend]) => {
          this.aggData =
            (page && page.aggregations && page.aggregations.dtcs) || [];
          this.srcData =
            (page && page.aggregations && page.aggregations.dtcSource) || [];
          this.legend = legend.dtc || [];
          this.legendSvty = legend.svtyCategory || [];
          this.legendSrc = legend.fsrc || [];

          return this.getArticleExistense();
        }),
        mergeMap(() => {
          return combineLatest([
            this.localization.getOCIDs([
              'clearsky.graphic-default',
              'clearsky.dtc-is-should-often-generated-sequence-is-without-label',
              'clearsky.dtc-reduced-the-this-the-restarting-label',
              'clearsky.dtc-machine-been-cut-dtcs-diagnostic-remedy-label',
              'clearsky.code-identifier-label',
              'clearsky.number-w-label',
              this.displayName,
            ]),
            this.clearskyService.getCurrentFilterValues(CSFilter.dtcSvty.key),
            this.clearskyService.getCurrentFilterValues(CSFilter.fsrc.key),
            this.clearskyService.getValuesByFilter(CSFilter.fsrc.key),
            this.clearskyService.getValuesByFilter(CSFilter.dtcSvty.key),
          ]);
        })
      )
      .subscribe(([ocids, filter, filterSrc, optionsSrc, optionsSvty]) => {
        this.ocids = ocids;
        this.filter = filter;
        this.filterSrc = filterSrc;
        this.optionsSrc = optionsSrc;
        this.optionsSvty = optionsSvty;

        this.resetChartData();
        this.setChartData();
        this.isLoading = false;
        this.showChart = true;

        // Does the chart already exist?
        if (this.plot && this.showChart) {
          this.setChartSeries();
        }
      });
  }

  /**
   * Toggle specific filter.
   * @param value
   */
  toggleFilter(value: number): void {
    this.clearskyService.updateFilter(
      CSFilter.fsrc.key,
      toggleCsFilterVal(value, this.filterSrc, this.optionsSrc)
    );
  }

  /**
   * Open DTC slide out.
   * @param dtc
   */
  openDtcDialog(dtc: CSRefDTC): void {
    this.dialog.open(DtcBubbleDialogComponent, {
      data: {
        dtc,
      },
      panelClass: ['mat-dialog-right', '_35', 'clearsky-dialog', 'dtc-dialog'],
      autoFocus: false,
    });
  }

  /**
   * Reset chart data.
   * @protected
   */
  private resetChartData(): void {
    if (this.plot && this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * On legend click.
   * @protected
   */
  private onLegendClick(e: {
    target: { userOptions: { custom: { id: number } } };
  }): void {
    this.clearskyService.updateFilter(
      CSFilter.dtcSvty.key,
      toggleCsFilterVal(
        e.target.userOptions.custom.id,
        this.filter,
        this.optionsSvty
      )
    );
  }

  /**
   * Set chart data.
   * @private
   */
  private setChartData(): void {
    this.sources = this.legendSrc.map((source) => {
      const data = this.srcData.find((s) => s.id === source.id);

      return {
        label: source.desc,
        value: data ? data.cnt : 0,
        custom: source,
        data: data ? data.cnt : 0,
        color: getDtcSrcColor(source.id),
        hidden: !!(
          this.filterSrc.length && !this.filterSrc.includes(source.id)
        ),
        tooltip: 'DTC source is ' + source.desc,
      };
    });
  }

  /**
   * Create chart instance.
   * @private
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    if (!this.chartEl) {
      return;
    }
    this.plot = Highcharts.chart(this.chartEl.nativeElement, this.chartData);
    this.setChartSeries();
  }

  /**
   * Set the chart series when data changes.
   * @private
   */
  private setChartSeries(): void {
    // Now loop through dtcs and push them to the appropriate bubble
    const bubbleConfig = (dtc: CSRefDTC, data: CsAggCountData) => {
      return {
        name: dtc.desc,
        custom: data,
        description: dtc.desc,
        value: data.cnt,
        color: getDtcSrcColor(dtc.src),
        events: {
          click: this.openDtcDialog.bind(this, dtc),
        },
        marker: this.articleExistence.includes(dtc.id.toString())
          ? {
              lineColor: shadeRGBColor(getDtcSrcColor(dtc.src), -0.2),
              lineWidth: 2,
            }
          : {},
      };
    };

    this.legendSvty.forEach((svty) => {
      // Add plot series for each svty
      this.plot.addSeries({
        name: svty.desc,
        useHtml: true,
        custom: svty,
        data: this.aggData.reduce((prev, data) => {
          const legendDtc = this.legend.find((l) => l.id === data.id);

          if (legendDtc && legendDtc.svty === svty.id) {
            prev.push(bubbleConfig(legendDtc, data));
          }

          return prev;
        }, []),
        color: getDtcSvtyColor(svty.id),
        visible: this.filter.length ? this.filter.includes(svty.id) : true,
      });
    });

    this.plot.redraw();
  }

  /**
   * Retrieve knowledge article existense for each dtc.
   * @private
   */
  private getArticleExistense(): Observable<string[]> {
    return this.kaService
      .getArticleExistsByDtcCodes(this.aggData.map((d) => d.id.toString()))
      .pipe(map((existence) => (this.articleExistence = existence)));
  }
}
