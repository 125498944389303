import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeLabelComponent } from './badge-label.component';

@NgModule({
  declarations: [
    BadgeLabelComponent
  ],
  exports: [
    BadgeLabelComponent
  ],
  imports: [
    CommonModule
  ]
})
export class BadgeLabelModule { }
