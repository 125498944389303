import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-machine-level-bar',
  templateUrl: './machine-level-bar.component.html',
  styleUrls: ['./machine-level-bar.component.scss'],
})
export class MachineLevelBarComponent {
  @Input() percentage: number;
  @Input() quantified: string | number;
}
