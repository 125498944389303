import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { IProduct } from "../../contracts/product/iproduct";
import { environment } from "../../../environments/environment";
import { IProductPricing } from "../../contracts/product/iproduct-pricing";
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ICommerceItemAvailability } from 'app/contracts/commerce/icommerce-item-availability';

@Injectable()
export class ProductsApiService {
  public product: IProduct;
  public productSubject: Subject<{
    product: IProduct,
    pricing: IProductPricing
  }> = new Subject<{
    product: IProduct,
    pricing: IProductPricing
  }>();

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string,
  ) { }

  /**
   * Get itemInfo for itemNumbers.
   *
   * @param {string[]} itemNumbers
   * @returns {Observable<IProduct[]>}
   */
  getProducts(itemNumbers: string[]): Observable<IProduct[]> {
    // Add all item numbers to request
    let products = [];
    itemNumbers.forEach((number: string) => {
      products.push({
        itemNumber: number,
        requestQTY: 1
      })
    });

    return this.http
      .post(`${environment.apiUrl}/products/itemInfo`, {
        items: products
      })
      .pipe(map(
        (res: {
          itemDetails: IProduct[]
        }) => {
          return res.itemDetails;
        })
      );
  }

  /**
   * Get itemAvailability for itemNumber.
   *
   * @param {string} itemNumber
   * @returns {Observable<ICommerceItemAvailability[]>}
   */
  getAvailability(itemNumber: string): Observable<ICommerceItemAvailability[]> {
    // Add all item numbers to reques
    return this.http
      .get(`${environment.apiUrl}/products/itemAvailability?itemNumber=${itemNumber}`)
      .pipe(map(
        (res: {
          itemsAvailability: ICommerceItemAvailability[]
        }) => {
          return res.itemsAvailability;
        })
      );
  }

  /**
   * Retrieve a product by ID.
   *
   * @param {!string} itemNumber
   * @return Observable<IProduct>
   */
  getProduct(itemNumber: string): Observable<IProduct> {
    return this.getProducts([itemNumber])
      .pipe(map(
        (products: IProduct[]) => {
          const response = products[0];
          this.product = response;

          return response;
        })
      );
  }

  /**
   * Get product pricing information.
   *
   * @param {string} itemNumber
   * @param {object} addtParams
   * @returns {Observable<IProductPricing>}
   */
  getPrices(itemNumber: string, addtParams?: object) {
    let params = {
      productId: itemNumber
    };
    let httpParams;

    if (addtParams !== undefined) {
      httpParams = { ...params, ...addtParams };
    }

    return this.http.get<IProductPricing>(`${environment.apiUrl}/products/${itemNumber}/prices`, {
      params: httpParams
    });
  }

  /**
   * Insert the product JSON-ld schema
   *
   * @param {Record<string, any>} schema
   * @param {string} className
   */
  insertSchema(schema: Record<string, any>, className: string): void {
    if (isPlatformBrowser(this.platformId)) {
      let script = this.document.createElement('script');
      script.setAttribute('class', className);
      script.type = 'application/ld+json';
      script.text = JSON.stringify(schema);
      this.document.head.appendChild(script);
    }
  }

  /**
   * Remove the inserted JSON chema
   * @param {string} className
   */
  removeSchema(className: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.head.removeChild(this.document.head.getElementsByClassName(className)[0]);
    }
  }

  /**
   * Get the product that was loaded.
   *
   * @returns {IProduct}
   */
  getLoadedProduct() {
    return this.product;
  }

  verifyBySerialNum(num: number) {

  }
}

