import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { CanonicalService } from '../../shared/canonical.service';

export abstract class TemplateInterface {
  abstract data: object;
  abstract payloadUrl: string;
  protected abstract titleService: Title;
  protected abstract metaService: Meta;
  protected abstract canonicalService: CanonicalService;

  /**
   * Set meta title and description tags if in payload.
   *
   * @private
   * @param {boolean} platformBrowser
   */
  protected _setMetaData(platformBrowser: boolean) {
    const title: string = this.data['metadataTitle'];
    const metaDataDescription: string = this.data['metadataDescription'];
    const canonicalUrl: string = this.data['canonicalUrl'];
    const metadataImage = this.data['metadataImage'];

    if (this.data.hasOwnProperty('metadataTitle')) {
      if (title.includes('--')) {
        this.titleService.setTitle(
          title.replace('Online Express --', `${environment.baseMetaTitle} |`)
        );
      } else {
        this.titleService.setTitle(
          title.replace('Online Express', environment.baseMetaTitle)
        );
      }
    }

    if (this.data.hasOwnProperty('metadataDescription')) {
      this.metaService.updateTag({
        name: 'description',
        content: metaDataDescription,
      });
    }

    if (this.data.hasOwnProperty('canonicalUrl') && !platformBrowser) {
      this.canonicalService.createCanonicalURL(canonicalUrl);
    }

    if (this.data['metadataDescription'] && this.titleService.getTitle()) {
      // Open Graph MetaData
      this.metaService.updateTag({ property: 'og:type', content: 'website' });
      this.metaService.updateTag({
        property: 'og:url',
        content: window.location.href,
      });
      this.metaService.updateTag({
        property: 'og:title',
        content: this.titleService.getTitle(),
      });
      this.metaService.updateTag({
        property: 'og:description',
        content: metaDataDescription,
      });
      this.metaService.updateTag({
        property: 'og:image',
        content: metadataImage,
      });

      // Twitter Meta Cards
      this.metaService.updateTag({
        property: 'twitter:card',
        content: 'summary_large_image',
      });
      this.metaService.updateTag({
        property: 'twitter:url',
        content: window.location.href,
      });
      this.metaService.updateTag({
        property: 'twitter:title',
        content: this.titleService.getTitle(),
      });
      this.metaService.updateTag({
        property: 'twitter:description',
        content: metaDataDescription,
      });
      this.metaService.updateTag({
        property: 'twitter:image',
        content: metadataImage,
      });
    }
  }
}
