import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, merge } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ICustomerSwitch } from '../../contracts/user/icustomer-switch';
import { IUser } from '../../contracts/user/iuser';
import { EndecaService } from '../../endeca/endeca.service';
import { ContentService } from '../../service/content.service';
import { LayoutService } from '../../service/layout.service';
import { CurrentUserService } from '../../service/user/current-user.service';
import { LocalizationService } from '../../shared/localization/localization.service';
import { OktaAuthWrapper } from '../../service/auth/okta.auth.wrapper';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SearchService } from '../../endeca/cartridges/search/search.service';
import { NavigationEnd, Router } from '@angular/router';
import { OcidItems } from '../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public logo: string;
  proxy$: Observable<ICustomerSwitch> = this.userService.proxyUser$;
  ocids$: Observable<OcidItems> = this.localization.OCIDs;
  isMaintenance$: Observable<boolean> = this.contentService.isMaintenance$.pipe(
    shareReplay()
  );
  user$: Observable<IUser> = this.userService.userSubject.pipe(shareReplay());
  isTablet$: Observable<boolean> = this.layoutService.isTabletMax$;
  showTabletSearch$: Observable<boolean> = this.layoutService.isDesktop$;
  toggleSearchEnabled$: Observable<boolean> = this.layoutService.toggleSearchEnabled$;
  isLoggedIn$: Observable<boolean> = this.oktaAuth.isLoggedIn;
  isSearchToggleable$ = new BehaviorSubject<boolean>(false);
  showSearch$: Observable<boolean> = combineLatest([this.endecaService.globalSearchBoxViewable$, this.isSearchToggleable$]).pipe(
    map(([showGlobalSearch, isSearchToggleable]) => showGlobalSearch || isSearchToggleable)
  );
  ifClickedMakeFocused = this.searchService.isSearchFocused;
  isJlg: boolean = environment.jlgStyling;
  platformBrowser: boolean = isPlatformBrowser(this.platformId);
  imageScrSet: string | undefined;
  searchType = 'default';
  public imageAlt: string;
  public imgHeight: string;
  public imgWidth: string;
  isMobile: boolean;
  isClearskyEnabled: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private oktaAuth: OktaAuthWrapper,
    private userService: CurrentUserService,
    private localization: LocalizationService,
    private endecaService: EndecaService,
    private contentService: ContentService,
    public layoutService: LayoutService,
    public searchService: SearchService,
    public router: Router
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.isJlg = this.isJlg && !location.href.includes('jerrdan');
    }
    combineLatest([
      this.layoutService.isClearskyEnabled$,
      this.layoutService.isMobile$
    ])
      .subscribe(([isClearskyEnabled, isMobile]) => {
        this.isMobile = isMobile;
        this.isClearskyEnabled = isClearskyEnabled;
        this.logo = this.isJlg
          ? isMobile
            ? '/assets/img/jlg-mobile.svg'
            : '/assets/img/jlg.svg'
          : '/assets/img/jerrdan.svg';
        this.imgWidth = this.isJlg ? (isMobile ? '122' : '208') : '210';
      });
    this.imageAlt = this.isJlg ? 'Jlg Logo' : 'Jerrdan Logo';
    this.imgHeight = this.isJlg ? '40' : '30';

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.ifClickedMakeFocused.next(false);
          this.isSearchToggleable$.next(false);
        }
      });
  }

  /**
   * Get CSR Proxy Using As OCID
   */
  get csrProxyUsingAs$(): Observable<string> {
    return this.ocids$.pipe(
      map((ocids) => {
        return ocids['csr-proxy.using-as'];
      })
    );
  }

  /**
   * The only pages currently with toggle search are clearsky, knowledge article, and manual pages.
   * If not the following pages make the non-global search focused
   * and clear the current search term.
   */
  makeSearchFocused(): void {
    this.searchService.setCurrentSearchTerm('');
    this.isSearchToggleable$.next(true);
    this.ifClickedMakeFocused.next(true);
  }
}
