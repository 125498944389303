import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ClearskyCart,
  ClearskySubscriptionPlan,
} from '../../../contracts/clearsky/subscription/clearsky-subscription';
import { isPlatformBrowser } from '@angular/common';
import * as dayjs from 'dayjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { IUser } from 'app/contracts/user/iuser';
import { CurrentUserService } from 'app/service/user/current-user.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-total-sidebar',
  templateUrl: './subscription-total-sidebar.component.html',
  styleUrls: ['./subscription-total-sidebar.component.scss'],
})
export class SubscriptionTotalSidebarComponent implements OnInit, OnChanges {
  @ViewChild('sidebarEl', { read: ElementRef }) sidebarEl!: ElementRef;
  @ViewChild('toggleEl', { read: ElementRef }) toggleEl!: ElementRef;
  @Input() selectedPlan!: ClearskySubscriptionPlan;
  @Input() selectedAddons: string[] = [];
  @Input() numOfAssets = 0;
  @Input() cart: ClearskyCart;
  @Input() showUpgrade;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.adjustPosition();
  }
  collapsed = true;
  addonTotal = 0;
  annualTotal = 0;
  planTotal = 0;
  year = dayjs().year();
  ocids: OcidItems = {};
  user: IUser;

  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    this.currentUserService.userSubject.subscribe((user) => {
      this.user = user;
    });
    this.localization
      .getOCIDs([
        'csv.fleet-size-label',
        'clearsky.access-period-label',
        'clearsky.assets-label',
        'clearsky.valid-date-label',
        'clearsky.current-add-ons-label',
        'clearsky.add-ons-total-label',
        'clearsky.subscription-total-label',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
    this.updatePricing();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPlan || changes.selectedAddons) {
      this.updatePricing();
    }
  }

  /**
   * On sidebar toggle.
   */
  onToggle(): void {
    this.collapsed = !this.collapsed;
    this.toggle.emit(this.collapsed);
    this.adjustPosition();
  }

  /**
   * Update pricing information.
   * @private
   */
  private updatePricing(): void {
    // Set plan price
    this.planTotal = this.selectedPlan
      ? this.selectedPlan.listPrice * this.numOfAssets
      : 0;

    // Set addons price
    if (this.selectedPlan && this.selectedAddons.length) {
      this.addonTotal = this.selectedPlan.addOns.reduce((prev, addon) => {
        prev += addon.listPrice * this.numOfAssets;
        return prev;
      }, 0);
    }

    // Set total
    this.annualTotal = this.planTotal + this.addonTotal;
  }

  /**
   * Adjust sidebar positioning based on screen height.
   * @private
   */
  private adjustPosition(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Make sure it doesn't scroll over the footer
      const sidebarEl = this.sidebarEl.nativeElement.getBoundingClientRect();
      const toggleEl = this.toggleEl.nativeElement.getBoundingClientRect();
      const stepperEl = document
        .getElementById('subscription-stepper')
        .getBoundingClientRect();
      const footerEl = document
        .getElementById('main-footer')
        .getBoundingClientRect();

      // Check if sidebar should be adjusted
      if (stepperEl.top + sidebarEl.height + window.scrollY > footerEl.top) {
        this.renderer.addClass(this.sidebarEl.nativeElement, 'fixed-bottom');
      } else {
        this.renderer.removeClass(this.sidebarEl.nativeElement, 'fixed-bottom');
      }

      // Check if toggle should be adjusted
      if (
        stepperEl.top + toggleEl.height + window.scrollY + 40 >
        footerEl.top
      ) {
        this.renderer.addClass(this.toggleEl.nativeElement, 'fixed-bottom');
      } else {
        this.renderer.removeClass(this.toggleEl.nativeElement, 'fixed-bottom');
      }
    }
  }
}
