import { Injectable } from '@angular/core';
import { SiteNetwork } from 'app/contracts/clearsky/site-network';
import { BehaviorSubject, Observable } from 'rxjs';
import { Machine } from '../../contracts/clearsky/machine/machine.dto';

@Injectable({
  providedIn: 'root',
})
export class ClearskyMapService {
  private _machinePinClicked: BehaviorSubject<Machine | undefined> =
    new BehaviorSubject<Machine | undefined>(undefined);
  machinePinClicked$: Observable<Machine | undefined> =
    this._machinePinClicked.asObservable();

  private _siteNetworkePinClicked: BehaviorSubject<SiteNetwork | undefined> =
    new BehaviorSubject<SiteNetwork | undefined>(undefined);
  siteNetworkPinClicked$: Observable<SiteNetwork | undefined> =
    this._siteNetworkePinClicked.asObservable();

  /**
   * Machine pushpin clicked.
   * @param machine
   */
  machinePinClicked(machine: Machine): void {
    this._machinePinClicked.next(machine);
  }

  /**
   * Reset machine pushpin clicked to undefined.
   */
  clearMachinePinClicked(): void {
    this._machinePinClicked.next(undefined);
  }

  /**
   * SiteNetwork pushpin clicked.
   * @param siteNetwork
   */
  siteNetworkPinClicked(siteNetwork: SiteNetwork): void {
    this._siteNetworkePinClicked.next(siteNetwork);
  }

  /**
   * Reset machine pushpin clicked to undefined.
   */
  clearSiteNetworkPinClicked(): void {
    this._siteNetworkePinClicked.next(undefined);
  }
}
