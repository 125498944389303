import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { shadeRGBColor } from '../../../../../shared/shade-rgb.pipe';
import { EngineComponentWidget } from '../engine-component-widget';
import { WidgetColors } from '../../../../../contracts/clearsky/dashboard/cs-colors.dto';

@Component({
  selector: 'app-fuel-consumption',
  templateUrl: './fuel-consumption.component.html',
})
export class FuelConsumptionComponent
  extends EngineComponentWidget
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() machine: Machine;
  @Input() historic: string = HistoricSelection.HOURS_24;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chart = content;
    }
  }
  protected historicMapping = {
    [HistoricSelection.HOURS_24]: 'engRpmTrqFuelConsum',
    [HistoricSelection.DAYS_7]: 'engRpmTrqFuelConsum7days',
    [HistoricSelection.DAYS_14]: 'engRpmTrqFuelConsum14days',
    [HistoricSelection.DAYS_31]: 'engRpmTrqFuelConsum31days',
    [HistoricSelection.DAYS_90]: 'engRpmTrqFuelConsum90days',
  };
  protected chartTitle = 'Fuel Consumption Rate (L/hr)';
  protected xAxisTitle = 'Engine Speed (RPM)';
  protected yAxisTitle = 'Torque (%)';
  protected colorScaleMin: string = WidgetColors.aqua;
  protected colorScaleMax: string = shadeRGBColor(WidgetColors.aqua, -0.6);
}
