import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaModule,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { OktaAuthWrapper } from '../service/auth/okta.auth.wrapper';
import { SharedModule } from '../shared/shared.module';
import { CartridgeComponent } from './cartridge/cartridge.component';
import { AccountModule } from './cartridges/account/account.module';
import { AdsenseComponent } from './cartridges/adsense/adsense.component';
import { AppliedFiltersComponent } from './cartridges/applied-filters/applied-filters.component';
import { AutoSuggestPanelComponent } from './cartridges/auto-suggest-panel/auto-suggest-panel.component';
import { CategoryPartsAutoSuggestComponent } from './cartridges/auto-suggest-panel/category-parts-auto-suggest/category-parts-auto-suggest.component';
import { FlyOutPanelSlotComponent } from './cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel-slot.component';
import { FlyOutPanelComponent } from './cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/flyout-panel.component';
import { ProductDetailFlyoutItemComponent } from './cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/product-detail-flyout-item/product-detail-flyout-item.component';
import { ResultsListFlyoutItemComponent } from './cartridges/auto-suggest-panel/flyout-panel-slot/flyout-panel/results-list-flyout-item/results-list-flyout-item.component';
import { KeywordSearchAutoSuggestComponent } from './cartridges/auto-suggest-panel/keyword-search-auto-suggest/keyword-search-auto-suggest.component';
import { BreadcrumbsComponent } from './cartridges/breadcrumbs/breadcrumbs.component';
import { PdpCrumbsComponent } from './cartridges/breadcrumbs/pdp-crumbs/pdp-crumbs.component';
import { RefinementCrumbsComponent } from './cartridges/breadcrumbs/refinement-crumbs/refinement-crumbs.component';
import { SearchCrumbsComponent } from './cartridges/breadcrumbs/search-crumbs/search-crumbs.component';
import { BulletinBoardMessageComponent } from './cartridges/bulletin-board-messages/bulletin-board-message/bulletin-board-message.component';
import { BulletinBoardMessagesComponent } from './cartridges/bulletin-board-messages/bulletin-board-messages.component';
import { CategoryNavigationItemComponent } from './cartridges/category-navigation/category-navigation-item/category-navigation-item.component';
import { CategoryNavigationComponent } from './cartridges/category-navigation/category-navigation.component';
import { CheckoutModule } from './cartridges/checkout/checkout.module';
import { ContentSlotLeftComponent } from './cartridges/content-slot-left/content-slot-left.component';
import { ContentSlotMainComponent } from './cartridges/content-slot-main/content-slot-main.component';
import { ContentSlotHeaderComponent } from './cartridges/content-slot-header/content-slot-header.component';
import { ContentSlotFooterComponent } from './cartridges/content-slot-footer/content-slot-footer.component';
import { ContentWithImageHeaderComponent } from './cartridges/content-with-image-header/content-with-image-header.component';
import { EmergencyAlertsComponent } from './cartridges/emergency-alerts/emergency-alerts.component';
import { ForgotUnlockWrapperComponent } from './cartridges/forgot-unlock-wrapper/forgot-unlock-wrapper.component';
import { NewPasswordFormComponent } from './cartridges/forgot-unlock-wrapper/new-password-form/new-password-form.component';
import { RecoveryAnswerFormComponent } from './cartridges/forgot-unlock-wrapper/recovery-answer-form/recovery-answer-form.component';
import { FormWithBackgroundImageComponent } from './cartridges/form-with-background-image/form-with-background-image.component';
import { GuidedNavigationComponent } from './cartridges/guided-navigation/guided-navigation.component';
import { HeroCarouselImageComponent } from './cartridges/hero-carousel/hero-carousel-image/hero-carousel-image.component';
import { HeroCarouselItemComponent } from './cartridges/hero-carousel/hero-carousel-item/hero-carousel-item.component';
import { HeroCarouselComponent } from './cartridges/hero-carousel/hero-carousel.component';
import { NoContentHeroCarouselImageComponent } from './cartridges/hero-carousel/no-content-hero-carousel-image/no-content-hero-carousel-image.component';
import { HorizontalRecordSpotlightComponent } from './cartridges/horizontal-record-spotlight/horizontal-record-spotlight.component';
import { ImageNavigationComponent } from './cartridges/image-navigation/image-navigation.component';
import { NavigationImageComponent } from './cartridges/image-navigation/navigation-image/navigation-image.component';
import { InteractiveManualsComponent } from './cartridges/interactive-manuals/interactive-manuals.component';
import { JumboTextListComponent } from './cartridges/jumbo-text-list/jumbo-text-list.component';
import { LatestNewsHeaderComponent } from './cartridges/latest-news/lates-news-item/latest-news-header/latest-news-header.component';
import { LatestNewsItemComponent } from './cartridges/latest-news/lates-news-item/latest-news-item.component';
import { LatestNewsComponent } from './cartridges/latest-news/latest-news.component';
import { MarketingBoxBannerComponent } from './cartridges/marketing-box-banner/marketing-box-banner.component';
import { MarketingBoxComponent } from './cartridges/marketing-box-banner/marketing-box/marketing-box.component';
import { PageHeadingComponent } from './cartridges/page-heading/page-heading.component';
import { PartsModule } from './cartridges/parts/parts.module';
import { PlacardModule } from './cartridges/placard/placard.module';
import { PlateIdReplacementModule } from './cartridges/plate-id-replacement/plate-id-replacement.module';
import { PopularCategoriesComponent } from './cartridges/popular-categories/popular-categories.component';
import { ProductDetailRelatedItemsComponent } from './cartridges/product-detail-related-items/product-detail-related-items.component';
import { ProductDetailComponent } from './cartridges/product-detail/product-detail.component';
import { RatingsComponent } from './cartridges/product-detail/ratings/ratings.component';
import { VerifyBySerialComponent } from './cartridges/product-detail/verify-by-serial/verify-by-serial.component';
import { RefinementMenuComponent } from './cartridges/refinement-menu/refinement-menu.component';
import { ResultsListItemComponent } from './cartridges/results-list/results-list-item/results-list-item.component';
import { ResultsListComponent } from './cartridges/results-list/results-list.component';
import { RichTextMainComponent } from './cartridges/rich-text-main/rich-text-main.component';
import { SchematicsComponent } from './cartridges/schematics/schematics.component';
import { SchematicsHelpComponent } from './cartridges/schematics/schematics-help/schematics-help.component';
import { SchematicsSelectorComponent } from './cartridges/schematics/schematics-selector/schematics-selector.component';
import { SearchAdjustmentsComponent } from './cartridges/search-adjustments/search-adjustments.component';
import { SearchBoxBannerComponent } from './cartridges/search-box-banner/search-box-banner.component';
import { SearchTypeaheadComponent } from './cartridges/search/search-typeahead/search-typeahead.component';
import { SearchComponent } from './cartridges/search/search.component';
import { CustomerPickupDialogComponent } from './cartridges/shopping-cart/customer-pickup-dialog/customer-pickup-dialog.component';
import { ShoppingCartModule } from './cartridges/shopping-cart/shopping-cart.module';
import { SERedirectComponent } from './cartridges/smart-equip-redirect/se-redirect.component';
import { SubCategoryNavigationComponent } from './cartridges/sub-category-navigation/sub-category-navigation.component';
import { TabsComponent } from './cartridges/tabs/tabs.component';
import { TextPromoComponent } from './cartridges/text-promo/text-promo.component';
import { TrustFactorsComponent } from './cartridges/trust-factors/trust-factors.component';
import { UserRegistrationComponent } from './cartridges/user-registration/user-registration.component';
import { VideoComponent } from './cartridges/video/video.component';
import { Web2CaseModule } from './cartridges/web2case/web2case.module';
import { ZeroResultsMessagesComponent } from './cartridges/zero-results-messages/zero-results-messages.component';
import { BaseBreadcrumbsComponent } from './endeca-payload/base-breadcrumbs/base-breadcrumbs.component';
import { EndecaPayloadComponent } from './endeca-payload/endeca-payload.component';
import { EndecaService } from './endeca.service';
import { TemplateComponent } from './template/template.component';
import { TemplateService } from './template/template.service';
import { OneColumnPageComponent } from './templates/one-column-page/one-column-page.component';
import { PageSlotComponent } from './templates/page-slot/page-slot.component';
import { TwoColumnPageComponent } from './templates/two-column-page/two-column-page.component';
import { ArticleSearchBoxComponent } from './cartridges/article-search-box/article-search-box.component';
import { ArticleResultListComponent } from './cartridges/article-result-list/article-result-list.component';
import { KnowledgeArticleAutoSuggestComponent } from './cartridges/auto-suggest-panel/knowledge-article-auto-suggest/knowledge-article-auto-suggest.component';
import { ArticleResultListItemComponent } from './cartridges/article-result-list/article-result-list-item/article-result-list-item.component';
import { ArticleDetailComponent } from './cartridges/article-detail/article-detail.component';
import { MarketingBoxCartComponent } from './cartridges/marketing-box-cart/marketing-box-cart.component';
import { PartsSliderCartComponent } from './cartridges/parts-slider-cart/parts-slider-cart.component';
import { JumpLinkComponent } from './cartridges/jump-link-carousel/jump-link/jump-link.component';
import { JumpLinkCarouselComponent } from './cartridges/jump-link-carousel/jump-link-carousel.component';
import { ThreeColumnComponent } from './cartridges/three-column/three-column.component';
import { ThreeColumnCardComponent } from './cartridges/three-column/three-column-card/three-column-card.component';
import { TabSearchCountComponent } from './cartridges/tab-search-count/tab-search-count.component';
import { TabSearchResultsComponent } from './cartridges/tab-search-results/tab-search-results.component';
import { ControlResultListComponent } from './cartridges/control-result-list/control-result-list.component';
import { ControlResultListItemComponent } from './cartridges/control-result-list/control-result-list-item/control-result-list-item.component';
import { ControlDetailComponent } from './cartridges/control-detail/control-detail.component';
import { H1Component } from './cartridges/h1/h1.component';
import { ApplicationTourComponent } from './cartridges/application-tour/application-tour.component';
import { ListrakRecommenderComponent } from './cartridges/listrak-recommender/listrak-recommender.component';
import { CalloutTextComponent } from './cartridges/product-detail/callout-text/callout-text.component';
import { SlickModule } from '../shared/slick-carousel/slick.module';
import { ManualResultListComponent } from './cartridges/manual-result-list/manual-result-list.component';
import { ManualResultListItemComponent } from './cartridges/manual-result-list/manual-result-list-item/manual-result-list-item.component';
import { JLGManualResultListItemComponent } from './cartridges/manual-result-list/jlg-manual-result-list-item/jlg-manual-result-list-item.component';
import { TwoColumnComponent } from './cartridges/two-column/two-column.component';
import { ControlDownloadComponent } from './cartridges/control-detail/control-download/control-download.component';
import { ClearskyModule } from '../clearsky/clearsky.module';
import { SubscriptionModule } from '../clearsky/subscription/subscription.module';
import { ClearskyPageComponent } from './templates/clearsky-page/clearsky-page.component';
import { SearchBoxHeaderComponent } from './cartridges/search-box-header/search-box-header.component';
import { ProductMediaComponent } from './cartridges/product-detail/media/media.component';
import { UnauthorizedResponseComponent } from './cartridges/unauthorized-response/unauthorized-response.component';
import { CopyButtonModule } from '../shared/copy-button/copy-button.module';
import { SeeOnSiteButtonComponent } from './cartridges/product-detail/see-on-site-button/see-on-site-button.component';
import { SeeOnSiteDialogComponent } from './cartridges/product-detail/see-on-site-dialog/see-on-site-dialog.component';
import { QrCodeModule } from '../shared/qr-code/qr-code.module';
import { PardotFormComponent } from './cartridges/pardot-form/pardot-form.component';

@NgModule({
  imports: [
    AccountModule,
    CheckoutModule,
    CommonModule,
    RecaptchaV3Module,
    RecaptchaModule,
    PartsModule,
    PlacardModule,
    PlateIdReplacementModule,
    SharedModule,
    ShoppingCartModule,
    Web2CaseModule,
    SlickModule,
    ClearskyModule,
    SubscriptionModule,
    CopyButtonModule,
    QrCodeModule,
  ],
  declarations: [
    AdsenseComponent,
    ApplicationTourComponent,
    AppliedFiltersComponent,
    ArticleDetailComponent,
    ArticleSearchBoxComponent,
    ArticleResultListComponent,
    ArticleResultListItemComponent,
    AutoSuggestPanelComponent,
    BaseBreadcrumbsComponent,
    SearchBoxBannerComponent,
    BreadcrumbsComponent,
    BulletinBoardMessagesComponent,
    BulletinBoardMessageComponent,
    CartridgeComponent,
    CategoryNavigationComponent,
    CategoryNavigationItemComponent,
    CategoryPartsAutoSuggestComponent,
    ContentSlotLeftComponent,
    ContentSlotMainComponent,
    ContentSlotHeaderComponent,
    ContentSlotFooterComponent,
    ContentWithImageHeaderComponent,
    ControlDetailComponent,
    ControlDownloadComponent,
    ControlResultListComponent,
    ControlResultListItemComponent,
    CustomerPickupDialogComponent,
    EmergencyAlertsComponent,
    EndecaPayloadComponent,
    FlyOutPanelComponent,
    FlyOutPanelSlotComponent,
    ForgotUnlockWrapperComponent,
    FormWithBackgroundImageComponent,
    GuidedNavigationComponent,
    H1Component,
    HeroCarouselComponent,
    HeroCarouselImageComponent,
    HeroCarouselItemComponent,
    HorizontalRecordSpotlightComponent,
    ImageNavigationComponent,
    InteractiveManualsComponent,
    JLGManualResultListItemComponent,
    JumboTextListComponent,
    JumpLinkComponent,
    JumpLinkCarouselComponent,
    KeywordSearchAutoSuggestComponent,
    KnowledgeArticleAutoSuggestComponent,
    LatestNewsComponent,
    LatestNewsHeaderComponent,
    LatestNewsItemComponent,
    ListrakRecommenderComponent,
    ManualResultListComponent,
    ManualResultListItemComponent,
    MarketingBoxBannerComponent,
    MarketingBoxComponent,
    MarketingBoxCartComponent,
    NavigationImageComponent,
    NewPasswordFormComponent,
    NoContentHeroCarouselImageComponent,
    OneColumnPageComponent,
    PageHeadingComponent,
    PageSlotComponent,
    PardotFormComponent,
    PartsSliderCartComponent,
    PdpCrumbsComponent,
    PopularCategoriesComponent,
    ProductDetailComponent,
    ProductDetailRelatedItemsComponent,
    RatingsComponent,
    RecoveryAnswerFormComponent,
    RefinementCrumbsComponent,
    RefinementMenuComponent,
    ResultsListComponent,
    ResultsListFlyoutItemComponent,
    ProductDetailFlyoutItemComponent,
    ResultsListItemComponent,
    RichTextMainComponent,
    SchematicsComponent,
    SchematicsHelpComponent,
    SchematicsSelectorComponent,
    SearchAdjustmentsComponent,
    SearchComponent,
    SearchBoxHeaderComponent,
    SearchCrumbsComponent,
    SearchTypeaheadComponent,
    SERedirectComponent,
    SubCategoryNavigationComponent,
    TabsComponent,
    TabSearchCountComponent,
    TabSearchResultsComponent,
    TemplateComponent,
    TextPromoComponent,
    ThreeColumnComponent,
    ThreeColumnCardComponent,
    TrustFactorsComponent,
    TwoColumnComponent,
    TwoColumnPageComponent,
    UnauthorizedResponseComponent,
    UserRegistrationComponent,
    VerifyBySerialComponent,
    VideoComponent,
    ZeroResultsMessagesComponent,
    CalloutTextComponent,
    ProductMediaComponent,
    ClearskyPageComponent,
    SeeOnSiteButtonComponent,
    SeeOnSiteDialogComponent,
  ],
  exports: [
    CartridgeComponent,
    EmergencyAlertsComponent,
    EndecaPayloadComponent,
    OneColumnPageComponent,
    PageSlotComponent,
    SearchComponent,
    SearchTypeaheadComponent,
    TemplateComponent,
    ClearskyPageComponent,
  ],
  providers: [
    EndecaService,
    OktaAuthWrapper,
    TemplateService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LezBNgUAAAAANjl7Rg1_Z0hJ5vLopdtA0PfjOQc',
    },
  ],
})
export class EndecaModule {}
