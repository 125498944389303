import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { IShippingInfoDto } from "../../contracts/commerce/dto/ishipping-info-dto";
import { IShippingInfo } from "../../contracts/commerce/ishipping-info";
import { Observable } from "rxjs";

@Injectable()
export class ShippingService {

  constructor(private http: HttpClient) { }

  /**
   * Get ShippingInfo for checkout.
   *
   * @param {IShippingInfoDto} shippingInfo
   *
   * @returns {Observable<IShippingInfo>}
   */
  getShippingInfo(shippingInfo: IShippingInfoDto): Observable<IShippingInfo> {
    return this.http.post<IShippingInfo>(`${environment.apiUrl}/shipping/getShippingInfo`, shippingInfo);
  }

  /**
   * Get available ship vias for a user's account.
   * @returns {Observable<IShipVias[]>}
   */
  getAvailShipVias() {
    return this.http.get(`${environment.apiUrl}/shipping/shipVias`);
  }

  /**
   * Gets the available brokers for a user's account.
   * 
   * @returns {Observable<IBroker[]}
   */
  getBrokers() {
    return this.http.get(`${environment.apiUrl}/shipping/brokers`);
  }

}
