import { MachineFilterSelection } from '../machine/machine-filter-v2';
import { environment } from '../../../../environments/environment';
import { WidgetSize } from './cs-widget-sizing';

export interface CsDashboardDto {
  name: string;
  data: {
    filters: MachineFilterSelection[];
    widgets: string[];
    columns: string[];
  };
}

export interface CsDashboardData {
  filters: {
    fieldName: string;
    values: unknown[] | object;
  }[];
  widgets: string[];
  columns: string[];
}

export interface CsDashboard {
  id: number;
  name: string;
  data: CsDashboardData;
}

export const Widgets = {
  FLEET_OVERVIEW: 'fleetOverview',
  MODEL_TYPE: 'modelType',
  QUICK_LINKS: 'quickLinks',
  MACHINE_IN_USE: 'miuTime24SumAgg7days',
  FLEET_AVERAGE_AGE: 'fleetAverageAge',
  IGNITION_STATUS: 'ignitionStatus',
  FUEL_LEVEL: 'fuelLevel',
  STATE_OF_CHARGE: 'stateOfCharge',
  DTC: 'dtc',
  DEF_REMAINING: 'defRemaining',
  FLEET_AGE: 'fleetAge',
  LAST_REPORTED: 'lastReported',
  ALERTS: 'alerts',
  HARDWARE_REPORTING_METRIC: 'hardwareReportingStatus',
  ENVELOPE_PROFILE_USE: 'envelopeProfileUse',
  SITE_NETWORKS: 'siteNetworks',
  BATTERY_MONITORING: 'batteryMonitoring',
};

// Default widgets in prod
export const DefaultProdWidgets = [
  Widgets.FLEET_OVERVIEW,
  Widgets.QUICK_LINKS,
  Widgets.MODEL_TYPE,
  Widgets.MACHINE_IN_USE,
  Widgets.FLEET_AVERAGE_AGE,
  Widgets.IGNITION_STATUS,
  Widgets.FUEL_LEVEL,
  Widgets.STATE_OF_CHARGE,
  Widgets.DEF_REMAINING,
  Widgets.LAST_REPORTED,
  Widgets.ALERTS,
  Widgets.HARDWARE_REPORTING_METRIC,
  Widgets.ENVELOPE_PROFILE_USE,
  Widgets.BATTERY_MONITORING,
];

// Default widgets in test
export const DefaultTestWidgets = [
  ...DefaultProdWidgets,
  Widgets.SITE_NETWORKS,
];

// Default widgets based on env
export const DefaultWidgets = environment.production
  ? DefaultProdWidgets
  : DefaultTestWidgets;

// Dashboard widgets available in PROD (determines default ordering)
export const ProdWidgets = [
  ...DefaultProdWidgets,
  Widgets.DTC,
  Widgets.FLEET_AGE,
];

// Dashboard widgets available in DEV/TEST
export const TestWidgets = [
  ...DefaultTestWidgets,
  Widgets.DTC,
  Widgets.FLEET_AGE,
];

// Available widgets based on env
export const AvailableWidgets = environment.production
  ? ProdWidgets
  : TestWidgets;

export const WidgetsDisplay: { [key: string]: string } = {
  modelType: 'clearsky.model-type-composition-label',
  dtc: 'clearsky.diagnostic-label',
  fuelLevel: 'web2case.level2.fuel-level',
  fuelLevelQuantified: 'web2case.level2.fuel-level',
  defRemaining: 'clearsky.def-remaining-label',
  defRemainingQuantified: 'clearsky.def-remaining-label',
  ignitionStatus: 'clearsky.ignition-status-label',
  customerNumber: 'global.customer-number',
  miuTime24SumAgg7days: `clearsky.machine-uses-label`,
  fleetAge: 'clearsky.fleet-label',
  lastReported: 'clearsky.reported-today-label',
  alerts: 'global.alerts-label',
  hardwareReportingStatus: 'clearsky.hardware-metric-label',
  envelopeProfileUse: 'clearsky.envelope-profile-label',
  siteNetworks: 'clearsky.site-networks-label',
  batteryMonitoring: 'clearsky.battery-monitoring-label',
  quickLinks: 'global.quick-links',
  fleetAverageAge: 'clearsky.fleet-average-age-label',
  stateOfCharge: 'clearsky.state-of-charge-label',
  fleetOverview: 'clearsky.fleet-overview-label',
};

export const WidgetsSizing: {
  [widget: string]: WidgetSize;
} = {
  [Widgets.FLEET_OVERVIEW]: { col: 3, row: 0.5 },
  [Widgets.MODEL_TYPE]: { col: 3, row: 0.5 },
  [Widgets.DTC]: { col: 2, row: 1.5 },
  [Widgets.MACHINE_IN_USE]: { col: 3, row: 1.5 },
  [Widgets.FLEET_AGE]: { col: 2, row: 1 },
  [Widgets.ALERTS]: { col: 2, row: 1 },
  [Widgets.HARDWARE_REPORTING_METRIC]: { col: 2, row: 1 },
  [Widgets.ENVELOPE_PROFILE_USE]: { col: 3 },
  [Widgets.BATTERY_MONITORING]: { col: 2, row: 1.5 },
  [Widgets.LAST_REPORTED]: { col: 1, row: 0.5 },
  [Widgets.FLEET_AVERAGE_AGE]: { col: 1, row: 0.5 },
  [Widgets.SITE_NETWORKS]: { col: 2, row: 1.5 },
};

export const WidgetsKey = {
  alerts: 'alerts',
  hardwareReportingStatus: 'hardwareReportingStatus',
  envelopeProfileUse: 'envelopeProfile',
  siteNetworks: 'siteNetworks',
  batteryMonitoring: 'batteryMonitoringEquipment',
};

export const DefaultColumns: string[] = [
  'mtype',
  'model',
  'brand',
  'sn',
  'cname',
  'eid',
  'ign',
];

export const WidgetWidth = {
  Small: 200,
  Large: 400
}

export const WidgetPostion = {
  Left: 0,
  Right: 200
}
