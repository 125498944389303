// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { devHostUrls, isLocal, localHostUrls } from '../host-urls';

/**
 * @param {string} host Used for any API requests.
 * @param {string} referer Used to set the client id when rendering on the server.
 */
export function setEnvironmentVars(host: string, referer?: string) {
  const clientId: string = getClientID(referer ?? host);
  environment = {
    ...environment,
    production: isProduction,
    apiUrl: getApiUrl(host),
    bamboraApiUrl: getBamboraApiUrl(),
    webAppUrl: getWebAppUrl(),
    digitalIdUrl: getDigitalIdUrl(host),
    payloadUrl: getPayloadUrl(host),
    utilsBaseUrl: getUtilsBaseUrl(host),
    ATG_BASE_URL: getApiUrl(host),
    CLIENT_ID: clientId,
    scanditKey: getScanditKey(host),
    jlgStyling: getDefaultStyling(clientId),
    REDIRECT_URL: host,
    referer: referer ? referer : host,
    baseMetaTitle: getBaseMetaTitle(clientId),
  };
}

let isProduction: boolean = true;

const getPayloadUrl = (host: string) => {
  return host + '/json';
};

const getApiUrl = (host: string) => {
  return host + '/api/v1';
};

const getBamboraApiUrl = () => {
  return 'https://api.na.bambora.com';
};

const getUtilsBaseUrl = (host: string) => {
  return host + '/utils';
};

const getDigitalIdUrl = (host: string) => {
  return isLocal(host) ? 'https://auth-l.jlg.com' : 'https://auth-d.jlg.com';
};

const getWebAppUrl = () => {
  return 'https://apis-d.jlg.com';
};

const getClientID = (host: string) => {
  if (
    [...localHostUrls, ...devHostUrls].some((url) =>
      host.includes(`${url}.jlg.com`)
    )
  ) {
    isProduction = false;
    // JLG local & dev clientID
    return '0oacqyqkjlw6FjiBM0h7';
  } else if (
    [...localHostUrls, ...devHostUrls].some((url) =>
      host.includes(`${url}.jerrdan.com`)
    )
  ) {
    isProduction = false;
    // Jerrdan local & dev clientID
    return '0oagadxarhs0Zd1Jl0h7';
  } else {
    isProduction = false;
    // JLG local & dev clientID
    return '0oacqyqkjlw6FjiBM0h7';
  }
};

const getAuthClientProperties = () => {
  return {
    url: 'https://dev-480903.oktapreview.com',
    issuer: 'https://dev-480903.oktapreview.com/oauth2/default',
    authorizeUrl:
      'https://dev-480903.oktapreview.com/oauth2/default/v1/authorize',
  };
};

const getDefaultStyling = (clientId: string) => {
  // Sets jlgStyling to true for JLG, otherwise sets jlgStyling to false for Jerrdan
  return clientId === '0oacqyqkjlw6FjiBM0h7';
};

const getBaseMetaTitle = (clientId: string) => {
  return getDefaultStyling(clientId)
    ? 'JLG\u00AE Online Express'
    : 'Jerr-Dan\u00AE Online Express';
};

const getScanditKey = (host: string) => {
  if (localHostUrls.some((h) => host.includes(h) && host.includes('jlg'))) {
    // OLE local key
    return 'AcjTvx9OOfv/Qs9aMDzkefEY0SRcSWNJa2kXKyh4n/DIVFAtsnTdE1Zs5oAgan3VEHL++fh7iOoKQKTXUUp+usVE1sFcK4pKRFsI0dZ6zF/9QsPsfj7oAg5/J1s1c1ZgE3jY5jNo8K58UrdHo0QW5/Q8G7vrQplgzxFA1UIrH78fM89mfjQu7z8cnG6xQQNtFWpBnN89YMGk7pkoFxm/pHzKJCe6MG+hZ4dxUawkswCKaGXflO7gogjmHtfQVPN8iag2qkG7B+M7QdPzwqrBQ4XsgN86XfP5ZuQ6DrGQseK0Lg0yrweGxM0b6y0eDijyDigqecYLSGxqHwWloVBd7Z+UAbZgfR+5tkWpfYaA+pqGpF0skqcEEvhR1rY9K3rpgzKofri1gmXCaj1bYO6liFNONb3pUWeKRqpmPqf70lOHeEDCofDW05RTO5EFvHGOodYC68PRRqD7qrTtUeFY6NeDxlx6/Av8oIAiEayQwmYU4H5IMQcRMMDIFvQHlqFoPPdpwFnb6WRcx2KFBb0ygtTd14uU2Ygurxu2hrZpy5kKTeuZvw82zSvqtFRPa0JXIqsZoko7NarvJY/75WZRscS3cdz85PJF2aLK0XvxKnYx9dN5RzZU8AVGRkBsWK+VkzNJwdyLmbNreCTvjEAPQwknU6kN1z/T9nmbzNpV94dE3eN0SzdfGq7Jaguls/mCrOgnqz4d4VowWIBkqleAPvEeY4nfCu+Vu1UdKG6xwhGehnn5wvFLTcysHE9u/P8NRrgC3mQO2bm9ZgtDvvrvvYFNnl/JF0O26sNKydmUrdqezaLiUZXMCbjWNBm1qTqUZ66hKiB/bh8YuIkoLvyWwkLceuFLtOQ/qahvt+b+';
  } else {
    // OLE DEV key
    return 'AUUjtTNOFfBjRPMIITkVOHUCpYP9ZkKdX0fz/c1QOuIAfOikc3ictedP5xFGMn9eGU2CxKU54RMldkSnu0sfByJPaNbce+pyuFR0uRl4wxYCT+ytmjF8ng5rZ4ESLzGYUUlqOtUv9j/jYMhJuWW8mNcgEEvpKmsqLSjCbUU8eO24ADgGOUG5grYBhXHnI/AMBYtu05ZF0+T6wVlQETk+C4Yl54N9Mb3+W9p0zwiHDDPj5hFKMEgEIhHibX1ufCifa+x+PWLfnRUWMUq15Fz/iUmxG/If4BivvgLqhCO5xtvb79pRDPPsyq1MVjDwQvhVewt7hqdx8lKb0Gr+FxTeP2A5JTz0bVO9JwKH8si5wJwcsPLh4kjSdlJvPLS0U26jzpAuBQVIDExenENXufbNJf1ZxmB/igdqOJ9nS9PKDa6tDqCQd+K4/4Nh6dtMVl3ZJ4Pdf/DWNHgh3EyOnkB/UAKhxWoxxEDE2NuIzPczUXU66Bxiw2NRr6XVyqPBsnC+4402gzf0IvKZ88zNAiPxjbvdIJbkThqaJNQ+BOvTOHqVxgohTvTsIfAkd10GmZCKAiaBHk5dLciE03iJB8iZea0A9iepP2lytIVRc+PHgUMgH8GWM451OnbPc5XWVrxiYYyuK5F7dbJ1/82wmtDOXgfd0YrsCeQGWozL9/wSwvuzfzmDQ7gSAqEcuJt5WTqzUBPcjqMtaYnbwF6rCOXFHnMyz+69ihizBVFGB6tiDoqfVbvZGMjC4OWrtPWlLdmI+3syJIs64F2NP8IIjyiCm96ljqqtkASlxaLoIVFvMuh2pzjpglW81/k2GF47iPpT/bXGLkLBqtqNleLJKHiRRckyCdRYoRVu1dvFSTCe';
  }
};

export let environment = {
  production: false,
  trueProduction: false,
  apiUrl: '',
  bamboraApiUrl: '',
  webAppUrl: '',
  digitalIdUrl: '',
  BING_URL: 'Ar2lPUWEkOMjWoY7a3rZBuns2GmWK3_Q4WjaJk3ldPHzRudCYRb0uX_IsGP4mBlx',
  bingMapsKey:
    'Aooz0iJZI0QDHfQND3Qff6CpJpe4yaeTsgRE7KjedodLvHEN88ChF7T-iO1YrC5i', // Todo: This needs updated to be DEV/LOCAL
  azureMapsKey: 'imFQo8TxoX6IK6oBIbZ4hGeJ_owFdWVG33de3rxlt98',
  scanditKey: '',
  payPalMerchantId:
    'ARkbqP5H8UgTgZtPlatRZIgQ_bnx8-HcOX2WsakKjxW49uvVNJUj8iK8b6cFp387BG2ORjcLKl3Jjwxt',
  payloadUrl: '',
  authClientProperties: getAuthClientProperties(),
  utilsBaseUrl: '',
  ATG_BASE_URL: '',
  CLIENT_ID: '',
  SESSION_ID_NAME: 'dsid',
  jlgStyling: true,
  REDIRECT_URL: '',
  referer: '',
  baseMetaTitle: 'Online Express',
  ADX: true,
  imagePath: '/assets/img/',
  placeholderImg: 'image_placeholder.webp',
};
