export interface IReturnCodes {
  id: string;
  reasonCode: string;
  description: string;
}

export interface ReturnItem {
  displayInvoice: boolean;
  lastItem: boolean;
  invoiceDisplayURL: string;
  invoiceNumber: string;
  thumbnailImageUrl: string;
  itemDescription: string;
  itemNumber: string;
  listPrice: number;
  quantity: number;
  eligibleQty: number;
  returnQty: number;
  totalPrice: number;
  shipmentNumber: string;
}

export interface ReturnConfirmation {
  comOrderNumber: string;
  orderId: string;
}

export interface IReturnDetails {
  changeDate?: string;
  changeTime?: string;
  comOrderNumber?: string;
  companyNumber?: string;
  creationDate?: string;
  creditMemoNumber?: string;
  customerNumber?: string;
  entryDate?: string;
  entryTime?: string;
  erpEnvironment?: string;
  freight?: string;
  headerType?: string;
  invoiceDisplayURL?: string;
  invoiceNumber?: string;
  invoiceTotal?: string;
  invoiceType?: string;
  orderNumber?: string;
  orderToken?: string;
  poNumber?: string;
  reasonCode?: string;
  reasonComments?: string;
  referenceNumber?: string;
  restock?: string;
  rpaNumber?: string;
  sellWarehouse?: string;
  shipToNumber?: string;
  statusCode?: string;
  statusMsg?: string;
  stockWarehouse?: string;
  transmitTime?: string;
  details?: IReturnDetailsItem[];
  factoryComments?: IReturnDetailsComments[];
  returnAddress?: {
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    companyName?: string;
    country?: string;
    county?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    ownerId?: string;
    postalCode?: string;
    prefix?: string;
    state?: string;
    suffix?: string;
  };
}

export interface IReturnDetailsItem {
  acceptedQuantity?: string;
  companyNumber?: string;
  invoiceDisplayURL?: string;
  invoiceNumber?: string;
  invoiceSequence?: string;
  partDescription?: string;
  partNumber?: string;
  productURL?: string;
  receiveQuantity?: string;
  returnAmount?: string;
  returnQuantity?: string;
  rpaNumber?: string;
  thumbnailImageUrl?: string;
  transmitTime?: string;
  unitPrice?: number;
}

interface IReturnDetailsComments {
  comment?: string;
  lineNumber?: string;
}

export const ReturnStatus = {
  PENDING: 'Pending Review',
  APPROVED: 'Approved; Return Parts',
  CREDITED: 'Return Credited'
}