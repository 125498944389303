import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-navigation-image',
  styleUrls: ['./navigation-image.component.scss'],
  templateUrl: './navigation-image.component.html',
})
export class NavigationImageComponent {
  @Input()
  item!: {
    imageSrcUrl: string;
    navLabel: string;
    linkToTarget: string;
    linkToUrl: string;
    imageTitle: string;
    imageAlt: string;
  };

  constructor() {}
}
