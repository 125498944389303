import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OcidItems } from '../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from '../../../clearsky.service';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { map } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-access-metrics-widget',
  templateUrl: './access-metrics-widget.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'widget' },
})
export class AccessMetricsWidgetComponent implements OnInit {
  chartEl: ElementRef;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  displayName = 'clearsky.access-control-metrics-label';
  displayOptions$ = this.clearskyService.legendRef$.pipe(
    map((legend) => legend.ar || [])
  );
  ocids: OcidItems = {};
  showChart = false;
  isLoading = true;
  data = [];
  private colors = {
    0: WidgetColors.grey2,
    1: WidgetColors.red,
    2: WidgetColors.blue,
    3: WidgetColors.green,
    4: WidgetColors.orange,
  };
  private plot;

  constructor(
    protected localization: LocalizationService,
    protected clearskyService: ClearskyService
  ) {}

  ngOnInit(): void {
    this.localization
      .getOCIDs(['clearsky.graphic-default', this.displayName])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  toggleFilter(option: number): void {
    //
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    // Todo: Create chart (mock fuel level or other pie chart since we are using shared component now)
  }
}
