import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-control-download',
  templateUrl: './control-download.component.html',
})
export class ControlDownloadComponent implements OnInit {
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<ControlDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: unknown,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['control-software.download-file-module-message'])
      .pipe(first())
      .subscribe();
  }

  onConfirm() {
    // download the file
    this.dialogRef.close(true);
  }

  onClose() {
    this.dialogRef.close();
  }
}
