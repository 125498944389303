import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LocalizationService } from '../../../../../../shared/localization/localization.service';
import { IOrderDetailShipments } from '../../../../../../contracts/orders/iorder-detail-shipments';
import {
  IUser,
  UserEnvironments,
} from '../../../../../../contracts/user/iuser';
import { InvoiceInformationService } from '../../invoice-information/invoice-information.service';
import {
  IOrderDetail,
  IReturnSelection,
} from '../../../../../../contracts/orders/iorder-detail';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { IOrderDetailShipmentDetails } from '../../../../../../contracts/orders/iorder-detail-shipment-details';
import { environment } from '../../../../../../../environments/environment';
import {
  ChargeIds,
  IOrderSpecialCharges,
} from '../../../../../../contracts/orders/iorder-detail-specialCharges';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shipment-history',
  styleUrls: ['../order-details.component.scss'],
  templateUrl: './shipment-history.component.html',
})
export class ShipmentHistoryComponent implements OnInit {
  @Input() order!: IOrderDetail;
  @Input() dateFormat!: string;
  @Input() user!: IUser;
  @Input() platformBrowser!: boolean;
  @Output() addToCartEvent: EventEmitter<object> = new EventEmitter<object>();
  @Output() returnEvent: EventEmitter<IReturnSelection> =
    new EventEmitter<IReturnSelection>();
  ocids = {};
  isJlg = environment.jlgStyling;
  environments = UserEnvironments;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    private localization: LocalizationService,
    private invoiceService: InvoiceInformationService
  ) {}

  ngOnInit() {
    // Get OCIDS.
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'rpa.view-return-label',
        'web2case.return',
        'order-details.core-deposit-warning',
      ])
      .subscribe();
  }

  /**
   * Get the invoice image.
   */
  getInvoiceImage(shipment: IOrderDetailShipments) {
    // Only get the invoice image if the platform is browser.
    if (this.platformBrowser) {
      this.invoiceService.getInvoiceImage(shipment.invoiceDisplayURL).subscribe(
        (indInvoice: string) => {
          if (window.navigator) {
            // If the browser is Chrome or Firefox, open the invoice in a new tab for the user to view.
            // Removed IE support when upgrading to Angular 13.
            const newTab = window.open();
            newTab.document.body.innerHTML = `<iframe src='data:application/pdf;base64,${indInvoice}' width='100%' height='100%'></iframe>`;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  /**
   * Emit add to cart event
   *
   * @param {IOrderDetailShipments} item
   */
  addToCart(item: IOrderDetailShipments) {
    this.addToCartEvent.emit(item);
  }

  /**
   * Emit return item event
   *
   * @param {string} itemNum
   * @param {string} invoiceNum
   */
  returnItem(itemNum: string, invoiceNum: string) {
    this.returnEvent.emit({ itemNumber: itemNum, invoiceNumber: invoiceNum });
  }

  identifyShipments(index: number, item: IOrderDetailShipments) {
    return item.shipmentNumber;
  }

  identifyDetails(index: number, item: IOrderDetailShipmentDetails) {
    return item.itemNumber;
  }

  /**
   * Check if the shipped item has any special core charges and if user is AA
   * @param {IOrderSpecialCharges[]} specialCharges
   */
  checkSpecialCharge(specialCharges: IOrderSpecialCharges[]) {
    return (
      specialCharges.length &&
      specialCharges.some(
        (i) =>
          +i.amount > 0 &&
          (i.chargeId === ChargeIds.CORS ||
            i.chargeIdDesc === ChargeIds.CoreCharge)
      ) &&
      this.user.erpEnvironment === UserEnvironments.AA
    );
  }
}
