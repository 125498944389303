import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-pricing-plan-table-header-item',
  templateUrl: './pricing-plan-table-header-item.component.html',
  styleUrls: ['./pricing-plan-table-header-item.component.scss'],
})
export class PricingPlanTableHeaderItemComponent {
  @HostBinding('class.pricing-plan-table-header-item') isItem = true;
  private _isSelected: boolean;
  @Input()
  public get selected(): boolean {
    return this._isSelected;
  }
  public set selected(val: boolean) {
    this._isSelected = val;
    this.isSelected = val;
  }

  @HostBinding('class.pricing-plan-table-header-item-selected')
  public isSelected = false;
}
