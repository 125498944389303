// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chart-content {
  display: flex;
  flex-direction: column;
}
.chart-statistics {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
}
.chart-statistics-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 25px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVuZ2luZS1zdGF0dXMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQUNGOztBQUdFO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0FBQUo7QUFHRTtFQUNFLGFBQUE7RUFDQSxvQkFBQTtFQUNBLFlBQUE7QUFESjtBQUdJO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsdUJBQUE7RUFDQSxVQUFBO0VBQ0EsYUFBQTtBQUROIiwiZmlsZSI6ImVuZ2luZS1zdGF0dXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xyXG4gIGhlaWdodDogMTAwJTtcclxuICB3aWR0aDogMTAwJTtcclxufVxyXG5cclxuLmNoYXJ0IHtcclxuICAmLWNvbnRlbnQge1xyXG4gICAgZGlzcGxheTogZmxleDtcclxuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XHJcbiAgfVxyXG5cclxuICAmLXN0YXRpc3RpY3Mge1xyXG4gICAgZGlzcGxheTogZmxleDtcclxuICAgIGFsaWduLWl0ZW1zOiBzdHJldGNoO1xyXG4gICAgZmxleC1ncm93OiAxO1xyXG5cclxuICAgICYtaXRlbSB7XHJcbiAgICAgIGRpc3BsYXk6IGZsZXg7XHJcbiAgICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XHJcbiAgICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xyXG4gICAgICB3aWR0aDogNTAlO1xyXG4gICAgICBwYWRkaW5nOiAyNXB4O1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/widgets/engine-status/engine-status.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAGE;EACE,aAAA;EACA,sBAAA;AAAJ;AAGE;EACE,aAAA;EACA,oBAAA;EACA,YAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;EACA,aAAA;AADN;AACA,wlCAAwlC","sourcesContent":[":host {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n.chart {\r\n  &-content {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n\r\n  &-statistics {\r\n    display: flex;\r\n    align-items: stretch;\r\n    flex-grow: 1;\r\n\r\n    &-item {\r\n      display: flex;\r\n      flex-direction: column;\r\n      justify-content: center;\r\n      width: 50%;\r\n      padding: 25px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
