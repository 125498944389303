import { CsFilterBuilder } from './machine-filters/cs-filter-builder';
import { CSLegend, CSRefBasic } from '../clearsky-legend';
import { OcidItems } from '../../ocid-items';
import { CsInFilterBuilder } from './machine-filters/cs-in-filter-builder';
import { CsActiveLastDaysBuilder } from './machine-filters/cs-active-last-days-builder';
import { CsInactiveLastDaysBuilder } from './machine-filters/cs-inactive-last-days-builder';
import { CsRangeFilterBuilder } from './machine-filters/cs-range-filter-builder';
import { CsRangeYearToDayFilterBuilder } from './machine-filters/cs-range-year-to-day-filter-builder';
import { CsNullFilterBuilder } from './machine-filters/cs-null-filter-builder';
import { CsHasAnyFilterBuilder } from './machine-filters/cs-has-any-filter-builder';
import { CsBooleanCSFilterBuilder } from './machine-filters/cs-boolean-cs-filter-builder';
import * as dayjs from 'dayjs';
import { CsDateRangeIntBuilder } from './machine-filters/cs-date-range-int-builder';
import { CsInCsFilterBuilder } from './machine-filters/cs-in-cs-filter-builder';

export interface MachineFilterSelection {
  name?: string;
  key: string;
  values: unknown[];
}

export enum CSFilterType {
  ALL = 'HAS_ALL',
  ANY = 'HAS_ANY',
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  EQUALS_CS = 'EQUALS_CS',
  IN = 'IN',
  IN_CS = 'IN_CS',
  NONE = 'HAS_NONE',
  NOTNULL = 'NOTNULL',
  NULL = 'NULL',
  RANGE = 'RANGE',
}

export interface CSFilterRange {
  min?: number;
  max?: number;
}

export interface CSFilterData {
  disFromJson?: boolean;
  disFromLeg?: boolean;
  disFromOcids?: boolean;
  disStatic?: boolean;
  isNonEdit?: boolean;
  jsonProp?: string;
  key: string;
  label: string;
  legKey?: string;
}

/**
 * CS Filter keys that are stored in local storage.
 */
export const CSFilter: { [key: string]: CSFilterData } = {
  actLDays: {
    disFromLeg: true,
    key: 'actLDays',
    label: 'clearsky.turned-on-label',
    legKey: 'd90d',
  },
  asrc: {
    disFromLeg: true,
    key: 'asrc',
    label: 'clearsky.alert-source-label',
    legKey: 'asrc',
  },
  alerts: {
    disFromLeg: true,
    key: 'alerts',
    label: 'global.alerts-label',
    legKey: 'alerts',
  },
  chgStatus: {
    disFromOcids: true,
    key: 'chgStatus',
    label: 'clearsky.charging-status-label',
  },
  copHrs: {
    isNonEdit: true,
    key: 'copHrs',
    label: 'clearsky.operating-label',
  },
  csDev: {
    disFromLeg: true,
    key: 'csDev',
    label: 'clearsky.clearsky-device-label',
    legKey: 'csDev',
  },
  defRmQ: {
    disFromLeg: true,
    key: 'defRmQ',
    label: 'clearsky.def-remaining-label',
    legKey: 'defRmQ',
  },
  dtcId: {
    disFromLeg: true,
    isNonEdit: true,
    key: 'dtc',
    label: 'clearsky.dtcs-label',
    legKey: 'dtc',
  },
  dtcSvty: {
    disFromLeg: true,
    key: 'dtcSvty',
    label: 'global.code-category-label',
    legKey: 'svtyCategory',
  },
  fsrc: {
    disFromLeg: true,
    key: 'fsrc',
    label: 'clearsky.code-source-label',
    legKey: 'fsrc',
  },
  flvlQ: {
    disFromLeg: true,
    key: 'flvlQ',
    label: 'web2case.level2.fuel-level',
    legKey: 'flvlQ',
  },
  hasDtcs: {
    disFromOcids: true,
    key: 'hasDtcs',
    label: 'clearsky.has-DTCs-label',
  },
  hrm: {
    disFromLeg: true,
    key: 'hrm',
    label: 'clearsky.hrm-label',
    legKey: 'hrs',
  },
  ign: {
    disFromOcids: true,
    key: 'ign',
    label: 'clearsky.ignition-status-label',
  },
  inactLDays: {
    disFromLeg: true,
    key: 'inactLDays',
    label: 'clearsky.not-turned-on-label',
    legKey: 'd90d',
  },
  inNetwork: {
    disStatic: true,
    key: 'inNetwork',
    label: 'clearsky.in-network-label',
  },
  lastChrg: {
    isNonEdit: true,
    key: 'lastChrg',
    label: 'clearsky.time-charge-label',
  },
  mgroup: {
    disFromLeg: true,
    key: 'mgroup',
    label: 'global.model-group-label',
    legKey: 'mgroup',
  },
  model: {
    disFromLeg: true,
    key: 'model',
    label: 'clearsky.model-label',
    legKey: 'model',
  },
  mtype: {
    disFromLeg: true,
    key: 'mtype',
    label: 'global.model-type-label',
    legKey: 'mtype',
  },
  mxAge: {
    isNonEdit: true,
    key: 'mxAge',
    label: 'clearsky.machine-age-label',
  },
  siteEnter24: {
    disStatic: true,
    key: 'siteEnter24',
    label: 'clearsky.entered-SN-last-24-hours',
  },
  siteExit24: {
    disStatic: true,
    key: 'siteExit24',
    label: 'clearsky.exited-SN-last-24-label',
  },
  cn: {
    disFromLeg: true,
    legKey: 'cn',
    key: 'cn',
    label: 'global.customer-number',
  },
  sn: {
    isNonEdit: true,
    key: 'sn',
    label: 'clearsky.sn-label',
  },
  socQ: {
    disFromLeg: true,
    key: 'socQ',
    label: 'clearsky.state-of-charge-label',
    legKey: 'socQ',
  },
  socRng: {
    isNonEdit: true,
    key: 'socRng',
    label: 'clearsky.SOC-range-label',
  },
  socrRng: {
    isNonEdit: true,
    key: 'socrRng',
    label: 'clearsky.SOCer-range-label',
  },
  pvc: {
    isNonEdit: true,
    key: 'pvc',
    label: 'clearsky.pvc-label',
  },
};

/**
 * Filters that can NOT be checked if they exist on a machine.
 */
export const CSFilterExistsDisabled: string[] = [CSFilter.sn.key, CSFilter.cn.key];

/**
 * Return all available values by filter key.
 *
 * @param key
 * @constructor
 */
export const CSFilterVals = (key: string): unknown[] => {
  switch (key) {
    case CSFilter.actLDays.key:
    case CSFilter.inactLDays.key:
      return [...Array(8).keys()].slice(1);
    case CSFilter.chgStatus.key:
    case CSFilter.hasDtcs.key:
    case CSFilter.ign.key:
    case CSFilter.inNetwork.key:
    case CSFilter.siteEnter24.key:
    case CSFilter.siteExit24.key:
      return [true, false];
    default:
      return [];
  }
};

/**
 * Return all available values by filter key from legend.
 *
 * @param key
 * @param legend
 * @constructor
 */
export const CSFilterValsByLegend = (
  key: string,
  legend: CSLegend
): unknown[] => {
  const filter = Object.values(CSFilter).find((f) => f.key === key);

  switch (key) {
    case CSFilter.model.key:
      return filter ? legend[filter.legKey] : [];
    case CSFilter.cn.key:
      return filter ? legend[filter.legKey].map(item=>item.cn) : [];
    case CSFilter.actLDays.key:
    case CSFilter.inactLDays.key:
      return filter ? legend[filter.legKey].slice(0, 14) : [];
    default:
      return filter
        ? (legend[filter.legKey] || []).map((a: CSRefBasic) => a.id)
        : [];
  }
};

const CSFilterTransDefVal = (value: unknown): unknown => {
  switch (value) {
    case CSFilterType.NULL:
      return 'N/A';
    default:
      return value;
  }
};

export const CSFilterTransValByLeg = (
  key: string,
  value: unknown,
  reader: CSLegend
): unknown => {
  const filter = Object.values(CSFilter).find((f) => f.key === key);

  switch (key) {
    case CSFilter.asrc.key:
    case CSFilter.alerts.key:
    case CSFilter.csDev.key:
    case CSFilter.defRmQ.key:
    case CSFilter.dtcId.key:
    case CSFilter.dtcSvty.key:
    case CSFilter.fsrc.key:
    case CSFilter.flvlQ.key:
    case CSFilter.hrm.key:
    case CSFilter.mgroup.key:
    case CSFilter.mtype.key:
    case CSFilter.socQ.key: {
      const rec = (reader[filter.legKey] || []).find(
        (a: CSRefBasic) => a.id === value
      );
      return rec ? rec.desc : CSFilterTransDefVal(value);
    }
    case CSFilter.actLDays.key:
    case CSFilter.inactLDays.key: {
      return dayjs.utc(value as string).format('M/D/YYYY');
    }
    default:
      return CSFilterTransDefVal(value);
  }
};

export const CSFilterSort = (key: string, a: unknown, b: unknown) => {
  switch (key) {
    case CSFilter.actLDays.key:
    case CSFilter.inactLDays.key: {
      return dayjs(b as string).valueOf() - dayjs(a as string).valueOf();
    }
    case CSFilter.defRmQ.key:
    case CSFilter.dtcSvty.key:
    case CSFilter.flvlQ.key:
    case CSFilter.socQ.key: {
      return 0;
    }
    default:
      return String(a).localeCompare(String(b));
  }
};

/**
 * Transform filter values to its legend values.
 *
 * @param key
 * @param values
 * @param reader
 * @constructor
 */
export const CSFilterTransValsByLeg = (
  key: string,
  values: unknown[],
  reader: CSLegend
): unknown[] => {
  return values.map((val) => CSFilterTransValByLeg(key, val, reader));
};

/**
 * Transform single filter value to its OCID.
 *
 * @param key
 * @param value
 * @param ocids
 * @constructor
 */
export const CSFilterTransValByOcids = (
  key: string,
  value: unknown,
  ocids: OcidItems
): unknown => {
  switch (key) {
    case CSFilter.chgStatus.key:
    case CSFilter.hasDtcs.key:
    case CSFilter.inNetwork.key:
    case CSFilter.siteEnter24.key:
    case CSFilter.siteExit24.key:
      return value ? ocids['global.yes'] : ocids['global.no'];
    case CSFilter.ign.key:
      return value ? ocids['global.on-label'] : ocids['global.off-label'];
    case CSFilter.lastChrg.key: {
      const range = value as CSFilterRange;
      if (!range.min && range.max) {
        return `less than ${range.max} days`;
      }

      if (!range.max && range.min) {
        return `greater than ${range.min} days`;
      }

      return `${range.min} to ${range.max} days`;
    }
    default:
      return CSFilterTransDefVal(value);
  }
};

/**
 * Transform all filter values to their OCIDs.
 *
 * @param key
 * @param values
 * @param reader
 * @constructor
 */
export const CSFilterTransValsByOcids = (
  key: string,
  values: unknown[],
  reader: OcidItems
): unknown[] => {
  return values.map((val) => CSFilterTransValByOcids(key, val, reader));
};

/**
 * Get set filter values transformed to it's correct display state.
 *
 * @param key
 * @param values
 * @param reader
 * @constructor
 */
export const CSFilterTransVals = (
  key: string,
  values: unknown[],
  reader: CSLegend | OcidItems
): unknown[] => {
  const filter = Object.values(CSFilter).find((f) => f.key === key);

  // Display from legend?
  if (filter && filter.disFromLeg) {
    return CSFilterTransValsByLeg(key, values, reader as CSLegend);
  }

  // Display from OCIDs?
  if (filter && filter.disFromOcids) {
    return CSFilterTransValsByOcids(key, values, reader as OcidItems);
  }

  // Nothing else so just return the static values already set
  return values;
};

export const CSFiltersSelect = (
  key: string,
  legend: CSLegend
): { key: string | number; values: unknown[] }[] => {
  switch (key) {
    default:
      return [];
  }
};

/**
 * Toggle filter values based on current filter values set.
 * @param value
 * @param currentValues
 * @param availableValues
 */
export const toggleCsFilterVal = (
  value: unknown,
  currentValues: unknown[],
  availableValues: unknown[]
): unknown[] => {
  const filterValues = [
    ...(currentValues.length ? currentValues : availableValues),
  ];
  const index = filterValues.indexOf(value);
  index === -1 ? filterValues.push(value) : filterValues.splice(index, 1);
  return filterValues.length === availableValues.length ? [] : filterValues;
};

/**
 * All OCIDs that are needed for filters.
 */
export const CSFilterValueOcids: string[] = [
  'global.on-label',
  'global.yes',
  'web2case.level2.engine',
  'clearsky.active-label',
  'clearsky.inactive-label',
];
export const CSFilterLabelOcids: string[] = [
  'clearsky.choose-dashboard-label',
  'clearsky.override-dashboard-label',
  'clearsky.continue-label',
  'clearsky.delete-dashboard-label',
  'clearsky.clear-dashboard-label',
  'clearsky.turned-on-label',
  'clearsky.alert-source-label',
  'clearsky.charging-status-label',
  'clearsky.operating-label',
  'clearsky.def-remaining-label',
  'clearsky.dtcs-label',
  'clearsky.code-severity-label',
  'clearsky.code-source-label',
  'web2case.level2.fuel-level',
  'clearsky.has-DTCs-label',
  'clearsky.hrm-label',
  'clearsky.ignition-status-label',
  'clearsky.not-turned-on-label',
  'clearsky.in-network-label',
  'clearsky.time-charge-label',
  'clearsky.model-label',
  'global.model-group-label',
  'global.model-type-label',
  'clearsky.machine-age-label',
  'clearsky.entered-SN-last-24-hours',
  'clearsky.exited-SN-last-24-label',
  'globale.customer-number',
  'clearsky.sn-label',
  'clearsky.SOC-label',
  'clearsky.SOC-range-label',
  'clearsky.SOCer-range-label',
  'clearsky.clearsky-device-label',
];

/**
 * If a filter is directly correlated to a machie property, it'll be listed here.
 */
export const CSFilterColumn: { [key: string]: string } = {
  [CSFilter.cn.key]: 'cn',
  [CSFilter.asrc.key]: 'asrc',
  [CSFilter.alerts.key]: 'alerts',
  [CSFilter.chgStatus.key]: 'cncStat',
  [CSFilter.copHrs.key]: 'copHrs',
  [CSFilter.csDev.key]: 'csDev',
  [CSFilter.defRmQ.key]: 'defRmQ',
  [CSFilter.dtcId.key]: 'dtc',
  [CSFilter.dtcSvty.key]: 'fsvty',
  [CSFilter.fsrc.key]: 'fsrc',
  [CSFilter.flvlQ.key]: 'flvlQ',
  [CSFilter.hasDtcs.key]: 'dtc',
  [CSFilter.hrm.key]: 'hrs',
  [CSFilter.ign.key]: 'ign',
  [CSFilter.inNetwork.key]: 'snID',
  [CSFilter.lastChrg.key]: 'dolc',
  [CSFilter.model.key]: 'model',
  [CSFilter.mgroup.key]: 'mgroup',
  [CSFilter.mtype.key]: 'mtype',
  [CSFilter.mxAge.key]: 'mxAge',
  [CSFilter.siteEnter24.key]: 'snEn',
  [CSFilter.siteExit24.key]: 'snEx',
  [CSFilter.sn.key]: 'sn',
  [CSFilter.socQ.key]: 'socQ',
  [CSFilter.socRng.key]: 'soc',
  [CSFilter.socrRng.key]: 'socr',
};

/**
 * Non editable filters.
 */
export const CSFilterNonEdit = Object.values(CSFilter).reduce(
  (prev, filter) => {
    if (filter.isNonEdit) {
      prev.push(filter.key);
    }

    return prev;
  },
  [] as string[]
);

/**
 * Filter labels.
 */
export const CSFilterLabel = Object.values(CSFilter).reduce((prev, filter) => {
  prev[filter.key] = filter.label;
  return prev;
}, {} as { [key: string]: string });

/**
 * Filter keys.
 */
export const CSFilterKeys = Object.values(CSFilter).map((filter) => filter.key);

/**
 * Class relations by filter key to build filter for QAPI.
 */
export const CSFilterBuilderClass: {
  [key: string]: () => CsFilterBuilder;
} = {
  [CSFilter.actLDays.key]: () => new CsActiveLastDaysBuilder(CSFilter.actLDays),
  [CSFilter.asrc.key]: () => new CsHasAnyFilterBuilder(CSFilter.asrc),
  [CSFilter.alerts.key]: () => new CsHasAnyFilterBuilder(CSFilter.alerts),
  [CSFilter.chgStatus.key]: () =>
    new CsBooleanCSFilterBuilder(CSFilter.chgStatus),
  [CSFilter.copHrs.key]: () =>
    new CsRangeYearToDayFilterBuilder(CSFilter.copHrs),
  [CSFilter.csDev.key]: () => new CsInCsFilterBuilder(CSFilter.csDev),
  [CSFilter.defRmQ.key]: () => new CsInCsFilterBuilder(CSFilter.defRmQ),
  [CSFilter.dtcId.key]: () => new CsHasAnyFilterBuilder(CSFilter.dtcId),
  [CSFilter.dtcSvty.key]: () => new CsHasAnyFilterBuilder(CSFilter.dtcSvty),
  [CSFilter.fsrc.key]: () => new CsHasAnyFilterBuilder(CSFilter.fsrc),
  [CSFilter.flvlQ.key]: () => new CsInCsFilterBuilder(CSFilter.flvlQ),
  [CSFilter.hasDtcs.key]: () => new CsNullFilterBuilder(CSFilter.hasDtcs),
  [CSFilter.hrm.key]: () => new CsInCsFilterBuilder(CSFilter.hrm),
  [CSFilter.ign.key]: () => new CsBooleanCSFilterBuilder(CSFilter.ign),
  [CSFilter.inactLDays.key]: () =>
    new CsInactiveLastDaysBuilder(CSFilter.inactLDays),
  [CSFilter.inNetwork.key]: () => new CsNullFilterBuilder(CSFilter.inNetwork),
  [CSFilter.lastChrg.key]: () =>
    new CsDateRangeIntBuilder(CSFilter.lastChrg, { unit: 'days' }),
  [CSFilter.model.key]: () => new CsInCsFilterBuilder(CSFilter.model),
  [CSFilter.mgroup.key]: () => new CsInCsFilterBuilder(CSFilter.mgroup),
  [CSFilter.mtype.key]: () => new CsInCsFilterBuilder(CSFilter.mtype),
  [CSFilter.mxAge.key]: () => new CsRangeFilterBuilder(CSFilter.mxAge),
  [CSFilter.siteEnter24.key]: () =>
    new CsBooleanCSFilterBuilder(CSFilter.siteEnter24),
  [CSFilter.siteExit24.key]: () =>
    new CsBooleanCSFilterBuilder(CSFilter.siteExit24),
  [CSFilter.cn.key]: () => new CsInFilterBuilder(CSFilter.cn),
  [CSFilter.sn.key]: () => new CsInFilterBuilder(CSFilter.sn),
  [CSFilter.socQ.key]: () => new CsInCsFilterBuilder(CSFilter.socQ),
  [CSFilter.socRng.key]: () => new CsRangeFilterBuilder(CSFilter.socRng),
  [CSFilter.socrRng.key]: () => new CsRangeFilterBuilder(CSFilter.socrRng),
};

/**
 * Format of filter for QAPI.
 */
export interface QAPIFilter {
  column: string;
  filterType: string;
  operator?: string;
  values?: unknown[];
}

/**
 * Reformat current set filters in browser to what QAPI request needs.
 * @param filters
 */
export const transformToQAPIFilters = (
  filters: MachineFilterSelection[]
): QAPIFilter[] => {
  return filters.reduce((prev, filter) => {
    prev =
      filter.values.includes(CSFilterType.NULL) &&
      !CSFilterExistsDisabled.includes(filter.key)
        ? prev.concat(
            new CsNullFilterBuilder(CSFilter[filter.key]).buildFilter([false])
          )
        : prev.concat(
            CSFilterBuilderClass[filter.key]().buildFilter(
              filter.values as unknown[]
            )
          );
    return prev;
  }, []);
};

/**
 * Compare two sets of filters.
 * @param filters
 * @param filters2
 */
export const filtersAreEqual = (
  filters: MachineFilterSelection[],
  filters2: MachineFilterSelection[]
): boolean => {
  // Sort both filters first because lodash isEqual returns false if array orders are not the same
  return (
    filters.length === filters2.length &&
    filters.every((f) => {
      // Find filter in other array
      const of = filters2.find((x) => x.key === f.key);

      // If it exists, loop through its values and check if the original one has those values too (every one)
      return (
        of &&
        of.values.every((v) => {
          return f.values.findIndex((y) => y === v) !== -1;
        })
      );
    })
  );
};
