import { Injectable } from '@angular/core';

@Injectable()
export class BVLoader {
  private static promise: Promise<string>;

  public static load(lang: string, isProd: boolean) {
    if (!BVLoader.promise) {
      // Make promise to load
      BVLoader.promise = new Promise((resolve) => {
        const node = document.createElement('script');
        node.src = `https://apps.bazaarvoice.com/deployments/jlg/main_site/${
          isProd ? 'production' : 'staging'
        }/${lang}/bv.js`;
        node.type = 'text/javascript';
        node.async = true;
        node.defer = true;
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return BVLoader.promise;
  }
}
