import { Component, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-hero-carousel-image',
  styleUrls: ['./hero-carousel-image.component.scss'],
  templateUrl: './hero-carousel-image.component.html',
})
export class HeroCarouselImageComponent {
  @Input() slide!: {
    btnText: string;
    imageSrcUrl: string;
    linkToTarget: string;
    linkToUrl: string;
    imageTitle: string;
    imageAlt: string;
    h1TitleTag: string;
    subHeadlineText: string;
    headlineText: string;
    descriptionText: string;
    imageHeight: string;
    marqueeStyle: string;
    textAlignment: string;
  };
  @Input() activeSlide: number;
  @Input() index: number;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor() {
    this.activeSlide = 0;
    this.index = 0;
  }
}
