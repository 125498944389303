import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Machine,
  MachineLocation,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { BingMapsService } from '../../../../../service/bing-maps.service';
import {
  getBingMapInstance,
  MachinePushpinColors,
} from '../../../../../contracts/clearsky/machine/machine.map.config';
import * as dayjs from 'dayjs';
import { WidgetColors } from '../../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-breadcrumbs',
  templateUrl: './machine-breadcrumbs.component.html',
})
export class MachineBreadcrumbsComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('map') map: ElementRef;
  @ViewChild('infoBoxTemplate') infoBoxTemplate: ElementRef;
  @Input() machine: Machine;
  @Input() historicData: MachineLocation[] = [];
  bingMap: Microsoft.Maps.Map;
  infoBox: Microsoft.Maps.Infobox;
  isLoading = true;
  ocids: OcidItems = {};

  constructor(
    private bingMaps: BingMapsService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs(['global.no-data-label'])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  ngAfterViewInit(): void {
    this.bingMaps.initialize().subscribe(() => {
      this.drawMap();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.historicData && !changes.historicData.firstChange) {
      this.drawMap();
    }
  }

  /**
   * Draw map based on breadcrumbs.
   * @private
   */
  private drawMap(): void {
    this.isLoading = true;

    if (!this.bingMap) {
      this.createChart();
    }

    // Clear old layer
    this.bingMap.entities.clear();
    this.infoBox.setOptions({ visible: false });

    // Now loop through data property
    let prevLoc: Microsoft.Maps.Location | undefined;
    const locs = this.historicData.reduce((prev, point, index) => {
      const loc = new Microsoft.Maps.Location(point.lat, point.lng);
      const pushpin = new Microsoft.Maps.Pushpin(loc, {
        color:
          index === 0
            ? Microsoft.Maps.Color.fromHex(MachinePushpinColors.INSIDE_RADIUS)
            : Microsoft.Maps.Color.fromHex(MachinePushpinColors.DEFAULT),
      });

      // Store some metadata with the pushpin so we can show info box
      pushpin.metadata = { point };

      // Add a click event handler to the pushpin.
      Microsoft.Maps.Events.addHandler(
        pushpin,
        'click',
        this.pushpinClicked.bind(this)
      );

      // Now store it
      this.bingMap.entities.push(pushpin);

      // Do we need to draw a line?
      if (prevLoc) {
        this.drawLine([prevLoc, loc]);
      }

      // Set the previous location to this one so next loop we can draw a line
      prevLoc = loc;

      prev.push(loc);
      return prev;
    }, [] as Microsoft.Maps.Location[]);

    const rect = Microsoft.Maps.LocationRect.fromLocations(locs);
    this.bingMap.setView({
      bounds: rect,
      padding: 50,
    });

    this.isLoading = false;
  }

  /**
   * Draw line betwen two coords.
   * @param coords
   * @private
   */
  private drawLine(coords: Microsoft.Maps.Location[]): void {
    const line = new Microsoft.Maps.Polyline(coords, {
      strokeColor: WidgetColors.blue,
      strokeThickness: 3,
    });

    this.bingMap.entities.push(line);
  }

  /**
   * Create the base chart.
   * @private
   */
  private createChart(): void {
    this.bingMap = getBingMapInstance(this.map.nativeElement, {
      showLocateMeButton: false,
      showZoomButtons: false,
    });

    // Create an infobox at the center of the map but don't show it.
    this.infoBox = new Microsoft.Maps.Infobox(this.bingMap.getCenter(), {
      visible: false,
    });

    // Assign the infobox to a map instance.
    this.infoBox.setMap(this.bingMap);
    Microsoft.Maps.Events.addHandler(
      this.infoBox,
      'click',
      this.handleClickInfoBox.bind(this)
    );
  }

  /**
   * Handle event when user clicks on the infobox.
   * @param e
   * @private
   */
  private handleClickInfoBox(e): void {
    const element = e.originalEvent.target;

    // Close functionality
    if (element.id === 'info-box-close') {
      this.infoBox.setOptions({ visible: false });
    }
  }

  /**
   * Open info box on map with machine data.
   * @param e
   * @private
   */
  private pushpinClicked(e): void {
    // Make sure the infobox has metadata to display.
    if (e.target.metadata) {
      const point = e.target.metadata.point;
      const timestamp = dayjs(point.ct);

      // Set the infobox options with the metadata of the pushpin.
      this.bingMaps
        .getAddressByGeo(point.lat, point.lng)
        .subscribe((location) => {
          this.infoBox.setOptions({
            htmlContent: this.infoBoxTemplate.nativeElement.innerHTML
              .replace(
                '||address||',
                location
                  ? location.formattedAddress
                  : this.ocids['global.no-data-label']
              )
              .replace('||times||', timestamp.format('M/D/YYYY h:mmA')),
          });

          // Locate info box on pushpin and show it
          this.infoBox.setOptions({
            location: e.target.getLocation(),
            visible: true,
          });
        });
    }
  }
}
