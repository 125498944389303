import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  EnvelopeHistoricMapping,
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import {
  getZoneScale,
  getZoneTooltip,
} from '../../../../../contracts/clearsky/machine/machine.chart.config';
import { EnvelopeProfileTelehandlerChart } from '../envelope-profile-telehandler-chart';
import { CSLegend } from '../../../../../contracts/clearsky/clearsky-legend';
import { ClearskyService } from '../../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MxMGroupImg } from '../../../../../contracts/clearsky/machine/machine.images';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-envelope-profile-telehandler',
  templateUrl: './envelope-profile-telehandler.component.html',
})
export class EnvelopeProfileTelehandlerComponent implements OnInit, OnChanges {
  @Input() machine: Machine;
  @Input() historic: string = HistoricSelection.HOURS_24;
  private chartEl: ElementRef;
  @ViewChild('chart', { read: ElementRef }) set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  showChart = false;
  image: string | undefined;
  private plot: Highcharts.Chart | undefined;
  private legend!: CSLegend;
  ocids: OcidItems = {};

  constructor(private clearskyService: ClearskyService, private localization: LocalizationService) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.hours-label',
        'clearsky.graphic-default',
      ])
      .pipe(first())
      .subscribe();
    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.showChart = !!this.historicData;
      this.image = this.machine.mgroup ? MxMGroupImg[this.machine.mgroup] : '';
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.historic && !changes.historic.firstChange) {
      this.showChart = !!this.historicData;

      if (this.plot && this.showChart) {
        this.setChartData();
      }
    }
  }

  /**
   * Create the chart.
   * @protected
   */
  protected createChart(): void {
    this.plot = EnvelopeProfileTelehandlerChart.createInstance().createChart(
      this.chartEl,
      this.getSeriesData.bind(this),
      {
        typeDescription: 'Telehandlers',
        tooltip: (zone: number) =>
          getZoneTooltip(zone, this.historicData, this.machine.ezTL),
      }
    );
  }

  /**
   * Set chart data.
   * @protected
   */
  protected setChartData(): void {
    // Redraw data points
    this.plot.series[0].setData(this.getSeriesData());
  }

  /**
   * Get series of current historical data selection.
   * @protected
   */
  protected getSeriesData(): any[] {
    const zones = Object.values(this.historicData).map((zone) => zone);
    const colorScale = getZoneScale(zones);

    return Object.entries(this.historicData)
      .map(([zone, value]) => {
        // Get color for zone
        const zoneNumber = +zone.slice(-1);
        const formattedValue = value;

        return {
          name: zone,
          zoneNumber,
          y: 1,
          value: formattedValue,
          color: colorScale[formattedValue],
          borderColor: '#000',
        };
      })
      .reverse();
  }

  /**
   * Get current historic data selection from machine data.
   */
  get historicData(): { [zone: string]: number } {
    return this.machine[EnvelopeHistoricMapping[this.historic]] || null;
  }
}
