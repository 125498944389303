import { Component, Input, OnInit } from '@angular/core';
import { Machine } from '../../../../../contracts/clearsky/machine/machine.dto';
import { ClearskyService } from '../../../../clearsky.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { MatDialog } from '@angular/material/dialog';
import { MachineHistoricDtcsDialogComponent } from '../../machine-historic-dtcs-dialog/machine-historic-dtcs-dialog.component';
import { MachineDialogConfig } from '../../../../../contracts/clearsky/machine/machine.dialog.config';
import { CSLegend } from '../../../../../contracts/clearsky/clearsky-legend';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-machine-status',
  templateUrl: './asset-machine-status.component.html',
  styleUrls: ['./asset-machine-status.component.scss'],
})
export class AssetMachineStatusComponent implements OnInit {
  @Input() machine: Machine;
  widgetName = MachineWidgets.MACHINE_STATUS;
  displayName = MachineWidgetsDisplay.assetMachineStatus;
  carouselData: { [key: string]: number } = {};
  slideConfig = {
    infinite: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  ocids: OcidItems = {};
  legend?: CSLegend;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.diagnostic-trouble codes-label',
        'clearsky.connectivity-status-label',
        'clearsky.no-active-dtcs-label',
        'clearsky.reported-tech-tip-message',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );

    this.carouselData = this.machine.ascnt ?? {};
  }

  /**
   * On activate dtc dialog click listener.
   */
  onActivateDTCDialog(): void {
    this.dialog.open(MachineHistoricDtcsDialogComponent, {
      ...MachineDialogConfig,
      data: {
        machine: this.machine,
      },
      panelClass: ['mat-dialog-right', '_75', 'clearsky-dialog'],
    });
  }
}
