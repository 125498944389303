import { Component, Input, OnInit } from '@angular/core';
import { ClearskyService } from 'app/clearsky/clearsky.service';
import {
  BasicWeather,
  Machine,
  TemperatureUnitType,
  UnitConst,
  UnitType,
  UnitTypeConst,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { Observable, map, tap } from 'rxjs';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { UserEnvironments } from 'app/contracts/user/iuser';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-local-weather',
  templateUrl: './machine-local-weather.component.html',
  styleUrls: ['./machine-local-weather.component.scss'],
})
export class MachineLocalWeatherComponent implements OnInit {
  @Input() machine: Machine;
  unit = UnitType.IMPERIAL;
  unitTypes = UnitTypeConst;
  weather$: Observable<BasicWeather | null>;
  ocids: OcidItems = {};
  unitInfo = UnitConst;
  widgetName = MachineWidgets.LOCAL_WEATHER;
  displayName = MachineWidgetsDisplay.machineLocalWeather;
  unitOfTemperature$: Observable<TemperatureUnitType>;
  temperatureUnitType = TemperatureUnitType;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private currentUserService: CurrentUserService
  ) { this.temperatureUnitType.CELSIUS }

  ngOnInit(): void {
    this.unitOfTemperature$ = this.currentUserService.getUser().pipe(
      untilDestroyed(this),
      map(user => user.unitOfTemperature ?
        user.unitOfTemperature : (user.erpEnvironment === UserEnvironments.AA ?
          this.temperatureUnitType.FAHRENHEIT : this.temperatureUnitType.CELSIUS
        )
      )
    );
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.wind-label',
        'clearsky.humidity-label',
        'clearsky.feel-like-label',
        'clearsky.no-available-label',
        'clearsky.f-label',
        'clearsky.c-label',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.weather$ = this.clearskyService.getLocalWeather(
      this.machine,
      this.unit
    );
  }
}
