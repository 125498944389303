import {
  Component,
  Input,
  OnInit,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { environment } from '../../../../environments/environment';

interface MediaInfo {
  videoSrcUrl?: string;
  imageSrcUrl?: string;
  mediaTitle?: string;
  mediaAlt?: string;
}
interface MainContent {
  content?: string;
  h3Text?: string;
  linkToUrl?: string;
  btnText?: string;
  linkToTarget?: string;
  downloadLinkTarget?: string;
  downloadLinkText?: string;
  downloadLinkUrl?: string;
}

@Component({
  selector: 'app-two-column',
  templateUrl: './two-column.component.html',
  styleUrls: ['./two-column.component.scss'],
})
export class TwoColumnComponent implements OnInit, AfterViewInit {
  @Input() data!: {
    background: string;
    descriptionText: string;
    h2Text: string;
    paddingBottom: boolean;
    paddingTop: boolean;
    leftContent: MediaInfo | MainContent;
    rightContent: MediaInfo | MainContent;
  };
  imgLeft = false;
  media: MediaInfo = {};
  content: MainContent = {};
  videoUrlSanitized!: SafeResourceUrl;
  dataLayer = (this.winRef.nativeWindow.dataLayer =
    this.winRef.nativeWindow.dataLayer || []);
    placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private sanitizer: DomSanitizer,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // determine which column - left or right - the image is on and set the media and content objects accordingly
    if (
      'videoSrcUrl' in this.data.rightContent ||
      'imageSrcUrl' in this.data.rightContent
    ) {
      this.media = this.data.rightContent as MediaInfo;
      this.content = this.data.leftContent as MainContent;
    } else {
      this.media = this.data.leftContent as MediaInfo;
      this.content = this.data.rightContent as MainContent;
      this.imgLeft = true;
    }

    // If there's a video, set the sanitized video url based on whether it's a DAM url or a Youtube video ID
    this.videoUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.media.videoSrcUrl?.startsWith('https')
        ? this.media.videoSrcUrl
        : 'https://www.youtube.com/embed/' + this.media.videoSrcUrl
    );
  }

  ngAfterViewInit() {
    // send GTM event to track video plays *after* the video has loaded
    if (isPlatformBrowser(this.platformId)) {
      this.dataLayer.push({
        event: 'hasVideo',
      });
    }
  }
}
