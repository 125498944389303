import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dtcToolTip' })
export class DTCTooltipPipe implements PipeTransform {
  transform(value: string): string {
    return value
      ?.replace('DTC', '<span title="Diagnostic Trouble Code">DTC</span>')
      ?.replace('DTCs', '<span title="Diagnostic Trouble Codes">DTCs</span>');
  }
}
