export const WidgetColors = {
  grey: 'rgba(238, 238, 238)',
  grey2: 'rgba(179, 181, 184)', // standard gray
  red: 'rgb(192, 46, 29)',
  orange: 'rgb(255, 84, 5)',
  green: 'rgb(156, 204, 101)',
  blue: 'rgb(19, 149, 186)',
  aqua: 'rgb(0, 187, 201)',
  yellow: 'rgb(237, 221, 78)'
};
