import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { SearchService } from '../search/search.service';
import { LayoutService } from '../../../service/layout.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-search-box-header',
  styleUrls: [
    './../search-box-banner/search-box-banner.component.scss',
    './search-box-header.component.scss',
  ],
  templateUrl: './search-box-header.component.html',
})
export class SearchBoxHeaderComponent implements OnInit, CartridgeInterface {
  @Input() data: {
    '@type': string;
    imageUrl: string;
    imageShading: string;
    position: string;
    searchBoxBannerHeading: string;
    headingColor: string;
    searchBoxSubtext: string;
    searchBoxHelp: string;
    landingPageUrl: string;
    isLandingPageUrlExternal: boolean;
  };
  public isFocused = false;

  constructor(private searchService: SearchService, public layoutService: LayoutService) {}

  ngOnInit() {
    this.searchService.isSearchFocused.subscribe((isActive) => {
      this.isFocused = isActive;
    });
  }
}
