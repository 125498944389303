import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { IUser } from '../../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import { IFreightTypeItems } from '../../../../contracts/commerce/ifreight-type-items';
import { IItemAvailabilityCheckout } from '../../../../contracts/commerce/icommerce-item-availability';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-freight-items-table',
  styleUrls: [
    './freight-items-table.component.scss',
    '../../shopping-cart/shopping-cart-item/shopping-cart-item.component.scss',
  ],
  templateUrl: './freight-items-table.component.html',
})
export class FreightItemsTableComponent implements OnInit {
  @Input() items: IFreightTypeItems[] = [];
  @Input() itemAvailability: IItemAvailabilityCheckout = {};
  @Input() user: IUser | undefined;
  @Input() isPickup = false;
  public ocids: OcidItems = {};
  platformBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
  }
}
