import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  ClearskyCart,
  ClearskySubscriptionCheckout,
  ClearskySubscriptionPlan,
} from '../../contracts/clearsky/subscription/clearsky-subscription';
import { CustomerMachineCount } from '../../contracts/clearsky/customer/customer-machine-count';
import { IAddItemDto } from '../../contracts/commerce/dto/iadd-item-dto';
import { IOrder } from '../../contracts/commerce/iorder';
import { IBillingHistory } from 'app/contracts/user/subscription/subscription.interface';

@Injectable({
  providedIn: 'root',
})
export class ClearskySubscriptionService {
  constructor(private http: HttpClient) {}

  getMachineCount(): Observable<CustomerMachineCount> {
    return this.http.get<CustomerMachineCount>(
      `${environment.apiUrl}/currentUser/getMachineCount`
    );
  }

  /**
   * Get available plans in clearsky.
   */
  getPlans(): Observable<ClearskySubscriptionPlan[]> {
    return this.http.get<ClearskySubscriptionPlan[]>(
      `${environment.apiUrl}/products/subscriptions`
    );
  }

  /**
   * @returns {Observable<IBillingHistory>}
   */
  getBillingHistory(): Observable<IBillingHistory[]> {
    return this.http.get<IBillingHistory[]>(`${environment.ATG_BASE_URL}/subscriptions/subscriptionOrderDetails`)
  }

  /**
   * Add items to cart for subscription.
   * @param items
   */
  addToCart(items: IAddItemDto[]): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}/subscriptions/add`, {
      items,
    });
  }

  /**
   * Get cart instance with tax calculated.
   */
  getCart(): Observable<ClearskyCart> {
    return this.http.post<ClearskyCart>(
      `${environment.apiUrl}/subscriptions/review`,
      {}
    );
  }

  /**
   * Remove an item from the cart.
   * @param item
   */
  removeFromCart(item: string): Observable<ClearskyCart> {
    return this.http.delete<ClearskyCart>(
      `${environment.apiUrl}/subscriptions/${item}`
    );
  }

  /**
   * Checkout with cart.
   * @param data
   */
  checkout(data: ClearskySubscriptionCheckout): Observable<IOrder> {
    return this.http.post<IOrder>(`${environment.apiUrl}/cart/checkout`, data);
  }
}
