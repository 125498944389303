import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

export interface IMarketingBox {
  description: string;
  isButtonUrlExternal: boolean;
  backgroundStyle: string;
  buttonStyle: string;
  width: string;
  buttonUrl: string;
  buttonText: string;
  heading: string;
  backgroundImage: string;
}

@Component({
  selector: 'app-marketing-box-banner',
  styleUrls: ['./marketing-box-banner.component.scss'],
  templateUrl: './marketing-box-banner.component.html',
})
export class MarketingBoxBannerComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    marketingBoxes: object;
  };
  marketingBoxes: IMarketingBox[] = [];

  constructor() {}

  ngOnInit() {
    // data.marketingBoxes returns an object that has keys of "0", "1", "2", etc.
    // Instead, we are going to create an array of marketing boxes by executing the following.
    const boxProperties: string[] = Object.keys(this.data.marketingBoxes);
    this.marketingBoxes = boxProperties.map((property: string) => {
      return this.data.marketingBoxes[property];
    });
  }

  /**
   * Receives the desired width and returns its corresponding class.
   * @param {string} width
   * @returns {string}
   */
  getWidth(width: string) {
    switch (width) {
      case '100': {
        return '100';
      }
      case '50': {
        return '50';
      }
      case '33': {
        return '30';
      }
      case '25': {
        return '23';
      }
      default: {
        return '23';
      }
    }
  }

  /**
   * Gets the image style depending on whether the box has a header, description,
   * or button text and the background style.
   * @param {IMarketingBox} box
   * @returns {string}
   */
  getImageStyle(box: IMarketingBox) {
    if (box.backgroundStyle === 'shaded') {
      return `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url('${box['backgroundImage']}')`;
    }
    return `url('${box['backgroundImage']}')`;
  }

  /**
   * Gets the box style class name.
   * @param {string} boxStyle
   */
  getBoxStyle(boxStyle: string) {
    switch (boxStyle) {
      case 'jlg-orange': {
        return 'marketing-box-style-site';
      }
      case 'jerrdan-red': {
        return 'marketing-box-style-site';
      }
      case 'gray': {
        return 'marketing-box-style-gray';
      }
      case 'white': {
        return 'marketing-box-style-white';
      }
      case 'black': {
        return 'marketing-box-style-black';
      }
      case 'shaded': {
        return 'marketing-box-style-shaded';
      }
      case 'transparent': {
        return 'marketing-box-style-transparent';
      }
      default: {
        return 'marketing-box-style-site';
      }
    }
  }
}
