export interface ISearchHistoryItem {
  term: string;
  url: string;
}

export const SearchTypes = {
  ARTICLES: 'KnowledgeSearchForm',
  SOFTWARE: 'ControlSoftwareSearchForm',
  MANUALS: 'JDCManualSearchForm',
  TECHPUB: 'JLGManualSearchForm',
  HEADER: 'SearchBoxBannerHeader'
};
