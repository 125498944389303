// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.progress-bar-text {
  position: absolute;
  top: 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  background-color: var(--orange);
}
.progress-bar-with-text {
  height: 16px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2dyZXNzLWJhci13aXRoLXRleHQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0Esa0JBQUE7RUFDQSxXQUFBO0VBQ0EsZ0JBQUE7QUFDRjtBQUNFO0VBQ0Usa0JBQUE7RUFDQSxNQUFBO0VBQ0EsZUFBQTtFQUNBLGdCQUFBO0VBQ0Esa0JBQUE7RUFDQSwrQkFBQTtBQUNKO0FBRUU7RUFDRSx1QkFBQTtBQUFKIiwiZmlsZSI6InByb2dyZXNzLWJhci13aXRoLXRleHQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucHJvZ3Jlc3MtYmFyIHtcbiAgZGlzcGxheTogZmxleDtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB3aWR0aDogMTAwJTtcbiAgb3ZlcmZsb3c6IGhpZGRlbjtcblxuICAmLXRleHQge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICB0b3A6IDA7XG4gICAgZm9udC1zaXplOiAxNHB4O1xuICAgIGZvbnQtd2VpZ2h0OiA3MDA7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLW9yYW5nZSk7XG4gIH1cblxuICAmLXdpdGgtdGV4dCB7XG4gICAgaGVpZ2h0OiAxNnB4ICFpbXBvcnRhbnQ7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/progress-bar/progress-bar-with-text/progress-bar-with-text.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,+BAAA;AACJ;AAEE;EACE,uBAAA;AAAJ;AACA,o3BAAo3B","sourcesContent":[".progress-bar {\n  display: flex;\n  position: relative;\n  width: 100%;\n  overflow: hidden;\n\n  &-text {\n    position: absolute;\n    top: 0;\n    font-size: 14px;\n    font-weight: 700;\n    text-align: center;\n    background-color: var(--orange);\n  }\n\n  &-with-text {\n    height: 16px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
