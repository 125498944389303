import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IAddress } from '../../../contracts/user/iaddress';
import { IUser } from '../../../contracts/user/iuser';
import {
  IFileList,
  IPlacardDto,
} from '../../../contracts/placard/iplacard-dto';
import { NotificationService } from '../../../service/notification/notification.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { ChangeAddressComponent } from '../checkout/change-address/change-address.component';
import { IEquipmentModel, ReplacementOptions, RequestTypeMode } from './models/request-mode.enum';
import { PlateIdReplacementService } from './plate-id-replacement.service';
import { IPlateIDReplacementDto } from 'app/contracts/plate-id-replacement/iplate-id-replacement-dto';
import { Router } from '@angular/router';

interface IPlateIdReplacementHelper {
  customerForm: object;
  files: IFileList[] | null;
}

interface EquipmentInfo {
  serial: string;
  model: string;
  type: string;
  desc: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-placard',
  templateUrl: './plate-id-replacement.component.html',
  styleUrls: [
    '../web2case/web2case.component.scss',
  ],
})
export class PlateIdReplacementComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper) stepper!: MatStepper;
  ocids = {};
  user!: IUser;
  requestTypeForm: UntypedFormGroup = new UntypedFormGroup({});
  replacementForm: UntypedFormGroup = new UntypedFormGroup({});
  replacementConsentForm: UntypedFormGroup = new UntypedFormGroup({});
  contactInfoForm: UntypedFormGroup = new UntypedFormGroup({});
  requestTypeFormComplete = false;
  confirmContactComplete = false;
  requestReasonComplete = false;
  caseSubmitted = false;
  platformBrowser = false;
  requestType!: string;
  manufactureDateFormatted!: string;
  equipment!: EquipmentInfo;
  shippingAddress!: IAddress;
  billingAddress!: IAddress;
  invalidNumber = false;
  requestTypeValues = RequestTypeMode;
  poNumRequired: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localizationService: LocalizationService,
    private notificationService: NotificationService,
    private plateIdReplacementService: PlateIdReplacementService
    ,
    private winRef: WindowRefService,
    private dialog: MatDialog,
    private userService: CurrentUserService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        if (user.billingAddress) {
          this.billingAddress = user.billingAddress;
        }
        if (!user.inProxy) {
          // If the user has a shipping address, use it as the shipping address.
          if (user.shippingAddress) {
            this.shippingAddress = user.shippingAddress;
          }
        } else if (user.orgShippingAddress) {
          // If the user is in proxy and the org shipping address exists, use the orgShippingAddress.
          this.shippingAddress = user.orgShippingAddress;
        }
        this.contactInfoForm.addControl(
          'mailingContact',
          this.formBuilder.control(
            this.shippingAddress?.contactName ?? '',
            Validators.required
          )
        );
        this.contactInfoForm.addControl(
          'mailingContactPhone',
          this.formBuilder.control(
            this.shippingAddress?.phoneNumber ?? '',
            Validators.pattern(/^[\d()+-.ext ]*$/)
          )
        );
      }
    });

    this.localizationService
      .getOCIDs([
        'id-request.id-plate-type',
        'id-request.attachment-label',
        'id-request.fail-message',
        'id-request.acceptance-text',
        'placard-request.reason-title',
        'web2case.confirm-contact-info',
        'web2case.other-please-explain',
        'placard-request.change-address-label',
        'placard-request.po-num-info',
        'placard-request.reason1',
        'placard-request.reason2',
        'id-request.plate-attachment',
        'web2case.browse',
        'web2case.no-file-selected',
        'web2case.add-additional-attachment',
        'web2case.thank-you-message',
        'id-plate.serial-number-error',
        'id-request.confirmation-text',
        'id-request.new',
        'id-request.attachment-serial-number',
        'id-request.confirmation-title',
        'id-request.contact-help-text',
        'id-request.information-request',
        'placard-request.step2-link-label',
        'id-request.confirmation-title',
        'id-request.confirmation-text',
        'id-plate.create-new-request',
        'profile.account-information',
        'placard-request.step2-answer',
        'id-request.replacement-id-request-label',
        'id-request.how-to-find-sn',
        'id-plate.attachment-part-hint',
        'id-plate.serial-number-text',
        'web2case.file-error-message-large',
        'web2case.file-message-fail',
        'web2case.file-format-message'
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Event emitted by the request type form when the request type changes.
   */
  requestTypeChanged(event) {
    this.requestType = event;
    this.replacementConsentForm.addControl(
      'PONum',
      this.formBuilder.control('')
    );
    this.replacementConsentForm.addControl(
      'consent',
      this.formBuilder.control('', Validators.required)
    );
    this.resetForm(this.contactInfoForm);
    this.resetForm(this.replacementConsentForm);
    this.resetForm(this.replacementForm);
  }

  /**
   * Event emitted by the request type form when the form has been submitted.
   */
  requestTypeFormSubmitted() {
    this.requestTypeFormComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  replacementReasonSubmit() {
    this.requestReasonComplete = true;
    this.poNumRequired = this.replacementForm.value.reasonforReplacement == ReplacementOptions[1].value || this.replacementForm.value.reasonforReplacement == ReplacementOptions[0].value;
    if (this.poNumRequired) {
      this.replacementConsentForm.get('poNum')?.setValidators([Validators.required])
    }
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  /**
   * Open modal to change shipping address.
   */
  onChangeAddress(): void {
    const dialogRef = this.dialog.open(ChangeAddressComponent, {
      width: '850px',
    });

    dialogRef.afterClosed().subscribe((result: IAddress) => {
      if (result) {
        this.shippingAddress = {
          customerName: result.customerName,
          address1: result.address1,
          address2: result.address2,
          address3: result.address3,
          postalCode: result.postalCode,
          state: result.state,
          city: result.city,
          country: result.country,
          contactName: result.contactName,
          phoneNumber: result.phoneNumber,
        };
      }
    });
  }

  /**
   * Form control name emitted by the request info form when the form has been submitted.
   */
  submitForm() {
    let dto = this.plateIdReplacementDto(this.requestTypeForm.value);
    let placardDto: IPlateIDReplacementDto = {
      customerForm: {
        legalSignOff: true,
        email: this.user.email,
        recordType: this.requestType,
        bodySerialNumber: this.equipment?.serial,
        equipmentModel: this.equipment?.desc,
        accountNumber: this.user.customerNumber,
        accountCity: this.billingAddress.city,
        accountState: this.billingAddress.state,
        billingContactName: this.billingAddress?.contactName ?? '',
        billingAddress1: this.billingAddress.address1,
        billingCity: this.billingAddress.city,
        billingState: this.billingAddress.state,
        billingPostalCode: this.billingAddress.postalCode,
        billingCountry: this.billingAddress.country,
        customerName: this.shippingAddress?.customerName ?? '',
        mailingCity: this.shippingAddress?.city,
        mailingCountry: this.shippingAddress?.country,
        mailingState: this.shippingAddress?.state,
        mailingStreet: this.shippingAddress?.address1,
        mailingZipCode: this.shippingAddress?.postalCode,
        mailingContact: this.user.shippingAddress.contactName ?? '',
        mailingContactPhone: this.user.shippingAddress.customerNumber ?? '',
        ...dto.customerForm,
      },
    };
    dto = this.plateIdReplacementDto((<UntypedFormGroup>this.replacementForm).value);
    placardDto = {
      customerForm: {
        ...placardDto.customerForm,
        ...dto.customerForm,
        poNumber: this.replacementConsentForm.value.PONum,
      },
    };

    if (dto.files) {
      placardDto['filelist'] = dto.files;
    }

    this.plateIdReplacementService.postCase(placardDto).subscribe({
      next: () => {
        this.notificationService.reset();
        this.caseSubmitted = true;

        setTimeout(() => {
          // // reset the form and remove error state on submit success
          // this.equipmentInfoForm.reset();
          // this.resetForm(this.equipmentInfoForm);
          // Send form success event to GA
          if (this.platformBrowser) {
            const dataLayer = (this.winRef.nativeWindow.dataLayer =
              this.winRef.nativeWindow.dataLayer || []);
            dataLayer.push({
              event: 'formEvent',
              formAction: 'Success',
              formName: 'Replacement Plate Id',
            });
          }
        }, 1);
      },
      error: (error) => {
        this.notificationService.addError(error.error.title);
        this.caseSubmitted = false;
      },
    });
  }

  createNewRequest() {
    this.router.navigate([this.router.url], { onSameUrlNavigation: 'reload' });
  }
  /**
   * resets the form and clears validation errors
   * @param {FormGroup} form
   */
  resetForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key)?.setErrors(null);
    });
  }

  /**
   * Assists in creating the Placard dto object. Takes the form value as a parameter,
   * loops over the properties and assigns them to the dto. This makes sure we get all
   * values from the form, including the nested form objects.
   * @param {object} objectValue
   * @returns {IPlateIdReplacementHelper}
   */
  plateIdReplacementDto(objectValue: object) {
    // Create the basic dto.
    const dto: IPlateIdReplacementHelper = {
      customerForm: {},
      files: null,
    };
    Object.keys(objectValue).forEach((key: string) => {
      const keyValue = objectValue[key];
      // Since the upload form contains values for the filelist property on the dto,
      // we need to manually pull it out.
      if (key === 'uploadInput') {
        dto.files = keyValue.length > 0 ? keyValue : null;
      } else {
        // If the current key value is of type object, we will call the placardDto
        // function again, passing in the object to pull out the properties.
        // exclude manufactureDate which returns a Date object which should be parsed as string instead
        if (typeof keyValue === 'object') {
          const innerDto: IPlateIdReplacementHelper = this.plateIdReplacementDto(keyValue);
          dto.customerForm = {
            ...dto.customerForm,
            ...innerDto.customerForm,
          };
          if (innerDto.files) {
            dto.files = innerDto.files;
          }
        } else {
          dto.customerForm = {
            ...dto.customerForm,
            [key]: keyValue ? keyValue.toString().replace(/"/g, '') : '',
          };
        }
      }
    });
    return dto;
  }

  equipmentChanged(event: IEquipmentModel) {
    this.equipment = event;
  }

  ngOnDestroy() {
    // Send form not finished event to GA if user leaves without submitting the form
    if (this.platformBrowser && !this.caseSubmitted) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: 'formEvent',
        formAction: 'Unfinished',
        formName: 'Replacement Plate Id',
      });
    }
  }
}
