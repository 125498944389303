import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { IGiftList } from '../../../../contracts/commerce/igift-list';
import { IGiftListItem } from '../../../../contracts/commerce/igift-list-item';
import { IAddGiftList } from '../../../../contracts/commerce/dto/iadd-gift-list';
import {
  IGiftlistParams,
  IGiftListRemove,
} from '../../../../contracts/commerce/params/igiftlist-params';
import { IUpdateGiftListDto } from '../../../../contracts/commerce/dto/iupdate-gift-list-dto';
import { IShareListDto } from '../../../../contracts/commerce/dto/ishare-list-dto';
import { IGiftListDto } from '../../../../contracts/commerce/dto/igift-list-dto';
import { IAddGiftListItemDto } from '../../../../contracts/commerce/dto/iadd-gift-list-item-dto';
import { IAddGiftListItemsDto } from '../../../../contracts/commerce/dto/iadd-gift-list-items-dto';
import { map } from 'rxjs/operators';
import { IGiftListResponse } from '../../../../contracts/commerce/igift-list-response';

@Injectable()
export class ShoppingListsService {
  constructor(private http: HttpClient) {}

  /**
   * Get all resources for current user.
   *
   * @returns {Observable<IGiftListResponse>}
   */
  getLists(params?: IGiftListDto) {
    const queryString = require('query-string');
    let query = queryString.stringify(params ? params : '');
    // If parameters were passed, append the query as necessary.
    if (params) {
      query = '?' + query;
    }
    return this.http.get(`${environment.apiUrl}/giftlists/search${query}`).pipe(
      map((lists: IGiftListResponse) => {
        lists.items = lists.items.map((list: IGiftList) => {
          // Only modify the date and event time if it hasn't been modified yet. We can determine that if the eventTime
          // property has not been added to each list item. This issue arrose when moving to universal and we pass the
          // server response for requests to the front end so we don't have to re-request data.
          if (!list.eventTime) {
            const eventDate = new Date(list.eventDate);
            list.eventTime = eventDate;
            const year: number = eventDate.getFullYear();
            const month: number = eventDate.getMonth() + 1;
            const day: number = eventDate.getDate();
            list.eventDate =
              year.toString() +
              (month < 10 ? '0' + month.toString() : month.toString()) +
              (day < 10 ? '0' + day.toString() : day.toString());
          }
          return list;
        });
        return lists;
      })
    );
  }

  /**
   * Get a single resource.
   *
   * @param {params} IGiftlistParams
   * @returns {Observable<IGiftList>}
   */
  getList(params: IGiftlistParams) {
    return this.http.get<IGiftList>(
      `${environment.apiUrl}/giftlists/${params.giftlistId}`
    );
  }

  /**
   * Store a new resource.
   *
   * @param {IAddGiftList} list
   * @returns {Observable<IGiftList>}
   */
  addList(list: IAddGiftList) {
    return this.http.post<IGiftList>(`${environment.apiUrl}/giftlists`, {
      ...{},
      ...list,
    });
  }

  /**
   * Add product to resource.
   *
   * @param {IGiftlistParams} params
   * @param {IAddGiftListItemDto} addItemDto
   * @returns {Observable<IGiftListItem[]>}
   */
  addItem(params: IGiftlistParams, addItemDto: IAddGiftListItemDto) {
    return this.http.post<IGiftListItem[]>(
      `${environment.apiUrl}/giftlists/${params.giftlistId}/giftlistItems/addItem`,
      addItemDto
    );
  }

  /**
   * Adds multiple items to the list.
   *
   * @param {IGiftlistParams} params
   * @param {IAddGiftListItemsDto} addItemsDto
   */
  addItems(params: IGiftlistParams, addItemsDto: IAddGiftListItemsDto) {
    return this.http.post<IGiftListItem[]>(
      `${environment.apiUrl}/giftlists/${params.giftlistId}/giftlistItems/addItems`,
      addItemsDto
    );
  }

  /**
   * Update resource.
   *
   * @param {IGiftlistParams} params
   * @param {IUpdateGiftListDto} list
   * @returns {Observable<IGiftList>}
   */
  updateList(params: IGiftlistParams, list: IUpdateGiftListDto) {
    return this.http.post<IGiftList>(
      `${environment.apiUrl}/giftlists/${params.giftlistId}/update`,
      { ...{}, ...list }
    );
  }

  /**
   * Delete resource.
   *
   * @param {IGiftlistParams} params
   * @returns {Observable<Object>}
   */
  deleteList(params: IGiftlistParams) {
    return this.http.delete(
      `${environment.apiUrl}/giftlists/${params.giftlistId}`
    );
  }

  /**
   * Delete multiple items from list.
   *
   * @param {giftlistId} string
   * @param {giftlistItems} <IGiftListRemove[]>
   * @returns {Observable<iGiftListItem[]>}
   */
  deleteItems(giftlistId: string, giftlistItems: IGiftListRemove[]) {
    const options = {
      body: {
        items: giftlistItems,
      },
    };
    return this.http.delete(
      `${environment.apiUrl}/giftlists/${giftlistId}/giftlistItems/deleteGiftlistItems`,
      options
    );
  }

  /**
   * Delete single item from list.
   *
   * @param {iGiftListParams} deleteListItemParams
   * @returns {Observable<iGiftListItem[]>}
   */
  deleteItem(deleteListItemParams: IGiftlistParams) {
    return this.http.delete(
      `${environment.apiUrl}/giftlists/${deleteListItemParams.giftlistId}/giftlistItems/${deleteListItemParams.giftlistItemId}`
    );
  }

  /**
   * Share list via email(s).
   *
   * @param {iGiftListParams} params
   * @param {IShareListDto} emails
   * @returns {Observable<null>}
   */
  shareList(params: IGiftlistParams, emails: IShareListDto) {
    return this.http.post(
      `${environment.apiUrl}/giftlists/${params.giftlistId}/email`,
      emails
    );
  }

  /**
   * Get all logins that have public shopping lists under
   * @returns {Observable<{ filterableLoginIds: string[] }>}
   */
  getPublicListUsers() {
    return this.http.get(`${environment.apiUrl}/giftlists/customerPublic`);
  }
}
