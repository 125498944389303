import {Pipe, PipeTransform} from '@angular/core';

export function localizeOcidData(ocid: string, data: any[] = []): string {
  let injectedOcid = ocid;

  // If ocid is not available, just return it back
  if (!ocid) {
    return ocid;
  }

  const args = ocid.match(/\|\|([^\|]*)\|\|/g);
  const argLength = args ? args.length : 0;

  if (argLength !== data.length) {
    // Error from invalid parameter length
    return ocid;
  }

  for (let i = 0; i < argLength; i++) {
    injectedOcid = injectedOcid.replace(args[i], data[i]);
  }

  return injectedOcid;
}

@Pipe({name: 'localizedData'})
export class LocalizedDataPipe implements PipeTransform {
  /**
   *
   * @param {string} ocid
   * @param {array} data
   */
  transform(ocid: string, data: any[]) {
    return localizeOcidData(ocid, data);
  }
}
