import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as Highcharts from 'highcharts';
import * as cloneDeep from 'lodash.clonedeep';
import { combineLatest, Subscription } from 'rxjs';
import { ClearskyService } from '../../../clearsky.service';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CsAggRangeChartData } from '../../../../contracts/clearsky/agg-data';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { MachineChargeStatus } from '../../../../contracts/clearsky/machine/machine.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-equipment-charge-complete',
  templateUrl: './equipment-charge-complete.component.html',
})
export class EquipmentChargeCompleteComponent implements AfterViewInit {
  @ViewChild('chartEl') chartEl: ElementRef;
  ocids: OcidItems = {};
  private chgData: CsAggRangeChartData[];
  private noChgData: CsAggRangeChartData[];
  private chgFilter: unknown[];
  private plot;
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngAfterViewInit(): void {
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.localization.getOCIDs([
        'clearsky.number-of-label',
        'clearsky.charging-label',
        'clearsky.not-label',
        'clearsky.graphic-default',
        'clearsky.last-charge-complete-label',
        'clearsky.current-charge-complete-label',
      ]),
      this.clearskyService.getCurrentFilterValues(CSFilter.chgStatus.key),
    ]).subscribe(([page, ocids, filter]) => {
      this.ocids = ocids;
      this.chgData =
        page && page.aggregations && page.aggregations.socrChart
          ? page.aggregations.socrChart.charging
          : [];
      this.noChgData =
        page && page.aggregations && page.aggregations.socrChart
          ? page.aggregations.socrChart.notCharging
          : [];
      this.chgFilter = filter;
      this.resetChartData();

      if (this.plot) {
        this.plotChart();
      } else {
        this.createChart();
        this.plotChart();
      }
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  protected createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    const chartData = mergeDeep(cloneDeep(BaseChartConfig), {
      chart: {
        type: 'column',
      },
      xAxis: {
        categories: this.getChartKeys(),
        title: {
          text: null,
        },
        labels: {
          format: '{text}%',
        },
      },
      yAxis: {
        title: {
          text: this.ocids['clearsky.number-of-label'],
        },
        minTickInterval: 1,
      },
      legend: {
        verticalAlign: 'bottom',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          point: {
            events: {
              click: this.onChartClick.bind(this),
            },
          },
          events: {
            legendItemClick: this.onLegendClick.bind(this),
          },
          cursor: 'pointer',
        },
      },
    });

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      chartData as unknown
    );
  }

  /**
   * Plot chart with data points.
   * @private
   */
  private plotChart(): void {
    // Add series for charging off
    this.plot.addSeries({
      name: this.ocids['clearsky.last-charge-complete-label'],
      data: this.noChgData.map((d) => ({
        y: d.y,
        custom: {
          range: d.x,
          status: false,
        },
      })),
      color: WidgetColors.blue,
      custom: {
        chargeStatus: false,
      },
      visible: !this.chgFilter.length || this.chgFilter.includes(false),
    });

    // Add series for charging on
    this.plot.addSeries({
      name: this.ocids['clearsky.current-charge-complete-label'],
      data: this.chgData.map((d) => ({
        y: d.y,
        custom: {
          range: d.x,
          status: true,
        },
      })),
      color: WidgetColors.orange,
      custom: {
        chargeStatus: true,
      },
      visible: !this.chgFilter.length || this.chgFilter.includes(true),
    });
  }

  /**
   * Remove series from existing chart.
   * @private
   */
  private resetChartData(): void {
    if (this.plot && this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Listener for on click of chart.
   * @param e
   * @private
   */
  private onChartClick(e: {
    point: { custom: { range: number[]; status: boolean } };
  }): void {
    this.clearskyService.updateFilters([
      {
        key: CSFilter.socrRng.key,
        values: e.point.custom.range,
      },
      {
        key: CSFilter.chgStatus.key,
        values: [e.point.custom.status],
      },
    ]);
  }

  /**
   * Listener for on click of legend.
   * @param e
   * @private
   */
  private onLegendClick(e: {
    target: {
      visible: boolean;
      userOptions: { custom: { chargeStatus: boolean } };
    };
  }): void {
    this.clearskyService.updateFilter(
      CSFilter.chgStatus.key,
      e.target.visible ? [!e.target.userOptions.custom.chargeStatus] : []
    );
  }

  /**
   * Get chart keys.
   * @private
   */
  private getChartKeys(): string[] {
    return this.chgData.map((d) => this.convertRangeToKey(d.x));
  }

  /**
   * Convert range to key string.
   * @param range
   * @private
   */
  private convertRangeToKey(range: number[]): string {
    return range.join('-');
  }
}
