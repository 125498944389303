import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ClearskyCart,
  ClearskySubscriptionCheckout,
  ClearskySubscriptionPlan,
} from '../../../contracts/clearsky/subscription/clearsky-subscription';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { IUser } from 'app/contracts/user/iuser';
import { CurrentUserService } from 'app/service/user/current-user.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-confirm-purchase',
  templateUrl: './subscription-confirm-purchase.component.html',
  styleUrls: ['./subscription-confirm-purchase.component.scss'],
})
export class SubscriptionConfirmPurchaseComponent implements OnInit {
  @Input() selectedPlan!: ClearskySubscriptionPlan;
  @Input() selectedAddons: string[] = [];
  @Input() cart: ClearskyCart;
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirm: EventEmitter<ClearskySubscriptionCheckout> =
    new EventEmitter<ClearskySubscriptionCheckout>();
  @Input() numOfAssets = 0;
  @Input() showUpgrade;
  form = this.fb.group({
    agree: this.fb.control(false, Validators.requiredTrue),
    poNum: this.fb.control(null),
  });
  annualTotal = 0;
  year = dayjs().year();
  ocids: OcidItems = {};
  user: IUser;

  constructor(
    private fb: UntypedFormBuilder,
    private localization: LocalizationService,
    private currentUserService: CurrentUserService
  ) { }

  ngOnInit(): void {
    this.currentUserService.userSubject.subscribe((user) => {
      this.user = user;
    });
    this.updateTotals();
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'csv.confirm-purchase-title',
        'csv.check-box-confirmation',
        'clearsky.total-price-label',
        'clearsky.valid-date-label',
        'clearsky.annually-label',
        'clearsky.prorated-days-remaining',
        'clearsky.prorated-upgrade'
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Checkout event listener.
   */
  onCheckout(): void {
    const data: ClearskySubscriptionCheckout = {};
    const poNumber = this.form.get('poNum')?.value;
    if (poNumber) {
      data['poNumber'] = poNumber;
    }

    return this.confirm.emit(data);
  }

  /**
   * Update pricing information.
   * @private
   */
  private updateTotals(): void {
    const planTotal = this.selectedPlan.listPrice * this.numOfAssets;
    let addonTotal = 0;

    // Set addons price
    if (this.selectedPlan && this.selectedAddons.length) {
      addonTotal = this.selectedPlan.addOns.reduce((prev, addon) => {
        prev += addon.listPrice * this.numOfAssets;
        return prev;
      }, 0);
    }

    // Set total
    this.annualTotal = planTotal + addonTotal;
  }
}
