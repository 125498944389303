import { Component, Input } from '@angular/core';
import { CartridgeInterface } from '../../../cartridge/cartridge.class';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
})
export class RatingsComponent implements CartridgeInterface {
  @Input() data!: {
    productId: string;
  };

  constructor() {}
}
