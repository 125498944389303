import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-navigation-item',
  templateUrl: './category-navigation-item.component.html',
})
export class CategoryNavigationItemComponent {
  @Input('category') item: {
    categoryMedia: {
      imageAltText: string;
      thumbnailImageUrl: string;
      fullImageUrl: string;
    };
    subCategories: {
      displayName: string;
      navigationState: string;
    }[];
    topLevelCategory: {
      displayName: string;
      navigationState: string;
    };
  };
  @Input() viewAllLinkText!: string;
  @Input() displayImage!: boolean;
  @Input() numSubCategories = 3;

  constructor(private router: Router) {}

  /**
   * Event emitted when navigating "view all" link.
   *
   * @returns {Promise<boolean>}
   */
  onNavigateCategory() {
    this.router
      .navigateByUrl(this.item.topLevelCategory.navigationState)
      .catch((error) => error);
  }
}
