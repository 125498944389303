// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-header-chip {
  width: 35% !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1YnNjcmlwdGlvbi1wYWNrYWdlLXNlbGVjdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHFCQUFBO0FBQ0YiLCJmaWxlIjoic3Vic2NyaXB0aW9uLXBhY2thZ2Utc2VsZWN0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnN1YnNjcmlwdGlvbi1oZWFkZXItY2hpcCB7XHJcbiAgd2lkdGg6IDM1JSAhaW1wb3J0YW50O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/subscription/subscription-package-selection/subscription-package-selection.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACA,gZAAgZ","sourcesContent":[".subscription-header-chip {\r\n  width: 35% !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
