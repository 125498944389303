// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBkZi1kb3dubG9hZC1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsZ0JBQWdCO0FBQWhCO0VBQ0ksZ0JBQUE7RUFDQSxxQkFBQTtFQUNBLHNCQUFBO0VBRUEsZ0RBQUE7RUFDQSxZQUFBLEVBQUEsMENBQUE7RUFDQSxVQUFBO0FBRUo7O0FBQ0E7RUFDSTtJQUNJLGFBQUE7RUFFTjtBQUNGIiwiZmlsZSI6InBkZi1kb3dubG9hZC1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubG9hZGluZzphZnRlciB7XHJcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xyXG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xyXG4gICAgdmVydGljYWwtYWxpZ246IGJvdHRvbTtcclxuICAgIC13ZWJraXQtYW5pbWF0aW9uOiBlbGxpcHNpcyBzdGVwcyg0LCBlbmQpIDkwMG1zIGluZmluaXRlO1xyXG4gICAgYW5pbWF0aW9uOiBlbGxpcHNpcyBzdGVwcyg0LCBlbmQpIDkwMG1zIGluZmluaXRlO1xyXG4gICAgY29udGVudDogXCJcXDIwMjZcIjsgLyogYXNjaWkgY29kZSBmb3IgdGhlIGVsbGlwc2lzIGNoYXJhY3RlciAqL1xyXG4gICAgd2lkdGg6IDBweDtcclxufVxyXG5cclxuQGtleWZyYW1lcyBlbGxpcHNpcyB7XHJcbiAgICB0byB7XHJcbiAgICAgICAgd2lkdGg6IDEuMjVlbTtcclxuICAgIH1cclxufVxyXG5cclxuQC13ZWJraXQta2V5ZnJhbWVzIGVsbGlwc2lzIHtcclxuICAgIHRvIHtcclxuICAgICAgICB3aWR0aDogMS4yNWVtO1xyXG4gICAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/shared/pdf-download-dialog/pdf-download-dialog.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,gBAAA;EACA,qBAAA;EACA,sBAAA;EAEA,gDAAA;EACA,YAAA,EAAA,0CAAA;EACA,UAAA;AAEJ;;AACA;EACI;IACI,aAAA;EAEN;AACF;AAAA,gkCAAgkC","sourcesContent":[".loading:after {\r\n    overflow: hidden;\r\n    display: inline-block;\r\n    vertical-align: bottom;\r\n    -webkit-animation: ellipsis steps(4, end) 900ms infinite;\r\n    animation: ellipsis steps(4, end) 900ms infinite;\r\n    content: \"\\2026\"; /* ascii code for the ellipsis character */\r\n    width: 0px;\r\n}\r\n\r\n@keyframes ellipsis {\r\n    to {\r\n        width: 1.25em;\r\n    }\r\n}\r\n\r\n@-webkit-keyframes ellipsis {\r\n    to {\r\n        width: 1.25em;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
