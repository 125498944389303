import { Component } from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

@Component({
  selector: 'app-fleet-age',
  templateUrl: './fleet-age.component.html',
  styleUrls: ['./fleet-age.component.scss'],
})
export class FleetAgeComponent {
  widgetName = Widgets.FLEET_AGE;
  displayName = WidgetsDisplay.fleetAge;
  ocids: OcidItems = {};
  constructor(private localization: LocalizationService) {
    this.localization.getOCIDs([this.displayName]).subscribe((ocids) => this.ocids = ocids)
  }
}
