import { Component, HostBinding, Input } from '@angular/core';

enum Direction {
  top = 'top',
  bottom = 'bottom',
}

@Component({
  selector: 'app-card-label-marker',
  templateUrl: './card-label-marker.component.html',
  styleUrls: ['./card-label-marker.component.scss'],
})
export class CardLabelMarkerComponent {
  @Input() direction!: Direction;
  @Input() color = 'tw-text-white';
  @Input() bgColor = 'tw-bg-primary';

  @HostBinding('class.h5') fontStyle = true;
  @HostBinding('class.fs-sm') fontSm = true;
  @HostBinding('class.card-label-marker-top') get dTop() {
    return this.direction === Direction.top;
  }
  @HostBinding('class.card-label-marker-bottom') get dBottom() {
    return this.direction === Direction.bottom;
  }
}
