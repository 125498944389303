import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoChartMsgComponent } from './no-chart-msg.component';

@NgModule({
  declarations: [NoChartMsgComponent],
  exports: [NoChartMsgComponent],
  imports: [CommonModule],
})
export class NoChartMsgModule {}
