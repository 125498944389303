export const CLEARSKY_MENU_MAPPING: {
  [page: string]: { icon: string; isMaterialIcon?: boolean; tooltip?: string };
} = {
  dashboard: {
    icon: 'icon-th-large',
  },
  map: {
    icon: 'icon-globe',
  },
  list: {
    icon: 'icon-list',
  },
  'site networks': {
    icon: 'hub',
    isMaterialIcon: true,
    tooltip: 'clearsky.local-machine-connection-label',
  },
  'access control': {
    icon: 'lock',
    isMaterialIcon: true,
  },
  subscription: {
    icon: 'icon-key',
  },
  'legacy clearsky': {
    icon: 'icon-circle',
  },
};
