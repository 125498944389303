import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
})
export class VideoComponent
  implements OnInit, AfterViewInit, CartridgeInterface
{
  @Input() data!: {
    videoUrl: string;
    title: string;
    rightContent: {
      content: string;
    };
    leftContent: {
      content: string;
    };
  };
  videoUrlSanitized!: SafeResourceUrl;
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private sanitizer: DomSanitizer,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);

    // Set the sanitized video url based on whether it's a DAM url or a Youtube video ID
    if (this.data.videoUrl.startsWith('http')) {
      this.videoUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.data.videoUrl
      );
    } else {
      this.videoUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/' + this.data.videoUrl
      );
    }
  }

  ngAfterViewInit() {
    // send GTM event to track video plays *after* the video has loaded
    if (this.platformBrowser) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: 'hasVideo',
      });
    }
  }
}
