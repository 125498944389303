import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IUser } from '../../../../contracts/user/iuser';
import { OneRequiredValidator } from '../../../../shared/one-required.validator';
import { IOCIDAndValue } from '../web2case.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ContentService } from '../../../../service/content.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { isPlatformBrowser } from '@angular/common';

const HelpTopics = {
  PARTS: 'Parts',
  SERVICE: 'Service',
  TRAINING: 'Training',
  DOWN: 'Machine Down',
  OOTB: 'Out of the Box',
};

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-request-info-form]',
  templateUrl: './request-info-form.component.html',
})
export class RequestInfoFormComponent implements OnInit {
  @Input() ocids!: OcidItems;
  @Input() user!: IUser;
  @Input() requestInfoForm!: UntypedFormGroup;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() formSubmittedEvent: EventEmitter<any> = new EventEmitter<any>();
  howToHelpOpts: IOCIDAndValue[] = [
    {
      ocid: 'global.parts',
      value: HelpTopics.PARTS,
    },
    {
      ocid: 'web2case.service',
      value: HelpTopics.SERVICE,
    },
    {
      ocid: 'web2case.training',
      value: HelpTopics.TRAINING,
    },
    {
      ocid: 'web2case.machine-down',
      value: HelpTopics.DOWN,
    },
    {
      ocid: 'web2case.ootb',
      value: HelpTopics.OOTB,
    },
  ];
  oldHowToHelpValue = '';
  canSubmit = false;
  itemNumber = '';
  platformBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private contentService: ContentService
  ) { }

  ngOnInit() {
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.requestInfoForm.addControl(
      'howToHelp',
      this.formBuilder.control('', Validators.required)
    );
    this.requestInfoForm.statusChanges.subscribe((status: string) => {
      const newStatus: boolean = status === 'VALID';
      // Set timeout to delay the check for form validity to avoid console errors while adding/removing
      // nested forms and their form controls.
      setTimeout(() => {
        if (newStatus !== this.canSubmit) {
          this.canSubmit = newStatus;
        }
      }, 100);
    });
    if (this.platformBrowser) {
      if (this.contentService.getCookie('partNumForPrice')) {
        const cookieVal = this.contentService.getCookieValue('partNumForPrice');
        // Check if it's a single part number or a multiple        
        this.itemNumber = cookieVal;
        this.requestInfoForm.get('howToHelp').setValue(HelpTopics.PARTS);
        this.howToHelpChanged(HelpTopics.PARTS);
      }
    }
  }

  /**
   * Event fired when the how to help dropdown select has changed. Adds various
   * form groups based on its value.
   * @param {string} selectedValue
   */
  howToHelpChanged(selectedValue: string) {
    if (this.requestInfoForm.get(this.oldHowToHelpValue + 'Form')) {
      this.requestInfoForm.removeControl(this.oldHowToHelpValue + 'Form');
    }
    // Adds needed form control.
    switch (selectedValue) {
      case HelpTopics.TRAINING:
        this.requestInfoForm.addControl(
          'trainingForm',
          new UntypedFormGroup({})
        );
        this.oldHowToHelpValue = 'training';
        break;
      case HelpTopics.DOWN:
        this.requestInfoForm.addControl(
          'machineDownForm',
          new UntypedFormGroup({})
        );
        this.oldHowToHelpValue = 'machineDown';
        break;
      case HelpTopics.OOTB:
        this.requestInfoForm.addControl('ootbForm', new UntypedFormGroup({}));
        this.oldHowToHelpValue = 'ootb';
        break;
      case HelpTopics.SERVICE:
        this.requestInfoForm.addControl(
          'serviceForm',
          new UntypedFormGroup({})
        );
        this.oldHowToHelpValue = 'service';
        break;
      case HelpTopics.PARTS:
        this.requestInfoForm.addControl(
          'partsForm',
          new UntypedFormGroup(
            {},
            { validators: OneRequiredValidator('OrderNumber', 'InvoiceNumber') }
          )
        );
        this.oldHowToHelpValue = 'parts';
        break;
    }
  }

  /**
   * Gets the training form.
   * @returns {FormGroup}
   */
  get trainingForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.requestInfoForm.get('trainingForm');
  }

  /**
   * Gets the machine down form.
   * @returns {FormGroup}
   */
  get machineDownForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.requestInfoForm.get('machineDownForm');
  }

  /**
   * Gets the ootb form.
   * @returns {FormGroup}
   */
  get ootbForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.requestInfoForm.get('ootbForm');
  }

  /**
   * Gets the service form.
   * @returns {FormGroup}
   */
  get serviceForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.requestInfoForm.get('serviceForm');
  }

  /**
   * Gets the parts form.
   * @returns {FormGroup}
   */
  get partsForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.requestInfoForm.get('partsForm');
  }

  /**
   * Pass the added form control name when the user submits the form.
   */
  requestInfoFormSubmitted() {
    if (this.requestInfoForm.valid) {
      this.formSubmittedEvent.emit(this.oldHowToHelpValue + 'Form');
    }
  }
}
