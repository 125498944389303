import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { ManageUsersService } from '../manage-users.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  providers: [NotificationService],
})
export class InviteUserComponent implements OnInit, OnDestroy {
  title = '';
  inviteForm: UntypedFormGroup;
  dataSubmitted = false;
  ocids: {} = {};

  constructor(
    public dialogRef: MatDialogRef<InviteUserComponent>,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private _formBuilder: UntypedFormBuilder,
    private manageUsersService: ManageUsersService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.title = this.ocids['account.manage-users-invite-link'];
    this.inviteForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email]],
      customerNumber: [this.data[0].customerNumber, Validators.required],
    });
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Invite the user.
   *
   * @param {form: NgForm}
   */
  inviteUser(form: UntypedFormGroup) {
    this.notificationService.reset();
    if (form.valid) {
      this.manageUsersService
        .inviteUser({
          firstName: form.controls.firstName.value,
          lastName: form.controls.lastName.value,
          contactEmail: form.controls.contactEmail.value,
          customerNumber: form.controls.customerNumber.value,
        })
        .subscribe(
          () => {
            this.dataSubmitted = true;
            this.title = this.ocids['manage-users.invite-confirmation'];

            this.changeDetectorRef.detectChanges();
          },
          (error) => {
            console.log(error);
            this.notificationService.addError(error.error.title);

            this.changeDetectorRef.detectChanges();
          }
        );
    } else {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Close the dialog box.
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Destroy any leftover subscriptions.
   */
  ngOnDestroy() {
    if (this.changeDetectorRef) {
      this.changeDetectorRef.detach();
    }
  }
}
