export interface IAddress {
  id?: string;
  addAsNewAddress?: boolean;
  readOnly?: boolean;
  address1: string;
  city: string;
  country: string;
  contactName?: string;
  addressType?: string;
  postalCode?: string;
  prefix?: string;
  salesRepName?: string;
  phoneNumber?: string;
  lastName?: string;
  addressCode?: string;
  companyNumber?: string;
  state?: string;
  localShipToNumber?: string;
  address3?: string;
  address2?: string;
  jobTitle?: string;
  salesRepId?: string;
  ownerId?: string;
  customerName?: string;
  firstName?: string;
  faxNumber?: string;
  companyName?: string;
  county?: string;
  shipToNumber?: string;
  activeFlag?: string;
  suffix?: string;
  middleName?: string;
  customerNumber?: string;
  taxSuffix?: string;
  contactEmail?: string;
  comments?: string;
  defaultBilling?: boolean;
  defaultShipping?: boolean;
  makeDefault?: boolean;
}

export interface IAddressDetails {
  address: IAddress,
  duplicateAddress: boolean,
  duplicateAddresses: IAddress[]
}

export const CountryWithoutZip = [
  'AO',
  'AG',
  'AW',
  'BS',
  'BZ',
  'BJ',
  'BW',
  'BO',
  'BQ',
  'BF',
  'BI',
  'CM',
  'CF',
  'KM',
  'CG',
  'CD',
  'CK',
  'CI',
  'CW',
  'DJ',
  'DM',
  'TL',
  'TP',
  'GQ',
  'ER',
  'FJ',
  'TF',
  'GA',
  'GM',
  'GH',
  'GD',
  'GY',
  'HM',
  'HK',
  'KI',
  'LY',
  'MO',
  'MW',
  'ML',
  'MW',
  'NR',
  'NU',
  'MR',
  'KP',
  'QA',
  'RW',
  'KN',
  'ST',
  'SC',
  'SL',
  'SB',
  'SR',
  'SY',
  'TG',
  'TK',
  'TO',
  'TV',
  'UG',
  'AE',
  'VU',
  'YE',
  'ZW',
];
