import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePlayBadgeComponent } from './google-play-badge.component';

@NgModule({
  declarations: [GooglePlayBadgeComponent],
  exports: [GooglePlayBadgeComponent],
  imports: [CommonModule],
})
export class GooglePlayBadgeModule {}
