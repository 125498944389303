import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrderInquiryService } from '../parts-order-inquiry/order-inquiry.service';
import { IOrderHistItem } from '../../../../../contracts/orders/iorder-hist-item';
import { IOrderItem } from '../../../../../contracts/orders/iorder-item';
import { INavMenu } from '../../../../../contracts/user/inav-menu';
import { IOrderInquiryDto } from '../../../../../contracts/orders/dto/iorder-inquiry-dto';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { forkJoin, merge, Observable, of } from 'rxjs';
import {
  catchError,
  map,
  onErrorResumeNext,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { ICodeAndDesc } from '../../../../../contracts/commerce/icode-and-desc';
import { AppDateAdapter } from '../../../../../shared/localization/format-datepicker';
import { localizationValueProvider } from '../../../../../shared/localization/localization.service.provider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExcelService } from '../../../../../service/excel/excel.service';
import { WindowRefService } from '../../../../../service/window-ref/window-ref.service';
import { FormatDatePipe } from '../../../../../shared/format-date.pipe';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { ContentService, IUrlState } from '../../../../../service/content.service';
import * as dayjs from 'dayjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-parts-orders',
  templateUrl: './parts-orders.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    localizationValueProvider,
    NotificationService,
  ],
})
export class PartsOrdersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('dateFormat') dateFormat: string;
  @Input('ocids') ocids: OcidItems;
  @Input('platformBrowser') platformBrowser: Record<string, unknown>;
  @Input('orderStatusOptions') orderStatusOptions: INavMenu;
  @Input('orderTypeOptions') orderTypeOptions: ICodeAndDesc[];
  @Input('pageSizeOptions') pageSizeOptions: number[] = [];
  @Input('isOrder') isOrder: boolean = true;
  @ViewChild('resetButton', { static: false }) resetButton!: any;
  orderResults: IOrderItem[] = [];
  inquiryFormGroup!: UntypedFormGroup;
  maxDate = new Date();
  minDate = new Date();
  oneMonth = new Date();
  maxSearchFieldWidth = 220;
  formatDatePipe: FormatDatePipe = new FormatDatePipe();
  searched = false;
  dataLength = 0;
  sort: MatSort;
  submitted = false;
  @ViewChild(MatSort) set sortCtrl(sort: MatSort) {
    if (!this.sort && sort) {
      // ViewChild for MatSort is undefined when there's *ngIf, so we'll use a setter to set the sort once ngIf = true.
      this.sort = sort;
    }
  }
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild('searchField') searchField!: ElementRef;
  searchEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadReturns: EventEmitter<boolean> = new EventEmitter<boolean>();
  urlState$: Observable<IUrlState> = this.contentService.urlState$.pipe(
    take(1),
    shareReplay(1)
  );
  dataSource = new MatTableDataSource<any>([]);
  previousParams: IOrderInquiryDto;
  previousParamsExist = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private contentService: ContentService,
    private orderService: OrderInquiryService,
    private excelService: ExcelService,
    private winRef: WindowRefService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    const params = this.orderService.getRequestParams(this.isOrder);
    this.previousParams = {
      limit: params ? params.limit : 10,
      offset: params ? params.offset - 1 : 0,
      sortBy: params ? params.sortBy : 'orderDate',
      sortDir: params ? params.sortDir : 'desc',
    };
    this.oneMonth.setMonth(this.oneMonth.getMonth() - 1);
    this.minDate = new Date();
    this.minDate.setFullYear(this.minDate.getFullYear() - 3);
    this.urlState$.subscribe((urlState: IUrlState) => {
      // Determine if the previous url was the order/return details page.
      if (urlState && urlState.previousUrl.includes(this.isOrder ? 'order-details' : 'return-details')) {
        // If it was the previous url, determine if there was a previous request to parts order/return inquiry.
        if (!this.isOrder) {
          // If returns, emit Load Returns event so that it displays the Returns tab instead of the order tab
          this.loadReturns.emit(true);
        }
        if (params) {
          // If there was a previous request, set the previous parameters var, the index of the paginator,
          // and that previous parameters exist to trigger the request to get the same payload.
          this.previousParams = params;
          // If the page index was already set to 0, keep it 0, but if it is anything else than 0, subtract
          // one from offset. This keeps the index of the page the same when hitting the back button.
          this.paginator.pageIndex =
            params.offset === 0 ? 0 : params.offset - 1;
          this.previousParamsExist = true;
        }
      }
      // Set the inquiry form now that we have all the data we need.
      this.inquiryFormGroup = this._formBuilder.group({
        orderStatus: [
          this.previousParams.orderStatus
            ? this.previousParams.orderStatus
            : 'A',
        ],
        searchTerm: [
          this.previousParams.searchTerm ? this.previousParams.searchTerm : '',
        ],
        startDate: [
          this.previousParams.startDate
            ? dayjs(new Date(this.previousParams.startDate))
              .add(1, 'day')
              .toDate()
            : new Date(this.oneMonth),
        ],
        endDate: [
          this.previousParams.endDate
            ? new Date(this.previousParams.endDate)
            : new Date(this.maxDate),
        ],
      });

      // If previous parameters existed, emit a search event to get the payload.
      if (this.previousParamsExist) {
        this.searchEvent.emit();
      }
    });
  }

  ngAfterViewInit() {
    if (this.platformBrowser) {
      merge(this.sort.sortChange, this.paginator.page, this.searchEvent)
        .pipe(
          startWith({}),
          skip(1),
          switchMap((event) => {
            this.notificationService.reset();
            // If the value of the event is true, reset the page index back to 0.
            if (event === true) {
              this.paginator.pageIndex = 0;
            }
            // Build the request parameters.
            const order: IOrderInquiryDto = {
              limit: this.paginator.pageSize,
              offset: event === true ? 0 : this.paginator.pageIndex,
              sortBy: this.sort.active ? this.sort.active : 'orderDate',
              sortDir: this.sort.direction ? this.sort.direction : 'desc',
            };
            // Loop through the inquiry form group controls and build the rest of the
            // order/return inquiry data transfer object.
            Object.keys(this.inquiryFormGroup.controls).forEach((key) => {
              const controlValue = this.inquiryFormGroup.controls[key].value;
              // Below we are checking if the control key is the start or end date.
              // If it is either of these, we are going to handle the case where CST
              // is the saved timestamp for orders and the browser may or may not be CST.
              // Here we convert the selected date to MM/DD/YYYY in getDateValue. We then
              // add either 00:00:00 (for the beginning of the selected date in the browser's
              // time) to the start date or 23:59:59 (for the very end of the selected date
              // in the browser's time). Lastly, we convert the date to CST by getting the
              // difference in time between the browser and UTC timezones and subtracting 5
              // (the difference between CST and UTC) which will give us the converted startDate
              // and endDate in CST from the browser's time (which is the timezone all orders
              // are saved in).
              if (key === 'startDate') {
                const dateValue = this.orderService.getDateValue(
                  controlValue || this.oneMonth
                );
                const convertedDateValue: Date = new Date(
                  new Date(dateValue + ' 00:00:00').getTime() +
                  60 *
                  60 *
                  1000 *
                  (this.orderService.getDifferenceInDate() - 5)
                );
                order[key] =
                  this.orderService.getDateValue(convertedDateValue) +
                  ' ' +
                  this.orderService.getTimeValue(convertedDateValue);
              } else if (key === 'endDate') {
                const dateValue = this.orderService.getDateValue(
                  controlValue || this.maxDate
                );
                const convertedDateValue: Date = new Date(
                  new Date(dateValue + ' 23:59:59').getTime() +
                  60 *
                  60 *
                  1000 *
                  (this.orderService.getDifferenceInDate() - 5)
                );
                order[key] =
                  this.orderService.getDateValue(convertedDateValue) +
                  ' ' +
                  this.orderService.getTimeValue(convertedDateValue);
              } else {
                order[key] = controlValue;
              }
            });
            // Join the getOrder request and order dto object into one observable to make
            // both data available to the next mapping.
            return forkJoin([
              this.orderService.getOrder(order, this.isOrder).pipe(
                catchError((error) => {
                  this.notificationService.addError(error.error.title);
                  this.searched = true;
                  this.orderResults = [];
                  return null;
                }),
                onErrorResumeNext()
              ),
              of(order),
            ]);
          }),
          map((response) => {
            // Below we are going to access the order inquiry response from the first object in the array
            // and the order dto in the second object in the array.

            this.dataLength = response[0]['totalResults'];
            this.searched = true;
            // Return the order inquiry response.
            return response[0];
          })
        )
        .subscribe((results: IOrderHistItem) => {
          // Set the results and data source.
          this.orderResults = results.items;
          this.dataSource.data = this.orderResults;
        });

      // fetch the results for the first time
      this.searchEvent.emit(true);
    }
  }

  /**
   * Used to programmatically check if the form is valid. If it is,
   * emit a search event.
   * @param {string} filterType
   */
  getOrders(filterType): void {
    if (this.inquiryFormGroup.valid) {
      this.submitted = true;
      this.searchEvent.emit(true);
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      let filterValue: string;
      switch (filterType) {
        case 'search':
          filterValue = this.inquiryFormGroup.controls.searchTerm.value;
          break;
        case 'status':
          filterValue = this.inquiryFormGroup.controls.orderStatus.value;
          break;
        case 'startDate':
          filterValue = this.inquiryFormGroup.controls.startDate.value;
          break;
        case 'endDate':
          filterValue = this.inquiryFormGroup.controls.endDate.value;
          break;
        default:
          filterValue = '';
      }
      dataLayer.push({
        event: 'orderHistory.filter',
        filterName: filterType,
        filterValue: filterValue,
        filterSource: this.isOrder ? 'Orders' : 'Returns',
      });
    }
  }
  /**
   * Set the inquiry form.
   */
  setInquiryForm(form): void {
    this.inquiryFormGroup = this._formBuilder.group(form);
  }

  /**
   * Export order history
   */
  exportOrders(): void {
    if (this.dataLength) {
      const headers = [
        'OrderNumber',
        'PONum',
        'OrderType',
        'OrderedBy',
        'Status',
        'OrderDate',
      ];
      const json = this.dataSource.data.map((data) => {
        return {
          OrderNumber: data.comOrderNumber,
          PONum: data.poNumber,
          OrderType: data.salesTypeText,
          OrderedBy: data.loginId,
          Status: data.orderStatus,
          OrderDate: this.formatDatePipe.transform(
            data.orderDate,
            this.dateFormat
          ),
        };
      });
      this.excelService.exportData(headers, json, 'OrderHistory');
    }
  }

  /**
 * Export return history
 */
  exportReturns(): void {
    if (this.dataLength) {
      const headers = [
        'ReturnNumber',
        'OrderNumber',
        'PONum',
        'ReturnLocation',
        'Status',
        'CreationDate',
      ];
      const json = this.dataSource.data.map((data) => {
        return {
          ReturnNumber: data.returnNumber,
          OrderNumber: data.orderNumber,
          PONum: data.poNumber,
          ReturnLocation: data.returnLocation,
          Status: data.orderStatus,
          CreationDate: this.formatDatePipe.transform(
            data.orderDate,
            this.dateFormat
          ),
        };
      });
      this.excelService.exportData(headers, json, 'ReturnHistory');
    }
  }

  resetForm(event) {
    event.preventDefault();
    this.inquiryFormGroup.reset();
    this.inquiryFormGroup.patchValue({
      orderStatus: 'A',
      searchTerm: '',
      startDate: new Date(this.oneMonth),
      endDate: new Date(this.maxDate)
    });
    this.searchEvent.emit();
    this.submitted = false;
  }

  get isDirty() {
    return this.inquiryFormGroup.dirty && this.submitted;
  }

  ngOnDestroy(): void {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
