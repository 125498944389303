import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';

interface PardotFormData {
  title: string;
  text: string;
  pardotFormName: string;
  ctaLabel: string;
  pardotFormFields: {
    pardotFieldName: string;
    fieldLabel: string;
  }[];
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pardot-form',
  templateUrl: './pardot-form.component.html',
})
export class PardotFormComponent implements OnInit, CartridgeInterface {
  platformBrowser = false;
  ocids: OcidItems;
  pardotGenericForm: UntypedFormGroup;
  formSubmitted = false;
  @Input() data: PardotFormData;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private formBuilder: UntypedFormBuilder,
    private userService: CurrentUserService,
    private notificationService: NotificationService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.pardotGenericForm = this.formBuilder.group({});
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.required-text',
        'clearsky.subscription-contact-us-popup',
      ])
      .subscribe();

    this.data.pardotFormFields.forEach((field) => {
      this.pardotGenericForm.addControl(
        field.pardotFieldName,
        this.formBuilder.control('')
      );
    });
  }
  submitForm(): void {
    this.notificationService.reset();
    if (this.pardotGenericForm.valid) {
      this.userService
        .submitToPardot(this.pardotGenericForm.value, this.data.pardotFormName)
        .subscribe({
          next: () => {
            //Send form success event to GA
            if (this.platformBrowser) {
              const dataLayer = (this.winRef.nativeWindow.dataLayer =
                this.winRef.nativeWindow.dataLayer || []);
              dataLayer.push({
                event: 'formEvent',
                formAction: 'Success',
                formName: 'Pardot Form',
              });
            }
            this.formSubmitted = true;
          },
          error: (error) => {
            this.notificationService.addError(error.error.title);
          },
        });
    }
  }
}
