import { ElementRef, Injectable, SimpleChanges } from '@angular/core';
import {
  Machine,
  ScatterPlot3dData,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { shadeRGBColor } from '../../../../shared/shade-rgb.pipe';
import * as Highcharts from 'highcharts';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';

@Injectable()
export abstract class EngineComponentWidget {
  abstract machine: Machine;
  abstract historic: string;
  protected chart: ElementRef;
  protected abstract historicMapping: object;
  plot;
  protected abstract chartTitle: string;
  protected xAxisTitle: string;
  protected yAxisTitle: string;
  protected colorScaleMin: string = WidgetColors.green;
  protected colorScaleMax: string = shadeRGBColor(WidgetColors.green, -0.6);
  protected chartData = () => {
    const chartHeight = 400;
    const min = Math.min(...this.historicalData.map((i) => i.z));
    const max = Math.max(...this.historicalData.map((i) => i.z));

    return {
      chart: {
        type: 'scatter',
        zoomType: 'xy',
        height: chartHeight,
        marginTop: 40,
        marginBottom: 80,
      },
      colorAxis: {
        min,
        max,
        minColor: this.colorScaleMin,
        maxColor: this.colorScaleMax,
        tickPositions: [min, max],
      },
      title: {
        text: this.chartTitle,
      },
      xAxis: {
        lineWidth: 1,
        lineColor: '#000',
        type: 'linear',
        title: {
          text: this.xAxisTitle,
        },
      },
      yAxis: {
        type: 'linear',
        title: {
          text: this.yAxisTitle,
        },
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: chartHeight - 100,
      },
      plotOptions: {
        scatter: {
          marker: {
            symbol: 'circle',
          },
        },
      },
      series: [],
    };
  };

  ngAfterViewInit(): void {
    this.drawChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.historic && !changes.historic.isFirstChange()) {
      this.drawChart();
    }
  }

  drawChart(): void {
    if (!this.plot) {
      this.plot = Highcharts.chart(
        this.chart.nativeElement,
        this.chartData() as any,
        function (chart) {
          // Adjust color scale so it's only the size of the chart plotting area
          chart.update({
            legend: {
              symbolHeight: chart.plotHeight,
            },
          });
        }
      );
      this.setChartData();
    } else {
      this.setChartData();
    }
  }

  /**
   * Set chart data.
   * @protected
   */
  protected setChartData(): void {
    // Remove old data if it exists
    if (this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }

    // Add new data
    this.plot.addSeries({
      type: 'scatter',
      group: 'group',
      marker: {
        radius: 6,
      },
      colorKey: 'colorValue',
      data: this.historicalData.map((i) => {
        return {
          x: i.x,
          y: i.y,
          colorValue: i.z,
        };
      }),
    });
  }

  /**
   * Get historical data based on historic selection.
   */
  get historicalData(): ScatterPlot3dData[] {
    return this.machine[this.historicMapping[this.historic]] || [];
  }

  ngOnDestroy() {
    this.plot.destroy();
  }
}
