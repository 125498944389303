import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PartsService } from '../../../../service/product/parts.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScannerService } from 'app/service/scanner.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-verify-by-serial',
  templateUrl: './verify-by-serial.component.html',
})
export class VerifyBySerialComponent implements OnInit {
  serialForm: UntypedFormGroup;
  isValid: boolean;
  verifyComplete: boolean;
  ocids: {} = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public dialogRef: MatDialogRef<VerifyBySerialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: UntypedFormBuilder,
    private _partsService: PartsService,
    private scanner: ScannerService,
    private localization: LocalizationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.serialForm = this._fb.group({
      serialNum: [
        '',
        [Validators.required, Validators.pattern('[0-9a-zA-Z]+')],
      ],
    });
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'pdp.serial-number-message',
        'pdp.verify-serial-number-success',
        'pdp.verify-serial-number-fail',
        'pdp.serial-number-new-search',
        'shopping-cart.retrofit-serial-number-message-error2',
      ])
      .subscribe();
  }

  closeDialog() {
    this.resetForm();
    this.dialogRef.close();
  }

  resetForm() {
    this.serialForm.reset();
    this.verifyComplete = false;
    this.isValid = false;
  }

  onVerifyNum() {
    if (this.serialForm.valid) {
      const serialNum = this.serialNumFormControl.value;

      this._partsService
        .verifyBySerialNum(this.data.part, serialNum)
        .subscribe((res) => {
          if (res.isValid) {
            this.isValid = true;
          } else {
            this.isValid = false;
          }

          this.verifyComplete = true;
        });
    }
  }

  getErrorMessage() {
    return this.serialNumFormControl.hasError('required')
      ? this.ocids['account.required-text']
      : this.serialNumFormControl.hasError('pattern')
      ? this.ocids['shopping-cart.retrofit-serial-number-message-error2']
      : this.ocids['account.required-text'];
  }

  get serialNumFormControl() {
    return this.serialForm.get('serialNum');
  }

  async openScanner() {

    (await this.scanner
        .scanSerialNum('Verify Part by Serial Number'))
      .subscribe((data?) => {
        if (data) {
          this.serialForm.get('serialNum').setValue(data);
          this.onVerifyNum();
        }
      });
  }
}
