import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { SearchCustomersDto } from "./dto/search-customers-dto";
import { ICustomerResult } from "../../contracts/user/icustomer-result";

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  /**
   * Check for existing account.
   *
   * @param {string} userId
   * @returns {Observable<any>}
   */
  checkExistingAccount(userId: string) {
    return this.http.get(environment.ATG_BASE_URL + '/users/available/' + userId,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      })
      .pipe(map(data => data), catchError(error => throwError(error)));
  }

  /**
   * Search users by property.
   *
   * @param {SearchCustomersDto} params
   * @returns {Observable<ICustomerResult[]>}
   */
  searchCustomers(params: SearchCustomersDto) {
    return this.http.post<ICustomerResult[]>(`${environment.apiUrl}/users/searchCustomers`, params);
  }

}
