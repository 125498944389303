import { AfterViewInit, Component, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyService } from '../../../clearsky.service';
import * as Highcharts from 'highcharts';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import * as cloneDeep from 'lodash.clonedeep';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-last-reported',
  templateUrl: './last-reported.component.html',
  styleUrls: ['./last-reported.component.scss'],
})
export class LastReportedComponent implements AfterViewInit {
  chartEl: ElementRef;
  ocids: OcidItems = {};
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  showChart = false;
  isLoading = true;
  displayName = WidgetsDisplay.lastReported;
  widgetName = Widgets.LAST_REPORTED;
  reportedLast24Hours: number;
  reportedOver24Hours: number;
  private plot;

  constructor(protected clearskyService: ClearskyService, private changeDetectorRef: ChangeDetectorRef, private localization: LocalizationService) { }

  ngAfterViewInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        this.displayName,
        'clearsky.graphic-default',
      ])
      .pipe(first())
      .subscribe();
    this.clearskyService
      .getDataByWidgetKey(CsRequestKeys.dashView)
      .subscribe((page) => {
        if (!page || !page.aggregations) {
          return;
        }

        this.resetChartData();
        this.showChart = true;
        this.isLoading = false;
        this.reportedLast24Hours = Math.ceil(
          ((page.aggregations.fleetOverview
            ? page.aggregations.fleetOverview.mxReportedLast24Hr
            : 0) /
            (page.aggregations.machineCount
              ? page.aggregations.machineCount.total
              : 0)) *
          100
        );
        this.reportedOver24Hours = 100 - this.reportedLast24Hours;
        // Does the chart already exist?
        if (this.plot && this.showChart) {
          this.setChartData();
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Set data for chart.
   * @protected
   */
  private setChartData(): void {
    this.plot.addSeries({
      name: 'Reported Over 24 Hours Ago',
      data: [
        {
          y: this.getBarXValue(this.reportedOver24Hours),
          color: '#eee',
          dataLabels: {
            format: `${this.reportedOver24Hours}% > 24 hours ago`,
          },
        },
      ],
    });

    this.plot.addSeries({
      name: 'Reported in Last 24 Hours',
      data: [
        {
          y: this.getBarXValue(this.reportedLast24Hours),
          color: WidgetColors.green,
          dataLabels: {
            format: `${this.reportedLast24Hours}% < 24 hours ago`,
          },
        },
      ],
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    const chartData = mergeDeep(cloneDeep(BaseChartConfig), {
      chart: {
        type: 'bar',
        height: 100,
        spacingLeft: 20,
        spacingRight: 20,
      },
      title: {
        text: undefined,
      },
      yAxis: {
        visible: false,
      },
      xAxis: {
        visible: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            verticalAlign: 'middle',
            align: 'left',
            overflow: 'allow',
            crop: false,
            inside: undefined,
            y: -30,
            color: '#000',
            style: {
              fontSize: '8px',
              textOutline: 'none',
            },
          },
        },
      },
      tooltip: {
        formatter: function () {
          return `${this.series.name}: ${this.y}%`;
        },
      },
      series: [],
    });

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      chartData as unknown
    );
    this.setChartData();
  }

  /**
   * Reset chart data.
   * @protected
   */
  private resetChartData(): void {
    if (this.plot && this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Get X value for the bar so it ALWAYS shows.
   * @param value
   * @private
   */
  private getBarXValue(value: number): number {
    if (value > 90) {
      return 90;
    } else if (value < 10) {
      return 10;
    } else {
      return value;
    }
  }
}
