import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { OktaAuthWrapper } from '../../service/auth/okta.auth.wrapper';
import { EndecaService } from '../endeca.service';
import { TemplateComponent } from '../template/template.component';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-endeca-payload',
  templateUrl: './endeca-payload.component.html',
})
export class EndecaPayloadComponent implements OnInit, AfterViewInit {
  @Input() data;
  @Input('url') payloadUrl;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container;
  private isLoggedIn: boolean = null;

  constructor(
    private oktaAuth: OktaAuthWrapper,
    private endecaService: EndecaService
  ) {}

  ngOnInit() {
    this.endecaService.injectTemplateType(
      this.container,
      TemplateComponent,
      this.data,
      this.payloadUrl
    );
  }

  ngAfterViewInit() {
    // This code is required in order to update the UI when the staet of the user changes.
    this.oktaAuth.isLoggedIn
      .pipe(distinctUntilChanged())
      .subscribe((value: boolean) => {
        // Prevent the endeca call to retrieve the home payload twice.
        if (this.isLoggedIn !== null && this.isLoggedIn !== value) {
          this.endecaService
            .getPayload(this.payloadUrl)
            .subscribe((data: any) => {
              this.endecaService.injectTemplateType(
                this.container,
                TemplateComponent,
                data,
                this.payloadUrl
              );
            });
        }
        this.isLoggedIn = value;
      });
  }
}
