import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
})
export class QrCodeComponent implements AfterViewInit {
  @ViewChild('qr', { read: ElementRef }) qrCode!: ElementRef;
  @Input() data: string;

  ngAfterViewInit(): void {
    QRCode.toCanvas(this.qrCode.nativeElement, this.data, {
      errorCorrectionLevel: 'M',
    });
  }
}
