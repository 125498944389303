import { ICommerceItemWithCart } from './icommerce-item-with-cart';
import { IPaymentGroup } from './ipayment-group';
import { IShippingGroup } from './ishipping-group';

export interface IOrder {
  showTax?: boolean;
  id?: number;
  PONumber?: string;
  comOrderNumber?: string;
  coreReturnExist?: boolean;
  customerNumber?: string;
  storeId?: string;
  description?: string;
  salesType?: string;
  salesTypeCode?: string;
  salesTypeDesc?: string;
  orderClassType?: string;
  shippingInstruction?: string;
  siblingComOrderNumber?: string;
  siblingOrderId?: string;
  comments?: string;
  orderItemType?: string;
  priceInfo?: {
    discounted: boolean;
    amount: number;
    total: number;
    adjustments: {
      quantityAdjusted: number;
      adjustment: number;
      adjustmentDescription: string;
      totalAdjustment: number;
    };
    shipping: number;
    manualAdjustmentTotal: number;
    amountIsFinal: boolean;
    discountAmount: number;
    tax: number;
    rawSubtotal: number;
    currencyCode: string;
    surchargeAmountTotal: number;
    coreReturnAmountTotal?: string;
  };
  pricingMessages?: {
    key: string;
    value: {
      identifier: string;
      priority: number;
      summary: string;
      type: string;
    }[];
  };
  state?: number;
  stateDetails?: string;
  pickupWarehouse?: string;
  taxPriceInfo?: {
    discounted: boolean;
    cityTax: number;
    amount: number;
    valueAddedTax: number;
    countyTax: number;
    adjustments?: {
      quantityAdjusted: number;
      promotionId: string;
      adjustmentDescription: string;
      adjustment: number;
      totalAdjustment: number;
      coupon: {
        id: string;
      };
    };
    isTaxIncluded: boolean;
    amountIsFinal: boolean;
    miscTaxInfo?: {
      key: string;
      value: object;
    };
    districtTax: number;
    stateTax: number;
    countryTax: number;
  };
  manualAdjustments?: {
    id: string;
    itemType: string;
    amount: number;
    reasonCode: string;
    notes: string;
    adjustmentType: string;
    type: string;
  };
  siteId?: string;
  totalCommerceItemCount?: number;
  commerceItems?: {
    items?: ICommerceItemWithCart[];
  };
  shippingGroups?: {
    items?: IShippingGroup[];
  };
  paymentGroups?: {
    items?: IPaymentGroup[];
  };
}

export const PaymentGroups = {
  CreditCard: 'tokenizedCreditCard',
  Account: 'account',
  PayPal: 'payPal',
};

export const OrderItemType = {
  Part: 'PARTS',
  Machine: 'MACHINE',
};

export const SalesType = {
  Pickup: 'S43',
  Economy: 'S15',
  Priority: 'S10',
  Conventional: 'S11',
};
