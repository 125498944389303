import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ClearskySubscriptionPlan } from '../../../../contracts/clearsky/subscription/clearsky-subscription';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-addons-card',
  templateUrl: './subscription-addons-card.component.html',
  styleUrls: ['./subscription-addons-card.component.scss'],
})
export class SubscriptionAddonsCardComponent implements OnInit {
  @Input() selectedPlan!: ClearskySubscriptionPlan;
  @Input() selectedAddons: string[] = [];
  @Input() disableEditBtn =  false;
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.-selections-label'
      ])
      .pipe(first())
      .subscribe();
  }
}
