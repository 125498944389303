import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SlickCarouselComponent } from '../../../shared/slick-carousel/slick.component';

interface IJumpLinkItem {
  JumpLinkRightContent: {
    videoUrl?: string;
    imageSrcUrl?: string;
    imageTitle?: string;
    imageAlt?: string;
    campaignDescrition?: string;
    campaignTitle?: string;
    jumpLinks?: IJumpLink[];
    ctaUrl?: string;
    ctaText?: string;
    ctaTarget?: string;
    viewAllUrl?: string;
    viewAllText?: string;
    viewAllTarget?: string;
  };
  JumpLinkLeftContent: {
    videoUrl?: string;
    imageSrcUrl?: string;
    imageTitle?: string;
    imageAlt?: string;
    campaignDescrition?: string;
    campaignTitle?: string;
    jumpLinks?: IJumpLink[];
    ctaUrl?: string;
    ctaText?: string;
    ctaTarget?: string;
    viewAllUrl?: string;
    viewAllText?: string;
    viewAllTarget?: string;
  };
}

interface IJumpLink {
  text: string;
  url: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-jump-link-carousel',
  styleUrls: ['./jump-link-carousel.component.scss'],
  templateUrl: './jump-link-carousel.component.html',
})
export class JumpLinkCarouselComponent
  implements AfterViewInit, CartridgeInterface
{
  @Input() data!: {
    sectionTitle: string;
    sectionDescription: string;
    carousel: IJumpLinkItem[];
  };
  @ViewChild('carousel') carousel!: SlickCarouselComponent;
  slideConfig = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  activeSlide = 0;

  ngAfterViewInit(): void {
    if (this.carousel) {
      this.carousel.afterChange.subscribe((changes) => {
        this.activeSlide = changes.currentSlide;
      });
    }
  }
}
