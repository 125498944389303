import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WidgetService } from 'app/clearsky/widget.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-pdf-download-dialog',
  templateUrl: './pdf-download-dialog.component.html',
  styleUrls: ['./pdf-download-dialog.component.scss'],
})
export class PdfDownloadDialogComponent implements OnInit {
  percentage: number = 0;
  ocids: OcidItems = {};
  constructor(private widgetService: WidgetService, private localization: LocalizationService) {
    this.widgetService.progress$.subscribe((val: number) => {
      this.percentage = val;
    });
  }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.generating-file-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
