// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 93px;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 2;
  border-radius: 0 20px 20px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hcC10b2dnbGVzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLHNCQUFBO0VBQ0Esa0JBQUE7RUFDQSxPQUFBO0VBQ0EsU0FBQTtFQUNBLHNCQUFBO0VBQ0Esa0JBQUE7RUFDQSxVQUFBO0VBQ0EsNEJBQUE7QUFDRiIsImZpbGUiOiJtYXAtdG9nZ2xlcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XHJcbiAgcG9zaXRpb246IGFic29sdXRlO1xyXG4gIGxlZnQ6IDA7XHJcbiAgdG9wOiA5M3B4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmY7XHJcbiAgcGFkZGluZzogMTBweCAyMHB4O1xyXG4gIHotaW5kZXg6IDI7XHJcbiAgYm9yZGVyLXJhZGl1czogMCAyMHB4IDIwcHggMDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/map/map-toggles/map-toggles.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,OAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,UAAA;EACA,4BAAA;AACF;AACA,oqBAAoqB","sourcesContent":[":host {\r\n  display: flex;\r\n  flex-direction: column;\r\n  position: absolute;\r\n  left: 0;\r\n  top: 93px;\r\n  background-color: #fff;\r\n  padding: 10px 20px;\r\n  z-index: 2;\r\n  border-radius: 0 20px 20px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
