import {
    Component,
    Input,
  } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
  import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../../contracts/ocid-items';
import { IOrderInquiryDto } from '../../../../../../contracts/orders/dto/iorder-inquiry-dto';
import { IOrderItem } from '../../../../../../contracts/orders/iorder-item';
  
  @UntilDestroy({ checkProperties: true })
  @Component({
    selector: '[app-returns-table]',
    templateUrl: './returns-table.component.html',
  })
  export class ReturnsTableComponent {
    @Input('previousParams') previousParams: IOrderInquiryDto;
    @Input('ocids') ocids: OcidItems;
    @Input('dateFormat') dateFormat: string;
    @Input('dataSource') dataSource: MatTableDataSource<any>;
  
    constructor() {}
    
    identify(index: number, item: IOrderItem):string {
      return item.returnNumber;
    }
  }