import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { MatTableDataSource } from '@angular/material/table';
import {
  CSLegend,
  CSRefBasic,
} from '../../../contracts/clearsky/clearsky-legend';
import { combineLatest, Subscription } from 'rxjs';
import { ClearskyService } from '../../clearsky.service';
import { isPlatformBrowser } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { KnowledgeArticleService } from '../../../service/knowledge-article.service';
import { CSFilterValueOcids } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { MachineDTC } from '../../../contracts/clearsky/machine/machine.dto';
import { getDTCFieldOcidTranslation } from '../../../contracts/clearsky/machine/machine.fields';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

interface DtcRec {
  id: string;
  src?: string;
  svty?: string;
  stat?: string;
  cnt?: number;
  st?: string;
  desc: string;
  custom: {
    src?: CSRefBasic;
    svty?: CSRefBasic;
  };
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-dtc-table',
  templateUrl: './dtc-table.component.html',
})
export class DtcTableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() dtcs!: MachineDTC[] | string[];
  @Input() caller!: string;
  @Input() columns: string[] = ['id', 'src', 'svty'];
  @Output() dtcClicked: EventEmitter<number> = new EventEmitter<number>();
  dataSource: MatTableDataSource<DtcRec> = new MatTableDataSource<DtcRec>([]);
  pageSize = 10;
  ocids: OcidItems = {};
  articleMapping: { [dtc: string]: string } = {};
  private legend: CSLegend;
  private subs: Subscription;

  constructor(
    private localization: LocalizationService,
    private clearskyService: ClearskyService,
    private kaService: KnowledgeArticleService,
    private gAService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: string
  ) { }

  ngAfterViewInit(): void {
    this.initDataSource();

    this.subs = combineLatest([
      this.clearskyService.legendRef$,
      this.localization.getOCIDs([
        ...Object.values(CSFilterValueOcids),
        'clearsky.filter-label',
        'clearsky.start-filter-label',
        'clearsky.dtc-label',
        'clearsky.code-description-label',
        'clearsky.code-source-label',
        'clearsky.number-of-label',
        'clearsky.time-label',
      ]),
    ]).subscribe(([legend, ocids]) => {
      this.legend = legend;
      this.ocids = ocids;
      this.updateDataSource();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dtcs && !changes.dtcs.firstChange) {
      this.updateDataSource();
    }
  }

  /**
   * Filter list by search term.
   * @param event
   */
  applyFilter(event: Event): void {
    if (!this.dataSource) {
      return;
    }
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Navigate to knowledge article.
   * @param code
   */
  goToArticle(code: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this.gAService.eventEmitter('clearsky_click', this.caller + '_knowledge_article', 'click', 'dtc', code);
      window.open(this.articleMapping[code], '_blank');
    }
  }

  /**
   * Init data source for table.
   * @private
   */
  private initDataSource(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.paginator.pageIndex = 0;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'svty':
          return item.custom.svty ? item.custom.svty.id : item[property];
        default:
          return item[property];
      }
    };
  }

  /**
   * Update data source for table.
   * @private
   */
  private updateDataSource(): void {
    this.dataSource.data = [...this.dtcs].reduce(
      (prev: DtcRec[], dtc: MachineDTC | string) => {
        const isObject = typeof dtc === 'object';
        const legDtc = (this.legend.dtc || []).find(
          (d) => d.id === (isObject ? dtc.id : dtc)
        );
        if (!legDtc) {
          return prev;
        }

        const legSvty = (this.legend.svtyCategory || []).find(
          (s) => s.id === legDtc.svty
        );
        const legSrc = (this.legend.fsrc || []).find(
          (s) => s.id === legDtc.src
        );

        prev.push({
          cnt: isObject ? dtc.cnt : 0,
          id: this.getConvertedId(legDtc.id),
          desc: legDtc.desc,
          src: legSrc ? legSrc.desc : '',
          svty: legSvty ? legSvty.desc : '',
          stat: isObject
            ? (getDTCFieldOcidTranslation(
              'stat',
              dtc.stat as never,
              this.ocids
            ) as string)
            : undefined,
          st: isObject
            ? (getDTCFieldOcidTranslation(
              'st',
              dtc.st as never,
              this.ocids
            ) as string)
            : undefined,
          custom: {
            src: legSrc,
            svty: legSvty,
          },
        });

        return prev;
      },
      [] as DtcRec[]
    );

    this.paginator.pageIndex = 0;
    this.getArticleExistense();
  }

  /**
   * Retrieve knowledge article existense for each dtc.
   * @private
   */
  private getArticleExistense(): void {
    const uniqueIdentifiers = [...new Set(this.dtcs.map((dtc) => this.getConvertedId(dtc.id)))];

    this.kaService
      .getDtcsArticleLink(uniqueIdentifiers)
      .subscribe((articles) => (this.articleMapping = articles));
  }

  /**
   * 
   * @param id 
   * @returns padded string
   */
  private getConvertedId(id: number): string {
    const idString = id.toString();
    return idString.length < 3 ? idString.padStart(idString.length === 1 ? 3 : 4, '00') : idString;
  }
}
