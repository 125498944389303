import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITokenResponse } from '../../../../contracts/bambora/itoken-response';
import { ICreditCard } from '../../../../contracts/user/icredit-card';
import { IAddress } from '../../../../contracts/user/iaddress';
import { ICreditCardDto } from '../../../../contracts/user/dto/icredit-card-dto';
import { ICreditCardsResponse } from '../../../../contracts/commerce/icredit-cards-response';

@Injectable()
export class CreditCardService {
  constructor(private http: HttpClient) { }

  /**
   * Exchange a customer's credit card information for a single-use Bambora token.
   *
   * @param {string} number
   * @param {string} expiry_month
   * @param {string} expiry_year
   * @param {string} cvd
   * @returns {Observable<ITokenResponse>}
   */
  tokenizeCard(number: string, expiry_month: string, expiry_year: string, cvd: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain'
      })
    };

    return this.http.post(`${environment.bamboraApiUrl}/scripts/tokenization/tokens`, {
      number: number,
      expiry_month: expiry_month,
      expiry_year: expiry_year,
      cvd: cvd
    },
    httpOptions)
      .pipe(map(res => <ITokenResponse>res));
  }

  /**
   * Add credit card to profile.
   *
   * @param {string} token
   * @param {IAddress} address
   * @param {boolean} setAsDefault
   * @returns {Observable<any>}
   */
  addCreditCard(token: string, address: IAddress, setAsDefault: boolean) {
    // Remove the set as default for now!
    return this.http.post<ICreditCard>(`${environment.apiUrl}/currentUser/creditCards`, {
      token: token,
      billingAddress: address,
      setAsDefault: setAsDefault.toString()
    })
  }

  /**
   * 
   * @param {ICreditCardDto} params 
   * @returns {ICreditCardsResponse}
   */
  getPaginatedCards(params: ICreditCardDto) {
    const queryString = require('query-string');
    let query = queryString.stringify(params);
    return this.http.get(`${environment.apiUrl}/currentUser/creditCards/creditCardsByPage?${query}`);
  }

  /**
   * Get all credit cards for current user.
   *
   * @returns {Observable<ICreditCard[]>}
   */
  getCreditCards(): Observable<ICreditCard[]> {
    return this.http.get(`${environment.apiUrl}/currentUser/creditCards`).pipe(
      map((data: object) => {
          return data['items'] ? <ICreditCard[]>data['items'] : [];
        }
      ));
  }

  /**
   * Set default credit card for user profile.
   *
   * @param {ICreditCard} card
   * @param {boolean} setAsDefault
   *
   * @returns {Observable<ICreditCard>}
   */
  setDefaultCreditCard(card: ICreditCard, setAsDefault: boolean) {
    return this.http.patch(`${environment.apiUrl}/currentUser/creditCards/${card.id}`, {
      setAsDefault: setAsDefault.toString()
    })
  }

  /**
   * Remove credit card from user profile.
   *
   * @param {ICreditCard} card
   * @returns {Observable<object>}
   */
  deleteCreditCard(card: ICreditCard) {
    return this.http.delete(`${environment.apiUrl}/currentUser/creditCards/${card.id}`);
  }
}
