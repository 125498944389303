import { Component, Input, OnInit } from '@angular/core';
import {
  ClearskyCart,
  ClearskySubscriptionPlan,
} from '../../../../../contracts/clearsky/subscription/clearsky-subscription';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

@Component({
  selector: 'app-subscription-addon-card-price',
  templateUrl: './subscription-addon-card-price.component.html',
})
export class SubscriptionAddonCardPriceComponent implements OnInit {
  @Input() plan!: ClearskySubscriptionPlan | ClearskyCart;
  ocids: OcidItems = {};
  constructor(private localization: LocalizationService) { }
  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.per-unit-annually-label',
        'clearsky.unit-annually-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
