import { Component, Input, OnInit } from '@angular/core';
import { IRefinementBreadcrumb } from '../../../../contracts/IRefinementBreadcrumb';
import { CartridgeInterface } from '../../../cartridge/cartridge.class';

@Component({
  selector: 'app-pdp-crumbs',
  styleUrls: [
    '../../../endeca-payload/base-breadcrumbs/base-breadcrumbs.component.scss',
  ],
  templateUrl: './pdp-crumbs.component.html',
})
export class PdpCrumbsComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    refinementCrumbs: {
      label: string;
      ancestors: IRefinementBreadcrumb[];
      properties: {
        pdpNavigationState: string;
      };
    }[];
  };
  breadcrumbs: {
    label: string;
    url: string;
  }[] = [];

  constructor() {}

  ngOnInit() {
    for (const crumb of this.data.refinementCrumbs) {
      // Reverse the ancestors
      for (const ancestor of crumb.ancestors) {
        this.breadcrumbs.push({
          label: ancestor.label,
          url: ancestor.properties.pdpNavigationState,
        });
      }

      // Now add the root label
      this.breadcrumbs.push({
        label: crumb.label,
        url: crumb.properties?.pdpNavigationState,
      });
    }
  }
}
