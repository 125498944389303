import { WidgetColors } from './dashboard/cs-colors.dto';
export const clusterPinRadius = 40;
export const clusterPinOuterRadiusWidth = 7;

/**
 * Get network cluster pin svg.
 */
export const getNetworkClusterPin = (): string => {
  return '/assets/img/clearsky/site-network-grouped-icon.svg';
};

/**
 * Get site network pin.
 */
export const getSiteNetworkPin = (): string | undefined => {
  return '/assets/img/clearsky/site-network-icon.svg';
};

/**
 * Get cluster pin svg.
 */
export const getClusterPin = (): string => {
  const radius = clusterPinRadius / 2;
  const fillColor = WidgetColors.orange;

  // Create an SVG string of two circles, one on top of the other, with the specified radius and color.
  let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${
    radius * 2
  }" height="${radius * 2}">`;

  // Create outer radius circle
  svg += `<circle cx="${radius}" cy="${radius}" r="${radius}" fill="rgba(255, 84, 5, .5)" />`;

  // Create inner circle
  svg += `<circle cx="${radius}" cy="${radius}" r="${
    radius - clusterPinOuterRadiusWidth
  }" fill="${fillColor}" stroke="black" stroke-width="2" />`;
  svg += `</svg>`;

  return svg;
};

/**
 * A mapping for model groups to pushpin svgs for maps.
 */
const PushpinsByModelGroupMapping: { [key: number]: string } = {
  1: '/assets/img/clearsky/PushpinBooms.svg',
  2: '/assets/img/clearsky/PushpinScissors.svg',
  3: '/assets/img/clearsky/PushpinTelehandlers.svg',
};

/**
 * Get svg pushpin by machine model group.
 * @param group
 */
export const getPushpinByModelGroup = (group: number): string => {
  return (
    PushpinsByModelGroupMapping[group] ??
    '/assets/img/clearsky/PushpinBlank.svg'
  );
};
