import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ICommerceItemAvailability } from '../../../../../contracts/commerce/icommerce-item-availability';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { IUser } from '../../../../../contracts/user/iuser';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { ProductTypes } from '../../../../../contracts/product/iproduct';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shopping-cart-item-warning',
  styleUrls: ['./shopping-cart-item-warning.component.scss'],
  templateUrl: './shopping-cart-item-warning.component.html',
})
export class ShoppingCartItemWarningComponent implements OnInit {
  @Input() item: ICommerceItemWithCart;
  @Input() availability?: ICommerceItemAvailability;
  @Input() isShoppingCart = false;
  @Input() user: IUser;
  ocids: OcidItems = {};
  productType = ProductTypes;
  private isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    private localization: LocalizationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    // Set localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'buy.out-of-stock-line-level',
        'buy.out-of-stock-line-level-2',
      ])
      .subscribe();
  }

  /**
   * prepopulates JLG form to request a quote
   */
  public requestQuote() {
    if (this.isBrowser) {
      const user = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        companyName: this.user.companyName,
        email: this.user.email,
        country: this.user.homeAddress.country,
        state: this.user.homeAddress.state,
        city: this.user.homeAddress.city,
        form: 'requestQuote',
        comment:
          'interested in purchasing ' +
          this.item.itemDescription +
          ' from OnlineExpress',
      };
      document.cookie =
        'jlgUserInfo=' + JSON.stringify(user) + ';path=/;domain=jlg.com';
      window.open('https://www.jlg.com/en/about-jlg/contact-us', '_blank');
    }
  }
}
