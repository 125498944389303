import { Pipe, PipeTransform } from '@angular/core';
import { OcidItems } from '../contracts/ocid-items';
import { getMachineFieldOcidTranslation } from '../contracts/clearsky/machine/machine.fields';
import { Machine } from '../contracts/clearsky/machine/machine.dto';

@Pipe({ name: 'fieldValueDisplay' })
export class FieldValueDisplayPipe implements PipeTransform {
  transform(
    value: never,
    field: string,
    ocids: OcidItems = {},
    fullData?: Machine
  ): unknown {
    return getMachineFieldOcidTranslation(field, value, ocids, fullData);
  }
}
