import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { EndecaService } from '../endeca.service';
import { OneColumnPageComponent } from '../templates/one-column-page/one-column-page.component';
import { TwoColumnPageComponent } from '../templates/two-column-page/two-column-page.component';
import { PageSlotComponent } from '../templates/page-slot/page-slot.component';
import { ClearskyPageComponent } from '../templates/clearsky-page/clearsky-page.component';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
})
export class TemplateComponent implements OnInit {
  @Input() data;
  @Input('url') payloadUrl;
  @Input() type;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container;
  templateTypes = {
    OneColumnPage: OneColumnPageComponent,
    TwoColumnPage: TwoColumnPageComponent,
    PageSlot: PageSlotComponent,
    ContentSlot: PageSlotComponent,
    ClearSkyPage: ClearskyPageComponent,
  };
  errors: string[] = [];

  constructor(private endecaService: EndecaService) {}

  ngOnInit() {
    this.endecaService.injectTemplate(
      this.container,
      this.data,
      this.payloadUrl,
      this.templateTypes
    );
  }
}
