import { Component, OnInit } from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyService } from '../../../clearsky.service';
import { MxAlert } from '../../../../contracts/clearsky/machine/machine.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StatisticalSlide } from '../../../../contracts/clearsky/dashboard/statistical-slide';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { DtcWidgetDialogComponent } from '../dtc-widget/dtc-widget-dialog/dtc-widget-dialog.component';
import { MachineDialogConfig } from '../../../../contracts/clearsky/machine/machine.dialog.config';
import { CSFilter } from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { OcidItems } from 'app/contracts/ocid-items';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import { combineLatest, Subscription } from 'rxjs';
import { LayoutService } from 'app/service/layout.service';
import { WidgetService } from 'app/clearsky/widget.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-overview-widget',
  templateUrl: './fleet-overview-widget.component.html',
  styleUrls: ['./fleet-overview-widget.component.scss'],
})
export class FleetOverviewWidgetComponent implements OnInit {
  displayName = WidgetsDisplay.fleetOverview;
  ocids: OcidItems = {};
  widgetName = Widgets.FLEET_OVERVIEW;
  carouselData: StatisticalSlide[] = [];
  isMobile$ = this.layoutService.isMobile$;
  isDownloading$ = this.widgetService.downloadPdfStarted$;
  slideConfig = {
    infinite: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  private widgets: string[] = [];
  protected filter = ''; // Disable
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private widgetService: WidgetService
  ) {}

  ngOnInit(): void {
    // Get current widgets
    this.clearskyService.currentWidgets$.subscribe(
      (widgets) => (this.widgets = widgets)
    );

    // Gather data
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.clearskyService.legendRef$,
    ]).subscribe(([page, legend]) => {
      if (!page) {
        return;
      }

      const sources = legend.asrc || [];
      if (page.aggregations && page.aggregations.fleetOverview) {
        // Reset old carousel data
        this.carouselData = [];

        // Push slide info
        this.carouselData.push({
          key: 'DTCSource',
          title: 'Machines with DTCs',
          data: page.aggregations.fleetOverview.mxWithDTCs,
          detailsClick: () => this.slideOutDTCDetail(),
          click: () => this.filterHasDtcs(),
        });

        this.carouselData.push({
          key: 'Low',
          title: `Machines with\nLow Fuel or <span title="${this.ocids['clearsky.soc-definition']}">SOCs</span>`,
          data:
            page.aggregations.fleetOverview.mxWithLowFuel +
            page.aggregations.fleetOverview.mxWithLowSOC,
          click: () => this.filterLowFuelSoc(),
        });

        const getSrc = (src: string) =>
          sources.find((s) => s.desc.toLowerCase().includes(src));

        // Get ID of system fsrc
        const system = getSrc('system');
        this.carouselData.push({
          key: 'sysAlerts',
          title: 'Machines with\nSystem Alerts',
          data: page.aggregations.fleetOverview.mxWithSysAlerts,
          click: () => system && this.filterAlertSource(system.id),
        });

        // Get ID of engine fsrc
        const engine = getSrc('engine');
        this.carouselData.push({
          key: 'engAlerts',
          title: 'Machines with\nEngine Alerts',
          data: page.aggregations.fleetOverview.mxWithEngAlerts,
          click: () => engine && this.filterAlertSource(engine.id),
        });

        // Get ID of telematics fsrc
        const telematics = getSrc('telematics');
        this.carouselData.push({
          key: 'telAlerts',
          title: 'Machines with\nTelematics Alerts',
          data: page.aggregations.fleetOverview.mxWithTeleAlerts,
          click: () => telematics && this.filterAlertSource(telematics.id),
        });
      }
    });

    this.localization
      .getOCIDs(['clearsky.soc-definition', 'global.more-details-label', this.displayName])
      .subscribe((ocids) => {
        this.ocids = ocids;
      });
  }

  /**
   * On slide click, call its callback.
   * @param e
   * @param slide
   */
  onSlideDetailsClick(e: Event, slide: StatisticalSlide): void {
    e.stopPropagation();
    if (!slide.detailsClick) {
      return;
    }
    slide.detailsClick(this);
  }

  /**
   * On slide click, call its callback.
   * @param slide
   */
  onSlideClick(slide: StatisticalSlide): void {
    if (!slide.click) {
      return;
    }
    slide.click(this);
  }

  /**
   * Show detailed DTC slide-out.
   * @private
   */
  private slideOutDTCDetail(): void {
    this.dialog.open(DtcWidgetDialogComponent, { ...MachineDialogConfig });
  }

  /**
   * Filter to low fuel or SOC.
   * @private
   */
  private filterLowFuelSoc(): void {
    this.clearskyService.updateFilter(CSFilter.alerts.key, [
      MxAlert.BAT006,
      MxAlert.ENG003,
    ]);
  }

  /**
   * Filter to certain code source of alert.
   * @param source
   * @private
   */
  private filterAlertSource(source: number): void {
    this.clearskyService.updateFilter(CSFilter.asrc.key, [source]);
  }

  /**
   * Filter to machines that have dtcs.
   * @private
   */
  private filterHasDtcs(): void {
    this.clearskyService.updateFilter(CSFilter.hasDtcs.key, [true]);
  }
}
