import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { IAddGiftListItemsDto } from '../../../../contracts/commerce/dto/iadd-gift-list-items-dto';
import { IGiftList } from '../../../../contracts/commerce/igift-list';
import { IGiftListResponse } from '../../../../contracts/commerce/igift-list-response';
import { IAddItemToGiftList } from '../../../../contracts/product/dto/iadd-item-to-giftlist';
import { ShoppingListsService } from '../../../../endeca/cartridges/account/account-shopping-lists/shopping-lists.service';
import { NotificationService } from '../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  providers: [NotificationService],
})
export class ShoppingListComponent implements OnInit {
  lists: IGiftList[] = [];
  addList = false;
  ocids: {} = {};
  @ViewChild('f') shoppingListForm: NgForm;

  constructor(
    public dialogRef: MatDialogRef<ShoppingListComponent>,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: { items: IAddItemToGiftList[] },
    private shoppingListService: ShoppingListsService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });

    this.localization
      .getOCIDs([
        'account.select-shopping-list',
        'account.new-list-link',
        'account.list-name-label',
        'shopping-list.privacy-info-icon-private',
        'shopping-list.privacy-info-icon-public',
      ])
      .subscribe();

    // Pull in user's shopping lists
    this.shoppingListService
      .getLists()
      .subscribe((lists: IGiftListResponse) => (this.lists = lists.items));
  }

  /**
   * Event emitted when adding item to list.
   *
   * @param {NgForm} form
   */
  onAdd(form: NgForm) {
    this.notificationService.reset();
    if (form.valid) {
      if (this.addList) {
        // Save the list and then add the items to the list.
        this.shoppingListService
          .addList({
            eventName: form.controls.listName.value,
            public: form.controls.public.value,
          })
          .pipe(
            map((list: IGiftList) => {
              // Add all of the items to the list.
              return this.addItemsToList(list.id);
            })
          )
          .subscribe();
      } else {
        // Add items to selected list.
        const id = form.controls.shoppingList.value;
        this.addItemsToList(id);
      }
    }
  }

  /**
   * Adds all of the items to a list.
   *
   * @param {string} id
   */
  addItemsToList(id: string) {
    const itemsToAdd: IAddGiftListItemsDto = { items: [] };
    this.data.items.forEach((item: IAddItemToGiftList) => {
      itemsToAdd.items.push({
        catalogRefIds: item.itemNumber,
        quantity: item.quantity,
        productId: item.itemNumber,
      });
    });
    this.notificationService.reset();
    this.shoppingListService
      .addItems(
        {
          giftlistId: id,
        },
        itemsToAdd
      )
      .subscribe(
        () => {
          this.dialogRef.close(true);
        },
        (error) => {
          this.notificationService.addError(error.error.title);
        }
      );
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
