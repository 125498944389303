import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IPlacardDto } from '../../../contracts/placard/iplacard-dto';

@Injectable()
export class PlacardService {

  constructor(private http: HttpClient) { }

  /**
   * Posts case to Salesforce.
   * @param {IPlacardDto} placardDto
   * @returns {Observable<any>} 
   */
  postCase(placardDto: IPlacardDto) {
    return this.http.post(`${environment.apiUrl}/placard/customerForm`, placardDto);
  }

  /**
   * Lookup serial number
   *
   * @param {object} serialNum
   * @returns {Observable<any>}
   */
  serialNumberLookup(serialNum: object) {
    return this.http.post(`${environment.apiUrl}/placard/pvcLookup`, serialNum);
  }

  /**
   * Lookup vin number
   *
   * @param {string} vinNum
   * @param {number} year
   * @returns {Observable<any>}
   */
  vinNumberLookup(vinNum: string, year: number) {
    return this.http.get(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${vinNum}?format=json&modelyear=${year}`);
  }

}
