import { Component, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator-displaying-info',
  templateUrl: './paginator-displaying-info.component.html',
})
export class PaginatorDisplayingInfoComponent {
  @Input() paginator!: MatPaginator;
}
