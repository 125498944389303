import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClearskyService } from 'app/clearsky/clearsky.service';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';
import { Machine } from 'app/contracts/clearsky/machine/machine.dto';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { CsRequestKeys } from '../../../../../contracts/clearsky/cs-machines-request';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Subscription } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-fleet-age-dialog',
  templateUrl: './fleet-age-dialog.component.html',
})
export class FleetAgeDialogComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<Machine> = new MatTableDataSource<Machine>();
  ocids: OcidItems = {};
  pageSize = 10;
  legend: CSLegend = {};
  private subs: Subscription;

  constructor(
    public dialogRef: MatDialogRef<FleetAgeDialogComponent>,
    private localization: LocalizationService,
    private clearskyService: ClearskyService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.localization
      .getOCIDs([
        'clearsky.brand-label',
        'clearsky.model-label',
        'clearsky.filter-label',
        'clearsky.start-filter-label',
        'csv.machine-info',
        'clearsky.age-label'
      ])
      .subscribe((ocids) => {
        this.ocids = ocids;
      });

    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.fleetAge),
      this.clearskyService.legendRef$,
    ]).subscribe(([data, legend]) => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.data = (data && (data.machines as Machine[])) || [];
      this.legend = legend || {};
    });
  }

  /**
   * Filter list by search term.
   * @param event
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Navigate to asset view.
   * @param machine
   */
  onMachineClicked(machine: Machine): void {
    this.dialogRef.close();
    this.router.navigate(['/clearsky/machine', machine.sn]);
  }
}
