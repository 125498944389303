import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IAddItemsDto } from '../../../../../contracts/commerce/dto/iadd-items-dto';
import { ICommerceItemWithCart } from '../../../../../contracts/commerce/icommerce-item-with-cart';
import { IPhantomItem } from '../../../../../contracts/product/iphantom-item';
import { IProduct } from '../../../../../contracts/product/iproduct';
import { OktaAuthWrapper } from '../../../../../service/auth/okta.auth.wrapper';
import { SignInDialogComponent } from '../../../../../core/header/sign-in-dialog/sign-in-dialog.component';
import { CartService } from '../../../../../service/cart/cart.service';
import { ConfirmationAlertService } from '../../../../../service/confirmation/confirmation-alert.service';
import { ContentService } from '../../../../../service/content.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { ConfirmationDialogsService } from '../../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { ShoppingListComponent } from '../../shopping-list/shopping-list.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-phantom-warning',
  templateUrl: './phantom-warning.component.html',
  providers: [NotificationService],
})
export class PhantomWarningComponent implements OnInit {
  @Input() item: ICommerceItemWithCart;
  @Input() info: IProduct;
  @Output() removed: EventEmitter<ICommerceItemWithCart> =
    new EventEmitter<ICommerceItemWithCart>();
  @Output() added: EventEmitter<any> = new EventEmitter<any>();
  isLoggedIn = false;
  public ocids = {};
  platformBrowser = false;
  gaSource = 'Phantom Items';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public dialog: MatDialog,
    private router: Router,
    private oktaAuth: OktaAuthWrapper,
    private localization: LocalizationService,
    private contentService: ContentService,
    private notificationService: NotificationService,
    private cartService: CartService,
    private alertService: ConfirmationAlertService,
    private zone: NgZone,
    private confirmationService: ConfirmationDialogsService,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Set localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    // Determine if the user is logged in or not.
    this.oktaAuth.isLoggedIn.subscribe(
      (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)
    );
  }

  /**
   * Event emitted when remove button is clicked.
   */
  onRemove() {
    // Show dialog first
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          this.notificationService.reset();
          this.cartService.removeFromCart(this.item, 'Phantom Item').subscribe(
            () => {
              this.removed.emit(this.item);
            },
            (err) => {
              this.notificationService.addError(err.error?.title);
            }
          );
        }
      });
  }

  /**
   * Add all phantom items to cart.
   */
  onAddToCart() {
    this.notificationService.reset();
    const itemsToAdd: IAddItemsDto = {
      items: [],
    };
    const gaItemsToAdd = [];
    this.info.phantomItems.forEach((item: IPhantomItem) => {
      itemsToAdd.items.push({
        catalogRefId: item.componentItemNumber,
        quantity: this.getRecommendedQty(item),
        productId: item.componentItemNumber,
      });
      gaItemsToAdd.push({
        item_id: item.componentItemNumber,
        item_name: item.description ? item.description : '',
        item_list_name: this.gaSource,
        id: item.componentItemNumber,
        name: item.description ? item.description : '',
        quantity: this.getRecommendedQty(item),
      });
    });
    // Add recommended quantity
    this.cartService.addToCart(itemsToAdd).subscribe(
      () => {
        this.added.emit();
        // Send Add to Cart action to GA if the platform is browser
        if (this.platformBrowser) {
          this.ecommService.addAllToCart(gaItemsToAdd, this.gaSource);
        }
      },
      (error) => {
        this.notificationService.addError(error.error.title);

        // Only execute the following if the platform is browser.
        if (this.platformBrowser) {
          this.contentService.scrollUserToTop();
        }
      }
    );
  }

  /**
   * Add all phantom items to the shopping list.
   */
  onAddToShoppingList() {
    if (this.isLoggedIn) {
      this.dialog
        .open(ShoppingListComponent, {
          width: '380px',
          data: {
            items: this.info.phantomItems.map((item: IPhantomItem) => {
              return {
                itemNumber: item.componentItemNumber,
                quantity: this.getRecommendedQty(item),
              };
            }),
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const fbProducts = [];
            // Show alert for each phantom item
            this.info.phantomItems.forEach((item: IPhantomItem) => {
              this.alertService.addToShoppingList(
                item.description ? item.description : item.componentItemNumber
              );
              // Create array of phantom items added for tracking
              fbProducts.push({
                item_id: item.componentItemNumber,
                item_list_name: this.gaSource,
                id: item.componentItemNumber,
                quantity: this.getRecommendedQty(item),
              });
            });
            if (this.platformBrowser) {
              this.ecommService.addAllToWishlist(fbProducts, this.gaSource);
            }
            // Remove parent element from cart.
            this.zone.run(() => {
              this.added.emit();
            });
          }
        });
    } else {
      this.dialog
        .open(SignInDialogComponent, {
          panelClass: ['sign-in-dialog'],
          width: '720px',
        })
        .afterClosed()
        .subscribe((loggedIn) => {
          if (loggedIn) {
            this.router.navigate(['/cart']);
          }
        });
    }
  }

  /**
   * Get recommended qty for each phantom item.
   *
   * @param {IPhantomItem} item
   * @returns {number}
   */
  public getRecommendedQty(item: IPhantomItem) {
    return item.quantity * this.item.quantity;
  }
}
