import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartLegendModule } from './chart-legend/chart-legend.module';
import { PieChartModule } from './pie-chart/pie-chart.module';
import { NoChartMsgModule } from './no-chart-msg/no-chart-msg.module';

@NgModule({
  exports: [ChartLegendModule, PieChartModule, NoChartMsgModule],
  imports: [CommonModule, ChartLegendModule, PieChartModule, NoChartMsgModule],
})
export class ChartModule {}
