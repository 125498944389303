import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IAddress } from '../../../../../contracts/user/iaddress';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shipping-address-dialog',
  templateUrl: './shipping-address-dialog.component.html',
})
export class ShippingAddressDialogComponent implements OnInit {
  ocids: {} = {};

  constructor(
    public dialogRef: MatDialogRef<ShippingAddressDialogComponent>,
    private ngZone: NgZone,
    private localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA)
    public data: { canMakeDefault: boolean; isEMEAUser: boolean }
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['address-book.add-address', 'shipping.add-new-address'])
      .subscribe();
  }

  /**
   * Event listener for adding address.
   *
   * @param address: IAddress
   */
  onAddressAdded(address: IAddress) {
    this.ngZone.run(() => {
      this.dialogRef.close(address);
    });
  }

  /**
   * Event emitted when closing dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }
}
