import { Component, Input, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IOCIDAndValue } from '../../web2case.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ScannerService } from 'app/service/scanner.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-ootb-form',
  templateUrl: './ootb-form.component.html',
})
export class OOTBFormComponent implements OnInit {
  @Input() ootbForm: UntypedFormGroup;
  ocids = {};
  reusableOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.level2.damage',
      value: 'Damage',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.production-damage',
          value: 'Production damage',
        },
        {
          ocid: 'web2case.level3.dents',
          value: 'Dents',
        },
        {
          ocid: 'web2case.level3.tires-damage',
          value: 'Tires damage',
        },
      ],
    },
    {
      ocid: 'web2case.level2.misalignment',
      value: 'Misalignment',
    },
    {
      ocid: 'web2case.level2.corrosion',
      value: 'Corrosion',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.pins',
          value: 'Pins',
        },
        {
          ocid: 'web2case.level3.hydrofittings',
          value: 'Hydrofittings',
        },
        {
          ocid: 'web2case.level3.rivets',
          value: 'Rivets',
        },
      ],
    },
    {
      ocid: 'web2case.level2.paint',
      value: 'Paint',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.scratch',
          value: 'Scratch',
        },
        {
          ocid: 'web2case.level3.fading',
          value: 'Fading',
        },
        {
          ocid: 'web2case.level3.flaking',
          value: 'Flaking',
        },
        {
          ocid: 'web2case.level3.wrong-color',
          value: 'Wrong color',
        },
        {
          ocid: 'web2case.level3.bad-touch-up',
          value: 'Bad touch-up',
        },
      ],
    },
    {
      ocid: 'web2case.level2.production-issue',
      value: 'Production issue',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.missing-welds',
          value: 'Missing welds',
        },
        {
          ocid: 'web2case.level3.bad-welds',
          value: 'Bad welds',
        },
        {
          ocid: 'web2case.level3.missing-parts',
          value: 'Missing parts',
        },
        {
          ocid: 'web2case.level3.wrong-parts',
          value: 'Wrong parts',
        },
        {
          ocid: 'web2case.level3.loose-parts',
          value: 'Loose parts',
        },
      ],
    },
    {
      ocid: 'web2case.level2.decals',
      value: 'Decals',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.missing',
          value: 'Missing',
        },
        {
          ocid: 'web2case.level3.incorrect',
          value: 'Incorrect',
        },
      ],
    },
    {
      ocid: 'web2case.level2.electrical',
      value: 'Electrical',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.not-working',
          value: 'Not working',
        },
        {
          ocid: 'web2case.level3.wrong-connection',
          value: 'Wrong connection',
        },
        {
          ocid: 'web2case.level2.misalignment',
          value: 'Misalignment',
        },
        {
          ocid: 'web2case.level3.loose',
          value: 'Loose',
        },
        {
          ocid: 'web2case.level3.error-message',
          value: 'Error message',
        },
        {
          ocid: 'web2case.level3.control-box-not-working',
          value: 'Control box not working',
        },
        {
          ocid: 'web2case.level3.switch-not-working',
          value: 'Switch not working',
        },
        {
          ocid: 'web2case.level3.sensor-defects',
          value: 'Sensor defects',
        },
        {
          ocid: 'web2case.level3.sensor-calibration',
          value: 'Sensor calibration',
        },
        {
          ocid: 'web2case.level3.sensor-mounted-wrong',
          value: 'Sensor mounted wrong',
        },
        {
          ocid: 'web2case.level3.loose-sensor',
          value: 'Loose sensor',
        },
        {
          ocid: 'web2case.level3.damaged-sensor',
          value: 'Damaged sensor',
        },
      ],
    },
    {
      ocid: 'web2case.level2.routing',
      value: 'Routing',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.too-tight',
          value: 'Too tight',
        },
        {
          ocid: 'web2case.level3.too-loose',
          value: 'Too loose',
        },
        {
          ocid: 'web2case.level3.need-tying-up',
          value: 'Need tying up',
        },
        {
          ocid: 'web2case.level3.wrong-routing',
          value: 'Wrong routing',
        },
      ],
    },
    {
      ocid: 'web2case.level2.liquids-oils',
      value: 'Liquids and oils',
      nextLevelOpts: [
        {
          ocid: 'web2case.level3.valve',
          value: 'Valve',
        },
        {
          ocid: 'web2case.level3.fitting',
          value: 'Fitting',
        },
        {
          ocid: 'web2case.level3.hub',
          value: 'Hub',
        },
      ],
    },
  ];
  causeOpts: IOCIDAndValue[] = [
    {
      ocid: 'web2case.level1.machine-setup',
      value: 'Machine setup',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.ce',
          value: 'CE',
        },
        {
          ocid: 'web2case.level2.software-version',
          value: 'Software version',
        },
        {
          ocid: 'web2case.level2.parameters',
          value: 'Parameters',
        },
      ],
    },
    {
      ocid: 'web2case.level1.functions-controls',
      value: 'Functions and controls',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.cutouts',
          value: 'Cutouts',
        },
      ],
    },
    {
      ocid: 'web2case.level1.manuals',
      value: 'Manuals',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.machine-manual',
          value: 'Machine manual',
        },
        {
          ocid: 'web2case.level2.ce',
          value: 'CE',
        },
        {
          ocid: 'web2case.level2.lss',
          value: 'LSS',
        },
        {
          ocid: 'web2case.level2.safety-manual',
          value: 'Safety manual',
        },
        {
          ocid: 'web2case.level2.new-inspection',
          value: 'New machine inspection',
        },
        {
          ocid: 'web2case.level2.warranty-document',
          value: 'Warranty document',
        },
      ],
    },
    {
      ocid: 'web2case.level1.serial-plate',
      value: 'Serial plate',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.incorrect-info',
          value: 'Incorrect info',
        },
        {
          ocid: 'web2case.level2.missing',
          value: 'missing',
        },
        {
          ocid: 'web2case.level2.not-readable',
          value: 'Not readable',
        },
        {
          ocid: 'web2case.level2.wrong-placement',
          value: 'Wrong placement',
        },
        {
          ocid: 'web2case.level2.incorrect-printed',
          value: 'Incorrect printed',
        },
        {
          ocid: 'web2case.level2.ansiplate',
          value: 'ANSI plate',
        },
      ],
    },
    {
      ocid: 'web2case.level1.tank',
      value: 'Tank',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.fuel-level',
          value: 'Fuel level',
        },
        {
          ocid: 'web2case.level2.hydraulic-level',
          value: 'Hydraulic oil level',
        },
        {
          ocid: 'web2case.level2.transmission-level',
          value: 'Transmission level',
        },
      ],
    },
    {
      ocid: 'web2case.level1.power-system',
      value: 'Power system',
      nextLevelOpts: [
        {
          ocid: 'web2case.level2.ventilator',
          value: 'Ventilator',
          nextLevelOpts: [
            {
              ocid: 'web2case.level1.other',
              value: 'Other',
            },
          ],
        },
        {
          ocid: 'web2case.level2.drive-pump',
          value: 'Drive pump',
          nextLevelOpts: [
            {
              ocid: 'web2case.level3.leak',
              value: 'Leak',
            },
            {
              ocid: 'web2case.level3.inoperative',
              value: 'Inoperative',
            },
            {
              ocid: 'web2case.level1.other',
              value: 'Other',
            },
          ],
        },
        {
          ocid: 'web2case.level2.generator',
          value: 'Generator',
          nextLevelOpts: [
            {
              ocid: 'web2case.level3.inoperative',
              value: 'Inoperative',
            },
            {
              ocid: 'web2case.level3.wrong-connection',
              value: 'Wrong connection',
            },
            {
              ocid: 'web2case.level3.loose',
              value: 'Loose',
            },
          ],
        },
        {
          ocid: 'web2case.level2.engine',
          value: 'Engine',
          nextLevelOpts: [
            {
              ocid: 'web2case.level3.leak',
              value: 'Leak',
            },
            {
              ocid: 'web2case.level3.low-performance',
              value: 'Low performance',
            },
            {
              ocid: 'web2case.level3.not-running-normal',
              value: 'Not running normal',
            },
            {
              ocid: 'web2case.level3.unexpected-smoke',
              value: 'Unexpected smoke',
            },
            {
              ocid: 'web2case.level3.coolant-level',
              value: 'Coolant level',
            },
            {
              ocid: 'web2case.level3.engine-oil-level',
              value: 'Engine oil level',
            },
            {
              ocid: 'web2case.level3.error-message',
              value: 'Error message',
            },
            {
              ocid: 'web2case.rpm-settings',
              value: 'RPM settings',
            },
          ],
        },
      ],
    },
    {
      ocid: 'web2case.level1.under-hood',
      value: 'Under hood/cover',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.platform',
      value: 'Platform',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.chassis',
      value: 'Chassis',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.axels-wheels',
      value: 'Axles and wheels/tracks',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.scissor-arms',
      value: 'Scissor arms',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.boom',
      value: 'Boom',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.turntable',
      value: 'Turntable',
      nextLevelOpts: this.reusableOpts,
    },
    {
      ocid: 'web2case.level1.other',
      value: 'Other',
    },
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private formBuilder: UntypedFormBuilder,
    private localizationService: LocalizationService,
    private scanner: ScannerService
  ) {}

  ngOnInit() {
    this.ootbForm.addControl(
      'MachineSerial',
      this.formBuilder.control('', Validators.required)
    );
    this.ootbForm.addControl('FleetNumber', this.formBuilder.control(''));
    this.ootbForm.addControl(
      'RunningHours',
      this.formBuilder.control('', Validators.required)
    );
    this.ootbForm.addControl(
      'IssueAreaLevel1',
      this.formBuilder.control('', Validators.required)
    );
    this.ootbForm.addControl('IssueAreaLevel2', this.formBuilder.control(''));
    this.ootbForm.addControl('IssueAreaLevel3', this.formBuilder.control(''));
    this.ootbForm.addControl(
      'CaseDescription',
      this.formBuilder.control('', Validators.required)
    );
    this.ootbForm.addControl('uploadForm', new UntypedFormGroup({}));
    this.localizationService
      .getOCIDs([
        'web2case.level1.machine-setup',
        'web2case.level1.functions-controls',
        'web2case.level1.manuals',
        'web2case.level1.serial-plate',
        'web2case.level1.tank',
        'web2case.level1.power-system',
        'web2case.level1.under-hood',
        'web2case.level1.platform',
        'web2case.level1.chassis',
        'web2case.level1.axels-wheels',
        'web2case.level1.scissor-arms',
        'web2case.level1.boom',
        'web2case.level1.turntable',
        'web2case.level1.other',
        'web2case.level2.ce',
        'web2case.level2.software-version',
        'web2case.level2.parameters',
        'web2case.level2.cutouts',
        'web2case.level2.machine-manual',
        'web2case.level2.lss',
        'web2case.level2.safety-manual',
        'web2case.level2.new-inspection',
        'web2case.level2.warranty-document',
        'web2case.level2.incorrect-info',
        'web2case.level2.missing',
        'web2case.level2.not-readable',
        'web2case.level2.wrong-placement',
        'web2case.level2.incorrect-printed',
        'web2case.level2.ansiplate',
        'web2case.level2.fuel-level',
        'web2case.level2.hydraulic-level',
        'web2case.level2.transmission-level',
        'web2case.level2.ventilator',
        'web2case.level2.drive-pump',
        'web2case.level2.generator',
        'web2case.level2.engine',
        'web2case.level2.damage',
        'web2case.level2.misalignment',
        'web2case.level2.corrosion',
        'web2case.level2.paint',
        'web2case.level2.production-issue',
        'web2case.level2.decals',
        'web2case.level2.electrical',
        'web2case.level2.routing',
        'web2case.level2.liquids-oils',
        'web2case.level3.leak',
        'web2case.level3.inoperative',
        'web2case.level3.wrong-connection',
        'web2case.level3.loose',
        'web2case.level3.low-performance',
        'web2case.level3.not-running-normal',
        'web2case.level3.unexpected-smoke',
        'web2case.level3.coolant-level',
        'web2case.level3.engine-oil-level',
        'web2case.level3.error-message',
        'web2case.level3.production-damage',
        'web2case.level3.dents',
        'web2case.level3.tires-damage',
        'web2case.level3.pins',
        'web2case.level3.hydrofittings',
        'web2case.level3.rivets',
        'web2case.level3.scratch',
        'web2case.level3.fading',
        'web2case.level3.flaking',
        'web2case.level3.wrong-color',
        'web2case.level3.bad-touch-up',
        'web2case.level3.missing-welds',
        'web2case.level3.bad-welds',
        'web2case.level3.missing-parts',
        'web2case.level3.wrong-parts',
        'web2case.level3.loose-parts',
        'web2case.level3.incorrect',
        'web2case.level3.not-working',
        'web2case.level3.control-box-not-working',
        'web2case.level3.switch-not-working',
        'web2case.level3.sensor-defects',
        'web2case.level3.sensor-calibration',
        'web2case.level3.sensor-mounted-wrong',
        'web2case.level3.loose-sensor',
        'web2case.level3.damaged-sensor',
        'web2case.level3.too-tight',
        'web2case.level3.too-loose',
        'web2case.level3.need-tying-up',
        'web2case.level3.wrong-routing',
        'web2case.level3.valve',
        'web2case.level3.fitting',
        'web2case.level3.hub',
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Gets the secondary level dropdown options based on the primary cause value.
   * @returns {IOCIDAndValue[]}
   */
  get secondaryOpts() {
    const primaryCauseValue: string =
      this.ootbForm.get('IssueAreaLevel1').value;
    if (primaryCauseValue) {
      const secondaryOpts: IOCIDAndValue = this.causeOpts.find(
        (opt: IOCIDAndValue) => {
          return opt.value === primaryCauseValue;
        }
      );
      return secondaryOpts ? secondaryOpts.nextLevelOpts : [];
    }
    return [];
  }

  /**
   * Gets the terciary level dropdown options based on the secpmdary cause value.
   * @returns {IOCIDAndValue[]}
   */
  get aspectOpts() {
    const secondaryCauseValue: string =
      this.ootbForm.get('IssueAreaLevel2').value;
    if (secondaryCauseValue) {
      if (this.secondaryOpts) {
        const aspectOpts: IOCIDAndValue = this.secondaryOpts.find(
          (opt: IOCIDAndValue) => {
            return opt.value === secondaryCauseValue;
          }
        );
        return aspectOpts ? aspectOpts.nextLevelOpts : [];
      }
    }
    return [];
  }

  /**
   * Toggles form fields based on the aspect dropdown value.
   * @param {MatSelectChange} event
   */
  aspectChanged() {
    if (this.ootbForm.get('IssueAreaLevel3').value === 'RPM settings') {
      if (!this.ootbForm.get('IssueAreaLevel4')) {
        this.ootbForm.addControl(
          'IssueAreaLevel4',
          this.formBuilder.control('', [Validators.required])
        );
      }
    } else {
      this.ootbForm.removeControl('IssueAreaLevel4');
    }
  }

  /**
   * Gets the upload form.
   * @returns {FormGroup}
   */
  get uploadForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.ootbForm.get('uploadForm');
  }
  /**
   * Opens barcode scanner
   */
  async openScanner() {

    (await this.scanner.scanSerialNum('Web2Case - Out of the Box')).subscribe((data?) => {
      if (data) {
        this.ootbForm.get('MachineSerial').setValue(data);
      }
    });

  }
}
