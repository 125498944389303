import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { IManagedUser } from '../../../../../contracts/user/imanaged-user';
import { INavMenu } from '../../../../../contracts/user/inav-menu';
import { ManageUsersService } from '../manage-users.service';
import { NotificationService } from '../../../../../service/notification/notification.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-edit-permission',
  templateUrl: './edit-permission.component.html',
})
export class EditPermissionComponent implements OnInit {
  id: number;
  ocids: {} = {};
  selectedUserRoles: string[] = [];

  @ViewChild('editPermissionsForm') editPermissionForm: NgForm;

  constructor(
    public dialogRef: MatDialogRef<EditPermissionComponent>,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private manageUserService: ManageUsersService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: IManagedUser;
      userRoles: INavMenu;
    }
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.data.user.allRoles.forEach((role) => {
      // Exclude CSRProxy from the list since it is only manageable in BCC.
      if (role.name !== 'CSRProxy') {
        this.selectedUserRoles.push(role.name);
      }
    });
  }

  /**
   * Toggles what user roles are selected to be changed.
   *
   * @param event any
   * @param userRole string
   */
  toggleUserRoles(event, userRole: string) {
    if (event.checked) {
      this.selectedUserRoles.push(userRole);
    } else {
      this.selectedUserRoles.splice(
        this.selectedUserRoles.indexOf(userRole),
        1
      );
    }
  }

  /**
   * Change the selected user's roles.
   *
   * @param form NgForm
   */
  onUpdate(form: NgForm) {
    this.notificationService.reset();
    if (form.valid) {
      this.manageUserService
        .updateUserRoles({
          login: this.data.user.login,
          roles: this.selectedUserRoles,
        })
        .subscribe(
          () => {
            this.closeDialog(true);
          },
          (error) => {
            console.log(error);
            this.notificationService.addError(error.error.title);
          }
        );
    }
  }

  /**
   * Closes the dialog and returns a boolean flag that defines whether a user's roles were changed or not.
   *
   * @param updateStatus boolean
   */
  closeDialog(updateStatus: boolean) {
    this.dialogRef.close(updateStatus);
  }
}
