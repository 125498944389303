import { MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { IUser } from "../../contracts/user/iuser";
import { CurrentUserService } from "../../service/user/current-user.service";

// Create the localization factory.
let localizationValueFactory = (userService: CurrentUserService) => {
  // Get the current user.
  const user: IUser = userService._userSubject.getValue();
  if (user) {
    // Get the default date format for the user.
    const dateFormat = user.appConfig.dateFormat.toLowerCase();
    // Determine which date format to use for the material date picker.
    if (dateFormat === 'dd/mm/yyyy') {
      return APP_DATE_FORMAT_1;
    } else if (dateFormat === 'mm/dd/yyyy') {
      return APP_DATE_FORMAT_2;
    }
    return APP_DATE_FORMAT_3;
  }
};
// Create the localization value provider.
export let localizationValueProvider = {
  provide: MAT_DATE_FORMATS,
  useFactory: localizationValueFactory,
  deps: [CurrentUserService]
};

// Application date format for users with default date dd/mm/yyyy.
const APP_DATE_FORMAT_1: MatDateFormats = {
  parse: {
    dateInput: 'dd/mm/yyyy'
  },
  display: {
    dateInput: 'dd/mm/yyyy',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY'
  }
};
// Application date format for users with default date mm/dd/yyyy.
let APP_DATE_FORMAT_2: MatDateFormats = {
  parse: {
    dateInput: 'mm/dd/yyyy'
  },
  display: {
    dateInput: 'mm/dd/yyyy',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MM YYYY'
  }
};
// Application date format for users with default date yyyy/mm/dd.
let APP_DATE_FORMAT_3: MatDateFormats = {
  parse: {
    dateInput: 'yyyy/mm/dd'
  },
  display: {
    dateInput: 'yyyy/mm/dd',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'MM YYYY'
  }
};
