import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { CartService } from '../../../service/cart/cart.service';
import { IAddItemsDto } from '../../../contracts/commerce/dto/iadd-items-dto';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-interactive-manuals',
  templateUrl: './interactive-manuals.component.html',
})
export class InteractiveManualsComponent implements OnInit, CartridgeInterface {
  ocids = {};
  @Input() data: {
    iframeSrcUrl: string;
    iframeFrameBorder: string;
    iframeHeight: string;
    '@error': string;
  };
  iframeUrlSanitized: SafeResourceUrl;
  platformBrowser = false;

  @HostListener('window:message', ['$event'])
  onMessage(e) {
    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      const product = e.data;
      if (
        (e.origin.endsWith('jlg.com') ||
          e.origin.endsWith('jerrdan.com') ||
          e.origin.endsWith('smartequip.net')) &&
        product.type === 'addToCart'
      ) {
        const productList = e.origin.endsWith('smartequip.net')
          ? 'SmartEquip'
          : 'Interactive Manuals';
        const itemsToAdd: IAddItemsDto = {
          items: [],
        };
        const gaItemsToAdd = [];
        for (let n = 0; n < product.items.length; n++) {
          const item = product.items[n];
          itemsToAdd.items.push({
            catalogRefId: item.itemNum,
            quantity: item.qty,
            productId: item.itemNum,
          });
          gaItemsToAdd.push({
            item_id: item.itemNum,
            item_name: '',
            item_list_name: productList,
            id: item.itemNum,
            quantity: item.qty,
          });
        }
        this.cartService.addToCart(itemsToAdd).subscribe(
          () => {
            // Send Add to Cart action to GA if the platform is browser
            if (this.platformBrowser) {
              this.ecommService.addAllToCart(gaItemsToAdd, productList);
            }
          },
          (error) => {
            this.confirmationService.confirm(
              '',
              this.ocids['equipment-information-iframe.invalid-item-message'],
              '',
              this.ocids['global.confirm']
            );
          }
        );
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private sanitizer: DomSanitizer,
    private cartService: CartService,
    private confirmationService: ConfirmationDialogsService,
    private localization: LocalizationService,
    private ecommService: EcommerceService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.iframeUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.iframeSrcUrl
    );
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['equipment-information-iframe.invalid-item-message'])
      .subscribe();
  }
}