import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthWrapper } from '../../../service/auth/okta.auth.wrapper';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { MenuService } from '../../../service/user/menu.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { OcidItems } from '../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-se-redirect',
  templateUrl: './se-redirect.component.html',
})
export class SERedirectComponent implements OnInit, OnDestroy {
  ocids: OcidItems = {};
  sessionToken = '';
  returnUrl = null;
  imageUrl = '/assets/img/404.jpg';
  resolving = true;
  errorReturned = false;
  errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localization: LocalizationService,
    private oktaAuth: OktaAuthWrapper,
    private menuService: MenuService,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
      const sourcePath = this.route.snapshot.url[0].toString();
      this.errorMessage = sourcePath.includes('cs-redirect')
        ? this.ocids['clearsky.login-logout-ole-label']
        : this.ocids['smart-equip.error-message'];
    });

    this.localization
      .getOCIDs([
        'smart-equip.error-logging',
        'smart-equip.error-message',
        'clearsky.login-logout-ole-label',
      ])
      .subscribe();

    // Grab the session token from the URL.
    this.sessionToken = this.route.snapshot.url[1].toString();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;

    // Login from smart equip.
    this.oktaAuth.ssoAtgLoginOrLogout(this.sessionToken, true).subscribe({
      next: () => {
        this.resolving = false;
        // Get menus as user is logged in or else menus won't load.
        this.menuService.getMenus().pipe(distinctUntilChanged()).subscribe();
        this.changeDetector.detectChanges();
        // Won't navigate home and show page changes unless run in ngZone.
        this.ngZone.run(() => {
          this.router.navigate([this.returnUrl ? this.returnUrl : '/']);
        });
      },
      error: () => {
        this.resolving = false;
        this.errorReturned = true;
        this.changeDetector.detectChanges();
      },
    });
  }

  /**
   * Unsubscribe to any subscriptions and detach from change detector.
   */
  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }
}
