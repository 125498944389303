import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  ApplicationRef,
  Inject,
  Injectable,
  Injector,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import {
  BehaviorSubject,
  first,
  forkJoin,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ISSOLoginDto } from '../../contracts/auth/dto/isso-login-dto';
import { ISSOLogin } from '../../contracts/auth/isso-login';
import { IUser } from '../../contracts/user/iuser';
import { CurrentUserService } from '../user/current-user.service';
import { MenuService } from '../user/menu.service';
import { UserAuth } from '../../shared/user.auth.model';
import {
  AuthErrorCode,
  RequireType,
  UserProfile,
} from '../../shared/user.profile.model';
import { CartService } from '../cart/cart.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProfileCompletionDialogComponent } from 'app/core/header/auth-container/profile-completion-dialog/profile-completion-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { PendingAccountDialogComponent } from '../../core/header/auth-container/pending-account-dialog/pending-account-dialog.component';
import { SuspendedAccountDialogComponent } from '../../core/header/auth-container/suspended-account-dialog/suspended-account-dialog.component';

@Injectable()
export class OktaAuthWrapper {
  // Of type OktaAuth, don't want to set the type because it requires the import of OktaAuth.
  public authClient = null;
  // Must be initiated as false, otherwise the homepage will not correctly update when the user
  // logs in or out. This logic lives here: EndecaPayloadComponent.
  private isLoggedInSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isLoggedIn: Observable<boolean> = this.isLoggedInSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  public isLoggedInValue: boolean | undefined;
  public returnUrl = '';
  public sessionExpired = false;
  private envLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly envLoaded$: Observable<boolean> = this.envLoaded.asObservable();
  public clearPayloadCache = false;
  private platformBrowser = isPlatformBrowser(this.platformId);
  billToShipToDialog = true;

  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private ngZone: NgZone,
    private cartService: CartService,
    private router: Router,
    private http: HttpClient,
    private ref: ApplicationRef,
    private currentUserService: CurrentUserService,
    private menuService: MenuService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService
  ) {}

  /**
   * Log a user into the application.
   *
   * @param {string} email
   * @param {string} password
   * @returns {Observable<IUser>}
   */
  login(email: string, password: string): Observable<IUser> {
    return this.http
      .post<UserProfile>(`${environment.apiUrl}/currentUser/oleLogin`, {
        login: email,
        password: password,
        clientId: environment.CLIENT_ID,
      })
      .pipe(
        switchMap((data: any) => {
          if (data?.['o:errorCode']) {
            return throwError(() => data);
          }
          // Check if data.require is 'billTo or shipTo'
          if (
            (data.require === RequireType.billTo ||
              data.require === RequireType.shipTo) &&
            this.billToShipToDialog
          ) {
            this.billToShipToDialog = false;
            // Open the dialog and return an observable that resolves when the dialog is closed
            return this.dialog
              .open(ProfileCompletionDialogComponent, {
                width: '100%',
                maxWidth: '560px',
                disableClose: true,
                data: {
                  firstName: data.firstName,
                  userType: data.userType,
                },
              })
              .afterClosed()
              .pipe(
                switchMap(() => {
                  // Set token otherwise
                  return this.getIdToken(data.session_token);
                })
              );
          } else {
            // If data.require is not 'billTo', continue without opening the dialog
            if (data['o:errorCode'] === AuthErrorCode.LOCKED) {
              return throwError(() => data);
            }

            // Set token otherwise
            return this.getIdToken(data.session_token);
          }
        }),
        switchMap(() => {
          return this.currentUserService.getUser();
        }),
        map((user: IUser) => {
          this.setLoggedIn(true);
          return user;
        }),
        catchError((error) => {
          console.log('error when logging in: ', error);
          return throwError(() => (error.error ? error.error : error));
        })
      );
  }

  /**
   * Check if user is authenticated.
   *
   * @returns {Observable<ISSOLogin | boolean>}
   */
  isAuthenticated(): Observable<ISSOLogin | boolean> {
    // Get SID. if not set, user is not logged in
    const sid = this.cookieService.get(environment.SESSION_ID_NAME);

    if (!sid) {
      return this.isLoggedInValue === undefined
        ? this.logout().pipe(map(() => false))
        : of(false);
    }

    return this.ssoAtgLoginOrLogout(sid).pipe(
      map((user) => {
        return user ? user : false;
      })
    );
  }

  /**
   * Try to refresh current set SID.
   * @private
   */
  private refreshSession(): Observable<ISSOLogin | null> {
    return this.http
      .post<ISSOLogin | { status: string }>(
        `${environment.apiUrl}/currentUser/refreshLogin`,
        {
          clientId: environment.CLIENT_ID,
        },
        {
          headers: new HttpHeaders({
            accept: 'application/json',
            'Content-Type': 'application/json',
          }),
          withCredentials: true,
        }
      )
      .pipe(
        mergeMap((res) => {
          if ('status' in res && res.status === '403') {
            // Token mismatch from okta and ATG
            return this.logout().pipe(map(() => null));
          }

          return of(res as ISSOLogin);
        })
      );
  }

  /**
   * Atg login and set logged in/logout.
   *
   * @param sessionToken
   * @param syncStatus
   * @private
   */
  ssoAtgLoginOrLogout(
    sessionToken: string,
    syncStatus = false
  ): Observable<ISSOLogin | null> {
    const sid = this.cookieService.get(environment.SESSION_ID_NAME);

    return this.ssoATGLogin(sessionToken, syncStatus).pipe(
      mergeMap((user: ISSOLogin) => {
        this.setInitialLoggedIn(!!user.login);

        // Logout user if sid is expired or try to refresh their token
        if (user.sessionExpired) {
          return sid
            ? this.refreshSession().pipe(
                catchError(() => this.logout().pipe(map(() => null)))
              )
            : this.logout().pipe(map(() => null));
        }

        return of(user);
      }),
      catchError((err) => {
        // Do we need to show the pending modal?
        if (err.error['o:errorCode'] === AuthErrorCode.PENDING) {
          this.dialog
            .open(PendingAccountDialogComponent)
            .afterClosed()
            .subscribe(() => {
              this.router.navigate(['/'], {
                onSameUrlNavigation: 'reload',
              });
            });
        }

        // Do we need to show the suspended modal?
        if (err.error['o:errorCode'] === AuthErrorCode.SUSPENDED_CUSTOMER) {
          this.dialog.open(SuspendedAccountDialogComponent);
        }

        // Try to refresh it if access token is available, otherwise logout
        return sid
          ? this.refreshSession().pipe(
              catchError(() => this.logout().pipe(map(() => null)))
            )
          : this.logout().pipe(map(() => null));
      })
    );
  }

  /**
   * Set initial subject value for isLoggedIn.
   *
   * @param {boolean} isLoggedIn
   */
  protected setInitialLoggedIn(isLoggedIn: boolean): void {
    // Needs to be set because logout will set the value to false
    this.isLoggedInSubject.next(isLoggedIn);
  }

  /**
   * Set token for session.
   *
   * @param {string} sessionToken
   * @returns {Observable<string>}
   */
  getIdToken(sessionToken: string): Observable<unknown> {
    // TODO: METHOD GOING AWAY
    return from(
      this.authClient.token.getWithoutPrompt({
        responseType: ['id_token', 'token'],
        sessionToken: sessionToken,
      })
    ).pipe(
      map((tokens: any) => {
        if (tokens.tokens.idToken) {
          this.authClient.tokenManager.add(
            'my_id_token',
            tokens.tokens.idToken
          );
        }

        this.ref.tick();
        // Only returns token object of the idToken
        return tokens.tokens.idToken;
      }),
      catchError((err) => {
        console.error('Error in getWithoutPrompt: ', err);
        this.ref.tick();
        return throwError(() => err);
      })
    );
  }

  /**
   * Check if a session exists and sso the user.
   */
  ssoSessionCheck(): Observable<ISSOLogin | boolean> {
    // Sign the user into ATG with the SID from Digital ID
    return forkJoin([this.isAuthenticated(), this.menuService.getMenus()]).pipe(
      mergeMap((response) => {
        const user = response[0] ? (response[0] as ISSOLogin) : false;

        // Check if data.require is 'billTo or shipTo'
        if (
          user &&
          (user.require === RequireType.billTo ||
            user.require === RequireType.shipTo) &&
          this.billToShipToDialog
        ) {
          this.billToShipToDialog = false;

          // Open the dialog and return an observable that resolves when the dialog is closed
          this.dialog
            .open(ProfileCompletionDialogComponent, {
              width: '100%',
              maxWidth: '560px',
              disableClose: true,
              data: {
                firstName: user.firstName,
                userType: user.userType,
              },
            })
            .afterClosed()
            .subscribe(() => {
              this.router.navigate(['/'], { onSameUrlNavigation: 'reload' });
            });
        }

        // Return the ssoLogin response.
        return of(user);
      }),
      catchError((error) => throwError(error))
    );
  }

  /**
   * Log into ATG.
   *
   * @returns {Observable<ISSOLogin>}
   */
  ssoATGLogin(token: string, syncStatus?: boolean): Observable<ISSOLogin> {
    const httpOptions = {
      headers: new HttpHeaders({
        accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
    };

    const postDto: ISSOLoginDto = {
      clientId: environment.CLIENT_ID,
    };

    if (syncStatus) {
      postDto.syncStatus = 'y';
    }

    return this.http.post<ISSOLogin>(
      `${environment.apiUrl}/currentUser/ssoLogin`,
      postDto,
      httpOptions
    );
  }

  /**
   * Logout of application.
   *
   * @returns {Observable<UserAuth>}
   */
  logout(): Observable<UserAuth> {
    return this.http
      .post<UserAuth>(`${environment.apiUrl}/currentUser/logout`, {})
      .pipe(
        tap(() => {
          this.cookieService.delete(
            environment.SESSION_ID_NAME,
            '/',
            environment.jlgStyling ? '.jlg.com' : '.jerrdan.com',
            true,
            'None'
          );
          this.setLoggedIn(false);
        })
      );
  }

  /**
   * Save session time out to local storage.
   * If session storage does not exist it returns undefined.
   * Only use this method once you are authenticated to prevent an error.
   * @returns {string} Timestamp in iso format.
   */
  getExpiredSessionTime(): string | null {
    const localSession = localStorage.getItem('session_expires_at');
    this.authClient.session.get().then(this.getExipredSessionTimeCallback);
    return localSession;
  }

  refreshSessionToStaySignedIn(): string | null {
    const localSession = localStorage.getItem('session_expires_at');
    this.authClient.session.refresh().then(this.getExipredSessionTimeCallback);
    return localSession;
  }

  getExipredSessionTimeCallback = (session) => {
    if (
      session === 'Invalid Date' ||
      session === null ||
      session === undefined
    ) {
      localStorage.setItem('session_expires_at', 'Not Authenticated');
    } else {
      localStorage.setItem(
        'session_expires_at',
        new Date(session.expiresAt).toString()
      );
      this.currentUserService.setStayLoggedInDialgState(false);
    }
  };

  /**
   * Emit logged in value.
   *
   * @param {boolean} loggedIn
   */
  private setLoggedIn(loggedIn: boolean) {
    if (this.isLoggedInValue !== loggedIn) {
      // We need to track this so endeca doesn't rebuild twice
      this.isLoggedInValue = loggedIn;
      this.isLoggedInSubject.next(loggedIn);
    }
  }

  logOutAndResetOle(clearShoppingCart: boolean): Observable<void> {
    return this.ngZone.run(() => {
      return this.logout().pipe(
        first(),
        mergeMap(() => {
          if (this.platformBrowser) {
            // remove any open chat instances on logout
            const chatBox = document.getElementById(
              'cisco_bubble_chat'
            ) as HTMLElement;
            if (chatBox) {
              chatBox.parentNode?.removeChild(chatBox);
            }
          }
          // Set the cart back to zero clearCart equals true.
          if (clearShoppingCart) {
            this.cartService.cartPreviewSubject.next({});
          }

          return this.currentUserService.getUser().pipe(
            first(),
            mergeMap(() => of(void 0))
          );
        })
      );
    });
  }
}
