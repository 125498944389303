import { Component } from '@angular/core';

@Component({
  selector: 'app-no-chart-msg',
  templateUrl: './no-chart-msg.component.html',
  styleUrls: ['./no-chart-msg.component.scss']
})
export class NoChartMsgComponent {

}
