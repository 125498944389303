import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertBannerComponent } from './alert-banner.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [AlertBannerComponent],
  exports: [AlertBannerComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class AlertBannerModule {}
