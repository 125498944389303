import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import {
  IOrder,
  PaymentGroups,
} from '../../../../../contracts/commerce/iorder';
import { IUser } from '../../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../../service/user/current-user.service';
import { ICreditCard } from '../../../../../contracts/user/icredit-card';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { OcidItems } from '../../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-payment-form-review]',
  templateUrl: './payment-form-review.component.html',
})
export class PaymentFormReviewComponent implements OnInit {
  @Input() cart!: IOrder;
  @Input() selectedCC!: ICreditCard;
  @Output() toggleForm: EventEmitter<any> = new EventEmitter<any>();
  public user!: IUser;
  ocids: OcidItems = {};
  paymentGroups = PaymentGroups;

  constructor(
    private localization: LocalizationService,
    private userService: CurrentUserService
  ) {}

  ngOnInit() {
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'payment.cc-expiration',
        'invoice-information.payment-method-label',
      ])
      .pipe(first())
      .subscribe();

    // Subscribe to user to get billing address
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
      }
    });
  }

  /**
   * Toggle form to edit mode.
   */
  onToggleForm() {
    this.toggleForm.emit();
  }

  /**
   * Get payment group.
   *
   * @returns {IPaymentGroup}
   */
  get paymentGroup() {
    if (this.cart.paymentGroups.items) {
      return this.cart.paymentGroups.items[0];
    }
  }
}
