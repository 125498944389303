import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IApplyShippingInfoDto } from "../../contracts/commerce/dto/iapply-shipping-info-dto";
import { environment } from "../../../environments/environment";

@Injectable()
export class ShippingGroupsService {

  constructor(private http: HttpClient) { }

  applyShippingInfo(shippingInfo: IApplyShippingInfoDto) {
    return this.http.post(`${environment.apiUrl}/cart/shippingGroups/applyShippingInfo`, shippingInfo);
  }

}
