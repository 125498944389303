import { Component, OnInit } from '@angular/core';
import { ClearskyService } from '../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
})
export class RefreshButtonComponent implements OnInit {
  ocids: OcidItems = {};

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.refresh-label', 'clearsky.refresh-machine-label'])
      .pipe(first())
      .subscribe();
  }

  /**
   * Force machines reload.
   */
  forceReload(): void {
    this.clearskyService.forceMachinesReload();
  }
}
