export const DistanceTypes = {
  MILES: 'mi',
  KILOMETERS: 'km'
};

/**
 * Get distance units for bing maps.
 * @param type
 */
export function getDistanceUnits(type: string): Microsoft.Maps.SpatialMath.DistanceUnits {
  switch (type) {
    case this.DISTANCE_TYPES.KILOMETERS:
      return Microsoft.Maps.SpatialMath.DistanceUnits.Kilometers;
    case this.DISTANCE_TYPES.MILES:
    default:
      return Microsoft.Maps.SpatialMath.DistanceUnits.Miles;
  }
}
