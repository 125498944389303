import { Component, Inject, OnInit } from '@angular/core';
import { Machine } from '../../../../contracts/clearsky/machine/machine.dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs/operators';

interface DialogData {
  machine: Machine;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-engine-component-dialog',
  templateUrl: './engine-component-dialog.component.html',
})
export class EngineComponentDialogComponent implements OnInit {
  ocids: OcidItems = {};
  constructor(
    public dialogRef: MatDialogRef<EngineComponentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.engine-details-label',
        'clearsky.engine-delivery-label',
        'clearsky.engine-consumption-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
