import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { IUser } from '../../../contracts/user/iuser';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-text-promo',
  styleUrls: ['./text-promo.component.scss'],
  templateUrl: './text-promo.component.html',
})
export class TextPromoComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    heading: string;
    subTitle: string;
    buttonUrl: string;
    buttonText: string;
    background: string;
  };
  user!: IUser;
  isBrowser = isPlatformBrowser(this.platformId);
  contactFormUrls = {
    KNOWLEDGE: 'knowledge',
    CLEARSKY: 'clearsky-register',
  };

  constructor(
    private router: Router,
    private userService: CurrentUserService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>
  ) {}

  ngOnInit() {
    this.userService.userSubject.subscribe((user: IUser) => (this.user = user));
  }

  /**
   * Save the customer info as a cookie so we can prepoulate it on the JLG contact form
   */
  saveUserInfo() {
    if (
      (this.router.url.includes(this.contactFormUrls.KNOWLEDGE) ||
        this.router.url.includes(this.contactFormUrls.CLEARSKY)) &&
      this.isBrowser
    ) {
      const user = {
        form: this.router.url.includes(this.contactFormUrls.CLEARSKY)
          ? 'clearsky'
          : 'knowledge',
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        companyName: this.user.companyName,
        email: this.user.email,
        country: this.user.homeAddress.country,
        state: this.user.homeAddress.state,
        city: this.user.homeAddress.city,
      };
      document.cookie =
        'jlgUserInfo=' + JSON.stringify(user) + ';path=/;domain=jlg.com';
    }
  }
}
