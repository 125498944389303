import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { AddPartsFormComponent } from './request-info-form/add-parts-form/add-parts-form.component';
import { JobSiteInfoFormComponent } from './request-info-form/job-site-info-form/job-site-info-form.component';
import { MachineDownFormComponent } from './request-info-form/machine-down-form/machine-down-form.component';
import { OOTBFormComponent } from './request-info-form/ootb-form/ootb-form.component';
import { ComplaintFormComponent } from './request-info-form/parts-form/complaint-form/complaint-form.component';
import { PartsFormComponent } from './request-info-form/parts-form/parts-form.component';
import { RequestInfoFormComponent } from './request-info-form/request-info-form.component';
import { ServiceFormComponent } from './request-info-form/service-form/service-form.component';
import { TrainingFormComponent } from './request-info-form/training-form/training-form.component';
import { UploadFormComponent } from './request-info-form/upload-form/upload-form.component';
import { Web2CaseShippingAddressFormComponent } from './request-info-form/web2case-shipping-address-form/web2case-shipping-address-form.component';
import { Web2CaseComponent } from './web2case.component';
import { Web2CaseService } from './web2case.service';

@NgModule({
  declarations: [
    AddPartsFormComponent,
    ComplaintFormComponent,
    JobSiteInfoFormComponent,
    MachineDownFormComponent,
    OOTBFormComponent,
    PartsFormComponent,
    RequestInfoFormComponent,
    ServiceFormComponent,
    TrainingFormComponent,
    UploadFormComponent,
    Web2CaseComponent,
    Web2CaseShippingAddressFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    Web2CaseService
  ]
})
export class Web2CaseModule {}
