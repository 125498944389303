import { Component, Input, OnInit } from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-battery-monitoring',
  templateUrl: './machine-battery-monitoring.component.html',
  styleUrls: ['./machine-battery-monitoring.component.scss'],
})
export class MachineBatteryMonitoringComponent implements OnInit {
  @Input() machine: Machine;
  historic = HistoricSelection.HOURS_24;
  widgetName = MachineWidgets.BATTERY_MONITORING;
  displayName = MachineWidgetsDisplay.assetBatteryMonitoring;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components
    this.localization
      .getOCIDs([this.displayName])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
