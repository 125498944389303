import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { mergeDeep } from '../../deep-merge';
import { BasePlotChart } from '../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import * as Highcharts from 'highcharts';
import { PieChartData } from './pie-chart-data';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
})
export class PieChartComponent implements AfterViewInit, OnChanges {
  @Input() data: PieChartData[] = [];
  @Input() name: string;
  @Output() legendClicked: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() chartClicked: EventEmitter<unknown> = new EventEmitter<unknown>();
  @ViewChild('chartEl') chartEl!: ElementRef;
  private plot;

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.isFirstChange()) {
      this.resetChartData();
      this.setChartSeries();
    }
  }

  /**
   * Set series for chart.
   * @protected
   */
  private setChartSeries(): void {
    // By default we do one level of series
    const series = {
      name: this.name,
      colorByPoint: true,
      innerSize: '50%',
      data: this.data,
    };

    this.plot.addSeries(series);

    // Update chart title
    this.setChartTitle();
  }

  /**
   * Set chart title.
   * @protected
   */
  private setChartTitle(): void {
    const total = this.data.reduce((prev, d) => (prev += d.y), 0);
    this.plot.setTitle({
      text: `<span class="donut-center-text-main">${total}</span><br /><span class="donut-center-text-sub">Total</span>`,
    });
  }

  /**
   * Create the chart.
   * @protected
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      mergeDeep(cloneDeep(BasePlotChart.pie), {
        plotOptions: {
          series: {
            events: {
              click: (e) => this.chartClicked.emit(e),
            },
          },
        },
      })
    );
    this.setChartSeries();
  }

  /**
   * Reset chart data.
   * @protected
   */
  private resetChartData(): void {
    if (!this.plot) {
      return;
    }

    this.plot.series.forEach((s) => s.remove());
  }
}
