import { CsFilterBuilder } from './cs-filter-builder';
import { CSFilterColumn, CSFilterType, QAPIFilter } from '../machine-filter-v2';

export class CsNullFilterBuilder extends CsFilterBuilder {
  buildFilter(values: unknown[]): QAPIFilter[] {
    return [
      {
        column: CSFilterColumn[this.filter.key],
        filterType: values.includes(true)
          ? CSFilterType.NOTNULL
          : CSFilterType.NULL,
      },
    ];
  }
}
