import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ClearskyService } from '../../clearsky.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, merge, Observable } from 'rxjs';
import { Machine } from '../../../contracts/clearsky/machine/machine.dto';
import { first, map, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MachineWidgetDialogComponent } from './widgets/machine-widget-dialog/machine-widget-dialog.component';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MachineWidgetItemComponent } from './widgets/machine-widget-item/machine-widget-item.component';
import Sortable from 'sortablejs';
import { WidgetService } from '../../widget.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { LocalizationService } from '../../../shared/localization/localization.service';
import {
  AvailableMachineWidgets,
  canMachineWidgetShow,
  MachineWidgetsSizing,
} from '../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { CsRequestKeys } from '../../../contracts/clearsky/cs-machines-request';
import { CSFilter } from '../../../contracts/clearsky/machine/machine-filter-v2';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-detail',
  templateUrl: './machine-detail.component.html',
  styleUrls: ['./machine-detail.component.scss'],
})
export class MachineDetailComponent implements OnInit, AfterViewInit {
  @Input() data!: {
    machineSerialNumber: string;
  };
  private grid: ElementRef;
  @ViewChild('grid', { read: ElementRef }) set content(content: ElementRef) {
    if (content) {
      this.grid = content;
      this.initDrag();
    }
  }
  @ViewChild('widgetContainer') widgetContainer: ElementRef;
  @ViewChildren(MachineWidgetItemComponent, { read: ViewContainerRef })
  widgetListItems: QueryList<ViewContainerRef>;
  machine$: Observable<Machine | undefined>;
  currentWidgets$: Observable<string[]> =
    this.clearskyService.currentMachineWidgets$;
  private widgets: string[] = [];
  copyTooltipText;
  copiedMessage;
  copiedDurationMs = 1500;
  ocids: OcidItems = {};
  widgetsSizing = MachineWidgetsSizing;
  private gridInstance: Sortable;
  private yOffset = -200;
  private machine: Machine | undefined;

  constructor(
    private clearskyService: ClearskyService,
    private route: ActivatedRoute,
    private location: Location,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private widgetService: WidgetService,
    private localization: LocalizationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.copy-label',
        'clearsky.copied-label',
        'clearsky.that-not-label',
        'clearsky.customize-label',
        'clearsky.sn-label'
      ])
      .pipe(first())
      .subscribe((ocids) => {
        this.copyTooltipText = ocids['clearsky.copy-label'];
        this.copiedMessage = ocids['clearsky.copied-label'];

        const getDataOnce = () => {
          return combineLatest([
            this.clearskyService.getDataByWidgetKey(
              CsRequestKeys.assetView,
              {
                filters: [
                  {
                    key: CSFilter.sn.key,
                    values: [this.data.machineSerialNumber],
                  },
                ],
              },
              false
            ),
            this.clearskyService.legendRef$,
          ]).pipe(
            map(([res]) => {
              if (!(res && res.machines && res.machines.length)) {
                this.snackbar.open(
                  ocids['clearsky.that-not-label'],
                  undefined,
                  {
                    duration: 3000,
                    verticalPosition: 'top',
                  }
                );
                this.location.back();
                return;
              }

              this.machine = res.machines[0] as Machine;
              return this.machine;
            }),
            take(1)
          );
        };

        const initialMachine$ = getDataOnce();
        const reload$ = this.clearskyService.forceMachineReload$.pipe(
          switchMap(() => {
            return getDataOnce();
          })
        );

        this.machine$ = merge(initialMachine$, reload$);
        this.currentWidgets$ = combineLatest([
          this.clearskyService.currentMachineWidgets$,
          this.machine$,
        ]).pipe(
          map(([widgets, machine]) => {
            return widgets.filter(
              (widget) =>
                machine &&
                AvailableMachineWidgets.includes(widget) &&
                canMachineWidgetShow(machine, widget)
            );
          })
        );
      });

    // Force reload after route param change
    this.route.params.subscribe(() => {
      this.clearskyService.forceMachineReload();
    });

    // See when we should scroll to a specific widget in the list
    this.widgetService.scrollToWidget$.subscribe((widget) =>
      this.scrollToWidget(widget)
    );
  }

  ngAfterViewInit(): void {
    // When widgets update, scroll user where necessary
    this.widgetListItems.changes.subscribe(() => {
      const newWidgets = this.widgetListItems.toArray().map((el) => {
        return el.element.nativeElement.id;
      });

      // Only start doing this logic after the initial set so we don't scroll the user to the bottom on page load
      if (this.widgets.length) {
        const added = newWidgets.filter((w) => !this.widgets.includes(w));

        if (added.length) {
          // Scroll user to bottom to see their new widgets
          if (isPlatformBrowser(this.platformId)) {
            let y: number;
            if (added.length > 1) {
              y =
                this.widgetContainer.nativeElement.getBoundingClientRect()
                  .bottom + this.yOffset;
            } else {
              y =
                this.document
                  .querySelector(`#${added[0]}`)
                  .getBoundingClientRect().top +
                window.scrollY +
                this.yOffset;
            }
            window.scrollTo(0, y);
          }
        }
      }

      this.widgets = newWidgets;
    });
  }

  /**
   * Open the widget dialog.
   */
  onAddWidget(): void {
    this.dialog
      .open(MachineWidgetDialogComponent, {
        panelClass: ['clearsky-dialog'],
        autoFocus: false,
        data: {
          machine: this.machine,
        },
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.clearskyService.updateMachineWidgets(data);
        }
      });
  }

  /**
   * Scroll to specific widget by id.
   * @param id
   */
  scrollToWidget(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const yOffset = -200;
      const y =
        this.document.querySelector(`#${id}`).getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo(0, y);
    }
  }

  /**
   * On copy field.
   */
  onFieldCopy(): void {
    this.snackbar.open(this.copiedMessage, null, {
      duration: this.copiedDurationMs,
      panelClass: 'cs-tooltip',
    });
  }

  /**
   * On grid row change event listener.
   * @param widget
   * @param row
   */
  onGridRowChange(widget: string, row: number): void {
    if (!this.widgetsSizing[widget]) {
      this.widgetsSizing[widget] = {
        row: 1,
        col: 1,
      };
    }

    this.widgetsSizing[widget].row = row;
  }

  /**
   * Init drag and drop functionality.
   * @private
   */
  private initDrag(): void {
    this.gridInstance = Sortable.create(this.grid.nativeElement, {
      handle: '.widget-drag-handler',
      forceFallback: true,
      onUpdate: this.onWidgetDragComplete.bind(this),
    });
  }

  /**
   * Update the order of the widgets.
   */
  private onWidgetDragComplete(): void {
    this.clearskyService.updateMachineWidgets(this.gridInstance.toArray());
  }
}
