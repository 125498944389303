import * as dayjs from 'dayjs';
import { OcidItems } from '../../ocid-items';
import { localizeOcidData } from '../../../shared/localization/localized-data.pipe';
import {
  Machine,
  MachineDTC,
  MachineLocation,
  MxAlert,
  MxAsrc,
} from './machine.dto';

interface MachineFieldDisplayData {
  skipOcid?: boolean;
  ocid?: string;
  data?: unknown[];
}

export const MachineFieldDisplayMapping = {
  dtc: ({ value }: { value: MachineDTC[] }): MachineFieldDisplayData => ({
    skipOcid: true,
    data: [value && value.length ? value.length : 0],
  }),
  ign: ({ value }: { value: boolean }): MachineFieldDisplayData => ({
    ocid: value === true ? 'global.on-label' : value === false ? 'global.off-label' : null,
  }),
  loc: ({ value }: { value: MachineLocation }): MachineFieldDisplayData => ({
    ocid: 'clearsky.long-lat-label',
    data: value.lng && value.lat ? [value.lng, value.lat] : null,
  }),
  lrt: ({ value }: { value: string | number }): MachineFieldDisplayData => ({
    data: value ? [dayjs(value).format('MM/DD/YYYY HH:mm:ss')] : null,
  }),
  uidt: ({ value }: { value: string | number }): MachineFieldDisplayData => ({
    data: value ? [dayjs(value).format('MM/DD/YYYY HH:mm:ss')] : null,
  }),
  rep: ({ value }: { value: boolean }): MachineFieldDisplayData => ({
    ocid: value === true ? 'global.yes' : value === false ? 'global.no' : null,
  }),
  dolc: ({
    value,
  }: {
    value: string | number | undefined;
  }): MachineFieldDisplayData => ({
    data: [value ? new Date(value).toLocaleString() : ''],
  }),
  hasBA: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.asrc?.includes(MxAsrc.HAS_BA) ? 'global.yes' : 'global.no',
  }),
  hib: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.hib ? 'clearsky.device-mode-hibernating' : machine.hib === false ? 'clearsky.device-mode-not-hibernating' : 'global.not-available-label',
  }),
  sofStat: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.sofStat ? 'global.current' : machine.sofStat === false ? 'clearsky.update-required' : 'global.not-available-label',
  }),
  hasEA: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.asrc?.includes(MxAsrc.HAS_EA) ? 'global.yes' : 'global.no',
  }),
  hasLFSOC: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.alerts?.some(
      (a) => a === MxAlert.BAT006 || a === MxAlert.ENG003
    )
      ? 'global.yes'
      : 'global.no',
  }),
  hasSA: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.asrc?.includes(MxAsrc.HAS_SA) ? 'global.yes' : 'global.no',
  }),
  hasTA: ({ machine }: { machine: Machine }): MachineFieldDisplayData => ({
    ocid: machine.asrc?.includes(MxAsrc.HAS_TA) ? 'global.yes' : 'global.no',
  }),
  sstat: ({
    value,
  }: {
    value: boolean | undefined;
  }): MachineFieldDisplayData => {
    switch (value) {
      case true:
        return { ocid: 'clearsky.charging-label' };
      case false:
        return { ocid: 'clearsky.not-label' };
      default:
        return { data: [''] };
    }
  },
  copHrs: ({
    value,
  }: {
    value: string | undefined;
  }): MachineFieldDisplayData => {
    if (value) {
      return {
        ocid: 'clearsky.value-hours-label',
        data: [parseFloat(value).toFixed(1)],
      };
    }
    return { data: [''] };
  },
  mxAge: ({
    value,
  }: {
    value: string | undefined;
  }): MachineFieldDisplayData => {
    if (value) {
      return {
        ocid: 'clearsky.value-years-label',
        data: [parseFloat(value).toFixed(1)],
      };
    }
    return { data: [''] };
  },
  alerts: ({ value }: { value: MachineDTC[] }): MachineFieldDisplayData => ({
    skipOcid: true,
    data: [value && value.length ? value.length : 0],
  }),
};

/**
 * Get user-friendly display name based on machine property.
 * @param field
 * @param value
 * @param machine
 * Note:: if ocid, call that key in localization service and append data using the pipe's method (separate it out first in localized-data.pipe.ts)
 * Note:: if no ocid property, then just spit out data
 */
export function getMachineFieldDisplayValue(
  field: string,
  value: never,
  machine?: Machine
): MachineFieldDisplayData {
  return typeof MachineFieldDisplayMapping[field] === 'function'
    ? MachineFieldDisplayMapping[field]({ value, machine })
    : { data: [value] };
}

/**
 * Get localized translated string with data injected based on current ocids.
 * @param field
 * @param fieldValue
 * @param ocids
 */
function getFieldOcidTranslation(
  field: string,
  fieldValue: MachineFieldDisplayData,
  ocids: OcidItems
): unknown {
  if (fieldValue.ocid) {
    return localizeOcidData(ocids[fieldValue.ocid], fieldValue.data || []);
  }
  return fieldValue.data && fieldValue.data.length ? (fieldValue.data[0] ? fieldValue.data[0]: ocids['global.not-available-label']) : ocids['global.not-available-label'];
}

/**
 * Get localized translated string with data injected based on current ocids.
 * @param field
 * @param value
 * @param ocids
 * @param fullData
 */
export function getMachineFieldOcidTranslation(
  field: string,
  value: never,
  ocids: OcidItems,
  fullData?: Machine
) {
  const fieldValue = getMachineFieldDisplayValue(field, value, fullData);
  return getFieldOcidTranslation(field, fieldValue, ocids);
}

export function getDTCFieldDisplayValue(
  field: string,
  value: never
): MachineFieldDisplayData {
  switch (field) {
    case 'st':
      return { data: [dayjs(value as number).format('MM/DD/YYYY HH:mm:ss')] };
    case 'stat':
      return {
        ocid: value ? 'clearsky.active-label' : 'clearsky.inactive-label',
      };
    default:
      return { data: [value] };
  }
}

/**
 * Get localized translated string with data injected based on current ocids.
 * @param field
 * @param value
 * @param ocids
 */
export function getDTCFieldOcidTranslation(
  field: string,
  value: never,
  ocids: OcidItems
) {
  const fieldValue = getDTCFieldDisplayValue(field, value);
  return getFieldOcidTranslation(field, fieldValue, ocids);
}

export const MachineFieldDisplayValueOcids: { [field: string]: string[] } = {
  ign: ['global.on-label', 'global.off-label'],
  loc: ['clearsky.long-lat-label|'],
  rep: ['global.yes', 'global.no'],
  cstat: ['clearsky.charging-label', 'clearsky.not-label'],
  mxAge: ['clearsky.value-years-label'],
  copHrs: ['clearsky.value-hours-label'],
};

export const reverseMappingColumns = {
  machineAge: 'mxAge',
  companyName: 'cname',
  powerSource: 'pwrSrc',
  reportedToday: 'rep',
  terminalId: 'tid',
  diagnosticTroubleCode: 'dtc',
  equipmentId: 'eid',
  oemName: 'brand',
  model: 'model',
  hasTelematicsAlert: 'hasTA',
  lastMachineReportTime: 'lrt',
  serialNumber: 'sn',
  clearSkyDevice: 'csDev',
  siteNetworkName: 'snName',
  modelType: 'mtype',
  hasBatteryAlert: 'hasBA',
  accessRestriction: 'ar',
  hasEngineAlert: 'hasEA',
  unitInstallDateTime: 'uidt',
  accessRestrictionHistory: 'arH',
  modelGroup: 'mgroup',
  hasLowFuelOrSOC: 'hasLFSOC',
  hasSystemAlert: 'hasSA',
  hardwareReportingStatus: 'hrs',
  ignitionStatus: 'ign',
  fuelLevelQuantified: 'flvlQ',
  cumulativeOperatingHours: 'copHrs',
  chargingStatus: 'connStat',
  datetimeOfLastCharge: 'dolc',
  defRemainingQuantified: 'defRmQ',
};

export const mappingColumns = {
  mxAge: 'machineAge',
  cname: 'companyName',
  pwrSrc: 'powerSource',
  rep: 'reportedToday',
  tid: 'terminalId',
  dtc: 'diagnosticTroubleCode',
  eid: 'equipmentId',
  brand: 'oemName',
  model: 'model',
  hasTA: 'hasTelematicsAlert',
  lrt: 'lastMachineReportTime',
  sn: 'serialNumber',
  csDev: 'clearSkyDevice',
  snName: 'siteNetworkName',
  mtype: 'modelType',
  hasBA: 'hasBatteryAlert',
  ar: 'accessRestriction',
  hasEA: 'hasEngineAlert',
  uidt: 'unitInstallDateTime',
  arH: 'accessRestrictionHistory',
  mgroup: 'modelGroup',
  hasLFSOC: 'hasLowFuelOrSOC',
  hasSA: 'hasSystemAlert',
  hrs: 'hardwareReportingStatus',
  ign: 'ignitionStatus',
  flvlQ: 'fuelLevelQuantified',
  copHrs: 'cumulativeOperatingHours',
  sstat: 'chargingStatus',
  dolc: 'datetimeOfLastCharge',
  defRmQ: 'defRemainingQuantified',
};
