import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-adsense',
  templateUrl: './adsense.component.html',
})
export class AdsenseComponent
  implements OnInit, CartridgeInterface, AfterViewInit
{
  platformBrowser = false;
  @Input() data: {
    adsense: string;
  };

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    if (this.platformBrowser) {
      setTimeout(() => {
        try {
          (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        } catch (e) {
          console.error('error');
        }
      }, 1000);
    }
  }
}
