import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  throwError,
  of,
  combineLatest,
  Subject,
} from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IAddItemsDto } from '../../contracts/commerce/dto/iadd-items-dto';
import { IAddSerialNumbersDto } from '../../contracts/commerce/dto/iadd-serial-numbers.dto';
import { ICheckoutDto } from '../../contracts/commerce/dto/icheckout-dto';
import { ICommerceAvailability } from '../../contracts/commerce/icommerce-availability';
import { ICommerceItem } from '../../contracts/commerce/icommerce-item';
import { ICommerceItemAvailability } from '../../contracts/commerce/icommerce-item-availability';
import { ICommerceItemSerialNumbers } from '../../contracts/commerce/icommerce-item-serial-numbers';
import { ICommerceItemWithCart } from '../../contracts/commerce/icommerce-item-with-cart';
import { ICommerceSpecialConditions } from '../../contracts/commerce/icommerce-special-conditions';
import { IOrder } from '../../contracts/commerce/iorder';
import { ListrakService } from '../gtm/listrak.service';
import { isPlatformBrowser } from '@angular/common';
import { EcommerceService } from '../gtm/ecommerce-service';
import { NotificationService } from '../notification/notification.service';
import { CurrentUserService } from '../user/current-user.service';
import { ProductTypes } from '../../contracts/product/iproduct';
import { ICodeAndDesc } from '../../contracts/commerce/icode-and-desc';

@Injectable()
export class CartService {
  public cart: IOrder = null;
  public cartPreviewSubject: BehaviorSubject<IOrder> =
    new BehaviorSubject<IOrder>({});
  private cartRefresh: Subject<void> = new Subject<void>();
  public cartRefresh$: Observable<void> = this.cartRefresh.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
    private notificationService: NotificationService,
    private ecommerceService: EcommerceService,
    private userService: CurrentUserService,
    private listrakService: ListrakService
  ) { }

  /**
   * Track cart update and update subject.
   * @param items
   * @protected
   */
  protected listrakUpdate(items: ICommerceItemWithCart[]): void {
    // Should we track in Listrak?
    if (isPlatformBrowser(this.platformId)) {
      this.listrakService.updateCartContents(items, this.userService.user);
    }
  }

  /**
   * Delete the commerce item for given ID.
   *
   * @param {ICommerceItemWithCart} item
   * @param {string} source
   * @param {string} customerNum
   * @param {string} inStock
   * @param {string} shipDate
   * @returns {Observable<IOrder>}
   */
  removeFromCart(
    item: ICommerceItemWithCart,
    source: string,
    customerNum = '',
    inStock = '',
    shipDate = 'n/a'
  ): Observable<{ items: ICommerceItemWithCart[]; cart: IOrder }> {
    return combineLatest([this.http
      .delete<{ items: ICommerceItemWithCart[]; cart: IOrder }>(`${environment.apiUrl}/cart/commerceItems/${item.id}`), this.userService.getUser()])
      .pipe(
        tap({
          next: ([cartItem, user]) => {
            // Send Remove action to GA if the platform is browser
            this.ecommerceService.remove(
              item,
              source,
              item.quantity,
              customerNum,
              user.companyName,
              inStock,
              shipDate
            );
          },
        }),
        map(([res]) => {
          if (res.items) {
            this.listrakUpdate(res.items);
          } else {
            // Last item removed so track clearing of cart
            this.listrakService.clearCart(this.userService.user);
          }
          this.cartPreviewSubject.next(res.cart);
          return res;
        }),
        catchError((err) => {
          // Reprice items on error
          this.repriceCart().subscribe();
          return throwError(err);
        })
      );
  }

  /**
   * Remove multiple products to cart.
   *
   * @param {ICommerceItemWithCart[]} items
   * @param {string} source
   * @returns {Observable<ICommerceItemWithCart>}
   */
  removeAll(items: ICommerceItemWithCart[], source: string) {
    const itemsToRemove = items.map((item: ICommerceItemWithCart) => {
      return item.id;
    });
    // using http.request instead of http.delete because current angular version doesn't support the latter
    // should update to http.delete when we upgrade
    return combineLatest([this.http
      .request<{ cart: IOrder }>(
        'delete',
        `${environment.apiUrl}/cart/commerceItems/deleteItems`,
        { body: { items: itemsToRemove } }
      ), this.userService.getUser()])
      .pipe(
        map(([res, user]) => {
          this.ecommerceService.removeMultiple(items, source, user.companyName);
          this.listrakService.clearCart(this.userService.user);
          this.cartPreviewSubject.next(res.cart);
          return res;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  /**
   * Add Multiple Products to cart.
   *
   * @param {IAddItemsDto} items
   * @returns {Observable<ICommerceItemWithCart>}
   */
  addToCart(items: IAddItemsDto) {
    // Add item to cart
    return this.http
      .post(`${environment.apiUrl}/cart/commerceItems/multi`, items)
      .pipe(
        switchMap(() => {
          return this.getCart(true).pipe(
            map((res) => {
              this.listrakUpdate(res.commerceItems.items);
              return res;
            })
          );
        }),
        catchError((err) => {
          // Reprice items on error
          this.repriceCart().subscribe();
          return throwError(err);
        })
      );
  }

  /**
   * Add Product to cart.
   *
   * @param {string} productId
   * @param {number} quantity
   * @returns {Observable<ICommerceItemWithCart>}
   */
  addOneToCart(productId: string, quantity: number) {
    // Add item to cart
    return this.http
      .post(`${environment.apiUrl}/cart/commerceItems`, {
        catalogRefId: productId,
        productId: productId,
        quantity: quantity,
      })
      .pipe(
        switchMap(() => {
          return this.getCart(true);
        }),
        map((res) => {
          this.listrakUpdate(res.commerceItems.items);
          // get the most recently added item from items array
          return res.commerceItems.items[res.commerceItems.items.length - 1];
        }),
        catchError((err) => {
          // Reprice items on error
          this.repriceCart().subscribe();
          return throwError(err);
        })
      );
  }

  /**
   * Get cart (GET /cart)
   *
   * @param {boolean} pricing
   * @param {boolean} [clearPayloadCache=false]
   * @returns {Observable<IOrder>}
   */
  getCart(pricing?: boolean, clearPayloadCache = false): Observable<IOrder> {
    let url = `${environment.apiUrl}/cart`;

    if (pricing) {
      url += '?reprice=ORDER_SUBTOTAL';
    }
    const headers = {};
    // If we need to clear the payload cache for the request, add that header to the request.
    if (clearPayloadCache) {
      headers['clear-payload-cache'] = 'true';
    }
    const httpOptions = {
      headers: new HttpHeaders(headers),
    };
    // Cart has never been defined so make API request.
    return this.http.get(url, httpOptions).pipe(
      map((res: IOrder) => {
        this.cartPreviewSubject.next(res);
        return res;
      })
    );
  }

  /**
   * Get cart with pricing information.
   *
   * @returns {Observable<IOrder>}
   */
  getCartWithPricing(): Observable<IOrder> {
    return this.getCart(true);
  }

  /**
   * Get required payload for ICommerceItem.
   *
   * @param {ICommerceItemWithCart | ICommerceItem} item
   * @returns {ICommerceItem}
   */
  protected getRequiredUpdatePayload(
    item: ICommerceItemWithCart | ICommerceItem
  ): ICommerceItem {
    return {
      id: item.id,
      catalogRefId: item.catalogRefId,
      productId: item.productId,
      quantity: item.quantity,
    };
  }

  /**
   * Update quantity of cart item.
   *
   * @param item ICommerceItemWithCart
   * @param quantity number
   * @return void
   */
  public updateQuantity(
    item: ICommerceItemWithCart,
    quantity: number
  ): Observable<ICommerceItemWithCart> {
    const updateItem = {
      ...this.getRequiredUpdatePayload(item),
      ...{
        quantity: +quantity,
      },
    };

    // Make API request
    return this.updateItem(item, updateItem).pipe(
      map((response: ICommerceItemWithCart) => {
        const cart = <IOrder>response['cart'];
        this.listrakUpdate(cart.commerceItems.items);
        return response;
      })
    );
  }

  /**
   * Confirm a consult factory item.
   *
   * @param {ICommerceItemWithCart} item
   * @returns {Observable<ICommerceItemWithCart>}
   */
  public confirmConsultFactory(
    item: ICommerceItemWithCart
  ): Observable<ICommerceItemWithCart> {
    const updateItem = {
      ...this.getRequiredUpdatePayload(item),
      ...{
        consultFactoryAction: 'approved',
      },
    };

    return this.updateItem(item, updateItem);
  }

  /**
   * Set coreReturnAction flag to approved.
   *
   * @param {ICommerceItemWithCart} item
   * @returns {Observable<ICommerceItemWithCart>}
   */
  public confirmCoreReturn(
    item: ICommerceItemWithCart
  ): Observable<ICommerceItemWithCart> {
    const updateItem = {
      ...this.getRequiredUpdatePayload(item),
      ...{
        coreReturnAction: 'approved',
      },
    };

    return this.updateItem(item, updateItem);
  }

  /**
   * Update commerce item.
   *
   * @param {ICommerceItemWithCart} item
   * @param {ICommerceItem} data
   * @returns {Observable<ICommerceItemWithCart>}
   */
  public updateItem(
    item: ICommerceItemWithCart,
    data: ICommerceItem
  ): Observable<ICommerceItemWithCart> {
    return this.http.patch<ICommerceItemWithCart>(
      `${environment.apiUrl}/cart/commerceItems/${item.id}`,
      data
    );
  }

  /**
   * Update cart
   *
   * @param {IOrder} options
   * @returns {Observable<IOrder>}
   */
  updateCart(options: IOrder) {
    // Make API request
    return this.http.patch(`${environment.apiUrl}/cart`, options).pipe(
      map((response: IOrder) => {
        this.cartPreviewSubject.next(response);
        return response;
      })
    );
  }

  /**
   * Force cart to refresh
   *
   */
  refreshCart() {
    this.cartRefresh.next();
  }

  /**
   * Add serial numbers to commerce item.
   *
   * @param {IAddSerialNumbersDto} request
   * @returns {Observable<ICommerceItemSerialNumbers>}
   */
  public addSerialNums(
    request: IAddSerialNumbersDto
  ): Observable<ICommerceItemSerialNumbers> {
    return this.http.post<ICommerceItemSerialNumbers>(
      `${environment.apiUrl}/cart/commerceItems/serialNumbers`,
      request
    );
  }

  /**
   * Reprice cart.
   *
   * @returns {Observable<void>}
   */
  repriceCart() {
    return this.http.post<IOrder>(`${environment.apiUrl}/cart/reprice`, {
      pricingOperation: 'ORDER_TOTAL',
    });
  }

  /**
   * Get cart item availability.
   *
   * @param {number} shipToNumber
   * @returns {Observable<ICommerceItemAvailability[]>}
   */
  public getAvailability(
    shipToNumber = 0
  ): Observable<ICommerceItemAvailability[]> {
    const url = shipToNumber
      ? `${environment.apiUrl}/cart/itemAvailability?shipToNumber=${shipToNumber}`
      : `${environment.apiUrl}/cart/itemAvailability`;
    return this.http.get(url).pipe(
      map((res: ICommerceAvailability) => {
        const avail = <ICommerceItemAvailability[]>res.itemsAvailability;
        return avail ? avail : [];
      })
    );
  }

  /**
   * Checkout cart.
   *
   * @param {IOrder} order
   * @returns {Observable<IOrder>}
   */
  public checkout(order: IOrder): Observable<IOrder> {
    return this.http
      .post<IOrder>(`${environment.apiUrl}/cart/checkout`, <ICheckoutDto>{
        siteId: order.siteId,
      })
      .pipe(
        map((order: IOrder) => {
          this.cartPreviewSubject.next(order);
          return order;
        })
      );
  }

  /**
   * Get sales types in checkout.
   *
   * @returns {Observable<ICodeAndDesc>}
   */
  public getSalesTypes() {
    return this.http.get(`${environment.apiUrl}/cart/salesTypes`);
  }

  /**
   * Get special conditions for commerce items in cart.
   *
   * @param {ICommerceItem[]} items
   * @returns {ICommerceSpecialConditions}
   */
  public getSpecialConditionsList(items: ICommerceItem[]) {
    const conditions = <ICommerceSpecialConditions>{
      invalidParts: [],
      pricingNotSetup: [],
      coreReturn: [],
      superseded: [],
      phantom: [],
      psr289serialReq: [],
      psr289serialOpt: [],
      consultFactory: [],
      machineOutOfStock: [],
      actionRequired: false,
    };

    // This array is used to hold any psr289 serial number required items that have an actionRequired attribute of false.
    // We will need to determine whether there are other items in the cart in addition to the one or many psr289 serial number
    // required action required false items because the backend does not handle that scenario.
    const potentialPSR289RequiredActionReq = [];

    // Loop through items and setup special conditions in priority
    items.forEach((item: ICommerceItemWithCart) => {
      // Does it have any special conditions? If not, skip to the next one
      // However, if an action is not required and it is a psr290 serial number required item, add it to the potentialPSR289RequiredActionReq array.
      if (!item.actionRequired) {
        if (item.psr289 && item.serialNumReq) {
          potentialPSR289RequiredActionReq.push(item);
        }
        return;
      } else {
        // If action is required, set the actionRequired value to true. This will properly
        // configure the checkout button.
        conditions.actionRequired = true;
      }

      // Superseded?
      if (item.supersededItem) {
        conditions.superseded.push(item);
        return;
      }

      // Phantom?
      if (item.phantomItem) {
        conditions.phantom.push(item);
        return;
      }

      // Invalid?
      if (item.invalidPart) {
        conditions.invalidParts.push(item);
        return;
      }

      // Consult factory?
      if (item.consultFactoryAction === 'required') {
        conditions.consultFactory.push(item);
        return;
      }

      // No pricing?
      if (item.pricingNotSetup) {
        conditions.pricingNotSetup.push(item);
        return;
      }

      // PSR289 serial num req?
      if (item.psr289) {
        // Determine if a serial number is or is not required.
        item.serialNumReq
          ? conditions.psr289serialReq.push(item)
          : conditions.psr289serialOpt.push(item);
        return;
      }

      // Machine out of stock
      if (!item.returnedQuantity && item.productType === ProductTypes.MACHINE) {
        conditions.machineOutOfStock.push(item);
        return;
      }

      // Core return?
      if (item.coreReturnItem) {
        conditions.coreReturn.push(item);
        return;
      }
    });

    // If the potentialPSR289RequiredActionReq array is greater than or equal to 1....
    if (potentialPSR289RequiredActionReq.length >= 1) {
      // And those item(s) are not the only items in the cart...
      if (potentialPSR289RequiredActionReq.length - items.length < 0) {
        // Set each item's action required attribute to true
        potentialPSR289RequiredActionReq.forEach((item: ICommerceItem) => {
          items.find((cartItem: ICommerceItem) => {
            return cartItem.id === item.id;
          }).actionRequired = true;
        });
        // And re-run the call to get special conditions and return the result. Now that the psr289 serial number
        // required items are all marked as action required, it will properly run through the special conditions checks.
        return this.getSpecialConditionsList(items);
      }
    }

    // If the psr289 serial number required items are greater than or equal to 1 AND the psr289 serial number not required items are greater than or equal to 1
    // OR If the psr289 serial number required items are greater than or equal to 1 AND those items are not the only items in the cart, we are going to move the
    // psr 289 serial number required items to the optional array. This is so that the user has the ability to pick which psr289 item they want and remove the rest of the items
    // or take other action on any of the items in the cart.
    if (
      (conditions.psr289serialReq.length >= 1 &&
        conditions.psr289serialOpt.length >= 1) ||
      (conditions.psr289serialReq.length >= 1 &&
        items.length - conditions.psr289serialReq.length >= 1)
    ) {
      conditions.psr289serialOpt = conditions.psr289serialOpt.concat(
        conditions.psr289serialReq
      );
      conditions.psr289serialReq = [];
    }

    return conditions;
  }

  /**
   * Returns payment options used in checkout.
   */
  getPaymentOptions() {
    return this.http.get<ICodeAndDesc[]>(
      `${environment.apiUrl}/cart/paymentOptions`
    );
  }

  /**
   * Determines if there are any outstanding special conditions and if the user can proceed to checkout.
   * @returns {Observable<any>}
   */
  canMoveToCheckout() {
    return this.http.post(`${environment.apiUrl}/cart/moveToCheckout`, {});
  }

  /**
   * Initialize PayPal payment
   * @param isCheckout
   * @param amount
   * @returns {Observable<any>}
   */
  public initPayPal(isCheckout: boolean, amount = 0) {
    const apiUrl = isCheckout
      ? `${environment.apiUrl}/cart/paymentGroups/initPayPal`
      : `${environment.apiUrl}/invoices/initPayPal`;
    const requestBody = amount ? { totalAmount: amount } : {};
    return this.http
      .post<HttpResponse<unknown>>(apiUrl, requestBody, {
        observe: 'response' as 'body',
      })
      .pipe(
        map((res) => {
          const left = window.innerWidth / 2 - 420 / 2;
          const top = screen.height / 2 - 520 / 2;
          window.open(
            res.headers.get('location'),
            '_blank',
            'left=' + left + ',top=' + top + ',width=420,height=520'
          );
          return res;
        })
      );
  }

  /**
   * Verify PayPal payment
   * @param source
   * @returns {Observable<any>}
   */
  public verifyPayPal(source: string) {
    return this.http
      .post<HttpResponse<unknown>>(
        `${environment.apiUrl}/cart/paymentGroups/verifyPayPalApproval`,
        {}
      )
      .pipe(
        map((res) => {
          this.ecommerceService.usePayPal(source);
          return res;
        })
      );
  }
}
