import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from "../../cartridge/cartridge.class";

@Component({
  selector: 'app-auto-suggest-panel',
  templateUrl: './auto-suggest-panel.component.html',
  host: {
    'class': 'auto-suggest-panel'
  }
})
export class AutoSuggestPanelComponent implements OnInit, CartridgeInterface {
  @Input('data') data: {
    autoSuggest: object[]
  };

  constructor() { }

  ngOnInit() {
  }

}
