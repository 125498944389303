import { Component, Input, OnInit } from '@angular/core';
import { Machine } from '../../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { OcidItems } from '../../../../contracts/ocid-items';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit {
  @Input() machine: Machine | undefined;
  widgetName = Widgets.QUICK_LINKS;
  displayName = WidgetsDisplay.quickLinks;
  ocids: OcidItems = {};
  links = [
    {
      label: 'clearsky.pick-label',
      link: "/equipment-information"
    },
    {
      label: 'clearsky.technical-label',
      link: "/technical-publications"
    },
    {
      label: 'parts.interactive-manuals',
      link: "/parts/interactive-manuals"
    },
    {
      label: 'clearsky.d-schematics-label',
      link: "/service/3d-schematics"
    },
    {
      label: 'clearsky.service-label',
      link: "/warranty-safety/safety-service-bulletins"
    },
    {
      label: 'navigation.control-software',
      link: "/service/machine-control-software"
    },
    {
      label: 'registration.product-registration-title',
      link: "/warranty-safety/product-registration"
    },
  ]

  constructor(private localization: LocalizationService, private gAService: GoogleAnalyticsService) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.pick-label',
        'clearsky.technical-label',
        'parts.interactive-manuals',
        'clearsky.d-schematics-label',
        'clearsky.service-label',
        'navigation.control-software',
        'clearsky.knowledge-base-label',
        'registration.product-registration-title',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  trackAnalytics(link, label) {
    this.gAService.eventEmitter('clearsky_click', 'dashboard_view', 'click', 'quick_link_' + label, link);
  }
}
