import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../shared/localization/localization.service';
import { OcidItems } from '../../contracts/ocid-items';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
})
export class SubscriptionComponent implements OnInit {
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService, private gAService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.gAService.eventEmitter('clearsky_click', 'subscription', 'click');
    this.localization
      .getOCIDs(['csv.subscription-label'])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
