import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Machine } from '../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { GoogleAnalyticsService } from 'app/clearsky/services/google-analytics.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-quick-links-list',
  templateUrl: './asset-quick-links-list.component.html',
})
export class AssetQuickLinksListComponent implements OnInit {
  @Input() machine: Machine;
  pickListLink: string | undefined;
  techPubLink: string | undefined;
  ocids: OcidItems = {};
  links = [
    {
      label: 'parts.interactive-manuals',
      link: "/parts/interactive-manuals"
    },
    {
      label: 'clearsky.d-schematics-label',
      link: "/service/3d-schematics"
    },
    {
      label: 'clearsky.service-label',
      link: "/warranty-safety/safety-service-bulletins"
    },
    {
      label: 'navigation.control-software',
      link: "/service/machine-control-software"
    },
    {
      label: 'clearsky.knowledge-base-label',
      link: "/knowledge"
    },
    {
      label: 'registration.product-registration-title',
      link: "/warranty-safety/product-registration"
    },
  ]
  constructor(
    private localization: LocalizationService,
    private gAService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.localization
      .getOCIDs([
        'clearsky.pick-label',
        'clearsky.technical-label',
        'parts.interactive-manuals',
        'clearsky.d-schematics-label',
        'clearsky.service-label',
        'navigation.control-software',
        'clearsky.knowledge-base-label',
        'registration.product-registration-title'
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.pickListLink = `/equipment-information?sn=${this.machine.sn}`;
    this.techPubLink = `/technical-publications?Ntt=${this.machine.model} ${this.machine.pvc ? this.machine.pvc : ''}`;
    if (this.techPubLink) {
      this.links.splice(0, 0, {
        label: 'clearsky.technical-label',
        link: this.techPubLink
      });
    }
    if (this.pickListLink) {
      this.links.splice(0, 0, {
        label: 'clearsky.pick-label',
        link: this.pickListLink
      });
    }
  }

  trackAnalytics(link, label) {
    this.gAService.eventEmitter('clearsky_click', 'asset_view', 'click', 'quick_link_' + label, link);
  }
}
