import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { LocalizationService } from '../../../../../../shared/localization/localization.service';
import { CartridgeInterface } from '../../../../../cartridge/cartridge.class';
import { SearchService } from '../../../../search/search.service';
import { WindowRefService } from '../../../../../../service/window-ref/window-ref.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../../../../../environments/environment';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-results-list-flyout-item',
  styleUrls: ['./result-list-flyout-item.component.scss'],
  templateUrl: './results-list-flyout-item.component.html',
  host: {
    class: 'record-search-auto-suggest',
  },
})
export class ResultsListFlyoutItemComponent
  implements OnInit, CartridgeInterface
{
  @Input() data: {
    keywordUsed: string;
    suggestedTerms: string;
    records: {
      numRecords: number;
      detailsAction: {
        contentPath: string;
        recordState: string;
      };
      attributes: {
        productMedia: {
          imageAltText: string;
          thumbnailImageUrl: string;
          fullImageUrl: string;
        }[];
        productId: string[];
        'product.displayName': string[];
      };
    }[];
  };
  term: string;
  ocids: {} = {};
  platformBrowser = false;
  isGlobalSearch = false;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private searchService: SearchService,
    private localization: LocalizationService,
    private winRef: WindowRefService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);

    // Get current search term
    this.searchService.currentSearchTerm.subscribe(
      (value: string) => (this.term = value)
    );
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });

    // Get which search is being used
    this.searchService.isGlobalSearchActive.subscribe(
      (active: boolean) => (this.isGlobalSearch = active)
    );
  }

  /**
   * Event when user clicks on product in cartrige.
   *
   * @param record
   */
  onRecordClick(record) {
    const url =
      record.detailsAction.contentPath + record.detailsAction.recordState;

    // Record search item in history
    this.searchService.recordSearch(
      record.attributes['product.displayName'][0],
      url
    );

    // Send search event to GA if the platform is browser
    if (this.platformBrowser) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: this.isGlobalSearch ? 'globalSearch' : 'searchBoxBannerSearch',
        searchTerm: record.attributes['product.displayName'][0],
        enteredTerm: this.term,
        searchType: 'Flyout content',
      });
    }

    // Now navigate the user
    this.searchService.termIsClicked.next(true);
    this.router.navigateByUrl(url).then(() => {
      this.searchService.termIsClicked.next(false);
    });
  }

  /**
   * Event when user clicks view all link.
   *
   * @returns {Promise<void>}
   */
  onViewAll() {
    const queryString = require('query-string');
    // https://www.npmjs.com/package/query-string

    // Record search item in history
    let url = '/search?';
    const query = queryString.stringify({
      Ntt: this.data.suggestedTerms,
    }); // Encode params
    url += query;
    this.searchService.recordSearch(this.data.suggestedTerms, url);

    // Now navigate the user
    this.searchService.termIsClicked.next(true);
    return this.router.navigateByUrl(url).then(() => {
      this.searchService.termIsClicked.next(false);
    });
  }
}
