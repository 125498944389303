import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ClearskyService } from '../../../clearsky.service';
import { Observable } from 'rxjs';
import {
  AvailableWidgets,
  WidgetsDisplay,
  WidgetsSizing,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { first } from 'rxjs/operators';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { WidgetGridDialogComponent } from '../../../shared/widget-grid-dialog/widget-grid-dialog.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-widget-dialog',
  templateUrl: './widget-dialog.component.html',
})
export class WidgetDialogComponent implements OnInit {
  selectedWidgets: string[] | undefined;
  currentWidgets$: Observable<string[]> = this.clearskyService.currentWidgets$;
  widgets: string[] = Object.values(AvailableWidgets);
  widgetsDisplay: { [key: string]: string } = WidgetsDisplay;
  ocids: OcidItems = {};
  private activeWidgets: string[];

  constructor(
    public dialogRef: MatDialogRef<WidgetDialogComponent>,
    protected clearskyService: ClearskyService,
    private localization: LocalizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initOCIDs();

    // Preserve any custom order of the widgets
    this.currentWidgets$.subscribe((currentWidgets) => {
      this.activeWidgets = currentWidgets;
      this.widgets = [...new Set([...currentWidgets, ...this.widgets])];
    });
  }

  /**
   * Open customize widget in grid format dialog.
   */
  openWidgetGridDialog(): void {
    const dialog = this.dialog.open(WidgetGridDialogComponent, {
      panelClass: ['clearsky-dialog'],
      width: '90vw',
      autoFocus: false,
      data: {
        // Append active widgets first so order is kept
        widgets: [
          ...this.activeWidgets,
          ...Object.values(AvailableWidgets).filter(
            (w) => !this.activeWidgets.includes(w)
          ),
        ].map((widget) => ({
          key: widget,
          label: this.ocids[WidgetsDisplay[widget]],
          active: this.activeWidgets.includes(widget),
          row: WidgetsSizing[widget] ? WidgetsSizing[widget].row : undefined,
          col: WidgetsSizing[widget] ? WidgetsSizing[widget].col : undefined,
        })),
      },
    });

    // After it's closed update the widget order
    dialog.afterClosed().subscribe((widgets) => {
      if (widgets) {
        this.clearskyService.updateWidgets(widgets);
      }
    });

    // Close this dialog after it opens
    dialog.afterOpened().subscribe(() => this.dialogRef.close());
  }

  /**
   * Initialize OCIDs for dialog.
   * @private
   */
  private initOCIDs(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        ...Object.values(this.widgetsDisplay),
        'clearsky.save-label',
        'clearsky.custom-widget-label',
      ])
      .pipe(first())
      .subscribe();
  }

  /**
   * Close dialog and update selected widgets.
   */
  close(): void {
    this.clearskyService.updateWidgets(this.selectedWidgets as string[]);
    this.dialogRef.close();
  }
}
