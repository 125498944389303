import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { Web2CaseService } from '../../web2case.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { PART_OPTION_VALUES } from '../../add-part-options.const';
import { CartService } from 'app/service/cart/cart.service';
import { IOrder } from 'app/contracts/commerce/iorder';
import { ICommerceItemWithCart } from 'app/contracts/commerce/icommerce-item-with-cart';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-add-parts-form',
  styleUrls: ['./add-parts-form.component.scss'],
  templateUrl: './add-parts-form.component.html',
})
export class AddPartsFormComponent implements OnInit, OnChanges {
  @Input() ocids!: OcidItems;
  @Input() complaintCategory!: string;
  @Input() quantityOCID!: string;
  @Input() defaultQty: number = null;
  @Input() noSuffixOrdered: boolean;
  @Input() addPartsForm!: UntypedFormGroup;
  @Input() requiredToAddParts = true;
  @Input() itemNumber!: string;
  subs: Subscription;
  showAddMorePart = true;
  cartItems: ICommerceItemWithCart[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private web2caseService: Web2CaseService,
    private cartService: CartService
  ) { }

  ngOnInit() {
    this.createPartsForm();
    this.subs = this.web2caseService.complaint.subscribe((complaintType: string) => {
      this.complaintCategory = complaintType;
      this.addPartsForm.removeControl('partRows');
      this.createPartsForm();
    });
  }
  createPartsForm() {
    this.addPartsForm.addControl('partRows', new UntypedFormArray([]));
    this.addPartsRow();
    // prepopulate item number(s) from the cookie
    if (this.itemNumber) {
      this.cartService.getCart().subscribe((cart: IOrder) => {
        this.cartItems = cart.commerceItems?.items;
        const itemNumberArr = this.itemNumber.split(',');
        itemNumberArr.forEach((itemNum, index) => {
          const control = this.partRows.controls[index];
          control.patchValue({ PartNoOrdered: itemNum });
          const itemInCart = this.cartItems?.find(item => item.itemNumber === itemNum);
          if (itemInCart) {
            control.patchValue({ PartNoQtyOrdered: itemInCart.quantity });
          }
          if (itemNumberArr.length - 1 > index) {
            this.addPartsRow();
          }
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Set the default quantity for all part row quantity form fields
    // if the default quantity has changed and is not the initial change.
    if (changes.defaultQty && !changes.defaultQty.firstChange) {
      this.partRows.controls.forEach((control: AbstractControl) => {
        control.patchValue({
          PartNoQtyOrdered: changes.defaultQty.currentValue,
        });
      });
    }
  }

  /**
   * Gets the part rows form.
   * @returns {FormArray}
   */
  get partRows(): UntypedFormArray {
    return <UntypedFormArray>this.addPartsForm.get('partRows');
  }

  /**
   * Adds a part row to the form group.
   */
  addPartsRow() {
    this.showAddMorePart = true;
    if (this.partRows.length === 15) {
      return;
    }
    const formGroup = {
      [`PartNoOrdered`]: '',
      [`PartNoQtyOrdered`]: this.defaultQty ? this.defaultQty : '',
      [`MachineNumber`]: '',
    };

    if (PART_OPTION_VALUES.INCORRECT_PART === this.complaintCategory ||
      PART_OPTION_VALUES.QTY_DISCREPANCY === this.complaintCategory ||
      PART_OPTION_VALUES.QUALITY_OF_PART === this.complaintCategory) {
      formGroup['PartNoQtyReceived'] = '';
      this.showAddMorePart = false;
    }

    if (PART_OPTION_VALUES.INCORRECT_PART === this.complaintCategory) {
      formGroup['PartNoReceived'] = '';
    }

    if (PART_OPTION_VALUES.QUALITY_OF_PART === this.complaintCategory) {
      formGroup['PartNoQtyDamaged'] = '';
    }

    if (PART_OPTION_VALUES.QTY_DISCREPANCY === this.complaintCategory || PART_OPTION_VALUES.QUALITY_OF_PART === this.complaintCategory) {
      delete formGroup.MachineNumber;
    }

    if (PART_OPTION_VALUES.OTHERS === this.complaintCategory || !this.complaintCategory) {
      delete formGroup.MachineNumber;
    }
    if (!this.complaintCategory) {
      formGroup['PartDescription'] = '';
    }
    this.partRows.push(this.formBuilder.group(formGroup));
  }

  /**
   * Removes a parts row given its index.
   * @param {number} index
   */
  removePartsRow(index: number) {
    if (this.partRows.length > 1) {
      this.partRows.removeAt(index);
    }
  }
}
