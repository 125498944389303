import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../../../contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { distinctUntilChanged } from 'rxjs';
import { IReturnDetails, ReturnStatus, IReturnDetailsItem } from 'app/contracts/orders/ireturn';
import { NotificationService } from 'app/service/notification/notification.service';
import { InvoiceInformationService } from '../../invoice-information/invoice-information.service';
import { IUser } from 'app/contracts/user/iuser';
import { CurrentUserService } from 'app/service/user/current-user.service';
import { environment } from 'environments/environment';
import { OrderInquiryService } from '../../parts-order-inquiry/order-inquiry.service';
import { FormatDatePipe } from '../../../../../../shared/format-date.pipe';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-rpa-details',
  styleUrls: [
    './rpa-details.component.scss',
    '../rpa-form/rpa-form.component.scss',
  ],
  templateUrl: './rpa-details.component.html',
})
export class RpaDetailsComponent implements OnInit {
  @Input() data!: {
    orderNumber: string;
  };
  ocids: OcidItems = {};
  platformBrowser = false;
  return: IReturnDetails;
  partsCredit = 0;
  restockingFee = 0;
  totalCredit = 0;
  user: IUser;
  returnReason: string;
  brand = environment.jlgStyling ? 'JLG' : 'Jerr-Dan';
  returnReady = false;
  isPending = true;
  formatDatePipe: FormatDatePipe = new FormatDatePipe();
  returnStatus = ReturnStatus;
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private location: Location,
    private localization: LocalizationService,
    private orderService: OrderInquiryService,
    private notificationService: NotificationService,
    private invoiceService: InvoiceInformationService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    // Get OCIDS.
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'rpa.total-potential-credit-label',
        'web2case.parts-return',
        'rpa.return-address-label',
        'web2case.reason-for-return',
        'orderdetails.invoice-number',
        'rpa.table-approved-qty-label',
        'rpa.tabel-received-qty-label',
        'rpa.return-summary label',
        'rpa.submitted-parts-credit-label',
        'rpa.estimated-credit',
        'rpa.freight-credit-label',
        'rpa.restocking-fee-label',
        'rpa.brand-comments-label',
        'rpa.table-return-qty-label'
      ])
      .subscribe();

    // Get the current user to properly format the currency/date
    this.currentUserService.userSubject
      .pipe(distinctUntilChanged())
      .subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });  

    // Get the return details and return reason codes
    if(this.platformBrowser){
      this.orderService
      .getReturnDetails({
        orderNumber: this.data.orderNumber,
      })
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (returnDetail) => {
          this.return = returnDetail;
          this.isPending = this.return.statusCode !== ReturnStatus.APPROVED;
          // Calculate the submitted parts credit using the return amount for each item
          returnDetail.details.forEach((item) => {
            this.partsCredit += +item.returnAmount;
          });
          // Calculate restocking fee since gateway only passes % instead of total amount
          this.restockingFee = this.partsCredit * (returnDetail.restock ? +returnDetail.restock : 0);
          // Calculate the total credit by adding in freight credit and restocking fee
          this.totalCredit =
            this.partsCredit +
            (returnDetail.freight ? +returnDetail.freight : 0) -
            this.restockingFee;
          this.returnReady = true;
        },
        error: (error) => {
          this.notificationService.addError(error.error.title);
        },
      });
    }
  }

  /**
   * Get the invoice image.
   * @param {string} invoiceUrl
   */
  getInvoiceImage(invoiceUrl: string) {
    // Only get the invoice image if the platform is browser.
    if (this.platformBrowser) {
      this.invoiceService
        .getInvoiceImage(invoiceUrl)
        .subscribe((indInvoice: string) => {
          if (window.navigator) {
            // Open the invoice in a new tab for the user to view.
            const newTab = window.open();
            newTab.document.body.innerHTML = `<iframe src='data:application/pdf;base64,${indInvoice}' width='100%' height='100%'></iframe>`;
          }
        });
    }
  }

  /**
   * Return to the previous page (more than likely the returns inquiry page).
   */
  goBack() {
    this.location.back();
  }

  /**
   * Print the page.
   */
  print() {
    // Only execute the following if the platform is browser.
    if (this.platformBrowser) {
      window.print();
    }
  }
}
