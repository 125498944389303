import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { ClearskyService } from '../../../clearsky.service';
import { combineLatest, Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import {
  HistoricSelection,
  Machine,
  MachineDTC,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { WidgetColors } from '../../../../contracts/clearsky/dashboard/cs-colors.dto';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';
import { CSLegend } from '../../../../contracts/clearsky/clearsky-legend';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-historic-dtc-chart',
  templateUrl: './machine-historic-dtc-chart.component.html',
  styleUrls: ['./machine-historic-dtc-chart.component.scss'],
})
export class MachineHistoricDtcsChartComponent implements OnInit, OnChanges {
  @Input() machine: Machine;
  @Input() historic = HistoricSelection.DAYS_90;
  chartEl: ElementRef;
  @ViewChild('chartEl') set content(content: ElementRef) {
    if (content) {
      this.chartEl = content;
      this.createChart();
    }
  }
  displayName = WidgetsDisplay.hardwareReportingStatus;
  widgetName = Widgets.HARDWARE_REPORTING_METRIC;
  machines: Machine[] = [];
  dtcs: MachineDTC[] = [];
  ocids: OcidItems = {};
  showChart = false;
  isLoading = true;
  private plot;
  legend: CSLegend;
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.subs = combineLatest([
      this.localization.getOCIDs([
        this.displayName,
        'clearsky.number-label',
        'clearsky.graphic-default',
      ]),
      this.clearskyService.legendRef$,
    ]).subscribe(([ocids, legend]) => {
      this.ocids = ocids;
      this.legend = legend;
      this.showChart = true;
      this.isLoading = false;
      this.updateDatasource(this.historic);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.historic && !changes.historic.isFirstChange()) {
      this.updateDatasource(changes.historic.currentValue);
    }
  }

  /**
   * Set the data source by historic value.
   * @private
   */
  updateDatasource(historic: string): void {
    if (!this.legend) {
      return;
    }
    this.dtcs = (this.machine.dtcT ?? []).reduce((prev, dtc) => {
      const dtcFromLegend = (this.legend.dtc || []).find(
        (item) => item.id == dtc.id
      );
      if (dtcFromLegend) {
        const convertedDTC: MachineDTC = { ...dtc, ...dtcFromLegend };
        const dtcTime = dayjs(convertedDTC.st);
        const historicTimestamp = this.getTimeByHistoric(historic);

        if (
          dtcTime.isBetween(historicTimestamp, this.todaysTimestamp, null, '()')
        ) {
          prev.push(convertedDTC);
        }
      }
      return prev;
    }, [] as MachineDTC[]);
    // Does the chart already exist?
    if (this.plot && this.showChart) {
      this.setChartData();
    }
  }

  /**
   * Get the time we need to determine between todays timestamp for dtcs to show.
   * @param historic
   * @private
   */
  private getTimeByHistoric(historic: string): dayjs.Dayjs {
    switch (historic) {
      case HistoricSelection.DAYS_7:
        return this.todaysTimestamp.subtract(1, 'week');
      case HistoricSelection.DAYS_14:
        return this.todaysTimestamp.subtract(2, 'weeks');
      case HistoricSelection.DAYS_31:
        return this.todaysTimestamp.subtract(31, 'days');
      case HistoricSelection.DAYS_90:
        return this.todaysTimestamp.subtract(90, 'days');
      default:
        return this.todaysTimestamp.subtract(1, 'days');
    }
  }

  /**
   * Get today's timestamp.
   * @private
   */
  private get todaysTimestamp(): dayjs.Dayjs {
    return dayjs();
  }

  /**
   * Set data for chart.
   * @protected
   */
  protected setChartData(): void {
    // Redraw data points
    this.plot.series[0].setData(this.getSeriesData());
  }

  /**
   * Create the chart.
   * @protected
   */
  protected createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }
    const categories = this.getSeriesData().map((item) => item.custom.key);
    const chartData = mergeDeep(cloneDeep(BaseChartConfig), {
      chart: {
        type: 'bar',
      },
      xAxis: {
        gridLineWidth: 1,
        categories,
        type: 'category',
        labels: {
          enabled: true,
        },
        title: {
          text: 'DTC',
          align: 'high',
          rotation: 0,
          x: 10,
          y: 10,
          style: {
            fontWeight: 'bold',
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        tickInterval: 1,
        title: {
          text: 'Count',
          style: {
            fontWeight: 'bold',
          },
        },
      },
      tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.custom.key}</b>: {point.y} Faults',
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          pointWidth: 15,
          color: WidgetColors.blue,
          cursor: 'pointer',
          data: this.getSeriesData(),
        },
      ],
    });

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      chartData as unknown
    );
  }

  protected getSeriesData() {
    let index = 0;
    return this.dtcs.reduce((result, item) => {
      const existingItem = result.find((entry) => entry.custom.key == item.id);
      const label = this.legend.dtc.find((l) => l.id === item.id);
      if (existingItem) {
        existingItem.y++;
      } else {
        result.push({
          x: index,
          y: 1,
          name: label?.desc,
          custom: {
            key: label.id,
          },
        });
        index++;
      }
      return result;
    }, []);
  }
}
