import { IOrderDetails } from './iorder-detail-orderDetails';
import { IOrderDetailShipments } from './iorder-detail-shipments';
import { IAddress } from '../user/iaddress';
import { IOrderSpecialCharges } from './iorder-detail-specialCharges';

export enum OrderPurchaseType {
  PARTS = 'PARTS',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface IOrderDetail {
  broker?: string;
  broker2?: string;
  broker2Desc?: string;
  brokerDesc?: string;
  calculatedSubtotal?: number;
  calculatedTax?: number;
  calculatedTotal?: number;
  comOrderNumber?: string;
  companyNumber?: string;
  contactName?: string;
  contactNumber?: string;
  coreReturnAmountTotal?: number;
  coreReturnExist?: boolean;
  creditHold?: string;
  crud?: string;
  csrUser?: string;
  currencyCode?: string;
  customerNumber?: string;
  deliveryMethod?: string;
  deliveryMethod2?: string;
  erpEnvironment?: string;
  freight?: string;
  freight2?: string;
  freight2Desc?: string;
  freightDesc?: string;
  incoTerm?: string;
  incoTerm2?: string;
  incoTerm2Desc?: string;
  incoTermDesc?: string;
  languageCode?: string;
  localCustomer?: string;
  namedPlace1?: string;
  namedPlace2?: string;
  orderComments?: string;
  orderDate?: string;
  convertedOrderDate?: string;
  orderNumber?: string;
  orderSource?: string;
  orderStatus?: string;
  orderType?: string;
  poNumber?: string;
  purchaseType: OrderPurchaseType;
  refNumber?: string;
  requestID?: string;
  returnEligible?: boolean;
  salesType?: string;
  salesTypeText?: string;
  seCompanyCode?: string;
  seCustomerId?: string;
  seReferenceNum?: string;
  sellWarehouse?: string;
  shipToNumber?: string;
  shipVia2?: string;
  shipVia2Desc?: string;
  shipViaAccount?: string;
  shipViaAccount2?: string;
  shipViaCC2?: string;
  shipViaCode?: string;
  shipViaCodeDesc?: string;
  shipWarehouse?: string;
  shipViaWarehouseDesc?: string;
  shippingInstruction?: string;
  shippingMethod?: string;
  storeId?: string;
  surcharge?: number;
  terms?: string;
  timeDateStamp?: string;
  user?: string;
  freightQuote?: {
    freightQuotedOrder?: string;
    freightTaxAmount?: string;
    totalQuotedFreightAmount?: string;
  };
  orderDetails?: IOrderDetails[];
  specialCharges?: IOrderSpecialCharges[];
  payments?: [
    {
      authorization?: string;
      creditCardId?: string;
      orderId?: string;
      paymentPro?: string;
      token?: string;
      typeOfPayment?: string;
    }
  ];
  customerCommunications?: [
    {
      body?: string;
      code?: string;
    }
  ];
  shippingAddress?: IAddress;
  shipments?: IOrderDetailShipments[];
}

export interface IReturnSelection {
  itemNumber: string;
  invoiceNumber: string;
}

export const TypeOfPayment = {
  PAYPAL: 'P',
};

export const ShippingMethod = {
  PARCEL: 'Primary',
  TRUCK: 'Secondary',
  BOTH: 'ALL',
};
