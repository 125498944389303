import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../../../contracts/user/iuser';
import { first, map } from 'rxjs/operators';
import { ClearskyLevels } from '../../../contracts/clearsky/customer/customer.dto';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { BingMapsService } from '../../../service/bing-maps.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-map-options',
  templateUrl: './map-options.component.html',
  styleUrls: ['./map-options.component.scss'],
})
export class MapOptionsComponent implements OnInit {
  @Input() isMapView: boolean;
  @Output() toggleMapView: EventEmitter<boolean> = new EventEmitter<boolean>();
  showMapOptions = true;
  user$: Observable<IUser> = this.currentUserService.userSubject;
  isAdvancedLevel$: Observable<boolean> = this.user$.pipe(
    map((user) => user.clearSkyAccessLevel === ClearskyLevels.ADVANCED)
  );
  protected userLocation: Microsoft.Maps.Location;
  ocids: OcidItems = {};

  constructor(
    private currentUserService: CurrentUserService,
    private bingMaps: BingMapsService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.local-machine-connection-label',
      ])
      .pipe(first())
      .subscribe();
    this.bingMaps.initialize().subscribe(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.userLocation = new Microsoft.Maps.Location(
            position.coords.latitude,
            position.coords.longitude
          );
        });
      } else {
        console.log('No support for geolocation');
      }
    });
  }
}
