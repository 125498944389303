import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar.component';
import { ProgressBarWithTextModule } from './progress-bar-with-text/progress-bar-with-text.module';

@NgModule({
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent, ProgressBarWithTextModule],
  imports: [CommonModule, ProgressBarWithTextModule],
})
export class ProgressBarModule {}
