import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IAddress, IAddressDetails } from '../../../../contracts/user/iaddress';
import { INavMenu, IUserNavMenus } from '../../../../contracts/user/inav-menu';
import { IUser, UserEnvironments } from '../../../../contracts/user/iuser';
import { NotificationService } from '../../../../service/notification/notification.service';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { MenuService } from '../../../../service/user/menu.service';
import { UserAddressesService } from '../../../../service/user/user-addresses.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { DuplicateShippingAddressDialogComponent } from '../account-address-book/duplicate-shipping-address-dialog/duplicate-shipping-address-dialog.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shipping-address-form',
  templateUrl: './shipping-address-form.component.html',
  providers: [NotificationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingAddressFormComponent implements OnInit, OnDestroy {
  @Output() submitted: EventEmitter<IAddress> = new EventEmitter();
  @Output() cancelled: EventEmitter<any> = new EventEmitter();
  @Output()
  createShiptoSubmitted: EventEmitter<boolean> = new EventEmitter();
  @Input('allowDefault') defaultOption?: boolean = false;
  @Input() showButtons?: boolean = true;
  @Input() isEMEAUser = false;
  addressForm: UntypedFormGroup;
  user: IUser;
  ocids: {} = {};
  countries: INavMenu[] = [];
  states: INavMenu[] = [];
  addressValidated = false;
  emeaRequestSubmitted = false;
  isChineseUser: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private currentUserService: CurrentUserService,
    private addressService: UserAddressesService,
    private localization: LocalizationService,
    private menuService: MenuService,
    private confirmationService: ConfirmationDialogsService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // Localization
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'account.make-default-text',
        'address-validation.message-7',
        'add-address.invalid-contact-number',
        'ee.recieved-ship-to-message',
        'shipping.company-name-error',
        'shipping.address1-error',
        'shipping.country-error',
        'shipping.state-province-error',
        'shipping.city-error',
        'shipping.zip-postal-error',
        'shipto.error-company-name-apostrophe',
      ])
      .subscribe();

    // Get user
    combineLatest([
      this.currentUserService.userSubject,
      this.menuService.menus$,
    ])
      .pipe(
        mergeMap((result: [IUser, IUserNavMenus]) => {
          const user: IUser = result[0];
          const menus: IUserNavMenus = result[1];
          if (user) {
            this.user = user;
            this.isChineseUser = user.erpEnvironment === UserEnvironments.CC;
            const validationPattern = this.isChineseUser? [] : [Validators.pattern(/^[A-Za-z 0-9'-]+$/)];
            // Add controls to parent form/build form
            this.addressForm = this.fb.group({
              customerName: ['', [Validators.required, ...validationPattern]],
              address1: ['', [Validators.required]],
              address2: [''],
              address3: [''],
              city: ['', [Validators.required]],
              country: this.isEMEAUser
                ? this.fb.control({
                  value: user.billingAddress
                    ? user.billingAddress.country
                    : '',
                  disabled: true,
                })
                : ['', Validators.required],
              state: '',
              postalCode: ['', [Validators.required]],
              contactName: ['', [Validators.required, ...validationPattern]],
              phoneNumber: [
                '',
                [Validators.required, Validators.pattern(/^[0-9()+-.ext ]*$/)],
              ]
            });
            if (this.isChineseUser) {
              this.defaultOption = false;
            } else if (this.defaultOption && !this.isEMEAUser) {
              this.addressForm.addControl('makeDefault', this.fb.control(''));
            }
            if (menus) {
              if (user.accountType == 'Retail') {
                return this.menuService.getMenuByUxKey('retailCountries');
              } else {
                return this.menuService.getMenuByUxKey('countries');
              }
            }
          }
          return of(null);
        })
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.countries = menu.childMenus;
        }
      });
  }

  /**
   * Event listener for adding address.
   */
  onAddAddress(event) {
    this.notificationService.reset();
    if (this.addressForm.valid) {
      const address = <IAddress>this.addressForm.value;
      if (this.isEMEAUser) {
        address['country'] = this.addressForm.get('country').value;
      }
      // We're now validating all addresses except UK, IE, and EI. US uses UPS and the rest of the countries use DHL
      if (
        !this.addressValidated &&
        address.country != 'UK' &&
        address.country != 'IE' &&
        address.country != 'EI'
      ) {
        this.addressService.isAddressValid(address).subscribe(
          (res) => {
            if (res === 'valid') {
              this.onSaveAddress(address);
            } else {
              this.addressService
                .confirmAddress(res, address)
                .subscribe((result) => {
                  if (result) {
                    this.addressValidated = true;
                    this.addressForm.controls['address1'].setValue(
                      this.toTitleCase(result.addressLine)
                    );
                    this.addressForm.controls['city'].setValue(
                      this.toTitleCase(result.city)
                    );
                    this.addressForm.controls['state'].setValue(result.state);
                    this.addressForm.controls['postalCode'].setValue(
                      result.zip
                    );
                    this.onSaveAddress(<IAddress>this.addressForm.value);
                  }
                });
            }
          },
          (error) => {
            // status 400 is being used for DHL errors
            if (error.error.httpStatus === 400) {
              this.confirmationService.confirm(
                this.ocids['address-validation.message-7'],
                ''
              );
            } else {
              this.notificationService.addError(error.error.title);
            }
          }
        );
      } else {
        this.onSaveAddress(address);
      }
    }
  }

  /**
   * Saves a valid address.
   * @param {IAddress} validAddress
   */
  onSaveAddress(validAddress: IAddress) {
    const isDefault = this.defaultOption && this.addressForm.controls.makeDefault ? this.addressForm.controls.makeDefault.value : false;
    // Ugly how we do this but contact name is not required in FE but
    // GATEWAY won't accept '' or missing value... Future enhancement?
    validAddress.contactName = this.user.firstName + ' ' + this.user.lastName;
    validAddress.makeDefault = isDefault;
    (this.addressService
      .addValidAddress(
        validAddress
      ) as Observable<IAddressDetails>)
      .subscribe(
        (value: IAddressDetails) => {
          if (value.duplicateAddress) {
            this.dialog
              .open(DuplicateShippingAddressDialogComponent, {
                width: '720px',
                data: {
                  allowMakeDefault: this.defaultOption,
                  makeDefault: isDefault,
                  addresses: value.duplicateAddresses,
                  newAddress: validAddress
                }
              }).afterClosed()
              .subscribe((address) => {
                this.submitted.emit(address);
              });
          } else {
            // Add to addresses list
            this.submitted.emit(value.address);
          }
        },
        (error: any) => {
          // Show error at top of form

          this.notificationService.addError(error.error.title);
        }
      );
  }

  ngOnDestroy() {
    if (this.changeDetector) {
      this.changeDetector.detach();
    }
  }

  /**
   * Event emitted when selecting country from dropdown.
   *
   * @param {MatSelect} value
   */
  onChangeCountry(value: MatSelect) {
    // Populate states dropdown
    const country: INavMenu = this.countries.find(
      (menu: INavMenu) => menu.uxKey === value.value
    );
    this.addressValidated = false;
    this.states = country.childMenus;
  }

  /**
   * On cancel click.
   */
  onCancel() {
    this.cancelled.emit();
  }

  // GETTERS
  get address1() {
    return this.addressForm.get('address1');
  }
  get address2() {
    return this.addressForm.get('address2');
  }
  get address3() {
    return this.addressForm.get('address3');
  }
  get city() {
    return this.addressForm.get('city');
  }
  get postalCode() {
    return this.addressForm.get('postalCode');
  }
  get state() {
    return this.addressForm.get('state');
  }
  get country() {
    return this.addressForm.get('country');
  }
  get customerName() {
    return this.addressForm.get('customerName');
  }
  get contactName() {
    return this.addressForm.get('contactName');
  }
  get phoneNumber() {
    return this.addressForm.get('phoneNumber');
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
