import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, mergeMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {OcidItems} from '../../contracts/ocid-items';

interface IOCIDResponse {
  functionOnPage: string;
  ocid: string;
  message: string;
}

@Injectable()
export class LocalizationService {
  private OCIDs$: OcidItems = {};
  public OCIDs: BehaviorSubject<OcidItems> = new BehaviorSubject<OcidItems>({});
  private timer: Observable<any>;
  private callableOcids: string[] = [];

  constructor(private http: HttpClient) {}

  /**
   * Get a list of OCIDs (per page behavior).
   *
   * @param {Array<string>} ocids
   * @param {boolean} [clearPayloadCache=false]
   * @returns {Observable<{}>}
   */
  public getOCIDs(
    ocids: string[],
    clearPayloadCache = false
  ): Observable<OcidItems> {
    const currentOcids = Object.keys(this.OCIDs.getValue());
    const newOcids = ocids.filter((value) => !currentOcids.includes(value));

    // Concat new ocids in ones that may have already been requested
    this.callableOcids = this.callableOcids.concat(newOcids);

    // Clear old timer so it restarts
    if (this.timer) {
      this.timer = null;
    }

    // Make actual request if timer is still here after half a second
    this.timer = timer(150);
    return this.timer.pipe(
      mergeMap(() =>
        this.makeOCIDRequest(this.callableOcids, clearPayloadCache)
      )
    );
  }

  /**
   * Make OCID request.
   * @param ocids
   * @param clearPayloadCache
   * @private
   */
  private makeOCIDRequest(
    ocids: string[],
    clearPayloadCache = false
  ): Observable<OcidItems> {
    // Reset callable ocids since request is going through
    this.callableOcids = [];

    // If ocids is empty, then just return old value
    if (!ocids.length) {
      return this.OCIDs;
    }

    const ocidString = ocids.join(',');
    const headers = {};
    // If we need to clear the payload cache for the request, add that header to the request.
    if (clearPayloadCache) {
      headers['clear-payload-cache'] = 'true';
    }
    const httpOptions = {
      headers: new HttpHeaders(headers),
    };
    return this.http
      .post(
        `${environment.apiUrl}/message/ocidsList`,
        {
          'ocids': ocidString,
        },
        httpOptions
      )
      .pipe(
        map((response: IOCIDResponse[]) => {
          this.OCIDs$ = response.reduce((prev, ocid: IOCIDResponse) => {
            prev[ocid.ocid] = ocid.message;
            return prev;
          }, this.OCIDs$);
          this.OCIDs.next(this.OCIDs$);

          return this.OCIDs$;
        })
      );
  }

  /**
   * Refresh list of OCIDs when locale is updated.
   *
   * @returns {Observable<{}>}
   */
  public refreshOCIDs(): Observable<OcidItems> {
    return this.http
      .post(`${environment.apiUrl}/message/ocidsList`, {
        'ocids': Object.keys(this.OCIDs.getValue()).join(','),
      })
      .pipe(
        map((response: IOCIDResponse[]) => {
          this.OCIDs$ = response.reduce((prev, ocid: IOCIDResponse) => {
            prev[ocid.ocid] = ocid.message;
            return prev;
          }, this.OCIDs$);
          this.OCIDs.next(this.OCIDs$);
          return this.OCIDs$;
        })
      );
  }

  /**
   * Method used to localize the labels for paginators.
   */
  setPaginationLocalization() {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel =
      this.OCIDs.getValue()['global.items-per-page'];
    paginatorIntl.nextPageLabel = this.OCIDs.getValue()['pagination.next-page'];
    paginatorIntl.previousPageLabel =
      this.OCIDs.getValue()['pagination.previous-page'];
    paginatorIntl.firstPageLabel =
      this.OCIDs.getValue()['pagination.first-page'];
    paginatorIntl.lastPageLabel = this.OCIDs.getValue()['pagination.last-page'];
    paginatorIntl.getRangeLabel = getRangeLabel;
  }
}

/**
 * Function used by the paginator to set its range label.
 * @param {number} page
 * @param {number} pageSize
 * @param {number} length
 */
function getRangeLabel(page: number, pageSize: number, length: number): string {
  if (length === 0 || pageSize === 0) {
    return `0 ${this.OCIDs.getValue()['pagination.of']} ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} ${
    this.OCIDs.getValue()['pagination.of']
  } ${length}`;
}
