import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Machine } from '../../../../../../contracts/clearsky/machine/machine.dto';
import { OcidItems } from '../../../../../../contracts/ocid-items';
import { CSLegend } from '../../../../../../contracts/clearsky/clearsky-legend';
import { ClearskyService } from '../../../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { LocalizationService } from 'app/shared/localization/localization.service';

interface DialogData {
  machine: Machine;
  status: number;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-access-control-dialog',
  templateUrl: './asset-access-control-dialog.component.html',
})
export class AssetAccessControlDialogComponent implements OnInit {
  legend: CSLegend = {};
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<AssetAccessControlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clearskyService: ClearskyService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.remotely-lockout-label',
        'clearsky.confirm-and-apply-label'
      ])
      .pipe(first())
      .subscribe();
  }
}
