import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-search-adjustments',
  styleUrls: ['./search-adjustments.component.scss'],
  templateUrl: './search-adjustments.component.html',
})
export class SearchAdjustmentsComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    originalTerms: string[];
    totalNumRecs: number;
  };
  ocids = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Get ocids.
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'browse.top-result',
        'browse.search-result-label-auto-correct-line1',
        'browse.search-result-label-auto-correct-line2',
      ])
      .subscribe();
  }
}
