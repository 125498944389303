import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-alert-dialog',
  templateUrl: './subscription-alert-dialog.component.html',
})
export class SubscriptionAlertDialogComponent implements OnInit {
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<SubscriptionAlertDialogComponent>,
    private localization: LocalizationService,
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky-subscription.zero-machines-error-title',
        'clearsky-subscription.zero-machines-error-text'
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
