import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-results-list',
  templateUrl: './results-list.component.html',
})
export class ResultsListComponent
  implements OnInit, AfterViewInit, CartridgeInterface
{
  @Input() data: {
    firstRecNum: number;
    lastRecNum: number;
    recsPerPage: number;
    totalNumRecs: number;
    precomputedSorts: object[];
    sortOptions: object[];
    records: object[];
    pagingActionTemplate: {
      navigationState: string;
      contentPath: string;
    };
    zeroSearchResultsPage: string; // url
  };
  pageSizeOptions: number[] = [12, 24, 48];
  currentPage = 0;
  currentSize = 12;
  pageEvent: PageEvent;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.data.records);

    this.currentPage = Math.floor(
      this.data.firstRecNum / this.data.recsPerPage
    );
    this.currentSize = this.data.recsPerPage;
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe((e: any) => {
      this.onNavigate(e);
    });
  }

  onNavigate(e) {
    // No = offset starting at 0, Nrpp = results per page
    const params = Object.assign({}, this.route.snapshot.queryParams);
    params['No'] = e.pageSize * e.pageIndex;
    params['Nrpp'] = e.pageSize;
    const url = [];

    // Loop through segments and build router commands
    this.route.snapshot.url.forEach((segment: UrlSegment) => {
      url.push(segment.path);
    });

    // Navigate user to new url to get new payload (hopefully API endpoint will be made in future as enhancement)
    this.router.navigate(url, {
      queryParams: params,
      state: { scrollTop: window.scrollY },
    });

    return e;
  }
}
