import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationService {

  private _errors: BehaviorSubject<string[]> = new BehaviorSubject([]);
  readonly errors$: Observable<string[]> = this._errors.asObservable();

  constructor() {}

  /**
   * Add error.
   *
   * @param {string} message
   */
  addError(message: string) {
    const errors = this._errors.getValue();
    errors.push(message);
    this._errors.next(errors);
  }

  /**
   * Reset console.
   */
  reset() {
    this._errors.next([]);
  }

}
