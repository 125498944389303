import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { Observable, first } from 'rxjs';
import { IUser, UserEnvironments } from '../../../contracts/user/iuser';
import { LayoutService } from '../../../service/layout.service';
import { MachineDetailsDialogComponent } from '../../machines/machine-details-dialog/machine-details-dialog.component';
import { MachineDialogConfig } from '../../../contracts/clearsky/machine/machine.dialog.config';
import { BingMapsService } from '../../../service/bing-maps.service';
import {
  Machine,
  MachineAccessRestrictionIcons,
} from '../../../contracts/clearsky/machine/machine.dto';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyMapService } from '../clearsky-map.service';
import { ClearskyService } from 'app/clearsky/clearsky.service';
import { CSLegend } from 'app/contracts/clearsky/clearsky-legend';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-pushpin-infobox',
  templateUrl: './asset-pushpin-infobox.component.html',
})
export class AssetPushpinInfoboxComponent implements OnChanges, OnInit {
  @Input() map: Microsoft.Maps.Map;
  @Input() selectedMachine: Machine;
  @Input() selectedTargetLocation: Microsoft.Maps.Location;
  @Output() infoBoxClosed: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('infoBoxTemplate') infoBoxTemplate: ElementRef;
  user$: Observable<IUser> = this.currentUserService.userSubject;
  userEnvironments = UserEnvironments;
  isChatOnline = true;
  selectedFormattedLocation: Microsoft.Maps.IAddress;
  accessRestrictionIcons = MachineAccessRestrictionIcons;
  legend: CSLegend = {};
  private infoBox: Microsoft.Maps.Infobox;
  ocids: OcidItems = {};

  constructor(
    private currentUserService: CurrentUserService,
    private layoutService: LayoutService,
    private bingMaps: BingMapsService,
    private dialog: MatDialog,
    private clearskyMapService: ClearskyMapService,
    private clearskyService: ClearskyService,
    private localization: LocalizationService,
    private cdRef: ChangeDetectorRef
  ) {
    this.isChatOnline = this.layoutService.isChatDisabled();
  }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.ignition-label',
        'clearsky.details-label',
        'header.chat-link',
      ])
      .pipe(first())
      .subscribe();

    this.infoBox = new Microsoft.Maps.Infobox(this.map.getCenter(), {
      visible: false,
    });

    this.clearskyService.legendRef$.subscribe(
      (legend) => (this.legend = legend)
    );

    // Assign the infobox to the map instance.
    this.infoBox.setMap(this.map);
    Microsoft.Maps.Events.addHandler(
      this.infoBox,
      'click',
      this.handleClickInfoBox.bind(this)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMachine && !changes.selectedMachine.isFirstChange()) {
      this.setInfoboxContent();
    }
  }

  private setInfoboxContent(): void {
    if (!this.selectedMachine) {
      this.infoBox.setOptions({ visible: false });
      return;
    }

    // Set the infobox options with the metadata of the pushpin.
    this.bingMaps
      .getAddressByGeo(
        this.selectedMachine.loc.lat,
        this.selectedMachine.loc.lng
      )
      .subscribe((location) => {
        this.selectedFormattedLocation = location ?? null;
        this.cdRef.detectChanges();

        this.infoBox.setOptions({
          location: this.selectedTargetLocation,
          htmlContent: this.infoBoxTemplate.nativeElement.innerHTML,
          visible: true,
        });
        const infoBoxWidth = this.infoBox.getWidth();
        const infoBoxHeight = this.infoBox.getHeight();
        this.infoBox.setOptions({ visible: false });

        // Now center user to pushpin location minus half the width & height of the info box
        // to avoid it going off screen.
        this.map.setView({
          center: this.infoBox.getLocation(),
          centerOffset: new Microsoft.Maps.Point(
            -infoBoxWidth * 0.5,
            infoBoxHeight * 0.5
          ),
        });

        this.infoBox.setOptions({ visible: true });
      });
  }

  /**
   * Handle event when user clicks on the infobox.
   * @param e
   * @protected
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleClickInfoBox(e: { originalEvent: { target: any } }): void {
    let element = e.originalEvent.target;
    const classes = [...element.classList];

    // Hacky way of doing it, but don't want to attach event listeners every time a different pin is clicked
    if (classes.includes('action-icon')) {
      element = element.parentElement;
    }

    // Close functionality
    if (element.id === 'info-box-close') {
      this.infoBox.setOptions({ visible: false });
      this.infoBoxClosed.emit();
      this.clearskyMapService.clearMachinePinClicked();
    }

    // More details functionality
    if (element.id === 'more-details') {
      this.openMoreDetails();
    }

    // Open chat slide in
    if (element.id === 'open-chat') {
      this.openChat();
    }

    // Get directions
    if (element.id === 'go-directions') {
      this.getDirections();
    }
  }

  /**
   * Open more details slide-in for this machine.
   * @protected
   */
  private openMoreDetails(): void {
    if (this.selectedMachine) {
      // Show slide in dialog with machine data
      this.dialog.open(MachineDetailsDialogComponent, {
        ...MachineDialogConfig,
        data: {
          sn: this.selectedMachine.sn,
          formattedAddress: this.selectedFormattedLocation,
        },
      });
    }
  }

  /**
   * Open chat.
   * @protected
   */
  private openChat(): void {
    this.layoutService.showChatMenu = true;
  }

  /**
   * Get directions to machine.
   * @protected
   */
  private getDirections(): void {
    this.bingMaps.getDirections(
      this.selectedMachine.loc.lat,
      this.selectedMachine.loc.lng
    );
  }
}
