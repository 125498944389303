import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { AccountType, IUser } from '../../../contracts/user/iuser';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { LayoutService } from '../../../service/layout.service';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { ContentService } from '../../../service/content.service';

interface ChatOptionsData {
  icon: string;
  widgetId: number;
  ocid: string;
  time: string;
  online: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-chat',
  styleUrls: ['./chat.component.scss'],
  templateUrl: './chat.component.html',
})
export class ChatComponent implements OnInit {
  @Input() user!: IUser;
  chatOnline = true;
  showMsg = false;
  ocids = {};
  serviceOnline = true;
  chatOptions!: ChatOptionsData[];
  hasChatCookie = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    public layoutService: LayoutService,
    private localization: LocalizationService,
    private router: Router,
    private winRef: WindowRefService,
    private contentService: ContentService
  ) {
    // When any user lands on PDP and chat is online, show msg after 5 seconds and close after 10 seconds.
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        startWith(this.router)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url.match(/\/parts\/?.*\/p\/.*/)) {
          // Disabling cookie check for now;
          // this.hasChatCookie = this.hasChatCookie || this.contentService.getCookie('hideChatMsg');
          if (this.chatOnline) {
            setTimeout(() => {
              this.showMsg = true;
              // DISABLE FOR NOW - Create a session cookie to hide the chat message bubble after it's been closed
              // this.contentService.createCookie('hideChatMsg', 'true', '0');
              // this.hasChatCookie = true;
            }, 5000);
            setTimeout(() => {
              this.showMsg = false;
            }, 15000);
          }
        }
      });
  }

  ngOnInit() {
    // Get OCIDs for page (including global)
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs(['navigation.service', 'chat.time-zone-label'])
      .subscribe();
    // check time to determine whether chat is online or offline
    this.chatOnline = this.layoutService.isChatDisabled();
    this.serviceOnline = this.layoutService.isServiceDisabled();
    this.chatOptions = [
      {
        icon: 'shopping_cart',
        widgetId: 4,
        ocid: 'global.parts',
        time: 'M-F 8:00 AM to 6:00 PM',
        online: this.chatOnline,
      },
      {
        icon: 'construction',
        widgetId: 5,
        ocid: 'navigation.service',
        time: 'M-F 8:00 AM to 4:45 PM',
        online: this.serviceOnline,
      },
      {
        icon: 'verified',
        widgetId: 6,
        ocid: 'global.warranty',
        time: 'M-F 8:00 AM to 4:45 PM',
        online: this.serviceOnline,
      },
    ];
  }

  chatToggle(): void {
    this.layoutService.showChatMenu = !this.layoutService.showChatMenu;
    this.showMsg = false;
  }

  onChatMsgClick(): void {
    // Start the chat widget immediately if it's a retail user, otherwise show chatMenu
    if (this.user.accountType === AccountType.RETAIL) {
      this.chatStart(8);
    } else {
      this.chatToggle();
    }
  }

  chatStart(widgetId: number): void {
    this.chatToggle();
    if (isPlatformBrowser(this.platformId)) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: 'chatStart',
        chat: {
          widget: widgetId,
          companyName: this.user.companyName,
          accountNumber: this.user.customerNumber,
          customerId: this.user.login,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
        },
      });
    }
  }
}
