import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IVerifyTokenResponse } from '../../../contracts/recovery/iverify-token-response';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { ForgotUnlockWrapperService } from './forgot-unlock-wrapper.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-forgot-unlock-wrapper',
  styleUrls: ['./forgot-unlock-wrapper.component.scss'],
  templateUrl: './forgot-unlock-wrapper.component.html',
})
export class ForgotUnlockWrapperComponent implements OnInit {
  isResetPassword = true;
  recoveryQuestion = '';
  recoveryStateToken = '';
  newPassStateToken = '';
  isValidRecoveryToken = false;
  recoverySuccessful = false;
  isAnswerRecoveryQuestion = false;
  pageTitle = '';
  ocids = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private wrapperService: ForgotUnlockWrapperService,
    private localization: LocalizationService
  ) {}

  ngOnInit() {
    this.isResetPassword = this.router.url.indexOf('reset-password') > -1;
    // Get the recovery token from the URL.
    const recoveryToken = this.route.snapshot.url[1].toString();
    // Verify the token (can be used for unlock or forgot password tokens).
    this.verifyRecoveryToken(recoveryToken);

    // Get OCIDs.
    this.localization.OCIDs.pipe(distinctUntilChanged()).subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'email.link-expired-technical-issue',
        'resetpassword.security-question-instruction',
        'resetpassword.password-reset-message',
        'resetpassword.create-new-message',
        'signin.unlock-security-questions',
        'resetpassword.password-reset',
        'signin.unlock-account-link',
      ])
      .subscribe(() => {
        // Set the page title.
        this.setPageTitle();
      });
  }

  /**
   * Sets the page title.
   */
  setPageTitle() {
    this.pageTitle = this.isResetPassword
      ? this.ocids['resetpassword.password-reset']
      : this.ocids['signin.unlock-account-link'];
  }

  /**
   * Event listener for when a token has expired.
   *
   * @param {any} event
   */
  tokenExpired(event) {
    this.isValidRecoveryToken = false;
  }

  /**
   * Event listener for when recovery answer is a success.
   *
   * @param {string} stateToken
   */
  recoveryAnswerSuccess(stateToken: string) {
    this.isResetPassword = true;
    this.setPageTitle();
    this.isAnswerRecoveryQuestion = true;
    this.newPassStateToken = stateToken;
  }

  /**
   * Event listener for when reset password is successful.
   *
   * @param {any} event
   */
  recoverySuccess(event) {
    this.recoverySuccessful = true;
    this.wrapperService.isForgotPassword.next(false);
  }

  /**
   * Method for verifying the token.
   *
   * @param {string} recoveryToken
   */
  verifyRecoveryToken(recoveryToken: string) {
    this.notificationService.reset();
    this.wrapperService
      .verifyRecoveryToken({
        recoveryToken: recoveryToken,
      })
      .subscribe(
        (res: IVerifyTokenResponse) => {
          this.isValidRecoveryToken = true;
          this.recoveryQuestion = res.question;
          this.recoveryStateToken = res.stateToken;
        },
        (error) => {
          this.isValidRecoveryToken = false;
          if (error.error['o:errorCode'] === 'E0000011') {
            this.notificationService.addError(
              this.ocids['email.link-expired-technical-issue']
            );
          } else {
            this.notificationService.addError(error.error.title);
          }
        }
      );
  }
}
