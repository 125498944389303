import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { concatMap } from 'rxjs/operators';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { IOrder } from '../../../contracts/commerce/iorder';
import { CartService } from '../../../service/cart/cart.service';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { NotificationService } from '../../../service/notification/notification.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { ICommerceItemWithCart } from '../../../contracts/commerce/icommerce-item-with-cart';
import { IUser } from '../../../contracts/user/iuser';
import { ProductsApiService } from '../../../service/product/products-api.service';
import { IProduct } from '../../../contracts/product/iproduct';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LayoutService } from '../../../service/layout.service';
import { isPlatformBrowser } from '@angular/common';
import { OcidItems } from '../../../contracts/ocid-items';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: '[app-cart-preview]',
  styleUrls: ['./cart-preview.component.scss'],
  templateUrl: './cart-preview.component.html',
})
export class CartPreviewComponent implements OnInit {
  @Input() platformBrowser!: boolean;
  @Input() user!: IUser;
  @ViewChild('cartPreview', { static: true }) cartPreview!: ElementRef;
  ocids: OcidItems = {};
  shoppingCartPreview!: IOrder;
  shoppingCartCount = 0;
  cartItems: ICommerceItemWithCart[] = [];
  previewForm: UntypedFormGroup = this.fb.group({
    items: this.fb.array([]),
  });
  orderNowForm: UntypedFormGroup = this.fb.group({
    itemNum: ['', Validators.required],
    qty: [],
  });
  invalidItemNum = false;
  itemNum = '';
  isCollapsed = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService,
    private cartService: CartService,
    private confirmationService: ConfirmationDialogsService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder,
    private productsService: ProductsApiService,
    private ecommService: EcommerceService,
    public layoutService: LayoutService
  ) { }

  // Define form properties
  get itemGroups() {
    return this.previewForm.get('items') as UntypedFormArray;
  }

  get orderNowItem() {
    return this.orderNowForm.get('itemNum');
  }

  ngOnInit() {
    // Get OCIDs for page (including global)
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'shopping-list.remove-item',
        'buy.item-subtotal',
        'buy.remove-item',
        'buy.shopping-cart-remove',
        'browse.no-result-text',
        'buy.item-number-invalid',
        'buy.item-number-error',
        'buy.shopping-cart-order-now',
        'shopping-cart-preview.label',
        'shopping-cart-preview.special-conditions',
        'shopping-cart-preview.charges-message',
        'shopping-cart-preview.empty-message',
        'shopping-cart-preview.view-message',
      ])
      .subscribe();

    this.cartService.cartPreviewSubject.subscribe((cart: IOrder) => {
      // clear the itemgroup array first
      this.itemGroups.clear();
      this.shoppingCartPreview = cart;
      this.cartItems = cart.commerceItems?.items
        ? cart.commerceItems.items
        : [];
      this.shoppingCartCount = cart.totalCommerceItemCount
        ? cart.totalCommerceItemCount
        : 0;

      for (let i = 0; i < this.cartItems.length; i++) {
        this.addItemGroup(this.cartItems[i].quantity);
      }
    });
  }

  /**
   * After cart preview is open.
   */
  onCartShown() {
    if (isPlatformBrowser(this.platformId)) {
      // Get the emergency alert + header height to set the margin of the cart preview element dynamically
      const alert = <HTMLElement>(
        document.querySelector('.emergency-alerts-container')
      );
      const header = <HTMLElement>document.querySelector('.header');
      const alertHeight = alert ? alert.offsetHeight : 0;
      const headerHeight = header ? header.offsetHeight : 0;
      this.cartPreview.nativeElement.style.top =
        alertHeight + headerHeight + 'px';
    }
  }

  /**
   * Remove item from cart.
   *
   * @param {ICommerceItemWithCart} item
   */
  removeItem(item: ICommerceItemWithCart) {
    this.notificationService.reset();
    this.cartService
      .removeFromCart(item, 'Cart Preview', this.user.customerNumber)
      .pipe(
        concatMap(() => {
          return this.cartService.getCartWithPricing();
        })
      )
      .subscribe(
        () => {
          this.cartService.refreshCart();
        },
        (err) => {
          console.log(err);
          this.notificationService.addError(err.error?.title);
        }
      );
  }

  /**
   * Event listener for when cart item quantity is updated.
   *
   * @param {number} newQty
   * @param {ICommerceItemWithCart} item
   */
  public onUpdateQuantity(newQty: number, item: ICommerceItemWithCart) {
    const oldQty = item.quantity;
    // only run update if quantity has changed
    this.notificationService.reset();
    if (!newQty) {
      this.notificationService.addError(this.ocids['global.invalid-qty']);
      return;
    }
    if (newQty !== oldQty) {
      this.cartService
        .updateQuantity(item, newQty)
        .pipe(
          concatMap(() => {
            return this.cartService.getCartWithPricing();
          })
        )
        .subscribe(
          () => {
            this.cartService.refreshCart();
            // send add or remove event to GA if the platform is browser
            if (this.platformBrowser) {
              const source = 'Cart Preview - Update Qty';
              if (newQty > oldQty) {
                this.ecommService.addToCart(
                  item.itemNumber,
                  item.categoryName,
                  item.productDisplayName,
                  +(newQty - oldQty),
                  source
                );
              } else {
                this.ecommService.remove(
                  item,
                  source,
                  +(oldQty - newQty),
                  this.user.customerNumber,
                  this.user.companyName,
                );
              }
            }
          },
          (err) => {
            // Log error

            this.notificationService.addError(err.error?.title);
          }
        );
    }
  }

  /**
   * Removes all items in the cart.
   */
  onRemoveAll() {
    // Show dialog first
    this.confirmationService
      .confirm(
        this.ocids['buy.remove-item'],
        this.ocids['buy.shopping-cart-remove'],
        this.ocids['global.remove'],
        this.ocids['global.cancel']
      )
      .pipe(
        concatMap((result) => {
          if (result) {
            // Remove all items ( ugly for now since only one item can be removed at a time, future enhancement? )

            return this.cartService.removeAll(this.cartItems, 'Cart Preview');
          }
        })
      )
      .subscribe(
        () => {
          this.cartService.refreshCart();
        },
        (err) => {
          this.notificationService.addError(err.error?.title);
        }
      );
  }
  /**
   * Determines if the item is found or not. If the item is not found, it sets a not found
   * error on the form control. Otherwise it resets the form control errors.
   */
  checkItemFound() {
    const field = this.orderNowItem;
    if (field?.value !== '') {
      this.productsService
        .getProduct(field?.value)
        .subscribe((product: IProduct) => {
          // Check if item was found
          if (product.itemNotFound) {
            field?.setErrors({ itemNotFound: true });
          } else {
            field?.setErrors(null);
          }
        });
    }
  }

  /**
   * Event emitter when order now form is submitted.
   *
   * @return void
   */
  addItem() {
    // Make request
    if (this.orderNowForm.valid) {
      this.notificationService.reset();
      const itemNum = this.orderNowForm.get('itemNum')?.value;
      // Sets the item quantity to the quantity form control but if the quantity is not given
      // given we default the quantity to 1 and add it to the cart.
      const itemQty = this.orderNowForm.get('qty')?.value
        ? this.orderNowForm.get('qty')?.value
        : 1;

      this.cartService.addOneToCart(itemNum, +itemQty).subscribe({
        next: (item) => {
          this.orderNowForm.reset();

          this.cartService.refreshCart();
          // Send Add to Cart action to GA if the platform is browser
          if (this.platformBrowser) {
            this.ecommService.addToCart(
              itemNum,
              item.categoryName,
              '',
              +itemQty,
              'Cart Preview - Order Now'
            );
          }
        },
        error: (err) => {
          console.log(err);
          this.notificationService.addError(err.error?.title);
        }
      });
    } else if (
      this.orderNowItem?.errors ? this.orderNowItem.errors['required'] : false
    ) {
      // If there are errors on the item number and it is the required error, set the required error
      // on the form control to true and mark the input as dirty.
      this.orderNowItem?.setErrors({ required: true });
      this.orderNowItem?.markAsDirty();
    }
  }

  /**
   * Add form group to item group.
   *
   * @param {number} quantity
   * @return {FormGroup}
   */
  protected addItemGroup(quantity: number) {
    const group = this.fb.group({
      quantity: [quantity],
    });
    this.itemGroups.push(group);

    return group;
  }
}
