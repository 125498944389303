import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class PayPalLoader {
  private static promise: Promise<string>;

  public static load() {
    if (!PayPalLoader.promise) {
      // Make promise to load
      PayPalLoader.promise = new Promise((resolve) => {
        const node = document.createElement('script');
        node.src = `https://www.paypal.com/sdk/js?client-id=${environment.payPalMerchantId}&components=messages`;
        node.attributes['data-namespace'] = 'PayPalSDK';
        document.getElementsByTagName('head')[0].appendChild(node);
      });
    }

    return PayPalLoader.promise;
  }
}
