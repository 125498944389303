import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BingMapsService {
  private apiUrl = 'https://dev.virtualearth.net/REST/v1';
  private initialized = false;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  public initialize(): Observable<boolean> {
    if (this.initialized) {
      return of(true);
    }
    const callBackName = `bingmapsLib${new Date().getMilliseconds()}`;
    const scriptUrl = `https://www.bing.com/api/maps/mapcontrol?callback=${callBackName}&key=${environment.BING_URL}`;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.src = scriptUrl;
    script.charset = 'utf-8';
    document.head.appendChild(script);
    const scriptPromise = new Promise<boolean>((resolve, reject) => {
      window[callBackName] = () => {
        this.initialized = true;
        resolve(true);
      };
      script.onerror = (error: Event) => {
        console.log(error);
        reject(false);
      };
    });
    return from(scriptPromise);
  }

  /**
   * Get address from bing maps based on lat and long.
   * @param lat
   * @param long
   */
  getAddressByGeo(
    lat: number | string,
    long: number | string
  ): Observable<Microsoft.Maps.IAddress> {
    return this.http
      .get<any>(
        `${this.apiUrl}/Locations/${lat},${long}?includeEntityTypes=address&key=${environment.BING_URL}`
      )
      .pipe(
        map((data) => {
          const firstSet = data.resourceSets.shift();
          if (firstSet.estimatedTotal > 0) {
            const resource = firstSet.resources.shift();
            return resource.address as Microsoft.Maps.IAddress;
          }

          return null;
        }),
        catchError((err) => {
          return of(null);
        })
      );
  }

  /**
   * Get directions from user's location to another location.
   * @param lat
   * @param long
   */
  getDirections(lat: number | string, long: number | string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          window.open(
            `https://bing.com/maps/default.aspx?rtp=pos.${position.coords.latitude}_${position.coords.longitude}~pos.${lat}_${long}&rtop=0~1~0`
          );
        });
      } else {
        console.log('No support for geolocation');
      }
    }
  }

  /**
   * Get direction from user's location to another address.
   * @param address
   */
  getDirectionsByAddress(address: string): void {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          window.open(
            `https://bing.com/maps/default.aspx?rtp=pos.${
              position.coords.latitude
            }_${position.coords.longitude}~adr.${address.replace(
              ' ',
              '%20'
            )}&rtop=0~1~0`
          );
        });
      } else {
        console.log('No support for geolocation');
      }
    }
  }
}
