import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CreditCardService } from '../../account/account-payment-preferences/credit-card.service';
import { CCIcons, ICreditCard } from '../../../../contracts/user/icredit-card';
import { IUser, UserEnvironments } from '../../../../contracts/user/iuser';
import { CurrentUserService } from '../../../../service/user/current-user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { IAddress } from '../../../../contracts/user/iaddress';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { CreditCardDialogComponent } from '../../account/account-payment-preferences/credit-card-dialog/credit-card-dialog.component';
import {
  IPaymentInfo,
  PaymentOptions,
} from '../../../../contracts/commerce/dto/ipayment-info';
import { NotificationService } from '../../../../service/notification/notification.service';
import { CartService } from '../../../../service/cart/cart.service';
import { ICodeAndDesc } from '../../../../contracts/commerce/icode-and-desc';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HttpResponse } from '@angular/common/http';
import { OcidItems } from '../../../../contracts/ocid-items';
import { concatMap, first } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { ConfirmationDialogsService } from '../../../../shared/confirmation-dialog/confirmation-dialog.service';
import { PayPalLoader } from '../../../../service/product/paypal-loader.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-payment-form',
  styleUrls: ['./payment-form.component.scss'],
  templateUrl: './payment-form.component.html',
  providers: [NotificationService], // Unique instance so errors thrown here only display in this component, not globally
})
export class PaymentFormComponent implements OnInit, OnChanges {
  @Input() isActive!: boolean;
  @Input() cartTotal: string;
  @Input() env: string;
  @Input() shipToAddress!: IAddress;
  @Output() formSubmitted: EventEmitter<object> = new EventEmitter<object>();
  @Output() toggleForm: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() selectCC: EventEmitter<ICreditCard> =
    new EventEmitter<ICreditCard>();
  formWasSubmitted = false;
  paymentForm!: UntypedFormGroup;
  poRequired = true;
  selectedCC!: ICreditCard;
  billingAddr!: IAddress;
  creditCards: ICreditCard[] = [];
  user!: IUser;
  isReady = false;
  ocids: OcidItems = {};
  canUseCC = false;
  canUseAccount = false;
  canUsePayPal = false;
  accountSelected = false;
  paypalSelected = false;
  paypalVerified = false;
  ccSelected = false;
  ccIcons = CCIcons;
  monthlyPayments = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private fb: UntypedFormBuilder,
    private ccService: CreditCardService,
    private userService: CurrentUserService,
    private cartService: CartService,
    public dialog: MatDialog,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationDialogsService
  ) {}

  ngOnInit() {
    // Setup localization
    this.localization.OCIDs.subscribe((ocids) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'payment.choose-payment-method',
        'payment.credit-card-label',
        'payment.select-card-error',
        'payment.add-credit-card',
        'payment.credit-card-billing',
        'checkout.review-order-label',
        'creditcard.delete-message',
        // PaymentFormReviewComponent OCIDs
        'payment.cc-expiration',
      ])
      .pipe(first())
      .subscribe();

    // Build payment form
    this.paymentForm = this.fb.group({
      poNum: [null],
      paymentOption: [null, Validators.required],
    });

    // Get user/billing address information
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;

        // Set poNumber required to false if the user is retail.
        if (user.accountType === 'Retail') {
          this.poRequired = false;
        }

        // Check to see if user has default billing address
        if (user.billingAddress) {
          this.billingAddr = <IAddress>user.billingAddress;
        }
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.data === 'paypalVerified' &&
            event.origin.indexOf('jlg.com') !== -1
          ) {
            return this.cartService
              .verifyPayPal('Checkout')
              .pipe(first())
              .subscribe({
                next: (resp: HttpResponse<unknown>) => {
                  this.paypalVerified = true;
                },
                error: (error) => {
                  this.notificationService.addError(error.error.title);
                },
              });
          }
        },
        false
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the changes were on isActive and the current value is true, get the payment options available.
    if (changes.isActive) {
      if (changes.isActive.currentValue) {
        // Get the user's payment options.
        this.notificationService.reset();
        this.cartService.getPaymentOptions().subscribe({
          next: (paymentMethods: ICodeAndDesc[]) => {
            this.canUseCC = !!paymentMethods.find(
              (paymentMethod: ICodeAndDesc) =>
                paymentMethod.code === PaymentOptions.CreditCard
            );
            this.canUsePayPal = !!paymentMethods.find(
              (paymentMethod: ICodeAndDesc) =>
                paymentMethod.code === PaymentOptions.PayPal
            );
            this.canUseAccount = !!paymentMethods.find(
              (paymentMethod: ICodeAndDesc) =>
                paymentMethod.code === PaymentOptions.MyAccount
            );
            // Add conditional fields
            if (this.canUseCC) {
              this.paymentForm.addControl(
                'creditCard',
                this.fb.control(null, [Validators.required])
              );
              this.paymentForm
                .get('paymentOption')
                .setValue(PaymentOptions.CreditCard);
              this.getCreditCards();
              this.ccSelected = true;
              this.accountSelected = false;
              this.paypalSelected = false;
            }
            if (this.canUseAccount) {
              // If the user can pay with their account, set that as their default.
              this.paymentForm
                .get('paymentOption')
                .setValue(PaymentOptions.MyAccount);
              this.accountSelected = true;
              this.ccSelected = false;
              this.paypalSelected = false;
              this.paymentForm.removeControl('creditCard');
            }
            if (this.canUsePayPal && this.env === UserEnvironments.AA) {
              // load the paypal script if user has paypal option and is AA
              PayPalLoader.load();
              this.monthlyPayments = true;
            }
            this.isReady = true;
          },
          error: (error) => {
            this.notificationService.addError(error.error.title);
          },
        });
      }
    }
  }

  /**
   * When form is submitted.
   */
  onSubmit() {
    this.formWasSubmitted = true;
    if (this.paymentForm.valid) {
      const data = <IPaymentInfo>{
        ...{
          billTo: this.selectedCC
            ? this.selectedCC.billingAddress
            : this.billingAddr,
        },
        ...this.paymentForm.value,
      };

      if (this.paymentOption.value === PaymentOptions.CreditCard) {
        data['creditCard'] = this.selectedCC;
      }

      this.formSubmitted.emit(data);
    }
  }

  /**
   * When a user selected a credit card from drop down.
   *
   * @param {MatSelectChange} select
   */
  onSelectCreditCard(select: MatSelectChange) {
    const card: ICreditCard = this.creditCards.find(
      (value: ICreditCard) => value.id === select.value
    );
    this.selectedCC = card;
    this.selectCC.emit(card);
  }

  /**
   * When payment option is selected.
   */
  onSelectPaymentOption() {
    const value = this.paymentOption.value;
    this.accountSelected = value === PaymentOptions.MyAccount;
    this.ccSelected = value === PaymentOptions.CreditCard;
    this.paypalSelected = value === PaymentOptions.PayPal;
    if (this.ccSelected) {
      // Add credit card fields to form
      this.paymentForm.addControl(
        'creditCard',
        this.fb.control(this.selectedCC ? this.selectedCC.id : null, [
          Validators.required,
        ])
      );
      this.getCreditCards();
    } else {
      // Remove credit card fields from form
      this.selectedCC = null;
      this.selectCC.emit(null);
      this.paymentForm.removeControl('creditCard');
      if (this.paypalSelected) {
        return this.cartService.initPayPal(true).pipe(first()).subscribe();
      }
    }
  }

  /**
   * Event emitted when adding a credit card.
   */
  onAddCreditCard() {
    this.dialog
      .open(CreditCardDialogComponent, {
        data: {
          isAddCreditCard: true,
          orderShippingAddress: this.shipToAddress,
        },
        width: '720px',
      })
      .afterClosed()
      .subscribe((data?: ICreditCard) => {
        if (data) {
          this.getCreditCards();
          this.paymentForm.get('creditCard').setValue(data.id);

          this.selectedCC = data;
          this.selectCC.emit(data);
        }
      });
  }

  /**
   * Method to consolidate the retrieval of credit cards.
   */
  getCreditCards() {
    // Get current user credit cards
    this.notificationService.reset();
    this.ccService.getCreditCards().subscribe(
      (cards: ICreditCard[]) => {
        this.creditCards = cards;
      },
      (error) => {
        this.notificationService.addError(error.error.title);
      }
    );
  }

  /**
   * Event emitted when removing a credit card.
   *
   * @param {ICreditCard} card
   */
  onDeleteCard(card: ICreditCard) {
    // Confirm the user wants to delete the card.
    this.confirmationService
      .confirm(
        this.ocids['creditcard.delete-message'],
        '',
        this.ocids['global.delete'],
        this.ocids['global.cancel']
      )
      .subscribe((result) => {
        if (result) {
          // Make request to delete the credit card and then fetch the current credit cards again
          // (deleteCreditCard returns empty response)
          this.ccService
            .deleteCreditCard(card)
            .pipe(
              concatMap(() => {
                return this.ccService.getCreditCards();
              })
            )
            .subscribe({
              next: (cards: ICreditCard[]) => {
                this.creditCards = cards;
              },
              error: (error) => {
                this.notificationService.addError(error.error.title);
              },
            });
        }
      });
  }

  // Get payment option form value
  get paymentOption() {
    return this.paymentForm.get('paymentOption');
  }
}
