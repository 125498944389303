import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from '../../../../service/notification/notification.service';
import { ForgotUnlockWrapperService } from '../forgot-unlock-wrapper.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';
import { customValidation } from '../../../../shared/password-validator.directive';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-new-password-form',
  styleUrls: ['../../user-registration/user-registration.component.scss'],
  templateUrl: './new-password-form.component.html',
  providers: [NotificationService],
})
export class NewPasswordFormComponent implements OnInit {
  @Input('newPassStateToken') stateToken = '';
  @Output() tokenExpired: EventEmitter<any> = new EventEmitter();
  @Output() recoverySuccess: EventEmitter<any> = new EventEmitter();
  ocids: OcidItems = {};
  newPasswordForm: UntypedFormGroup;
  newPassFailureMessage = '';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private wrapperService: ForgotUnlockWrapperService
  ) {}

  ngOnInit() {
    // Get OCIDs.
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'resetpassword.password-no-match',
        'account-profile.password-message-1',
      ])
      .subscribe();
    // Set net password form.
    this.newPasswordForm = this._formBuilder.group(
      {
        login: [''],
        password: [
          '',
          [
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[!@#$%^&*])).+$/
            ),
            Validators.minLength(8),
          ],
        ],
        confirmPassword: [''],
      },
      {
        validator: [customValidation()],
      }
    );
  }

  /**
   * Verify the user's security token and update their password.
   */
  onPasswordSubmit() {
    if (this.newPasswordForm.valid) {
      this.newPassFailureMessage = '';
      this.notificationService.reset();
      this.wrapperService
        .verifySecurityTokenAndUpdatePassword({
          stateToken: this.stateToken,
          newPassword: this.newPasswordForm.get('password').value,
        })
        .subscribe(
          () => {
            this.recoverySuccess.emit();
          },
          (error) => {
            if (error.error['o:errorCode'] === 'E0000080') {
              // New password rules not met or matches current password.
              this.newPassFailureMessage = error.error.title;
            } else if (error.error['o:errorCode'] === 'E0000011') {
              // If the token has expired, emit an event that sets token validity boolean to false and show error.
              this.tokenExpired.emit();
            } else {
              // All other errors.
              this.notificationService.addError(error.error.title);
            }
          }
        );
    }
  }

  get password() {
    return this.newPasswordForm.controls.password;
  }
}
