import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-blocks-item-sub',
  templateUrl: './grid-blocks-item-sub.component.html',
  styleUrls: ['./grid-blocks-item-sub.component.scss'],
  host: {'class': 'grid-blocks-item-sub'}
})
export class GridBlocksItemSubComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
