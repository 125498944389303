import { CsAggData } from './agg-data';
import { Machine } from './machine/machine.dto';
import { MachineFilterSelection } from './machine/machine-filter-v2';
import { BehaviorSubject } from 'rxjs';
import { SiteNetwork } from './site-network';

export const CsRequestKeys = {
  fleetAge: 'opHoursSO',
  siteNetwork: 'sitenetworkPage',
  quickAsset: 'assetQuickSO',
  assetView: 'assetPage',
  mapView: 'mapPage',
  listView: 'listPage',
  dashView: 'dashPage',
  dtcBubbleSO: 'dtcBubbleSO',
  filterVals: 'filterVals',
};

export interface CsMxRequestBody {
  filters?: MachineFilterSelection[];
  paginate?: boolean;
  pageNum?: number;
  pageSize?: number;
  sorting?: {
    column: string;
    direction: string;
  };
}

export const enum CsRequestExpect {
  AGG_DATA = 'AGG_DATA',
  MX_DATA = 'MX_DATA',
}

export const CsRequestKeysExpect: { [expect: string]: string[] } = {
  [CsRequestExpect.AGG_DATA]: [CsRequestKeys.dashView],
};

export const CsRequestKeysToColumns: { [key: string]: string[] } = {
  [CsRequestKeys.fleetAge]: [
    'sn',
    'cn',
    'eid',
    'copHrs',
    'mxAge',
    'model',
    'brand',
  ],
  [CsRequestKeys.siteNetwork]: ['sn', 'cn', 'snID', 'snEn', 'snEx'],
  [CsRequestKeys.quickAsset]: [
    'sn',
    'cn',
    'eid',
    'model',
    'mtype',
    'mgroup',
    'brand',
    'ar',
    'loc',
    'ign',
    'soc',
    'socQ',
    'flvl',
    'flvlQ',
    'pwrSrc',
    'dtc',
    'copHrs',
    'connStat'
  ],
  [CsRequestKeys.assetView]: ['*'],
  [CsRequestKeys.mapView]: [
    'sn',
    'cn',
    'loc',
    'model',
    'mtype',
    'mgroup',
    'eid',
    'csDev',
    'ar',
    'snID',
    'alerts',
    'ign',
  ],
  [CsRequestKeys.listView]: [
    'alerts',
    'sn',
    'cn',
    'mxAge',
    'mxAgeQ',
    'brand',
    'pwrSrc',
    'rep',
    'tid',
    'dtc',
    'eid',
    'model',
    'mtype',
    'mgroup',
    'asrc',
    'lrt',
    'csDev',
    'ar',
    'flvlQ',
    'copHrs',
    'sstat',
    'ign',
    'hrs',
    'dolc',
    'defRmQ',
    'socQ',
    'yb',
    'sofVer',
    'sofStat',
    'hib',
    'pvc'
  ],
  [CsRequestKeys.dashView]: [
    'sn',
    'cn',
    'eid',
    'tid',
    'pwrSrc',
    'csDev',
    'uidt',
    'ign',
    'copHrs',
    'copHrsQ',
    'mxAge',
    'mxAgeQ',
  ],
  [CsRequestKeys.dtcBubbleSO]: [
    'sn',
    'cn',
    'eid',
    'model',
    'mtype',
    'mgroup',
    'dtc',
    'loc',
    'ar',
    'ign',
  ],
  [CsRequestKeys.filterVals]: ['model'],
};

export const CsReqKeyImgReq = [
  CsRequestKeys.quickAsset,
  CsRequestKeys.assetView,
  CsRequestKeys.mapView,
];

export interface CsMachineRequest {
  aggregations?: CsAggData;
  machines?: Machine[] | SiteNetwork[];
  currentPage?: number;
  totalPages?: number;
  totalMachines?: number;
}

export interface CsRequestKeyStorageItem {
  key: string;
  data?: CsMachineRequest;
  filters: MachineFilterSelection[];
  pending: BehaviorSubject<boolean>;
}
