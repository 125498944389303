import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-form-with-background-image',
  styleUrls: ['./form-with-background-image.component.scss'],
  templateUrl: './form-with-background-image.component.html',
})
export class FormWithBackgroundImageComponent {
  @Input() data!: {
    form: {
      '@type': string;
    };
    imagePaddingBottom: number;
    imagePaddingLeft: number;
    imagePaddingRight: number;
    imagePaddingTop: number;
    imageSrcUrl: string;
  };
  placeholderImg = environment.imagePath + environment.placeholderImg;

  constructor() {}
}
