import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface ILatestNewsItem {
  backgroundColor: string;
  imageTag: string;
  imageTitle: string;
  imageUrl: string;
  content: string;
  textOverlay: string;
  title: string;
  url?: string;
  externalUrl?: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-latest-news',
  styleUrls: ['./latest-news.component.scss'],
  templateUrl: './latest-news.component.html',
})
export class LatestNewsComponent implements OnInit, CartridgeInterface {
  @Input() data!: {
    messages: ILatestNewsItem[];
    newsHeading: string;
  };
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    this.localization
      .getOCIDs([
        'home.message-board-read-less',
        'home.message-board-read-more',
      ])
      .subscribe();
  }
}
