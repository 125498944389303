import { Component, Input, OnInit } from '@angular/core';
import { EcommerceService } from 'app/service/gtm/ecommerce-service';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements OnInit, CartridgeInterface {
  @Input() data: {
    refinementCrumbs: {
      ancestors: Record<string, unknown>[];
      count: number;
      dimensionName: string;
      displayName: string;
      label: string;
      multiSelect: boolean;
    }[];
    searchCrumbs: Record<string, unknown>[];
  };

  constructor(private ecommService: EcommerceService) {}

  ngOnInit() {
    // Send category data to GA4
    if (this.data.refinementCrumbs[0]?.displayName) {
      this.ecommService.selectCategory(
        this.data.refinementCrumbs[0].displayName,
        this.data.refinementCrumbs[0].label
      );
    }
  }
}
