import { ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import {
  BaseChartConfig,
  getZoneScale,
} from '../../../../contracts/clearsky/machine/machine.chart.config';
import { mergeDeep } from '../../../../shared/deep-merge';
import * as cloneDeep from 'lodash.clonedeep';

export abstract class EnvelopeProfileChart {
  chartSVG;
  protected zones: number[];

  /**
   * Create scissor lift chart.
   * @param chartEl
   * @param getZoneData
   * @param options
   * @param config
   */
  createChart(
    chartEl: ElementRef,
    getZoneData: Function,
    options: { typeDescription?: string; tooltip?: Function } = {},
    config: object = {}
  ): Highcharts.Chart {
    const self = this;

    return Highcharts.chart(
      chartEl.nativeElement,
      mergeDeep(
        cloneDeep(BaseChartConfig),
        mergeDeep(
          {
            chart: {
              className: 'overflow-out',
              backgroundColor: 'transparent',
              height: '100%',
              margin: [0, 20, 0, 20],
              events: {
                load: function () {
                  self.zones = getZoneData();
                  self.drawZones(this, options.tooltip);
                },
                redraw: function () {
                  // Remove old
                  self.chartSVG.destroy();
                  self.zones = getZoneData();
                  self.drawZones(this, options.tooltip);
                },
              },
            },
            accessibility: {
              typeDescription: options.typeDescription || null,
            },
          },
          config
        )
      )
    );
  }

  /**
   * Draw zones (determine placements of labels and rects).
   * @param svg
   * @param tooltip
   * @protected
   */
  protected abstract drawZones(svg: Highcharts.Chart, tooltip?: Function): void;

  /**
   * Render the tooltip.
   * @param svg
   * @param zone
   * @param data
   * @param text
   * @protected
   */
  protected renderTooltip(
    svg: Highcharts.Chart,
    zone: number,
    data: { x: number; y: number },
    text: string
  ): Highcharts.SVGElement {
    return svg.renderer
      .label(text, data.x, data.y, 'rect', undefined, undefined, false)
      .css({
        color: '#333',
        pointerEvents: 'none',
        whiteSpace: 'nowrap',
        width: 160,
        fontSize: '12px',
        border: '1px solid black',
      })
      .attr({
        fill: 'white',
        stroke: 'black',
        'stroke-width': '1',
        padding: 8,
        r: 5,
        zIndex: 6,
        opacity: 0,
        visibility: 'hidden',
      })
      .add(this.chartSVG);
  }

  /**
   * Render the rectangle for the zone.
   * @param svg
   * @param zone
   * @param data
   * @protected
   */
  protected renderRect(
    svg: Highcharts.Chart,
    zone: number,
    data: {
      x: number;
      y: number;
      width: number;
      height: number;
      tooltip: Highcharts.SVGElement;
      radius?: number;
    }
  ): Highcharts.SVGElement {
    // Get color for zone
    const scale = getZoneScale(this.zones);

    const panelRect = svg.renderer
      .rect(
        data.x,
        data.y,
        data.width,
        data.height,
        data.radius ? 0 : data.radius
      )
      .css({
        cursor: data.tooltip ? 'pointer' : 'auto',
      })
      .attr({
        fill: scale[this.zones[zone - 1]],
        stroke: 'black',
        'stroke-width': 1,
      })
      .add(this.chartSVG);

    // Only add mouseovers if tooltip needs to show
    if (data.tooltip) {
      panelRect
        .on('mouseover', function () {
          panelRect.attr({
            stroke: '#b5b5b5',
            'stroke-width': 6,
          });
        })
        .on('mousemove', function () {
          data.tooltip.attr({
            opacity: 1,
            visibility: 'visible',
          });
        })
        .on('mouseout', function () {
          data.tooltip.attr({
            opacity: 0,
            visibility: 'hidden',
          });

          panelRect.attr({
            stroke: 'black',
            'stroke-width': 1,
          });
        });
    }

    panelRect.add();

    return panelRect;
  }

  /**
   * Render the zone label.
   * @param svg
   * @param zone
   * @param data
   * @protected
   */
  protected renderLabel(
    svg: Highcharts.Chart,
    zone: number,
    data: { x: number; y: number }
  ): Highcharts.SVGElement {
    return this.renderText(svg, this.zones[zone - 1].toString(), data);
  }

  /**
   * Render a text label.
   * @param svg
   * @param text
   * @param data
   * @protected
   */
  protected renderText(
    svg: Highcharts.Chart,
    text: string,
    data: { x: number; y: number }
  ): Highcharts.SVGElement {
    return svg.renderer
      .label(text, data.x, data.y)
      .css({
        color: '#000',
        fontSize: '12px',
      })
      .add(this.chartSVG);
  }

  /**
   * Amount of zones in data.
   */
  get zoneCount(): number {
    return this.zones.length;
  }
}
