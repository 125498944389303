import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckoutComponent } from './checkout.component';
import { ChangeAddressComponent } from './change-address/change-address.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { ShippingFormComponent } from './shipping-form/shipping-form.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { ShippingFormReviewComponent } from './shipping-form/shipping-form-review/shipping-form-review.component';
import { PaymentFormReviewComponent } from './payment-form/payment-form-review/payment-form-review.component';
import { CheckoutReviewComponent } from './checkout-review/checkout-review.component';
import { ShippingAddressSectionComponent } from './shipping-address-section/shipping-address-section.component';
import { ShippingAddressSectionReviewComponent } from './shipping-address-section/shipping-address-section-review/shipping-address-section-review.component';
import { ShippingFreightTypeFormComponent } from './shipping-form/shipping-freight-type-form/shipping-freight-type-form.component';
import { FreightItemsTableComponent } from './freight-items-table/freight-items-table.component';
import { CheckoutFreightTypeReviewComponent } from './shipping-form/shipping-form-review/checkout-freight-type-review/checkout-freight-type-review.component';
import { SharedModule } from '../../../shared/shared.module';
import { WarehouseListComponent } from './warehouse-list/warehouse-list.component';

@NgModule({
  declarations: [
    CheckoutComponent,
    ChangeAddressComponent,
    OrderConfirmationComponent,
    ShippingFormComponent,
    PaymentFormComponent,
    ShippingFormReviewComponent,
    PaymentFormReviewComponent,
    CheckoutReviewComponent,
    ShippingAddressSectionComponent,
    ShippingAddressSectionReviewComponent,
    ShippingFreightTypeFormComponent,
    FreightItemsTableComponent,
    CheckoutFreightTypeReviewComponent,
    WarehouseListComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class CheckoutModule {}
