import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IOrder } from '../../../contracts/commerce/iorder';
import { OrderInquiryService } from '../../../endeca/cartridges/account/account-orders/parts-order-inquiry/order-inquiry.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { OcidItems } from '../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-subscription-order-confirmation',
  templateUrl: './subscription-order-confirmation.component.html',
  styleUrls: ['./subscription-order-confirmation.component.scss'],
})
export class SubscriptionOrderConfirmationComponent implements OnInit {
  @Input() data!: {
    subscriptionOrderNumber: string;
  };
  order$!: Observable<IOrder>;
  qrImg: string | undefined;
  ocids: OcidItems = {};

  constructor(
    private orderService: OrderInquiryService,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    this.localization
      .getOCIDs([
        'csv-qr.button-label',
        'csv-qr.title',
        'csv-qr.subtitle',
        'clearsky.enter-clearsky-label',
        'clearsky.sign-up-label',
        'clearsky.order-confirmation-number-label',
        'checkout.order-confirmation',
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.order$ = this.orderService.getOrderById(
      this.data.subscriptionOrderNumber
    );
    this.qrImg = environment.trueProduction
      ? 'https://www.jlg.com/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/83530-source'
      : 'https://www.jlg.com/dfsmedia/e4042b10c9ce4595b4cc059f1299f079/83529-source';
  }
}
