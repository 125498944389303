import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClearskyComponent } from './clearsky.component';
import { SharedModule } from '../shared/shared.module';
import { SaveDashboardDialogComponent } from './dashboard/save-dashboard-dialog/save-dashboard-dialog.component';
import { MapComponent } from './map/map.component';
import { ClearskyService } from './clearsky.service';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../shared/material.module';
import { AlertBannerModule } from '../shared/alert-banner/alert-banner.module';
import { ListComponent } from './machines/list/list.component';
import { FuelLevelComponent } from './machines/widgets/fuel-level/fuel-level.component';
import { SettingsDialogComponent } from './machines/widgets/settings-dialog/settings-dialog.component';
import { MachineDetailsDialogComponent } from './machines/machine-details-dialog/machine-details-dialog.component';
import { NavToggleComponent } from './header/nav-toggle/nav-toggle.component';
import { RefreshButtonComponent } from './header/refresh-button/refresh-button.component';
import { EngineStatusComponent } from './machines/widgets/engine-status/engine-status.component';
import { DefLevelComponent } from './machines/widgets/def-level/def-level.component';
import { WidgetHeaderComponent } from './machines/widgets/widget-header/widget-header.component';
import { WidgetFooterComponent } from './machines/widgets/widget-footer/widget-footer.component';
import { WidgetContentComponent } from './machines/widgets/widget-content/widget-content.component';
import { WidgetTitleComponent } from './machines/widgets/widget-title/widget-title.component';
import { DtcWidgetComponent } from './machines/widgets/dtc-widget/dtc-widget.component';
import { MachineDetailComponent } from './machines/machine-detail/machine-detail.component';
import { GeneralMachineInfoComponent } from './machines/machine-detail/widgets/general-machine-info/general-machine-info.component';
import { MachineLevelBarComponent } from './machines/machine-level-bar/machine-level-bar.component';
import { LocationWidgetComponent } from './machines/machine-detail/widgets/location-widget/location-widget.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ResourcesComponent } from './resources/resources.component';
import { ClearskyDashboardService } from './clearsky-dashboard.service';
import { WidgetDialogComponent } from './machines/widgets/widget-dialog/widget-dialog.component';
import { EngineComponentDialogComponent } from './machines/machine-detail/engine-component-dialog/engine-component-dialog.component';
import { FuelPressureComponent } from './machines/charts/engine-component/fuel-pressure/fuel-pressure.component';
import { FuelConsumptionComponent } from './machines/charts/engine-component/fuel-consumption/fuel-consumption.component';
import { LastReportedComponent } from './machines/widgets/last-reported/last-reported.component';
import { ModelTypeComponent } from './machines/widgets/model-type/model-type.component';
import { WidgetService } from './widget.service';
import { WidgetItemComponent } from './machines/widgets/widget-item/widget-item.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { MachineMapComponent } from './machines/machine-map/machine-map.component';
import { MachineWidgetItemComponent } from './machines/machine-detail/widgets/machine-widget-item/machine-widget-item.component';
import { MachineWidgetDialogComponent } from './machines/machine-detail/widgets/machine-widget-dialog/machine-widget-dialog.component';
import { DtcBubbleDialogComponent } from './machines/widgets/dtc-widget/dtc-bubble-dialog/dtc-bubble-dialog.component';
import { FieldDisplayPipe } from './field-display.pipe';
import { DTCTooltipPipe } from './dtc-tooltip.pipe';
import { EngineFuelPressureComponent } from './machines/widgets/engine-fuel-pressure/engine-fuel-pressure.component';
import { EngineFuelConsumptionComponent } from './machines/widgets/engine-fuel-consumption/engine-fuel-consumption.component';
import { ReportFormComponent } from './dashboard/report-form/report-form.component';
import { GenerateReportDialogComponent } from './dashboard/generate-report-dialog/generate-report-dialog.component';
import { MachineInUseDetailComponent } from './machines/machine-detail/widgets/machine-in-use-detail/machine-in-use-detail.component';
import { MachineInUseComponent as MachineInUseWidgetComponent } from './machines/widgets/machine-in-use/machine-in-use.component';
import { AssetMachineInUseComponent } from './machines/charts/asset-machine-in-use/asset-machine-in-use.component';
import { MachineBreadcrumbsComponent } from './machines/machine-detail/widgets/machine-breadcrumbs/machine-breadcrumbs.component';
import { MachineBreadcrumbsDialogComponent } from './machines/machine-detail/machine-breadcrumbs-dialog/machine-breadcrumbs-dialog.component';
import { KnowledgeArticleService } from '../service/knowledge-article.service';
import { MachineHistoricDtcsComponent } from './machines/machine-detail/widgets/machine-historic-dtcs/machine-historic-dtcs.component';
import { MachineHistoricDtcsChartComponent } from './machines/machine-detail/machine-historic-dtc-chart/machine-historic-dtc-chart.component';
import { EnvelopeProfileBoomLiftComponent } from './machines/charts/envelope-profile-use/envelope-profile-boom-lift/envelope-profile-boom-lift.component';
import { EnvelopeProfileScissorLiftComponent } from './machines/charts/envelope-profile-use/envelope-profile-scissor-lift/envelope-profile-scissor-lift.component';
import { AlertsWidgetComponent } from './machines/widgets/alerts-widget/alerts-widget.component';
import { EnvelopeProfileTelehandlerComponent } from './machines/charts/envelope-profile-use/envelope-profile-telehandler/envelope-profile-telehandler.component';
import { MachineLocalWeatherComponent } from './machines/machine-detail/widgets/machine-local-weather/machine-local-weather.component';
import { FleetAgeComponent } from './machines/widgets/fleet-age/fleet-age.component';
import { MachineSetupDialogComponent } from './machines/machine-detail/machine-setup-dialog/machine-setup-dialog.component';
import { MachinePersonalitiesDialogComponent } from './machines/machine-detail/machine-personalities-dialog/machine-personalities-dialog.component';
import { HardwareReportingMetricComponent } from './machines/widgets/hardware-reporting-metric/hardware-reporting-metric.component';
import { EnvelopeProfileUseComponent } from './machines/widgets/envelope-profile-use/envelope-profile-use.component';
import { WidgetApplicableAssetsBarComponent } from './machines/widgets/widget-applicable-assets-bar/widget-applicable-assets-bar.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { EnvelopeProfileScissorLiftFleetComponent } from './machines/widgets/envelope-profile-use/envelope-profile-scissor-lift-fleet/envelope-profile-scissor-lift-fleet.component';
import { EnvelopeProfileBoomLiftFleetComponent } from './machines/widgets/envelope-profile-use/envelope-profile-boom-lift-fleet/envelope-profile-boom-lift-fleet.component';
import { EnvelopeProfileTelehandlerFleetComponent } from './machines/widgets/envelope-profile-use/envelope-profile-telehandler-fleet/envelope-profile-telehandler-fleet.component';
import { PersistentSlideOutComponent } from './persistent-slide-out/persistent-slide-out.component';
import { SiteNetworkMapListItemComponent } from './map/site-network-map-list-item/site-network-map-list-item.component';
import { AssetPushpinInfoboxComponent } from './map/asset-pushpin-infobox/asset-pushpin-infobox.component';
import { MapOptionsComponent } from './map/map-options/map-options.component';
import { SiteNetworkAssetsTableComponent } from './map/site-network-assets-table/site-network-assets-table.component';
import { MachineBatteryMonitoringComponent } from './machines/machine-detail/widgets/machine-battery-monitoring/machine-battery-monitoring.component';
import { AssetBatteryMonitoringComponent } from './machines/charts/asset-battery-monitoring/asset-battery-monitoring.component';
import { BatteryMonitoringComponent } from './machines/widgets/battery-monitoring/battery-monitoring.component';
import { EquipmentStateOfChargeComponent } from './machines/charts/equipment-state-of-charge/equipment-state-of-charge.component';
import { EquipmentChargeCompleteComponent } from './machines/charts/equipment-charge-complete/equipment-charge-complete.component';
import { EquipmentTimeSinceLastChargeComponent } from './machines/charts/equipment-time-since-last-charge/equipment-time-since-last-charge.component';
import { MatSliderModule } from '@angular/material/slider';
import { LandmarkPushpinInfoboxComponent } from './map/landmark-pushpin-infobox/landmark-pushpin-infobox.component';
import { ImageWrapperModule } from '../shared/image-wrapper/image-wrapper.module';
import { BadgeLabelModule } from '../shared/badge-label/badge-label.module';
import { TruncatorModule } from '../shared/truncator/truncator.module';
import { MachineMapAddressComponent } from './machines/machine-map/machine-map-address/machine-map-address.component';
import { AssetQuickLinksComponent } from './machines/machine-detail/widgets/asset-quick-links/asset-quick-links.component';
import { AssetUtilizationComponent } from './machines/machine-detail/widgets/asset-utilization/asset-utilization.component';
import { AssetEngineInformationComponent } from './machines/machine-detail/widgets/asset-engine-information/asset-engine-information.component';
import { AssetFuelLevelGaugeComponent } from './machines/charts/asset-fuel-level-gauge/asset-fuel-level-gauge.component';
import { AssetDefLevelGaugeComponent } from './machines/charts/asset-def-level-gauge/asset-def-level-gauge.component';
import { AssetBatteryMonitoringGeneralComponent } from './machines/machine-detail/widgets/asset-battery-monitoring-general/asset-battery-monitoring-general.component';
import { GridBlocksModule } from '../shared/grid-blocks/grid-blocks.module';
import { AssetMachineStatusComponent } from './machines/machine-detail/widgets/asset-machine-status/asset-machine-status.component';
import { QuickLinksComponent } from './machines/widgets/quick-links/quick-links.component';
import { FleetAverageAgeComponent } from './machines/widgets/fleet-average-age/fleet-average-age.component';
import { StateOfChargeWidgetComponent } from './machines/widgets/state-of-charge-widget/state-of-charge-widget.component';
import { FleetOverviewWidgetComponent } from './machines/widgets/fleet-overview-widget/fleet-overview-widget.component';
import { MachineWidgetHeaderComponent } from './machines/machine-detail/widgets/machine-widget-header/machine-widget-header.component';
import { MatCardModule } from '@angular/material/card';
import { EmailDashboardDialogComponent } from './dashboard/email-dashboard-dialog/email-dashboard-dialog.component';
import { FleetHeaderComponent } from './header/fleet-header/fleet-header.component';
import { CustomizeButtonComponent } from './header/customize-button/customize-button.component';
import { FilterChipComponent } from './header/fleet-header/filter-chip/filter-chip.component';
import { NewFilterComponent } from './header/fleet-header/new-filter/new-filter.component';
import { SlickModule } from '../shared/slick-carousel/slick.module';
import { DtcWidgetDialogComponent } from './machines/widgets/dtc-widget/dtc-widget-dialog/dtc-widget-dialog.component';
import { FleetDtcDonutChartComponent } from './machines/charts/fleet-dtc-donut-chart/fleet-dtc-donut-chart.component';
import { MapTogglesComponent } from './map/map-toggles/map-toggles.component';
import { MapToggleComponent } from './map/map-toggle/map-toggle.component';
import { MachineUtilizationDialogComponent } from './machines/machine-detail/machine-utilization-dialog/machine-utilization-dialog.component';
import { AssetUtilizationXrangeComponent } from './machines/charts/asset-utilization-xrange/asset-utilization-xrange.component';
import { FleetAgeDialogComponent } from './machines/widgets/fleet-age/fleet-age-dialog/fleet-age-dialog.component';
import { FleetAgeScatterComponent } from './machines/charts/fleet-age-scatter/fleet-age-scatter.component';
import { MachineHistoricDtcsTableComponent } from './machines/machine-detail/widgets/machine-historic-dtcs/machine-historic-dtcs-table/machine-historic-dtcs-table.component';
import { MachineHistoricDtcsDialogComponent } from './machines/machine-detail/machine-historic-dtcs-dialog/machine-historic-dtcs-dialog.component';
import { HistoricSelectComponent } from './form-controls/historic-select/historic-select.component';
import { AssetBatteryMonitoringWithBlocksComponent } from './machines/charts/asset-battery-monitoring-with-blocks/asset-battery-monitoring-with-blocks.component';
import { MachineBatteryMonitoringDialogComponent } from './machines/machine-detail/machine-battery-monitoring-dialog/machine-battery-monitoring-dialog.component';
import { DtcCategoryDotTitleComponent } from './shared/dtc-category-dot-title/dtc-category-dot-title.component';
import { WidgetGridDialogComponent } from './shared/widget-grid-dialog/widget-grid-dialog.component';
import { AccessControlComponent } from './access-control/access-control.component';
import { PdfDownloadDialogComponent } from './shared/pdf-download-dialog/pdf-download-dialog.component';
import { AssetAccessControlComponent } from './machines/machine-detail/widgets/asset-access-control/asset-access-control.component';
import { AccessSiteNetworkMapWidgetComponent } from './access-control/widgets/access-site-network-map-widget/access-site-network-map-widget.component';
import { FleetPercentageBarComponent } from './header/fleet-header/fleet-percentage-bar/fleet-percentage-bar.component';
import { MapContentComponent } from './map/map-content/map-content.component';
import { AccessListWidgetComponent } from './access-control/widgets/access-list-widget/access-list-widget.component';
import { AccessMetricsWidgetComponent } from './access-control/widgets/access-metrics-widget/access-metrics-widget.component';
import { ClearskySharedModule } from './clearsky-shared/clearsky-shared.module';
import { SiteNetworkPushpinInfoboxComponent } from './map/site-network-pushpin-infobox/site-network-pushpin-infobox.component';
import { AssetAccessControlDialogComponent } from './machines/machine-detail/widgets/asset-access-control/asset-access-control-dialog/asset-access-control-dialog.component';
import { SiteNetworkWidgetComponent } from './machines/widgets/site-network-widget/site-network-widget.component';
import { NetworkDetailDialogComponent } from './networks/network-detail-dialog/network-detail-dialog.component';
import { NetworkGeneralInfoComponent } from './networks/network-general-info/network-general-info.component';
import { NetworkMiniMapComponent } from './networks/network-mini-map/network-mini-map.component';
import { MachineTableComponent } from './machines/machine-table/machine-table.component';
import { FieldValueDisplayPipe } from './field-value-display.pipe';
import { NetworkHistoryLogComponent } from './networks/network-history-log/network-history-log.component';
import { WidgetListComponent } from './machines/widgets/widget-list/widget-list.component';
import { WidgetComponent } from './machines/widgets/widget/widget.component';
import { WidgetIconComponent } from './machines/widgets/widget-icon/widget-icon.component';
import { ChartModule } from '../shared/chart/chart.module';
import { DtcTableComponent } from './shared/dtc-table/dtc-table.component';
import { NoMachineDataAlertComponent } from './machines/no-machine-data-alert/no-machine-data-alert.component';
import { LegendValuePipe } from './shared/legend-value.pipe';
import { TemperatureConverterPipe } from './temperature.pipe';
import { AssetQuickLinksListComponent } from './machines/asset-quick-links-list/asset-quick-links-list.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ClearskyContentComponent } from './clearsky-content/clearsky-content.component';
import { WidgetDragHandlerComponent } from './machines/widgets/widget-drag-handler/widget-drag-handler.component';
import { MapInfoBoxModule } from '../shared/map/map-info-box/map-info-box.module';
import { ProgressBarModule } from '../shared/progress-bar/progress-bar.module';
import { MachineEngineStatusComponent } from './machines/machine-engine-status/machine-engine-status.component';
import { CsLayoutMainComponent } from './cs-layout/cs-layout-main/cs-layout-main.component';
import { CsLayoutSidebarComponent } from './cs-layout/cs-layout-sidebar/cs-layout-sidebar.component';
import { CsLayoutNavComponent } from './cs-layout/cs-layout-nav/cs-layout-nav.component';
import { CsLayoutContentComponent } from './cs-layout/cs-layout-content/cs-layout-content.component';
import { AssetConnDevComponent } from './machines/machine-detail/widgets/asset-conn-dev/asset-conn-dev.component';
import { CsNavToggleComponent } from './shared/cs-nav-toggle/cs-nav-toggle.component';
import { AssetBatteryStatusBlockComponent } from './shared/asset-battery-status-block/asset-battery-status-block.component';
import { FilterMenuComponent } from './header/fleet-header/filter-menu/filter-menu.component';
import { PaginatorModule } from '../shared/paginator/paginator.module';

@NgModule({
  declarations: [
    DashboardComponent,
    ClearskyComponent,
    FuelLevelComponent,
    SettingsDialogComponent,
    SaveDashboardDialogComponent,
    MapComponent,
    ListComponent,
    MachineDetailsDialogComponent,
    NavToggleComponent,
    RefreshButtonComponent,
    EngineStatusComponent,
    DefLevelComponent,
    WidgetHeaderComponent,
    WidgetFooterComponent,
    WidgetContentComponent,
    WidgetTitleComponent,
    DtcWidgetComponent,
    MachineDetailComponent,
    GeneralMachineInfoComponent,
    MachineLevelBarComponent,
    LocationWidgetComponent,
    ResourcesComponent,
    WidgetDialogComponent,
    EngineComponentDialogComponent,
    FuelPressureComponent,
    FuelConsumptionComponent,
    AssetMachineInUseComponent,
    LastReportedComponent,
    ModelTypeComponent,
    WidgetItemComponent,
    WidgetListComponent,
    MachineMapComponent,
    MachineWidgetItemComponent,
    MachineWidgetDialogComponent,
    DtcBubbleDialogComponent,
    FieldDisplayPipe,
    FieldValueDisplayPipe,
    DTCTooltipPipe,
    EngineFuelPressureComponent,
    EngineFuelConsumptionComponent,
    MachineInUseDetailComponent,
    MachineInUseWidgetComponent,
    AssetMachineInUseComponent,
    ReportFormComponent,
    GenerateReportDialogComponent,
    MachineBreadcrumbsComponent,
    MachineBreadcrumbsDialogComponent,
    MachineHistoricDtcsComponent,
    MachineHistoricDtcsChartComponent,
    EnvelopeProfileBoomLiftComponent,
    EnvelopeProfileScissorLiftComponent,
    AlertsWidgetComponent,
    EnvelopeProfileTelehandlerComponent,
    MachineLocalWeatherComponent,
    FleetAgeComponent,
    MachineSetupDialogComponent,
    MachinePersonalitiesDialogComponent,
    HardwareReportingMetricComponent,
    EnvelopeProfileUseComponent,
    WidgetApplicableAssetsBarComponent,
    EnvelopeProfileScissorLiftFleetComponent,
    EnvelopeProfileBoomLiftFleetComponent,
    EnvelopeProfileTelehandlerFleetComponent,
    PersistentSlideOutComponent,
    SiteNetworkMapListItemComponent,
    AssetPushpinInfoboxComponent,
    MapOptionsComponent,
    SiteNetworkAssetsTableComponent,
    MachineBatteryMonitoringComponent,
    AssetBatteryMonitoringComponent,
    BatteryMonitoringComponent,
    EquipmentStateOfChargeComponent,
    EquipmentChargeCompleteComponent,
    EquipmentTimeSinceLastChargeComponent,
    LandmarkPushpinInfoboxComponent,
    MachineMapAddressComponent,
    AssetQuickLinksComponent,
    AssetUtilizationComponent,
    AssetEngineInformationComponent,
    AssetFuelLevelGaugeComponent,
    AssetDefLevelGaugeComponent,
    AssetBatteryMonitoringGeneralComponent,
    AssetMachineStatusComponent,
    QuickLinksComponent,
    FleetAverageAgeComponent,
    StateOfChargeWidgetComponent,
    FleetOverviewWidgetComponent,
    MachineWidgetHeaderComponent,
    EmailDashboardDialogComponent,
    FleetHeaderComponent,
    CustomizeButtonComponent,
    FilterChipComponent,
    NewFilterComponent,
    DtcWidgetDialogComponent,
    FleetDtcDonutChartComponent,
    MapTogglesComponent,
    MapToggleComponent,
    MachineUtilizationDialogComponent,
    AssetUtilizationXrangeComponent,
    FleetAgeDialogComponent,
    FleetAgeScatterComponent,
    MachineHistoricDtcsTableComponent,
    MachineHistoricDtcsDialogComponent,
    HistoricSelectComponent,
    AssetBatteryMonitoringWithBlocksComponent,
    MachineBatteryMonitoringDialogComponent,
    DtcCategoryDotTitleComponent,
    DtcTableComponent,
    WidgetGridDialogComponent,
    AccessControlComponent,
    PdfDownloadDialogComponent,
    AssetAccessControlComponent,
    AccessSiteNetworkMapWidgetComponent,
    FleetPercentageBarComponent,
    MapContentComponent,
    AccessListWidgetComponent,
    AccessMetricsWidgetComponent,
    SiteNetworkPushpinInfoboxComponent,
    AssetAccessControlDialogComponent,
    SiteNetworkWidgetComponent,
    NetworkDetailDialogComponent,
    NetworkGeneralInfoComponent,
    NetworkMiniMapComponent,
    MachineTableComponent,
    NetworkHistoryLogComponent,
    WidgetComponent,
    WidgetIconComponent,
    LegendValuePipe,
    TemperatureConverterPipe,
    AssetQuickLinksListComponent,
    RequestFormComponent,
    ClearskyContentComponent,
    WidgetDragHandlerComponent,
    MachineEngineStatusComponent,
    NoMachineDataAlertComponent,
    CsLayoutMainComponent,
    CsLayoutSidebarComponent,
    CsLayoutNavComponent,
    CsLayoutContentComponent,
    AssetConnDevComponent,
    CsNavToggleComponent,
    AssetBatteryStatusBlockComponent,
    FilterMenuComponent,
  ],
  providers: [
    ClearskyService,
    ClearskyDashboardService,
    WidgetService,
    KnowledgeArticleService,
    DatePipe,
  ],
  exports: [
    ClearskyComponent,
    DashboardComponent,
    ListComponent,
    MapComponent,
    AccessControlComponent,
    MachineDetailComponent,
    RequestFormComponent,
    ClearskyContentComponent,
    CsNavToggleComponent,
    AssetBatteryStatusBlockComponent
  ],
  imports: [
    CommonModule,
    ClearskySharedModule,
    SharedModule,
    HttpClientModule,
    MaterialModule,
    MatButtonToggleModule,
    DragDropModule,
    NgxMasonryModule,
    ClipboardModule,
    MatSliderModule,
    ImageWrapperModule,
    BadgeLabelModule,
    TruncatorModule,
    GridBlocksModule,
    MatCardModule,
    SlickModule,
    ChartModule,
    ScrollingModule,
    MapInfoBoxModule,
    ProgressBarModule,
    AlertBannerModule,
    PaginatorModule,
  ],
})
export class ClearskyModule {}
