import { Component, Input, OnInit } from '@angular/core';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-widget-applicable-assets-bar',
  templateUrl: './widget-applicable-assets-bar.component.html',
})
export class WidgetApplicableAssetsBarComponent implements OnInit {
  @Input() percentage: number;
  @Input() number: number;
  @Input() showPercentage: boolean;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}
  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.applicable-label'])
      .pipe(first())
      .subscribe();
  }
}
