import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Machine,
  MachineDTC,
  MxPwrSrc,
} from '../../../contracts/clearsky/machine/machine.dto';
import { BingMapsService } from '../../../service/bing-maps.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { KnowledgeArticleService } from '../../../service/knowledge-article.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClearskyService } from '../../clearsky.service';
import { CsRequestKeys } from '../../../contracts/clearsky/cs-machines-request';
import { CSFilter } from '../../../contracts/clearsky/machine/machine-filter-v2';
import { CSLegend } from '../../../contracts/clearsky/clearsky-legend';
import { combineLatest, first, Subscription } from 'rxjs';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { OcidItems } from 'app/contracts/ocid-items';
import { MxPlaceholderImg } from '../../../contracts/clearsky/machine/machine.images';
import { WidgetColors } from 'app/contracts/clearsky/dashboard/cs-colors.dto';

interface DialogData {
  sn: number;
  machine?: Machine;
  formattedAddress?: Microsoft.Maps.IAddress;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-details-dialog',
  templateUrl: './machine-details-dialog.component.html',
  styleUrls: ['./machine-details-dialog.component.scss'],
})
export class MachineDetailsDialogComponent implements OnInit {
  @ViewChild('map') map: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  bingMap: Microsoft.Maps.Map | undefined;
  displayedColumns: string[] = ['color', 'group', 'dtc'];
  dataSource: MatTableDataSource<MachineDTC>;
  articleMapping: { [dtc: string]: string } = {};
  isBattery: boolean | undefined;
  machine: Machine | undefined;
  batteryVoltageIconColor: string;
  legend: CSLegend = {};
  placeholderImg = MxPlaceholderImg;
  ocids: OcidItems = {};
  private subs: Subscription;

  constructor(
    private clearskyService: ClearskyService,
    public dialogRef: MatDialogRef<MachineDetailsDialogComponent>,
    private bingMaps: BingMapsService,
    private router: Router,
    private kaService: KnowledgeArticleService,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.attributes-label',
        'clearsky.state-of-charge-label',
        'clearsky.charge-status-label',
        'clearsky.not-label',
        'clearsky.charging-label',
        'web2case.level2.fuel-level',
        'clearsky.operating-label',
        'clearsky.dtcs-label',
        'clearsky.no-dtcs-label',
        'clearsky.diagnostic-trouble codes-label'
      ])
      .pipe(first())
      .subscribe();
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(
        CsRequestKeys.quickAsset,
        {
          filters: [
            {
              key: CSFilter.sn.key,
              values: [this.data.sn],
            },
          ],
        },
        false
      ),
      this.clearskyService.legendRef$,
    ]).subscribe(([res, legend]) => {
      this.machine =
        res && res.machines && res.machines.length
          ? (res.machines[0] as Machine)
          : undefined;
      this.legend = legend || {};
      this.isBattery = this.machine && this.machine.pwrSrc === MxPwrSrc.BATTERY;
      this.batteryVoltageIconColor = this.machine.connStat ? WidgetColors.green : WidgetColors.grey2;
    });
  }

  /**
   * View more details on machine.
   */
  viewMore(): void {
    if (!this.machine) {
      return;
    }
    this.dialogRef.close();
    this.router.navigate(['/clearsky/machine', this.machine.sn]);
  }
}
