import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { OcidItems } from '../../../../contracts/ocid-items';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  CSFieldType,
  Machine,
  MachineFieldDisplay,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { ClearskyService } from '../../../clearsky.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  getMachineFieldOcidTranslation,
  MachineFieldDisplayValueOcids,
} from '../../../../contracts/clearsky/machine/machine.fields';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-access-list-widget',
  templateUrl: './access-list-widget.component.html',
  styleUrls: ['./access-list-widget.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'widget' },
})
export class AccessListWidgetComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayName = 'global.list-label';
  ocids: OcidItems = {};
  dataSource: MatTableDataSource<Machine> = new MatTableDataSource<Machine>([]);
  displayFieldsMapping = MachineFieldDisplay;
  displayedColumns: string[] = [
    'equipmentId',
    'oemName',
    'model',
    'siteNetworkName',
    'ignitionStatus',
    'diagnosticTroubleCode',
    'hardwareReportingStatus',
    'accessRestriction',
  ];
  pageSize = 30;
  hasResults = false;
  fieldType = CSFieldType;

  constructor(
    private localization: LocalizationService,
    private clearskyService: ClearskyService
  ) {}

  ngOnInit(): void {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        ...this.displayedColumns.reduce((prev, column) => {
          return prev.concat(MachineFieldDisplayValueOcids[column] || []);
        }, []),
        ...Object.values(MachineFieldDisplay),
        'browse.filter-title',
        'clearsky.asset-id-site-net-label',
        'clearsky.modify-restriction-lable',
        this.displayName,
      ])
      .pipe(first())
      .subscribe();

    /**
    this.clearskyService.getCachedMachines$.subscribe((machines) => {
      this.dataSource.data = machines;

      this.hasResults = !!this.dataSource.data.length;
    });
     **/

    this.dataSource.filterPredicate = (machine, filter: string) => {
      // Convert to OCIDs/translations
      const convertedMachine = Object.entries(machine).reduce(
        (prev, [key, value]) => {
          prev[key] = getMachineFieldOcidTranslation(
            key,
            value as never,
            this.ocids
          );
          return prev;
        },
        {} as Machine
      );

      // Now see if any values contain filter
      return this.displayedColumns.some((key) =>
        convertedMachine[key]
          ? convertedMachine[key].toString().toLowerCase().includes(filter)
          : false
      );
    };
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Filter list by search term.
   * @param event
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Get field value per row in table.
   * @param field
   * @param value
   */
  getFieldValue(field: string, value: never): string {
    return getMachineFieldOcidTranslation(field, value, this.ocids) as string;
  }

  /**
   * Drag and drop displayed column ordering.
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    const columns = [...this.displayedColumns];
    moveItemInArray(columns, event.previousIndex, event.currentIndex);
    this.displayedColumns = columns;
  }
}
