import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-h1',
  templateUrl: './h1.component.html',
})
export class H1Component implements CartridgeInterface, OnInit {
  @Input() data: {
    'OCID-h1TitleTagAppend': string;
    'OCID-h1TitleTagPrepend': string;
    h1TitleTag: string;
    h1TitleTagAppend: string;
    h1TitleTagFreeForm: string;
    h1TitleTagPrepend: string;
    useCategoryNameForH1TitleTag: boolean;
    dimensionForBreadcrumb: string;
  };
  h1DisplayText = '';

  ngOnInit() {
    if (this.data.useCategoryNameForH1TitleTag) {
      this.h1DisplayText = this.data.dimensionForBreadcrumb;
    } else if (this.data.h1TitleTag) {
      this.h1DisplayText = this.data.h1TitleTag;
    } else {
      this.h1DisplayText =
        `${this.data['OCID-h1TitleTagPrepend']} ` +
        this.data.h1TitleTagFreeForm +
        ` ${this.data['OCID-h1TitleTagPrepend']}`;
    }
  }
}
