import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IVerifyTokenAnswerResponse } from '../../../../contracts/recovery/iverify-token-answer-response';
import { NotificationService } from '../../../../service/notification/notification.service';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { ForgotUnlockWrapperService } from '../forgot-unlock-wrapper.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-recovery-answer-form',
  templateUrl: './recovery-answer-form.component.html',
  providers: [NotificationService],
})
export class RecoveryAnswerFormComponent implements OnInit {
  @Input() recoveryQuestion = '';
  @Input() recoveryStateToken = '';
  @Input() isResetPassword = false;
  @Output() tokenExpired: EventEmitter<any> = new EventEmitter();
  @Output() recoveryAnswerSuccess: EventEmitter<any> = new EventEmitter();
  ocids: {} = {};
  recoveryAnswerForm: UntypedFormGroup;
  failedAttempts = 0;
  answerFailureMessage = '';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private localization: LocalizationService,
    private notificationService: NotificationService,
    private wrapperService: ForgotUnlockWrapperService
  ) {}

  ngOnInit() {
    // Get OCIDs.
    this.localization.OCIDs.subscribe((ocids: {}) => {
      this.ocids = ocids;
    });
    this.localization
      .getOCIDs([
        'signin.incorrect-password',
        'signin.security-incorrect-password',
      ])
      .subscribe();
    // Set unlock account form.
    this.recoveryAnswerForm = this._formBuilder.group({
      recoveryAnswer: ['', [Validators.required]],
    });
  }

  /**
   * Submit unlock recover answer for validation.
   */
  submitRecoveryAnswer() {
    if (this.recoveryAnswerForm.valid) {
      this.answerFailureMessage = '';
      this.notificationService.reset();
      this.wrapperService
        .verifySecurityTokenAndAnswer({
          stateToken: this.recoveryStateToken,
          answer: this.recoveryAnswerForm.controls.recoveryAnswer.value,
        })
        .subscribe(
          (res: IVerifyTokenAnswerResponse) => {
            // Emit event to return as successful. Pass that state token back to the parent form.
            this.recoveryAnswerSuccess.emit(res.stateToken);
          },
          (error) => {
            if (error.error['o:errorCode'] === 'E0000087') {
              // Invalid attempt.
              this.failedAttempts++;
              if (this.failedAttempts >= 3) {
                // If user has failed more than 3 times, disable the button and recommend sending an email to service desk.
                this.answerFailureMessage =
                  this.ocids['signin.security-incorrect-password'];
              } else {
                // If user has failed less than 3 times, show invalid input message.
                this.answerFailureMessage =
                  this.ocids['signin.incorrect-password'];
              }
            } else if (error.error['o:errorCode'] === 'E0000011') {
              // If the token has expired, emit an event that sets token validity boolean to false and show error.
              this.tokenExpired.emit();
            } else {
              // All other errors.
              this.notificationService.addError(error.error.title);
            }
          }
        );
    }
  }
}
