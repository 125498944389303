import { Component, Input, OnInit } from '@angular/core';
import {
  Machine,
} from './../../../contracts/clearsky/machine/machine.dto';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { first } from 'rxjs';
import { OcidItems } from '../../../contracts/ocid-items';
import { WidgetColors } from 'app/contracts/clearsky/dashboard/cs-colors.dto';

@Component({
  selector: 'app-asset-battery-status-block',
  templateUrl: './asset-battery-status-block.component.html'
})
export class AssetBatteryStatusBlockComponent implements OnInit {
  @Input() machine: Machine;
  ocids: OcidItems;
  batteryVoltageIconColor;
  elapsedChargeDate: string;
  constructor(private localization: LocalizationService) {
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.charge-status-label',
        'clearsky.date-time-last-charge-label',
        'clearsky.not-label',
        'clearsky.elapsed-charging-time-label'
      ])
      .pipe(first())
      .subscribe();
  }
  ngOnInit() {
    this.batteryVoltageIconColor = this.machine.connStat ? WidgetColors.green : WidgetColors.grey2;
    // Elapsed charge time
    const elapsedHours = this.machine.ect ? this.machine.ect / 3600 : 0; // hours
    const elapsedMinutes = (elapsedHours % 1) * 60;
    this.elapsedChargeDate = `${Math.floor(elapsedHours)} hr ${Math.round(
      elapsedMinutes
    )} min`;
  }
}
