import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-map-toggle',
  templateUrl: './map-toggle.component.html',
})
export class MapToggleComponent {
  @Input() label: string;
  @Input() value: boolean;
  @Output() toggle: EventEmitter<void> = new EventEmitter<void>();
}
