import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero-carousel-item',
  templateUrl: './hero-carousel-item.component.html'
})
export class HeroCarouselItemComponent implements OnInit {
  @Input('slide') slide;
  @Input('activeSlide') activeSlide;
  @Input('index') index;

  constructor() { }

  ngOnInit() {
  }

}
