import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IAddress } from '../../../contracts/user/iaddress';
import { IUser } from '../../../contracts/user/iuser';
import {
  IFileList,
  IPlacardDto,
  ITruckData,
} from '../../../contracts/placard/iplacard-dto';
import { NotificationService } from '../../../service/notification/notification.service';
import { CurrentUserService } from '../../../service/user/current-user.service';
import { LocalizationService } from '../../../shared/localization/localization.service';
import { WindowRefService } from '../../../service/window-ref/window-ref.service';
import { PlacardService } from './placard.service';
import { ChangeAddressComponent } from '../checkout/change-address/change-address.component';
import { ConfirmationDialogsService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { ScannerService } from 'app/service/scanner.service';

interface IPlacardHelper {
  customerForm: object;
  files: IFileList[];
}

interface EquipmentInfo {
  serial: string;
  model: string;
  type: string;
  desc: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-placard',
  templateUrl: './placard.component.html',
  styleUrls: [
    '../web2case/web2case.component.scss',
  ],
})
export class PlacardComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper) stepper!: MatStepper;
  ocids = {};
  user!: IUser;
  requestTypeForm: UntypedFormGroup = new UntypedFormGroup({});
  vinForm: UntypedFormGroup = new UntypedFormGroup({});
  truckForm: UntypedFormGroup = new UntypedFormGroup({});
  equipmentInfoForm: UntypedFormGroup = new UntypedFormGroup({});
  replacementForm: UntypedFormGroup = new UntypedFormGroup({});
  replacementConsentForm: UntypedFormGroup = new UntypedFormGroup({});
  contactInfoForm: UntypedFormGroup = new UntypedFormGroup({});
  requestTypeFormComplete = false;
  verifyModelComplete = false;
  confirmBodyComplete = false;
  confirmTruckComplete = false;
  confirmVinComplete = false;
  confirmContactComplete = false;
  requestReasonComplete = false;
  caseSubmitted = false;
  platformBrowser = false;
  requestType!: string;
  manufactureDateFormatted!: string;
  serialSearch = '';
  equipment!: EquipmentInfo;
  shippingAddress!: IAddress;
  billingAddress!: IAddress;
  invalidNumber = false;
  truckData!: ITruckData;
  requestTypeValues = {
    new: 'New',
    replacement: 'Replacement',
    star: 'S.T.A.R.',
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private localizationService: LocalizationService,
    private notificationService: NotificationService,
    private placardService: PlacardService,
    private winRef: WindowRefService,
    private dialog: MatDialog,
    private userService: CurrentUserService,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationDialogsService,
    private scanner: ScannerService
  ) { }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    this.platformBrowser = isPlatformBrowser(this.platformId);
    this.userService.userSubject.subscribe((user: IUser) => {
      if (user) {
        this.user = user;
        if (user.billingAddress) {
          this.billingAddress = user.billingAddress;
        }
        if (!user.inProxy) {
          // If the user has a shipping address, use it as the shipping address.
          if (user.shippingAddress) {
            this.shippingAddress = user.shippingAddress;
          }
        } else if (user.orgShippingAddress) {
          // If the user is in proxy and the org shipping address exists, use the orgShippingAddress.
          this.shippingAddress = user.orgShippingAddress;
        }
        this.contactInfoForm.addControl(
          'mailingContact',
          this.formBuilder.control(
            this.shippingAddress?.contactName ?? '',
            Validators.required
          )
        );
        this.contactInfoForm.addControl(
          'mailingContactPhone',
          this.formBuilder.control(
            this.shippingAddress?.phoneNumber ?? '',
            Validators.pattern(/^[\d()+-.ext ]*$/)
          )
        );
      }
    });

    this.localizationService
      .getOCIDs([
        'placard-request.page-title',
        'placard-request.step1-title',
        'placard-request.step1-label',
        'placard-request.step1-cta1',
        'placard-request.step1-cta2',
        'placard-request.step2-title',
        'placard-request.step2-label',
        'placard-request.step2-link-label',
        'placard-request.step2-answer',
        'placard-request.step3-title',
        'placard-request.label-wheel-lift-model-no',
        'placard-request.label-wrecker-boom-model-num',
        'placard-request.label-wheel-grid-style',
        'placard-request.label-tunnel-compartment',
        'placard-request.label-srs-option',
        'placard-request.alteration-label',
        'placard-request.body',
        'placard-request.grid',
        'placard-request.tow-sling',
        'placard-request.dolly',
        'placard-request.wrecker-boom',
        'placard-request.tunnel-tool-box',
        'placard-request.push-bumper',
        'placard-request.alteration-description',
        'placard-request.tow-option',
        'placard-request.truck-make',
        'placard-request.year',
        'placard-request.drive-type',
        'placard-request.chassis-brake-type',
        'placard-request.truck-base-in',
        'placard-request.truck-gvwr-lbs',
        'placard-request.front-gawr-lbs',
        'placard-request.truck-model',
        'placard-request.engine-type',
        'placard-request.chassis-cab-type',
        'placard-request.vin',
        'placard-request.rear-gawr-lbs',
        'placard-request.distributor-address',
        'placard-request.change-address-label',
        'placard-request.review-label1',
        'placard-request.placard-type-label',
        'placard-request.date-of-manufacture',
        'placard-request.equipmentlookup-fail',
        'placard-request.reason-title',
        'placard-request.reason1',
        'placard-request.reason2',
        'placard-request.attachment-label',
        'placard-request.acceptance-text',
        'placard-request.po-num-info',
        'web2case.confirm-contact-info',
        'web2case.other-please-explain',
        'web2case.browse',
        'web2case.no-file-selected',
        'web2case.add-additional-attachment',
        'footer.equipment-info',
        'web2case.thank-you-message',
        'placard-request.confirm-truck-label',
        'placard-request.review-submit-label',
        'placard-request.serial-num-invalid',
        'placard-request.equipment-class-error',
        'placard-request.ca-ct-in',
        'placard-request.vin-invalid-message',
        'placard-request.vin-model-invalid-message',
        'placard-request.how-to-find-body-SN-label',
        'placard-request.structural-placard-error-message',
        'placard-request.star-placard-details'
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }

  /**
   * Event emitted by the request type form when the request type changes.
   */
  requestTypeChanged(event) {
    this.requestType = event;
    if (this.requestType === 'Replacement') {
      this.replacementConsentForm.addControl(
        'PONum',
        this.formBuilder.control('', Validators.required)
      );
      this.replacementConsentForm.addControl(
        'consent',
        this.formBuilder.control('', Validators.required)
      );
    } else {
      this.replacementConsentForm.removeControl('PONum');
    }
  }

  /**
   * Event emitted by the request type form when the form has been submitted.
   */
  requestTypeFormSubmitted() {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    this.requestTypeFormComplete = true;
    this.manufactureDateFormatted =
      this.requestTypeForm.value.manufactureDate
        .toLocaleString('en-US', options)
        .toString()
        .replace(/"/g, '');
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  onSerialSearch() {
    this.placardService
      .serialNumberLookup({ serialNumber: this.serialSearch })
      .subscribe({
        next: (data) => {
          this.invalidNumber = !data['modelNumber'].length
          this.equipment = {
            serial: this.serialSearch,
            model: data['modelNumber'],
            type: data['equipmentType'].toLowerCase(),
            desc: data['equipmentTypeDescription'],
          };
          this.equipmentInfoForm = new UntypedFormGroup({});
        },
        error: () => {
          this.invalidNumber = true;
        },
      });
  }

  /**
   * Event emitted when we get updated truck info from the API
   */
  truckDataChanged(event) {
    this.truckData = event;
  }

  populateForm() {
    this.equipmentInfoForm.addControl(
      'alterationsSincePurchased',
      this.formBuilder.control('', Validators.required)
    );
    // Adds needed form control.
    if (
      this.requestType === this.requestTypeValues.new ||
      this.requestTypeForm.value.otherType === this.requestTypeValues.star
    ) {
      if (
        this.equipment.model === 'HPL-35' ||
        this.equipment.model === 'HPL-60'
      ) {
        this.equipmentInfoForm.addControl(
          'hpl60Form',
          new UntypedFormGroup({})
        );
      } else if (this.equipment.type === 'wrecker') {
        this.equipmentInfoForm.addControl(
          'wreckerForm',
          new UntypedFormGroup({})
        );
      } else {
        this.equipmentInfoForm.addControl(
          'carrierForm',
          new UntypedFormGroup({})
        );
      }
    }
    this.verifyModelComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  confirmBodySubmit() {
    this.confirmBodyComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  confirmVinSubmit() {
    this.confirmVinComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  confirmTruckSubmit() {
    this.confirmTruckComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  replacementReasonSubmit() {
    this.requestReasonComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  contactConfirmSubmit() {
    this.confirmContactComplete = true;
    setTimeout(() => {
      this.stepper.next();
    }, 1);
  }

  altStatusChanged(event: MatButtonToggleChange) {
    this.equipmentInfoForm
      .get('alterationsSincePurchased')
      .setValue(event.value);
    if (event.value === 'Yes') {
      this.equipmentInfoForm.addControl(
        'describeAlterations',
        this.formBuilder.control('', Validators.required)
      );
    } else {
      this.equipmentInfoForm.removeControl('describeAlterations');
    }
  }

  /**
   * Open modal to change shipping address.
   */
  onChangeAddress(): void {
    const dialogRef = this.dialog.open(ChangeAddressComponent, {
      width: '850px',
    });

    dialogRef.afterClosed().subscribe((result: IAddress) => {
      if (result) {
        this.shippingAddress = {
          customerName: result.customerName,
          address1: result.address1,
          address2: result.address2,
          address3: result.address3,
          postalCode: result.postalCode,
          state: result.state,
          city: result.city,
          country: result.country,
          contactName: result.contactName,
          phoneNumber: result.phoneNumber,
        };
      }
    });
  }

  /**
   * Form control name emitted by the request info form when the form has been submitted.
   */
  submitForm() {
    let dto = this.placardDto(this.requestTypeForm.value);
    let placardDto: IPlacardDto = {
      customerForm: {
        legalSignOff: true,
        email: this.user.email,
        recordType:
          this.requestType === 'New'
            ? this.equipment.type.charAt(0).toUpperCase() +
            this.equipment.type.slice(1)
            : 'Other',
        bodySerialNumber: this.equipment.serial,
        equipmentModel: this.equipment.desc,
        accountNumber: this.user.customerNumber,
        accountCity: this.billingAddress.city,
        accountState: this.billingAddress.state,
        contactName: this.billingAddress?.contactName ?? '',
        customerName: this.shippingAddress?.customerName ?? '',
        mailingCity: this.shippingAddress?.city,
        mailingCountry: this.shippingAddress?.country,
        mailingState: this.shippingAddress?.state,
        mailingStreet: this.shippingAddress?.address1,
        mailingZipCode: this.shippingAddress?.postalCode,
        mailingContact: this.contactInfoForm.value.mailingContact,
        mailingContactPhone: this.contactInfoForm.value.mailingContactPhone,
        ...dto.customerForm,
      },
    };
    if (
      this.requestType === 'New' ||
      this.requestTypeForm.value.otherType === 'S.T.A.R.'
    ) {
      const equipmentDto = this.placardDto(this.equipmentInfoForm.value);
      const vinDto = this.placardDto(this.vinForm.value);
      const truckDto = this.placardDto(
        (<UntypedFormGroup>this.truckForm).value
      );
      placardDto = {
        customerForm: {
          ...placardDto.customerForm,
          ...equipmentDto.customerForm,
          ...vinDto.customerForm,
          ...truckDto.customerForm,
        },
      };
    }

    if (this.requestType === 'Replacement') {
      dto = this.placardDto((<UntypedFormGroup>this.replacementForm).value);
      placardDto = {
        customerForm: {
          ...placardDto.customerForm,
          ...dto.customerForm,
          poNumber: this.replacementConsentForm.value.PONum,
        },
      };
    }

    if (dto.files) {
      placardDto['filelist'] = dto.files;
    }

    this.placardService.postCase(placardDto).subscribe({
      next: () => {
        this.notificationService.reset();
        this.caseSubmitted = true;

        setTimeout(() => {
          // reset the form and remove error state on submit success
          this.equipmentInfoForm.reset();
          this.resetForm(this.equipmentInfoForm);
          // Send form success event to GA
          if (this.platformBrowser) {
            const dataLayer = (this.winRef.nativeWindow.dataLayer =
              this.winRef.nativeWindow.dataLayer || []);
            dataLayer.push({
              event: 'formEvent',
              formAction: 'Success',
              formName: 'Placard',
            });
          }
        }, 1);
      },
      error: (error) => {
        this.notificationService.addError(error.error.title);
        this.caseSubmitted = false;
      },
    });
  }

  /**
   * Gets the HPL60 form.
   * @returns {FormGroup}
   */
  get hpl60Form(): UntypedFormGroup {
    return <UntypedFormGroup>this.equipmentInfoForm.get('hpl60Form');
  }

  /**
   * Gets the wrecker form.
   * @returns {FormGroup}
   */
  get wreckerForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.equipmentInfoForm.get('wreckerForm');
  }

  /**
   * Gets the carrier form.
   * @returns {FormGroup}
   */
  get carrierForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.equipmentInfoForm.get('carrierForm');
  }

  /**
   * resets the form and clears validation errors
   * @param {FormGroup} form
   */
  resetForm(form: UntypedFormGroup) {
    form.reset();
    Object.keys(form.controls).forEach((key) => {
      form.get(key).setErrors(null);
    });
  }

  /**
   * Assists in creating the Placard dto object. Takes the form value as a parameter,
   * loops over the properties and assigns them to the dto. This makes sure we get all
   * values from the form, including the nested form objects.
   * @param {object} objectValue
   * @returns {IPlacardHelper}
   */
  placardDto(objectValue: object) {
    // Create the basic dto.
    const dto: IPlacardHelper = {
      customerForm: {},
      files: null,
    };
    Object.keys(objectValue).forEach((key: string) => {
      const keyValue = objectValue[key];
      // Since the upload form contains values for the filelist property on the dto,
      // we need to manually pull it out.
      if (key === 'uploadInput') {
        dto.files = keyValue.length > 0 ? keyValue : null;
      } else {
        // If the current key value is of type object, we will call the placardDto
        // function again, passing in the object to pull out the properties.
        // exclude manufactureDate which returns a Date object which should be parsed as string instead
        if (typeof keyValue === 'object' && key !== 'manufactureDate') {
          const innerDto: IPlacardHelper = this.placardDto(keyValue);
          dto.customerForm = {
            ...dto.customerForm,
            ...innerDto.customerForm,
          };
          if (innerDto.files) {
            dto.files = innerDto.files;
          }
        } else {
          // if value is None we don't want to pass this back to Salesforce, otherwise the endpoint will fail
          if (key === 'manufactureDate') {
            dto.customerForm = {
              ...dto.customerForm,
              [key]: keyValue ? this.manufactureDateFormatted : '',
            };
          } else {
            dto.customerForm = {
              ...dto.customerForm,
              [key]: keyValue ? keyValue.toString().replace(/"/g, '') : '',
            };
          }
        }
      }
    });
    return dto;
  }

  /**
   * Input masking for phone number field
   * @param field
   */
  formatPhone(field) {
    const phoneNumDigits = field.value.replace(/\D/g, '');
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6) {
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ')' +
        phoneNumDigits.substring(3, 6) +
        '-' +
        phoneNumDigits.substring(6);
    } else if (phoneNumDigits.length >= 3) {
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ')' +
        phoneNumDigits.substring(3);
    }
    field.value = formattedNumber;
  }

  /**
   * Open the serial number tips dialog
   */
  openSNTip() {
    this.confirmationService.confirm(
      '',
      this.ocids['placard-request.how-to-find-body-SN-label'],
      this.ocids['global.close'],
      ''
    );
  }

  /**
   * Opens barcode scanner
   */
  async openScanner() {

    (await this.scanner.scanSerialNum('Placard Request')).subscribe((data?) => {
      if (data) {
        this.serialSearch = data;
      }
    });

  }

  ngOnDestroy() {
    // Send form not finished event to GA if user leaves without submitting the form
    if (this.platformBrowser && !this.caseSubmitted) {
      const dataLayer = (this.winRef.nativeWindow.dataLayer =
        this.winRef.nativeWindow.dataLayer || []);
      dataLayer.push({
        event: 'formEvent',
        formAction: 'Unfinished',
        formName: 'Placard',
      });
    }
  }
}
