import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  IWeb2Case,
  IWorkOrder,
  IWorkOrderList,
} from '../../../contracts/web2case/iweb2case';
import { IFileList, IWeb2CaseDto } from '../../../contracts/web2case/iweb2case-dto';
import { Subject } from 'rxjs';

@Injectable()
export class Web2CaseService {
  constructor(private http: HttpClient) { }
  complaintType$ = new Subject();

  /**
   * Posts case to Salesforce.
   * @param {IWeb2CaseDto} web2caseDto
   * @returns {Observable<any>}
   */
  postCase(web2caseDto: IWeb2CaseDto) {
    return this.http.post(
      `${environment.apiUrl}/webtocase/customerCase`,
      web2caseDto
    );
  }

  /**
   * Returns a list of cases for the current user
   * @returns {Observable<IWeb2CaseList>}
   */
  getCaseList() {
    return this.http
      .get<IWeb2Case[]>(`${environment.apiUrl}/webtocase/getCurrentUserCases`)
      .pipe(
        map((res) => {
          return <IWeb2Case[]>res;
        })
      );
  }
  /**
   * Returns a list of attachment for case id
   * @returns {Observable<IFileList>}
   */
  getCaseAttachmentList(Id: number) {
    return this.http
      .post<IFileList[]>(`${environment.apiUrl}/webtocase/caseAttachments`, { Id })
      .pipe(
        map((res) => {
          return <IFileList[]>res;
        })
      );
  }

  /**
   * Returns a list of work orders for the current user
   * @returns {Observable<IWorkOrderList>}
   */
  getWorkOrders() {
    return this.http
      .get<IWorkOrderList>(
        `${environment.apiUrl}/webtocase/getCurrentUserWorkOrders`
      )
      .pipe(
        map((res) => {
          return <IWorkOrder[]>res.workOrderList;
        })
      );
  }

  /**
   * Request a case update
   * @param {any} caseRequest
   * @returns {}
   */
  requestUpdate(caseRequest) {
    return this.http.post(
      `${environment.apiUrl}/webtocase/updateCase`,
      caseRequest
    );
  }

  get complaint() {
    return this.complaintType$.asObservable();
  }

  set complaintType(val: string) {
    this.complaintType$.next(val);
  }
}
