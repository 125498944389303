import { Component, Inject, OnInit } from '@angular/core';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getSiteNetworkPin } from '../../../contracts/clearsky/map-pins';
import { Machine } from '../../../contracts/clearsky/machine/machine.dto';
import { Router } from '@angular/router';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';
import { OcidItems } from 'app/contracts/ocid-items';

interface DialogData {
  network: SiteNetwork;
}

@Component({
  selector: 'app-network-detail-dialog',
  templateUrl: './network-detail-dialog.component.html',
})
export class NetworkDetailDialogComponent implements OnInit {
  networkPinSvg: undefined | string;
  machineColumns = ['sn', 'eid', 'model', 'alerts'];
  isMachineTableExpanded = false;
  isLogTableExpanded = false;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<NetworkDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    private localization: LocalizationService
  ) {
    this.networkPinSvg = getSiteNetworkPin();
  }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.network-log-label'])
      .pipe(first())
      .subscribe();
  }

  /**
   * On machine row clicked event listener in machine table.
   * @param machine
   */
  onMachineRowClick(machine: Machine): void {
    this.dialogRef.close();
    this.router.navigate(['/clearsky/machine', machine.sn]);
  }
}
