import { Component, OnInit } from '@angular/core';
import {
  Widgets,
  WidgetsDisplay,
} from '../../../../contracts/clearsky/dashboard/cs-dashboard.dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-battery-monitoring',
  templateUrl: './battery-monitoring.component.html',
  styleUrls: ['./battery-monitoring.component.scss'],
})
export class BatteryMonitoringComponent implements OnInit {
  displayName = WidgetsDisplay.batteryMonitoring;
  widgetName = Widgets.BATTERY_MONITORING;
  ocids: OcidItems = {};

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.state-of-charge-label',
        'clearsky.charge-label',
        'clearsky.time-charge-label',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));
  }
}
