import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { SiteNetwork } from '../../../contracts/clearsky/site-network';
import {
  getBingMapInstance,
  MachinePushpinColors,
} from '../../../contracts/clearsky/machine/machine.map.config';
import {
  getPushpinByModelGroup,
  getSiteNetworkPin,
} from '../../../contracts/clearsky/map-pins';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BingMapsService } from '../../../service/bing-maps.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-network-mini-map',
  templateUrl: './network-mini-map.component.html',
})
export class NetworkMiniMapComponent implements AfterViewInit {
  @Input() network!: SiteNetwork;
  @ViewChild('map') map: ElementRef;
  bingMap: Microsoft.Maps.Map;

  constructor(private bingMaps: BingMapsService) {}

  ngAfterViewInit(): void {
    this.bingMaps.initialize().subscribe(() => {
      this.loadMap();
    });
  }

  private loadMap(): void {
    this.bingMap = getBingMapInstance(this.map.nativeElement, {
      showLocateMeButton: false,
    });

    // Create custom Pushpin
    const pin = new Microsoft.Maps.Pushpin(
      new Microsoft.Maps.Location(
        this.network.centerPoint.latitude,
        this.network.centerPoint.longitude
      ),
      {
        title: this.network.siteNetworkName,
        icon: getSiteNetworkPin(),
      }
    );

    // Add the pushpin to the map
    this.bingMap.entities.push(pin);

    // Now plot machines in network
    this.network.machinesData.forEach((machine) => {
      const loc = new Microsoft.Maps.Location(machine.loc.lat, machine.loc.lng);

      const pushpin = new Microsoft.Maps.Pushpin(loc, {
        title: machine.eid,
        visible: true,
        icon: getPushpinByModelGroup(machine.mgroup),
        anchor: new Microsoft.Maps.Point(10, 10),
      });

      this.bingMap.entities.push(pushpin);
    });

    // Now draw bounds
    const bounds = this.network.bounds.map(
      (bound) => new Microsoft.Maps.Location(bound.latitude, bound.longitude)
    );

    const polygon = new Microsoft.Maps.Polygon(bounds, {
      fillColor: new Microsoft.Maps.Color(0.2, 0, 193, 212),
      strokeColor: MachinePushpinColors.INSIDE_NETWORK,
      strokeThickness: 2,
      visible: true,
    });

    this.bingMap.entities.push(polygon);

    // Now zoom map to bounds of network
    this.bingMap.setView({
      bounds: Microsoft.Maps.LocationRect.fromLocations(bounds),
      padding: 100,
    });
  }
}
