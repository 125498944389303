// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-overlay {
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: -webkit-fill-available;
  z-index: 101;
  margin-left: -40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vLW1hY2hpbmUtZGF0YS1hbGVydC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxlQUFBO0VBQ0EsOEJBQUE7RUFDQSw2QkFBQTtFQUNBLFlBQUE7RUFDQSxrQkFBQTtBQUNGIiwiZmlsZSI6Im5vLW1hY2hpbmUtZGF0YS1hbGVydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250ZW50LW92ZXJsYXkge1xuICBoZWlnaHQ6IDEwMCU7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgYmFja2dyb3VuZDogcmdiYSgwLCAwLCAwLCAwLjUpO1xuICB3aWR0aDogLXdlYmtpdC1maWxsLWF2YWlsYWJsZTtcbiAgei1pbmRleDogMTAxO1xuICBtYXJnaW4tbGVmdDogLTQwcHg7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/machines/no-machine-data-alert/no-machine-data-alert.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,8BAAA;EACA,6BAAA;EACA,YAAA;EACA,kBAAA;AACF;AACA,okBAAokB","sourcesContent":[".content-overlay {\n  height: 100%;\n  position: fixed;\n  background: rgba(0, 0, 0, 0.5);\n  width: -webkit-fill-available;\n  z-index: 101;\n  margin-left: -40px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
