import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Machine,
  MachinePersonality,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { isPlatformBrowser } from '@angular/common';
import XLSX from 'xlsx-populate/browser/xlsx-populate.min';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { saveAs } from 'file-saver-es';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';

interface DialogData {
  machine: Machine;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-personalities-dialog',
  templateUrl: './machine-personalities-dialog.component.html',
})
export class MachinePersonalitiesDialogComponent implements OnInit {
  personalities: MachinePersonality[] = [];
  lastModified: string;
  lastReported: string;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<MachinePersonalitiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PLATFORM_ID) private platformId: string,
    private localization: LocalizationService
  ) { }

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.personalities-details-label',
        'clearsky.last-reported-label',
        'clearsky.last-modified-label',
        'clearsky.download-historical-report-label',
      ])
      .pipe(first())
      .subscribe();
    dayjs.extend(utc);

    // Only display latest in dialog
    const personalities = [...(this.data.machine.mpers || [])].pop();
    if (personalities) {
      this.personalities = personalities.array;
      this.lastModified = dayjs
        .utc(personalities.time)
        .format('MMMM D, YYYY h:mm A');
      this.lastReported = dayjs
        .utc(this.data.machine.lrt)
        .format('MMMM D, YYYY h:mm A');
    }
  }

  onDownload(): void {
    // Write all personalities to csv
    if (isPlatformBrowser(this.platformId)) {
      // Get category names for first column
      const categoryNames: { [category: string]: string[] } = (
        this.data.machine.mpers || []
      ).reduce((categories, personality) => {
        personality.array.reduce((prev, entity) => {
          const category = entity.category;

          entity.children.forEach((pair) => {
            const fullName = `${category}: ${pair.name}`;

            if (!categories[fullName]) {
              categories[fullName] = [];
            }

            categories[fullName].push(pair.value);
          });

          return prev;
        }, {});

        return categories;
      }, {});

      const historicalCount = (this.data.machine.mpers || []).length;
      const blankColumnsForTimestamps = new Array(historicalCount);

      // Header information
      const rows: string[][] = [
        [
          `Serial Number: ${this.data.machine.sn}`,
          ...blankColumnsForTimestamps,
        ],
        [`Asset ID: ${this.data.machine.eid}`, ...blankColumnsForTimestamps],
        [
          `Last Reported: ${dayjs
            .utc(this.data.machine.lrt)
            .format('MMMM D, YYYY H:mm')}`,
          ...blankColumnsForTimestamps,
        ],
        ['Personalities', ...blankColumnsForTimestamps],
        ['', ...blankColumnsForTimestamps],
        [
          '',
          ...(this.data.machine.mpers || []).map((i) =>
            dayjs.utc(i.time).format('M/D/YYYY H:mm')
          ),
        ],
        ...Object.keys(categoryNames).map((key) => [
          key,
          ...categoryNames[key],
        ]),
      ];

      const name = this.ocids['clearsky.personalities-details-label'];
      XLSX.fromBlankAsync().then((workbook) => {
        // Modify the workbook.
        const sheet = workbook.sheet(0).name(name);
        sheet.row(1).cell(1).value(rows);

        // Write to file.
        workbook.outputAsync('blob').then((blob) => {
          saveAs(blob, `${name}.xlsx`);
        });
      });
    }
  }
}
