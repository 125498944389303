import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from '../../../../contracts/ocid-items';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-search-crumbs',
  styleUrls: [
    '../../../endeca-payload/base-breadcrumbs/base-breadcrumbs.component.scss',
  ],
  templateUrl: './search-crumbs.component.html',
})
export class SearchCrumbsComponent implements OnInit {
  @Input() data!: {
    removeAction: {
      contentPath: string;
      navigationState: string;
    };
    correctedTerms: string;
    terms: string;
    key: string;
  };
  ocids!: OcidItems;

  constructor(private localization: LocalizationService) {}

  ngOnInit() {
    // Get localization info
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
  }
}
