export interface IWeb2Case {
  attributes: any;
  Job_Site_Address__c: string;
  OLE_case_update__c: string;
  Job_Site_Contact_Name__c: string;
  Job_Site_Contact_Phone__c: string;
  Order_Number__c: string,
  Asset_Model__c: string;
  CaseNumber: string;
  Case_Region__c: string;
  CreatedDate: string;
  OLE_case_update_date__c: string;
  Description: string;
  CurrencyIsoCode: string;
  Customer_PO__c: string;
  Id: string;
  Job_Site_Contact_City__c: string;
  Job_Site_Contact_Country__c: string;
  Job_Site_Contact_Postal_Code__c: string;
  Job_Site_Contact_Street__c: string;
  Job_Site_Name__c: string;
  Level__c: string;
  OLE_CreatedBy__c: string;
  OLE_Status__c: string;
  OLE_request_update__c: boolean;
  Origin: string;
  Serial_No__c: string;
  Subject: string;
  SuppliedEmail: string;
  SuppliedName: string;
  SuppliedPhone: string;
  Type: string;
  Level_2__c: string,
  Part_No_1__c: string,
  Part_No_1_Qty__c: number,
  Parts_Number_received__c: string,
  Qty_received__c: number
}

export interface IWorkOrderList {
  workOrderList: IWorkOrder[];
}

export interface IWorkOrder {
  createdDate: string;
  id: string;
  jobType: string;
  machineSerialNumber: string;
  model: string;
  repairType: string;
  status: string;
  suggestedMaintenanceDate: string;
  workOrderNumber: string;
}

export const CaseStatus = {
  NEW: 'New',
  ACTIVE: 'Active',
  CLOSED: 'Closed',
};
