import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Machine } from '../../../../../contracts/clearsky/machine/machine.dto';
import { EngineComponentDialogComponent } from '../../engine-component-dialog/engine-component-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalizationService } from '../../../../../shared/localization/localization.service';
import { OcidItems } from '../../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  MachineWidgets,
  MachineWidgetsDisplay,
} from '../../../../../contracts/clearsky/dashboard/cs-asset-dashboard.dto';
import { ClearskyService } from '../../../../clearsky.service';
import { convertToLegendVal } from '../../../../shared/legend-value.pipe';
import { CSLegend } from '../../../../../contracts/clearsky/clearsky-legend';
import { WidgetColors } from 'app/contracts/clearsky/dashboard/cs-colors.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-asset-engine-information',
  templateUrl: './asset-engine-information.component.html',
  styleUrls: ['./asset-engine-information.component.scss'],
})
export class AssetEngineInformationComponent implements OnInit, OnChanges {
  @Input() machine: Machine;
  widgetName = MachineWidgets.ENGINE;
  displayName = MachineWidgetsDisplay.engine;
  hasClickHandler: boolean;
  engineIconClass: string;
  ocids: OcidItems = {};
  hasDefRemaining = false;
  hasFuelLevel = false;
  legend!: CSLegend;
  color: string;

  constructor(
    private dialog: MatDialog,
    private localization: LocalizationService,
    private clearskyService: ClearskyService
  ) { }

  ngOnInit(): void {
    this.getBatteryColor();
    // Get OCIDs needed for these components.
    this.localization
      .getOCIDs([
        'clearsky.make-label',
        'clearsky.model-label',
        'clearsky.class-label',
        'clearsky.sn-label',
        'web2case.level2.fuel-level',
        'clearsky.def-level-label',
        'clearsky.battery-label',
        'clearsky.v-label',
        'clearsky.no-label',
        this.displayName,
      ])
      .subscribe((ocids) => (this.ocids = ocids));

    this.clearskyService.legendRef$.subscribe((legend) => {
      this.legend = legend;
      this.engineIconClass = convertToLegendVal(
        this.machine.estat,
        legend,
        'estat'
      )?.toString()
        .toLowerCase()
        .includes('not running')
        ? 'text-body-gray'
        : 'text-success';
    });

    this.setVisEls();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setVisEls();
  }

  /**
   * Open dialog for engine details.
   */
  onOpenEngineDetails(): void {
    if (!this.hasClickHandler) {
      return;
    }

    this.dialog.open(EngineComponentDialogComponent, {
      panelClass: [
        'mat-dialog-right',
        '_35',
        'clearsky-dialog',
        '_dark',
        'engine-component-dialog',
      ],
      data: {
        machine: this.machine,
      },
    });
  }

  /**
   * Set visibility on elements.
   * @private
   */
  private setVisEls(): void {
    this.hasDefRemaining = 'defRm' in this.machine;
    this.hasFuelLevel = 'flvl' in this.machine;
    this.hasClickHandler = !!(this.machine.efpT || this.machine.efcT);
  }

  getBatteryColor() {
    const { batV } = this.machine;
    if (batV === 0) {
      this.color = WidgetColors.grey;
    } else if (batV <= 11.8) {
      this.color = WidgetColors.red;
    } else if (batV <= 12.4) {
      this.color = WidgetColors.yellow;
    } else {
      this.color = WidgetColors.green;
    }
    return this.color;
  }
}
