import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';
import { LayoutService } from '../../../service/layout.service';
import { SearchService } from '../search/search.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-guided-navigation',
  styleUrls: ['./guided-navigation.component.scss'],
  templateUrl: './guided-navigation.component.html',
})
export class GuidedNavigationComponent
  implements OnInit, CartridgeInterface, OnDestroy {
  @Input() data: {
    navigation: {
      name: string;
      dimensionName: string;
    }[];
  };

  constructor(
    public layoutService: LayoutService,
    public searchService: SearchService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
  ) { }

  ngOnInit() {
    this.layoutService.isMobile$.subscribe((isMobile) => {
      this.layoutService.searchFilterCollapsed = isMobile;
    });
    // Set to true so we can show the filter button on mobile
    this.searchService.hasFilters.next(true);
  }

  closeFilters() {
    this.layoutService.searchFilterCollapsed =
      !this.layoutService.searchFilterCollapsed;
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.classList.remove('tw-overflow-hidden');
    }
  }

  ngOnDestroy() {
    // hide filter button when we navigate away
    this.searchService.hasFilters.next(false);
  }
}
