import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class PartsService {
  constructor(private _http: HttpClient) {}

  /**
   * Verify part by serial number.
   *
   * @param {number} part
   * @param {number} serial
   */
  verifyBySerialNum(part: number, serial: number): Observable<any> {
    const params = new HttpParams()
      .set('pn', String(part))
      .set('sn', String(serial));

    return this._http
      .get(`${environment.apiUrl}/validatePart/forSerialNumber`, {
        params,
      })
      .pipe(
        map((res) => {
          const response = res;

          return response;
        })
      );
  }
}
