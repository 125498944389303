import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../../../service/content.service';
import { CanonicalService } from '../../../shared/canonical.service';
import { TemplateInterface } from '../../template/template.class';

@Component({
  selector: 'app-one-column-page',
  templateUrl: './one-column-page.component.html'
})
export class OneColumnPageComponent extends TemplateInterface implements OnInit {
  @Input('data')
  data: {
    headerContent: object[];
    mainContent: object[];
    baseBreadcrumbs?: object[];
    footerContent: object[];
  };
  @Input('url') payloadUrl: string;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              protected titleService: Title,
              protected metaService: Meta,
              protected canonicalService: CanonicalService,
              protected contentService: ContentService) {
    super();
  }

  ngOnInit() {
    // Set whether the platform is the browser or server.
    const platformBrowser = isPlatformBrowser(this.platformId);
    // Set metadata title & description
    this._setMetaData(platformBrowser);
  }

}
