// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
:host .subscription-addon-icon {
  height: 35px;
  width: 35px;
  font-size: 35px;
}
:host.subscription-addon-card-icon-left {
  order: 0;
  margin-right: 5px;
}
:host.subscription-addon-card-icon-right {
  order: 1;
  margin-left: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1YnNjcmlwdGlvbi1hZGRvbi1jYXJkLWljb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxtQkFBQTtFQUNBLDJCQUFBO0FBQ0Y7QUFDRTtFQUNFLFlBQUE7RUFDQSxXQUFBO0VBQ0EsZUFBQTtBQUNKO0FBRUU7RUFDRSxRQUFBO0VBQ0EsaUJBQUE7QUFBSjtBQUVFO0VBQ0UsUUFBQTtFQUNBLGdCQUFBO0FBQUoiLCJmaWxlIjoic3Vic2NyaXB0aW9uLWFkZG9uLWNhcmQtaWNvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcclxuICBkaXNwbGF5OiBmbGV4O1xyXG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XHJcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcclxuICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XHJcblxyXG4gIC5zdWJzY3JpcHRpb24tYWRkb24taWNvbiB7XHJcbiAgICBoZWlnaHQ6IDM1cHg7XHJcbiAgICB3aWR0aDogMzVweDtcclxuICAgIGZvbnQtc2l6ZTogMzVweDtcclxuICB9XHJcblxyXG4gICYuc3Vic2NyaXB0aW9uLWFkZG9uLWNhcmQtaWNvbi1sZWZ0IHtcclxuICAgIG9yZGVyOiAwO1xyXG4gICAgbWFyZ2luLXJpZ2h0OiA1cHg7XHJcbiAgfVxyXG4gICYuc3Vic2NyaXB0aW9uLWFkZG9uLWNhcmQtaWNvbi1yaWdodCB7XHJcbiAgICBvcmRlcjogMTtcclxuICAgIG1hcmdpbi1sZWZ0OiA1cHg7XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/clearsky/subscription/subscription-addons-selection/subscription-addon-card/subscription-addon-card-icon/subscription-addon-card-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,eAAA;AACJ;AAEE;EACE,QAAA;EACA,iBAAA;AAAJ;AAEE;EACE,QAAA;EACA,gBAAA;AAAJ;AACA,4hCAA4hC","sourcesContent":[":host {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: flex-start;\r\n\r\n  .subscription-addon-icon {\r\n    height: 35px;\r\n    width: 35px;\r\n    font-size: 35px;\r\n  }\r\n\r\n  &.subscription-addon-card-icon-left {\r\n    order: 0;\r\n    margin-right: 5px;\r\n  }\r\n  &.subscription-addon-card-icon-right {\r\n    order: 1;\r\n    margin-left: 5px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
