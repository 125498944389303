import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { INavMenu, IUserNavMenus } from '../../contracts/user/inav-menu';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMenuListItem } from '../../contracts/user/imenu-list-item';

@Injectable()
export class MenuService {
  private menus: BehaviorSubject<IUserNavMenus> =
    new BehaviorSubject<IUserNavMenus>(null);
  public menus$ = this.menus.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Get user navigation menus.
   *
   * @param {boolean} [clearPayloadCache=false]
   * @returns {Observable<IUserNavMenus>}
   */
  getMenus(clearPayloadCache: boolean = false) {
    const headers = {};
    // If we need to clear the payload cache for the request, add that header to the request.
    if (clearPayloadCache) {
      headers['clear-payload-cache'] = 'true';
    }
    const httpOptions = {
      headers: new HttpHeaders(headers),
    };
    return this.http
      .get<IUserNavMenus>(
        `${environment.apiUrl}/currentUser/menus`,
        httpOptions
      )
      .pipe(
        map((menus: IUserNavMenus) => {
          this.menus.next(menus);

          return menus;
        })
      );
  }

  /**
   * Get clearsky menu.
   */
  getClearskyMenu(): Observable<INavMenu[]> {
    return this.http
      .get<INavMenu>(`${environment.apiUrl}/navigation/menus/clearsky`)
      .pipe(map((menu) => menu.childMenus || []));
  }

  /**
   * Get google play store badge menu.
   */
  getPlayStoreBadgeMenu(): Observable<string | undefined> {
    return this.http
      .get<INavMenu>(`${environment.apiUrl}/navigation/menus/googlePlayBadge`)
      .pipe(map((menu) => menu.label));
  }

  /**
   * Get apple app store badge menu.
   */
  getAppStoreBadgeMenu(): Observable<string | undefined> {
    return this.http
      .get<INavMenu>(
        `${environment.apiUrl}/navigation/menus/appleAppStoreBadge`
      )
      .pipe(map((menu) => menu.label));
  }

  /**
   * Return menu by UX key.
   *
   * @param {string} key
   * @returns {Observable<INavMenu | undefined>}
   */
  public getMenuByUxKey(key: string) {
    return this.menus.pipe(
      map((menus: IUserNavMenus) => {
        return menus
          ? menus.menus.find((menu: INavMenu) => menu.uxKey === key)
          : null;
      })
    );
  }

  /**
   * Map array of objects to a menu translation.
   *
   * @param {string} menuUxKey
   * @param {object[]} data
   * @param {string} dataKey
   * @return Observable<IMenuListItem[]|any[]>
   */
  public mapMenu(
    menuUxKey: string,
    data: any[],
    dataKey?: string
  ): Observable<IMenuListItem[] | any[]> {
    return this.getMenuByUxKey(menuUxKey).pipe(
      map((menu: INavMenu | undefined) => {
        if (menu) {
          // Map the data to the key and label in the menu.
          const newMappedMenu = <IMenuListItem[]>data
            .map((value: object) => {
              // Find the key in the menu.
              const search = dataKey ? value[dataKey] : value;
              // Find the menu with key.
              const foundMenu = menu.childMenus.find(
                (menuItem: INavMenu) => menuItem.uxKey === search
              );
              // Return the found label and key.
              return {
                value: search,
                label: foundMenu ? foundMenu.label : null,
              };
              // Filter out any null objects.
            })
            .filter((newMenu: IMenuListItem) => newMenu.label !== null);
          // Returned the new mapped menu or an empty array.
          return newMappedMenu.length !== 0 ? newMappedMenu : [];
        }

        return [];
      })
    );
  }

  /**
   * Return menu from Footer Menu by UX key.
   *
   * @param {string} key
   * @returns {Observable<INavMenu | undefined>}
   */
  getFooterMenuByUxKey(key: string) {
    return this.menus$.pipe(
      map((menus: IUserNavMenus) => {
        return menus.footerMenu.childMenus.find(
          (menu: INavMenu) => menu.uxKey === key
        );
      })
    );
  }
}
