// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-list-item {
  padding: 20px 0;
}
.control-list-item:not(:last-child) {
  border-bottom: 1px solid var(--gray10);
}
.control-list-item a {
  color: var(--orange);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbnRyb2wtcmVzdWx0LWxpc3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFFRSxlQUFBO0FBREo7QUFFSTtFQUNFLHNDQUFBO0FBQU47QUFFSTtFQUNFLG9CQUFBO0FBQU4iLCJmaWxlIjoiY29udHJvbC1yZXN1bHQtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250cm9sIHtcclxuICAmLWxpc3QtaXRlbSB7XHJcblxyXG4gICAgcGFkZGluZzogMjBweCAwO1xyXG4gICAgJjpub3QoOmxhc3QtY2hpbGQpIHtcclxuICAgICAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkIHZhcigtLWdyYXkxMCk7XHJcbiAgICB9XHJcbiAgICBhIHtcclxuICAgICAgY29sb3I6IHZhcigtLW9yYW5nZSk7XHJcbiAgICB9XHJcbiAgfVxyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/endeca/cartridges/control-result-list/control-result-list.component.scss"],"names":[],"mappings":"AACE;EAEE,eAAA;AADJ;AAEI;EACE,sCAAA;AAAN;AAEI;EACE,oBAAA;AAAN;AACA,4mBAA4mB","sourcesContent":[".control {\r\n  &-list-item {\r\n\r\n    padding: 20px 0;\r\n    &:not(:last-child) {\r\n      border-bottom: 1px solid var(--gray10);\r\n    }\r\n    a {\r\n      color: var(--orange);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
