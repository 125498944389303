import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { INavMenu } from '../../../../../contracts/user/inav-menu';
import { MenuService } from '../../../../../service/user/menu.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-account-orders',
  styleUrls: ['./account-orders-start.component.scss'],
  templateUrl: './account-orders-start.component.html',
})
export class AccountOrdersStartComponent implements OnInit {
  menu: INavMenu;

  constructor(private menuService: MenuService) {}

  ngOnInit() {
    // Subscribe to menu data
    this.menuService.menus$
      .pipe(
        mergeMap((menus) =>
          menus ? this.menuService.getMenuByUxKey('orders-dashboard') : of(null)
        )
      )
      .subscribe((menu: INavMenu) => {
        if (menu) {
          this.menu = menu;
        }
      });
  }

  get orderDashboard() {
    return this.menu;
  }
}
