import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { OcidItems } from 'app/contracts/ocid-items';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-subscription-tos',
  templateUrl: './subscription-tos.component.html',
})
export class SubscriptionTosComponent {
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Input() tosContent: unknown;
  form = this.fb.group({
    tos: this.fb.control(false, Validators.requiredTrue),
  });
  ocids: OcidItems = {};

  constructor(
    private fb: UntypedFormBuilder,
    private localization: LocalizationService
  ) {
    this.translate();
  }
  translate() {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs([
        'clearsky.accept-label',
        'clearsky.terms-label',
        'clearsky.continue-label',
      ])
      .pipe(first())
      .subscribe();
  }
}
