import * as Highcharts from 'highcharts';
import { EnvelopeProfileChart } from './envelope-profile-chart';

export class EnvelopeProfileBoomChart extends EnvelopeProfileChart {
  static createInstance(): EnvelopeProfileBoomChart {
    return new this();
  }

  /**
   * Draw zones (determine placements of labels and rects).
   * @param svg
   * @param tooltip
   * @protected
   */
  protected drawZones(svg: Highcharts.Chart, tooltip?: Function): void {
    const offset = 0.6 / this.zoneCount; // Don't go below 40%
    const getVariable = (i: number, zone: number): number => {
      return i * (offset * (this.zoneCount - zone));
    };

    const renderer = svg.renderer;
    const width = svg.plotWidth;
    const height = svg.plotHeight - 10;
    this.chartSVG = renderer.g('chart').add();

    for (let i = this.zoneCount; i > 0; i--) {
      const topOffset = 10 + getVariable(height, i);
      const panelWidth = width - getVariable(width, i);
      const panelHeight = height - getVariable(height, i);

      // Now add tooltip label
      const zoneTooltip = tooltip
        ? this.renderTooltip(
            svg,
            i,
            {
              x: 0,
              y: 20 + getVariable(height, i),
            },
            tooltip ? tooltip(i) : null
          )
        : null;

      // Add rectangle
      this.renderRect(svg, i, {
        x: 10,
        y: topOffset,
        width: panelWidth,
        height: panelHeight,
        tooltip: zoneTooltip,
        radius: 5,
      });

      // Add label
      this.renderLabel(svg, i, {
        x: 20,
        y: 10 + getVariable(height, i),
      });
    }
  }
}
