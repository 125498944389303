import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OcidItems } from '../../../../contracts/ocid-items';
import { ITruckData } from '../../../../contracts/placard/iplacard-dto';

@Component({
  selector: 'app-truck-form',
  templateUrl: './truck-form.component.html',
})
export class TruckFormComponent implements OnInit, OnChanges {
  @Input() ocids!: OcidItems;
  @Input() truckForm!: UntypedFormGroup;
  @Input() truckData!: ITruckData;
  private titleCasePipe = new TitleCasePipe();
  driveTypeOpts = [
    '4X2 (SINGLE STEER - SINGLE DRIVE)',
    '4X4 (SINGLE STEER - ALL DRIVE)',
  ];
  engineTypeOpts!: string[];
  chassisCabOpts = ['REGULAR', 'EXTENDED', 'CREW'];
  chassisBrakeOpts = [
    'AIR BRAKES - DISC',
    'AIR BRAKES - DRUM',
    'HYDRAULIC BRAKES',
  ];
  truckOpts = {
    makes: [
      'Dodge',
      'Ford',
      'Freightliner',
      'GMC/Chevy',
      'Hino',
      'International',
      'Isuzu',
      'Kenworth',
      'Mack',
      'Peterbilt',
      'Volvo',
      'Western Star',
    ],
    Dodge: [
      'DODGE 3500',
      'DODGE 4500',
      'DODGE 4500 ALUM',
      'DODGE 4500 STEEL',
      'DODGE 5500',
      'DODGE 5500 ALUM',
      'DODGE 5500 LARAMIE',
      'DODGE 5500 STEEL',
    ],
    Ford: [
      'FORD F350',
      'FORD F350 XL ALUM',
      'FORD F350 XLT ALUM',
      'FORD F350 XLT STEEL',
      'FORD F450',
      'FORD F450 ALUM XL',
      'FORD F450 ALUM XLT HC',
      'FORD F450 STEEL XL',
      'FORD F450 XLT ALUM',
      'FORD F450 XLT STEEL',
      'FORD F550',
      'FORD F550 ALUM LARIAT',
      'FORD F550 ALUM XL',
      'FORD F550 ALUM XLT HC',
      'FORD F550 STEEL XL',
      'FORD F550 XLT ALUM',
      'FORD F550 XLT STEEL',
      'FORD F600',
      'FORD F650',
      'FORD F650 AIR HP',
      'FORD F650 AIR RIDE/BRAKES',
      'FORD F650 ALUM',
      'FORD F650 HP',
      'FORD F650 STEEL',
      'FORD F750',
      'FORD F750 HP',
    ],
    Freightliner: [
      'FREIGHTLINER 108SD',
      'FREIGHTLINER 114 SD',
      'FREIGHTLINER CORONADO SD',
      'FREIGHTLINER FLM212DA',
      'FREIGHTLINER M2 106 AW AR 19.5',
      'FREIGHTLINER M2 106 AW AR 19.5 HP',
      'FREIGHTLINER M2 106 AW AR 22.5',
      'FREIGHTLINER M2 106 AW AR 22.5 HP',
      'FREIGHTLINER M2 106 AW SR 19.5',
      'FREIGHTLINER M2 106 AW SR 22.5',
      'FREIGHTLINER M2 106 SW SR 19.5',
      'FREIGHTLINER M2 106 SW SR 22.5',
      'FREIGHTLINER M2 112',
      'FREIGHTLINER M2 PRO',
    ],
    'GMC/Chevy': [
      'Chevrolet 3500HD',
      'Chevrolet 4500HD',
      'Chevrolet 5500HD',
      'Chevrolet 5500HD WT',
      'Chevrolet 6500HD',
      'Chevrolet 6500HD Air',
      'Chevrolet 6500HD WT',
      'Chevrolet 6500HD AIR WT',
      'GMC 6500',
      'GMC C3500',
      'GMC C4500',
      'GMC C5500',
    ],
    Hino: [
      'HINO 195',
      'HINO 258ALP',
      'HINO 258ALP-A',
      'HINO 258ALP-A HP',
      'HINO 258ALP-B',
      'HINO 258ALP-C',
      'HINO 258LP',
      'HINO 258LP-A',
      'HINO 258LP-B',
      'HINO 268',
      'HINO 268A',
      'HINO 268A HP',
      'HINO 338',
      'HINO FB2320',
      'HINO L6-A',
      'HINO L6-A HP',
      'HINO L6-B',
      'HINO M5',
      'HINO L6-A HP 22.5',
    ],
    International: [
      'INTERNATIONAL 4300',
      'INTERNATIONAL 4300 AB SR',
      'INTERNATIONAL 4300 AIR',
      'INTERNATIONAL 4300 AIR 22.5',
      'INTERNATIONAL 4300 AIR HP',
      'INTERNATIONAL 4300 AIR HP 22.5',
      'INTERNATIONAL 4300 HP',
      'INTERNATIONAL 4400',
      'INTERNATIONAL 5900I',
      'INTERNATIONAL 7500',
      'INTERNATIONAL 7600',
      'INTERNATIONAL CV503',
      'INTERNATIONAL CV515',
      'INTERNATIONAL HX515',
      'INTERNATIONAL HX520',
      'INTERNATIONAL HX615',
      'INTERNATIONAL HX620',
      'INTERNATIONAL LONESTAR',
      'INTERNATIONAL MV607',
      'INTERNATIONAL MV607 19.5',
      'INTERNATIONAL MV607 AIR 19.5',
      'INTERNATIONAL MV607 AIR 22.5',
      'INTERNATIONAL MV607 AIR HP 19.5',
      'INTERNATIONAL MV607 AIR HP 22.5',
      'INTERNATIONAL TERRASTAR',
    ],
    Isuzu: ['ISUZU NQR', 'ISUZU NRR'],
    Kenworth: [
      'KENWORTH T270',
      'KENWORTH T270 HP',
      'KENWORTH T370',
      'KENWORTH T440',
      'KENWORTH T800',
      'KENWORTH T880',
      'KENWORTH T880 HP',
      'KENWORTH T880 Rotator',
      'KENWORTH W900',
      'KENWORTH W900 ROTATOR',
      'KENWORTH W990',
      'KENWORTH W990 ROTATOR',
    ],
    Mack: ['MACK GRANITE GU813E', 'MACK GU812E'],
    Peterbilt: [
      'PETERBILT 330',
      'PETERBILT 335',
      'PETERBILT 337',
      'PETERBILT 337 HP',
      'PETERBILT 348',
      'PETERBILT 388',
      'PETERBILT 388 Rotator',
      'PETERBILT 389 BOC',
      'PETERBILT 389 BOC HP',
      'PETERBILT 389 ROTATOR',
      'PETERBILT 389 SOC',
      'PETERBILT 389 SOC HP',
      'Peterbilt 567',
      'Peterbilt 567 Rotator',
    ],
    Volvo: ['UD 18CS', 'Volvo'],
    'Western Star': [
      'WESTERN STAR 4700',
      'WESTERN STAR 4800',
      'WESTERN STAR 4900',
    ],
  };
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.engineTypeOpts = [
      this.ocids['global.gasoline'],
      this.ocids['global.diesel'],
    ];
    this.truckForm.addControl(
      'vin',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'year',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'truckMake',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'driveType',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'chassisBrakeType',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'truckChassisWheelBase',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'truckGVWR',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'frontGAWR',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'rearGAWR',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'model',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'engineType',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'chassisCabType',
      this.formBuilder.control('', Validators.required)
    );
    this.truckForm.addControl(
      'caORct',
      this.formBuilder.control('', Validators.required)
    );
  }

  ngOnChanges() {
    if (this.truckData) {
      if (Object.keys(this.truckData).length) {
        this.truckForm.get('vin').setValue(this.truckData.vin);
        this.truckForm.get('vin').disable();
        this.truckForm.get('year').setValue(this.truckData.year);
        this.truckForm.get('year').disable();
        this.truckForm
          .get('truckMake')
          .setValue(this.titleCasePipe.transform(this.truckData.truckMake));
        this.truckForm.get('model').setValue(this.truckData.model);
        this.truckForm.get('driveType').setValue(this.truckData.driveType);
        this.truckForm
          .get('chassisBrakeType')
          .setValue(this.truckData.chassisBrakeType);
      }
    }
  }
}
