import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from '../../cartridge/cartridge.class';

@Component({
  selector: 'app-image-navigation',
  templateUrl: './image-navigation.component.html'
})
export class ImageNavigationComponent implements OnInit, CartridgeInterface {
  @Input('data') data;

  constructor() {}

  ngOnInit() {}
}
