import { Component, Input, OnInit } from '@angular/core';
import { CartridgeInterface } from "../../../cartridge/cartridge.class";

@Component({
  selector: 'app-category-parts-auto-suggest',
  templateUrl: './category-parts-auto-suggest.component.html'
})
export class CategoryPartsAutoSuggestComponent implements OnInit, CartridgeInterface {
  @Input() data: {
    flyOutDisplayOrientation: string,
    matchSections: {
      heading: string;
      typeAheadMatches: {
        flyoutPath: string;
        suggestion: string;
        url: string;
      }[];
    }[];
  };

  constructor() { }

  ngOnInit() {}

}
