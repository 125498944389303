import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { ClearskyService } from '../../../clearsky.service';
import * as Highcharts from 'highcharts';
import { mergeDeep } from '../../../../shared/deep-merge';
import { BaseChartConfig } from '../../../../contracts/clearsky/machine/machine.chart.config';
import * as cloneDeep from 'lodash.clonedeep';
import * as dayjs from 'dayjs';
import { Router } from '@angular/router';
import { OcidItems } from '../../../../contracts/ocid-items';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalizationService } from '../../../../shared/localization/localization.service';
import { CsAggDateLastChargeData } from '../../../../contracts/clearsky/agg-data';
import { CsRequestKeys } from '../../../../contracts/clearsky/cs-machines-request';
import {
  CSFilter,
  CSFilterRange,
} from '../../../../contracts/clearsky/machine/machine-filter-v2';
import { MxDolcStartOfTime } from '../../../../contracts/clearsky/machine/machine.dto';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-equipment-time-since-last-charge',
  templateUrl: './equipment-time-since-last-charge.component.html',
})
export class EquipmentTimeSinceLastChargeComponent implements AfterViewInit {
  @ViewChild('chartEl') chartEl: ElementRef;
  ocids: OcidItems = {};
  plot;
  private aggData: CsAggDateLastChargeData[];
  private subs: Subscription;
  private xAxis: CSFilterRange[] = [
    { min: 0, max: 1 },
    { min: 1, max: 3 },
    { min: 3, max: 7 },
    { min: 7, max: 14 },
    { min: 14, max: 21 },
    { min: 21, max: null },
  ];

  constructor(
    private clearskyService: ClearskyService,
    private router: Router,
    private localization: LocalizationService
  ) {}

  ngAfterViewInit(): void {
    this.subs = combineLatest([
      this.clearskyService.getDataByWidgetKey(CsRequestKeys.dashView),
      this.localization.getOCIDs([
        'clearsky.present-label',
        'clearsky.days-label',
      ]),
    ]).subscribe(([page, ocids]) => {
      this.ocids = ocids;
      this.aggData =
        (page && page.aggregations && page.aggregations.dolc) ?? [];

      if (this.plot) {
        this.resetChartData();
        this.plotChart();
      } else {
        this.createChart();
      }
    });
  }

  /**
   * Create the chart instance.
   * @private
   */
  private createChart(): void {
    if (this.plot) {
      this.plot.destroy();
    }

    this.plot = Highcharts.chart(
      this.chartEl.nativeElement,
      mergeDeep(cloneDeep(BaseChartConfig), {
        chart: {
          type: 'column',
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            point: {
              events: {
                click: this.onChartClick.bind(this),
              },
            },
          },
        },
        xAxis: {
          categories: this.xAxis.map((axis) => {
            if (!axis.min && axis.max) {
              return `less than ${axis.max} days`;
            }

            if (!axis.max && axis.min) {
              return `greater than ${axis.min} days`;
            }

            return `${axis.min} to ${axis.max} days`;
          }),
          title: {
            text: null,
          },
        },
      })
    );

    this.plotChart();
  }

  /**
   * Plot chart with data points.
   * @private
   */
  private plotChart(): void {
    if (!this.plot) {
      return;
    }

    this.plot.addSeries({
      name: 'Time Since Last Charge',
      data: this.xAxis.map((axis, index) => {
        // Get machines that fall in this range
        const filtered = this.aggData.filter((d) => {
          const dayDiff = dayjs().diff(dayjs(d.ts), 'days');
          const min = axis.min ?? 0;
          const max =
            axis.max ?? dayjs().diff(dayjs(MxDolcStartOfTime), 'days');

          return dayDiff > min && dayDiff < max;
        });

        return {
          y: filtered.length,
          color: Highcharts.getOptions().colors[index],
          custom: {
            range: axis,
          },
        };
      }),
    });
  }

  /**
   * Remove series from existing chart.
   * @private
   */
  private resetChartData(): void {
    if (this.plot && this.plot.series && this.plot.series.length) {
      while (this.plot.series.length > 0) {
        this.plot.series[0].remove();
      }

      this.plot.redraw();
    }
  }

  /**
   * Listener for on click of chart.
   * @param e
   * @private
   */
  private onChartClick(e: {
    point: { custom: { range: number[]; status: boolean } };
  }): void {
    this.clearskyService.updateFilters([
      {
        key: CSFilter.lastChrg.key,
        values: [e.point.custom.range],
      },
    ]);
  }
}
