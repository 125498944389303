import { Component, Inject, OnInit } from '@angular/core';
import {
  HistoricSelection,
  Machine,
} from '../../../../contracts/clearsky/machine/machine.dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from 'app/shared/localization/localization.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OcidItems } from 'app/contracts/ocid-items';
import { first } from 'rxjs/operators';

interface DialogData {
  machine: Machine;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-machine-battery-monitoring-dialog',
  templateUrl: './machine-battery-monitoring-dialog.component.html',
})
export class MachineBatteryMonitoringDialogComponent implements OnInit {
  historic = HistoricSelection.HOURS_24;
  ocids: OcidItems = {};

  constructor(
    public dialogRef: MatDialogRef<MachineBatteryMonitoringDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private localization: LocalizationService
  ) {}

  ngOnInit(): void {
    // Get OCIDs needed for these components.
    this.localization.OCIDs.subscribe((ocids) => (this.ocids = ocids));
    this.localization
      .getOCIDs(['clearsky.battery-monitoring-label'])
      .pipe(first())
      .subscribe();
  }
}
